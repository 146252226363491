/* eslint-disable react/button-has-type */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Helmet } from "react-helmet-async";
// import * as Router from 'react-router-dom';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "react-datepicker/dist/react-datepicker.css";
// import TextField from "@mui/material/TextField";
import { Link as RouterLink } from "react-router-dom";

import { Link } from "@mui/material";
import { useState } from "react";
import styles from "../Wallet.module.css";
// ----------------------------------------------------------------------

export default function WithdrawFunds() {
    const [recipient, setRecipient] = useState('select');

    const handleRecipientChange = (event) => {
        setRecipient(event.target.value);
    };



    return (
        <>
            <Helmet>
                <title> Withdraw Funds </title>
            </Helmet>
            <div className="wrapper mt-md-3 mt-2">
            <div className=" pb-2 d-flex justify-content-between align-items-center border-bottom">
                    <div className="d-flex">
                        <Link
                            className="d-block text-secondary"
                            to="/app/wallet"
                            component={RouterLink}
                        >
                            <i
                                className="bi bi-x"
                                style={{ fontSize: "1.5em", lineHeight: "1" }}
                            ></i>
                        </Link>
                        <h6 className="pl-3">Withdraw Funds</h6>
                    </div>
                </div>

                <div className={`${styles.trftobank_side_wrap} mt-5`}>
                    <div>
                        <div className="mb-3">
                            <label htmlFor="">Input Amount</label>
                            <div className={`${styles.charges_input}`}>
                                <div className={`${styles.select_wrap}`}>
                                    <Select
                                        labelId="s"
                                        id="s"
                                        size="small"
                                        required
                                        name="verified"
                                        defaultValue="ngn"
                                        style={{ width: "fit-content" }}
                                    >
                                <MenuItem value="ngn">NGN</MenuItem>
                                <MenuItem value="cny">CNY</MenuItem>
                                    </Select>
                                </div>
                                <input
                                    type="text"
                                    placeholder="1200"
                                    style={{ width: "100%", textIndent: "75px" }}
                                    className=" form-control"
                                />
                            </div>
                            <small className="text-secondary font-weight-normal">
                                charge for transaction NGN10
                            </small>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="name">Select Destination</label>
                            <Select
                                labelId="s"
                                id="s"
                                fullWidth
                                size="small"
                                required
                                name="verified"
                                defaultValue="select"
                                value={recipient}
                                onChange={handleRecipientChange}
                            >
                                <MenuItem value="select">- Select -</MenuItem>
                                <MenuItem value="myself">Sterling Bank - 0066924804</MenuItem>
                                <MenuItem value="another-merch">Wema Bank - 0066924804</MenuItem>
                            </Select>
                        </div>

                        <div className="my-5">
                            <Link to="/app/wallet/payment-otp" component={RouterLink} className="btn border btn-primary">
                                <span className="button_text text-white">Proceed</span>
                            </Link>
                            &nbsp; &nbsp;
                            <Link
                                className="btn border"
                                to="/app/wallet"
                                component={RouterLink}
                            >
                                <span className="button_text">Cancel</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
