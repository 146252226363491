// ----------------------------------------------------------------------
import React from "react";
import { Helmet } from "react-helmet-async";
import {
    useEffect,
    useState,
} from "react";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axiosInstance from "src/axiosInstance"
import CircularProgress from "@mui/material/CircularProgress";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import LiveSelect from 'react-select';
import { useLangDropDowns } from "src/hooks/useMockData";

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

import {
    Checkbox,
} from "@mui/material";
// sections
// mock
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Pagination from '../../../pagination/GlobalPagination'; // Adjust the path as necessary
import Scrollbar from "../../../components/scrollbar";
import styles from "../UserSettings.module.css";
import { notifySuccess, notifyError } from "src/hooks/useMockData";

export default function MyAccountTab() {
    const apiUrl = process.env.REACT_APP_NAME;
    const authUser = JSON.parse(localStorage.getItem('auth'))
    const userDetails = JSON.parse(localStorage.getItem('userDetails'))
    const [category, setCategory] = useState(0);
    const [save, setSave] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const langs = useLangDropDowns();
    const [language, setLanguage] = useState('English');
    const [languageArray, setLanguageArray] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [enableSetPassword, setEnableSetPassword] = useState(false);
    const [passwordExisted, setPasswordExisted] = useState(false);
    const pageSize = 8;
    const navigate = useNavigate();
    const [selectedLangOption, setSelectedLangOption] = useState(null);
    const [EnterPassword, setEnterPassword] = useState("");
    const [showEnterPassword, setShowEnterPassword] = React.useState(false);

    const [saveResponse1, setSaveResponse1] = useState('')
    const [saveResponse2, setSaveResponse2] = useState('')

    const [ConfPassword, setConfPassword] = useState("");
    const [showConfPassword, setShowConfPassword] = React.useState(false);

    const [CurrentPassword, setCurrentPassword] = useState("");
    const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);

    const [CurrentEditablePassword, setCurrentEditablePassword] = useState("");
    const [showCurrentEditablePassword, setShowCurrentEditablePassword] = React.useState(false);

    const [NewEditablePassword, setNewEditablePassword] = useState("");
    const [showNewEditablePassword, setShowNewEditablePassword] = React.useState(false);

    const [ConfirmEditablePassword, setConfirmEditablePassword] = useState("");
    const [showConfirmEditablePassword, setShowConfirmEditablePassword] = React.useState(false);
    const [KYCResponseObj, setKYCResponseObj] = useState(null);
    const [isObjUpdated, setIsObjUpdated] = useState(false);

    const [item, setItem] = useState({
        name: '',
        email: '',
        phone: '',
    });

    useEffect(() => {
        // authUser && authUser?.has_set_password ? authUser?.has_set_password : false
        if (userDetails && userDetails?.data?.has_set_password === 'YES') {
            // if(authUser && !authUser?.has_set_password) {
            setPasswordExisted(true)
        } else {
            setPasswordExisted(false)
        }
    }, [])


    const HTShowEnterPassword = () => setShowEnterPassword((show) => !show);
    const HTShowConfPassword = () => setShowConfPassword((show) => !show);
    const HTShowCurrentPassword = () => setShowCurrentPassword((show) => !show);
    const HTShowCurrentEditablePassword = () => setShowCurrentEditablePassword((show) => !show);
    const HTShowNewEditablePassword = () => setShowNewEditablePassword((show) => !show);
    const HTShowConfirmEditablePassword = () => setShowConfirmEditablePassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChangeSave = () => {
        setSave(!save);
    }

    useEffect(() => {
        // const langs = useLangDropDowns();
        setLanguageArray(langs);

        if (langs.length > 0) {
            const defaultLang = langs.find(lang => lang.name === "English");
            if (defaultLang) {
                const defaultOption = { value: defaultLang.key, label: defaultLang.name };
                setSelectedLangOption(defaultOption);
                setLanguage(defaultLang.key);
                // console.log("Default Language:", defaultOption);
            }
        }
    }, []);

    const handleLanguageChange = (selectedOption) => {
        setLanguage(selectedOption.value);
        setSelectedLangOption(selectedOption);
        // console.log("Selected Language:", selectedOption);
    };

    // const handleLanguageChange = (event) => {
    //     setLanguage(event.value);
    // };

    const handlePasswordExisted = () => {
        setEnableSetPassword(false);
        // setPasswordExisted(true);
    };

    useEffect(() => {
        setEnableSetPassword(!enableSetPassword);
        // setPasswordExisted(true);
    }, [passwordExisted])

    const handleUpdatePassword = () => {
        setEnableSetPassword(true);
        // setPasswordExisted(true);
    }

    const handleLedgerDetailsRoute = () => {
        navigate("/app/ledger-management/ledger-details/accounts");
    };

    const tableData = [
        {
            location: 'Nigeria',
            devices: 'Chrome - Mac OS',
            ip_addr: '102.88.84.186',
            time: '30 seconds ago',
        },
        {
            location: 'Nigeria',
            devices: 'Chrome - Mac OS',
            ip_addr: '102.88.84.186',
            time: '1 hour ago',
        },
    ];

    const totalResults = tableData.length;

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    // Calculate the data to display on the current page
    const currentTableData = tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };

    const handleChangeItem = (e) => {
        const { name, value } = e.target
        setItem(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleResetPassword = async () => {
        setIsLoading(true)
        const filter_payload = {}

        if (enableSetPassword && passwordExisted) {
            if (CurrentEditablePassword !== '') {
                filter_payload.current_password = CurrentEditablePassword
            }
            if (NewEditablePassword !== '') {
                filter_payload.password = NewEditablePassword
            }
            if (ConfirmEditablePassword !== '') {
                filter_payload.confirm_password = ConfirmEditablePassword
            }
        }
        try {
            const response = await axiosInstance.post(`/accounts/user/change-password?productName=${apiUrl}`, filter_payload)
            setSaveResponse2(response.data.status)
            handlePasswordExisted()
        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsLoading(false)
        }
    }

    const handleSetPassword = async () => {
        setIsLoading(true)
        const filter_payload = {}

        if (enableSetPassword && passwordExisted) {
            if (EnterPassword !== '') {
                filter_payload.password = EnterPassword
            }
            if (ConfPassword !== '') {
                filter_payload.confirm_password = ConfPassword
            }
        }
        try {
            const response = await axiosInstance.post(`/accounts/user/set-password?productName=${apiUrl}`, filter_payload)
            setSaveResponse2(response.data.status)
            handlePasswordExisted()
        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsLoading(false)
        }
    }

    const handleSaveItems = async () => {
        if (EnterPassword !== ConfPassword) {
            notifyError('Password does not match!');
            return;
        }

        setIsLoading(true)
        const filter_payload = {}

        if (item.name !== '') {
            filter_payload.name = item.name
        }
        if (item.email !== '') {
            filter_payload.email = item.email
        }
        if (item.phone !== '') {
            filter_payload.phone = item.phone
        }
        if (language !== '') {
            filter_payload.language = language
        }

        // if(enableSetPassword && passwordExisted) {
        if (EnterPassword !== '') {
            filter_payload.password = EnterPassword
        }
        if (ConfPassword !== '') {
            filter_payload.confirm_password = ConfPassword
        }
        // }

        // if(enableSetPassword && passwordExisted) {
        if (CurrentEditablePassword !== '') {
            filter_payload.current_password = CurrentEditablePassword
        }
        if (NewEditablePassword !== '') {
            filter_payload.password = NewEditablePassword
        }
        if (ConfirmEditablePassword !== '') {
            filter_payload.confirm_password = ConfirmEditablePassword
        }
        // }

        try {
            const response = await axiosInstance.post(`/user-account/update?productName=${apiUrl}`, filter_payload)
            // localStorage.setItem('auth', JSON.stringify(response.data.data?.data))
            // setSaveResponse1(response.data.status)
            notifySuccess(response.data.message)
            // getUserDetails()
            handleFetch()

            const auth = JSON.parse(localStorage.getItem('auth'));
            auth.name = response.data?.data?.data?.name;
            localStorage.setItem('auth', JSON.stringify(auth));

            // if(NewEditablePassword !== '' && ConfirmEditablePassword !== '' && ConfirmEditablePassword !== '') {
            //     if(enableSetPassword && passwordExisted) {
            //         handleResetPassword()
            //     } 
            //     if(enableSetPassword && !passwordExisted) {
            //         handleSetPassword()
            //     }
            // } else {
            // }
        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsLoading(false)
        }
    }

    const handleFetch = async () => {
        setIsObjUpdated(true)
        try {
            const response = await axiosInstance.get(`/accounts/user?productName=${apiUrl}`);
            const data = response.data.data;

            if (response.data.status === 'success') {
                setItem(prev => {
                    return {
                        ...prev,
                        name: data?.data?.name,
                        email: data?.data?.email,
                        phone: data?.data?.phone,
                    }
                })

                const langKey = data?.data?.language;
                const matchedlang = languageArray.find(lang => lang.name === langKey);
                if (matchedlang) {
                    setSelectedLangOption({ value: matchedlang.key, label: matchedlang.name });
                }

                localStorage.setItem('userDetails', JSON.stringify(data))
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsObjUpdated(false);
        }
    }

    //    const getUserDetails =  async () => {
    //     try {
    //         const response = await axiosInstance.get(`/accounts/${authUser && authUser.account_id ? authUser.account_id : null}?productName=${apiUrl}`)
    //         const data = response.data.data
    //         setItem(prev => {
    //             return {
    //                 ...prev,
    //                 name: data?.data?.user?.name,
    //                 email: data?.data?.user?.email,
    //                 phone: data?.data?.user?.phone,
    //             }
    //         })
    //         localStorage.setItem('userDetails', JSON.stringify(data))

    //     } catch (err) {
    //         if (err.response) {
    //             if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
    //               console.log(err.response.data.data.errorMessage);
    //             } else if (err.response.data && err.response.data.message) {
    //               console.log(err.response.data.message);
    //             } else {
    //               console.log('An unexpected error occurred');
    //             }
    //           } else {
    //             console.log('Network error or server not responding');
    //           }
    //     } finally {

    //     }
    //   }

    useEffect(() => {
        if (userDetails) {
            setItem(prev => {
                return {
                    ...prev,
                    name: userDetails?.data?.name,
                    email: userDetails?.data?.email,
                    phone: userDetails?.data?.phone,
                }
            })

            const langKey = userDetails?.data?.language;
            const matchedlang = languageArray.find(lang => lang.name === langKey);
            if (matchedlang) {
                setSelectedLangOption({ value: matchedlang.key, label: matchedlang.name });
            }
        }
    }, [])

    useEffect(() => {
        handleFetch();
    }, [])



    return (
        <>
            <div className="px-0 pt-4">
                <div className="row align-items-center mb-3">
                    <div className="col-sm-3">
                        <label htmlFor="name" className="fw-500 mb-0">Full Name</label>
                    </div>
                    <div className="col-sm-9">
                        <div style={{ width: '18em', maxWidth: '100%' }} className="input_fullWidth height_40">
                            <TextField
                                name="name"
                                required
                                type="text"
                                fullWidth
                                id="name"
                                placeholder="Full Name"
                                size="small"
                                defaultValue=''
                                value={item?.name}
                                onChange={handleChangeItem}
                            />
                        </div>
                    </div>
                </div>

                <div className="row align-items-center mb-3">
                    <div className="col-sm-3">
                        <label htmlFor="email" className="fw-500 mb-0">Email Address</label>
                    </div>
                    <div className="col-sm-9">
                        <div style={{ width: '18em', maxWidth: '100%' }} className="input_fullWidth height_40">
                            <TextField
                                name="email"
                                required
                                type="email"
                                fullWidth
                                id="email"
                                placeholder="Email"
                                size="small"
                                defaultValue=''
                                value={item?.email}
                                onChange={handleChangeItem}
                            />
                        </div>
                    </div>
                </div>

                <div className="row align-items-center mb-3">
                    <div className="col-sm-3">
                        <label htmlFor="phone" className="fw-500 mb-0">Phone Number</label>
                    </div>
                    <div className="col-sm-9">
                        <div style={{ width: '18em', maxWidth: '100%' }} className="input_fullWidth height_40">
                            <TextField
                                name="phone"
                                required
                                type="phone"
                                fullWidth
                                id="phone"
                                placeholder="Phone"
                                size="small"
                                value={item?.phone}
                                onChange={handleChangeItem}
                            />
                        </div>
                    </div>
                </div>

                <div className="row align-items-center mb-4">
                    <div className="col-sm-3">
                        <label htmlFor="email" className="fw-500 mb-0">Language</label>
                    </div>
                    <div className="col-sm-9">
                        <div style={{ width: '18em', maxWidth: '100%' }} className={`${styles.input_box} mb-0 input_fullWidth`}>
                            <Box
                                sx={{
                                    maxWidth: "100%",
                                    position: "relative",
                                }}
                            >
                                <div className="w-100 liveselect_noborderradius">
                                    <LiveSelect
                                        defaultValue={selectedLangOption}
                                        value={selectedLangOption}
                                        onChange={handleLanguageChange}
                                        options={languageArray.map((option) => ({
                                            value: option.key,
                                            label: option.name,
                                        }))}
                                    />
                                </div>

                                {/* <Select
                    labelId="language-select-label"
                    id="language-select"
                    size="small"
                    fullWidth
                    value={language}
                    name="language"
                    required
                    onChange={handleLanguageChange}
                >
                    <MenuItem value="select">Select Language</MenuItem>
                    {languageOptions.map(option => (
                    <MenuItem className="fw-500 text-dark" key={option.key} value={option.key}>{option.name}</MenuItem>
                    ))}
                </Select> */}
                            </Box>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center mb-3 pt-sm-5">
                    <div className="col-sm-7 col-md-3">
                        <div className="">
                            {passwordExisted ? (
                                <h6 className="page_title fw-500 fs-14 text-dark mb-1">Update Password</h6>
                            ) : (
                                <h6 className="page_title fw-500 fs-14 text-dark mb-1">Set Up Password</h6>
                            )}
                            <p style={{ color: '#4B5563', fontSize: '12px', gap: '8px' }} className="mb-0 d-flex align-items-center fw-500 text-dark">
                                {passwordExisted ? (
                                    <span className="" style={{ color: 'rgba(102, 112, 133, 1)' }}>
                                        Update your password here
                                    </span>
                                ) : (
                                    <span className="" style={{ color: 'rgba(102, 112, 133, 1)' }}>
                                        Create your password here
                                    </span>
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-5 col-md-9">
                        <div className="" style={{ width: '18em', maxWidth: '100%' }}>
                            <div className="fit-content  ml-sm-auto mt-3 mt-sm-0">
                                {/* {enableSetPassword && !passwordExisted &&
                                    (
                                        <>
                                            <button
                                                className="btn border btn-primary"
                                                type="button"
                                                onClick={handlePasswordExisted}
                                            >
                                                <span className="button_text text-white">Confirm</span>
                                            </button>
                                            <button
                                                className="btn border ml-2"
                                                type="button"
                                                onClick={() => setEnableSetPassword(false)}
                                            >
                                                <span className="button_text">Cancel</span>
                                            </button>

                                        </>
                                    )} */}
                                {/* {!enableSetPassword && !passwordExisted &&

                                    (
                                        <>
                                            <button
                                                className="btn border btn-primary"
                                                type="button"
                                                onClick={() => setEnableSetPassword(true)}
                                            >
                                                <span className="button_text text-white">Set Password</span>
                                            </button>
                                        </>
                                    )
                                } */}

                                {!enableSetPassword && passwordExisted && (
                                    <>
                                        <button
                                            className="btn border"
                                            type="button"
                                            onClick={handleUpdatePassword}
                                        >
                                            <span className="button_text ">Update Password</span>
                                        </button>
                                    </>
                                )}

                                {/* {enableSetPassword && !passwordExisted &&
                                    <div className="fit-content">
                                        <button
                                            className="btn border btn-primary px-4"
                                            type="button"
                                            onClick={handlePasswordExisted}
                                        >
                                            <span className="button_text text-white">Set Password</span>
                                        </button>
                                    </div>
                                } */}

                                {/* {enableSetPassword && passwordExisted && (
                                    <>
                                        <div className="fit-content">
                                            <button
                                                className="btn border btn-primary px-4"
                                                type="button"
                                                onClick={handlePasswordExisted}
                                            >
                                                <span className="button_text text-white">Confirm</span>
                                            </button>
                                        </div>
                                    </>
                                )} */}

                                {/* {enableSetPassword && passwordExisted && (
                                    <>
                                        <button
                                            className="btn border btn-primary"
                                            type="button"
                                            onClick={handlePasswordExisted}
                                        >
                                            <span className="button_text text-white">Confirm</span>
                                        </button>
                                        <button
                                            className="btn border ml-2"
                                            type="button"
                                            onClick={() => setEnableSetPassword(false)}
                                        >
                                            <span className="button_text">Cancel</span>
                                        </button>
                                    </>
                                )
                                } */}
                            </div>
                        </div>
                    </div>
                </div>


                {passwordExisted && !enableSetPassword && (
                    <>
                        <div className="row align-items-center mb-3">
                            <div className="col-sm-3">
                                <label htmlFor="conf_password" className="fw-500 mb-0">Current Password</label>
                            </div>
                            <div className="col-sm-9">
                                <div style={{ width: '18em', maxWidth: '100%' }} className="disabled_bg pwIcon input_fullWidth height_40">
                                    <div className={`${styles.input_box} mb-0`}>
                                        <label htmlFor=""></label>
                                        <FormControl sx={{ width: '100%' }} variant="outlined">
                                            <div style={{ display: 'none' }}>
                                                <input type="text" name="fakeusernameremembered" autoComplete="off" />
                                                <input type="password" name="fakepasswordremembered" autoComplete="off" />
                                            </div>
                                            <OutlinedInput
                                                placeholder='*****'
                                                id="conf_password"
                                                type={showCurrentPassword ? 'text' : 'password'}
                                                autoComplete="new-password"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={HTShowCurrentPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showCurrentPassword ? <VisibilityOff style={{ fontSize: '30px' }} /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                readOnly={true}
                                                disabled={true}
                                                value={CurrentPassword}
                                                onChange={(e) => setCurrentPassword(e.target.value)}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="row align-items-center mb-3">
                            <div className="col-sm-3">
                                <label htmlFor="currpassword" className="fw-500 mb-0">Current Password</label>
                            </div>
                            <div className="col-sm-9">
                                <div style={{ width: '18em', maxWidth: '100%' }} className="input_fullWidth height_40">
                                    <TextField
                                        name="currpassword"
                                        required
                                        type="password"
                                        fullWidth
                                        id="currpassword"
                                        placeholder="*****"
                                        size="small"
                                        defaultValue={`*****`}
                                        disabled={passwordExisted}
                                    />
                                </div>
                            </div>
                        </div> */}
                    </>
                )}

                {enableSetPassword && !passwordExisted && (
                    <>
                        <div className="row align-items-center mb-3">
                            <div className="col-sm-3">
                                <label htmlFor="enter_password" className="fw-500 mb-0">Enter Password</label>
                            </div>
                            <div className="col-sm-9">
                                <div style={{ width: '18em', maxWidth: '100%' }} className="input_fullWidth height_40">
                                    <div className={`${styles.input_box} mb-0`}>
                                        <label htmlFor=""></label>
                                        <FormControl sx={{ width: '100%' }} variant="outlined">
                                            <div style={{ display: 'none' }}>
                                                <input type="text" name="fakeusernameremembered" autoComplete="off" />
                                                <input type="password" name="fakepasswordremembered" autoComplete="off" />
                                            </div>
                                            <OutlinedInput
                                                placeholder=''
                                                id="enter_password"
                                                type={showEnterPassword ? 'text' : 'password'}
                                                autoComplete="new-password"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={HTShowEnterPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showEnterPassword ? <VisibilityOff style={{ fontSize: '30px' }} /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                value={EnterPassword}
                                                onChange={(e) => setEnterPassword(e.target.value)}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center mb-3">
                            <div className="col-sm-3">
                                <label htmlFor="conf_password" className="fw-500 mb-0">Confirm Password</label>
                            </div>
                            <div className="col-sm-9">
                                <div style={{ width: '18em', maxWidth: '100%' }} className="input_fullWidth height_40">
                                    <div className={`${styles.input_box} mb-0`}>
                                        <label htmlFor=""></label>
                                        <FormControl sx={{ width: '100%' }} variant="outlined">
                                            <div style={{ display: 'none' }}>
                                                <input type="text" name="fakeusernameremembered" autoComplete="off" />
                                                <input type="password" name="fakepasswordremembered" autoComplete="off" />
                                            </div>
                                            <OutlinedInput
                                                placeholder=''
                                                id="conf_password"
                                                type={showConfPassword ? 'text' : 'password'}
                                                autoComplete="new-password"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={HTShowConfPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showConfPassword ? <VisibilityOff style={{ fontSize: '30px' }} /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                value={ConfPassword}
                                                onChange={(e) => setConfPassword(e.target.value)}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {enableSetPassword && passwordExisted && (
                    <>
                        <div className="row align-items-center mb-3">
                            <div className="col-sm-3">
                                <label htmlFor="conf_password" className="fw-500 mb-0">Current Password</label>
                            </div>
                            <div className="col-sm-9">
                                <div style={{ width: '18em', maxWidth: '100%' }} className="input_fullWidth height_40">
                                    <div className={`${styles.input_box} mb-0`}>
                                        <label htmlFor=""></label>
                                        <FormControl sx={{ width: '100%' }} variant="outlined">
                                            <div style={{ display: 'none' }}>
                                                <input type="text" name="fakeusernameremembered" autoComplete="off" />
                                                <input type="password" name="fakepasswordremembered" autoComplete="off" />
                                            </div>
                                            <OutlinedInput
                                                placeholder='*****'
                                                id="conf_password"
                                                type={showCurrentEditablePassword ? 'text' : 'password'}
                                                autoComplete="new-password"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={HTShowCurrentEditablePassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showCurrentEditablePassword ? <VisibilityOff style={{ fontSize: '30px' }} /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                value={CurrentEditablePassword}
                                                onChange={(e) => setCurrentEditablePassword(e.target.value)}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center mb-3">
                            <div className="col-sm-3">
                                <label htmlFor="conf_password" className="fw-500 mb-0">New Password</label>
                            </div>
                            <div className="col-sm-9">
                                <div style={{ width: '18em', maxWidth: '100%' }} className="input_fullWidth height_40">
                                    <div className={`${styles.input_box} mb-0`}>
                                        <label htmlFor=""></label>
                                        <FormControl sx={{ width: '100%' }} variant="outlined">
                                            <div style={{ display: 'none' }}>
                                                <input type="text" name="fakeusernameremembered" autoComplete="off" />
                                                <input type="password" name="fakepasswordremembered" autoComplete="off" />
                                            </div>
                                            <OutlinedInput
                                                placeholder='*****'
                                                id="conf_password"
                                                type={showNewEditablePassword ? 'text' : 'password'}
                                                autoComplete="new-password"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={HTShowNewEditablePassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showNewEditablePassword ? <VisibilityOff style={{ fontSize: '30px' }} /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                value={NewEditablePassword}
                                                onChange={(e) => setNewEditablePassword(e.target.value)}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center mb-3">
                            <div className="col-sm-3">
                                <label htmlFor="conf_password" className="fw-500 mb-0">Confirm Password</label>
                            </div>
                            <div className="col-sm-9">
                                <div style={{ width: '18em', maxWidth: '100%' }} className="input_fullWidth height_40">
                                    <div className={`${styles.input_box} mb-0`}>
                                        <label htmlFor=""></label>
                                        <FormControl sx={{ width: '100%' }} variant="outlined">
                                            <div style={{ display: 'none' }}>
                                                <input type="text" name="fakeusernameremembered" autoComplete="off" />
                                                <input type="password" name="fakepasswordremembered" autoComplete="off" />
                                            </div>
                                            <OutlinedInput
                                                placeholder='*****'
                                                id="conf_password"
                                                type={showConfirmEditablePassword ? 'text' : 'password'}
                                                autoComplete="new-password"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={HTShowConfirmEditablePassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showConfirmEditablePassword ? <VisibilityOff style={{ fontSize: '30px' }} /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                value={ConfirmEditablePassword}
                                                onChange={(e) => setConfirmEditablePassword(e.target.value)}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                {
                                    (NewEditablePassword && ConfirmEditablePassword !== '') && NewEditablePassword !== ConfirmEditablePassword &&
                                    <>
                                        <i class="bi bi-exclamation-circle-fill text-danger fs-12"></i>{' '}
                                        <span className="text-danger fs-12">Password Not Match</span>
                                    </>
                                }
                            </div>
                        </div>
                    </>

                )}

                <div className="save_btn_wrap mt-5 mb-4">
                    <button
                        className="btn border btn-primary"
                        type="button"
                        onClick={() => handleSaveItems()}
                        disabled={isLoading}
                    >
                        <span className="button_text text-white">
                            Save Changes
                            {
                                isLoading &&
                                <CircularProgress
                                    style={{ color: "#fff" }}
                                    className="text-white ml-1"
                                    size="0.8rem"
                                />
                            }
                        </span>
                    </button>
                </div>

                <div className="w-100 mb-4 pb-5">
                    {/* {enableSetPassword || passwordExisted ? (
                        <div className="checkbox_div">
                            <Checkbox id="schedule" checked={save} onClick={handleChangeSave} style={{}} />
                            <label htmlFor="schedule" className="ml-2 fs-14 text-dar cursor-pointer fw-500">Use password to login</label>
                        </div>
                    ) : (
                        <span></span>
                    )} */}


                </div>

                {/* <div className="px-0 pb-5" style={{ width: '50em', maxWidth: '100%' }}>
                    <div className="row align-items-center mb-3 pt-3">
                        <div className="col-sm-7">
                            <div className="">
                                <h6 className="page_title fw-500 fs-14 text-dark mb-1">Login Sessions</h6>
                                <p style={{ color: '#4B5563', fontSize: '12px', gap: '8px' }} className="mb-0 d-flex align-items-center fw-500 text-dark">
                                    <span className="" style={{ color: 'rgba(102, 112, 133, 1)' }}>
                                        Places where you're logged into OnePoint
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div>
                                <div className="fit-content ml-sm-auto mt-3 mt-sm-0">
                                    <button
                                        className="btn border"
                                        type="button"
                                    >
                                        <span className="button_text">Sign out all other session</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ marginTop: "0px", borderRadius: "10px" }} className="p-0">
                        <Scrollbar>
                            <div
                                className={`${styles.overflow_table} table-responsive  pt- pb-3 pl-0 pr-0`}
                            >
                                <table
                                    className={`${styles.table} table-hover table-striped`}
                                    style={{ minWidth: "40em" }}
                                >
                                    <thead className={`${styles.thead}`}>
                                        <tr>
                                            <th className="text-muted fs-13 fw-600 th_width" style={{ minWidth: "6%" }}>
                                                Location {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </th>
                                            <th className="text-muted fs-13 fw-600 th_width">Devices {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></th>
                                            <th className="text-muted fs-13 fw-600 th_width">IP Address {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></th>
                                            <th className="text-muted fs-13 fw-600 th_width" style={{ minWidth: '280px', }}>Time {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {currentTableData.map((item, index) => (
                                            <tr key={index} className={`${styles.tr} table-fonts`}>
                                                <td>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <span>
                                                                <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clipPath="url(#clip0_4649_54367)">
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M0 0H16V12H0V0Z" fill="white" />
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.665 0H16V12H10.665V0ZM0 0H5.3325V12H0V0Z" fill="#008753" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_4649_54367">
                                                                            <rect width="16" height="12" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </span>
                                                            <span className="ml-2 fw-500">
                                                                {item.location}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="fw-500">
                                                        {item.devices}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="fw-500">
                                                        {item.ip_addr}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="fw-500">
                                                        {item.time}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Scrollbar>

                        <Pagination
                            currentPage={currentPage}
                            totalResults={totalResults}
                            pageSize={pageSize}
                            onPageChange={onPageChange}
                        />
                    </div>
                </div> */}
            </div>
        </>
    )
}