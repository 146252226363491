import React from "react";
import Chart from "react-apexcharts";

const InvoicesChart = () => {
  const chartOptions = {
    chart: {
      type: "bar",
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "200%",
      },
    },
    colors: ["rgba(17, 24, 39, 1)", "rgba(217, 217, 217, 1)", "rgba(229, 231, 235, 1)"],
    xaxis: {
      categories: [""],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: false,
    },
  };

  const chartSeries = [
    {
      name: "Paid",
      data: [60],
    },
    {
      name: "Open",
      data: [20],
    },
    {
      name: "Due",
      data: [20],
    },
  ];

  return (
    <div className="w-100 h-100">
      

      
        <div className="chart horizontal_invoice_chart position-relative" style={{ width: "100%", maxWidth: "100%" }}>
        <div className="floating_chart_wrap border"></div>
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={80}
          />
        </div>
        <div className="d-flex justify-content-between align-items-end">
          <div className="px-2">
          <div
            className="d-flex flex-column justify-content-between align-items-start px-4"
            style={{ width: "100%", maxWidth: "100%" }}
          >
            <span className="fs-12 fw-500">Invoice Sent</span>
            <span className="fs-25 fw-600 text-dark">388</span>
          </div>
        </div>

        <ul className="list-unstyled pb-2 px-3 px-sm-4 px-lg-5 d-flex">
          <li className="cursor-pointer d-flex align-items-center mr-3">
            <p className="mb-0 d-flex align-items-center">
              <span
                className="legend-color d-block mb-2"
                style={{
                  backgroundColor: "rgba(17, 24, 39, 1)",
                  width: "10px",
                  height: "10px",
                  borderRadius: "6px",
                }}
              ></span>
              <span className="ml-2 d-block mb-2 fs-11 fw-500">Paid</span>
            </p>
          </li>
          <li className="cursor-pointer d-flex align-items-center mr-3">
            <p className="mb-0 d-flex align-items-center">
              <span
                className="legend-color d-block mb-2"
                style={{
                  backgroundColor: "rgba(217, 217, 217, 1)",
                  width: "10px",
                  height: "10px",
                  borderRadius: "6px",
                }}
              ></span>
              <span className="ml-2 d-block mb-2 fs-11 fw-500">Open</span>
            </p>
          </li>
          <li className="cursor-pointer d-flex align-items-center">
          <p className="mb-0 d-flex align-items-center">
              <span
                className="legend-color d-block mb-2"
                style={{
                  backgroundColor: "rgba(229, 231, 235, 1)",
                  width: "10px",
                  height: "10px",
                  borderRadius: "6px",
                }}
              ></span>
              <span className="ml-2 d-block mb-2 fs-11 fw-500">Due</span>
            </p>
          </li>
        </ul>
        </div>
    </div>
  );
};

export default InvoicesChart;
