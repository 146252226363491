import React, { useEffect, useState } from 'react';
import styles from "../Home.module.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
    Link,
    //   Avatar,
} from "@mui/material";
import bus from 'src/bus';
import {
    DateTimeFormat,
    formatDateToDMY,
    fullNameDateFormat,
    formatDateToDMYWithHyphen
} from "src/hooks/useDateFormat";

export default function OverallDashboard({isPending, walletData, scheduleData}) {
    const [showText, setShowText] = useState(false);
    const [showText02, setShowText02] = useState(false);
    const [currType, setCurrType] = useState("ngn");
    const navigate = useNavigate();


    const handleCurrTypeChange = (event) => {
        setCurrType(event.target.value);
    };
    const changeType = () => {
        setShowText(!showText);
    };
    const changeType02 = () => {
        setShowText02(!showText02);
    };

    const [scheduledPayments, setScheduledPayments] = useState([
        {
            account_name: 'Michael Joseph',
            next_schedule_date: '24 Mar,2024 11:54 pm',
            amount: '2400',
            currency: 'NGN',
        },
        {
            account_name: 'Michael Joseph',
            next_schedule_date: '24 Mar,2024 11:54 pm',
            amount: '2400',
            currency: 'NGN',
        },
        {
            account_name: 'Michael Joseph',
            next_schedule_date: '24 Mar,2024 11:54 pm',
            amount: '2400',
            currency: 'NGN',
        },
    ]);

    const recentTransactions = [
        {
            image: '/assets/icons/inward_trn.svg',
            title: 'Sales of Goods',
            date: '24 Mar,2024 11:54 pm',
            amount: '450.65',
            currency: '$',
            profit_loss: '+'
        },
        {
            image: '/assets/icons/outward_trn.svg',
            title: 'Salaries and Wages',
            date: '24 Mar,2024 11:54 pm',
            amount: '450.65',
            currency: '$',
            profit_loss: '-'
        },
        {
            image: '/assets/icons/inward_trn.svg',
            title: 'Sales of Goods',
            date: '24 Mar,2024 11:54 pm',
            amount: '450.65',
            currency: '$',
            profit_loss: '+'
        },
        {
            image: '/assets/icons/inward_trn.svg',
            title: 'Sales of Goods',
            date: '24 Mar,2024 11:54 pm',
            amount: '450.65',
            currency: '$',
            profit_loss: '+'
        },
    ];

    const handleRouteClick = (val) => {
        if(val === 'wallet') {
            navigate('/app/wallet')
        } else if(val === 'create-wallet') {
            localStorage.setItem('toCreate', JSON.stringify('create-wallet'))
            navigate('/app/wallet')
        } else if(val === 'create-plink') {
            navigate('/app/payment/payment-links/create')
        } else if(val === 'create-invoice') {
            navigate('/app/payment/invoice/create')
        } else {
            navigate('/app/payment/transfers/single')
        }
    }

    const onFundWallet = (ref) => {
        localStorage.setItem('toFund', JSON.stringify(ref))
        navigate('/app/wallet/transactions')
    }

    const onFundWithdraw = (ref) => {
        localStorage.setItem('toWithdraw', JSON.stringify(ref))
        navigate('/app/wallet/transactions')
    }

    useEffect(() => {
        setScheduledPayments(scheduleData)
    }, [scheduleData])


    return (
        <>
            <div className="px-0 px-md-2 dashboard_measured_wrapper" style={{}}>
                <div className=" bg-white w-100 pt-4"
                    style={{}}>
                    <div className={`${styles.trfpage_head} w-100`}>
                        <h2 className="text-left">
                            <span className='fw-700'>Welcome to OnePoint</span>
                        </h2>
                        <p className="mb-0 fw-500 text-left"><span className='fw-500 fs-16'>Here is a quick summary of whats happening with your business</span></p>
                    </div>

                    <div className="row mt-4 pb-5">
                        <div className="col-sm-8 col-lg-9 mb-4">
                            <div className="card" style={{
                                border: '1px solid rgba(229, 231, 235, 1)',
                                background: 'rgba(249, 250, 251, 1)',
                                borderRadius: '10px',
                            }}>
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center ">
                                        <h5 className='fs-16 text-dark fw-600 mb-0'>Top Wallets</h5>
                                        <button className="btn d-flex align-items-center" onClick={() => handleRouteClick('wallet')}>
                                            <span className='fw-500 mr-2'>
                                                View wallet list
                                            </span>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <mask id="mask0_8649_90068" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="2" y="0" width="8" height="12">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.72604 0.469875C2.42502 0.762757 2.42502 1.23761 2.72604 1.53049L7.31979 5.99999L2.72604 10.4695C2.42502 10.7624 2.42502 11.2372 2.72604 11.5301C3.02707 11.823 3.51512 11.823 3.81615 11.5301L9.5 5.99999L3.81615 0.469875C3.51512 0.176993 3.02707 0.176993 2.72604 0.469875Z" fill="#006FFD" />
                                                </mask>
                                                <g mask="url(#mask0_8649_90068)">
                                                    <rect y="-0.000732422" width="11.9995" height="11.9995" fill="#374151" />
                                                </g>
                                            </svg>
                                        </button>
                                    </div>

                                    <div className="row pt-4 py-md-4">
                                        <div className="col-md-6 w-100 mb-4 mb-md-0">
                                            <div className="mb-3 mb-lg-0">
                                                <div className="d-flex align-items-center">
                                                    {/* {walletData?.highestNgnWallet?.currency === 'NGN' ?
                                                        <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H23V17.25H0V0Z" fill="white" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3309 0H23V17.25H15.3309V0ZM0 0H7.66547V17.25H0V0Z" fill="#008753" />
                                                        </svg> : walletData?.highestNgnWallet?.currency === 'CNY' ?
                                                        <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_8649_90010)">
                                                            <path d="M0 0L23 0V17.25H0L0 0Z" fill="#EE1C25" />
                                                            <path d="M2.76155 6.38251L4.31403 1.72501L5.86651 6.38251L1.72656 3.53626H6.9015L2.76155 6.38251Z" fill="#FFFF00" />
                                                            <path d="M9.49423 1.81426L7.89653 2.16832L8.96226 0.926444L8.85773 2.59785L7.97111 1.11815L9.49423 1.81426Z" fill="#FFFF00" />
                                                            <path d="M11.1055 3.8578L9.49551 3.56449L10.9595 2.83315L10.2149 4.33319L9.97154 2.62544L11.1055 3.8578Z" fill="#FFFF00" />
                                                            <path d="M10.8707 6.72476L9.52036 5.80032L11.1553 5.72966L9.86367 6.79559L10.338 5.13708L10.8707 6.72476Z" fill="#FFFF00" />
                                                            <path d="M8.84162 8.59773L7.95309 7.22348L9.48845 7.78976L7.88537 8.27412L8.96343 6.9275L8.84162 8.59773Z" fill="#FFFF00" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_8649_90010">
                                                                <rect width="23" height="17.25" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg> : <></>
                                                    } */}
                                                    <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H23V17.25H0V0Z" fill="white" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3309 0H23V17.25H15.3309V0ZM0 0H7.66547V17.25H0V0Z" fill="#008753" />
                                                    </svg>
                                                    <span className="d-block fs-13 text-dark fw-500 ml-2">{walletData?.highestNgnWallet?.name || '---'}</span>
                                                </div>

                                                <div className="d-flex flex-wrap align-items-center mt-2">
                                                    <div className=" border-0 position-relative d-inline-block" style={{ width: 'fit-content', minWidth: '9em', maxWidth: '100%' }}>
                                                        {!showText ? (
                                                            <p className="mb-0 fs-16 fw-500 py-1 text-dark fs-24" style={{ transform: 'translateY(5px)' }}>* * * * *</p>
                                                        ) : (
                                                            <p className="mb-0 fs-16 fw-500 py-1 text-dark fs-24">₦{walletData?.highestNgnWallet?.balance || '0.0'}</p>
                                                        )}

                                                        <div className="icon btn mt-2 position-absolute" onClick={changeType}
                                                            style={{
                                                                right: '3px',
                                                                top: '50%',
                                                                transform: 'translate(0%, -70%)'
                                                            }}>
                                                            {!showText && (
                                                                <>
                                                                    <svg style={{ transform: 'scale(0.8)' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M17.8201 5.76998C16.0701 4.44998 14.0701 3.72998 12.0001 3.72998C8.47009 3.72998 5.18009 5.80998 2.89009 9.40998C1.99009 10.82 1.99009 13.19 2.89009 14.6C3.68009 15.84 4.60009 16.91 5.60009 17.77" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M9.47 14.53L2 22" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M22 2L14.53 9.47" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                    {/* <img src="/assets/icons/eye.svg" alt="" width={30} /> */}
                                                                </>
                                                            )}
                                                            {showText && (
                                                                <>
                                                                    <svg style={{ transform: 'scale(0.8)' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M12.0001 20.27C15.5301 20.27 18.8201 18.19 21.1101 14.59C22.0101 13.18 22.0101 10.81 21.1101 9.39997C18.8201 5.79997 15.5301 3.71997 12.0001 3.71997C8.47009 3.71997 5.18009 5.79997 2.89009 9.39997C1.99009 10.81 1.99009 13.18 2.89009 14.59C5.18009 18.19 8.47009 20.27 12.0001 20.27Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>

                                                                    {/* <img src="/assets/icons/eye-off.svg" alt="" width={30} /> */}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex">
                                                        <button className='btn btn-primary px-2 mr-2' disabled={walletData?.highestNgnWallet === undefined} onClick={() => onFundWallet(walletData?.highestNgnWallet?.wallet_ref)}>
                                                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4.22266 11.8501L13.5568 2.51593" stroke="white" stroke-width="0.727338" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M4.22266 5.00282V11.8501H11.0699" stroke="white" stroke-width="0.727338" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            <span className='text-dark fs-11 text-white ml-1 pr-1'>
                                                                Top Up
                                                            </span>
                                                        </button>
                                                        <button className='btn' style={{
                                                            border: '1px solid rgba(0, 0, 0, 1)',
                                                            background: 'rgba(53, 51, 51, 0.1)',
                                                        }} disabled={walletData?.highestNgnWallet === undefined} onClick={() => onFundWithdraw(walletData?.highestNgnWallet?.wallet_ref)}>
                                                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.69141 2.87962L13.0256 12.2138" stroke="black" stroke-width="0.727338" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M3.69141 9.7269V2.87962H10.5387" stroke="black" stroke-width="0.727338" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            <span className='text-dark fs-11 text-white ml-1'>
                                                                Withdraw
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-md-6 w-100">
                                            <div className="mb-3 mb-lg-0">
                                                <div className="d-flex align-items-center">
                                                    {/* {walletData?.highestCnyWallet?.currency === 'NGN' ?
                                                        <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H23V17.25H0V0Z" fill="white" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3309 0H23V17.25H15.3309V0ZM0 0H7.66547V17.25H0V0Z" fill="#008753" />
                                                        </svg> : walletData?.highestCnyWallet?.currency === 'CNY' ?
                                                        <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_8649_90010)">
                                                                <path d="M0 0L23 0V17.25H0L0 0Z" fill="#EE1C25" />
                                                                <path d="M2.76155 6.38251L4.31403 1.72501L5.86651 6.38251L1.72656 3.53626H6.9015L2.76155 6.38251Z" fill="#FFFF00" />
                                                                <path d="M9.49423 1.81426L7.89653 2.16832L8.96226 0.926444L8.85773 2.59785L7.97111 1.11815L9.49423 1.81426Z" fill="#FFFF00" />
                                                                <path d="M11.1055 3.8578L9.49551 3.56449L10.9595 2.83315L10.2149 4.33319L9.97154 2.62544L11.1055 3.8578Z" fill="#FFFF00" />
                                                                <path d="M10.8707 6.72476L9.52036 5.80032L11.1553 5.72966L9.86367 6.79559L10.338 5.13708L10.8707 6.72476Z" fill="#FFFF00" />
                                                                <path d="M8.84162 8.59773L7.95309 7.22348L9.48845 7.78976L7.88537 8.27412L8.96343 6.9275L8.84162 8.59773Z" fill="#FFFF00" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_8649_90010">
                                                                    <rect width="23" height="17.25" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg> : <></>
                                                    } */}
                                                    <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_8649_90010)">
                                                            <path d="M0 0L23 0V17.25H0L0 0Z" fill="#EE1C25" />
                                                            <path d="M2.76155 6.38251L4.31403 1.72501L5.86651 6.38251L1.72656 3.53626H6.9015L2.76155 6.38251Z" fill="#FFFF00" />
                                                            <path d="M9.49423 1.81426L7.89653 2.16832L8.96226 0.926444L8.85773 2.59785L7.97111 1.11815L9.49423 1.81426Z" fill="#FFFF00" />
                                                            <path d="M11.1055 3.8578L9.49551 3.56449L10.9595 2.83315L10.2149 4.33319L9.97154 2.62544L11.1055 3.8578Z" fill="#FFFF00" />
                                                            <path d="M10.8707 6.72476L9.52036 5.80032L11.1553 5.72966L9.86367 6.79559L10.338 5.13708L10.8707 6.72476Z" fill="#FFFF00" />
                                                            <path d="M8.84162 8.59773L7.95309 7.22348L9.48845 7.78976L7.88537 8.27412L8.96343 6.9275L8.84162 8.59773Z" fill="#FFFF00" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_8649_90010">
                                                                <rect width="23" height="17.25" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <span className="d-block fs-13 text-dark fw-500 ml-2">{walletData?.highestCnyWallet?.name || '---'}</span>
                                                </div>

                                                <div className="d-flex flex-wrap align-items-center mt-2">
                                                    <div className=" border-0 position-relative d-inline-block" style={{ width: 'fit-content', minWidth: '9em', maxWidth: '100%' }}>
                                                        {!showText02 ? (
                                                            <p className="mb-0 fs-16 fw-500 py-1 text-dark fs-24" style={{ transform: 'translateY(5px)' }}>* * * * *</p>
                                                        ) : (
                                                            <p className="mb-0 fs-16 fw-500 py-1 text-dark fs-24">₦{walletData?.highestCnyWallet?.balance || '0.0'}</p>
                                                        )}

                                                        <div className="icon btn mt-2 position-absolute" onClick={changeType02}
                                                            style={{
                                                                right: '3px',
                                                                top: '50%',
                                                                transform: 'translate(0%, -70%)'
                                                            }}>
                                                            {!showText02 && (
                                                                <>
                                                                    <svg style={{ transform: 'scale(0.8)' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M17.8201 5.76998C16.0701 4.44998 14.0701 3.72998 12.0001 3.72998C8.47009 3.72998 5.18009 5.80998 2.89009 9.40998C1.99009 10.82 1.99009 13.19 2.89009 14.6C3.68009 15.84 4.60009 16.91 5.60009 17.77" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M9.47 14.53L2 22" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M22 2L14.53 9.47" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                    {/* <img src="/assets/icons/eye.svg" alt="" width={30} /> */}
                                                                </>
                                                            )}
                                                            {showText02 && (
                                                                <>
                                                                    <svg style={{ transform: 'scale(0.8)' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M12.0001 20.27C15.5301 20.27 18.8201 18.19 21.1101 14.59C22.0101 13.18 22.0101 10.81 21.1101 9.39997C18.8201 5.79997 15.5301 3.71997 12.0001 3.71997C8.47009 3.71997 5.18009 5.79997 2.89009 9.39997C1.99009 10.81 1.99009 13.18 2.89009 14.59C5.18009 18.19 8.47009 20.27 12.0001 20.27Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>

                                                                    {/* <img src="/assets/icons/eye-off.svg" alt="" width={30} /> */}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="d-inline-block">
                                                        <button className='btn btn-primary px-2 mr-2' disabled={walletData?.highestCnyWallet === undefined || !walletData?.highestCnyWallet} onClick={() => onFundWallet(walletData?.highestCnyWallet?.wallet_ref)}>
                                                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4.22266 11.8501L13.5568 2.51593" stroke="white" stroke-width="0.727338" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M4.22266 5.00282V11.8501H11.0699" stroke="white" stroke-width="0.727338" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            <span className='text-dark fs-11 text-white ml-1 pr-1'>
                                                                Top Up
                                                            </span>
                                                        </button>
                                                        <button className='btn' style={{
                                                            border: '1px solid rgba(0, 0, 0, 1)',
                                                            background: 'rgba(53, 51, 51, 0.1)',
                                                        }} disabled={walletData?.highestCnyWallet === undefined || !walletData?.highestCnyWallet} onClick={() => onFundWithdraw(walletData?.highestCnyWallet?.wallet_ref)}>
                                                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.69141 2.87962L13.0256 12.2138" stroke="black" stroke-width="0.727338" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M3.69141 9.7269V2.87962H10.5387" stroke="black" stroke-width="0.727338" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            <span className='text-dark fs-11 text-white ml-1'>
                                                                Withdraw
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-4 col-lg-3 mb-4">
                            <div className="card h-100" style={{
                                border: '1px solid rgba(229, 231, 235, 1)',
                                background: 'rgba(249, 250, 251, 1)',
                                borderRadius: '10px',
                            }}>
                                <div className="card-body h-100 d-flex flex-column justify-content-between align-items-start">
                                    <div className="d-flex justify-content-between align-items-center ">
                                        <h5 className='fs-16 mb-0 text-dark fw-600 text-capitalize'>Transact across multiple currencies</h5>
                                    </div>

                                    <div className="fit-content d-flex align-items-center py-4 py-sm-3 mx-auto mx-sm-0">
                                        <svg className='position-relative' style={{ zIndex: '10' }} width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_8701_91827" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="34" height="34">
                                                <circle cx="16.987" cy="16.987" r="16.987" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_8701_91827)">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.6312 1.51236L40.5304 1.51236V31.6205H24.6312V1.51236ZM-7.15234 1.51236L8.73943 1.51236L8.73943 31.6205H-7.15234L-7.15234 1.51236Z" fill="#008753" />
                                            </g>
                                        </svg>

                                        <svg className='position-relative' style={{ transform: 'translateX(-10px)', zIndex: '0' }} width="40" height="35" viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g filter="url(#filter0_d_8701_91821)">
                                                <mask id="mask0_8701_91821" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="5" y="0" width="35" height="35">
                                                    <circle cx="22.0143" cy="17.7449" r="16.987" fill="#D9D9D9" />
                                                </mask>
                                                <g mask="url(#mask0_8701_91821)">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M-2.12305 0.757965L45.5597 0.757965V36.52H-2.12305L-2.12305 0.757965Z" fill="white" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M29.6605 0.757965L45.5597 0.757965V36.52H29.6605V0.757965ZM-2.12305 0.757965L13.7687 0.757965L13.7687 36.52H-2.12305L-2.12305 0.757965Z" fill="#008753" />
                                                    <g clip-path="url(#clip0_8701_91821)">
                                                        <path d="M-0.0996094 4.05258L45.0106 -3.49041L50.6679 30.3423L5.55763 37.8853L-0.0996094 4.05258Z" fill="#EE1C25" />
                                                        <path d="M7.407 15.6655L8.92445 6.02154L13.4968 14.6472L4.44362 10.4225L14.5933 8.7254L7.407 15.6655Z" fill="#FFFF00" />
                                                        <path d="M19.1163 4.4974L16.0988 5.71579L17.7818 2.93057L18.1249 6.243L15.9007 3.63162L19.1163 4.4974Z" fill="#FFFF00" />
                                                        <path d="M22.9461 7.97664L19.6923 7.92937L22.3237 6.01486L21.3553 9.20111L20.3179 5.93148L22.9461 7.97664Z" fill="#FFFF00" />
                                                        <path d="M23.428 13.6767L20.4764 12.3065L23.6598 11.6317L21.4761 14.1459L21.8625 10.7375L23.428 13.6767Z" fill="#FFFF00" />
                                                        <path d="M20.0591 18.0162L17.8657 15.6122L21.0627 16.2194L18.0774 17.6951L19.7502 14.7004L20.0591 18.0162Z" fill="#FFFF00" />
                                                    </g>
                                                </g>
                                            </g>
                                            <defs>
                                                <filter id="filter0_d_8701_91821" x="0.909286" y="0.757904" width="42.2107" height="42.2101" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                    <feOffset dy="4.11806" />
                                                    <feGaussianBlur stdDeviation="2.05903" />
                                                    <feComposite in2="hardAlpha" operator="out" />
                                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8701_91821" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8701_91821" result="shape" />
                                                </filter>
                                                <clipPath id="clip0_8701_91821">
                                                    <rect width="45.7365" height="34.3024" fill="white" transform="translate(-0.0996094 4.05258) rotate(-9.49274)" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </div>

                                    <div className="w-100">
                                        <button className='btn btn-primary py-2 d-block w-100' onClick={() => handleRouteClick('create-wallet')}>
                                            <span className='text-dark fs-13 text-white ml-1'>
                                                Create Wallets
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-7 mb-4">
                                    <div className="card h-100" style={{
                                        border: '1px solid rgba(229, 231, 235, 1)',
                                        background: 'rgba(249, 250, 251, 1)',
                                        borderRadius: '10px',
                                        boxShadow: '-1px 0px 4px 0px rgba(0, 0, 0, 0.1)',
                                    }}>
                                        <div className="card-body pt-4 h-100 d-flex flex-column justify-content-between align-items-start">
                                            <div className="d-flex justify-content-between align-items-center ">
                                                <h5 className='fs-16 mb-0 text-dark fw-600 text-capitalize'>Conversion</h5>
                                            </div>

                                            <div className=" w-100 mt-4">
                                                <div className={`${styles.charges_input} w-100`}>
                                                    <div className={`${styles.select_wrap}`}
                                                        style={{ left: 'auto', right: '0px' }}
                                                    >
                                                        <div className=" all_selectdropdown_icon position-absolute pr-2">
                                                            <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M0.292893 0.792893C0.683416 0.402369 1.31658 0.402369 1.7071 0.792893L4.99999 4.08579L8.29288 0.792893C8.6834 0.402369 9.31657 0.402369 9.70709 0.792893C10.0976 1.18342 10.0976 1.81658 9.70709 2.20711L5.7071 6.20711C5.31657 6.59763 4.68341 6.59763 4.29289 6.20711L0.292893 2.20711C-0.0976309 1.81658 -0.0976309 1.18342 0.292893 0.792893Z" fill="#6B7280" />
                                                            </svg>
                                                        </div>
                                                        <Select
                                                            labelId="s"
                                                            id="s"
                                                            size="small"
                                                            required
                                                            name="verified"
                                                            defaultValue="NGN"
                                                            className="remove_lines addons"
                                                            style={{ width: "95px", height: '30px', transform: 'translateX(-10px)' }}
                                                        >
                                                            <MenuItem value="NGN">NGN</MenuItem>
                                                            <MenuItem value="USD">USD</MenuItem>
                                                            <MenuItem value="CNY">CNY</MenuItem>
                                                        </Select>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        placeholder="0.00"
                                                        style={{ width: "100%", textIndent: '0' }}
                                                        className=" form-control bg-none"
                                                    // value={Shipping}
                                                    // onChange={handleShippingOptionChange}
                                                    />
                                                </div>

                                                <div className="fit-content py-2 mx-auto">
                                                    <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <ellipse cx="17.1834" cy="16.583" rx="16.2909" ry="15.8836" fill="#F3F4F6" />
                                                        <path d="M15.9196 12.6887L12.8895 9.65862L9.85938 12.6887" stroke="#292D32" stroke-width="1.22181" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M12.8887 24.321V9.65922" stroke="#292D32" stroke-width="1.22181" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M18.4453 21.2905L21.4754 24.3206L24.5055 21.2905" stroke="#292D32" stroke-width="1.22181" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M21.4746 9.65798V24.3198" stroke="#292D32" stroke-width="1.22181" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </div>

                                                <div className={`${styles.charges_input} ${styles.convert_side} w-100`}>
                                                    <div className={`${styles.select_wrap}`}
                                                        style={{ left: 'auto', right: '0px' }}
                                                    >
                                                        <div className=" all_selectdropdown_icon position-absolute pr-2">
                                                            <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M0.292893 0.792893C0.683416 0.402369 1.31658 0.402369 1.7071 0.792893L4.99999 4.08579L8.29288 0.792893C8.6834 0.402369 9.31657 0.402369 9.70709 0.792893C10.0976 1.18342 10.0976 1.81658 9.70709 2.20711L5.7071 6.20711C5.31657 6.59763 4.68341 6.59763 4.29289 6.20711L0.292893 2.20711C-0.0976309 1.81658 -0.0976309 1.18342 0.292893 0.792893Z" fill="#6B7280" />
                                                            </svg>
                                                        </div>
                                                        <Select
                                                            labelId="s"
                                                            id="s"
                                                            size="small"
                                                            required
                                                            name="verified"
                                                            defaultValue="USD"
                                                            className="remove_lines addons"
                                                            style={{ width: "95px", height: '30px', transform: 'translateX(-10px)' }}
                                                        >
                                                            <MenuItem value="NGN">NGN</MenuItem>
                                                            <MenuItem value="USD">USD</MenuItem>
                                                            <MenuItem value="CNY">CNY</MenuItem>
                                                        </Select>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        placeholder="0.00"
                                                        style={{ width: "100%", textIndent: '0' }}
                                                        className=" form-control bg-none"
                                                    // value={Shipping}
                                                    // onChange={handleShippingOptionChange}
                                                    />
                                                </div>
                                                <small className="d-block  pt-4 pb-4 fw-500 fs-13" style={{ cursor: 'pointer' }}>Exchange Rate N465 = $1</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 mb-4">
                                    <div className="card h-100" style={{
                                        border: '1px solid rgba(229, 231, 235, 1)',
                                        background: '#111827',
                                        borderRadius: '10px',
                                    }}>
                                        <div className="card-body py-4 h-100 d-flex flex-column justify-content-between align-items-start">
                                            <div className="pb-4 pb-md-2">
                                                <h5 className='fs-16 mb-4 text-white fw-600 text-capitalize'>Payment</h5>
                                                <p className="mb-0 fs-13 fw-500 text-white">Streamline your payment process, making it easier than ever for your customers to pay you.</p>
                                            </div>

                                            <div className='w-100 pb-3'>
                                                <button onClick={() => handleRouteClick('create-plink')} className='btn py-2 d-block w-100' style={{ background: 'rgba(170, 171, 172, 1)' }}>
                                                    <span className='text-dark fs-13 fw-500 ml-1'>
                                                        Create Payment Link
                                                    </span>
                                                </button>
                                                <button onClick={() => handleRouteClick('create-invoice')} className='btn py-2 d-block w-100 mt-2' style={{ border: '1px solid rgba(170, 171, 172, 1)' }}>
                                                    <span className='fs-13 fw-500 text-white ml-1'>
                                                        Create Invoice
                                                    </span>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mb-4">
                                    <div className="card h-100" style={{
                                        border: '1px solid rgba(229, 231, 235, 1)',
                                        background: 'rgba(249, 250, 251, 1)',
                                        borderRadius: '10px',
                                        boxShadow: '-1px 0px 4px 0px rgba(0, 0, 0, 0.1)',
                                    }}>
                                        <div className="card-body pt-4 h-100 d-flex flex-column justify-content-between align-items-start">
                                            <div className="d-flex justify-content-between align-items-center pb-4 w-100">
                                                <h5 className='fs-16 mb-0 text-dark fw-600 text-capitalize'>Scheduled Payment</h5>
                                                <div className="btn-group ">
                                                    <span className=" btn fw-600 fs-17"
                                                        data-toggle="dropdown"
                                                        aria-expanded="false">
                                                        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3.27344 6.5H9.77344" stroke="black" stroke-width="1.16867" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M6.52344 9.75V3.25" stroke="black" stroke-width="1.16867" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>

                                                        New Transfer
                                                    </span>
                                                    <div
                                                        className="dropdown-menu rounded border-0"
                                                        style={{
                                                            background: "#F9FAFB",
                                                            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                                                        }}
                                                    >
                                                        <Link
                                                            className="dropdown-item dropdown_text"
                                                            to="/app/payment/transfers/single"
                                                            component={RouterLink}
                                                        >
                                                            Single Transfer
                                                        </Link>
                                                        <Link
                                                            className="dropdown-item dropdown_text"
                                                            to="/app/payment/transfers/bulk"
                                                            component={RouterLink}
                                                        >
                                                            Bulk Transfer
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-100" style={{ overflowX: 'auto' }}>
                                                {scheduledPayments.map((item) => (
                                                    <div className="px-3 py-2 mb-2 d-flex justify-content-between align-items-center w-100 border"
                                                        style={{ borderRadius: '10px', minWidth: '30em' }}>
                                                        <span className="d-block fw-500 ">{item?.account_name}</span>
                                                        <span className="d-block fw-500 ">{item?.currency} {Number(item?.amount).toLocaleString()}</span>
                                                        <span className="d-block fw-500 ">{fullNameDateFormat(item?.next_schedule_date)}</span>
                                                        <span className="d-block btn fw-500 ">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M2.5 5.83333H17.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" />
                                                                <path d="M2.5 10H17.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" />
                                                                <path d="M2.5 14.1667H17.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                ))}
                                                {
                                                    (!isPending && !scheduledPayments.length) &&
                                                    <div className="alerts alerts-primary text-center mt-4 mb-4">
                                                        <h6>No scheduled payment found</h6>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-4">
                            <div className="d-flex w-100 h-100 flex-column align-items-center">
                                <div className="card w-100 mb-4" style={{
                                    border: '1px solid rgba(229, 231, 235, 1)',
                                    background: 'rgba(249, 250, 251, 1)',
                                    borderRadius: '10px',
                                }}>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center ">
                                            <h5 className='fs-16 text-dark fw-600'>Daily Inflow</h5>

                                            <div className="create_wallet_select_curr small">
                                                <div
                                                    className="position-relative mx-auto mx-sm-0"
                                                    style={{
                                                        width: "8em",
                                                        maxWidth: "100%",
                                                        background: "rgba(249, 250, 251, 1)",
                                                        border: "1px solid rgba(209, 213, 219, 1)",
                                                        boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.05)",
                                                        borderRadius: "4px",
                                                    }}
                                                >
                                                    <span
                                                        className=" all_selectdropdown_icon position-absolute px-1"
                                                        style={{
                                                            right: "auto",
                                                            top: "1px",
                                                            left: "-2px",
                                                            transform: "translateY(0%) scale(0.8)",
                                                        }}
                                                    >
                                                        {currType === "CNY" && (
                                                            <img src="/assets/icons/ic_flag_cny.svg" alt="" width={28} />
                                                        )}
                                                        {currType === "ngn" && (
                                                            <img src="/assets/icons/small_ngn_flag.svg" alt="" width={28}
                                                            style={{transform: 'translateY(4px)'}} />
                                                        )}
                                                    </span>
                                                    <span
                                                        className=" all_selectdropdown_icon position-absolute px-2"
                                                        style={{
                                                            right: "2px",
                                                            left: "auto",
                                                            transform: "translateY(-45%)",
                                                        }}
                                                    >
                                                        <svg
                                                            width="21"
                                                            height="21"
                                                            viewBox="0 0 21 21"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M5.13209 8.84964L7.80709 11.5246L9.44042 13.1663C10.1321 13.858 11.2571 13.858 11.9488 13.1663L16.2654 8.84964C16.8321 8.28298 16.4238 7.31631 15.6321 7.31631H10.9571H5.76542C4.96542 7.31631 4.56542 8.28298 5.13209 8.84964Z"
                                                                fill="#4B5563"
                                                            />
                                                        </svg>
                                                    </span>
                                                    <Select
                                                        labelId="s"
                                                        id="s"
                                                        fullWidth
                                                        size="small"
                                                        required
                                                        name="verified"
                                                        defaultValue="ngn"
                                                        value={currType}
                                                        onChange={handleCurrTypeChange}
                                                        className="border-0"
                                                    >
                                                        <MenuItem value="ngn">NGN</MenuItem>
                                                        <MenuItem value="CNY">CNY</MenuItem>
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <h2 className="mb-0 fw-600 text-dark py-4">₦250,000</h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="card w-100 h-100" style={{
                                    border: '1px solid rgba(229, 231, 235, 1)',
                                    background: 'rgba(249, 250, 251, 1)',
                                    borderRadius: '10px',
                                    boxShadow: '-1px -1px 4px 0px rgba(0, 0, 0, 0.1)'
                                }}>
                                    <div className="card-body h-100">
                                        <div className="d-flex justify-content-between align-items-center ">
                                            <h5 className='fs-16 text-dark mb-0 fw-600'>Recent Transactions</h5>
                                            <button className="btn d-flex align-items-center pr-0">
                                                <span className='fw-500 mr-2 fs-14'>
                                                    See All
                                                </span>
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <mask id="mask0_8649_90068" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="2" y="0" width="8" height="12">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.72604 0.469875C2.42502 0.762757 2.42502 1.23761 2.72604 1.53049L7.31979 5.99999L2.72604 10.4695C2.42502 10.7624 2.42502 11.2372 2.72604 11.5301C3.02707 11.823 3.51512 11.823 3.81615 11.5301L9.5 5.99999L3.81615 0.469875C3.51512 0.176993 3.02707 0.176993 2.72604 0.469875Z" fill="#006FFD" />
                                                    </mask>
                                                    <g mask="url(#mask0_8649_90068)">
                                                        <rect y="-0.000732422" width="11.9995" height="11.9995" fill="#374151" />
                                                    </g>
                                                </svg>
                                            </button>
                                        </div>

                                        <div className="w-100 pt-4">
                                            {recentTransactions.map((item) => (
                                                <div className="d-flex mb-3 pt-2 pb-3 rounded justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <img src={item.image} alt="" />
                                                        <div className='ml-2'>
                                                            <p className="mb-0 fw-600 fs-13 text-dark">{item.title}</p>
                                                            <span className="d-block fs-11 fw-500">{item.date}</span>
                                                        </div>
                                                    </div>
                                                    <span className='fs-16 fw-600 text-dark'>{item.profit_loss} {item.currency}{item.amount}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="w-100 py-3" style={{ minHeight: 'auto' }}>
                                <WalletStatisticsCards />

                                <div className="w-100 py-4">
                                    <div className="row pt-2">
                                        <div className="col-12 mb-4">
                                            <TransactionStatistics />
                                        </div>
                                        <div className="col-md-6 mb-4 mb-md-0">
                                            <RecentTransactions />
                                        </div>
                                        <div className="col-md-6 mb-4 mb-md-0">
                                            <InvoiceStatistics />
                                        </div>
                                    </div>
                                </div>
                            </div> */}

            </div>
        </>
    );
}