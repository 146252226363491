// ----------------------------------------------------------------------
import { Helmet } from "react-helmet-async";
import styles from "../TopUps/Topups.module.css";
import { useEffect, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link as RouterLink } from "react-router-dom";
import html2canvas from "html2canvas";
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import * as dateFns from "date-fns"

import {
  Link,
  Stack,
  Drawer,
  Typography,
  IconButton,
  Divider,
  //   Avatar,
} from "@mui/material";
import Scrollbar from "../../../components/scrollbar";
// sections
// mock
import toast from "react-hot-toast";
import SuccessToast from 'src/components/SuccessToast';
import ErrorToast from 'src/components/ErrorToast';
import axiosInstance from "src/axiosInstance"
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Iconify from "../../../components/iconify";
import GlobalModal from "../../../Modals/GlobalModal";
import { format } from "date-fns";
import Pagination from "../../../pagination/GlobalPagination"; // Adjust the path as necessary
import {
  mockupGetWalletTransactions,
  mockupGetSingleTransactions,
  authenticationData
} from "src/hooks/useMockData";
import CircularProgress from "@mui/material/CircularProgress";
import {
  DateTimeFormat,
  formatDateToDMY,
  fullNameDateFormat,
  formatDateToDMYWithHyphen,
  formatDateToToFullYear,
  formatDateToFullYear
} from "src/hooks/useDateFormat";
import { auth } from "src/theme/firebaseConfig";
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import bus from 'src/bus'
import Preloader from 'src/preloader';
import Pageloader from 'src/pageloader';

// ----------------------------------------------------------------------
const formatDateWithSuffix = (date) => {
  const day = format(date, "do"); // 'do' gives the day of the month with ordinal suffix (e.g., 1st, 2nd, 3rd)
  const month = format(date, "MMMM");
  const year = format(date, "yyyy");
  return `${day} ${month} ${year}`;
};
const SCHEDULE_TRANSFER_FREQUENCY = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  CUSTOM: 'CUSTOM',
}
const SCHEDULE_TRANSFER_INTERVAL = {
  EVERY_5_DAYS: 'EVERY_5_DAYS',
  EVERY_2_WEEKS: 'EVERY_2_WEEKS',
  EVERY_2_MONTHS: 'EVERY_2_MONTHS',
  BI_ANNUALLY: 'BI_ANNUALLY',
  QUARTERLY: 'QUARTERLY',
}
export default function WalletTransfersPage() {
  // eslint-disable-next-line no-unused-vars
  const apiUrl = process.env.REACT_APP_NAME;
  const response = mockupGetWalletTransactions();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [isDataAvl, setIsDataAvl] = useState(true);
  const [showLedgerBanner, setShowLedgerBanner] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [transactionDetailsDrawer, setTransactionDetailsDrawer] = useState(false);
  const [editSchedule, setEditSchedule] = useState(false);
  const [activateEdit, setActivateEdit] = useState(false);
  const [scheduledOption, setScheduledOption] = useState("WEEKLY");
  const [interval, setInterval] = useState('EVERY_5_DAYS');
  const [noOfTimes, setNoOfTimes] = useState(1); // Initialize with default value of 2
  const [paymentSuccessfulDialog, setPaymentSuccessfulDialog] = useState(false);
  const defaultTime = "00:00"; // Default time in 24-hour format
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [paymentTime, setPaymentTime] = useState(defaultTime);
  const [previewPaymentTime, setPreviewPaymentTime] = useState(null);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [transferData, setTransferData] = useState([]);
  const [transferPagination, setTransferPagination] = useState({});
  const [currentData, setCurrentData] = useState({});
  const [isPending, setIsPending] = useState(true);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isPending02, setIsPending02] = useState(false);
  const detailsRef = useRef();
  const [currentRef, setCurrentRef] = useState('')
  const [isSorted, setIsSorted] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [reloadData, setReloadData] = useState(false);
  const [hasLength, setHasLength] = useState(false);
  // const hasLength = JSON.parse(localStorage.getItem('hasLength')) || false;
  const [IsKYCVerified, setIsKYCVerified] = useState(false);
  let KYC_Status = JSON.parse(localStorage.getItem('kyc_status'))
  let userDetails = JSON.parse(localStorage.getItem('userDetails'))
  const [filter, setFilter] = useState({
    status: '',
    amount: '',
    currency: '',
    transaction_ref: '',
  });

  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "ascending",
  });



  const handleTimeChange = (event) => {
    const time = event.target.value;
    setPaymentTime(time);

    // Format the time to display as 12:00 AM and 12:00 PM, not 14:00
    const formattedTime = formatTimeTo12Hour(time);
    setPreviewPaymentTime(formattedTime);
  };

  useEffect(() => {
    if (paymentTime && scheduledOption) {
      getNextPaymentDate(paymentTime, scheduledOption, noOfTimes, interval)
    }
  }, [paymentTime, scheduledOption, interval])

  const getNextPaymentDate = (time, frequency, number_of_occurance = noOfTimes, interval) => {
    const [hours, minutes] = time.split(':').map(Number);
    let nextDate = new Date();

    // Set the time
    nextDate.setHours(hours, minutes, 0, 0);

    switch (frequency) {
      case SCHEDULE_TRANSFER_FREQUENCY.DAILY:
        nextDate = dateFns.addDays(nextDate, 1);
        break;
      case SCHEDULE_TRANSFER_FREQUENCY.WEEKLY:
        nextDate = dateFns.addWeeks(nextDate, 1);
        break;
      case SCHEDULE_TRANSFER_FREQUENCY.MONTHLY:
        nextDate = dateFns.addMonths(nextDate, 1);
        break;
      case SCHEDULE_TRANSFER_FREQUENCY.QUARTERLY:
        nextDate = dateFns.addQuarters(nextDate, 1);
        break;
      case SCHEDULE_TRANSFER_FREQUENCY.CUSTOM:
        if (number_of_occurance > 0 && interval) {
          switch (interval) {
            case SCHEDULE_TRANSFER_INTERVAL.EVERY_5_DAYS:
              // console.log(number_of_occurance);
              nextDate = dateFns.addDays(nextDate, 5);
              break;
            case SCHEDULE_TRANSFER_INTERVAL.EVERY_2_WEEKS:
              nextDate = dateFns.addWeeks(nextDate, 2);
              break;
            case SCHEDULE_TRANSFER_INTERVAL.EVERY_2_MONTHS:
              nextDate = dateFns.addMonths(nextDate, 2);
              break;
            case SCHEDULE_TRANSFER_INTERVAL.BI_ANNUALLY:
              nextDate = dateFns.addMonths(nextDate, 6);
              break;
            case SCHEDULE_TRANSFER_INTERVAL.QUARTERLY:
              nextDate = dateFns.addQuarters(nextDate, 1);
              break;
            default:
              throw new Error('Invalid custom interval');
          }
        }
        break;
      default:
      // throw new Error('Invalid frequency');
    }
    setPaymentDate(nextDate)
    return nextDate;
  }

  const formatTimeTo12Hour = (time) => {
    const [hours, minutes] = time.split(":");
    let hour = parseInt(hours, 10);
    const isPM = hour >= 12;
    hour = hour % 12 || 12; // Convert 24-hour to 12-hour format, handling midnight as 12
    const formattedHour = hour.toString().padStart(2, "0");
    const period = isPM ? "PM" : "AM";
    return `${formattedHour}:${minutes} ${period}`;
  };

  const handleNoOfTimesChange = (event) => {
    setNoOfTimes(event.target.value); // Update the state with the user's typed value
  };

  const handleInterval = (event) => {
    setInterval(event.target.value); // Update the state with the user's typed value
  };

  const handleClosePaymentSuccessfulDialog = () => {
    setPaymentSuccessfulDialog(false);
  };

  const handleToggleSuccessDialog = () => {
    setPaymentSuccessfulDialog(true);
  }

  const handleStopSchedule = async () => {
    setIsLoading(true);
    const endpoint = `/payments/transfers/stop-schedule/${currentRef.batch_id ?? currentRef.transaction_ref}?productName=${apiUrl}`;
    try {
      const response = await axiosInstance.get(endpoint);

      if (response.status <= 300) {
        toast.success(response.data.message);
        setEditSchedule(false);
        setActivateEdit(false);
        setPaymentSuccessfulDialog(false);
        fetchData();
      }
    } catch (err) {
      toast.error(err.response?.data?.data?.errorMessage || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const handleScheduleChange = (event) => {
    setScheduledOption(event.target.value);
    setInterval('');
  };

  const handleResetActivateEdit = () => {
    setActivateEdit(false);
    setScheduledOption("weekly");
  };

  const handleOnReschedule = (item) => {
    setEditSchedule(true);
    setCurrentRef(item)
    setPaymentTime(item?.schedule?.time)
    setPreviewPaymentTime(item?.schedule?.time)
    setScheduledOption(item?.schedule?.frequency)
    setNoOfTimes(item?.schedule?.number_of_occurance ?? 1)
    setInterval(item?.schedule?.interval)
  };

  useEffect(() => {
    // console.log(previewPaymentTime, scheduledOption, noOfTimes, interval);
  }, [previewPaymentTime, scheduledOption, noOfTimes, interval])

  const verifyScheduleTrue = async () => {
    setIsLoading(true);
    let filter_payload = {};
    filter_payload.schedule = {};

    if (paymentTime !== null) {
      // Remove "AM" or "PM" from previewPaymentTime
      const cleanedTime = paymentTime.replace(/AM|PM/, '').trim();
      filter_payload.schedule.time = cleanedTime;
    }
    if (scheduledOption !== '') {
      filter_payload.schedule.frequency = scheduledOption.toUpperCase();
    }
    if (scheduledOption === 'CUSTOM' && noOfTimes !== null) {
      filter_payload.schedule.number_of_occurance = noOfTimes;
    }
    if (scheduledOption === 'CUSTOM' && interval !== '') {
      filter_payload.schedule.interval = interval;
    }

    const endpoint = `/payments/transfers/attach-schedule/${currentRef.batch_id ?? currentRef.transaction_ref}?productName=${apiUrl}`;
    try {
      const response = await axiosInstance.post(endpoint, filter_payload);

      if (response.status <= 300) {
        toast.success(response.data.message);
        setEditSchedule(false);
        setActivateEdit(false);
        fetchData()
      }
    } catch (err) {
      toast.error(err.response?.data?.data?.errorMessage || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };


  const handleCloseEditSchedule = () => {
    setEditSchedule(false);
    setActivateEdit(false);
    setScheduledOption("WEEKLY");
  };

  const handleToggleIsDataAvl = () => {
    setIsDataAvl(!isDataAvl);
  };

  const handleToggleDropdown = () => {
    setToggleDropdown(!toggleDropdown);
  };

  const handleOpenTransactionDetailsDrawer = (item) => {
    setCurrentData(item);
    // const getSingleResponse = item;
    // setIsPending02(true);
    // setTimeout(() => {
    //   if (response.status === "success") {
    //     const singledataList = getSingleResponse.data;

    //     setIsPending02(false);
    //   } else {
    //     notifyError("Something went wrong , please try again later");
    //     setIsPending02(false);
    //   }
    // }, 700);

    // console.log("setTransactionDetailsDrawer:", item); // Log the selected item data
    setTransactionDetailsDrawer(true); // Open the drawer
  };

  const handleCloseTransactionDetailsDrawer = () => {
    setTransactionDetailsDrawer(false);
  };

  const handleCloseLedgerBanner = () => {
    setShowLedgerBanner(false);
    localStorage.setItem("show_ledger_banner", JSON.stringify(false));
  };


  const handleClearFilter = () => {
    setFilter({
      status: '',
      amount: '',
      currency: '',
      transaction_ref: '',
    })
    setStartDate(null)
    setEndDate(null)
    setSearchQuery('')
    setToggleDropdown(false)
    setReloadData(true)
  }

  const fetchAllWalletTransactions = () => {
    // setIsPending(true);
    setTimeout(() => {
      if (response.status === "success") {
        const dataList = response.data.data;

        const currentTableData = dataList.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize
        );
        setCurrentTableData(currentTableData);
        setIsPending(false);
      } else {
        notifyError("Something went wrong , please try again later");
        setIsPending(false);
      }
    }, 700);
  };

  const fetchData = async () => {
    setIsLoadingData(true)

    const filter_payload = {}

    if (filter.currency !== '') {
      filter_payload.currency = filter.currency
    }
    if (filter.status !== '') {
      filter_payload.status = filter.status
    }
    if (filter.amount !== '') {
      filter_payload.amount = filter.amount
    }
    if (filter.transaction_ref !== '') {
      filter_payload.transaction_ref = filter.transaction_ref
    }
    if (startDate !== null) {
      filter_payload.from = formatDateToFullYear(startDate)
    }
    if (endDate !== null) {
      filter_payload.to = formatDateToFullYear(endDate)
    }
    if (searchQuery !== '') {
      filter_payload.search = searchQuery
    }
    filter_payload.page = currentPage
    filter_payload.perPage = pageSize

    // console.log(filter_payload);
    try {
      const response = await axiosInstance.get(`/payments/transfers?productName=${apiUrl}`, {
        params: {
          ...filter_payload
        }
      })

      setTransferData(response.data.data.data)
      setTransferPagination(response.data.data)
      setToggleDropdown(false)
      const data = response.data.data.data;
      if (data.length > 0) {
        setHasLength(true)
        // localStorage.setItem('hasLength', JSON.stringify(true))
      }
      // else {
      //   if (filter.search !== '' || filter.currency !=='' || filter.amount !== '' || startDate || endDate) {
      //     setHasLength(true)
      //   } 
      //   else {
      //     setHasLength(false)
      //   }
      // }
      setIsLoadingPage(false)
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
          notifyError(err.response.data.data.errorMessage);
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
        } else {
          notifyError('An unexpected error occurred');
        }
      } else {
        notifyError('Network error or server not responding');
      }
    } finally {
      setIsLoadingData(false)
    }
  }

  

  const handleFilterChange = (e) => {
    const { name, value } = e.target
    setFilter(prev => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  useEffect(() => {
    fetchData()
  }, [searchQuery, pageSize, currentPage, reloadData])

  useEffect(() => {
    if ((KYC_Status !== null && KYC_Status) || (userDetails !== null && userDetails.account !== null && userDetails.account.kyc_status)) {
      setIsKYCVerified(true);
    } else {
      setIsKYCVerified(false);
    }

    if (transferData.length > 0) {
      setIsDataAvl(true);
    }
  }, [])

  const handleDownloadFile = async (extension) => {
    try {
      let tableData;
      tableData = transferData;

      if (!tableData || tableData.length === 0) {
        throw new Error('Transaction not found');
      }

      if (extension === 'csv') {
        exportTableDataToCSV(tableData, 'Transfers.csv');
      } else if (extension === 'pdf') {
        exportTableDataToPDF(tableData, 'Transfers.pdf');
      }
    } catch (err) {
      console.error(err);
      notifyError(`Unable to export ${extension} file`);
    }
  };

  const exportTableDataToCSV = (tableData, filename) => {
    const headers = ["Transaction ID", "Status", "Amount", "Date"];
    const csvContent = [
      headers.join(','),
      ...tableData.map(item => [item.transaction_ref, item.user_status, Number(item.amount).toLocaleString(), moment(item.transaction_date).format('DD-MM-YYYY') + ' ' + moment(item.transaction_date).format('HH:mm A')].join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, filename);
  };

  const exportTableDataToPDF = (tableData, filename) => {
    const doc = new jsPDF();
    const tableColumn = ["Transaction ID", "Status", "Amount", "Date"];
    const tableRows = [];

    tableData.forEach(item => {
      const rowData = [item.transaction_ref, item.user_status, Number(item.amount).toLocaleString(), moment(item.transaction_date).format('DD-MM-YYYY') + ' ' + moment(item.transaction_date).format('HH:mm A')];
      tableRows.push(rowData);
    });

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.save(filename);
  };

  const filteredTransfers = currentTableData.length && currentTableData.filter(transfer =>
    transfer.transaction_ref.toLowerCase().includes(searchQuery.toLowerCase()) ||
    transfer.amount_due.toString().includes(searchQuery.toLowerCase()) ||
    transfer.status.toLowerCase().includes(searchQuery.toLowerCase())
  );

  //   const sortTableData = (key) => {
  //     let direction = "ascending";
  //     if (sortConfig.key === key && sortConfig.direction === "ascending") {
  //       direction = "descending";
  //     }
  //     const sortedData = [...currentTableData].sort((a, b) => {
  //       if (a[key] < b[key]) {
  //         return direction === "ascending" ? -1 : 1;
  //       }
  //       if (a[key] > b[key]) {
  //         return direction === "ascending" ? 1 : -1;
  //       }
  //       return 0;
  //     });
  //     setCurrentTableData(sortedData);
  //     setSortConfig({ key, direction });
  //     console.log('sortConfig:', sortConfig);
  //   };

  const sortTableData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    if (direction === "ascending") {
      setIsSorted(true);
      // console.log("isSorted:", isSorted);
    } else if (direction === "descending") {
      setIsSorted(false);
      // console.log("isSorted:", isSorted);
    }

    const sortedData = [...currentTableData].sort((a, b) => {
      if (typeof a[key] === "number" && typeof b[key] === "number") {
        // return direction === 'ascending' ? a[key] - b[key] : b[key] - a[key];
        if (direction === "ascending") {
          // console.log("enters ascending");
          return direction === "ascending" ? -1 : 1;
        } else if (direction === "descending") {
          // console.log("enters descending");
          return direction === "ascending" ? 1 : -1;
        }
      } else {
        if (a[key] < b[key]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      }
    });
    setCurrentTableData(sortedData);
    setSortConfig({ key, direction });
    // console.log("sortConfig:", sortConfig);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate the data to display on the current page

  // useEffect(() => {
  //   setShowLedgerBanner(false);
  //   let bannerStatus = JSON.parse(localStorage.getItem("show_ledger_banner"));

  //   setTimeout(() => {
  //     if (transferData.length < 1) {
  //       // if (bannerStatus === true) {
  //         setIsDataAvl(true);
  //         setShowLedgerBanner(true);
  //       // }
  //     } else {
  //       setIsDataAvl(false);
  //     }
  //     fetchAllWalletTransactions();
  //     setIsLoading(false);

  //     setTimeout(() => {
  //       setShowLedgerBanner(false);
  //       let authAval = localStorage.getItem('auth');
  //       if (authAval) {
  //         localStorage.setItem("show_ledger_banner", JSON.stringify(false));
  //       }
  //     }, 3000);

  //     // Cleanup function to set show_ledger_banner back to false when unmounted
  //   }, 200);

  //   return () => {
  //     setShowLedgerBanner(false);
  //     let authAval = localStorage.getItem('auth');
  //     if (authAval) {
  //       localStorage.setItem("show_ledger_banner", JSON.stringify(false));
  //     }
  //   };
  // }, []);

  useEffect(() => {
    setShowLedgerBanner(false);
    // setTimeout(() => {
    if (transferData.length > 0) {
      setHasLength(true)
      // localStorage.setItem('hasLength', JSON.stringify(true))
      setIsDataAvl(true);
      // setShowLedgerBanner(true);
    } else {
      setIsDataAvl(false);
      // localStorage.setItem('hasLength', JSON.stringify(false))
    }

    fetchAllWalletTransactions();
    setIsLoading(false);

    setTimeout(() => {
      setShowLedgerBanner(false);
      const authAval = localStorage.getItem('auth');
      if (authAval) {
        localStorage.setItem('show_ledger_banner', JSON.stringify(false));
      }
    }, 3000);
    // }, 200);

    return () => {
      setShowLedgerBanner(false);
      const authAval = localStorage.getItem('auth');
      if (authAval) {
        localStorage.setItem('show_ledger_banner', JSON.stringify(false));
      }
    };
    // }, []);
  }, [transferData]);


  useEffect(() => {
    setIsLoadingPage(true)
    if (hasLength && !transferData.length) {
      setHasLength(true)
    }
  }, [])

  const exportReceiptToPDF = () => {
    handleCloseTransactionDetailsDrawer();
    const input = detailsRef.current;

    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: "a5",
        });

        const imgWidth = 148; // Width in mm for A5
        const pageHeight = 150; // Height in mm for A5
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        let position = 0;
        if (imgHeight > pageHeight) {
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        } else {
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, pageHeight);
        }

        pdf.save("receipt.pdf");
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
            notifyError(err.response.data.data.errorMessage);
          } else if (err.response.data && err.response.data.message) {
            notifyError(err.response.data.message);
          } else {
            notifyError('An unexpected error occurred');
          }
        } else {
          notifyError('Network error or server not responding');
        }
        console.error("Error generating PDF: ", err);
      });
  };


  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setTooltipVisible(true);
        setTimeout(() => {
          setTooltipVisible(false);
        }, 2000);
      },
      (err) => {
        console.error('Failed to copy: ', err);
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>Transfers</title>
      </Helmet>

      {IsKYCVerified ?
        (
          <>
            {isLoadingPage ? (
              <Preloader />
            ) : (
              <>
                {
                  (hasLength) &&
                  <div className="px-0">
                    <div className={`${showLedgerBanner ? "run" : "d-none"} top_alert_wrapper`}>
                      <div
                        className="alert bg-white alert-dismissible fade show"
                        role="alert"
                      >
                        <div className="d-flex">
                          <div className="pr-3">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                                stroke="#34D399"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div>
                            <h4 className="fs-14 text-dark fw-600 mb-0">Ledger Update</h4>
                            <p className="mb-1">
                              <span className="fs-12 fw-400">
                                Your transfer has been updated to the ledger
                              </span>
                            </p>
                            <Link
                              // to="/app/ledger-management/ledger-entries"
                              // component={RouterLink}
                              className="btn pl-0 pr-3 rounded-0 position-relative"
                            >
                              {" "}
                              <span className=" fs-12">View Posting</span>{" "}
                              <span
                                className="position-absolute"
                                style={{
                                  transform: "rotate(-90deg)",
                                  top: "7px",
                                  right: "0",
                                }}
                              >
                                <svg
                                  width="16"
                                  height="17"
                                  viewBox="0 0 16 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.00065 3.8335V13.1668M8.00065 13.1668L12.6673 8.50016M8.00065 13.1668L3.33398 8.50016"
                                    stroke="#000"
                                    strokeWidth="1.33333"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </Link>
                          </div>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                            onClick={handleCloseLedgerBanner}
                          >
                            <span aria-hidden="true">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_5456_128482)">
                                  <rect width="20" height="20" rx="6" fill="white" />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
                                    fill="#9CA3AF"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_5456_128482">
                                    <rect width="20" height="20" rx="6" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      onClick={() => handleToggleDropdown()}
                      className={`overlay_div ${toggleDropdown ? "show_overlay" : ""}`}
                    ></div>

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="">
                        <h5 className="page_title mb-2">Transfers</h5>
                        <p style={{ color: "#4B5563", fontSize: "13px" }}>
                          <span>Share a link to a checkout page</span>
                        </p>
                      </div>
                    </div>

                    <div className="d-md-flex justify-content-between align-items-center mt-4 w-100">
                      <div
                        className="d-flex justify-content-between align-items-center mb-3 mb-md-0 w-100"
                        style={{ gap: "10px", width: "fit-content" }}
                      >
                        <div className={`${styles.hold_input} w-100`}>
                          <img src="/assets/icons/search.svg" alt="" />
                          <input
                            type="text"
                            name="search"
                            placeholder="Search"
                            style={{
                              paddingLeft: "33px",
                              width: "24em",
                              maxWidth: "100%",
                              borderRadius: "0",
                              fontSize: "13px",
                              minHeight: "40px",
                            }}
                            className=" form-control rounded-0"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <div
                          className="fit-content d-flex"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <div className="btn-group">
                            <button
                              className="btn border btn-primary pl-3 pr-3"
                              type="button"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <svg
                                width="13"
                                height="14"
                                viewBox="0 0 15 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.75 8H11.25"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.5 11.75V4.25"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>{" "}
                              <span className="text-white button_text">
                                New <span className="d-none d-sm-inline">Transfer</span>
                              </span>
                            </button>
                            <div
                              className="dropdown-menu rounded border-0"
                              style={{
                                background: "#F9FAFB",
                                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              <Link
                                className="dropdown-item dropdown_text"
                                to="/app/payment/transfers/single"
                                component={RouterLink}
                              >
                                Single Transfer
                              </Link>
                              <Link
                                className="dropdown-item dropdown_text"
                                to="/app/payment/transfers/bulk"
                                component={RouterLink}
                              >
                                Bulk Transfer
                              </Link>
                            </div>
                          </div>
                          &nbsp;&nbsp;
                          <div className="btn-group">
                            <button
                              data-toggle="dropdown"
                              aria-expanded="false"
                              className="btn border btn-primary"
                              type="button"
                            >
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.51855 8.93359V10.3937C1.51855 10.9313 1.95436 11.3671 2.49195 11.3671H9.30576C9.84335 11.3671 10.2792 10.9313 10.2792 10.3937V8.93359"
                                  stroke="#F3F4F6"
                                  strokeWidth="1.86"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M3.9519 6.5L5.8987 8.4468L7.8455 6.5"
                                  stroke="#F3F4F6"
                                  strokeWidth="1.86"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.89868 1.6333V8.4471"
                                  stroke="#F3F4F6"
                                  strokeWidth="1.86"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>{" "}
                              <span className="button_text text-white">Export</span>
                            </button>
                            <div
                              className="dropdown-menu dropdown-menu-right rounded border-0"
                              style={{
                                background: "#fff",
                                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              <button className="dropdown-item dropdown_text fw-500 position-relative" onClick={() => handleDownloadFile("csv")}>
                                CSV
                              </button>
                              <button className="dropdown-item dropdown_text fw-500 position-relative" onClick={() => handleDownloadFile("pdf")}>
                                PDF
                              </button>
                            </div>
                          </div>
                          &nbsp;&nbsp;
                          <div className="btn-group">
                            <Link
                              onClick={() => handleToggleDropdown()}
                              className="btn border btn-primary rounded d-flex align-items-center"
                            >
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.7523 5.01514L6.57741 8.19003C6.20246 8.56498 5.58891 8.56498 5.21396 8.19003L2.03906 5.01514"
                                  stroke="white"
                                  strokeWidth="1.16867"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>{" "}
                              <span className="ml-1 text-white">Filters</span>
                            </Link>
                            <div
                              className={`${toggleDropdown ? "show" : ""
                                } dropdown-menu dropdown-menu-right ${styles.drp_dwn_menu_right_topup
                                } drp_dwn_menu_right`}
                              style={{ transform: "translate3d(-310px, 40px, 0px)" }}
                            >
                              <div className={`${styles.filter_wrap} px-2 px-sm-4 py-3`}>
                                <div className="row">
                                  <div className="col-12 mb-1">
                                    <div className="mb-3 d-flex flex-column">
                                      <label className="fw-500" htmlFor="currency">Currency</label>
                                      <div className="position-relative bg-white">
                                        <span className=" all_selectdropdown_icon position-absolute px-2">
                                          <svg
                                            width="20"
                                            height="21"
                                            viewBox="0 0 20 21"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z"
                                              fill="#6B7280"
                                            />
                                          </svg>
                                        </span>
                                        <Select
                                          labelId="currency"
                                          id="currency"
                                          fullWidth
                                          size="small"
                                          required
                                          name="currency"
                                          defaultValue="all"
                                          value={filter.currency}
                                          onChange={handleFilterChange}
                                        >
                                          <MenuItem value="">ALL</MenuItem>
                                          <MenuItem value="ngn">NGN</MenuItem>
                                          <MenuItem value="cny">CNY</MenuItem>
                                        </Select>
                                      </div>
                                    </div>

                                    <div className="mb-3 d-flex flex-column">
                                      <label className="fw-500" htmlFor="status">Status</label>
                                      <div className="position-relative bg-white">
                                        <span className=" all_selectdropdown_icon position-absolute px-2">
                                          <svg
                                            width="20"
                                            height="21"
                                            viewBox="0 0 20 21"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z"
                                              fill="#6B7280"
                                            />
                                          </svg>
                                        </span>
                                        <Select
                                          labelId="status"
                                          id="status"
                                          fullWidth
                                          size="small"
                                          required
                                          name="status"
                                          placeholder="Select"
                                          defaultValue="all"
                                          className="text-uppercase"
                                          value={filter.status}
                                          onChange={handleFilterChange}
                                        >
                                          <MenuItem className="text-uppercase" value="">ALL</MenuItem>
                                          <MenuItem className="text-uppercase" value="SUCCESSFUL">Successful</MenuItem>
                                          <MenuItem className="text-uppercase" value="PENDING">Pending</MenuItem>
                                          <MenuItem className="text-uppercase" value="FAILED">Failed</MenuItem>
                                        </Select>
                                      </div>
                                    </div>

                                    <div className="mb-3 d-flex flex-column">
                                      <label className="fw-500" htmlFor="amount">Amount</label>
                                      <div className="position-relative">
                                        <TextField
                                          name="amount"
                                          required
                                          type="number"
                                          fullWidth
                                          id="amount"
                                          placeholder=""
                                          size="small"
                                          value={filter.amount}
                                          onChange={handleFilterChange}
                                        />
                                      </div>
                                    </div>

                                    {/* <div className="mb-3 d-flex flex-column">
                                      <label className="fw-500" htmlFor="trsn_id">Transaction ID</label>
                                      <TextField
                                        name="trsn_id"
                                        required
                                        type="number"
                                        fullWidth
                                        id="trsn_id"
                                        placeholder=""
                                        size="small"
                                        value={filter.transaction_ref}
                                        onChange={handleFilterChange}
                                      />
                                    </div> */}

                                    <label className="fw-500" htmlFor="date_range">Custom Date Range</label>

                                    <div className="start_enddate d-flex">
                                      <div className="" style={{ width: "100%" }}>
                                        <div
                                          className="mb-3 bg-white"
                                          style={{ position: "relative" }}
                                        >
                                          <span
                                            style={{
                                              position: "absolute",
                                              right: "10px",
                                              top: "7px",
                                              zIndex: "0",
                                            }}
                                          >
                                            <svg
                                              width="14"
                                              height="14"
                                              viewBox="0 0 14 14"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M2.40527 4.29523C2.40527 3.38641 3.14202 2.64966 4.05084 2.64966H10.6331C11.5419 2.64966 12.2787 3.3864 12.2787 4.29523V10.8775C12.2787 11.7863 11.5419 12.5231 10.6331 12.5231H4.05084C3.14202 12.5231 2.40527 11.7863 2.40527 10.8775V4.29523Z"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M9.53516 1.55298V3.74707"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M5.14844 1.55298V3.74707"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M2.40527 5.94092H12.2787"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          </span>
                                          <DatePicker
                                            // showIcon
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={15}
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            dateFormat="yyyy/MM/dd"
                                            className="form-control border"
                                            placeholderText="Start date"
                                            required
                                            maxDate={new Date()}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="px-2 d-flex align-items-center "
                                        style={{ height: "40px" }}
                                      >
                                        <img
                                          src="/assets/icons/line.svg"
                                          style={{ width: "30px" }}
                                          alt=""
                                        />
                                      </div>
                                      <div className="" style={{ width: "100%" }}>
                                        <div
                                          className="mb-3 bg-white"
                                          style={{ position: "relative" }}
                                        >
                                          <span
                                            style={{
                                              position: "absolute",
                                              right: "10px",
                                              top: "7px",
                                              zIndex: "0",
                                            }}
                                          >
                                            <svg
                                              width="14"
                                              height="14"
                                              viewBox="0 0 14 14"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M2.40527 4.29523C2.40527 3.38641 3.14202 2.64966 4.05084 2.64966H10.6331C11.5419 2.64966 12.2787 3.3864 12.2787 4.29523V10.8775C12.2787 11.7863 11.5419 12.5231 10.6331 12.5231H4.05084C3.14202 12.5231 2.40527 11.7863 2.40527 10.8775V4.29523Z"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M9.53516 1.55298V3.74707"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M5.14844 1.55298V3.74707"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M2.40527 5.94092H12.2787"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          </span>
                                          <DatePicker
                                            // showIcon
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={15}
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            dateFormat="yyyy/MM/dd"
                                            className="form-control border"
                                            placeholderText="End date"
                                            required
                                            maxDate={new Date()}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-1">
                                  <div
                                    className="mr-auto"
                                    style={{ width: "fit-content" }}
                                  >
                                    <button
                                      type="button"
                                      className={`btn border bg-white`}
                                      onClick={() => handleClearFilter()}
                                    >
                                      Clear
                                    </button>
                                    &nbsp; &nbsp;
                                    <button
                                      type="button"
                                      className={`btn btn-primary`}
                                      onClick={fetchData}
                                      disabled={isPending}
                                    >
                                      Apply
                                      {
                                        isPending &&
                                        <CircularProgress
                                          style={{ color: "#fff" }}
                                          className="text-white ml-1"
                                          size="0.8rem"
                                        />
                                      }
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ marginTop: "0px", borderRadius: "10px" }}
                      className="p-0"
                    >
                      <Scrollbar>
                        <div
                          className={`${styles.overflow_table} table-responsive  pt-3 pb-3 pl-0 pr-0`}
                          style={{ minHeight: '80vh' }}
                        >
                          <table
                            className={`${styles.table} table-hover table-striped`}
                            style={{ minWidth: "50em" }}
                          >
                            <thead className={`${styles.thead}`}>
                              <tr>
                                <th
                                  className="text-muted fw-600 cursor-pointer fs-13 th_width3"
                                  style={{ minWidth: "26%" }}
                                  onClick={() => sortTableData("transaction_ref")}
                                >
                                  Transaction ID{" "}
                                  <svg
                                    className={`${!isSorted ? "" : "rotate_icon"}`}
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                                      stroke="#667085"
                                      strokeWidth="1.33333"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </th>
                                <th
                                  className="text-muted fw-600 cursor-pointer fs-13 th_width"
                                  onClick={() => sortTableData("amount_due")}
                                >
                                  Amount{" "}
                                  <svg
                                    className={`${!isSorted ? "" : "rotate_icon"}`}
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                                      stroke="#667085"
                                      strokeWidth="1.33333"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </th>
                                <th
                                  className="text-muted fw-600 cursor-pointer fs-13 th_width_12"
                                  onClick={() => sortTableData("status")}
                                >
                                  Status{" "}
                                  <svg
                                    className={`${!isSorted ? "" : "rotate_icon"}`}
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                                      stroke="#667085"
                                      strokeWidth="1.33333"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </th>
                                <th
                                  className="text-muted fw-600 cursor-pointer fs-13 th_width"
                                  style={{ minWidth: "300px" }}
                                  onClick={() => sortTableData("transaction_date")}
                                >
                                  Date{" "}
                                  <svg
                                    className={`${!isSorted ? "" : "rotate_icon"}`}
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                                      stroke="#667085"
                                      strokeWidth="1.33333"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </th>
                                <th
                                  style={{ width: "fit-content" }}
                                  className="px-0"
                                ></th>
                              </tr>
                            </thead>

                            <tbody>
                              {isLoadingData ? (
                                <>
                                  <tr>
                                    <td colspan="4" style={{ position: 'relative', height: '150px', backgroundColor: '#fff' }}>
                                      <Pageloader loading={isLoadingData} />
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                !isLoadingData &&
                                transferData &&
                                transferData.map((item, index) => (
                                  <tr
                                    key={index}
                                    className={`${styles.tr} table-fonts cursor-pointer`}
                                  >
                                    <td
                                      onClick={() =>
                                        handleOpenTransactionDetailsDrawer(item)
                                      }
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div className=" ">
                                          <span className="fw-500">
                                            {item?.transaction_ref}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleOpenTransactionDetailsDrawer(item)
                                      }
                                    >
                                      <span className="fw-600">
                                        {item?.currency === 'CNY' ? '¥' : '₦'}
                                        {item?.amount?.toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </span>
                                      {/* <span style={{color: 'rgba(149, 155, 164, 1)'}}>
                                                            {item.currency} 
                                                        </span> */}
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleOpenTransactionDetailsDrawer(item)
                                      }
                                    >
                                      <div className=" ">
                                        <div
                                          className={`${item?.user_status} badge_style fit-content text-uppercase fw-500 fs-12 d-inline`}
                                        >
                                          {item?.user_status}
                                        </div>
                                      </div>
                                    </td>

                                    <td
                                      onClick={() =>
                                        handleOpenTransactionDetailsDrawer(item)
                                      }
                                    >
                                      <span className="fw-500">
                                        {DateTimeFormat(item?.updatedAt)}
                                      </span>
                                    </td>
                                    <td
                                      className=" pl-0 pr-4"
                                    // onClick={() =>
                                    //   item?.next_schedule_date === null
                                    //     ? handleOpenTransactionDetailsDrawer(item)
                                    //     : setEditSchedule(true)
                                    // }
                                    >
                                      {item?.next_schedule_date !== null && (
                                        <div
                                          className="btn border fit-content ml-auto no-wrap d-block"
                                          onClick={() => handleOnReschedule(item)}
                                        >
                                          <span className=" fs-12">
                                            Next Payment Date :
                                          </span>{" "}
                                          <span className=" fs-12">
                                            {formatDateToDMY(item?.next_schedule_date)}
                                          </span>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                          {
                            (!isLoadingData && !transferData.length) &&
                            <div className="alerts alerts-primary text-center mt-4 mb-4">
                              <h5>No record found!</h5>
                            </div>
                          }

                          {!isLoadingData && transferData.length ? (
                            <Pagination
                              currentPage={currentPage}
                              totalResults={transferPagination?.totalPages}
                              totalItems={transferPagination?.totalItems}
                              pageSize={pageSize}
                              onPageChange={onPageChange}
                            />
                          ) : (
                            <Pagination
                              currentPage={0}
                              totalResults={0}
                              totalItems={0}
                              pageSize={0}
                              onPageChange={onPageChange}
                            />
                          )}

                        </div>

                      </Scrollbar>
                    </div>
                  </div>
                }
                {
                  (!hasLength) && (
                    <div className="px-0 px-md-2 mt-3 py-5">
                      <div className="">
                        <div className={`${styles.trfpage_head} text-center`}>
                          <h2 className="text-center mx-auto">
                            Lets Assist You In Making Your First Transfer
                          </h2>
                          <p className="text-center mx-auto">
                            Get started with our seamless payment service{" "}
                          </p>
                        </div>
                      </div>
                      <div className="rowWrap py-5">
                        <div className="row">
                          <div className="col-sm-6 mb-4">
                            <div
                              className={`${styles.trfpage_cardbox} pb-3 mx-auto ml-sm-auto mr-sm-0 pr-md-3`}
                            >
                              <div
                                className="topside py-5 w-100"
                                // onClick={() => handleToggleIsDataAvl()}
                                style={{
                                  background: "url(/assets/single_trf.gif) no-repeat",
                                  backgroundSize: "cover",
                                  backgroundPosition: "top center",
                                  minHeight: "200px",
                                  borderRadius: "10px",
                                }}
                              ></div>
                              <div className="content pt-4">
                                <h6>Single Transfer</h6>
                                <p className="mb-0">
                                  <span>
                                    Create a payment from the Dashboard by manually entering
                                    a recipient bank details.
                                  </span>
                                </p>
                                <div className="mt-3">
                                  <Link
                                    to="/app/payment/transfers/single"
                                    component={RouterLink}
                                    className="btn border"
                                  >
                                    <span className="button_text">
                                      Perform a Single Transfer
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 mb-4">
                            <div
                              className={`${styles.trfpage_cardbox} pb-3 mx-auto mr-sm-auto ml-sm-0 pl-md-3`}
                            >
                              <div
                                className="topside py-5 w-100"
                                style={{
                                  background: "url(/assets/bulk_trf.gif) no-repeat",
                                  backgroundSize: "cover",
                                  backgroundPosition: "top center",
                                  minHeight: "200px",
                                  borderRadius: "10px",
                                }}
                              ></div>
                              <div className="content pt-4">
                                <h6>Bulk Transfer</h6>
                                <p className="mb-0">
                                  <span>
                                    Create and send out bulk payments to recipients with ease.
                                  </span>
                                </p>
                                <div className="mt-3">
                                  <Link
                                    to="/app/payment/transfers/bulk"
                                    component={RouterLink}
                                    className="btn border"
                                  >
                                    <span className="button_text">
                                      Perform Bulk Transfer
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </>
            )
            }
          </>
        ) :
        (
          <div className="px-0 px-md-2 mt-3 py-5">
            <div className="">
              <div className={`${styles.trfpage_head} text-center`}>
              <h2 className="text-center mx-auto">
                            Lets Assist You In Making Your First Transfer
                          </h2>
                          <p className="text-center mx-auto">
                            Get started with our seamless payment service{" "}
                          </p>
              </div>
            </div>
            <div className="rowWrap py-5">
              <div className="row">
                <div className="col-sm-6 mb-4">
                  <div
                    className={`${styles.trfpage_cardbox} pb-3 mx-auto ml-sm-auto mr-sm-0 pr-md-3`}
                  >
                    <div
                      className="topside py-5 w-100"
                      // onClick={() => handleToggleIsDataAvl()}
                      style={{
                        background: "url(/assets/single_trf.gif) no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "top center",
                        minHeight: "200px",
                        borderRadius: "10px",
                      }}
                    ></div>
                    <div className="content pt-4">
                      <h6>Single Transfer</h6>
                      <p className="mb-0">
                        <span>
                          Create a payment from the Dashboard by manually entering
                          a recipient bank details.
                        </span>
                      </p>
                      <div className="mt-3">
                        <button
                          className="btn border"
                          onClick={() => bus.emit('not_verified', true)}
                        >
                          <span className="button_text">
                            Make Single Transfer
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 mb-4">
                  <div
                    className={`${styles.trfpage_cardbox} pb-3 mx-auto mr-sm-auto ml-sm-0 pl-md-3`}
                  >
                    <div
                      className="topside py-5 w-100"
                      style={{
                        background: "url(/assets/bulk_trf.gif) no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "top center",
                        minHeight: "200px",
                        borderRadius: "10px",
                      }}
                    ></div>
                    <div className="content pt-4">
                      <h6>Bulk Transfer</h6>
                      <p className="mb-0">
                        <span>                    
                        Create and send out bulk payments to recipients with ease.
                        </span>
                      </p>
                      <div className="mt-3">
                        <button
                          className="btn border"
                          onClick={() => bus.emit('not_verified', true)}
                        >
                          <span className="button_text">
                            Make Bulk Transfer
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }



      {/* {IsKYCVerified ?
        (<>

        </>) : (
          <>
          </>
        )
      } */}



      <Drawer
        anchor="right"
        open={transactionDetailsDrawer}
        onClose={handleCloseTransactionDetailsDrawer}
        PaperProps={{
          sx: {
            width: 350,
            maxWidth: "100%",
            border: "none",
            overflow: "",
            marginTop: "0em",
            height: "100vh",
          },
        }}
        className={`${styles.drawerHolder}`}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, pt: 2, pb: 1 }}
          className="bg-white"
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            {/* Filters */}
          </Typography>
          <IconButton className="d-inline d-sm-none" onClick={() => setTransactionDetailsDrawer(false)}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        {/* <Divider /> */}

        <Scrollbar>
          <Stack spacing={2} sx={{ p: 0 }}>
            <div
              className={`modal_content bg-white`}
              style={{ minHeight: "100vh" }}
            >
              {isPending02 ? (
                <div
                  className="w-100  d-flex justify-content-center align-items-center"
                  style={{
                    minHeight: "90vh",
                    width: "100%",
                  }}
                >
                  <div className="mx-auto fit-content">
                    <Pageloader loading={isPending02} />
                  </div>
                </div>
              ) : (
                <div
                  className={` bg-white`}
                >
                  <div ref={detailsRef} className="w-100 px-4 pt-3 pb-3">
                    <div className="pt-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M1.51953 1.25H10.5195C10.9337 1.25 11.2695 1.58579 11.2695 2V2.75H0.769531V2C0.769531 1.58579 1.10532 1.25 1.51953 1.25ZM0.769531 3.5H11.2695C11.6837 3.5 12.0195 3.80526 12.0195 4.18182V10.3182C12.0195 10.6947 11.6837 11 11.2695 11H0.769531C0.355318 11 0.0195312 10.6947 0.0195312 10.3182V4.18182C0.0195312 3.80526 0.355318 3.5 0.769531 3.5ZM7.89453 7.25C7.89453 8.28553 7.05507 9.125 6.01953 9.125C4.984 9.125 4.14453 8.28553 4.14453 7.25C4.14453 6.21447 4.984 5.375 6.01953 5.375C7.05507 5.375 7.89453 6.21447 7.89453 7.25Z"
                              fill="#8792A2"
                            />
                          </svg>
                          <small
                            className="pl-2 font-weight-normal fs-10"
                            style={{ color: "rgba(105, 115, 134, 1)" }}
                          >
                            Transfer
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="mt-2">
                      <h4 className="d-flex">
                        <span
                          className="fw-700 preview_amount"
                          style={{ fontSize: "24px" }}
                        >
                          {currentData.currency === 'CNY' ? '¥' : '₦'}
                          {Number(currentData?.amount).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                        <span className="ml-3 ml-auto">
                          <div className=" ">
                            <div
                              className={`${currentData?.user_status} badge_style fit-content text-uppercase fw-500 fs-12 d-inline`}
                            >
                              {currentData?.user_status}
                            </div>
                          </div>
                        </span>
                      </h4>
                    </div>

                    <div className="mt-4 d-flex justify-content-between align-items-center">
                      <div>
                        <small className="d-block mb-1 font-weight-normal fs-13">
                          Date
                        </small>
                        <span className="fs-13 fw-500">
                          {fullNameDateFormat(currentData?.createdAt)}
                        </span>
                      </div>
                      <div className="text-right">
                        <small className="d-block mb-1 font-weight-normal fs-13">
                          Transaction ID
                        </small>
                        <small className="fw-500 fs-13 position-relative cursor-pointer"
                          onClick={() => copyToClipboard(currentData.transaction_ref)}
                        >
                          {currentData.transaction_ref}
                          <span className="ml-1">
                            <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.88777 8.3125V10.9375C8.88777 13.125 8.16813 14 6.36902 14H4.2101C2.41099 14 1.69135 13.125 1.69135 10.9375V8.3125C1.69135 6.125 2.41099 5.25 4.2101 5.25H6.36902C8.16813 5.25 8.88777 6.125 8.88777 8.3125Z" stroke="#5D20D2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M8.88777 8.3125V10.9375C8.88777 13.125 8.16813 14 6.36902 14H4.2101C2.41099 14 1.69135 13.125 1.69135 10.9375V8.3125C1.69135 6.125 2.41099 5.25 4.2101 5.25H6.36902C8.16813 5.25 8.88777 6.125 8.88777 8.3125Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M8.88777 8.3125V10.9375C8.88777 13.125 8.16813 14 6.36902 14H4.2101C2.41099 14 1.69135 13.125 1.69135 10.9375V8.3125C1.69135 6.125 2.41099 5.25 4.2101 5.25H6.36902C8.16813 5.25 8.88777 6.125 8.88777 8.3125Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M8.88777 8.3125V10.9375C8.88777 13.125 8.16813 14 6.36902 14H4.2101C2.41099 14 1.69135 13.125 1.69135 10.9375V8.3125C1.69135 6.125 2.41099 5.25 4.2101 5.25H6.36902C8.16813 5.25 8.88777 6.125 8.88777 8.3125Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M11.9719 4.5625V7.1875C11.9719 9.375 11.2523 10.25 9.45319 10.25H8.88776V8.3125C8.88776 6.125 8.16812 5.25 6.36901 5.25H4.77551V4.5625C4.77551 2.375 5.49516 1.5 7.29426 1.5H9.45319C11.2523 1.5 11.9719 2.375 11.9719 4.5625Z" stroke="#5D20D2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M11.9719 4.5625V7.1875C11.9719 9.375 11.2523 10.25 9.45319 10.25H8.88776V8.3125C8.88776 6.125 8.16812 5.25 6.36901 5.25H4.77551V4.5625C4.77551 2.375 5.49516 1.5 7.29426 1.5H9.45319C11.2523 1.5 11.9719 2.375 11.9719 4.5625Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M11.9719 4.5625V7.1875C11.9719 9.375 11.2523 10.25 9.45319 10.25H8.88776V8.3125C8.88776 6.125 8.16812 5.25 6.36901 5.25H4.77551V4.5625C4.77551 2.375 5.49516 1.5 7.29426 1.5H9.45319C11.2523 1.5 11.9719 2.375 11.9719 4.5625Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M11.9719 4.5625V7.1875C11.9719 9.375 11.2523 10.25 9.45319 10.25H8.88776V8.3125C8.88776 6.125 8.16812 5.25 6.36901 5.25H4.77551V4.5625C4.77551 2.375 5.49516 1.5 7.29426 1.5H9.45319C11.2523 1.5 11.9719 2.375 11.9719 4.5625Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </span>
                          {tooltipVisible && (
                            <div className="copy_tooltip">
                              Copied
                            </div>
                          )}
                        </small>
                      </div>
                    </div>

                    <div className={`${styles.dropdown_payInfo} mt-5`}>
                      <p className="fw-500 fs-14">Payment Information</p>
                      <div className="d-flex align-items-center">
                        <div className="w-75">
                          <span className="fs-14 fw-400">Amount</span>
                        </div>

                        <div className="w-100 text-right">
                          <span className="fs-14 text-dark">
                            {currentData.currency === 'CNY' ? '¥' : '₦'}
                            {Number(currentData?.amount).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="w-75">
                          <span className="fs-14 fw-400">Recipient</span>
                        </div>

                        <div className="w-100 text-right">
                          <span className="fs-14 text-dark">
                            {currentData?.account_name}
                          </span>
                        </div>
                      </div>
                      {/* <div className="d-flex align-items-center">
                        <div className="w-75">
                          <span className="fs-14 fw-400">Payment Type</span>
                        </div>

                        <div className="w-100 text-right">
                          <span className="fs-14 text-dark">
                            {currentData?.transfer_type?.replace(/_/g, ' ')}
                          </span>
                        </div>
                      </div> */}
                      <div className="d-flex align-items-center">
                        <div className="w-75">
                          <span className="fs-14 fw-400">Bank Name</span>
                        </div>

                        <div className="w-100 text-right">
                          <span className="fs-14 text-dark">
                            {currentData?.bank_name}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="w-75">
                          <span className="fs-14 fw-400">Account Number</span>
                        </div>

                        <div className="w-100 text-right">
                          <span className="fs-14 text-dark">
                            {currentData?.account_number}
                          </span>
                        </div>
                      </div>
                      {
                        currentData?.routing_id &&
                        <div className="d-flex align-items-center">
                          <div className="w-75">
                            <span className="fs-14 fw-400">Routing ID</span>
                          </div>

                          <div className="w-100 text-right">
                            <span className="fs-14 text-dark">{currentData?.routing_id}</span>
                          </div>
                        </div>
                      }
                      <div className="d-sm-flex align-items-center">
                        <div className="w-75">
                          <span className="fs-14 fw-400">Description</span>
                        </div>

                        <div className="w-100 text-sm-right">
                          <span
                            className="fs-14 d-block text-dark"
                            style={{ lineHeight: "1.5" }}
                          >
                            {/* Richard Phegley{" "} */}
                            {currentData.description}
                          </span>{" "}
                          {/* <span
                          className="fs-14 d-block"
                          style={{ lineHeight: "1.5" }}
                        >
                          richard.phegley@hotmail.com&gt;
                        </span> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" mt-5 px-4">
                    <button
                      type="button"
                      className="btn border d-block w-100 py-2 d-flex justify-content-center align-items-center"
                      onClick={exportReceiptToPDF}
                    >
                      <span>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.375 11.125V13C2.375 13.6904 2.93464 14.25 3.625 14.25H12.375C13.0654 14.25 13.625 13.6904 13.625 13V11.125"
                            stroke="#374151"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.5 8L8 10.5L10.5 8"
                            stroke="#374151"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 1.75V10.5"
                            stroke="#374151"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <span className="ml-2 py-1">Get PDF Receipt</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Stack>
          {/* <Box sx={{ p: 3 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              startIcon={<Iconify icon="ic:round-clear-all" />}
            >
              Clear All
            </Button>
          </Box> */}
        </Scrollbar>
      </Drawer>

      <Drawer
        anchor="right"
        open={editSchedule}
        onClose={handleCloseEditSchedule}
        PaperProps={{
          sx: {
            width: 300,
            maxWidth: "100%",
            border: "none",
            overflow: "",
            marginTop: "0em",
            height: "100vh",
            background: "rgba(249, 250, 251, 1)",
          },
        }}
      >

        <div className="px-3">
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ px: 0, pt: 2, }}
            className="pt-4 mt-2 mb-2"
          >
            <div className="text-left w-100">
              <p className="mb-0">
                <span className="text-dark fw-500 fs-15">Edit Schedule</span>
              </p>
              <p className="mb-0" style={{ fontWeight: '300', color: 'rgba(75, 85, 99, 1)' }}>
                <small className="fs-11">Edit a scheduled payment</small>
              </p>
            </div>
            <IconButton
              className="d-inline d-sm-none"
              onClick={() => {
                setEditSchedule(false);
                setActivateEdit(false);
              }}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Stack>
          <Divider />
        </div>
        <Scrollbar>
          <Stack spacing={2} sx={{ p: 2 }}>
            <div
              className={`modal_content`}
            // style={{overflowY: 'scroll', height: '450px'}}
            >
              {/* <div className="w-100 mb-3">
                                <label htmlFor="ounit">Payment Date</label>
                                <div
                                    className="w-100 payment_date"
                                    style={{ position: "relative" }}
                                >
                                    <span
                                        style={{
                                            position: "absolute",
                                            left: "10px",
                                            top: "5px",
                                            zIndex: "10",
                                        }}
                                    >
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.66602 1.6665V4.1665" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M13.334 1.6665V4.1665" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M2.91602 7.5752H17.0827" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M17.5 7.08317V14.1665C17.5 16.6665 16.25 18.3332 13.3333 18.3332H6.66667C3.75 18.3332 2.5 16.6665 2.5 14.1665V7.08317C2.5 4.58317 3.75 2.9165 6.66667 2.9165H13.3333C16.25 2.9165 17.5 4.58317 17.5 7.08317Z" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M13.0781 11.4167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M13.0781 13.9167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M9.99607 11.4167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M9.99607 13.9167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M6.91209 11.4167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M6.91209 13.9167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>

                                    </span>
                                    <DatePicker
                                        selected={paymentDate}
                                        onChange={(date) => setPaymentDate(date)}
                                        dateFormat="do MMMM yyyy"
                                        className="form-control border rounded-0 w-100"
                                        placeholderText="Select Payment Date"
                                        required
                                        fullWidth
                                        minDate={new Date()}
                                        readOnly={!activateEdit}
                                        disabled={!activateEdit}
                                    // renderCustomHeader={({ date }) => (
                                    //   <div className="">{paymentDate ? formatDateWithSuffix(paymentDate) : 'Select Payment Date'}</div>
                                    // )}
                                    />
                                </div>
                            </div> */}

              <div className={`wallet_modal_header m-0 w-100 mb-0`}>
                <label htmlFor="ounit">Payment Time</label>

                <div className=" d-flex">
                  <div className="" style={{ width: "100%" }}>
                    <div
                      className="mb-2 w-100 custom_short_datepkr bg-white"
                      style={{ position: "relative" }}
                    >
                      <span
                        className="bg-white"
                        style={{
                          position: "absolute",
                          left: "9px",
                          top: "5px",
                          zIndex: "20",
                          // transform: 'scale(0.85)'
                        }}
                      >
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.66699 2.1665V4.6665"
                            stroke="#9CA3AF"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.333 2.1665V4.6665"
                            stroke="#9CA3AF"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M2.91699 8.0752H17.0837"
                            stroke="#9CA3AF"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M17.5 7.58317V14.6665C17.5 17.1665 16.25 18.8332 13.3333 18.8332H6.66667C3.75 18.8332 2.5 17.1665 2.5 14.6665V7.58317C2.5 5.08317 3.75 3.4165 6.66667 3.4165H13.3333C16.25 3.4165 17.5 5.08317 17.5 7.58317Z"
                            stroke="#9CA3AF"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.0791 11.9167H13.0866"
                            stroke="#9CA3AF"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.0791 14.4167H13.0866"
                            stroke="#9CA3AF"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.99607 11.9167H10.0036"
                            stroke="#9CA3AF"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.99607 14.4167H10.0036"
                            stroke="#9CA3AF"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.91209 11.9167H6.91957"
                            stroke="#9CA3AF"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.91209 14.4167H6.91957"
                            stroke="#9CA3AF"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>

                      {/* <div className="p-2 border rounded-0 pl-5">
                          <p className="mb-0">2:00 PM</p>
                        </div> */}

                      <input
                        aria-label="Time"
                        onChange={handleTimeChange}
                        value={paymentTime}
                        className="form-control border"
                        type="time"
                        defaultValue={defaultTime}
                        readOnly={!activateEdit}
                        disabled={!activateEdit}
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="freq">Frequency</label>
                  <div className="" style={{ width: "100%" }}>
                    <div className="position-relative bg-white">
                      <span className=" all_selectdropdown_icon position-absolute bg-white px-2">
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z"
                            fill="#6B7280"
                          />
                        </svg>
                      </span>
                      <Select
                        labelId="freq"
                        id="freq"
                        fullWidth
                        size="small"
                        required
                        name="freq"
                        defaultValue="weekly"
                        value={scheduledOption}
                        onChange={handleScheduleChange}
                        className=""
                        readOnly={!activateEdit}
                        disabled={!activateEdit}
                      >
                        <MenuItem value="DAILY">Daily</MenuItem>
                        <MenuItem value="WEEKLY">Weekly</MenuItem>
                        <MenuItem value="MONTHLY">Monthly</MenuItem>
                        <MenuItem value="CUSTOM">Custom</MenuItem>
                      </Select>
                    </div>
                  </div>
                </div>
                {scheduledOption === "CUSTOM" && (
                  <>
                    <div className="mb-3">
                      <label htmlFor="freq">Interval</label>
                      <div className="" style={{ width: "100%" }}>
                        <div className="position-relative bg-white">
                          <span className=" all_selectdropdown_icon position-absolute bg-white px-2">
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z"
                                fill="#6B7280"
                              />
                            </svg>
                          </span>
                          <Select
                            labelId="freq"
                            id="freq"
                            fullWidth
                            size="small"
                            required
                            name="freq"
                            defaultValue=""
                            className=""
                            readOnly={!activateEdit}
                            disabled={!activateEdit}
                            onChange={handleInterval}
                            value={interval}
                          >
                            <MenuItem value="EVERY_5_DAYS">
                              Every 5 Days
                            </MenuItem>
                            <MenuItem value="EVERY_2_WEEKS">
                              Every 2 Weeks
                            </MenuItem>
                            <MenuItem value="EVERY_2_MONTHS">
                              Every 2 Months
                            </MenuItem>
                            <MenuItem value="BI_ANNUALLY">Bi-Annually</MenuItem>
                            <MenuItem value="QUARTERLY">Quarterly</MenuItem>
                          </Select>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="">Number of Occurence</label>
                      <div className="" style={{ width: "100%" }}>
                        <div className="">
                          <TextField
                            name=""
                            // multiline
                            // rows={4}
                            required
                            type="number"
                            id="ounit"
                            size="small"
                            placeholder=""
                            onChange={handleNoOfTimesChange}
                            fullWidth
                            value={noOfTimes}
                            readOnly={!activateEdit}
                            disabled={!activateEdit}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <label htmlFor="ounit">Next payment is on</label>

                <div className=" d-flex">
                  <div className="" style={{ width: "100%" }}>
                    <div
                      className="mb-3 w-100 custom_short_datepkr"
                      style={{ position: "relative" }}
                    >
                      <div className="p-2 border rounded-0 ">
                        <p className="mb-0">
                          {format(paymentDate, "do MMMM yyyy - h:mmaaa")}
                        </p>
                      </div>

                      {/* <DatePicker
                                        // showIcon
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control"
                                        placeholderText="Start date"
                                        required
                                    /> */}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="mt-2 modal_content"
                style={{ maxHeight: "70vh", overflowY: "scroll" }}
              >
                {activateEdit && (
                  <div
                    className="d-flex flex-wrap mb-5"
                    style={{ gap: "10px" }}
                  >
                    <button
                      type="button"
                      onClick={verifyScheduleTrue}
                      className="btn btn-primary"
                    >
                      <span className="text-white font-weight-normal">
                        Done
                        {
                          isLoading &&
                          <CircularProgress
                            style={{ color: "#fff" }}
                            className="text-white ml-1"
                            size="0.8rem"
                          />
                        }
                      </span>
                    </button>
                    <button
                      type="button"
                      onClick={handleResetActivateEdit}
                      className="btn border bg-white"
                    >
                      <span>Cancel</span>
                    </button>
                  </div>
                )}

                {!activateEdit && (
                  <div
                    className="d-flex flex-wrap mb-5"
                    style={{ gap: "10px" }}
                  >
                    <button
                      type="button"
                      onClick={() => setActivateEdit(true)}
                      className="btn btn-primary"
                    >
                      <span className="text-white font-weight-normal">
                        Edit
                      </span>
                    </button>
                    <button
                      type="button"
                      onClick={handleToggleSuccessDialog}
                      className="btn border bg-white"
                    >
                      <span>Stop</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Stack>
          {/* <Box sx={{ p: 3 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              startIcon={<Iconify icon="ic:round-clear-all" />}
            >
              Clear All
            </Button>
          </Box> */}
        </Scrollbar>
      </Drawer>

      <GlobalModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={paymentSuccessfulDialog}
        onClose={handleClosePaymentSuccessfulDialog}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="500px !important"
        maxWidth="100%"
        overflowY="scroll"
        bgcolor="#FFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div
          className={`modal_content`}
          style={{ overflowY: "scroll", height: "auto" }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center w-100">
            {/* <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="48" height="48" rx="24" fill="#D1FAE5" />
                            <path d="M17 25L21 29L31 19" stroke="#059669" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg> */}

            <div className="text-center pt-4">
              <h6>
                <span
                  className="text-dark modals_head_title"
                  style={{ fontWeight: "500" }}
                >
                  Stop Schedule
                </span>
              </h6>
              <p style={{ fontWeight: "300", color: "rgba(75, 85, 99, 1)" }}>
                <small>
                  Once you stop a schedule , pending payment will automatically
                  be cancelled
                </small>
              </p>
            </div>

            <div
              className="mt-2 modal_content w-100"
              style={{ overflowY: "scroll" }}
            >
              <div className="d-flex" style={{ gap: "10px" }}>
                <button
                  onClick={handleStopSchedule}
                  className="d-block w-100 btn py-2"
                  style={{ background: "rgba(254, 226, 226, 1)" }}
                  disabled={isLoading}
                >
                  <span
                    className="font-weight-normal"
                    style={{ color: "rgba(185, 28, 28, 1)" }}
                  >
                    Stop Schedule
                    {
                      isLoading &&
                      <CircularProgress
                        style={{ color: "#B91C1C" }}
                        className="text-white ml-1"
                        size="0.8rem"
                      />
                    }
                  </span>
                </button>
                <button
                  type="button"
                  onClick={() => setPaymentSuccessfulDialog(false)}
                  className="d-block w-100 btn py-2 border"
                >
                  <span>Cancel</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </GlobalModal>
    </>
  );
}
