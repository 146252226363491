import React from 'react';
import {Link} from '@mui/material';
import Styles from './CSSModules/FormPages.module.css';

const Footer = () => {
  return (
    <div className={`${Styles.signup_footer} mx-sm-auto ml-md-5 pl-md-5`}>
      <p><Link href=''>Terms and Condition</Link></p>
      <p><Link href=''>Privacy Policy</Link></p>
      <p>
        <a href='mailto:Hailey@onepointhq.com'>Support</a> 
        {/* | <a href='tel:+1234567890'>Support via Phone</a> */}
      </p>
    </div>
  );
};

export default Footer;
