import { arrowFaceSideIconSvg, roundShieldIconSvg } from '../svgs';
import './HomeFeatureSection.css';
import { useTranslation } from 'react-i18next';
const { useEffect, useState } = require('react');
const {
  HomeFeatureSectionData,
  featureTagData,
} = require('./HomeFeatureSectionData');

const HomeFeatureSection = () => {
  const { t } = useTranslation(['landing-page']);
  const [currentIndex, setCurenctIndex] = useState(0);

  const nextCard = () => {
    if (currentIndex === HomeFeatureSectionData.length - 1) {
      setCurenctIndex(0);
    } else {
      setCurenctIndex((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const autoPlay = setInterval(nextCard, 5000);

    return () => {
      clearInterval(autoPlay);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <div className="home-features">
      {HomeFeatureSectionData.map(({ heading, subText, image }, index) => (
        <div
          className={` home-feature`}
          style={{
            display: index === currentIndex ? 'grid' : 'none',
            transition: 'opacity 0.5s ease-in-out', // Optional for smooth transitions
            opacity: index === currentIndex ? 1 : 0,
          }}
          key={index}
        >
          <div
            className={`
            ${
              index === currentIndex ? 'active-card-left' : ' home-feature-left'
            }
            `}
          >
            <div className="home-feature-header">
              <h2>{t(heading)}</h2>
              <p>{t(subText)}</p>
            </div>

            <div className="home-feature-tags">
              {featureTagData.map(({ text }, indexTag) => (
                <div
                  className={`

                ${index === indexTag ? 'active-card-tag' : 'home-feature-tag'}

              `}
                >
                  <span>{t(text)}</span>
                </div>
              ))}
            </div>
          </div>

          <div
            className={`home-feature-right
                ${index === 0 ? 'right_animate-content-main-img__con' : ''}
          `}
          >
            {/* Animation ccontent third card index 2 */}
            <div
              className={`

                ${index === 2 ? 'right_animate-content-3' : 'right-content-3'}

                home-feature-right_animate-content

              `}
            >
              <div className="card-3-items right_animate-content-3__img1 ">
                <span className="clip-dark">{arrowFaceSideIconSvg()}</span>
                <span>{t('Quick')}</span>
              </div>

              <div className=" card-3-items  right_animate-content-3__img2">
                <span className="clip-dark">{arrowFaceSideIconSvg()}</span>
                <span>{t('Convenient')}</span>
              </div>

              <div className="card-3-items  right_animate-content-3__img3">
                <span className="clip-dark">{arrowFaceSideIconSvg()}</span>
                <span>{t('Easy')}</span>
              </div>
            </div>

            {/* first card content  index 0 */}
            <div
              className={` ${
                index === 0 ? 'right_animate-content-1' : 'right-content-1'
              }
             home-feature-right_animate-content

              `}
            >
              <img
                className="img-flip"
                src="/assets/images/card-1-img-topup.png"
                alt="top up icon"
              />

              <img
                className="img-scale"
                src="/assets/images/exchaange-rate-scale.png"
                alt="top up icon"
              />
            </div>

            {/* Second card content  2  */}
            <div
              className={` ${
                index === 1 ? 'right_animate-content-2' : 'right-content-2'
              }

                 home-feature-right_animate-content

              `}
            >
              <div className="card-2-items img-scale-2">
                <span>{roundShieldIconSvg()}</span>

                <p>{t('Reliable')}</p>
              </div>
            </div>

            <div className="main-img">
              <img src={image} alt={`feature service ${index}`} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomeFeatureSection;
