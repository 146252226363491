// ----------------------------------------------------------------------
import styles from "./LedgerTable.module.css";
import {
    // useEffect,
    useState,
} from "react";
import "react-datepicker/dist/react-datepicker.css";

import { Link as RouterLink } from "react-router-dom";

import {
    Link,
    //   Avatar,
} from "@mui/material";
import Pagination from '../../pagination/GlobalPagination'; // Adjust the path as necessary
import Scrollbar from "../../components/scrollbar";
// ----------------------------------------------------------------------


export default function TransactionsTable() {
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 8;


    const tableData = [
        {
            status: 'posted',
            amount: '₦24,000',
            cr_account: 'Cash',
            db_account: 'Debtors',
            currency: 'CNY',
            date: '24 Mar,2024 11:54 pm',
        },
        {
            status: 'posted',
            amount: '₦24,000',
            cr_account: 'Cash',
            db_account: 'Debtors',
            currency: 'CNY',
            date: '24 Mar,2024 11:54 pm',
        },
    ];

    const totalResults = tableData.length;

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    // Calculate the data to display on the current page
    const currentTableData = tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize);


    return (
        <>
            <div className="px-0">

                <div style={{ marginTop: "0px", borderRadius: "10px" }} className="p-0">
                    <Scrollbar>
                        <div
                            className={`${styles.overflow_table} table-responsive  pt- pb-3 pl-0 pr-0`}
                            style={{ minHeight: '80vh' }}
                        >
                            <table
                                className={`${styles.table} table-hover table-striped`}
                                style={{ minWidth: "50em" }}
                            >
                                <thead className={`${styles.thead}`}>
                                    <tr>
                                        <th className="text-muted fs-13 fw-600 th_width" style={{ minWidth: "6%" }}>
                                            Status {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </th>
                                        <th className="text-muted fs-13 fw-600 th_width">Amount {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg></th>
                                        <th className="text-muted fs-13 fw-600 th_width">Credit Account {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg></th>
                                        <th className="text-muted fs-13 fw-600 th_width">Debit Account {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg></th>
                                        <th className="text-muted fs-13 fw-600 th_width">Date Posted {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg></th>
                                        {/* <th className="text-muted fs-13 fw-600 th_width" style={{minWidth: '280px',}}>Normal Balance {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></th> */}
                                    </tr>
                                </thead>

                                <tbody>
                                    {currentTableData.map((item, index) => (
                                        <tr key={index} className={`${styles.tr} table-fonts`}>
                                            <td>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div className=" ">
                                                        <div className={`${item.status} badge_style fit-content text-uppercase`}>
                                                            <span className="fw-500">{item.status}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="fw-500">
                                                    {item.amount}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="fw-500">
                                                    {item.cr_account}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="fw-500">
                                                    {item.db_account}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="fw-500">
                                                    {item.date}
                                                </span>
                                            </td>
                                            <td className="" style={{ minWidth: '200px' }}>
                                                <div className="btn-group">
                                                    <span className="fw-500 btn rounded-0" data-toggle="dropdown"
                                                        aria-expanded="false">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4.5 10.5C3.675 10.5 3 11.175 3 12C3 12.825 3.675 13.5 4.5 13.5C5.325 13.5 6 12.825 6 12C6 11.175 5.325 10.5 4.5 10.5ZM19.5 10.5C18.675 10.5 18 11.175 18 12C18 12.825 18.675 13.5 19.5 13.5C20.325 13.5 21 12.825 21 12C21 11.175 20.325 10.5 19.5 10.5ZM12 10.5C11.175 10.5 10.5 11.175 10.5 12C10.5 12.825 11.175 13.5 12 13.5C12.825 13.5 13.5 12.825 13.5 12C13.5 11.175 12.825 10.5 12 10.5Z" fill="black" />
                                                        </svg>
                                                    </span>

                                                    <div
                                                        className="dropdown-menu dropdown-menu-right py-0 rounded border-0 overflow-hidden"
                                                        style={{
                                                            background: "rgba(249, 250, 251, 1)",
                                                            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                                                        }}
                                                    >
                                                        <Link
                                                            to="/app/ledger-management/edit-transaction"
                                                            component={RouterLink}
                                                            className="dropdown-item dropdown_text fw-500 position-relative"
                                                        >
                                                            Edit
                                                        </Link>
                                                    </div>
                                                </div>
                                            </td>
                                            {/* <td>
                                                <div className="btn-group d-flex">
                                                    <Link
                                                    to="/app/ledger-management/sales-ledger"
                                                    component={RouterLink}
                                                        className="p-2 ml-auto"
                                                        style={{ cursor: "pointer" }}

                                                    >
                                                        <svg
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="ml-auto"
                                                        >
                                                            <path
                                                                d="M7.60162 8.78101L10.6809 5.4812L11.5607 6.42401L7.60162 10.6667L3.64258 6.42401L4.52237 5.4812L7.60162 8.78101Z"
                                                                fill="#4B5563"
                                                            />
                                                        </svg>
                                                    </Link>
                                                </div>
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="pt-3">
                                <Pagination
                                    currentPage={currentPage}
                                    totalResults={totalResults}
                                    pageSize={pageSize}
                                    onPageChange={onPageChange}
                                />
                            </div>
                        </div>
                        {/* <div className="d-flex justify-content-center">

            </div> */}

                        {/* <div className="alert alert-primary text-center">
                <h4>No record found</h4>
              </div> */}
                    </Scrollbar>

                    {/* <Scrollbar>
                        <div
                            className={`${styles.overflow_table} table-responsive  pt- pb-3 pl-0 pr-0`}
                        >
                            <table
                                className={`${styles.table} table-hover table-striped`}
                                style={{ minWidth: "50em" }}
                            >
                                <thead className={`${styles.thead}`}>
                                    <tr>
                                        <th className="text-muted font-weight-bold th_width4" style={{ minWidth: "6%" }}>
                                           Description
                                        </th>
                                        <th className="text-muted font-weight-bold th_width2">Date</th>
                                        <th className="text-muted font-weight-bold th_width">Amount</th>
                                        <th className="text-muted font-weight-bold text-right">Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {tableData.map((item, index) => (
                                        <tr key={index} className={`${styles.tr} bg-white table-fonts`}>
                                            <td>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div className=" ">
                                                        <span>
                                                            {item.desc}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span>
                                                    {item.date}
                                                </span>
                                            </td>
                                            <td>
                                                <span style={{ fontWeight: '600' }}>
                                                    {item.balance}
                                                </span>
                                            </td>
                                            <td>
                                            <div className="merged_side_btn d-flex ml-auto">
                                                <div className="ml-auto">
                                                    <Link to="/app/ledger-management/add-entries" component={RouterLink} className='btn pl-2 border bg-white'>
                                                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.3952 4.04422L8.79049 1.43955L8.85588 1.37416C9.57514 0.654902 10.7413 0.654902 11.4606 1.37416C12.1798 2.09342 12.1798 3.25957 11.4606 3.97883L11.3952 4.04422ZM10.3345 5.10488L3.38607 12.0533L0 12.8347L0.781401 9.44864L7.72983 2.50021L10.3345 5.10488Z" fill="#4F566B" />
                                                        </svg>
                                                    </Link>
                                                    <Link to="" component={RouterLink} className='btn pl-2 border bg-white'>
                                                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.00088 5.77347L9.44486 2.32949C9.73802 2.03633 10.2133 2.03633 10.5065 2.32949C10.7997 2.62266 10.7997 3.09797 10.5065 3.39113L7.06301 6.83462L10.5065 10.2781C10.7997 10.5713 10.7997 11.0466 10.5065 11.3397C10.2133 11.6329 9.73802 11.6329 9.44486 11.3397L6.00088 7.89577L2.55691 11.3397C2.26374 11.6329 1.78843 11.6329 1.49526 11.3397C1.2021 11.0466 1.2021 10.5713 1.49526 10.2781L4.93875 6.83462L1.49526 3.39113C1.2021 3.09797 1.2021 2.62266 1.49526 2.32949C1.78843 2.03633 2.26374 2.03633 2.55691 2.32949L6.00088 5.77347Z" fill="#4F566B" />
                                                        </svg>
                                                    </Link>
                                                </div>
                                            </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Scrollbar> */}
                </div>
            </div>
        </>
    );
}
