import { Helmet } from "react-helmet-async";
// import * as Router from 'react-router-dom';
// import Scrollbar from "../../../components/scrollbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import DatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import TextField from "@mui/material/TextField";
import { Link as RouterLink } from "react-router-dom";
import Scrollbar from "../../components/scrollbar";

import {
    Link,
} from "@mui/material";
import {
    // useEffect, 
    useState
} from "react";
// import bus from "src/bus";
import styles from "./LedgerTable.module.css";
// ----------------------------------------------------------------------

export default function NewAccount() {
    const [dueDate, setDueDate] = useState(null);


    return (
        <>
            <Helmet>
                <title>Create Account - Ledger</title>
            </Helmet>
            <div className="wrapper">
                <div className={`${styles.header_wrap} d-sm-flex justify-content-between align-items-center`}>
                    <div className="d-flex">
                        <Link
                            to="/app/ledger-management/ledger-details"
                            component={RouterLink}
                            className="d-block text-secondary"
                        >
                            <i
                                className="bi bi-x"
                                style={{ fontSize: "1.5em", lineHeight: "1" }}
                            ></i>
                        </Link>
                        <h6 className="pl-2 pl-sm-3 mb-0 fw-700 pb-0" style={{ lineHeight: '1.5' }} >Create Account</h6>
                    </div>
                    <div className="header_btn_group d-none d-sm-block mt-2 mt-sm-0">
                        <div className="btn-group">
                            <button
                                className="btn border btn-primary"
                            >
                                <span className="button_text px-2 text-white">Create</span>
                            </button>
                            &nbsp;
                            &nbsp;
                            <Link
                                to="/app/ledger-management/ledger-details"
                                component={RouterLink}
                                className="btn border"
                            >
                                <span className="button_text px-2">Cancel</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="header_btn_group d-block d-sm-none mt-3 mt-sm-0">
                    <div className="btn-group">
                        <button
                            className="btn border btn-primary"
                        //   onClick={() => setTrsnPinDialog(true)}
                        >
                            <span className="button_text px-2 text-white">Create</span>
                        </button>
                        &nbsp;
                        &nbsp;
                        <Link
                            to="/app/ledger-management/ledger-details"
                            component={RouterLink}
                            className="btn border"
                        >
                            <span className="button_text px-2">Cancel</span>
                        </Link>
                    </div>
                </div>

                <div className="w-100 pb-5">
                    <div className="mx-auto" style={{ width: '26em', maxWidth: '100%' }}>
                        <div className={`${styles.trftobank_side_wrap} px-0 px-sm-2 py-sm-4`}>
                            <div>
                                <div className="mb-3">
                                    <label htmlFor="ounit">Name</label>
                                    <div className="" style={{ width: "100%" }}>
                                        <div className="">
                                            <TextField
                                                name="name"
                                                required
                                                type="text"
                                                id="ounit"
                                                size="small"
                                                placeholder=""
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="ounit">Normal Balance</label>
                                    <div className="" style={{ width: "100%" }}>
                                        <div className="position-relative">
                                            <span className=" all_selectdropdown_icon position-absolute bg-white px-2">
                                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z" fill="#6B7280" />
                                                </svg>
                                            </span>
                                            <Select
                                                labelId="s"
                                                id="s"
                                                fullWidth
                                                size="small"
                                                required
                                                name="verified"
                                                defaultValue="debit"
                                            >
                                                <MenuItem value="debit">Debit</MenuItem>
                                                <MenuItem value="credit">Credit</MenuItem>
                                            </Select>
                                        </div>
                                    </div>
                                </div>


                                <div className="pb-2 mb-3 d-flex justify-content-between align-items-center border-bottom">
                                    <h6 className=" mb-0 fw-700 pb-0" style={{ lineHeight: '1.5' }} >Additional Information</h6>
                                    <span className="fs-10 fw-500 cursor-pointer" style={{ fontStyle: 'italic', color: 'rgba(107, 114, 128, 1)' }}>optional</span>

                                </div>

                                <div className="mb-3">
                                    <label htmlFor="ounit">Description</label>
                                    <div className="" style={{ width: "100%" }}>
                                        <div className="">
                                            <TextField
                                                name="desc"
                                                required
                                                type="text"
                                                id="ounit"
                                                size="small"
                                                placeholder=""
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="pb-2 mb-3 d-flex justify-content-between align-items-center border-bottom">
                                    <h6 className=" mb-0 fw-700 pb-0" style={{ lineHeight: '1.5' }} >Customer Group</h6>
                                    <span className="fs-10 fw-500 cursor-pointer" style={{ fontStyle: 'italic', color: 'rgba(107, 114, 128, 1)' }}>optional</span>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="ounit">Select Group</label>
                                    <div className="" style={{ width: "100%" }}>
                                        <div className="position-relative">
                                            <span className=" all_selectdropdown_icon position-absolute bg-white px-2">
                                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z" fill="#6B7280" />
                                                </svg>
                                            </span>
                                            <Select
                                                labelId="s"
                                                id="s"
                                                fullWidth
                                                size="small"
                                                required
                                                name="verified"
                                                defaultValue="select"
                                            >
                                                <MenuItem value="select">Select</MenuItem>
                                                <MenuItem value="single-group">Single Group</MenuItem>
                                                <MenuItem value="bulk-group">Bulk Group</MenuItem>
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
