import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useState, useEffect } from 'react';

import {
    Link,
    Typography,
    Stack,
    Button
} from '@mui/material';
// components
import Logo from '../../components/logo';
// Module styles
import Styles from './CSSModules/FormPages.module.css'
import { Link as RouterLink } from "react-router-dom";

// sections
// import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';


import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import axiosInstance from 'src/axiosInstance';
import { BaseUrlContext } from 'src/context/BaseUrlContext';
import axios from 'axios';
import { useAuth } from 'src/hooks/useAuth';
import bus from 'src/bus';
import { mockupEmailVerifySuccess, mockupEmailVerifyError } from "src/hooks/useMockData";
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import Footer from './Footer';


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme, imageUrl }) => ({
    [theme.breakpoints.up('0')]: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        boxSizing: 'border-box',
        backgroundColor: '#FFFFFF',
        // backgroundImage: `url(${imageUrl})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        padding: theme.spacing(4, 0),
    },
}));

const StyledContent = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    overflowX: 'hidden',
    position: 'relative',
    width: '80em',
    maxWidth: '100%',
    margin: '0',
    minHeight: '90vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'space-between',
    flexDirection: 'column',
    padding: theme.spacing(3, 0),
}));

// ----------------------------------------------------------------------

export default function VerifyEmailPage() {
    const { dispatch } = useAuth();
    const apiUrl = process.env.REACT_APP_NAME;
    const authEmail = localStorage.getItem('e');
    const navigate = useNavigate();
    const baseUrl = useContext(BaseUrlContext);
    const imageUrl = '/assets/images/op_background.png';
    const [isPending, setIsPending] = useState(false);
    const [isResendPending, setIsResendPending] = useState(false);

    const [otp, setOtp] = useState('');
    const [countdown, setCountdown] = useState(120);
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    // const notify = (msg) => toast.success(msg);
    // const notify2 = () => notifySuccess('Verification token has been sent to your mail');
    // const notifyError = (err) => notifyError(err);


    // Function to handle OTP input changes
    const handleOtpChange = (event) => {
        const value = event.target.value;

        // Ensure the value is only numbers and has a maximum length of 4
        if (/^\d{0,4}$/.test(value)) {
            setOtp(value);
        }
    };



    useEffect(() => {
        // Start the countdown timer
        const timer = setTimeout(() => {
            if (countdown > 0) {
                setCountdown(countdown - 1);
                setIsResendDisabled(true); // Enable the "Resend Code" link
            } else {
                setIsResendDisabled(false); // Enable the "Resend Code" link
            }
        }, 1000);

        // Clear the timer when the component unmounts
        return () => clearTimeout(timer);
    }, [countdown]); // Re-run effect when countdown changes

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
        // return `${remainingSeconds.toString()}`;
    };

    const handleProceed = () => {
        navigate('/login');
    }

    // const requestMagicLink = async () => {
    //     let payload = {
    //         emailAddress: authEmail,
    //       };
    //     try {
    //       if (payload.emailAddress) {
    //         const res = await axiosInstance.post(`/accounts/magic-link-otp?productName=${apiUrl}`, payload);

    //         // localStorage.setItem("e", payload.emailAddress);    
    //       }

    //     } catch (err) {
    //         notifyError(err.response.data.data.errorMessage);

    //     } finally {
    //     }
    //   };

    // function HandleFormSubmit(e) {
    //     e.preventDefault();


    //     let payload = {
    //         email: authEmail,
    //         otp: otp
    //     }

    //     const fetchData = async () => {

    //         if (!payload.email) {
    //             notifyError('Email not found. Kindly register a new account or login if you have an existing account');
    //             setTimeout(() => {

    //                 navigate('/signup');
    //             }, 1000);
    //             return;
    //         }

    //         try {
    //             setIsPending(true);
    //             if (payload.email && payload.otp) {

    //                 const res = await axiosInstance.post(`/accounts/otp/validation?productName=${apiUrl}`, payload);
    //                   notifySuccess(res.data.message);
    //                   navigate('/login-via-link');
    //                   requestMagicLink()
    //             }
    //         } catch (err) {
    //             notifyError(err.response.data.data.errorMessage)
    //             setIsPending(false)
    //         } finally {
    //             setIsPending(false)
    //         }
    //     };
    //     fetchData(true);
    // }

    const requestMagicLink = async () => {
        let payload = {
            emailAddress: authEmail,
        };
        try {
            if (payload.emailAddress) {
                const res = await axiosInstance.post(`/accounts/magic-link-otp?productName=${apiUrl}`, payload);
                localStorage.setItem("e", payload.emailAddress);
                dispatch({ type: "LOGIN", payload: res.data.data });
                localStorage.setItem('awaiting_kyc_verification', JSON.stringify(false))
                localStorage.setItem('has_dived_in', JSON.stringify(false));

                navigate('/login-via-link');
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        }
    };

    const HandleFormSubmit = (e) => {
        e.preventDefault();

        let payload = {
            email: authEmail,
            otp: otp
        };

        const fetchData = async () => {
            if (!payload.email) {
                notifyError('Email not found. Kindly register a new account or login if you have an existing account');
                setTimeout(() => {
                    navigate('/signup');
                }, 1000);
                return;
            }

            try {
                setIsPending(true);
                if (payload.email && payload.otp) {
                    const res = await axiosInstance.post(`/accounts/otp/validation?productName=${apiUrl}`, payload);
                    localStorage.setItem("auth", JSON.stringify(res.data.data));

                    dispatch({ type: "LOGIN", payload: res.data.data });

                    localStorage.removeItem('e')
                    localStorage.setItem('awaiting_kyc_verification', JSON.stringify(false))
                localStorage.setItem('has_dived_in', JSON.stringify(false));
                notifySuccess(res.data.message);
                    navigate('/app/overview')

                    // requestMagicLink();
                }
            } catch (err) {
                if (err.response) {
                    if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                        notifyError(err.response.data.data.errorMessage);
                    } else if (err.response.data && err.response.data.message) {
                        notifyError(err.response.data.message);
                    } else {
                        notifyError('An unexpected error occurred');
                    }
                } else {
                    notifyError('Network error or server not responding');
                }
                setIsPending(false);
            } finally {
                setIsPending(false);
            }
        };

        fetchData();
    };

    const resendToken = async () => {
        try {
            setIsResendPending(true)
            // Make the API request
            let payload = {
                emailAddress: localStorage.getItem('e'),
            }
            //   setN([])
            //   setValues(['', '', '', '']);
            setOtp('')
            let inputsVal = document.querySelectorAll('.form-control');
            inputsVal.forEach(item => {
                item.value = ''
            })

            const response = await axiosInstance.post(`/accounts/otp/resend?productName=${apiUrl}`, payload);

            notifySuccess('Verification token has been sent to your mail')
        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsResendPending(false)
        }
    };


    return (
        <>
            <Helmet>
                <title>Verify Email</title>
            </Helmet>

            <StyledRoot imageUrl={imageUrl} className='px-3'>
                <StyledContent className={`${Styles.StyledContent}`}>
                    <div className={`${Styles.logo_wrap}`}>
                        <Logo />
                    </div>

                    <div className={`${Styles.signin_form_wrap} py-5 my-3 py-md-5 my-md-5 mx-sm-auto ml-md-5 pl-md-5`}>
                        <form onSubmit={HandleFormSubmit} className='w-100'>
                            <Typography variant='h4'>
                                Verify Email
                            </Typography>
                            {/* <div className={`${Styles.signup_switch}`}>
                            <span className='' style={{ color: '#4B5563' }}>We’ve sent a message to michealjoseph344@gmail.com with an otp to reset your password.</span>
                        </div> */}
                            <div className={`${Styles.input_box}`}>
                                <label>Enter Code</label>
                                <Box
                                    sx={{
                                        maxWidth: '100%',
                                    }}
                                >
                                    <TextField
                                        type='text'
                                        value={otp}
                                        onChange={handleOtpChange}
                                        fullWidth
                                        inputProps={{
                                            maxLength: 4,
                                            style: { textAlign: 'left' }
                                        }}
                                    />
                                </Box>
                            </div>
                            <div>
                                <Stack spacing={2} direction="row">
                                    <div className='w-100 white_circular_progress'>
                                        {isPending ? (
                                            <>
                                                <Button disabled className={`${Styles.signin_btn} ${Styles.signin_btn_bg} mb-0 position-relative`} style={{ verticalAlign: 'middle' }}>
                                                    <span className='fw-500 text-white'>Verifying...</span>
                                                    {isPending && <CircularProgress style={{ color: 'white' }} className='text-white' size="1rem" />}
                                                </Button>
                                            </>
                                        ) : (
                                            <>


                                                <Button disabled={otp === ''} type='submit' className={`${Styles.signin_btn} ${otp ? Styles.signin_btn_bg : ''} mb-0 position-relative`} style={{ verticalAlign: 'middle' }}>
                                                    <span className='fw-500 text-white'>Proceed</span>
                                                    {isPending && <CircularProgress style={{ color: 'white' }} className='text-white' size="1rem" />}
                                                </Button>
                                            </>
                                        )
                                        }
                                    </div>
                                </Stack>
                                {/* <Stack spacing={2} direction="row">
                                <Button onClick={handleProceed} className={`${Styles.signin_btn}`}>Proceed</Button>
                            </Stack> */}
                            </div>
                        </form>

                        {/* <div>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <span className='mr-2'>
                                        <i className='bi bi-clock' style={{ color: '#4B5563' }}></i>
                                    </span>
                        <label className='mb-0'>{formatTime(countdown)}</label>
                                </div>
                                <div className={`${Styles.forgot}`}>
                                    <Link href='/forgot-password' onClick={(e) => isResendDisabled && e.preventDefault()}>
                                    <i>
                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.62646 3.9397H11.8531C12.9598 3.9397 13.8531 4.83303 13.8531 5.9397V8.15304" stroke="#4A40DA" strokeWidth="2.62292" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M4.73313 1.83313L2.62646 3.93978L4.73313 6.04647" stroke="#4A40DA" strokeWidth="2.62292" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M13.8531 13.0599H4.62646C3.5198 13.0599 2.62646 12.1666 2.62646 11.0599V8.84656" stroke="#4A40DA" strokeWidth="2.62292" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M11.7461 15.1665L13.8528 13.0598L11.7461 10.9531" stroke="#4A40DA" strokeWidth="2.62292" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>

                                    </i> Resend Code</Link>
                                </div>
                            </Stack>
                        </div> */}

                        <div className='pt-3'>
                            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
                                {/* <div className='d-flex justify-content-center align-items-center'>
                                    <span className='mr-2'>
                                        <i className='bi bi-clock' style={{ color: '#4B5563' }}></i>
                                    </span>
                        <label className='mb-0'>{formatTime(countdown)}</label>
                                </div> */}
                                <div className={`${Styles.forgot} text-center`}>
                                    {/* <Link href=''>Forgot Password?</Link> */}
                                    <button
                                        disabled={isResendDisabled}
                                        // onClick={(e) => isResendDisabled && e.preventDefault()}
                                        onClick={resendToken}
                                        className={`${isResendDisabled ? 'text-dark' : 'text-muted'} text-center btn`}
                                    >
                                        {isResendDisabled && (
                                            <span className=' text-center'>Resend OTP in {formatTime(countdown)} seconds</span>
                                        )}

                                        {!isResendDisabled && (
                                            <>
                                                {isResendPending ? (
                                                    <>
                                                        <span className=' text-center'>Please wait...</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className=' text-center'>Resend OTP</span>
                                                    </>
                                                )
                                                }
                                            </>
                                        )}
                                    </button>
                                </div>
                            </Stack>
                        </div>

                    </div>
                    <Footer />
                </StyledContent>
            </StyledRoot>
        </>
    );
}
