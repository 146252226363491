import React from 'react';
import PropTypes from 'prop-types';

const Pagination = ({ currentPage, totalResults, totalItems, pageSize, onPageChange }) => {
  const totalPages = Math.ceil(totalResults);

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const pages = [];
  const visiblePages = 5;
  const generatePageNumbers = () => {
    let startPage = Math.max(currentPage - Math.floor(visiblePages / 2), 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (endPage - startPage < visiblePages - 1) {
      startPage = Math.max(endPage - visiblePages + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  return (
    <div className="card border-0 p-0 m-0 responsive_paginate_btn_sect" style={{background: 'transparent'}}>
      <div className="card-body pt-0 mt-0">
        <div className="d-sm-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center count_wrap mb-3 mb-sm-0">
            <small className="font-weight-normal">
              Showing {currentPage !== 0 ? pageSize * (currentPage - 1) + 1 : 0} to {currentPage !== 0 ? Math.min(currentPage * pageSize, totalItems ) : 0} of {totalItems ?  totalItems : 0} results
            </small>
          </div>

          <nav aria-label="Page navigation example" className="pagination_wrap">
            <ul className="pagination d-flex align-items-center">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={handlePrevious} aria-label="Previous" disabled={currentPage === 1}>
                  <span aria-hidden="true">
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M5.70711 0.292893C6.09763 0.683417 6.09763 1.31658 5.70711 1.70711L2.41421 5L5.70711 8.29289C6.09763 8.68342 6.09763 9.31658 5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711L0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289L4.29289 0.292893C4.68342 -0.0976311 5.31658 -0.0976311 5.70711 0.292893Z" fill="#6B7280" />
                    </svg>
                  </span>
                </button>
              </li>

              {currentPage > Math.floor(visiblePages / 2) + 1 && (
                <>
                  <li className="page-item">
                    <button className="page-link" onClick={() => onPageChange(1)}>1</button>
                  </li>
                  <li className="page-item">
                    <span className="page-link">...</span>
                  </li>
                </>
              )}

              {currentPage === 0 && (
                <>
                  <li className="page-item">
                    <button disabled className="page-link">1</button>
                  </li>
                </>
              )}

              {generatePageNumbers().map(page => (
                <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                  <button className="page-link" onClick={() => onPageChange(page)}>
                    {page}
                  </button>
                </li>
              ))}

              {currentPage < totalPages - Math.floor(visiblePages / 2) && (
                <>
                  <li className="page-item">
                    <span className="page-link">...</span>
                  </li>
                  <li className="page-item">
                    <button className="page-link" onClick={() => onPageChange(totalPages)}>{totalPages}</button>
                  </li>
                </>
              )}

              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                <button className="page-link" onClick={handleNext} aria-label="Next" disabled={currentPage === totalPages}>
                  <span aria-hidden="true">
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M0.292894 9.70711C-0.0976307 9.31658 -0.0976307 8.68342 0.292894 8.29289L3.58579 5L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292894C0.683417 -0.0976312 1.31658 -0.0976312 1.70711 0.292894L5.70711 4.29289C6.09763 4.68342 6.09763 5.31658 5.70711 5.70711L1.70711 9.70711C1.31658 10.0976 0.683418 10.0976 0.292894 9.70711Z" fill="#6B7280" />
                    </svg>
                  </span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalResults: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
