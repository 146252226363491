import React, { useState, useEffect } from 'react';
import './Carousel.css';
import { arrowIconSvg, circleArrowRightSvg } from '../svgs';
import { useTranslation } from 'react-i18next';

const imagesData = [
  '/assets/images/carousel-img1.png',

  '/assets/images/carousel-img2.png',

  '/assets/images/carousel-img3.png',
];
// Add more images here if needed

const Carousel = () => {
  const { t } = useTranslation(['landing-page']);
  const midImage = imagesData[1];
  const leftImage = imagesData[0];

  const [currentIndex, setCurrentIndex] = useState(0);

  const [isAnimating, setIsAnimating] = useState(false);

  const [images, setImages] = useState(imagesData);

  const [slides, setSlides] = useState([
    images[images.length - 1],
    ...images.slice(0, 2),
  ]);

  const nextSlide = () => {
    setIsAnimating(true);

    // Change positions
    setSlides((prevSlides) => {
      const newSlides = [
        ...prevSlides.slice(1),
        images[(currentIndex + 2) % images.length],
      ];
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      return newSlides;
    });

    setTimeout(() => {
      setIsAnimating(false);
    }, 4900);
  };

  // Noot in use
  // Just in case needed
  const prevSlide = () => {
    setIsAnimating(true);

    setSlides((prevSlides) => {
      const newSlides = [
        images[(currentIndex - 2 + images.length) % images.length],
        ...prevSlides.slice(0, 2),
      ];
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length
      );
      return newSlides;
    });

    setTimeout(() => {
      setIsAnimating(false);
    }, 4900);
  };

  // Call next slide on init load
  useEffect(() => {
    setImages(imagesData);
    // nextSlide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const autoPlay = setInterval(nextSlide, 5000);

    return () => clearInterval(autoPlay);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides]);

  return (
    <div className="carousel">
      {/* Desktop view */}
      <div className=" hide-desktop-view carousel__slides">
        {slides.map((image, index) => {
          let className = 'carousel__slide ';

          return (
            <div
              key={index}
              className={`${className}

              ${
                index === 0 && isAnimating
                  ? 'animate-carousel__slide--left '
                  : index === 0
                  ? ' carousel__slide--left'
                  : ''
              }

               ${
                 index === 1 && isAnimating
                   ? 'animate-carousel__slide--mid'
                   : index === 1
                   ? ' carousel__slide--mid'
                   : ''
               }

              ${
                index === 2 && isAnimating
                  ? 'animate-carousel__slide--right '
                  : index === 2
                  ? '  carousel__slide--right'
                  : ''
              }

             carousel__slide-con `}
            >
              {/* Send glass */}
              {index === 1 && (
                <div
                  className={`carousel-glass-tag carousel-mid-tag
                  ${
                    index === 1 && isAnimating
                      ? 'animate-carousel-glass-right'
                      : 'remove-animation-glass'
                  }
                `}
                >
                  <span className="clip-dark">{arrowIconSvg()}</span>
                  <span>{t('Send')}</span>
                </div>
              )}

              {/* Receive glass */}
              {index === 2 && (
                <div
                  className={`mobile-glass carousel-glass-tag



                ${
                  index === 2 && isAnimating
                    ? 'animate-carousel-glass-right'
                    : 'remove-animation-glass'
                }
                 carousel-right-tag
                `}
                >
                  <span className="clip-dark-right">{arrowIconSvg()}</span>
                  <span>{t('Receive')}</span>
                </div>
              )}

              <img src={image} alt={`Slide ${index}`} />
            </div>
          );
        })}
      </div>

      {/* Mobile view */}
      <div className="hide-mobile-view carousel__slides">
        {slides.map((image, index) => {
          let className = 'carousel__slide ';

          return (
            <div
              key={index}
              className={`${className}

              ${
                index === 1 && isAnimating
                  ? 'animate_mobile_mid'
                  : index === 1
                  ? 'carousel-mobile__slide--mid'
                  : ''
              }


              ${
                index === 0 && isAnimating
                  ? 'animate_mobile_left'
                  : index === 0
                  ? 'carousel-mobile__slide--left'
                  : ''
              }

                ${index === 2 ? 'carousel-mobile__slide--right' : ''}




            `}
            >
              {/* Send glass */}
              {[midImage, leftImage].includes(image) && (
                <div
                  className={`glass-mobile-view carousel-glass-tag

                `}
                >
                  <span
                    className={`  ${
                      image === midImage ? 'clip-dark' : 'clip-dark-right'
                    }`}
                  >
                    {arrowIconSvg()}
                  </span>
                  <span className="span-text">
                    {image === midImage ? t('Send') : ''}
                    {image === leftImage ? t('Receive') : ''}
                  </span>
                </div>
              )}

              <img className="main-img" src={image} alt={`Slide ${index}`} />
            </div>
          );
        })}
      </div>

      {/* Dot */}
      <div className="carousel__dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={`carousel__dot ${
              index === currentIndex ? 'carousel__dot--active' : ''
            }`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>

      {/* <button
        className="carousel__button carousel__button--prev"
        onClick={prevSlide}
      >
        ‹
      </button>
      <button
        className="carousel__button carousel__button--next"
        onClick={nextSlide}
      >
        ›
      </button> */}
    </div>
  );
};

export default Carousel;
