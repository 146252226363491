import { Helmet } from "react-helmet-async";
// import * as Router from 'react-router-dom';
// import Scrollbar from "../../../components/scrollbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import DatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import TextField from "@mui/material/TextField";
import { Link as RouterLink } from "react-router-dom";

import {
    Link,
    Checkbox,

} from "@mui/material";
import {
    // useEffect, 
    useState
} from "react";
// import bus from "src/bus";
import GlobalModal from "../../../Modals/GlobalModal";
import styles from "../Payment.module.css";
// ----------------------------------------------------------------------

export default function EditInvoice() {
    const [addCustomer, setAddCustomer] = useState(false);
    const [dueDate, setDueDate] = useState(null);
    const [save, setSave] = useState(false);
    const [existingBen, setExistingBen] = useState(false);
    const [status01, setStatus01] = useState(false);
    const [status02, setStatus02] = useState(false);
    const [status03, setStatus03] = useState(false);

    const handleChangeStatus01 = () => {
        setStatus01(!status01)
    }
    const handleChangeStatus02 = () => {
        setStatus02(!status02)
    }
    const handleChangeStatus03 = () => {
        setStatus03(!status03)
    }
    const handleCloseExistingBen = () => {
        setExistingBen(false);
    };
    const handleChangeSave = () => {
        setSave(!save)
    }

    const handleCloseAddCustomer = () => {
        setAddCustomer(false);
    };


    return (
        <>
            <Helmet>
                <title> Edit Invoice </title>
            </Helmet>
            <div className="wrapper">
                <div className={`${styles.header_wrap} d-flex justify-content-between align-items-center`}>
                    <div className="d-flex">
                        <Link
                            className="d-block text-secondary"
                            to="/app/payment/invoice"
                            component={RouterLink}
                        >
                            <i
                                className="bi bi-x"
                                style={{ fontSize: "1.5em", lineHeight: "1" }}
                            ></i>
                        </Link>
                        <h6 className="pl-2 pl-sm-3 mb-0" style={{ fontWeight: "bold" }}>Edit Invoice</h6>
                    </div>
                    <div>
                        <div className="btn-group">
                            <Link
                                className="btn border btn-primary"
                                to="/app/payment/invoice"
                                component={RouterLink}
                            >
                                <span className="button_text text-white">Update</span>
                            </Link>
                            <Link
                                className="btn border ml-2 ml-sm-3"
                                to="/app/payment/invoice/preview"
                                component={RouterLink}
                            >
                                <span className="button_text">Preview</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className={`${styles.trftobank_side_wrap} mt-sm-4 px-sm-3 px-md-5 pt-4 py-sm-4`}>
                    <div>
                        <div className="mb-3">
                            <label htmlFor="name">Settlement Account</label>
                            <Select
                                labelId="s"
                                id="s"
                                fullWidth
                                size="small"
                                required
                                name="verified"
                                defaultValue="operation"
                            >
                                <MenuItem value="operation">Operations - $1,100</MenuItem>
                                <MenuItem value="banking">Banking - $1,100</MenuItem>
                                <MenuItem value="finance">Finance - $1,100</MenuItem>
                            </Select>
                            <small className="text-secondary font-weight-normal">
                                Select account to credit once payment is sent
                            </small>
                        </div>

                        {/* <div className="mb-3">
              <label htmlFor="">Input Amount</label>
              <div className={`${styles.charges_input}`}>
                <div className={`${styles.select_wrap}`}>
                  <Select
                    labelId="s"
                    id="s"
                    size="small"
                    required
                    name="verified"
                    defaultValue="ngn"
                    style={{ width: "fit-content" }}
                  >
                    <MenuItem value="ngn">NGN</MenuItem>
                    <MenuItem value="CNY">CNY</MenuItem>
                  </Select>
                </div>
                <input
                  type="text"
                  placeholder="1200"
                  style={{ width: "100%", textIndent: "75px" }}
                  className=" form-control"
                />
              </div>
              <small className="text-secondary font-weight-normal">
                charge for transaction NGN10
              </small>
            </div> */}

                        {/* <div className="mb-3">
              <label htmlFor="ounit">Bank Name</label>
              <div className="" style={{ width: "100%" }}>
                <div className="">
                  <TextField
                    name="min_products"
                    required
                    type="text"
                    id="ounit"
                    size="small"
                    placeholder=""
                    fullWidth
                  />
                </div>
              </div>
            </div> */}

                        {/* <div className="mb-3">
              <label htmlFor="ounit">Account Number</label>
              <div className="" style={{ width: "100%" }}>
                <div className="">
                  <TextField
                    name="min_products"
                    required
                    type="number"
                    id="ounit"
                    size="small"
                    placeholder=""
                    fullWidth
                  />
                </div>
              </div>
              <small className="d-block text-center mt-2">
                <Link
                  className="text-primary font-weight-bold"
                  component={RouterLink}
                  onClick={() => setAddCustomer(true)}
                >
                  Select from existing beneficiaries
                </Link>
              </small>
            </div> */}

                        {/* <div className="mb-3">
              <label htmlFor="ounit">Description</label>
              <div className="" style={{ width: "100%" }}>
                <div className="">
                  <TextField
                    name="min_products"
                    required
                    type="text"
                    id="ounit"
                    size="small"
                    placeholder=""
                    fullWidth
                  />
                </div>
              </div>
            </div> */}

                        {/* <div className="mt-5">
              <Link to="/app/wallet/payment-otp" component={RouterLink} className="btn border btn-primary">
              <span className="button_text text-white">Proceed</span>
              </Link>
              &nbsp; &nbsp;
              <Link
                className="btn border"
                to="/app/wallet/overview"
                component={RouterLink}
              >
                <span className="button_text">Cancel</span>
              </Link>
            </div> */}
                    </div>
                </div>

                <div className="pb-2 px-sm-3 px-md-5 border-bottom">
                    <label htmlFor="">Customer Details</label>
                </div>
                <div className={`py-4 px-sm-3 px-md-5 `}>
                    <div className="row">
                        <div className="col-md-4 mb-0 pb-0">
                            <div className="">
                                <label htmlFor="ounit">To</label>
                                <div className="" style={{ width: "100%" }}>
                                    <div className="">
                                        <TextField
                                            name="min_products"
                                            required
                                            type="text"
                                            id="ounit"
                                            size="small"
                                            placeholder=""
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-0 pb-0">
                            <div className="">
                                <label htmlFor="ounit">Forward To;</label>
                                <div className="" style={{ width: "100%" }}>
                                    <div className="">
                                        <TextField
                                            name="min_products"
                                            required
                                            type="text"
                                            id="ounit"
                                            size="small"
                                            placeholder=""
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-0 pb-0">
                            <div className="">
                                <label htmlFor="ounit">Due Date</label>
                                <div className="" style={{ width: "fit-content" }}>
                                    <div
                                        className=""
                                        style={{ position: "relative" }}
                                    >
                                        <span
                                            style={{
                                                position: "absolute",
                                                right: "25px",
                                                top: "5px",
                                                zIndex: "10",
                                            }}
                                        >
                                            <svg
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M2.40527 4.29523C2.40527 3.38641 3.14202 2.64966 4.05084 2.64966H10.6331C11.5419 2.64966 12.2787 3.3864 12.2787 4.29523V10.8775C12.2787 11.7863 11.5419 12.5231 10.6331 12.5231H4.05084C3.14202 12.5231 2.40527 11.7863 2.40527 10.8775V4.29523Z"
                                                    stroke="#7B8794"
                                                    strokeWidth="1.64557"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M9.53516 1.55298V3.74707"
                                                    stroke="#7B8794"
                                                    strokeWidth="1.64557"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M5.14844 1.55298V3.74707"
                                                    stroke="#7B8794"
                                                    strokeWidth="1.64557"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M2.40527 5.94092H12.2787"
                                                    stroke="#7B8794"
                                                    strokeWidth="1.64557"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        <DatePicker
                                            // showIcon
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={15}
                                            selected={dueDate}
                                            onChange={(date) => setDueDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control"
                                            placeholderText=""
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button className="btn px-0" onClick={() => setAddCustomer(true)}>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 12.5H18" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 18.5V6.5" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span className="ml-1 text-primary">Add Customer</span>
                        </button>
                    </div>
                </div>





                <div className="pb-2 px-sm-3 px-md-5 border-bottom">
                    <label htmlFor="">Product Details</label>
                </div>
                <div className={`py-4 px-sm-3 px-md-5 `}>
                    <div className="row">
                        <div className="col-md-4 mb-0 pb-0 pr-sm-1">
                            <div className="">
                                <label htmlFor="ounit">Invoiced Item</label>
                                <div className="" style={{ width: "100%" }}>
                                    <div className="">
                                        <TextField
                                            name="min_products"
                                            required
                                            type="text"
                                            id="ounit"
                                            size="small"
                                            placeholder=""
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="checkbox_div pt-2">
                                    <Checkbox id="schedule" checked={save} onClick={handleChangeSave} style={{}} />
                                    <label htmlFor="schedule" className="ml-1" style={{ fontSize: '12px', cursor: 'pointer' }}>Save Product</label>
                                </div>
                                <button className="btn p-0 m-0">
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 12.5H18" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12 18.5V6.5" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className="ml-1 text-primary" style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>Add another item</span>
                                </button>
                            </div>
                        </div>

                        <div className="col-md-2 mb-0 pb-0 px-sm-1">
                            <div className="">
                                <label htmlFor="ounit">Quantity</label>
                                <div className="" style={{ width: "100%" }}>
                                    <div className="">
                                        <TextField
                                            name="min_products"
                                            required
                                            type="text"
                                            id="ounit"
                                            size="small"
                                            placeholder=""
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2 mb-0 pb-0 px-sm-1">
                            <div className="">
                                <label htmlFor="ounit">Unit Price</label>
                                <div className="" style={{ width: "100%" }}>
                                    <div
                                        className=""
                                        style={{ position: "relative" }}
                                    >
                                        <div className={`${styles.charges_input}`}>
                                            <div className={`${styles.select_wrap}`}>
                                                <Select
                                                    labelId="s"
                                                    id="s"
                                                    size="small"
                                                    required
                                                    name="verified"
                                                    defaultValue="ngn"
                                                    style={{ width: "fit-content" }}
                                                >
                                                    <MenuItem value="ngn">NGN</MenuItem>
                                                    <MenuItem value="CNY">CNY</MenuItem>
                                                </Select>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder=""
                                                style={{ width: "100%", textIndent: "0px" }}
                                                className=" form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-0 pb-0">
                            <div className="ml-auto h-100 d-flex justify-content-end align-items-center" style={{ width: 'fit-content' }}>
                                <button className="btn" onClick={() => setExistingBen(true)}>
                                    <span className="ml-1 text-primary">Select from product list</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="pb-2 px-sm-3 px-md-5 border-bottom">
                    <label htmlFor="">Add-ons & Total</label>
                </div>
                <div className={`py-4 px-sm-3 px-md-5 `} style={{ width: '55em', maxWidth: '100%' }}>
                    <div className="d-flex justify-content-between py-2">
                        <span style={{ color: 'rgba(102, 112, 133, 1)' }}>Subtotal</span>
                        <p className="mb-0" style={{ fontSize: '18px', fontWeight: '500' }}>₦ 0.00</p>
                    </div>






                    <div className="d-flex justify-content-between py-2">
                        <span style={{ color: 'rgba(102, 112, 133, 1)', }}>Shipping Fee</span>
                        <div className="mb-0" style={{ width: '150px', }}>
                            {status02 === true && (
                                <div>
                                    <div className={`${styles.charges_input}`} onClick={() => setStatus02(true)}>
                                        <div className={`${styles.select_wrap} bg-white px-1`}>
                                            <Select
                                                labelId="s"
                                                id="s"
                                                size="small"
                                                required
                                                name="verified"
                                                defaultValue="ngn"
                                                style={{ width: "fit-content" }}
                                            >
                                                <MenuItem value="ngn">NGN</MenuItem>
                                                <MenuItem value="CNY">CNY</MenuItem>
                                            </Select>
                                        </div>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{ width: "100%", textIndent: '0' }}
                                            className=" form-control"
                                        />
                                    </div>
                                    <small className="d-block text-primary text-right py-1" style={{ cursor: 'pointer' }} onClick={handleChangeStatus02}>Save</small>
                                </div>
                            )}

                            {status02 === false && (
                                <button className="btn px-0 d-block w-100" onClick={() => setStatus02(true)}>
                                    <span className="ml-1 d-block text-primary text-right">Add Shipping Fee</span>
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="d-flex justify-content-between py-2">
                        <span style={{ color: 'rgba(102, 112, 133, 1)', }}>Discount</span>
                        <div className="mb-0" style={{ width: '150px', }}>
                            {status01 === true && (
                                <div>
                                    <div className={`${styles.charges_input}`} onClick={() => setStatus01(true)}>
                                        <div className={`${styles.select_wrap} bg-white px-1`}>
                                            <Select
                                                labelId="s"
                                                id="s"
                                                size="small"
                                                required
                                                name="verified"
                                                defaultValue="ngn"
                                                style={{ width: "fit-content" }}
                                            >
                                                <MenuItem value="ngn">NGN</MenuItem>
                                                <MenuItem value="CNY">CNY</MenuItem>
                                            </Select>
                                        </div>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{ width: "100%", textIndent: '0' }}
                                            className=" form-control"
                                        />
                                    </div>
                                    <small className="d-block text-primary text-right py-1" style={{ cursor: 'pointer' }} onClick={handleChangeStatus01}>Save</small>
                                </div>
                            )}

                            {status01 === false && (
                                <button className="btn px-0 d-block w-100" onClick={() => setStatus01(true)}>
                                    <span className="ml-1 d-block text-primary text-right">Add Discount</span>
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="d-flex justify-content-between py-2">
                        <span style={{ color: 'rgba(102, 112, 133, 1)', }}>Tax</span>
                        <div className="mb-0" style={{ width: '100px', }}>
                            {status03 === true && (
                                <div>
                                    <div className={`${styles.charges_input}`} onClick={() => setStatus03(true)}>
                                        <div className={`${styles.select_wrap} ${styles.hastemplate} bg-white px-1`}>
                                            <small className="d-block text-right">%</small>
                                        </div>
                                        <input
                                            type="text"
                                            placeholder=""
                                            style={{ width: "100%", textIndent: '0' }}
                                            className=" form-control"
                                        />
                                    </div>
                                    <small className="d-block text-primary text-right py-1" style={{ cursor: 'pointer' }} onClick={handleChangeStatus03}>Save</small>
                                </div>
                            )}

                            {status03 === false && (
                                <button className="btn px-0 d-block w-100" onClick={() => setStatus03(true)}>
                                    <span className="ml-1 d-block text-primary text-right">Add Tax</span>
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="d-flex justify-content-between py-2 pb-5 border-top">
                        <span style={{ fontWeight: '700' }}>Total</span>
                        <p className="mb-0">
                            <span style={{ fontWeight: '700' }}>NGN 240,000</span>
                        </p>
                    </div>
                </div>
            </div>

            {/* Customer Modal */}
            <GlobalModal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={addCustomer}
                onClose={handleCloseAddCustomer}
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                width="500px !important"
                maxWidth="100%"
                overflowY="scroll"
                bgcolor="#FFFF"
                border="1px solid #F5F5F5"
                borderRadius="0px"
                boxShadow="24"
                p="25px"
            >
                <div
                    className={`modal_content`}
                // style={{overflowY: 'scroll', height: '450px'}}
                >
                    <div className="mb-4 d-flex justify-content-between align-items-start">
                        <div>
                            <h5 style={{ fontWeight: '500' }}>Add Customer</h5>
                            <span><small>Add to your list of existing customers</small></span>
                        </div>
                        {/* <span
                            className="px-2 py-0"
                            style={{ fontSize: "25px", cursor: "pointer", lineHeight: '1' }}
                            onClick={() => setAddCustomer(false)}
                        >
                            &times;
                        </span> */}
                    </div>

                    <div
                        className="mt-4 modal_content"
                        style={{ maxHeight: "70vh", overflowY: "scroll" }}
                    >
                        <div className="mb-3">
                            <label htmlFor="ounit">Customer Name</label>
                            <div className="" style={{ width: "100%" }}>
                                <div className="">
                                    <TextField
                                        name="min_products"
                                        required
                                        type="text"
                                        id="ounit"
                                        size="small"
                                        placeholder=""
                                        fullWidth
                                        defaultValue={`Michael Joseph`}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="ounit">Email Address</label>
                            <div className="" style={{ width: "100%" }}>
                                <div className="">
                                    <TextField
                                        name="min_products"
                                        required
                                        type="email"
                                        id="ounit"
                                        size="small"
                                        placeholder=""
                                        fullWidth
                                        defaultValue={`michael@gmail.com`}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="ounit">Phone Number</label>
                            <div className="" style={{ width: "100%" }}>
                                <div className="">
                                    <TextField
                                        name="min_products"
                                        required
                                        type="text"
                                        id="ounit"
                                        size="small"
                                        placeholder=""
                                        fullWidth
                                        defaultValue={`070XXXXXX`}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mt-5">
                            <Link
                                onClick={() => setAddCustomer(false)}
                                className="btn border btn-primary">
                                <span className="button_text text-white">Done</span>
                            </Link>
                            &nbsp; &nbsp;
                            <Link
                                className="btn border"
                                onClick={() => setAddCustomer(false)}
                            >
                                <span className="button_text">Cancel</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </GlobalModal>


            {/* Select Existing Beneficiary Modal */}
            <GlobalModal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={existingBen}
                onClose={handleCloseExistingBen}
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                width="500px !important"
                maxWidth="100%"
                overflowY="scroll"
                bgcolor="#FFFF"
                border="1px solid #F5F5F5"
                borderRadius="5px"
                boxShadow="24"
                p="25px"
            >
                <div
                    className={`modal_content`}
                // style={{overflowY: 'scroll', height: '450px'}}
                >
                    <div className="mb-4 d-flex justify-content-between align-items-start">
                        <h5>Product</h5>
                        <span
                                className="px-3 py-2"
                                style={{ fontSize: "25px", cursor: "pointer", position: 'absolute', top: '0', right: '0' }}
                                onClick={() => setExistingBen(false)}
                            >
                                &times;
                            </span>
                    </div>
                    <div className={`modal_header mb-0`}>
                        <div className={`${styles.hold_input1} w-100`}>
                            <img src="/assets/icons/search.svg" alt="" />
                            <input
                                type="text"
                                name="search"
                                placeholder="Search"
                                style={{ paddingLeft: "33px", }}
                                className="fs-13 form-control rounded-0 border w-100"
                            />
                        </div>
                    </div>

                    <div
                        className="mt-4 modal_content"
                        style={{ maxHeight: "70vh", overflowY: "scroll" }}
                    >
                        <div
                            className="border-bottom py-3"
                            onClick={() => setExistingBen(false)}
                            style={{
                                cursor: "pointer",
                            }}
                        >
                            <div>
                                <div className=" ">
                                    <p className="mb-0 text-dark" style={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: '500' }}>iPhone 15</span>
                                    </p>
                                    <span className="text-muted" style={{ fontSize: "13px" }}>
                                        N12,000
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom py-3">
                            <div
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <div className=" ">
                                    <p className="mb-0 text-dark" style={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: '500' }}>Product Management</span>
                                    </p>
                                    <span className="text-muted" style={{ fontSize: "13px" }}>
                                        N30,000
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom py-3">
                            <div
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <div className=" ">
                                    <p className="mb-0 text-dark" style={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: '500' }}>Macbook Pro</span>
                                    </p>
                                    <span className="text-muted" style={{ fontSize: "13px" }}>
                                        N24,000
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom py-3">
                            <div
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <div className=" ">
                                    <p className="mb-0 text-dark" style={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: '500' }}>Product Management</span>
                                    </p>
                                    <span className="text-muted" style={{ fontSize: "13px" }}>
                                        N30,000
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom py-3">
                            <div
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <div className=" ">
                                    <p className="mb-0 text-dark" style={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: '500' }}>Macbook Pro</span>
                                    </p>
                                    <span className="text-muted" style={{ fontSize: "13px" }}>
                                        N24,000
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom py-3">
                            <div
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <div className=" ">
                                    <p className="mb-0 text-dark" style={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: '500' }}>Product Management</span>
                                    </p>
                                    <span className="text-muted" style={{ fontSize: "13px" }}>
                                        N30,000
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom py-3">
                            <div
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <div className=" ">
                                    <p className="mb-0 text-dark" style={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: '500' }}>Macbook Pro</span>
                                    </p>
                                    <span className="text-muted" style={{ fontSize: "13px" }}>
                                        N24,000
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom py-3">
                            <div
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <div className=" ">
                                    <p className="mb-0 text-dark" style={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: '500' }}>Product Management</span>
                                    </p>
                                    <span className="text-muted" style={{ fontSize: "13px" }}>
                                        N30,000
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom py-3">
                            <div
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <div className=" ">
                                    <p className="mb-0 text-dark" style={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: '500' }}>Macbook Pro</span>
                                    </p>
                                    <span className="text-muted" style={{ fontSize: "13px" }}>
                                        N24,000
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom py-3">
                            <div
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <div className=" ">
                                    <p className="mb-0 text-dark" style={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: '500' }}>Product Management</span>
                                    </p>
                                    <span className="text-muted" style={{ fontSize: "13px" }}>
                                        N30,000
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom py-3">
                            <div
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <div className=" ">
                                    <p className="mb-0 text-dark" style={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: '500' }}>Macbook Pro</span>
                                    </p>
                                    <span className="text-muted" style={{ fontSize: "13px" }}>
                                        N24,000
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom py-3">
                            <div
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <div className=" ">
                                    <p className="mb-0 text-dark" style={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: '500' }}>Product Management</span>
                                    </p>
                                    <span className="text-muted" style={{ fontSize: "13px" }}>
                                        N30,000
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom py-3">
                            <div
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <div className=" ">
                                    <p className="mb-0 text-dark" style={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: '500' }}>Macbook Pro</span>
                                    </p>
                                    <span className="text-muted" style={{ fontSize: "13px" }}>
                                        N24,000
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </GlobalModal>
        </>
    );
}
