// TransactionPINDialog.js
import React, { useState, useEffect } from 'react';
import { Drawer, Stack, IconButton, FormControl, OutlinedInput, InputAdornment, CircularProgress } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PropTypes from 'prop-types';
import Iconify from 'src/components/iconify';

const TransactionPINDialog = ({ open, onClose, onSubmit, isLoading, pinError }) => {
  const [PIN, setPIN] = useState("");
  const [showPIN, setShowPIN] = useState(false);
  const [error, setError] = useState(false);
  const [trialCount, setTrialCount] = useState(3);

  const handleClickShowPIN = () => setShowPIN((show) => !show);
  const handleMouseDownPIN = (event) => event.preventDefault();
  const handlePINChange = (event) => {
    const value = event.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setPIN(value);
    } 
    
  };

  const handleSendTransfer = () => {
    if (PIN.length === 4) {
      onSubmit(PIN);
    //   setPIN("");
    }
  };

  useEffect(() => {
    if (!open) {
      setPIN("");
    }
  }, [open]);

  useEffect(() => {
    if (!pinError) {
      setError(false);
    } else {
      setError(true);
      setTrialCount(prevCount => prevCount - 1);
    }
  }, [pinError]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 350,
          maxWidth: "100%",
          border: "none",
          marginTop: "0em",
          height: "100vh",
        },
      }}
    >
      <div className="w-100 px-2 px-md-4 drawer_minHeight">
        <div className="px-2 px-md-0">
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ px: 0, pt: 2 }}
            className="pt-4 mt-2 mb-2"
          >
            <div className="text-left w-100 pt-4">
              <p className="mb-0">
                <span className="text-dark fw-500 fs-16">Enter Transaction Pin</span>
              </p>
              <p className="mb-0" style={{ fontWeight: '300', color: 'rgba(75, 85, 99, 1)' }}>
                <small className="fs-13 fw-500">Enter your 4-digit pin to confirm your transfer</small>
              </p>
            </div>
            <IconButton className="d-block d-sm-none" onClick={onClose}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Stack>
        </div>
        <div className="modal_content w-100 px-2 pl-md-0 pr-md-0 py-2 mt-2">
          <div className="mt-2 pb-5 modal_content wallet_modal_details" style={{ maxHeight: "70vh", overflowY: "scroll" }}>
            <div className="input_box">
              <label htmlFor="trsn_pin" className="fs-13 mb-0">Enter Pin</label>
              <FormControl sx={{ width: '100%' }} variant="outlined" error={error}>
                <OutlinedInput
                  id="outlined-adornment-PIN"
                  type={showPIN ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end" className="ml-0 px-0 py-0">
                      <IconButton
                        aria-label="toggle PIN visibility"
                        onClick={handleClickShowPIN}
                        onMouseDown={handleMouseDownPIN}
                        edge="end"
                        className="px-3 py-3"
                      >
                        {showPIN ? <VisibilityOff style={{ fontSize: '30px' }} /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  value={PIN}
                  onChange={handlePINChange}
                />
              </FormControl>
              <div className='d-flex mt-1' style={{ justifyContent: 'space-between' }}>
                {error && <small className='text-danger fw-500'>Incorrect Pin</small>}
                {(trialCount > 0 && error) && <small>{trialCount} more {trialCount === 1 ? 'trial' : 'trials'}</small>}
              </div>
            </div>
            <div className="w-100 mt-3">
              <button
                type="button"
                className="btn border btn-primary d-block px-3"
                onClick={handleSendTransfer}
                disabled={PIN === '' || PIN.length < 4}
              >
                <span className="text-white">
                  Proceed
                  {isLoading && <CircularProgress className='ml-1 mt-1' style={{ color: '#fff' }} size="0.8rem" />}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

TransactionPINDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pinError: PropTypes.bool.isRequired,
};

export default TransactionPINDialog;
