// ----------------------------------------------------------------------
import styles from "../UserSettings.module.css";
// import {
//     // useEffect,
//     useState,
// } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
    useEffect,
    useState,
} from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
    Link,
    Checkbox,
    Radio,
    //   Avatar,
} from "@mui/material";
import { Drawer, Stack, IconButton, FormControl, OutlinedInput, InputAdornment, CircularProgress, Divider, } from '@mui/material';
import Pagination from '../../../pagination/GlobalPagination'; // Adjust the path as necessary
import Scrollbar from "../../../components/scrollbar";
import GlobalModal from "../../../Modals/GlobalModal";
import axiosInstance from "src/axiosInstance"
// import CircularProgress from "@mui/material/CircularProgress";
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------


export default function NotificationTab() {
    const apiUrl = process.env.REACT_APP_NAME;
    const authUser = JSON.parse(localStorage.getItem('auth'))
    const [save, setSave] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [enableAppNot, setEnableAppNot] = useState(false);
    const pageSize = 10;
    const [item, setItem] = useState({});
    const [PIN, setPIN] = useState("");
    const [showPIN, setShowPIN] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [trialCount, setTrialCount] = useState(3);
    const [selectedOption, setSelectedOption] = useState('');
    const userDetails = JSON.parse(localStorage.getItem('userDetails'))
  
    const handleClickShowPIN = () => setShowPIN((show) => !show);
    const handleMouseDownPIN = (event) => event.preventDefault();
    const handlePINChange = (event) => {
      const value = event.target.value;
      setPIN(value);
    };

    const handleChangeSave = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleEnableAppNot = async () => {
        const newEnableAppNot = !enableAppNot;
        setEnableAppNot(newEnableAppNot);

        if (newEnableAppNot && (userDetails?.account?.notification_channel === '' || userDetails?.account?.notification_channel === null)) {
            setSelectedOption('APP')
        } else if(newEnableAppNot && (userDetails?.account?.notification_channel !== '' || userDetails?.account?.notification_channel !== null)) {
            setSelectedOption(userDetails?.account?.notification_channel); 
        } else {
            setSelectedOption('');
        }
    }

    
    useEffect(() => {
        if(enableAppNot && selectedOption !== '') {
            // console.log(userDetails.account.notification_channel, '&&', selectedOption);
            if (userDetails && (userDetails.account.notification_channel === selectedOption)) {
                // console.log('it matches');
            } else {
                const handleEnableAppNotification = async () => {
                    const payload = {notification_channel: selectedOption}
                    try {
                        const response = await axiosInstance.patch(`/account/${authUser && authUser.account_id ? authUser.account_id : null}/update-notification?productName=${apiUrl}`, payload)
                        notifySuccess(response.data.message)
                        handleFetch();
                    } catch (err) {
                        if (err.response) {
                            if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                                notifyError(err.response.data.data.errorMessage);
                            } else if (err.response.data && err.response.data.message) {
                                notifyError(err.response.data.message);
                            } else {
                                notifyError('An unexpected error occurred');
                            }
                            } else {
                            notifyError('Network error or server not responding');
                            }
                    } finally {
            
                    }
                }
        
                handleEnableAppNotification();
            }
        }
    }, [selectedOption])

    const handleFetch = async () => {
        try {
            const response = await axiosInstance.get(`/accounts/user?productName=${apiUrl}`);
            const data = response.data.data;

            if (response.data.status === 'success') {
                // console.log('userDetails reset successful');
                localStorage.setItem('userDetails', JSON.stringify(data))
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
        }
    }

    const handleOpenDeleteDialog = (item) => {
        setDeleteDialog(true);
        setItem(item)
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialog(false);
    };

    // const tableData = [
    //     {
    //         email: 'MichealJoseph344@gmail.com',
    //         is_default: true,
    //     },
    //     {
    //         email: 'MichealJoseph344@gmail.com',
    //         is_default: false,
    //     },
    // ];

    const [tableData, setTableData] = useState([])

    const getNotificationEmail = async () => {
        setIsPending(true)
        try {
            const response = await axiosInstance.get(`/notification-channel?productName=${apiUrl}`)
            const data = response.data.data
            setTableData(data)

        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
                } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsPending(false)
        }
    }


    useEffect(() => {
        if (userDetails) {
            setEnableAppNot(true);
            setSelectedOption(userDetails.account.notification_channel)
        }
        getNotificationEmail()
    }, [])
  
    const handleCreateEmail = async () => {
        setIsLoading(true)
        const payload = {
            account_id: authUser && authUser?.account_id ? authUser?.account_id : null,
            channel: 'EMAIL',
            data: PIN
        }
      try {
        const response = await axiosInstance.post(`/notification-channel?productName=${apiUrl}`, payload)
        notifySuccess(response.data.message)
        setOpenDrawer(false)
        setPIN('');
        getNotificationEmail()
      } catch (err) {
        if (err.response) {
            if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                notifyError(err.response.data.data.errorMessage);
            } else if (err.response.data && err.response.data.message) {
                notifyError(err.response.data.message);
            } else {
                notifyError('An unexpected error occurred');
            }
            } else {
            notifyError('Network error or server not responding');
        }
      } finally {
        setIsLoading(false)
      }
    };

    const handleDeleteNotEmail = async () => {
        setIsLoading(true)
        const payload = {
            account_id: authUser && authUser?.account_id ? authUser?.account_id : null,
            channel: 'EMAIL',
            data: item?.data
        }
        try {
            const response = await axiosInstance.delete(`/notification-channel/${item?.id}?productName=${apiUrl}`, payload)
            notifySuccess(response.data.message)
            getNotificationEmail()
            handleCloseDeleteDialog()
        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
                } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsLoading(false)
        }
    }


    const currentTableData = tableData?.data?.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    return (
        <>
            <div className="px-0 mt-3 pb-5">
                <div style={{ marginTop: "0px", borderRadius: "10px" }} className="p-0">
                    <Scrollbar>
                        <div
                            className={`${styles.overflow_table} table-responsive pt-4 pb-3 pl-0 pr-0`}
                        >
                            <div
                                className="w-100 mb-5"
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: 'flex-start',
                                    alignItems: "start",
                                }}
                            >
                                <div className="custom-control default position-relative custom-switch fit-content wallet_sect pb-0">
                                    <input
                                        type="checkbox"
                                        checked={enableAppNot} onClick={handleEnableAppNot}
                                        className="custom-control-input d-inline-block"
                                        id="two_fa"
                                    />
                                    <label className="custom-control-label d-inline-block"></label>
                                </div>
                                <div className="ml-3">
                                    <label htmlFor="two_fa" className="mb-1 fw-500 text-dark fs-14 cursor-pointer">Enable Platform Notification</label>
                                    <span className="d-block fw-400 fs-12">
                                        Get notification via SMS & Email or In app
                                    </span>
                                </div>
                            </div>

                            <div
                                className="w-100 mb-4"
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: 'flex-start',
                                    alignItems: "start",
                                }}
                            >
                                <div className="pb-2 w-100" style={{ borderBottom: '1px solid rgba(249, 250, 251, 1)' }}>
                                    <p className="mb-1 fw-500 text-dark fs-14">Select Channels</p>
                                    <span className="d-block fw-500 fs-12">
                                        Select who bears the transaction charge
                                    </span>
                                </div>
                            </div>
                            <div
                                className="w-100 mb-4"
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: 'flex-start',
                                    alignItems: "start",
                                }}
                            >
                                <div className="checkbox_div radio_btn pt-">
                                    <Radio id="inapp_notify" value="APP" checked={selectedOption === 'APP'} onClick={handleChangeSave} style={{}} />
                                </div>
                                <div className="ml-3">
                                    <label htmlFor="inapp_notify" className="mb-1 fw-500 text-dark fs-14 cursor-pointer">In app Notification</label>
                                    <span className="d-block fw-500 fs-12">
                                        Transaction charge will be deducted from the base price your customer pays
                                    </span>
                                </div>
                            </div>

                            <div
                                className="w-100 mb-4"
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: 'flex-start',
                                    alignItems: "start",
                                }}
                            >
                                <div className="checkbox_div radio_btn pt-">
                                    <Radio id="email_noify" value="EMAIL" checked={selectedOption === 'EMAIL'} onClick={handleChangeSave} style={{}} />
                                </div>
                                <div className="ml-3">
                                    <label htmlFor="email_noify" className="mb-1 fw-500 text-dark fs-14 cursor-pointer">Email Notification</label>
                                    <span className="d-block fw-500 fs-12">
                                        Transaction charge is added as an extra markup to the base price your customer pays.
                                    </span>
                                </div>
                            </div>
                            <div
                                className="w-100 mb-4"
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: 'flex-start',
                                    alignItems: "start",
                                }}
                            >
                                <div className="checkbox_div radio_btn pt-">
                                    <Radio id="sms_notify" value="SMS" checked={selectedOption === 'SMS'} onClick={handleChangeSave} style={{}} />
                                </div>
                                <div className="ml-3">
                                    <label htmlFor="sms_notify" className="mb-1 fw-500 text-dark fs-14 cursor-pointer">SMS Notification</label>
                                    <span className="d-block fw-500 fs-12">
                                        This channel attract some service charge. <span className="text-dark fw-500">View Charges</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Scrollbar>
                </div>
            </div>
            <div className="px-0 pb-5" style={{ width: '50em', maxWidth: '100%' }}>
                    <div className="row align-items-center mb-3 pt-">
                        <div className="col-sm-7">
                            <div className="">
                                <h6 className="page_title fw-500 fs-14 text-dark mb-1">Notification Email</h6>
                                <p style={{ color: '#4B5563', fontSize: '12px', gap: '8px' }} className="mb-0 d-flex align-items-center fw-500 text-dark">
                                    <span className="" style={{ color: 'rgba(102, 112, 133, 1)' }}>
                                        Add other mails to receive notification alert.
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div>
                                <div className="fit-content ml-sm-auto mt-3 mt-sm-0">
                                    <button
                                        className="btn border"
                                        type="button"
                                        onClick={() => setOpenDrawer(!openDrawer)}
                                    >
                                        <span className="button_text fs-15">Add Email Address</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ marginTop: "0px", borderRadius: "10px" }} className="p-0">
                        <Scrollbar>
                            <div
                                className={`${styles.overflow_table} table-responsive  pt- pb-3 pl-0 pr-0`}
                            >
                                <table
                                    className={`${styles.table} table-hover table-striped`}
                                    style={{ minWidth: "40em" }}
                                >
                                    <tbody>
                                        { !isPending &&
                                            <tr className={`${styles.tr} table-fonts`}>
                                                <td className="ml-2" style={{width: '40%', color: '#4B5563'}}>
                                                    <span className="ml-2 fw-500">
                                                        {userDetails?.account?.email}
                                                    </span>
                                                </td>
                                                <td style={{width: '40%'}}>
                                                    <div
                                                        className={`primary badge_style fit-content text-capitalize fw-500 fs-11 d-inline ml-5`}
                                                    >
                                                        Primary
                                                    </div>
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                        }
                                        {!isPending && currentTableData?.map((item, index) => (
                                            <tr key={item?.id} className={`${styles.tr} table-fonts`}>
                                                <td style={{width: '40%'}}>
                                                    <span className="ml-2 fw-500">
                                                        {item?.data}
                                                    </span>
                                                </td>
                                                <td style={{width: '40%'}}>
                                                    <span className="fw-500">
                                                        {item?.is_default &&
                                                            <div
                                                                className={`primary badge_style fit-content text-capitalize fw-500 fs-11 d-inline ml-5`}
                                                            >
                                                                Primary
                                                            </div>
                                                        }
                                                    </span>
                                                </td>
                                                <td>
                                                    {!item?.is_default && (
                                                        <div className="btn-group">
                                                            <span className="fw-500 btn rounded-0" data-toggle="dropdown"
                                                                aria-expanded="false">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4.5 10.5C3.675 10.5 3 11.175 3 12C3 12.825 3.675 13.5 4.5 13.5C5.325 13.5 6 12.825 6 12C6 11.175 5.325 10.5 4.5 10.5ZM19.5 10.5C18.675 10.5 18 11.175 18 12C18 12.825 18.675 13.5 19.5 13.5C20.325 13.5 21 12.825 21 12C21 11.175 20.325 10.5 19.5 10.5ZM12 10.5C11.175 10.5 10.5 11.175 10.5 12C10.5 12.825 11.175 13.5 12 13.5C12.825 13.5 13.5 12.825 13.5 12C13.5 11.175 12.825 10.5 12 10.5Z" fill="black" />
                                                                </svg>
                                                            </span>

                                                            <div
                                                                className={`${styles.dropdown_style} dropdown-menu dropdown-menu-right py-0 rounded border-0 overflow-hidden`}
                                                                style={{
                                                                    background: "rgba(249, 250, 251, 1)",
                                                                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                                                                }}
                                                            >
                                                                {!item?.is_default &&
                                                                    <button
                                                                        className="dropdown-item dropdown_text fw-500 position-relative fs-11"
                                                                        onClick={() => {handleOpenDeleteDialog(item);}}
                                                                    >
                                                                        Delete
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {isPending &&
                                <>
                                    <div className="d-flex justify-content-center mt-3">
                                        <CircularProgress
                                            style={{ color: "#fff" }}
                                            className="text-white ml-1"
                                            size="1.3rem"
                                        />
                                    </div>
                                    <br />
                                </>
                            }
                            {/* {
                                (!isPending && !currentTableData?.length) &&
                                <div className="alerts alerts-primary text-center mt-4 mb-4">
                                    <h5>No record found</h5>
                                </div>
                            } */}
                        </Scrollbar>

                        {/* <Pagination
                            currentPage={currentPage}
                            totalResults={totalResults}
                            pageSize={pageSize}
                            onPageChange={onPageChange}
                        /> */}
                    </div>
                </div>

                <GlobalModal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={deleteDialog}
                    onClose={handleCloseDeleteDialog}
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    width="570px !important"
                    maxWidth="100%"
                    overflowY="scroll"
                    bgcolor="#F9FAFB"
                    border="1px solid #F5F5F5"
                    borderRadius="5px"
                    boxShadow="24"
                    p="25px"
                >
                    <span
                        className="px-3 py-2"
                        style={{
                            fontSize: "25px",
                            cursor: "pointer",
                            position: "absolute",
                            top: "0px",
                            right: "20px",
                        }}
                        onClick={handleCloseDeleteDialog}
                    >
                        &times;
                    </span>
                    <div
                        className={`modal_content w-100`}
                        style={{ overflowY: 'scroll', maxHeight: '85vh', }}
                    >
                        <div className="w-100">
                            <div className="w-100">
                                <h6>
                                    <span className="text-dark fw-700" style={{ fontSize: '17px' }}>Delete Notification</span>
                                </h6>
                            </div>

                            <div className="">
                                    Confirm you want to delete this notification
                            </div>

                            <div className=" px-3 pt-4">
                                <div className="d-flex ">
                                    <div className="px-0 mb-3 ml-auto">
                                        <button className="btn btn-danger ml-auto" type="button" onClick={handleDeleteNotEmail}>
                                            Delete
                                            {isLoading && <CircularProgress className='ml-1 mt-1' style={{ color: '#fff' }} size="0.8rem" />}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </GlobalModal>

                <Drawer
                anchor="right"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                PaperProps={{
                    sx: {
                        width: 300,
                        maxWidth: "100%",
                        border: "none",
                        marginTop: "0em",
                        height: "100vh",
                        padding: "20px",
                        paddingTop: '40px'
                    },
                }}
            >
                <div className="w-100 drawer_minHeight">
                    <div className="px-2 px-md-0">
                    <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ px: 0, pt: 2 }}
                        className="pt-4d mt-2 mb-2"
                    >
                        <div className="text-left w-100">
                            <p className="mb-0">
                                <span className="text-dark fw-500 fs-16">Add Email</span>
                            </p>
                            <p className="mb-1" style={{ fontWeight: '300', color: 'rgba(75, 85, 99, 1)' }}>
                                <small className="fs-12 fw-500">Enter your Email Address to change Email</small>
                            </p>
                            <Divider />
                        </div>
                        <IconButton className="d-block d-sm-none" onClick={() => setOpenDrawer(false)}>
                        {/* <span
                            className="px-3 py-2"
                            style={{
                                fontSize: "25px",
                                cursor: "pointer",
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                            }}
                            onClick={() => setOpenDrawer(false)}
                        >
                            &times;
                        </span> */}
                        <Iconify icon="eva:close-fill" />
                        </IconButton>
                    </Stack>
                    </div>
                    <div className="modal_content w-100 px-2 pl-md-0 pr-md-0 py-2 mt-2">
                    <div className="mt-2 pb-5 modal_content wallet_modal_details" style={{ maxHeight: "70vh", overflowY: "scroll" }}>
                        <div className="input_box">
                        <label htmlFor="changeable_email" className="fs-13 mb-0">Enter Email Address</label>
                        <FormControl sx={{ width: '100%' }} variant="outlined" error={error}>
                            <OutlinedInput
                            id="changeable_email"
                            type='email'
                            value={PIN}
                            onChange={handlePINChange}
                            />
                        </FormControl>
                        </div>
                        <div className="w-100 mt-3">
                        <button
                            type="button"
                            className="btn border btn-primary d-block px-3"
                            onClick={() => handleCreateEmail()}
                        >
                            <span className="text-white">
                            Proceed
                            {isLoading && <CircularProgress className='ml-1 mt-1' style={{ color: '#fff' }} size="0.8rem" />}
                            </span>
                        </button>
                        </div>
                    </div>
                    </div>
                </div>
            </Drawer>
        </>
    );
}
