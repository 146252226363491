import React from "react";
import Chart from "react-apexcharts";

const MostTransactedBanksChart = () => {
  const formatValue = (val) => {
    if (val >= 1000) {
      return (val / 1000).toFixed(0) + "K"; // Format numbers as K for thousands
    }
    return val.toLocaleString();
  };

  const chartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "40%", // Adjust bar height
        borderRadius: 0, // Rounded corners for bars
      },
    },
    dataLabels: {
      enabled: true,
      formatter: formatValue, // Use custom formatter for data labels
      style: {
        colors: ["#111827"],
        fontSize: "10px",
      },
      offsetX: 60, // Adjust position of data labels
    },
    xaxis: {
      categories: [
        "Wema Bank",
        "Sterling Bank",
        "GT Bank",
        "Polaris Bank",
        "Access Bank",
      ],
      labels: {
        formatter: formatValue, // Use custom formatter for x-axis labels
        style: {
          colors: ["#333"],
          fontSize: "11px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: ["#333"],
          fontSize: "11px",
        },
      },
    },
    grid: {
      borderColor: "transparent",
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      colors: ["#111827"],
    },
    tooltip: {
      y: {
        formatter: formatValue, // Use custom formatter for tooltip values
      },
    },
  };

  const chartSeries = [
    {
      name: "Transfers",
      data: [115666, 200666, 75666, 200666, 105666],
    },
  ];

  return (
    <div className="weekly_volume_chart h-100">
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={300}
      />
    </div>
  );
};

export default MostTransactedBanksChart;
