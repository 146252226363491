import axios from 'axios';
import toast from 'react-hot-toast';
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import { useAuth } from "src/hooks/useAuth";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/onepoint-gateway/api/v1`,
  // baseURL: 'https://onepoint.com/api/v1',
});

instance.interceptors.request.use(
  config => {
    const data = JSON.parse(localStorage.getItem('auth'));

    config.headers['ngrok-skip-browser-warning'] = true;
    config['withCredentials'] = true;

    if (data && data.token) {
      config.headers['x-auth-token'] = data.token;
    }
    return config;
  },
  error => Promise.reject(error)
);

instance.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;
    // const { dispatch } = useAuth();

    if (response) {
      // Check for unauthorized response and the specific error message
      if (response.status === 401 ||
        (response.data && response.data.data && response.data.data.errorMessage === "Unauthorized.")) {
        // localStorage.removeItem('auth');
        // localStorage.removeItem('me');
        // localStorage.removeItem('show_ledger_banner');
        // localStorage.removeItem('preview_data');
        // localStorage.removeItem('selectedLanguage');

        notifyError('SESSION TIMEOUT!');
        localStorage.clear();
        setTimeout(() => {
          // dispatch({ type: 'LOGIN', payload: {} })
          window.location.href = '/login';
          // navigate('/login');
        }, 1000);
        return;
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
