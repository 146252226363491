// src/index.js
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthContextProvider } from './context/AuthContext';
import { BaseUrlContextProvider } from './context/BaseUrlContext';
import { PathnameProvider } from './context/PathContext';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { LanguageProvider } from './theme/languageContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <AuthContextProvider>
        <BaseUrlContextProvider>
          <PathnameProvider>
            <LanguageProvider>
              <App />
            </LanguageProvider>
          </PathnameProvider>
        </BaseUrlContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </HelmetProvider>
);

serviceWorker.unregister();
reportWebVitals();
