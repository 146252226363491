export const HomeFeatureSectionData = [
  {
    heading: 'Send and receive money seamlessly across borders',

    subText: `orderless Banking, Limitless Opportunities:Seamlessly transfer funds between Nigeria, and China.`,
    image: '/assets/images/feature-img3.png',
  },

  {
    heading: 'Multi-Layered Authentication for Enhanced Security',
    subText: `Your Trustworthy Partner in Secure Transactions`,
    image: '/assets/images/feature-img2.png',
  },

  {
    heading: 'Boost Your Efficiency with Cutting-Edge Solutions',

    subText: `Streamline Your Workflow and Achieve More with Less.`,

    image: '/assets/images/feature-img1.png',
  },
];

export const featureTagData = [
  {
    text: 'Fast',
  },

  {
    text: 'Secure',
  },

  {
    text: 'Efficient',
  },
];
