// ----------------------------------------------------------------------
import React from "react";
import { Helmet } from "react-helmet-async";
import styles from "./LedgerTable.module.css";
import TransactionsTable from "./TransactionsTable";
import AccountTable from "./AccountTable";
import {
    // useEffect,
    useState,
} from "react";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Link as RouterLink } from "react-router-dom";

import {
    Link,
} from "@mui/material";
// sections
// mock
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Pagination from '../../pagination/GlobalPagination'; // Adjust the path as necessary
import Scrollbar from "../../components/scrollbar";
// ----------------------------------------------------------------------

export default function AccountDetails() {
    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = React.useState("1");

    const [paymentDate, setPaymentDate] = useState(new Date());
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 8;

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleToggleDropdown = () => {
        setToggleDropdown(!toggleDropdown);
    };

    const tableData = [
        {
            date_posted: '24 Mar 2024 11:54 pm',
            debit_bal: '₦24,000.00',
            credit_bal: '-',
            status: 'posted',
        },
        {
            date_posted: '24 Mar 2024 11:54 pm',
            debit_bal: '-',
            credit_bal: '₦24,000.00',
            status: 'posted',
        },
        {
            date_posted: '24 Mar 2024 11:54 pm',
            debit_bal: '₦24,000.00',
            credit_bal: '-',
            status: 'posted',
        },
        {
            date_posted: '24 Mar 2024 11:54 pm',
            debit_bal: '₦24,000.00',
            credit_bal: '-',
            status: 'posted',
        },
    ];

    const totalResults = tableData.length;

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    // Calculate the data to display on the current page
    const currentTableData = tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize);




    return (
        <>
            <Helmet>
                <title>All Transactions</title>
            </Helmet>

            <div className="px-0 px-md-2">
                <div onClick={() => handleToggleDropdown()}
                    className={`overlay_div ${toggleDropdown ? "show_overlay" : ""}`}
                ></div>

                <div className="d-flex justify-content-between align-items-center">
                    <div className="">
                        <h5 className="page_title mb-2">Ledgers</h5>
                        <p style={{ color: '#4B5563', fontSize: '13px', gap: '8px' }} className="mt-2 d-flex align-items-center fw-500 text-dark">
                            <Link to="/app/ledger-management" component={RouterLink} className="py-1 d-flex justify-content-center align-items-center">

                                <span className="text-dark">Ledger</span>
                            </Link>
                            <span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.29289 14.7071C6.90237 14.3166 6.90237 13.6834 7.29289 13.2929L10.5858 10L7.29289 6.70711C6.90237 6.31658 6.90237 5.68342 7.29289 5.29289C7.68342 4.90237 8.31658 4.90237 8.70711 5.29289L12.7071 9.29289C13.0976 9.68342 13.0976 10.3166 12.7071 10.7071L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071Z" fill="#111827" />
                                </svg>
                            </span>
                            <Link to="/app/ledger-management/ledger-details" component={RouterLink} className="py-1 d-flex justify-content-center align-items-center">
                                <span className=" text-dark">
                                    NGN Ledger
                                </span>
                            </Link>
                            <span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.29289 14.7071C6.90237 14.3166 6.90237 13.6834 7.29289 13.2929L10.5858 10L7.29289 6.70711C6.90237 6.31658 6.90237 5.68342 7.29289 5.29289C7.68342 4.90237 8.31658 4.90237 8.70711 5.29289L12.7071 9.29289C13.0976 9.68342 13.0976 10.3166 12.7071 10.7071L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071Z" fill="#111827" />
                                </svg>
                            </span>
                            <span className=" text-dark">
                                Sales
                            </span>
                        </p>
                    </div>
                </div>

                <div className="d-md-flex justify-content-between align-items-center mt-sm-4 w-100">
                    <div className="balance_as_at d-flex justify-content-between align-items-center mb-3 mb-md-0 w-100" style={{ gap: '10px', width: 'fit-content' }}>
                        <div className={`position-relative btn border py-1 rounded d-flex justify-content-start align-items-center`}
                            style={{ width: '16.2em', background: 'rgba(249, 250, 251, 1)' }}>
                            <span className="">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.4375 1.59961V3.21972" stroke="#4B5563" strokeWidth="1.29609" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8.75781 1.59961V3.21972" stroke="#4B5563" strokeWidth="1.29609" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M2.00781 5.42871H11.1885" stroke="#4B5563" strokeWidth="1.29609" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11.457 5.10937V9.6997C11.457 11.3198 10.647 12.3999 8.75683 12.3999H4.43652C2.54639 12.3999 1.73633 11.3198 1.73633 9.6997V5.10937C1.73633 3.48926 2.54639 2.40918 4.43652 2.40918H8.75683C10.647 2.40918 11.457 3.48926 11.457 5.10937Z" stroke="#4B5563" strokeWidth="1.29609" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8.59322 7.91846H8.59808" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8.59322 9.53857H8.59808" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.59518 7.91846H6.60003" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.59518 9.53857H6.60003" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4.59713 7.91846H4.60198" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4.59713 9.53857H4.60198" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>
                            <div className="d-flex justify-content-start align-items-center ">
                                <span className="ml-2" style={{ marginTop: '1px' }}>Balance as at </span>
                                <DatePicker
                                    // showIcon
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={15}
                                    selected={paymentDate}
                                    onChange={(date) => setPaymentDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control border-0 fit-content"
                                    placeholderText=""
                                    required
                                    minDate={new Date()}
                                    size="small"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex w-100 justify-content-end" style={{}}>
                        <div className="fit-content d-flex flex-wrap" style={{ whiteSpace: 'nowrap' }}>

                            <div className="fit-content last_btn_arrg ml-sm-2">
                                <div className="btn-group">
                                    <button
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                        className="btn border btn-primary"
                                        type="button"
                                    >
                                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.51855 8.93359V10.3937C1.51855 10.9313 1.95436 11.3671 2.49195 11.3671H9.30576C9.84335 11.3671 10.2792 10.9313 10.2792 10.3937V8.93359" stroke="#F3F4F6" strokeWidth="1.86" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3.9519 6.5L5.8987 8.4468L7.8455 6.5" stroke="#F3F4F6" strokeWidth="1.86" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M5.89868 1.6333V8.4471" stroke="#F3F4F6" strokeWidth="1.86" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        {" "}
                                        <span className="button_text text-white">Export</span>
                                    </button>
                                    <div
                                        className="dropdown-menu dropdown-menu-right rounded border-0"
                                        style={{
                                            background: "#fff",
                                            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                                        }}
                                    >
                                        <button
                                            className="dropdown-item dropdown_text fw-500 position-relative"
                                        >
                                            CSV
                                        </button>
                                        <button
                                            className="dropdown-item dropdown_text fw-500 position-relative"
                                        >
                                            PDF
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: "0px", borderRadius: "10px" }} className="p-0 mt-4 create_invoice_tab_section">
                    <div className="px-0">

                        <div style={{ marginTop: "0px", borderRadius: "10px" }} className="p-0">
                            <Scrollbar>
                                <div
                                    className={`${styles.overflow_table} table-responsive  pt- pb-3 pl-0 pr-0`}
                                >
                                    <table
                                        className={`${styles.table} table-hover table-striped`}
                                        style={{ minWidth: "50em" }}
                                    >
                                        <thead className={`${styles.thead}`}>
                                            <tr>
                                                <th className="text-muted fs-13 fw-600 th_width" style={{ minWidth: "6%" }}>
                                                    Date Posted {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </th>
                                                <th className="text-muted fs-13 fw-600 th_width">Debit {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg></th>
                                                <th className="text-muted fs-13 fw-600 th_width">Credit {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg></th>
                                                <th className="text-muted fs-13 fw-600 th_width">Status {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {currentTableData.map((item, index) => (
                                                <tr key={index} className={`${styles.tr} table-fonts`}
                                                >
                                                    <td>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <div className=" ">
                                                                <span className="fw-500">
                                                                    {item.date_posted}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="fw-500">
                                                            {item.debit_bal}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fw-500">
                                                            {item.credit_bal}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className=" ">
                                                            <div className={`${item.status} badge_style fit-content text-capitalize`}>
                                                                <span className="fw-500">{item.status}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="" style={{ minWidth: '200px' }}>
                                                        <div className="btn-group">
                                                            <span className="fw-500 btn rounded-0" data-toggle="dropdown"
                                                                aria-expanded="false">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4.5 10.5C3.675 10.5 3 11.175 3 12C3 12.825 3.675 13.5 4.5 13.5C5.325 13.5 6 12.825 6 12C6 11.175 5.325 10.5 4.5 10.5ZM19.5 10.5C18.675 10.5 18 11.175 18 12C18 12.825 18.675 13.5 19.5 13.5C20.325 13.5 21 12.825 21 12C21 11.175 20.325 10.5 19.5 10.5ZM12 10.5C11.175 10.5 10.5 11.175 10.5 12C10.5 12.825 11.175 13.5 12 13.5C12.825 13.5 13.5 12.825 13.5 12C13.5 11.175 12.825 10.5 12 10.5Z" fill="black" />
                                                                </svg>
                                                            </span>

                                                            <div
                                                                className="dropdown-menu dropdown-menu-right py-0 rounded border-0 overflow-hidden"
                                                                style={{
                                                                    background: "rgba(249, 250, 251, 1)",
                                                                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                                                                }}
                                                            >
                                                                <Link
                                                                // to="/app/ledger-management/edit-account"
                                                                to="/app/ledger-management/edit-transaction"
                                                                component={RouterLink}
                                                                    className="dropdown-item dropdown_text fw-500 position-relative"
                                                                >
                                                                    Edit
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    {/* <td>
                                <div className="btn-group d-flex">
                                    <Link
                                    to="/app/ledger-management/sales-ledger"
                                    component={RouterLink}
                                        className="p-2 ml-auto"
                                        style={{ cursor: "pointer" }}

                                    >
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="ml-auto"
                                        >
                                            <path
                                                d="M7.60162 8.78101L10.6809 5.4812L11.5607 6.42401L7.60162 10.6667L3.64258 6.42401L4.52237 5.4812L7.60162 8.78101Z"
                                                fill="#4B5563"
                                            />
                                        </svg>
                                    </Link>
                                </div>
                            </td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {/* <div className="d-flex justify-content-center">

</div> */}

                                {/* <div className="alert alert-primary text-center">
<h4>No record found</h4>
</div> */}
                            </Scrollbar>
                            <Pagination
                                currentPage={currentPage}
                                totalResults={totalResults}
                                pageSize={pageSize}
                                onPageChange={onPageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
