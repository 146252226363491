import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
} from "@mui/material";
// utils
// components
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import Iconify from "../../../components/iconify";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
//
import NotificationsPopover from "./NotificationsPopover";
import bus from "src/bus";
import { bgBlur } from "../../../utils/cssStyles";
import Scrollbar from "../../../components/scrollbar";
import LanguagePopover from "./LanguagePopover";
import navConfig from "../nav/config"; // Import getFilteredNavConfig function

// ----------------------------------------------------------------------

const NAV_WIDTH = 190;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 50;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "none",
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

SmallScreenHeader.propTypes = {
  onOpenNav: PropTypes.func,
  pathName: PropTypes.string.isRequired,
};

export default function SmallScreenHeader({ onOpenNav, pathName }) {
  const [openFilter, setOpenFilter] = useState(false);
  const [currentPath, setCurrentPath] = useState(pathName);
  const location = useLocation();

  // const { data } = useAuth();
  useEffect(() => {
    // console.log('navConfig :', navConfig);
    // console.log('pathName :', pathName);
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  // const LogoutUser=()=>{
  //   localStorage.removeItem('auth');
  //   setTimeout(() => {
  //   }, 1000);
  // }

  return (
    <>
      <StyledRoot className="pt-md-5 style_cs bg-white pb-0">
        <StyledToolbar className="px-3 style_c">
          <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: "text.primary",
              display: { lg: "none" },
            }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
          <div className={` header d-flex align-items-center w-100`}>
            {/* <h4 className={`mb-1 pb-0`}>{pageHeader}</h4> */}
            {/* <p style={{ fontSize: "14px" }} className={`mb-0 pb-0`}>
            {pageText}
          </p> */}
            <div className="d-md-flex justify-content-between align-items-center w-100 pt-2 px-md-3 px-lg-5">
              <div className="">
                {/* <div className={`navbar_search d-none d-md-inline`}>
                  <input type="search" name="" placeholder="Search" id="" />
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="search"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.99999 3C4.34314 3 3 4.34315 3 6C3 7.65685 4.34314 9 5.99999 9C7.65684 9 8.99998 7.65685 8.99998 6C8.99998 4.34315 7.65684 3 5.99999 3ZM1.5 6C1.5 3.51472 3.51471 1.5 5.99999 1.5C8.48526 1.5 10.5 3.51472 10.5 6C10.5 6.97187 10.1919 7.87179 9.66804 8.6074L13.2803 12.2197C13.5732 12.5126 13.5732 12.9874 13.2803 13.2803C12.9874 13.5732 12.5125 13.5732 12.2196 13.2803L8.60738 9.66806C7.87177 10.1919 6.97186 10.5 5.99999 10.5C3.51471 10.5 1.5 8.48528 1.5 6Z"
                      fill="#9CA3AF"
                    />
                  </svg>
                </div> */}
              </div>

              <div className="">
                <div
                  className={`ml-auto navbar_search right d-flex justify-content-end align-items-center pr-md-4`}
                  style={{ gap: "1em" }}
                >
                  {/* <div>
                    <div
                      className="border border-primary text-primary px-2 py-1"
                      style={{ borderRadius: "8px", cursor: "pointer" }}
                    >
                      <span
                        className="mr-1"
                        style={{
                          color: "#222222",
                          fontWeight: "500",
                        }}
                      >
                        Quick access{" "}
                      </span>{" "}
                      <i
                        className="bi bi-chevron-down mt-2"
                        style={{
                          color: "#222222",
                          fontWeight: "500",
                        }}
                      ></i>
                    </div>
                  </div> */}

                  {/* <div className="d-flex align-items-center">
                    <label
                      className="pr- mb-0"
                      for="customSwitch1"
                      style={{ fontWeight: "500", fontSize: '12px' }}
                    >
                      Test mode
                    </label>
                    <div className="custom-control custom-switch pb-0">
                      <input
                        type="checkbox"
                        className="custom-control-input d-inline-block"
                        checked
                        id="customSwitch1"
                      />
                      <label className="custom-control-label d-inline-block"></label>
                    </div>
                  </div> */}

                  {/* <div className="notif_sett">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.01242 1.93994C5.80575 1.93994 4.01242 3.73327 4.01242 5.93994V7.86661C4.01242 8.27328 3.83908 8.89327 3.63242 9.23994L2.86575 10.5133C2.39242 11.2999 2.71908 12.1733 3.58575 12.4666C6.45908 13.4266 9.55908 13.4266 12.4324 12.4666C13.2391 12.1999 13.5924 11.2466 13.1524 10.5133L12.3857 9.23994C12.1857 8.89327 12.0124 8.27328 12.0124 7.86661V5.93994C12.0124 3.73994 10.2124 1.93994 8.01242 1.93994Z"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                      />
                      <path
                        d="M9.24596 2.13321C9.0393 2.07321 8.82596 2.02655 8.60596 1.99988C7.96596 1.91988 7.35263 1.96655 6.7793 2.13321C6.97263 1.63988 7.45263 1.29321 8.01263 1.29321C8.57263 1.29321 9.05263 1.63988 9.24596 2.13321Z"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.0137 12.7068C10.0137 13.8068 9.11367 14.7068 8.01367 14.7068C7.467 14.7068 6.96034 14.4801 6.60034 14.1201C6.24034 13.7601 6.01367 13.2535 6.01367 12.7068"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                      />
                    </svg>
                  </div> */}

                  <div
                    className="notif_sett"
                    onClick={() => setOpenFilter(true)}
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.5 9.66007V8.34007C1.5 7.56007 2.1375 6.91507 2.925 6.91507C4.2825 6.91507 4.8375 5.95507 4.155 4.77757C3.765 4.10257 3.9975 3.22507 4.68 2.83507L5.9775 2.09257C6.57 1.74007 7.335 1.95007 7.6875 2.54257L7.77 2.68507C8.445 3.86257 9.555 3.86257 10.2375 2.68507L10.32 2.54257C10.6725 1.95007 11.4375 1.74007 12.03 2.09257L13.3275 2.83507C14.01 3.22507 14.2425 4.10257 13.8525 4.77757C13.17 5.95507 13.725 6.91507 15.0825 6.91507C15.8625 6.91507 16.5075 7.55257 16.5075 8.34007V9.66007C16.5075 10.4401 15.87 11.0851 15.0825 11.0851C13.725 11.0851 13.17 12.0451 13.8525 13.2226C14.2425 13.9051 14.01 14.7751 13.3275 15.1651L12.03 15.9076C11.4375 16.2601 10.6725 16.0501 10.32 15.4576L10.2375 15.3151C9.5625 14.1376 8.4525 14.1376 7.77 15.3151L7.6875 15.4576C7.335 16.0501 6.57 16.2601 5.9775 15.9076L4.68 15.1651C3.9975 14.7751 3.765 13.8976 4.155 13.2226C4.8375 12.0451 4.2825 11.0851 2.925 11.0851C2.1375 11.0851 1.5 10.4401 1.5 9.66007Z"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  {navConfig.map((item, index) => {
                    // Check if the current path matches the item's path
                    const isActive =
                      item.path === currentPath &&
                      item.path === "/app/overview";

                    // Render the item conditionally based on the match
                    return (
                      isActive && (
                        <>
                          <NotificationsPopover />

                          <LanguagePopover />
                        </>
                      )
                      // <div key={index} className={isActive ? 'active' : 'inactive'}>

                      //   <span>{item.title}</span>
                      // </div>
                    );
                  })}

                  {/* <div className="d-flex align-items-center cursor-pointer">
                    <span><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_3487_31412)">
                        <path d="M12.7066 12.4467L11.2799 9.6001L9.85327 12.4467" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10.1133 11.9399H12.46" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.28 14.6664C9.41331 14.6664 7.89331 13.153 7.89331 11.2797C7.89331 9.41304 9.40665 7.89307 11.28 7.89307C13.1466 7.89307 14.6666 9.40637 14.6666 11.2797C14.6666 13.153 13.1533 14.6664 11.28 14.6664Z" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M3.34659 1.3335H5.95992C7.33992 1.3335 8.00659 2.00018 7.97326 3.34684V5.96016C8.00659 7.34016 7.33992 8.00684 5.95992 7.97351H3.34659C1.99992 8.00018 1.33325 7.33349 1.33325 5.95349V3.34017C1.33325 2.00017 1.99992 1.3335 3.34659 1.3335Z" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6.00671 3.8999H3.30005" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.64673 3.44678V3.90011" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.32655 3.89307C5.32655 5.05973 4.41321 6.00639 3.29321 6.00639" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6.00652 6.00684C5.51985 6.00684 5.07986 5.74683 4.77319 5.3335" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M1.33325 10C1.33325 12.58 3.41992 14.6667 5.99992 14.6667L5.29992 13.5" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M14.6667 6.00016C14.6667 3.42016 12.58 1.3335 10 1.3335L10.7 2.50016" stroke="#111827" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_3487_31412">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg></span>
                    <div
                      className="text-primary d-flex justify-content-between align-items-center rounded"
                      style={{ cursor: "pointer", padding: '3px 8px', width: '100px' }}
                    >
                      <span
                        className="mr-1"
                        style={{
                          color: "#222222",
                          fontWeight: "500",
                          fontSize: '12px'
                        }}
                      >
                        English{" "}
                      </span>{" "}
                      <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.29995 4.22949L5.58328 6.94616C5.26245 7.26699 4.73745 7.26699 4.41662 6.94616L1.69995 4.22949" stroke="#111827" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <Box sx={{ flexGrow: 1 }} />

          {/* <div className='hold_input4' style={{width:'35%'}}>
            <img src="/assets/icons/search.svg" alt="" />
            <input type="text" placeholder="Search" style={{textIndent:'25px',width:'100%'}} className=" form-control" />

        </div> */}

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
          >
            {/* <LanguagePopover /> */}
            {/* <NotificationsPopover /> */}
            {/* <div className="dropleft ">
              <PowerSettingsNewIcon id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ color: 'gray', cursor: 'pointer', width: '30px' }} />



              <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                <button
                  type="button"
                  // onClick={LogoutUser} 
                  onClick={() => bus.emit("open-logout-modal")}
                  className="rss dropdown-item btn border-0">Logout</button>


              </div>
            </div> */}
            {/* <AccountPopover /> */}
          </Stack>
        </StyledToolbar>
      </StyledRoot>



      <Drawer
        anchor="right"
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        PaperProps={{
          sx: {
            width: 360,
            maxWidth: "100%",
            border: "none",
            overflow: "",
            marginTop: "0em",
            height: "100vh",
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, pt: 2, pb: 1 }}
          className=""
        >
          <Typography variant="h6" sx={{ ml: 1 }}></Typography>
          <IconButton onClick={() => setOpenFilter(false)}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={() => setOpenFilter(false)}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider /> */}

        <Scrollbar>
          <Stack spacing={2} sx={{ p: 2 }}>
            <div
              onClick={() => setOpenFilter(false)}
              className="d-flex rounded settings_option_box"
            >
              <span>
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="34" height="34" rx="4.25" fill="#EFF6FF" />
                  <path
                    d="M17.0003 8.66666C14.817 8.66666 13.042 10.4417 13.042 12.625C13.042 14.7667 14.717 16.5 16.9003 16.575C16.967 16.5667 17.0337 16.5667 17.0837 16.575C17.1003 16.575 17.1087 16.575 17.1253 16.575C17.1337 16.575 17.1337 16.575 17.142 16.575C19.2753 16.5 20.9503 14.7667 20.9587 12.625C20.9587 10.4417 19.1837 8.66666 17.0003 8.66666Z"
                    fill="#60A5FA"
                  />
                  <path
                    d="M21.2338 18.7917C18.9088 17.2417 15.1171 17.2417 12.7755 18.7917C11.7171 19.5 11.1338 20.4583 11.1338 21.4833C11.1338 22.5083 11.7171 23.4583 12.7671 24.1583C13.9338 24.9417 15.4671 25.3333 17.0005 25.3333C18.5338 25.3333 20.0671 24.9417 21.2338 24.1583C22.2838 23.45 22.8671 22.5 22.8671 21.4667C22.8588 20.4417 22.2838 19.4917 21.2338 18.7917Z"
                    fill="#60A5FA"
                  />
                </svg>
              </span>
              <div className="ml-3">
                <h6 className="font-weight-normal mb-1">Profile</h6>
                <small
                  className="d-block"
                  style={{
                    fontSize: "12px",
                    color: "rgba(107, 114, 128, 1)",
                    lineHeight: "1.4",
                  }}
                >
                  Personal details, password, communication preferences, and
                  active sessions.
                </small>
              </div>
            </div>

            <div
              onClick={() => setOpenFilter(false)}
              className="d-flex rounded settings_option_box"
            >
              <span>
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="34" height="34" rx="4.25" fill="#EFF6FF" />
                  <path
                    d="M25.3337 22.8333V25.3333H8.66699V22.8333C8.66699 22.375 9.04199 22 9.50033 22H24.5003C24.9587 22 25.3337 22.375 25.3337 22.8333Z"
                    fill="#60A5FA"
                    stroke="#60A5FA"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.8337 16.1667H11.167V22H12.8337V16.1667Z"
                    fill="#60A5FA"
                  />
                  <path
                    d="M16.1667 16.1667H14.5V22H16.1667V16.1667Z"
                    fill="#60A5FA"
                  />
                  <path
                    d="M19.4997 16.1667H17.833V22H19.4997V16.1667Z"
                    fill="#60A5FA"
                  />
                  <path
                    d="M22.8337 16.1667H21.167V22H22.8337V16.1667Z"
                    fill="#60A5FA"
                  />
                  <path
                    d="M26.1663 25.9583H7.83301C7.49134 25.9583 7.20801 25.675 7.20801 25.3333C7.20801 24.9917 7.49134 24.7083 7.83301 24.7083H26.1663C26.508 24.7083 26.7913 24.9917 26.7913 25.3333C26.7913 25.675 26.508 25.9583 26.1663 25.9583Z"
                    fill="#60A5FA"
                  />
                  <path
                    d="M24.8087 11.7917L17.3087 8.79167C17.142 8.725 16.8587 8.725 16.692 8.79167L9.19199 11.7917C8.90033 11.9083 8.66699 12.25 8.66699 12.5667V15.3333C8.66699 15.7917 9.04199 16.1667 9.50033 16.1667H24.5003C24.9587 16.1667 25.3337 15.7917 25.3337 15.3333V12.5667C25.3337 12.25 25.1003 11.9083 24.8087 11.7917ZM17.0003 14.0833C16.3087 14.0833 15.7503 13.525 15.7503 12.8333C15.7503 12.1417 16.3087 11.5833 17.0003 11.5833C17.692 11.5833 18.2503 12.1417 18.2503 12.8333C18.2503 13.525 17.692 14.0833 17.0003 14.0833Z"
                    fill="#60A5FA"
                  />
                </svg>
              </span>
              <div className="ml-3">
                <h6 className="font-weight-normal mb-1">Business</h6>
                <small
                  className="d-block"
                  style={{
                    fontSize: "12px",
                    color: "rgba(107, 114, 128, 1)",
                    lineHeight: "1.4",
                  }}
                >
                  Personal details, password, communication preferences, and
                  active sessions.
                </small>
              </div>
            </div>

            <div
              onClick={() => setOpenFilter(false)}
              className="d-flex rounded settings_option_box"
            >
              <span>
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="34" height="34" rx="4.25" fill="#EFF6FF" />
                  <path
                    d="M22.0002 20.9583C21.8669 20.9583 21.7419 20.9167 21.6252 20.8333C21.3502 20.625 21.2919 20.2333 21.5002 19.9583C22.8086 18.2167 22.8086 15.7833 21.5002 14.0417C21.2919 13.7667 21.3502 13.375 21.6252 13.1667C21.9002 12.9583 22.2919 13.0167 22.5002 13.2917C24.1336 15.475 24.1336 18.525 22.5002 20.7083C22.3752 20.875 22.1919 20.9583 22.0002 20.9583Z"
                    fill="#60A5FA"
                  />
                  <path
                    d="M23.5256 23.0417C23.3923 23.0417 23.2673 23 23.1506 22.9167C22.8756 22.7083 22.8173 22.3167 23.0256 22.0417C25.2506 19.075 25.2506 14.925 23.0256 11.9583C22.8173 11.6833 22.8756 11.2917 23.1506 11.0833C23.4256 10.875 23.8173 10.9333 24.0256 11.2083C26.584 14.6167 26.584 19.3833 24.0256 22.7917C23.909 22.9583 23.7173 23.0417 23.5256 23.0417Z"
                    fill="#60A5FA"
                  />
                  <path
                    d="M18.6837 10.15C17.7503 9.63333 16.5587 9.76666 15.342 10.525L12.9087 12.05C12.742 12.15 12.5503 12.2083 12.3587 12.2083H11.5837H11.167C9.15033 12.2083 8.04199 13.3167 8.04199 15.3333V18.6667C8.04199 20.6833 9.15033 21.7917 11.167 21.7917H11.5837H12.3587C12.5503 21.7917 12.742 21.85 12.9087 21.95L15.342 23.475C16.0753 23.9333 16.792 24.1583 17.4587 24.1583C17.892 24.1583 18.3087 24.0583 18.6837 23.85C19.6087 23.3333 20.1253 22.2583 20.1253 20.825V13.175C20.1253 11.7417 19.6087 10.6667 18.6837 10.15Z"
                    fill="#60A5FA"
                  />
                </svg>
              </span>
              <div className="ml-3">
                <h6 className="font-weight-normal mb-1">Notification</h6>
                <small
                  className="d-block"
                  style={{
                    fontSize: "12px",
                    color: "rgba(107, 114, 128, 1)",
                    lineHeight: "1.4",
                  }}
                >
                  Personal details, password, communication preferences, and
                  active sessions.
                </small>
              </div>
            </div>

            <div
              onClick={() => setOpenFilter(false)}
              className="d-flex rounded settings_option_box"
            >
              <span>
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="34" height="34" rx="4.25" fill="#EFF6FF" />
                  <path
                    d="M20.3333 8.66656H13.6667C10.75 8.66656 9.5 10.3332 9.5 12.8332V21.1666C9.5 23.6666 10.75 25.3332 13.6667 25.3332H20.3333C23.25 25.3332 24.5 23.6666 24.5 21.1666V12.8332C24.5 10.3332 23.25 8.66656 20.3333 8.66656ZM15.775 20.7249C16.0167 20.9666 16.0167 21.3666 15.775 21.6082C15.65 21.7332 15.4917 21.7916 15.3333 21.7916C15.175 21.7916 15.0167 21.7332 14.8917 21.6082L13.225 19.9416C12.9833 19.6999 12.9833 19.2999 13.225 19.0582L14.8917 17.3916C15.1333 17.1499 15.5333 17.1499 15.775 17.3916C16.0167 17.6332 16.0167 18.0332 15.775 18.2749L14.55 19.4999L15.775 20.7249ZM20.775 19.9416L19.1083 21.6082C18.9833 21.7332 18.825 21.7916 18.6667 21.7916C18.5083 21.7916 18.35 21.7332 18.225 21.6082C17.9833 21.3666 17.9833 20.9666 18.225 20.7249L19.45 19.4999L18.225 18.2749C17.9833 18.0332 17.9833 17.6332 18.225 17.3916C18.4667 17.1499 18.8667 17.1499 19.1083 17.3916L20.775 19.0582C21.0167 19.2999 21.0167 19.6999 20.775 19.9416ZM22.4167 14.7082H20.75C19.4833 14.7082 18.4583 13.6832 18.4583 12.4166V10.7499C18.4583 10.4082 18.7417 10.1249 19.0833 10.1249C19.425 10.1249 19.7083 10.4082 19.7083 10.7499V12.4166C19.7083 12.9916 20.175 13.4582 20.75 13.4582H22.4167C22.7583 13.4582 23.0417 13.7416 23.0417 14.0832C23.0417 14.4249 22.7583 14.7082 22.4167 14.7082Z"
                    fill="#60A5FA"
                  />
                </svg>
              </span>
              <div className="ml-3">
                <h6 className="font-weight-normal mb-1">Developers</h6>
                <small
                  className="d-block"
                  style={{
                    fontSize: "12px",
                    color: "rgba(107, 114, 128, 1)",
                    lineHeight: "1.4",
                  }}
                >
                  Personal details, password, communication preferences, and
                  active sessions.
                </small>
              </div>
            </div>

            <div
              onClick={() => setOpenFilter(false)}
              className="d-flex rounded settings_option_box"
            >
              <span>
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="34" height="34" rx="4.25" fill="#EFF6FF" />
                  <path
                    d="M22.4497 10.475L17.8663 8.75833C17.3913 8.58333 16.6163 8.58333 16.1413 8.75833L11.558 10.475C10.6747 10.8083 9.95801 11.8417 9.95801 12.7833V19.5333C9.95801 20.2083 10.3997 21.1 10.9413 21.5L15.5247 24.925C16.333 25.5333 17.658 25.5333 18.4663 24.925L23.0497 21.5C23.5913 21.0917 24.033 20.2083 24.033 19.5333V12.7833C24.0413 11.8417 23.3247 10.8083 22.4497 10.475ZM17.6247 17.725V19.9167C17.6247 20.2583 17.3413 20.5417 16.9997 20.5417C16.658 20.5417 16.3747 20.2583 16.3747 19.9167V17.725C15.533 17.4583 14.9163 16.675 14.9163 15.75C14.9163 14.6 15.8497 13.6667 16.9997 13.6667C18.1497 13.6667 19.083 14.6 19.083 15.75C19.083 16.6833 18.4663 17.4583 17.6247 17.725Z"
                    fill="#60A5FA"
                  />
                </svg>
              </span>
              <div className="ml-3">
                <h6 className="font-weight-normal mb-1">
                  Payment and Security
                </h6>
                <small
                  className="d-block"
                  style={{
                    fontSize: "12px",
                    color: "rgba(107, 114, 128, 1)",
                    lineHeight: "1.4",
                  }}
                >
                  Personal details, password, communication preferences, and
                  active sessions.
                </small>
              </div>
            </div>

            <div
              onClick={() => setOpenFilter(false)}
              className="d-flex rounded settings_option_box"
            >
              <span>
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="34" height="34" rx="4.25" fill="#EFF6FF" />
                  <path
                    d="M21.6079 13.475C21.5495 13.4667 21.4912 13.4667 21.4329 13.475C20.1412 13.4334 19.1162 12.375 19.1162 11.075C19.1162 9.75002 20.1912 8.66669 21.5245 8.66669C22.8495 8.66669 23.9329 9.74169 23.9329 11.075C23.9245 12.375 22.8995 13.4334 21.6079 13.475Z"
                    fill="#60A5FA"
                  />
                  <path
                    d="M24.3247 19.25C23.3913 19.875 22.083 20.1083 20.8747 19.95C21.1913 19.2666 21.358 18.5083 21.3663 17.7083C21.3663 16.875 21.183 16.0833 20.833 15.3916C22.0663 15.225 23.3747 15.4583 24.3163 16.0833C25.633 16.95 25.633 18.375 24.3247 19.25Z"
                    fill="#60A5FA"
                  />
                  <path
                    d="M12.3665 13.475C12.4248 13.4667 12.4831 13.4667 12.5415 13.475C13.8331 13.4334 14.8581 12.375 14.8581 11.075C14.8581 9.74169 13.7831 8.66669 12.4498 8.66669C11.1248 8.66669 10.0498 9.74169 10.0498 11.075C10.0498 12.375 11.0748 13.4334 12.3665 13.475Z"
                    fill="#60A5FA"
                  />
                  <path
                    d="M12.4579 17.7083C12.4579 18.5167 12.6329 19.2833 12.9496 19.975C11.7746 20.1 10.5496 19.85 9.64961 19.2583C8.33294 18.3833 8.33294 16.9583 9.64961 16.0833C10.5413 15.4833 11.7996 15.2417 12.9829 15.375C12.6413 16.075 12.4579 16.8667 12.4579 17.7083Z"
                    fill="#60A5FA"
                  />
                  <path
                    d="M17.1 20.225C17.0333 20.2167 16.9583 20.2167 16.8833 20.225C15.35 20.175 14.125 18.9167 14.125 17.3667C14.1333 15.7833 15.4083 14.5 17 14.5C18.5833 14.5 19.8667 15.7833 19.8667 17.3667C19.8583 18.9167 18.6417 20.175 17.1 20.225Z"
                    fill="#60A5FA"
                  />
                  <path
                    d="M14.392 21.95C13.1337 22.7917 13.1337 24.175 14.392 25.0083C15.8253 25.9667 18.1753 25.9667 19.6087 25.0083C20.867 24.1667 20.867 22.7833 19.6087 21.95C18.1837 20.9917 15.8337 20.9917 14.392 21.95Z"
                    fill="#60A5FA"
                  />
                </svg>
              </span>
              <div className="ml-3">
                <h6 className="font-weight-normal mb-1">Role and Permission</h6>
                <small
                  className="d-block"
                  style={{
                    fontSize: "12px",
                    color: "rgba(107, 114, 128, 1)",
                    lineHeight: "1.4",
                  }}
                >
                  Personal details, password, communication preferences, and
                  active sessions.
                </small>
              </div>
            </div>
          </Stack>
          {/* <Box sx={{ p: 3 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              startIcon={<Iconify icon="ic:round-clear-all" />}
            >
              Clear All
            </Button>
          </Box> */}
        </Scrollbar>
      </Drawer>
    </>
  );
}
