/* eslint-disable react/button-has-type */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Helmet } from "react-helmet-async";
// import * as Router from 'react-router-dom';
// import Scrollbar from "../../../components/scrollbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import DatePicker from "react-datepicker";
import TextField from "@mui/material/TextField";
import { Link as RouterLink } from "react-router-dom";

import {
    Link,

} from "@mui/material";
// import {
//     // useEffect, 
//     useState
// } from "react";
// import bus from "src/bus";
import styles from "../Payment.module.css";
// ----------------------------------------------------------------------

export default function PLSinglePayment() {


    return (
        <>
            <Helmet>
                <title> Create Single Payment Link </title>
            </Helmet>
            <div className="wrapper mt-md-3 mt-4">
                <div className={`${styles.header_wrap} d-flex justify-content-between align-items-center`}>
                    <div className="d-flex">
                        <Link
                            className="d-block text-secondary"
                            to="/app/payment/payment-links"
                            component={RouterLink}
                        >
                            <i
                                className="bi bi-x"
                                style={{ fontSize: "1.5em", lineHeight: "1" }}
                            ></i>
                        </Link>
                        <h6 className="pl-3 mb-0" style={{ fontWeight: "bold" }}>Single Payment Link</h6>
                    </div>
                    {/* <div>
                        <div className="btn-group">
                            <Link
                                className="btn border btn-primary"
                                to="/app/payment/invoice"
                                component={RouterLink}
                            >
                                <span className="button_text text-white">Send</span>
                            </Link>
                            <Link
                                className="btn border ml-3"
                                to="/app/payment/invoice/preview"
                                component={RouterLink}
                            >
                                <span className="button_text">Preview</span>
                            </Link>
                        </div>
                    </div> */}
                </div>

                <div className={``}>
                    <div className="row">
                        <div className="col-lg-6 px-lg-5 pt-5">
                            <div className="px-lg-4 mb-5 pb-lg-5 mx-auto" style={{width: '30em', maxWidth: '100%'}}>
                                <div className="mb-3">
                                    <label className="mb-3">Title</label>
                                    <div className="" style={{ width: "100%" }}>
                                        <div className="">
                                            <TextField
                                                name=""
                                                required
                                                type="text"
                                                id="ounit"
                                                size="small"
                                                placeholder=""
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label className="mb-3">Recipient Email Address</label>
                                    <div className="" style={{ width: "100%" }}>
                                        <div className="">
                                            <TextField
                                                name=""
                                                required
                                                type="email"
                                                id="ounit"
                                                size="small"
                                                placeholder=""
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label className="mb-3">Type</label>

                                    <div className="" style={{ width: "100%" }}>
                                        <div className="">
                                            <Select
                                                labelId="s"
                                                id="s"
                                                fullWidth
                                                size="small"
                                                required
                                                name="verified"
                                                defaultValue="select"
                                            >
                                                <MenuItem value="select">Single</MenuItem>
                                                <MenuItem value="operation">Operations</MenuItem>
                                                <MenuItem value="banking">Banking</MenuItem>
                                                <MenuItem value="finance">Finance</MenuItem>
                                            </Select>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="mb-3">
                                        <label className="mb-3">Payment</label>
                                        <small className="d-block mb-3 text-secondary font-weight-normal">
                                            Create an invoice requesting payment on a specific date
                                        </small>
                                        <div className="" style={{ width: "fit-content" }}>
                                            <div
                                                style={{ position: "relative" }}
                                            >
                                                <span
                                                    style={{
                                                        position: "absolute",
                                                        right: "10px",
                                                        top: "5px",
                                                        zIndex: "10",
                                                    }}
                                                >
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.66602 1.6665V4.1665" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M13.334 1.6665V4.1665" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M2.91602 7.5752H17.0827" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M17.5 7.08317V14.1665C17.5 16.6665 16.25 18.3332 13.3333 18.3332H6.66667C3.75 18.3332 2.5 16.6665 2.5 14.1665V7.08317C2.5 4.58317 3.75 2.9165 6.66667 2.9165H13.3333C16.25 2.9165 17.5 4.58317 17.5 7.08317Z" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M13.0781 11.4167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M13.0781 13.9167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M9.99607 11.4167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M9.99607 13.9167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M6.91209 11.4167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M6.91209 13.9167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>

                                                </span>
                                                <DatePicker
                                                    // showIcon
                                                    selected={dueDate}
                                                    onChange={(date) => setDueDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control w-100"
                                                    placeholderText="Select Due Date"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div> */}

                                <div className="mb-3">
                                    <label className="mb-3">Settlement Account</label>

                                    {/* <small className="d-block mb-2 text-secondary font-weight-normal">
                                            Select account to credit once payment is sent
                                        </small> */}
                                    <div className="" style={{ width: "100%" }}>
                                        <div className="">
                                            <Select
                                                labelId="s"
                                                id="s"
                                                fullWidth
                                                size="small"
                                                required
                                                name="verified"
                                                defaultValue="operation"
                                            >
                                                <MenuItem value="operation">Operations - $1,100</MenuItem>
                                                <MenuItem value="banking">Banking - $1,100</MenuItem>
                                                <MenuItem value="finance">Finance - $1,100</MenuItem>
                                            </Select>
                                        </div>
                                        {/* <div className="checkbox_div pt-2">
                                            <Checkbox id="schedule" checked={save} onClick={handleChangeSave} style={{}} />
                                            <label htmlFor="schedule" className="ml-2" style={{ fontSize: '14px', cursor: 'pointer', fontWeight: '400' }}>Include link for online payment</label>
                                        </div> */}
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="">Input Amount</label>
                                    <div className={`${styles.charges_input}`}>
                                        <div className={`${styles.select_wrap}`}>
                                            <Select
                                                labelId="s"
                                                id="s"
                                                size="small"
                                                required
                                                name="verified"
                                                defaultValue="ngn"
                                                style={{ width: "fit-content" }}
                                            >
                                <MenuItem value="ngn">NGN</MenuItem>
                                <MenuItem value="cny">CNY</MenuItem>
                                            </Select>
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="1200"
                                            style={{ width: "100%", textIndent: "75px" }}
                                            className=" form-control"
                                        />
                                    </div>
                                    <small className="text-secondary font-weight-normal">
                                        Leave empty to allow customers enter desired amount
                                    </small>
                                </div>

                                <div className="mb-3">
                                    <label className="mb-3">Description</label>
                                    <div className="" style={{ width: "100%" }}>
                                        <div className="">
                                            <TextField
                                                name=""
                                                multiline
                                                rows={4}
                                                required
                                                type="email"
                                                id="ounit"
                                                size="small"
                                                placeholder=""
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <Link to="/app/wallet/payment-otp" component={RouterLink} className="btn border btn-primary">
                                        <span className="button_text text-white">Create Link</span>
                                    </Link>
                                    &nbsp; &nbsp;
                                    <Link
                                        className="btn border"
                                        to="/app/wallet"
                                        component={RouterLink}
                                    >
                                        <span className="button_text">Cancel</span>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 py-5" style={{ background: 'rgba(249, 250, 251, 1)' }}>
                            <div className="wrapper pb-4 px-md-4 mx-auto" style={{width: '30em', maxWidth: '100%'}}>
                                <div className="d-flex justify-content-between align-items-center pb-3">
                                    <div className="d-flex">
                                        <h6 className="">Preview</h6>
                                    </div>
                                </div>

                                <div className={`${styles.PreviewInvoice_wrapper_prev} mx-auto px-3 py-5 p-md-5 position-relative`}>
                                    <div style={{
                                        position: 'absolute',
                                        top: '0',
                                        right: '8px',
                                    }}>
                                        <svg width="87" height="80" viewBox="0 0 87 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 48L47.5 0.5H87L7.5 80L0 48Z" fill="#222222" />
                                        </svg>
                                    </div>
                                    <div style={{
                                        position: 'absolute',
                                        top: '0',
                                        right: '68px',
                                    }}>
                                        <svg width="87" height="80" viewBox="0 0 87 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 48L47.5 0.5H87L7.5 80L0 48Z" fill="#222222" />
                                        </svg>
                                    </div>

                                    <div>
                                        <div>
                                            <img src="/assets/SPL_Avatar.png" alt="" width={50} />
                                        </div>
                                        <p className="mt-3" style={{
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            textAlign: 'center',

                                        }}>Rabbit Fund Raising</p>
                                        <div className="p-2 border rounded text-center">
                                            <h3 className="mb-0"
                                                style={{
                                                    // fontSize: '40px',
                                                    fontWeight: '700',
                                                    lineHeight: '44px',
                                                    // textAlign: 'left',

                                                }}
                                            >NGN1200.00</h3>
                                        </div>

                                        <div className="mt-4 mx-auto" style={{width: '38em', maxWidth: '100%'}}>
                                            <p className="text-center" style={{fontSize: '10px'}}>
                                                Rhoncus morbi et augue nec, in id ullamcorper at sit. Condimentum sit nunc in eros scelerisque sed. Commodo in viverra nunc, ullamcorper ut. Non, amet, aliquet scelerisque nullam sagittis, pulvinar. Fermentum scelerisque sit consectetur hac mi. Mollis leo eleifend ultricies purus iaculis.
                                            </p>
                                        </div>
                                        <div className="nelow_btn mt-5 pt-5">
                                            <div className="btn_wrap">
                                                <Link to="" component={RouterLink} className="btn d-block w-100 text-white py-3" style={{background: 'rgba(156, 163, 175, 1)'}}>Proceed</Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="mb-4 d-flex justify-content-between align-items-center">
                                        <h6>
                                            <div className={`${styles.logo_wrap} d-inline`}>
                                                <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.3743 21.2766C11.5225 20.895 11.5932 20.4877 11.5822 20.0785C11.6048 18.9445 11.9079 17.8337 12.4644 16.8454C13.0195 15.8587 13.8112 15.0254 14.7681 14.4205C15.1225 14.2304 15.4346 13.9705 15.6855 13.6563C15.9365 13.3421 16.1211 12.9803 16.2282 12.5928C16.3354 12.2052 16.3627 11.7999 16.3087 11.4015C16.2547 11.0031 16.1204 10.6197 15.9139 10.2747C15.6381 9.81376 15.2412 9.4359 14.7681 9.18449C14.4969 8.99633 14.2364 8.79368 13.988 8.57656L13.3656 7.9671C12.2251 6.76815 11.5856 5.1789 11.5776 3.52416C11.5894 3.11488 11.5191 2.70739 11.3709 2.32572C11.2226 1.94406 10.9994 1.59594 10.7145 1.30192C10.4308 1.00823 10.0909 0.774703 9.71495 0.61528C9.33903 0.455856 8.93485 0.373805 8.52653 0.374024C8.11844 0.374599 7.71461 0.457015 7.33893 0.616394C6.96325 0.775773 6.62335 1.00888 6.33934 1.30192C6.05489 1.59646 5.83205 1.94477 5.68384 2.32647C5.53563 2.70818 5.46504 3.11561 5.47619 3.52492C5.48285 4.05624 5.62919 4.57646 5.90053 5.03333C6.17093 5.48942 6.55699 5.86601 7.01965 6.12502C8.89297 7.31041 10.1964 9.57683 10.1964 11.8059C10.1918 13.4463 9.56445 15.0237 8.44121 16.2192L7.02422 17.4777C6.56179 17.7359 6.17555 18.1123 5.90434 18.5694C5.63237 19.0257 5.48686 19.5468 5.48 20.0785C5.46835 20.4878 5.53872 20.8953 5.68695 21.2769C5.83519 21.6586 6.0583 22.0067 6.34315 22.3008C6.62688 22.5944 6.96683 22.8279 7.34273 22.9873C7.71864 23.1467 8.12279 23.2288 8.5311 23.2287C8.93932 23.2282 9.34329 23.1458 9.71911 22.9865C10.0949 22.8271 10.435 22.5939 10.7191 22.3008C11.0034 22.0063 11.2261 21.6581 11.3743 21.2766Z" fill="#222222" />
                                                    <path d="M15.5124 5.21921C15.1765 4.71489 14.9974 4.12143 14.9974 3.51502C14.998 2.70271 15.3198 1.92357 15.8926 1.34763C16.3907 0.846536 17.0476 0.53439 17.7507 0.464748C18.4538 0.395107 19.1592 0.572311 19.7459 0.96596C20.2484 1.30337 20.6397 1.78215 20.8703 2.34181C21.101 2.90211 21.1612 3.518 21.0434 4.11236C20.9256 4.70672 20.6352 5.25313 20.2083 5.68316C19.7828 6.11201 19.2391 6.40432 18.6466 6.52278C18.0542 6.64124 17.4399 6.58047 16.8822 6.34823C16.3239 6.11576 15.8472 5.72283 15.5124 5.21921Z" fill="#222222" />
                                                    <path d="M16.3542 17.5287C16.8563 17.192 17.4467 17.0122 18.0501 17.0122V17.0107C18.4511 17.0112 18.8482 17.0908 19.2184 17.2451C19.5886 17.3994 19.9248 17.6252 20.2075 17.9096C20.7073 18.4124 21.0181 19.0722 21.0875 19.7776C21.1569 20.483 20.9806 21.1908 20.5885 21.7812C20.2537 22.2855 19.7767 22.679 19.2179 22.9118C18.6602 23.144 18.0459 23.2048 17.4535 23.0863C16.8611 22.9679 16.3174 22.6755 15.8918 22.2467C15.4648 21.8166 15.1742 21.2701 15.0565 20.6755C14.9387 20.081 14.999 19.465 15.2298 18.9046C15.4605 18.3449 15.8517 17.8662 16.3542 17.5287Z" fill="#222222" />
                                                    <path d="M25.346 10.0933C25.6811 10.5975 25.8601 11.1899 25.8603 11.7961C25.8598 12.6086 25.538 13.388 24.9651 13.9642C24.6823 14.2485 24.3461 14.4743 23.9759 14.6285C23.6057 14.7828 23.2087 14.8625 22.8076 14.8631C22.2036 14.8631 21.6133 14.6833 21.1118 14.3466C20.6093 14.0092 20.218 13.5304 19.9874 12.9707C19.7566 12.4104 19.6963 11.7943 19.8141 11.1998C19.9318 10.6053 20.2224 10.0587 20.6494 9.62864C21.075 9.19964 21.6188 8.90723 22.2114 8.78877C22.804 8.67031 23.4184 8.73116 23.9763 8.96357C24.5347 9.19624 25.0114 9.58945 25.346 10.0933Z" fill="#222222" />
                                                    <path d="M2.07695 9.24696C2.57899 8.91023 3.16864 8.73044 3.77276 8.73044C4.17374 8.73129 4.57062 8.8112 4.9407 8.96558C5.31077 9.11996 5.64677 9.34579 5.92948 9.63016C6.42894 10.1327 6.73961 10.7922 6.80902 11.4974C6.87842 12.2025 6.70229 12.9099 6.31039 13.5002C5.9757 14.0041 5.49898 14.3973 4.94063 14.63C4.38271 14.8611 3.7687 14.9213 3.17652 14.8029C2.58434 14.6845 2.04068 14.3928 1.61452 13.9649C1.18767 13.5349 0.897181 12.9885 0.779421 12.3941C0.661661 11.7998 0.721865 11.1839 0.952497 10.6236C1.18302 10.0637 1.57431 9.58461 2.07695 9.24696Z" fill="#222222" />
                                                </svg>

                                            </div>
                                            <span className="ml-2" style={{ fontWeight: '600', fontSize: '12px' }}>Company Name</span>
                                        </h6>
                                        <span
                                            className="px-2"
                                            style={{ fontSize: "8px", cursor: "pointer" }}
                                        >
                                            #456799
                                        </span>
                                    </div>

                                    <div>
                                        <small className="d-block pb-2">Bill to <span> Michel Joseph</span></small>
                                        <small className="d-block pb-2">Date Created: <span>13th Decemeber , 2024</span></small>
                                        <small className="d-block pb-2">Due Created: <span>20th Decemeber , 2024</span></small>
                                    </div>

                                    <div className="mt-5">
                                        <div className="pb-2 border-bottom">
                                            <label htmlFor="">Item Details</label>
                                        </div>
                                        <div className={`py-5`}>
                                            <div className="row">
                                                <div className="col-md-3 mb-0 pb-0 pr-1">
                                                    <div className="">
                                                        <label htmlFor="">Items</label>
                                                        <div className="" style={{ width: "100%" }}>
                                                            <div className="">
                                                                <small className="d-block mb-2">iPad</small>
                                                                <small className="d-block mb-2">WristWatch</small>
                                                                <small className="d-block mb-2">Macbook</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-0 pb-0 pr-1">
                                                    <div className="">
                                                        <label htmlFor="" className="d-block text-right">Per Unit</label>
                                                        <div className="" style={{ width: "100%" }}>
                                                            <div className="text-right">
                                                                <small className="d-block mb-2">$10.00</small>
                                                                <small className="d-block mb-2">$0.29</small>
                                                                <small className="d-block mb-2">$20.00</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-0 pb-0 pr-1">
                                                    <div className="">
                                                        <label htmlFor="" className="d-block text-right">Quantity</label>
                                                        <div className="" style={{ width: "100%" }}>
                                                            <div className="text-right">
                                                                <small className="d-block mb-2">2</small>
                                                                <small className="d-block mb-2">4</small>
                                                                <small className="d-block mb-2">1</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-0 pb-0 pr-1">
                                                    <div className="">
                                                        <label htmlFor="" className="d-block text-right">Amount</label>
                                                        <div className="" style={{ width: "100%" }}>
                                                            <div className="text-right">
                                                                <small className="d-block mb-2">$20.00</small>
                                                                <small className="d-block mb-2">$1.16</small>
                                                                <small className="d-block mb-2">$20.00</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="pb-2 border-bottom">
                                            <label htmlFor="">Add Ons & Totals</label>
                                        </div>
                                        <div className={`py-4`}>
                                            <div className="d-flex justify-content-between">
                                                <div className="mb-0 pb-0 pr-1">
                                                    <div className="">
                                                        <div className="">
                                                            <div className="">
                                                                <small className="d-block mb-2">Subtotal</small>
                                                                <small className="d-block mb-2">Shipping</small>
                                                                <small className="d-block mb-2">Discount</small>
                                                                <small className="d-block mb-2">Tax</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-0 pb-0 pr-1">
                                                    <div className="">
                                                        <div className="">
                                                            <div className="text-right">
                                                                <small className="d-block mb-2">$61.16</small>
                                                                <small className="d-block mb-2">$5.00</small>
                                                                <small className="d-block mb-2">-</small>
                                                                <small className="d-block mb-2">-</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="pb-2 border-bottom border-top">
                                            <div className="d-flex justify-content-between py-3">
                                                <span style={{ fontWeight: '500' }}>Total</span>
                                                <p className="mb-0">
                                                    <span style={{ fontWeight: '500' }}>$99.45</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="pt-4 mt-2">
                                        <p className="d-block ">Payment Details</p>
                                        <small className="d-block pb-2">Bank Name: <span>Wema Bank</span></small>
                                        <small className="d-block pb-2">Account Number: <span>0066924804</span></small>
                                        <small className="d-block pb-2">Account Name: <span>Michel Jason</span></small>
                                        <small className="d-block pb-2">
                                            <Link className="text-primary font-weight-bold text-underlined">
                                                Click to proceed to payment
                                            </Link>
                                        </small>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
