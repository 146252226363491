// ----------------------------------------------------------------------
import styles from "./LedgerTable.module.css";
// import {
//     // useEffect,
//     useState,
// } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
    // useEffect,
    useState,
} from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
    Link,
    //   Avatar,
} from "@mui/material";
import Pagination from '../../pagination/GlobalPagination'; // Adjust the path as necessary
import Scrollbar from "../../components/scrollbar";
// ----------------------------------------------------------------------


export default function AccountTable() {
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 8;
    const navigate = useNavigate();


    const handleLedgerDetailsRoute = () => {
        navigate("/app/ledger-management/ledger-details/accounts");
    };

    const tableData = [
        {
            acc_name: 'Sales',
            acc_desc: 'Track amount received from s...',
            posted_bal: '₦24,000,000.00',
            normal_balance: 'Credit',
        },
        {
            acc_name: 'Salaries',
            acc_desc: 'Track amount paid to staffs',
            posted_bal: '₦10,000,000.00',
            normal_balance: 'Debit',
        },
        {
            acc_name: 'Salaries',
            acc_desc: 'Track amount paid to staffs',
            posted_bal: '₦10,000,000.00',
            normal_balance: 'Debit',
        },
        {
            acc_name: 'Purchases',
            acc_desc: 'Track amount received from s...',
            posted_bal: '₦24,000,000.00',
            normal_balance: 'Credit',
        },
    ];

    const totalResults = tableData.length;

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    // Calculate the data to display on the current page
    const currentTableData = tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize);


    return (
        <>
            <div className="px-0">

                <div style={{ marginTop: "0px", borderRadius: "10px" }} className="p-0">
                    <Scrollbar>
                        <div
                            className={`${styles.overflow_table} table-responsive  pt- pb-3 pl-0 pr-0`}
                            style={{ minHeight: '80vh' }}
                        >
                            <table
                                className={`${styles.table} table-hover table-striped`}
                                style={{ minWidth: "50em" }}
                            >
                                <thead className={`${styles.thead}`}>
                                    <tr>
                                        <th className="text-muted fs-13 fw-600 th_width" style={{ minWidth: "6%" }}>
                                           Name {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </th>
                                        <th className="text-muted fs-13 fw-600 th_width">Description {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></th>
                                        <th className="text-muted fs-13 fw-600 th_width">Posted Balance {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></th>
                                        <th className="text-muted fs-13 fw-600 th_width" style={{minWidth: '280px',}}>Normal Balance {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {currentTableData.map((item, index) => (
                                        <tr key={index} className={`${styles.tr} table-fonts cursor-pointer`} onClick={handleLedgerDetailsRoute}>
                                            <td>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div className=" ">
                                                    <span className="fw-500">
                                                            {item.acc_name}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <span className="fw-500">
                                                    {item.acc_desc}
                                                </span>
                                            </td>
                                            <td>
                                            <span className="fw-500">
                                                    {item.posted_bal}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="fw-500">
                                                    {item.normal_balance}
                                                </span>
                                            </td>
                                            {/* <td>
                                                <div className="btn-group d-flex">
                                                    <Link
                                                    to="/app/ledger-management/sales-ledger"
                                                    component={RouterLink}
                                                        className="p-2 ml-auto"
                                                        style={{ cursor: "pointer" }}

                                                    >
                                                        <svg
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="ml-auto"
                                                        >
                                                            <path
                                                                d="M7.60162 8.78101L10.6809 5.4812L11.5607 6.42401L7.60162 10.6667L3.64258 6.42401L4.52237 5.4812L7.60162 8.78101Z"
                                                                fill="#4B5563"
                                                            />
                                                        </svg>
                                                    </Link>
                                                </div>
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="pt-3">
                    <Pagination
                        currentPage={currentPage}
                        totalResults={totalResults}
                        pageSize={pageSize}
                        onPageChange={onPageChange}
                    />
                            </div>
                        </div>
                        {/* <div className="d-flex justify-content-center">

            </div> */}

                        {/* <div className="alert alert-primary text-center">
                <h4>No record found</h4>
              </div> */}
                    </Scrollbar>
                </div>
            </div>
        </>
    );
}
