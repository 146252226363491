import { Helmet } from "react-helmet-async";
// import * as Router from 'react-router-dom';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "react-datepicker/dist/react-datepicker.css";
import { Link as RouterLink } from "react-router-dom";

import { Link } from "@mui/material";
import { useState } from "react";
import GlobalModal from "../../../Modals/GlobalModal";
import styles from "../Wallet.module.css";
// ----------------------------------------------------------------------

export default function FundWallet() {
    const [existingBen, setExistingBen] = useState(false);
    const [recipient, setRecipient] = useState('select');

    const handleRecipientChange = (event) => {
        setRecipient(event.target.value);
    };

    const handleCloseExistingBen = () => {
        setExistingBen(false);
    };


    return (
        <>
            <Helmet>
                <title> Fund Wallet </title>
            </Helmet>
            <div className="wrapper mt-md-3 mt-4">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <Link
                            className="d-block text-secondary"
                            to="/app/wallet"
                            component={RouterLink}
                        >
                            <i
                                className="bi bi-x"
                                style={{ fontSize: "2em", lineHeight: "1" }}
                            ></i>
                        </Link>
                        <h3 className="pl-2">Withdraw Funds</h3>
                    </div>
                </div>

                <div className={`${styles.trftobank_side_wrap} mt-5`}>
                    <div>
                        <div className="mb-3">
                            <label htmlFor="">Input Amount</label>
                            <div className={`${styles.charges_input}`}>
                                <div className={`${styles.select_wrap}`}>
                                    <Select
                                        labelId="s"
                                        id="s"
                                        size="small"
                                        required
                                        name="verified"
                                        defaultValue="ngn"
                                        style={{ width: "fit-content" }}
                                    >
                                <MenuItem value="ngn">NGN</MenuItem>
                                <MenuItem value="cny">CNY</MenuItem>
                                    </Select>
                                </div>
                                <input
                                    type="text"
                                    placeholder="1200"
                                    style={{ width: "100%", textIndent: "75px" }}
                                    className=" form-control"
                                />
                            </div>
                            <small className="text-secondary font-weight-normal">
                                charge for transaction NGN10
                            </small>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="name">Select Destination</label>
                            <Select
                                labelId="s"
                                id="s"
                                fullWidth
                                size="small"
                                required
                                name="verified"
                                defaultValue="select"
                                value={recipient}
                                onChange={handleRecipientChange}
                            >
                                <MenuItem value="select">- Select -</MenuItem>
                                <MenuItem value="myself">Sterling Bank - 0066924804</MenuItem>
                                <MenuItem value="another-merch">Wema Bank - 0066924804</MenuItem>
                            </Select>
                        </div>

                        <div className="mt-5">
                            <Link to="/app/wallet/payment-otp" component={RouterLink} className="btn border btn-primary">
                                <span className="button_text text-white">Proceed</span>
                            </Link>
                            &nbsp; &nbsp;
                            <Link
                                className="btn border"
                                to="/app/wallet"
                                component={RouterLink}
                            >
                                <span className="button_text">Cancel</span>
                            </Link>
                        </div>
                    </div>
                </div>



                {/* Select Existing Beneficiary Modal */}
                <GlobalModal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={existingBen}
                    onClose={handleCloseExistingBen}
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    width="500px !important"
                    overflowY="scroll"
                    bgcolor="#FFFF"
                    border="1px solid #F5F5F5"
                    borderRadius="5px"
                    boxShadow="24"
                    p="25px"
                >
                    <div
                        className={`modal_content`}
                    // style={{overflowY: 'scroll', height: '450px'}}
                    >
                        <div className="mb-4 d-flex justify-content-between align-items-start">
                            <h4>Beneficiaries</h4>
                            <span
                                className="px-2"
                                style={{ fontSize: "25px", cursor: "pointer" }}
                                onClick={() => setExistingBen(false)}
                            >
                                &times;
                            </span>
                        </div>
                        <div className={`modal_header mb-0`}>
                            <div className={`${styles.hold_input1} w-100`}>
                                <img src="/assets/icons/search.svg" alt="" />
                                <input
                                    type="text"
                                    name="search"
                                    placeholder="Search"
                                    style={{ paddingLeft: "33px", width: "100%" }}
                                    className=" form-control"
                                />
                            </div>
                        </div>

                        <div
                            className="mt-4 modal_content"
                            style={{ maxHeight: "70vh", overflowY: "scroll" }}
                        >
                            <div
                                className="border-bottom py-3"
                                onClick={() => setExistingBen(false)}
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <div>
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Calvin Hawkins</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Ted Fox</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </GlobalModal>
            </div>
        </>
    );
}
