// ----------------------------------------------------------------------
import React from "react";
import { Helmet } from "react-helmet-async";
import styles from "./LedgerTable.module.css";
import TransactionsTable from "./TransactionsTable";
import AccountTable from "./AccountTable";
import {
    // useEffect,
    useState,
} from "react";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Link as RouterLink } from "react-router-dom";

import {
    Link,
    Drawer,
} from "@mui/material";
// sections
// mock
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// ----------------------------------------------------------------------

export default function LedgerDetails() {
    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = React.useState("1");

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [toggleShowLedger, setToggleShowLedger] = useState(false);
    const [toggleShowPosting, setToggleShowPosting] = useState(false);
    const [item, setItem] = useState({
        name: '',
        balance: '',
        description: '',
    });

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleToggleDropdown = () => {
        setToggleDropdown(!toggleDropdown);
    };

    const handleItemChange = (e) => {
        const { name, value } = e.target;
        setItem((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleCreateLedger = async () => {
        // console.log(item)
    }


    return (
        <>
            <Helmet>
                <title>All Transactions</title>
            </Helmet>

            <div className="px-0 px-md-2">
                <div onClick={() => handleToggleDropdown()}
                    className={`overlay_div ${toggleDropdown ? "show_overlay" : ""}`}
                ></div>

                <div className="d-flex justify-content-between align-items-center">
                    <div className="">
                        <h5 className="page_title mb-2">Ledgers</h5>
                        <p style={{ color: '#4B5563', fontSize: '13px', gap: '8px' }} className="mt-2 d-flex align-items-center fw-500 text-dark">
                            <Link to="/app/ledger-management" component={RouterLink} className="py-1 d-flex justify-content-center align-items-center">

                                <span className="text-dark">Ledger</span>
                            </Link>
                            <span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.29289 14.7071C6.90237 14.3166 6.90237 13.6834 7.29289 13.2929L10.5858 10L7.29289 6.70711C6.90237 6.31658 6.90237 5.68342 7.29289 5.29289C7.68342 4.90237 8.31658 4.90237 8.70711 5.29289L12.7071 9.29289C13.0976 9.68342 13.0976 10.3166 12.7071 10.7071L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071Z" fill="#111827" />
                                </svg>
                            </span>
                            <span className=" text-dark">
                                NGN Ledger
                            </span>
                        </p>
                    </div>
                </div>

                <div className="d-md-flex justify-content-between align-items-center mt-sm-4 w-100">
                    <div className="d-flex justify-content-between align-items-center mb-3 mb-md-0 w-100" style={{ gap: '10px', width: 'fit-content' }}>
                        <div className={`${styles.hold_input} w-100`}>
                            <img src="/assets/icons/search.svg" alt="" />
                            <input
                                type="text"
                                name="search"
                                placeholder="Search"
                                style={{ paddingLeft: "33px", width: "30em", maxWidth: '100%', borderRadius: '0', fontSize: '13px', minHeight: '40px' }}
                                className=" form-control rounded-0"
                            />
                        </div>
                    </div>
                    <div className="d-flex w-100 justify-content-end" style={{}}>
                        <div className="fit-content d-flex flex-wrap" style={{ whiteSpace: 'nowrap' }}>
                            <div className="fit-content">
                                <button
                                    // to="/app/ledger-management/new-transaction"
                                    // component={RouterLink}
                                    className="btn border btn-primary"
                                    type="button"
                                    onClick={() => setToggleShowPosting(true)}
                                >
                                    <span className="button_text text-white">New Posting</span>
                                </button>
                                &nbsp;&nbsp;
                                <button
                                    // to="/app/ledger-management/new-account"
                                    // component={RouterLink}
                                    className="btn border btn-primary"
                                    type="button"
                                    onClick={() => setToggleShowLedger(true)}
                                >
                                    <span className="button_text text-white">New Ledger Account</span>
                                </button>
                            </div>
                            <div className="fit-content last_btn_arrg ml-sm-2">
                                {value == 1 && (
                                    <div className="btn-group">
                                        <Link
                                            onClick={() => handleToggleDropdown()}
                                            className="btn border btn-primary rounded d-flex align-items-center"
                                        >
                                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.7523 5.01514L6.57741 8.19003C6.20246 8.56498 5.58891 8.56498 5.21396 8.19003L2.03906 5.01514" stroke="white" strokeWidth="1.16867" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            {" "}
                                            <span className="ml-1 text-white">Filters</span>
                                        </Link>
                                        <div
                                            className={`${toggleDropdown ? "show" : ""
                                                } dropdown-menu dropdown-menu-right ${styles.drp_dwn_menu_right_topup} drp_dwn_menu_right`}
                                            style={{ transform: "translate3d(-310px, 40px, 0px)" }}
                                        >
                                            <div className={`${styles.filter_wrap} px-2 px-sm-4 py-3`}>
                                                <div className="row">
                                                    <div className="col-12 mb-1">

                                                        <div className="mb-3 d-flex flex-column">
                                                            <label className="fw-500 text-dark" htmlFor="normal_bal">Normal Balance</label>
                                                            <div className="position-relative bg-white">

                                                                <span className=" all_selectdropdown_icon position-absolute px-2">
                                                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z" fill="#6B7280" />
                                                                    </svg>
                                                                </span>
                                                                <Select
                                                                    labelId="normal_bal"
                                                                    id="normal_bal"
                                                                    fullWidth
                                                                    size="small"
                                                                    required
                                                                    name="normal_bal"
                                                                    defaultValue="credit"
                                                                >
                                                                    <MenuItem value="select">Select</MenuItem>
                                                                    <MenuItem value="credit">Credit</MenuItem>
                                                                    <MenuItem value="debit">Debit</MenuItem>
                                                                </Select>
                                                            </div>
                                                        </div>

                                                        <div className="mb-3 d-flex flex-column">
                                                            <label className="fw-500 text-dark" htmlFor="balance">Balance</label>
                                                            <div className="position-relative d-flex" style={{ gap: '0px' }}>
                                                                {/* <span className="position-absolute" style={{
                                                                zIndex: '10',
                                                                top: '50%',
                                                                left: '15px',
                                                                transform: 'translateY(-50%)'
                                                            }}>$</span> */}
                                                                <TextField
                                                                    name="min_products"
                                                                    required
                                                                    type="number"
                                                                    fullWidth
                                                                    id="min_balance"
                                                                    placeholder="Min"
                                                                    size="small"
                                                                />

                                                                <div
                                                                    className="px-2 d-flex align-items-center "
                                                                    style={{ height: "40px" }}
                                                                >
                                                                    <img
                                                                        src="/assets/icons/line.svg"
                                                                        style={{ width: "30px" }}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <TextField
                                                                    name="min_products"
                                                                    required
                                                                    type="number"
                                                                    fullWidth
                                                                    id="max_balance"
                                                                    placeholder="Max"
                                                                    size="small"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-1">
                                                    <div className="mr-auto" style={{ width: "fit-content" }}>
                                                        <button
                                                            type="button"
                                                            className={`btn border bg-white`}
                                                            onClick={() => setToggleDropdown(false)}
                                                        >
                                                            Clear
                                                        </button>
                                                        &nbsp; &nbsp;
                                                        <button
                                                            type="button"
                                                            className={`btn btn-primary`}
                                                            onClick={() => setToggleDropdown(false)}
                                                        >
                                                            Apply
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {value == 2 && (
                                    <div className="btn-group">
                                        <Link
                                            onClick={() => handleToggleDropdown()}
                                            className="btn border btn-primary rounded d-flex align-items-center"
                                        >
                                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.7523 5.01514L6.57741 8.19003C6.20246 8.56498 5.58891 8.56498 5.21396 8.19003L2.03906 5.01514" stroke="white" strokeWidth="1.16867" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            {" "}
                                            <span className="ml-1 text-white">Filters</span>
                                        </Link>
                                        <div
                                            className={`${toggleDropdown ? "show" : ""
                                                } dropdown-menu dropdown-menu-right ${styles.drp_dwn_menu_right_topup} drp_dwn_menu_right`}
                                            style={{ transform: "translate3d(-310px, 40px, 0px)" }}
                                        >
                                            <div className={`${styles.filter_wrap} px-2 px-sm-4 py-3`}>
                                                <div className="row">
                                                    <div className="col-12 mb-1">

                                                        <div className="mb-3 d-flex flex-column">
                                                            <label htmlFor="name">Status</label>
                                                            <div className="position-relative bg-white">

                                                                <span className=" all_selectdropdown_icon position-absolute px-2">
                                                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z" fill="#6B7280" />
                                                                    </svg>
                                                                </span>
                                                                <Select
                                                                    labelId="s"
                                                                    id="s"
                                                                    fullWidth
                                                                    size="small"
                                                                    required
                                                                    name="verified"
                                                                    placeholder="Select"
                                                                    defaultValue="ALL"
                                                                >
                                                                    <MenuItem value="ALL">ALL</MenuItem>
                                                                    <MenuItem value="POSTED">POSTED</MenuItem>
                                                                </Select>
                                                            </div>
                                                        </div>

                                                        <div className="mb-3 d-flex flex-column">
                                                            <label htmlFor="ounit">Amount</label>
                                                            <div className="position-relative">
                                                                <TextField
                                                                    name="min_products"
                                                                    required
                                                                    type="number"
                                                                    fullWidth
                                                                    id="ounit"
                                                                    placeholder=""
                                                                    size="small"
                                                                />
                                                            </div>
                                                        </div>

                                                        <label htmlFor="ounit">Custom Date Range</label>
                                                        <div className="start_enddate d-flex">
                                                            <div className="" style={{ width: "100%" }}>
                                                                <div
                                                                    className="mb-3 bg-white"
                                                                    style={{ position: "relative" }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            position: "absolute",
                                                                            right: "10px",
                                                                            top: "7px",
                                                                            zIndex: "0",
                                                                        }}
                                                                    >
                                                                        <svg
                                                                            width="14"
                                                                            height="14"
                                                                            viewBox="0 0 14 14"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                clipRule="evenodd"
                                                                                d="M2.40527 4.29523C2.40527 3.38641 3.14202 2.64966 4.05084 2.64966H10.6331C11.5419 2.64966 12.2787 3.3864 12.2787 4.29523V10.8775C12.2787 11.7863 11.5419 12.5231 10.6331 12.5231H4.05084C3.14202 12.5231 2.40527 11.7863 2.40527 10.8775V4.29523Z"
                                                                                stroke="#7B8794"
                                                                                strokeWidth="1.64557"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M9.53516 1.55298V3.74707"
                                                                                stroke="#7B8794"
                                                                                strokeWidth="1.64557"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M5.14844 1.55298V3.74707"
                                                                                stroke="#7B8794"
                                                                                strokeWidth="1.64557"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M2.40527 5.94092H12.2787"
                                                                                stroke="#7B8794"
                                                                                strokeWidth="1.64557"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    <DatePicker
                                                                        // showIcon
                                                                        showYearDropdown
                                                                        scrollableYearDropdown
                                                                        yearDropdownItemNumber={15}
                                                                        selected={startDate}
                                                                        onChange={(date) => setStartDate(date)}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        className="form-control border"
                                                                        placeholderText="Start date"
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="px-2 d-flex align-items-center "
                                                                style={{ height: "40px" }}
                                                            >
                                                                <img
                                                                    src="/assets/icons/line.svg"
                                                                    style={{ width: "30px" }}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="" style={{ width: "100%" }}>
                                                                <div
                                                                    className="mb-3 bg-white"
                                                                    style={{ position: "relative" }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            position: "absolute",
                                                                            right: "10px",
                                                                            top: "7px",
                                                                            zIndex: "0",
                                                                        }}
                                                                    >
                                                                        <svg
                                                                            width="14"
                                                                            height="14"
                                                                            viewBox="0 0 14 14"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                clipRule="evenodd"
                                                                                d="M2.40527 4.29523C2.40527 3.38641 3.14202 2.64966 4.05084 2.64966H10.6331C11.5419 2.64966 12.2787 3.3864 12.2787 4.29523V10.8775C12.2787 11.7863 11.5419 12.5231 10.6331 12.5231H4.05084C3.14202 12.5231 2.40527 11.7863 2.40527 10.8775V4.29523Z"
                                                                                stroke="#7B8794"
                                                                                strokeWidth="1.64557"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M9.53516 1.55298V3.74707"
                                                                                stroke="#7B8794"
                                                                                strokeWidth="1.64557"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M5.14844 1.55298V3.74707"
                                                                                stroke="#7B8794"
                                                                                strokeWidth="1.64557"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M2.40527 5.94092H12.2787"
                                                                                stroke="#7B8794"
                                                                                strokeWidth="1.64557"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    <DatePicker
                                                                        // showIcon
                                                                        showYearDropdown
                                                                        scrollableYearDropdown
                                                                        yearDropdownItemNumber={15}
                                                                        selected={endDate}
                                                                        onChange={(date) => setEndDate(date)}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        className="form-control border"
                                                                        placeholderText="End date"
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-1">
                                                    <div className="mr-auto" style={{ width: "fit-content" }}>
                                                        <button
                                                            type="button"
                                                            className={`btn border bg-white`}
                                                            onClick={() => setToggleDropdown(false)}
                                                        >
                                                            Clear
                                                        </button>
                                                        &nbsp; &nbsp;
                                                        <button
                                                            type="button"
                                                            className={`btn btn-primary`}
                                                            onClick={() => setToggleDropdown(false)}
                                                        >
                                                            Apply
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                &nbsp;&nbsp;
                                <div className="btn-group">
                                    <button
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                        className="btn border btn-primary"
                                        type="button"
                                    >
                                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.51855 8.93359V10.3937C1.51855 10.9313 1.95436 11.3671 2.49195 11.3671H9.30576C9.84335 11.3671 10.2792 10.9313 10.2792 10.3937V8.93359" stroke="#F3F4F6" strokeWidth="1.86" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3.9519 6.5L5.8987 8.4468L7.8455 6.5" stroke="#F3F4F6" strokeWidth="1.86" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M5.89868 1.6333V8.4471" stroke="#F3F4F6" strokeWidth="1.86" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        {" "}
                                        <span className="button_text text-white">Export as</span>
                                    </button>
                                    <div
                                        className="dropdown-menu dropdown-menu-right rounded border-0"
                                        style={{
                                            background: "#fff",
                                            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                                        }}
                                    >
                                        <button
                                            className="dropdown-item dropdown_text fw-500 position-relative"
                                        >
                                            Trial Balance
                                        </button>
                                        <button
                                            className="dropdown-item dropdown_text fw-500 position-relative"
                                        >
                                            CSV
                                        </button>
                                        <button
                                            className="dropdown-item dropdown_text fw-500 position-relative"
                                        >
                                            PDF
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: "0px", borderRadius: "10px" }} className="p-0 mt-4 create_invoice_tab_section">
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider", }}>
                            <TabList aria-label="lab API tabs" onChange={handleTabChange}>
                                <Tab label="Ledger Account" value="1" />
                                <Tab label="Postings" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1" className="invoice_preview_wrap">
                            <AccountTable />
                        </TabPanel>
                        <TabPanel value="2" className="invoice_preview_wrap">
                            <TransactionsTable />
                        </TabPanel>
                    </TabContext>
                </div>
            </div>

            {/* MODALS */}
            <Drawer
                anchor="right"
                open={toggleShowLedger}
                onClose={() => setToggleShowLedger(false)}
                PaperProps={{
                    sx: {
                        width: 350,
                        maxWidth: "100%",
                        border: "none",
                        marginTop: "0em",
                        height: "100vh",
                        padding: "20px",
                        paddingTop: '40px'
                    },
                }}
            >
                {/* <span
                    className="px- py-2"
                    style={{
                        fontSize: "25px",
                        cursor: "pointer",
                        position: "absolute",
                        top: "40px",
                        right: "20px",
                    }}
                    onClick={() => setToggleShowLedger(false)}
                >
                    &times;
                </span> */}
                <div
                    className={`modal_content px-sm2-2 py-3`}
                    style={{ overflowY: 'scroll', maxHeight: '90vh', }}
                >
                    <div className={`${styles.filter_wrapd} px-2 px-sms-4 py-3`}>
                        <div className="mt-3">
                            <p className="fs-16 mb-0 fw-500 text-dark">
                                New Ledger Account
                            </p>
                            <span className="fs-14">Set Up your ledger account</span>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12 mb-1">

                                <div className="mb-3 flex-column">
                                    <label className="fw-500 text-dark" htmlFor="name">Name</label>
                                    <div className="position-relative" style={{ gap: '0px' }}>
                                        <TextField
                                            name="name"
                                            required
                                            type="text"
                                            fullWidth
                                            id="name"
                                            placeholder=""
                                            size="small"
                                            value={item?.name}
                                            onChange={handleItemChange}
                                        />
                                    </div>
                                </div>

                                <div className="mb-3 d-flex flex-column">
                                    <label className="fw-500 text-dark" htmlFor="normal_bal">Normal Balance</label>
                                    <div className="position-relative bg-white">

                                        <span className=" all_selectdropdown_icon position-absolute px-2">
                                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z" fill="#6B7280" />
                                            </svg>
                                        </span>
                                        <Select
                                            labelId="balance"
                                            id="balance"
                                            fullWidth
                                            size="small"
                                            required
                                            name="balance"
                                            defaultValue="credit"
                                            value={item?.balance}
                                            onChange={handleItemChange}
                                        >
                                            <MenuItem value="select">Select</MenuItem>
                                            <MenuItem value="credit">Credit</MenuItem>
                                            <MenuItem value="debit">Debit</MenuItem>
                                        </Select>
                                    </div>
                                </div>

                                <div className="mb-3 flex-column">
                                    <label className="fw-500 text-dark" htmlFor="description">Description</label>
                                    <div className="position-relative" style={{ gap: '0px' }}>
                                        <TextField
                                            name="description"
                                            required
                                            type="text"
                                            fullWidth
                                            id="description"
                                            placeholder=""
                                            size="small"
                                            value={item?.description}
                                            onChange={handleItemChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-1">
                            <div className="mr-auto" style={{ width: "fit-content" }}>
                                <button
                                    type="button"
                                    className={`btn btn-primary pl-3 pr-3 pt-2 pb-2`}
                                    onClick={handleCreateLedger}
                                >
                                    Create
                                </button>
                                &nbsp; &nbsp;
                                <button
                                    type="button"
                                    className={`btn border bg-white pl-3 pr-3 pt-2 pb-2`}
                                    onClick={() => {
                                        setToggleShowLedger(false)
                                        setItem({})
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </Drawer>

            <Drawer
                anchor="right"
                open={toggleShowPosting}
                onClose={() => setToggleShowPosting(false)}
                PaperProps={{
                    sx: {
                        width: 300,
                        maxWidth: "100%",
                        border: "none",
                        marginTop: "0em",
                        height: "100vh",
                        padding: "20px",
                        paddingTop: '40px'
                    },
                }}
            >
                <span
                    className="px- py-2"
                    style={{
                        fontSize: "25px",
                        cursor: "pointer",
                        position: "absolute",
                        top: "40px",
                        right: "20px",
                    }}
                    onClick={() => setToggleShowPosting(false)}
                >
                    &times;
                </span>
                <div
                    className={`modal_content px-sm- py-3`}
                    style={{ overflowY: 'scroll', maxHeight: '90vh', }}
                >

                </div>
            </Drawer>
        </>
    );
}
