import React from "react";
import Chart from "react-apexcharts";

const TopCustomersChart = () => {
  const chartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "50%", // Adjust bar height
        borderRadius: 1, // Rounded corners for bars
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toLocaleString(); // Format data labels with commas
      },
      style: {
        colors: ["#fff"],
        fontSize: "10px",
      },
      offsetX: 0, // Adjust position of data labels
    },
    xaxis: {
      categories: [
        "John Brandson",
        "Ben Njoku",
        "Cultz Gordon",
        "Mccollum Demain",
        "Lillard Ace",
      ],
      labels: {
        formatter: function (val) {
          if (val < 1) {
            return val.toLocaleString(); // Format x-axis labels with commas and 'K'
          }
          return val.toLocaleString() + "K"; // Format x-axis labels with commas and 'K'
        },
        style: {
          colors: ["#333"],
          fontSize: "11px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: ["#333"],
          fontSize: "11px",
        },
      },
    },
    grid: {
      borderColor: "#fff",
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      colors: ["#1E1E1E"],
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toLocaleString(); // Format tooltip values as commas
        },
      },
    },
  };

  const chartSeries = [
    {
      name: "Transfers",
      data: [115666, 103002, 87998, 76931, 56034],
    },
  ];

  return (
    <div className="card h-100">
      <div className="card-body px-0 pt-1 pb-0">
        <div className="px-3 border-bottom">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="card-title fs-14 mb-0 py-0 fw-500 text-dark">
              Top Customers
            </h6>
            <div className="dropdown">
              <button
                className="btn dropdown-toggle text-dark "
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Transfers
              </button>
              <div
                className="dropdown-menu dropdown-menu-right fs-12"
                aria-labelledby="dropdownMenuButton"
              >
                <button className="dropdown-item">Transfers</button>
                <button className="dropdown-item">Other</button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="busiest-day-chart px-3 pt-4"></div> */}

        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={200}
        />
      </div>
    </div>
  );
};

export default TopCustomersChart;
