import { Helmet } from "react-helmet-async";
import CircularProgress from '@mui/material/CircularProgress';

import {
    Link,
    Checkbox,

} from "@mui/material";
import {
    useEffect,
    // useEffect, 
    useState,
    useRef
} from "react";
// import bus from "src/bus";
import styles from "../Payment.module.css";
import axiosInstance from "src/axiosInstance"
import { useParams } from "react-router-dom";
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import { format } from "date-fns-tz";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

// ----------------------------------------------------------------------

export default function EditInvoice() {
    const [data, setData] = useState(null);
    const { invoice_no } = useParams();
    const [isPending, setIsPending] = useState(false);
    const [isInitiating, setIsInitiating] = useState(false);
    const apiUrl = process.env.REACT_APP_NAME;
    const detailsRef = useRef();

    const fetchData = async () => {
        setIsPending(true)
        try {
            const response = await axiosInstance.get(`/payments/invoice/preview/${invoice_no}?productName=${apiUrl}`)
            setData(response.data.data.data)
        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                }
                else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }

            } else {
                notifyError('Network error or server not responding');
            }
        }
        finally {
            setIsPending(false)

        }

    }

    const initiateTransaction = async () => {
        setIsInitiating(true)

        try {
            const response = await axiosInstance.get(`/payments/invoice/checkout/${invoice_no}?productName=${apiUrl}`)

            const data = response.data;
            //   setInitiatePLResponseObj(data)
            //   localStorage.setItem('initiated_data', JSON.stringify(data))

            //   if (initiatePLResponseObj) {
            //   }
            //   setTimeout(() => {
            // navigate(`${data.data.url}`)
            window.location.href = `${data.data.url}`
            //   }, 1000);
        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsInitiating(false)
        }

    }

    useEffect(() => {
        fetchData()
    }, [])

    const exportReceiptToPDF = () => {
        const input = detailsRef.current;

        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF({
                orientation: "portrait",
                unit: "mm",
                format: "a5",
            });

            const imgWidth = 148; // Width in mm for A5
            const pageHeight = 210; // Height in mm for A5 (portrait)
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            let position = 0;
            let remainingHeight = imgHeight;

            while (remainingHeight > 0) {
                pdf.addImage(imgData, "PNG", 0, position, imgWidth, Math.min(imgHeight, pageHeight));
                remainingHeight -= pageHeight;
                if (remainingHeight > 0) {
                    pdf.addPage();
                    position = -pageHeight;
                }
                position += pageHeight;
            }

            pdf.save("Payment Invoice.pdf");
        })
            .catch((err) => {
                if (err.response) {
                    if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                        notifyError(err.response.data.data.errorMessage);
                    } else if (err.response.data && err.response.data.message) {
                        notifyError(err.response.data.message);
                    } else {
                        notifyError('An unexpected error occurred');
                    }
                }
                console.error("Error generating PDF: ", err);
            });
    };

    return (
        <>
            <Helmet>
                <title> Invoice preview </title>
            </Helmet>
            <div className="wrapper" style={{ width: '100%', background: 'rgba(249, 250, 251, 1)' }}>

                <div className="wrapper pt-4 pt-md-5 mx-auto" style={{ width: '1200px', maxWidth: '93%' }}>
                    <div className={`${styles.header_wrap} d-sm-flex justify-content-between align-items-center`}>
                        <div className="d-flex">
                            <h6 className="pl-2 pl-sm-3 py-2 py-sm-0 mb-0 fw-700 pb-0" style={{ lineHeight: '1.5' }} >Invoice preview</h6>
                        </div>

                        <div className=" d-none d-sm-block">
                            <div className="btn-group">
                                <button
                                    disabled={!data}
                                    className="btn border bg-white"
                                    onClick={exportReceiptToPDF}
                                >
                                    <span className="button_text">Download PDF</span>
                                </button>
                                <button
                                    className="btn border btn-primary ml-2 ml-sm-3"
                                    disabled={!data}
                                >
                                    <span className="button_text text-white" onClick={() => initiateTransaction()}>
                                        &nbsp;
                                        &nbsp;
                                        &nbsp;
                                        Make Payment
                                        {isInitiating && (
                                            <CircularProgress
                                                style={{ color: "#fff" }}
                                                className="text-white ml-1"
                                                size="0.8rem"
                                            />
                                        )}
                                        &nbsp;
                                        &nbsp;
                                        &nbsp;
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="w-100 mt-2 d-block d-sm-none">
                        <div className="btn-group fit-content ml-auto d-block">
                            <button
                                className="btn border bg-white"
                                onClick={exportReceiptToPDF}
                                disabled={!data}
                            >
                                <span className="button_text">Download PDF</span>
                            </button>
                            <button
                                className="btn border btn-primary ml-2 ml-sm-3"
                                disabled={!data}
                            >
                                {/* <span className="button_text text-white">Make Payment</span> */}
                                <span className="button_text text-white" onClick={() => initiateTransaction()}>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    Make Payment
                                    {isInitiating && (
                                        <CircularProgress
                                            style={{ color: "#fff" }}
                                            className="text-white ml-1"
                                            size="0.8rem"
                                        />
                                    )}
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                </span>
                            </button>
                        </div>
                    </div>
                    {
                        (!isPending && data) &&
                        <div className="pb-0 pt-4 pb-md-0">
                            <div className="w-100 px-md-4 pt-3">
                                <div className={`${styles.PreviewInvoice_wrapper_prev} mx-auto mt-3 pb-5 py-4 px-3 px-md-4`}
                                    ref={detailsRef}
                                    style={{
                                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)',
                                        width: '50rem', maxWidth: '100%'
                                    }}>
                                    <div className="mb-4 pt-3 d-flex justify-content-between align-items-center">
                                        <div className="">
                                            <table>
                                                <tr>
                                                    <td className="pl-0 mr-0">
                                                        <img src={data.company_logo} alt="" style={{
                                                            width: "auto", maxWidth: "90px", display: "block", margin: "0 auto"
                                                        }} />

                                                    </td>
                                                    <td>
                                                        <span className="ml-2 fs-18 fw-600">{data.company_name}</span>

                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <span
                                            className="px-2 fs-11 cursor-pointer"
                                        >
                                            #{data.invoice_no}
                                        </span>
                                    </div>

                                    <div>
                                        <small className="d-block pb-2 fw-400 text-dark">Bill to <span className="fs-12 text-dark"> {data.customer_name}</span></small>
                                        <small className="d-block pb-2"><span className="fs-12 fw-400 text-dark">Date Created:</span> <span className="fs-12"> {format(new Date(data.createdAt), "do MMMM yyyy - h:mmaaa")}</span></small>
                                        <small className="d-block pb-2"><span className="fs-12 fw-400 text-dark">Due Created:</span> <span className="fs-12">{format(new Date(data.due_date), "do MMMM yyyy - h:mmaaa")}</span></small>
                                    </div>

                                    <div className="mt-4">
                                        <div className="pb-2 border-bottom">
                                            <label htmlFor="">Item Details</label>
                                        </div>
                                        <div className={`pt-4 pb-3 mb-4 w-100 remove_calc_height`} style={{ overflowX: 'auto' }}>
                                            <div style={{ minWidth: '50em' }}>
                                                <div className="row w-100">
                                                    <div className="col-3 pr-0 mb-0 pb-0">
                                                        <div className="">
                                                            <label htmlFor="">Items</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 pr-0 mb-0 pb-0">
                                                        <div className="">
                                                            <label htmlFor="" className="d-block text-right">Unit Price</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 pr-0 mb-0 pb-0">
                                                        <div className="">
                                                            <label htmlFor="" className="d-block text-right">Quantity</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 pr-0 mb-0 pb-0">
                                                        <div className="">
                                                            <label htmlFor="" className="d-block text-right">Amount</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    data.products.map((el) => {
                                                        return (
                                                            <div className="row align-items-center w-100">
                                                                <div className="col-3 pr-0 mb-0 pb-0">
                                                                    <div className="">
                                                                        <div className="w-100">
                                                                            <div className="d-flex align-items-center">
                                                                                <img src={el.image_url} alt="" width={45} />
                                                                                <div className="ml-2 ">
                                                                                    <small className="d-block">{el.product_name}</small>
                                                                                </div>
                                                                            </div>
                                                                            {/* <small className="d-block mb-2">iPad</small>
                                                                                <small className="d-block mb-2">WristWatch</small>
                                                                                <small className="d-block mb-2">Macbook</small> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3 pr-0 mb-0 pb-0">
                                                                    <div className="">
                                                                        <div className="w-100">
                                                                            <div className="text-right">
                                                                                <small className="d-block">{data.currency}{el.price.toLocaleString()}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3 pr-0 mb-0 pb-0">
                                                                    <div className="">
                                                                        <div className="w-100">
                                                                            <div className="text-right">
                                                                                <small className="d-block">{el.quantity.toLocaleString()}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3 pr-0 mb-0 pb-0">
                                                                    <div className="">
                                                                        <div className="w-100">
                                                                            <div className="text-right">
                                                                                <small className="d-block">{data.currency}{el.amount.toLocaleString()}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>



                                    <div className="">
                                        <div className="pb-2 border-bottom">
                                            <label htmlFor="">Add Ons & Totals</label>
                                        </div>
                                        <div className={`py-4`}>
                                            <div className="d-flex justify-content-between">
                                                <div className="mb-0 pb-0 pr-1">
                                                    <div className="">
                                                        <div className="">
                                                            <div className="">
                                                                <small className="d-block mb-2">Subtotal</small>
                                                                <small className="d-block mb-2">Shipping</small>
                                                                <small className="d-block mb-2">Discount</small>
                                                                <small className="d-block mb-2">Tax</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-0 pb-0 pr-1">
                                                    <div className="">
                                                        <div className="">
                                                            <div className="text-right">
                                                                <small className="d-block mb-2">{data.currency}{data.sub_total.toLocaleString()}</small>
                                                                <small className="d-block mb-2">{data.currency}{data.shipping.toLocaleString()}</small>
                                                                <small className="d-block mb-2">{data.discount}%</small>
                                                                <small className="d-block mb-2">{data.tax}%</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="pb-2 border-bottom border-top">
                                            <div className="d-flex justify-content-between py-3">
                                                <span className="fw-500">Total</span>
                                                <p className="mb-0">
                                                    <span className="fw-500">{data.currency}{data.total.toLocaleString()}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="pt-4 mt-2 mb-4">
                                        <p className="d-block fw-500 fs-12">Payment Details</p>
                                        <small className="d-block fw-500 pb-2">Bank Name: <span className="font_10">Wema Bank</span></small>
                                        <small className="d-block fw-500 pb-2">Account Number: <span className="font_10">0066924804</span></small>
                                        <small className="d-block fw-500 pb-2">Account Name: <span className="font_10">Michel Jason</span></small>
                                        <small className="d-block fw-500 pb-2">
                                            <Link className="text-primary font-weight-bold text-underlined">
                                                Click to proceed to payment
                                            </Link>
                                        </small>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    }

                    {
                        !isPending && !data &&
                        <div className="pb-0 pt-4 pb-md-0">
                            <div className="w-100 px-md-4 pt-3">
                                <div className={`${styles.PreviewInvoice_wrapper_prev} mx-auto mt-3 pb-5 py-4 px-3 px-md-4`}
                                    style={{
                                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)',
                                        width: '50rem', maxWidth: '100%'
                                    }}>
                                    <h3>Invalid invoice</h3>
                                </div>
                            </div>
                        </div>
                    }

                    {isPending &&
                        <>
                            <div className="d-flex justify-content-center mt-3">
                                <CircularProgress
                                    style={{ color: "#fff" }}
                                    className="text-white ml-1"
                                    size="1.3rem"
                                />
                            </div>
                            <br />
                        </>
                    }
                </div>
            </div>
        </>
    );
}
