
import {
  Link,
  Stack,
  TextField,
  Typography,
  Button
} from '@mui/material';
import axios from "axios";
import { BaseUrlContext } from "src/context/BaseUrlContext";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'src/axiosInstance';
// Module styles
import Styles from './CSSModules/FormPages.module.css'
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import Logo from '../../components/logo';

// sections
import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from "@mui/material/CircularProgress";
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import Footer from './Footer'

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('0')]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    // backgroundImage: `url(${imageUrl})`,
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
    padding: theme.spacing(4, 0),
    // overflowX: 'hidden'
    // alignItems: 'center',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  overflowX: 'hidden',
  position: 'relative',
  width: '80em',
  maxWidth: '100%',
  margin: '0',
  minHeight: '90vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'space-between',
  flexDirection: 'column',
  padding: theme.spacing(3, 0),
}));

// ----------------------------------------------------------------------

export default function ForgotPassword() {
  const imageUrl = '/assets/images/op_background.png';
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_NAME;
  const baseUrl = useContext(BaseUrlContext);
  const [email, setEmail] = useState("");
  const [isPPending, setIsPPending] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleForgotPassword = async () => {
    setIsPPending(true)
    let payload = {
      emailAddress: email
    }
    try {

      const res = await axiosInstance.post(`/accounts/recovery?productName=${apiUrl}`, payload);

      localStorage.setItem("e", payload.emailAddress);
      notifySuccess(res.data.message);
      navigate('/otp');

    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
          notifyError(err.response.data.data.errorMessage);
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
        } else {
          notifyError('An unexpected error occurred');
        }
      } else {
        notifyError('Network error or server not responding');
      }
    } finally {
      setIsPPending(false)
    }
  }

  return (
    <>
      <Helmet>
        <title> Forgot Password  </title>
      </Helmet>

      <StyledRoot imageUrl={imageUrl} className='px-3'>
        <StyledContent className={`${Styles.StyledContent}`}>
          <div className={`${Styles.logo_wrap}`}>
            <Logo />
          </div>

          <div className={`${Styles.signin_form_wrap} py-5 my-3 py-md-5 my-md-5 mx-sm-auto ml-md-5 pl-md-5`}>
            <Typography variant='h4'>
              Forgot Password
            </Typography>
            <div className={`${Styles.input_box}`}>
              <label>Email</label>
              <Box
                sx={{
                  maxWidth: '100%',
                }}
              >
                <TextField
                  required
                  type="text"
                  id="email"
                  placeholder=""
                  value={email}
                  onChange={handleEmailChange}
                  fullWidth
                />
              </Box>
            </div>
            <div>
              <Stack spacing={2} direction="row">
                {isPPending ? (
                  <>
                    <Button
                      disabled
                      className={`${Styles.signin_btn} mb-0 position-relative`}
                      style={{ verticalAlign: "middle" }}
                    >
                      <span className="fw-500 text-white">
                        Please wait...
                      </span>
                      {isPPending && (
                        <CircularProgress
                          style={{ color: "white" }}
                          className="text-white ml-1"
                          size="1rem"
                        />
                      )}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      disabled={email === ""}
                      type="button"
                      className={`${Styles.signin_btn}  ${email ? Styles.signin_btn_bg : ""
                        } mb-0 position-relative`}
                      style={{ verticalAlign: "middle" }}
                      onClick={handleForgotPassword}
                    >
                      <span className="fw-500 text-white">Proceed</span>
                      {isPPending && (
                        <CircularProgress
                          style={{ color: "white" }}
                          className="text-white ml-1"
                          size="1rem"
                        />
                      )}
                    </Button>
                  </>
                )}
              </Stack>
            </div>
          </div>
          <Footer />
        </StyledContent>
      </StyledRoot>
    </>
  );
}
