export const AppDashboardData = [
  {
    text: 'Receive Payment',
    subText:
      'Accept payments from anywhere in the world, anytime. Get paid faster, easier, and more securely.',
  },

  {
    text: 'Online Invoice',
    subText:
      'Create professional invoices, track payments. Streamline your invoicing process and improve cash flow.',
  },

  {
    text: 'Data insight',
    subText:
      'Unlock clear and comprehensive data insights into your business transactions, enabling you to monitor performance and make informed decisions with ease.',
  },

  {
    text: 'Transfer funds',
    subText:
      'Send money locally and internationally. Enjoy fast, secure and cost-effective transfers to and from Nigeria and China.',
  },

  {
    text: 'Ledger Management',
    subText:
      'Maintain detailed financial records with ease. Reconcile transactions accurately and efficiently.',
  },

  {
    text: 'Multi-currency wallet',
    subText:
      'Effortlessly access a variety of currency wallets on one platform, enabling you to send, receive, and store funds in multiple currencies without the need for multiple accounts',
  },
];
