/* eslint-disable react/button-has-type */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Helmet } from "react-helmet-async";
// import * as Router from 'react-router-dom';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@mui/material/TextField";
import { Link as RouterLink } from "react-router-dom";

import { Link } from "@mui/material";
import { useState } from "react";
import GlobalModal from "../../../Modals/GlobalModal";
import styles from "../Wallet.module.css";
// ----------------------------------------------------------------------

export default function OnePointTrf() {
    const [existingBen, setExistingBen] = useState(false);
    const [recipient, setRecipient] = useState('select');

    const handleRecipientChange = (event) => {
        setRecipient(event.target.value);
    };

    const handleCloseExistingBen = () => {
        setExistingBen(false);
    };


    return (
        <>
            <Helmet>
                <title> OnePoint Transfer </title>
            </Helmet>
            <div className="wrapper">
                <div className={`${styles.header_wrap} d-sm-flex justify-content-between align-items-center`}>
                    <div className="d-flex">
                        <Link
                            className="d-block text-secondary"
                            to="/app/wallet"
                            component={RouterLink}
                        >
                            <i
                                className="bi bi-x"
                                style={{ fontSize: "1.5em", lineHeight: "1" }}
                            ></i>
                        </Link>
                        <h6 className="pl-2 pl-sm-3 mb-0" style={{ fontWeight: "bold" }}>Transfer to OnePoint Account</h6>
                    </div>
                </div>

                <div className={`${styles.trftobank_side_wrap} mt-5`}>
                    <div>
                        <div className="mb-3">
                            <label htmlFor="name">Select Wallet</label>
                            <Select
                                labelId="s"
                                id="s"
                                fullWidth
                                size="small"
                                required
                                name="verified"
                                defaultValue="operation"
                            >
                                <MenuItem value="operation">Operation Wallet - $1,100</MenuItem>
                                <MenuItem value="banking">Banking Wallet - $1,100</MenuItem>
                                <MenuItem value="finance">Finance Wallet - $1,100</MenuItem>
                            </Select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="name">Recipient</label>
                            <Select
                                labelId="s"
                                id="s"
                                fullWidth
                                size="small"
                                required
                                name="verified"
                                defaultValue="select"
                                value={recipient}
                                onChange={handleRecipientChange}
                            >
                                <MenuItem value="select">- Select -</MenuItem>
                                <MenuItem value="myself">To Myself</MenuItem>
                                <MenuItem value="another-merch">To Another Merchant</MenuItem>
                            </Select>
                        </div>

                        {recipient === 'myself' && (
                            <div className="mb-3">
                                <label htmlFor="name">To</label>
                                <Select
                                    labelId="s"
                                    id="s"
                                    fullWidth
                                    size="small"
                                    required
                                    name="verified"
                                    defaultValue="operation"
                                >
                                    <MenuItem value="operation">Operation Wallet - $1,100</MenuItem>
                                    <MenuItem value="banking">Banking Wallet - $1,100</MenuItem>
                                    <MenuItem value="finance">Finance Wallet - $1,100</MenuItem>
                                </Select>
                            </div>
                        )}

                        {recipient === 'another-merch' && (
                            <div className="mb-3">
                                <label htmlFor="ounit">Merchant ID</label>
                                <div className="" style={{ width: "100%" }}>
                                    <div className="">
                                        <TextField
                                            name="min_products"
                                            required
                                            type="number"
                                            id="ounit"
                                            size="small"
                                            placeholder=""
                                            fullWidth
                                        />
                                    </div>
                                </div>
                                <small className="d-block text-center mt-2">
                                    <Link
                                        className="text-primary font-weight-bold"
                                        component={RouterLink}
                                        onClick={() => setExistingBen(true)}
                                    >
                                        Select from existing beneficiaries
                                    </Link>
                                </small>
                            </div>
                        )}

                        <div className="mb-3">
                            <label htmlFor="">Input Amount</label>
                            <div className={`${styles.charges_input}`}>
                                <div className={`${styles.select_wrap}`}>
                                    <Select
                                        labelId="s"
                                        id="s"
                                        size="small"
                                        required
                                        name="verified"
                                        defaultValue="ngn"
                                        style={{ width: "fit-content" }}
                                    >
                                <MenuItem value="ngn">NGN</MenuItem>
                                <MenuItem value="cny">CNY</MenuItem>
                                    </Select>
                                </div>
                                <input
                                    type="text"
                                    placeholder="1200"
                                    style={{ width: "100%", textIndent: "75px" }}
                                    className=" form-control"
                                />
                            </div>
                            <small className="text-secondary font-weight-normal">
                                charge for transaction NGN10
                            </small>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="ounit">Bank Name</label>
                            <div className="" style={{ width: "100%" }}>
                                <div className="">
                                    <TextField
                                        name="min_products"
                                        required
                                        type="text"
                                        id="ounit"
                                        size="small"
                                        placeholder=""
                                        fullWidth
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="ounit">Description</label>
                            <div className="" style={{ width: "100%" }}>
                                <div className="">
                                    <TextField
                                        name="min_products"
                                        required
                                        type="text"
                                        id="ounit"
                                        size="small"
                                        placeholder=""
                                        fullWidth
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="my-4 my-md-5">
                            <Link to="/app/wallet/payment-otp" component={RouterLink} className="btn border btn-primary">
                                <span className="">Proceed</span>
                            </Link>
                            &nbsp; &nbsp;
                            <Link
                                className="btn border"
                                to="/app/wallet"
                                component={RouterLink}
                            >
                                <span className="">Cancel</span>
                            </Link>
                        </div>
                    </div>
                </div>



                {/* Select Existing Beneficiary Modal */}
                <GlobalModal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={existingBen}
                    onClose={handleCloseExistingBen}
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    width="500px !important"
                    maxWidth="100%"
                    overflowY="scroll"
                    bgcolor="#FFFF"
                    border="1px solid #F5F5F5"
                    borderRadius="5px"
                    boxShadow="24"
                    p="25px"
                >
                    <div
                        className={`modal_content`}
                    // style={{overflowY: 'scroll', height: '450px'}}
                    >
                        <div className="mb-4 d-flex justify-content-between align-items-start">
                            <h5>Beneficiaries</h5>
                            <span
                                className="px-3 py-2"
                                style={{ fontSize: "25px", cursor: "pointer", position: 'absolute', top: '0', right: '0' }}
                                onClick={() => setExistingBen(false)}
                            >
                                &times;
                            </span>
                        </div>
                        <div className={`modal_header mb-0`}>
                            <div className={`${styles.hold_input1} w-100`}>
                                <img src="/assets/icons/search.svg" alt="" />
                                <input
                                    type="text"
                                    name="search"
                                    placeholder="Search"
                                    style={{ paddingLeft: "33px", width: "100%" }}
                                    className=" form-control"
                                />
                            </div>
                        </div>

                        <div
                            className="mt-4 modal_content"
                            style={{ maxHeight: "70vh", overflowY: "scroll" }}
                        >
                            <div
                                className="border-bottom py-3"
                                onClick={() => setExistingBen(false)}
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <div>
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Calvin Hawkins</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Ted Fox</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </GlobalModal>
            </div>
        </>
    );
}
