import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem } from './styles';
import { useNavigate, useLocation } from 'react-router-dom';
// import { usePathname } from "src/context/PathContext";


// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const navigate = useNavigate();
  const pathname = '';
  const [navItems, setNavItems] = useState([]);
  const currentRoute = window.location.pathname;
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const pathHistory = currentRoute;
    if (pathHistory) {
      setHistory(pathHistory);
    }
  }, []);

  useEffect(() => {
    // Add current pathname to history when it changes
    setHistory(prevHistory => [...prevHistory, pathname]);
    // Save history to local storage
    // localStorage.setItem('pathHistory', JSON.stringify(history));
  }, [pathname]);


  useEffect(() => {

    if (!currentRoute.includes('/payment-links/preview')) {
      localStorage.removeItem('preview_data');
    }
    
    setNavItems(data.map((item) => (
      // console.log('item: ', item),
      <NavItem key={item.title + pathname} item={item} />
    )));
  }, [data, pathname]);

  const handleBack = () => {
    // localStorage.setItem('path', '/app/overview');
    navigate('/app/overview');
  };

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1, pl: 3, mt: 1 }}>
        {/* {(
          // WALLET
          currentRoute === '/app/wallet/overview' ||
          currentRoute === '/app/payment/transfers/single' ||
          currentRoute === '/app/payment/transfers/bulk' ||
          currentRoute === '/app/wallet/payment-otp' ||
          currentRoute === '/app/wallet/onepoint-transfer' ||
          currentRoute === '/app/wallet/create' ||
          currentRoute === '/app/wallet/withdraw' ||
          currentRoute === '/app/wallet/top-ups' ||
          currentRoute === '/app/payment/transfers' ||
          currentRoute === '/app/wallet/fees' ||
          // PAYMENT
          currentRoute === '/app/payment/payment-links' ||
          currentRoute === '/app/payment/invoice' ||
          currentRoute === '/app/payment/transfers' ||
          currentRoute === '/app/payment/payroll' ||
          currentRoute === '/app/payment/chargebacks' ||
          currentRoute === '/app/payment/refunds' ||
          currentRoute === '/app/payment/single-cash' ||
          currentRoute === '/app/payment/bulk-cash' ||
          currentRoute === '/app/payment/invoice/edit' ||
          currentRoute === '/app/payment/invoice/preview' ||
          currentRoute === '/app/payment/invoice/create' ||
          currentRoute === '/app/payment/payment-links/create-single' ||
          currentRoute === '/app/payment/payment-links/create-bulk' ||
          currentRoute === '/app/payment/refunds/log-refund' ||

          // BILLS
          currentRoute === '/app/bills/airtime' ||
          currentRoute === '/app/bills/data' ||
          // CUSTOMERS
          currentRoute === '/app/customer/all' ||
          currentRoute === '/app/customer/blacklisted' ||
          currentRoute === '/app/customer/customer-details' ||
          // MESSAGING
          currentRoute === '/app/messaging/sender-id' ||
          currentRoute === '/app/messaging/send-message' ||
          currentRoute === '/app/messaging/single-messaging' ||
          currentRoute === '/app/messaging/bulk-messaging' ||
          // OTP
          currentRoute === '/app/otp/configure-otp' ||
          currentRoute === '/app/otp/otp-logs' ||
          // LEDGER MANAGEMENT
          currentRoute === '/app/ledger-management' ||
          currentRoute === '/app/ledger-management/journal-entries' ||
    currentRoute === '/app/ledger-management/sales-ledger' ||
    currentRoute === '/app/ledger-management/add-entries'
        ) && (
            <span className='text-dark back_btn d-block mb-4 d-flex align-items-center py-2 rounded' style={{ cursor: 'pointer', paddingLeft: '4px', paddingRight: '10px', width: 'fit-content' }} onClick={handleBack}>
              <svg width="21" height="21" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='chevron_left'>
                <path d="M9.57 5.92993L3.5 11.9999L9.57 18.0699" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20.4999 12H3.66992" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <b className='main-menu ml-2'>Main Menu</b>
            </span>
          )}

        {(currentRoute.includes('/wallet')) && (
          <h5 className='d-block mb-2 mt-5 menu-title' style={{ paddingLeft: '5px', }}>
            Wallet
          </h5>
        )}
        {(currentRoute.includes('/payment')) && (
          <h5 className='d-block mb-2 mt-5 menu-title' style={{ paddingLeft: '5px', }}>
            Payment
          </h5>
        )}
        {(currentRoute.includes('/customer')) && (
          <h5 className='d-block mb-2 mt-5 menu-title' style={{ paddingLeft: '5px', }}>
            Customers
          </h5>
        )}
        {(currentRoute.includes('/messaging')) && (
          <h5 className='d-block mb-2 mt-5 menu-title' style={{ paddingLeft: '5px', }}>
            Messaging
          </h5>
        )}
        {(currentRoute.includes('/otp')) && (
          <h5 className='d-block mb-2 mt-5 menu-title' style={{ paddingLeft: '5px', }}>
            OTP
          </h5>
        )}
        {(currentRoute.includes('/bills')) && (
          <h5 className='d-block mb-3 mt-5 menu-title' style={{ paddingLeft: '5px', }}>
            Bill Payment
          </h5>
        )}
        {(currentRoute.includes('/ledger-management')) && (
          <h5 className='d-block mb-3 mt-5 menu-title' style={{ paddingLeft: '5px', }}>
            Ledger Management
          </h5>
        )} */}
        {navItems}
      </List>

    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const {
    title,
    path,
    icon,
    info,
    iconActive,
    className
  } = item;
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('');
  const currentRoute = window.location.pathname;

  useEffect(() => {
    setCurrentPath(location.pathname);
    // console.log('both Paths :', path, 'and', currentPath);
    // console.log('Breakpoint yunno!');
  }, [location.pathname]);

  // const handleClick = (key) => {
  //   localStorage.setItem('path', key);
  //   setCurrentPath(key);
  //   // console.log(currentPath, path);
  // };

  return (
    <>
      {path === "" &&
        <p className='mb-0 text-uppercase noPath_text'>{title}</p>
      }
      {path !== "" &&
        <StyledNavItem
          component={path !== "" && RouterLink}
          to={path !== "" && path}
          sx={{
            '&.active': {
              // color: '#222222',
              // fontWeight: 'fontWeightBold',
              // marginTop: '9px',
              bgcolor: 'action.selected',
            },
          }}
          style={{
            paddingLeft: '9px',
            paddingTop: '18px',
            paddingBottom: '18px',
            marginLeft: '0px',
            marginBottom: '3px'
          }}
          // onClick={() => handleClick(path)}
          className={
            `${(currentPath === path && currentRoute.includes('/app/overview')) ||

              ((currentPath === path && currentRoute.includes('/app/wallet')) ||
                ((currentPath === '/app/wallet/convert-funds') && path.includes('/app/wallet')) ||
                ((currentPath === '/app/wallet/transactions') && path.includes('/app/wallet'))) ||

              ((currentPath === path && currentRoute.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/ledger-details') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/ledger-details/accounts') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/new-transaction') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/new-account') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/edit-account') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/ledger-entries') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/ledger-entries/edit') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/edit-transaction') && path.includes('/app/ledger-management'))) ||

              ((currentPath === path && currentRoute.includes('/app/payment/transfers')) ||
                ((currentPath === '/app/payment/transfers/single') && path.includes('/app/payment/transfers')) ||
                ((currentPath === '/app/payment/transfers/bulk') && path.includes('/app/payment/transfers'))) ||
              
              ((currentPath === path && currentRoute.includes('/app/payment/payment-links')) ||
                ((currentPath === '/app/payment/payment-links/create') && path.includes('/app/payment/payment-links'))) ||
              
              ((currentPath === path && currentRoute.includes('/app/customer/all')) ||
              ((currentPath === '/app/customer/create-new-group') && path.includes('/app/customer')) ||
              ((currentPath === '/app/customer/customer-statistics') && path.includes('/app/customer'))) ||
              
              ((currentPath === path && currentRoute.includes('/app/payment/invoice')) ||
                ((currentPath === '/app/payment/invoice/create') && path.includes('/app/payment/invoice'))) ||
              
              ((currentPath === path && currentRoute.includes('/app/payment/chargebacks')) ||
                ((currentPath === '/app/payment/chargebacks/details') && path.includes('/app/payment/chargebacks')))
              ? 'active' :
              ''} ${className}`}
        >
          <p className='mb-0'>
            {path !== "" ? (
            (currentPath === path && currentRoute.includes('/app/overview')) ||

              ((currentPath === path && currentRoute.includes('/app/wallet')) ||
                ((currentPath === '/app/wallet/convert-funds') && path.includes('/app/wallet')) ||
                ((currentPath === '/app/wallet/transactions') && path.includes('/app/wallet'))) ||

              ((currentPath === path && currentRoute.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/ledger-details') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/ledger-details/accounts') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/new-transaction') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/new-account') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/edit-account') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/ledger-entries') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/ledger-entries/edit') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/edit-transaction') && path.includes('/app/ledger-management'))) ||

              ((currentPath === path && currentRoute.includes('/app/payment/transfers')) ||
                ((currentPath === '/app/payment/transfers/single') && path.includes('/app/payment/transfers')) ||
                ((currentPath === '/app/payment/transfers/bulk') && path.includes('/app/payment/transfers'))) ||
              
              ((currentPath === path && currentRoute.includes('/app/payment/payment-links')) ||
                ((currentPath === '/app/payment/payment-links/create') && path.includes('/app/payment/payment-links'))) ||
              
                ((currentPath === path && currentRoute.includes('/app/customer/all')) ||
              ((currentPath === '/app/customer/create-new-group') && path.includes('/app/customer')) ||
              ((currentPath === '/app/customer/customer-statistics') && path.includes('/app/customer'))) ||
              
              ((currentPath === path && currentRoute.includes('/app/payment/invoice')) ||
                ((currentPath === '/app/payment/invoice/create') && path.includes('/app/payment/invoice'))) ||
              
              ((currentPath === path && currentRoute.includes('/app/payment/chargebacks')) ||
                ((currentPath === '/app/payment/chargebacks/details') && path.includes('/app/payment/chargebacks')))
                ? <img src={iconActive} alt="icon_active" /> : <img src={icon} alt="icon" />) : <span></span>}
          </p>
          <ListItemText
            className={`${
            (currentPath === path && currentRoute.includes('/app/overview')) ||

              ((currentPath === path && currentRoute.includes('/app/wallet')) ||
                ((currentPath === '/app/wallet/convert-funds') && path.includes('/app/wallet')) ||
                ((currentPath === '/app/wallet/transactions') && path.includes('/app/wallet'))) ||

              ((currentPath === path && currentRoute.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/ledger-details') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/ledger-details/accounts') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/new-transaction') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/new-account') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/edit-account') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/ledger-entries') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/ledger-entries/edit') && path.includes('/app/ledger-management')) ||
                ((currentPath === '/app/ledger-management/edit-transaction') && path.includes('/app/ledger-management'))) ||

              ((currentPath === path && currentRoute.includes('/app/payment/transfers')) ||
                ((currentPath === '/app/payment/transfers/single') && path.includes('/app/payment/transfers')) ||
                ((currentPath === '/app/payment/transfers/bulk') && path.includes('/app/payment/transfers'))) ||
              
              ((currentPath === path && currentRoute.includes('/app/payment/payment-links')) ||
                ((currentPath === '/app/payment/payment-links/create') && path.includes('/app/payment/payment-links'))) ||
              
              ((currentPath === path && currentRoute.includes('/app/customer/all')) ||
              ((currentPath === '/app/customer/create-new-group') && path.includes('/app/customer')) ||
              ((currentPath === '/app/customer/customer-statistics') && path.includes('/app/customer'))) ||
              
              ((currentPath === path && currentRoute.includes('/app/payment/invoice')) ||
                ((currentPath === '/app/payment/invoice/create') && path.includes('/app/payment/invoice'))) ||
              
              ((currentPath === path && currentRoute.includes('/app/payment/chargebacks')) ||
                ((currentPath === '/app/payment/chargebacks/details') && path.includes('/app/payment/chargebacks')))
              ? 'text-primary' : ''}`}
            style={{
              paddingLeft: '12px',
              fontWeight: '600',
              fontSize: '13px',
              color: '#9CA3AF',
            }}
            disableTypography
            primary={title}
          />
          {info && info}
        </StyledNavItem>
      }
    </>
  );
}
