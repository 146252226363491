import React from 'react';
import './pageloader.css';

const Pageloader = ({ loading }) => {
  return (
    loading && (
      <div id="preloaders" className="preloaders">
        <div id="loaders"></div>
      </div>
    )
  );
};

export default Pageloader;