
export const useApiKeys = () => {
    return {
        PASTACK: {
            SECRET_KEY: 'sk_test_61ee3fa8a1a02926b136d855dc2c0a6fce62f582'
        },
        GOOGLE_MAPS: {
            KEY: 'AIzaSyCAe5s_Pl3kwqN3zYrTzibup-3sSZRGCsU'
        }
    }


}