import { Helmet } from "react-helmet-async";
// import * as Router from 'react-router-dom';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@mui/material/TextField";
import { Link as RouterLink } from "react-router-dom";

import { Link } from "@mui/material";
import { useState } from "react";
import GlobalModal from "../../../Modals/GlobalModal";
import styles from "../../Wallet/Wallet.module.css";
// ----------------------------------------------------------------------

export default function BulkCashPayment() {
    const [selectedImage, setSelectedImage] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(URL.createObjectURL(file));
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        setSelectedImage(URL.createObjectURL(file));
    };

    return (
        <>
            <Helmet>
                <title> Bulk Cash Payment </title>
            </Helmet>
            <div className="wrapper mt-2 mt-md-3">
                {/* <div className={`${styles.header} d-block d-md-none mb-4 mt-2`}>
                    <h4 className={`mb-0 pb-0`}>Dashboard</h4>
                    <p style={{ fontSize: '14px' }} className={`mb-0 pb-0`}>
                        An overview of your business performance
                    </p>

                </div> */}
                {/* <div className={styles.landing} /> */}
                <div className={`${styles.header_wrap} d-flex justify-content-between align-items-center`}>
                    <div className="d-flex">
                        <Link
                            className="d-block text-secondary"
                            to="/app/payment/transfers"
                            component={RouterLink}
                        >
                            <i
                                className="bi bi-x"
                                style={{ fontSize: "1.5em", lineHeight: "1" }}
                            ></i>
                        </Link>
                        <h6 className="pl-3" style={{ fontWeight: "bold" }}>Bulk Cash Payment</h6>
                    </div>
                </div>

                <div className={`${styles.trftobank_side_wrap} mt-4 mt-md-5`}>
                    <div>
                        <div className={`${styles.requirement_wrap} pb-4 border-bottom`}>
                            <h5 className="font-weight-normal">Requirements</h5>
                            <ul className={`${styles.requirement_ul}`}>
                                <li>The file must be CSV</li>
                                <li>
                                    CSV file should contain the following columns - Account
                                    number, Bank code, and Amount.
                                </li>
                                <li>
                                    View{" "}
                                    <Link to="" component={RouterLink} className="text-primary">
                                        list of bank codes
                                    </Link>
                                </li>
                                <li>
                                    The order of the columns should be same as the order in which
                                    they are listed above with the first row as headers.
                                </li>
                            </ul>
                            <span className="pl-4 ml-1">
                                Get a sample{" "}
                                <Link to="" component={RouterLink} className="text-primary">
                                    bulk payment CSV file
                                </Link>
                            </span>
                        </div>

                        <div
                            className="drop_zone my-4"
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                        >
                            <input
                                type="file"
                                multiple
                                onChange={handleFileChange}
                                onDrop={handleDrop}
                            />
                            {!selectedImage && (
                                <div>
                                    <div className="mx-auto" style={{ width: "fit-content" }}>
                                        <svg
                                            width="46"
                                            height="46"
                                            viewBox="0 0 46 46"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                x="3"
                                                y="3"
                                                width="40"
                                                height="40"
                                                rx="20"
                                                fill="#F2F4F7"
                                            />
                                            <rect
                                                x="3"
                                                y="3"
                                                width="40"
                                                height="40"
                                                rx="20"
                                                stroke="#F9FAFB"
                                                strokeWidth="6"
                                            />
                                            <g clipPath="url(#clip0_1729_11206)">
                                                <path
                                                    d="M26.3333 26.3332L23 22.9999M23 22.9999L19.6666 26.3332M23 22.9999V30.4999M29.9916 28.3249C30.8044 27.8818 31.4465 27.1806 31.8165 26.3321C32.1866 25.4835 32.2635 24.5359 32.0351 23.6388C31.8068 22.7417 31.2862 21.9462 30.5555 21.3778C29.8248 20.8094 28.9257 20.5005 28 20.4999H26.95C26.6977 19.5243 26.2276 18.6185 25.5749 17.8507C24.9222 17.0829 24.104 16.4731 23.1817 16.0671C22.2594 15.661 21.2571 15.4694 20.2501 15.5065C19.243 15.5436 18.2575 15.8085 17.3676 16.2813C16.4777 16.7541 15.7066 17.4225 15.1122 18.2362C14.5177 19.05 14.1155 19.9879 13.9358 20.9794C13.756 21.9709 13.8034 22.9903 14.0743 23.961C14.3452 24.9316 14.8327 25.8281 15.5 26.5832"
                                                    stroke="#475467"
                                                    strokeWidth="1.66667"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1729_11206">
                                                    <rect
                                                        width="20"
                                                        height="20"
                                                        fill="white"
                                                        transform="translate(13 13)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <p className="mb-1">
                                        <span className="text-primary">Click to upload</span> <span style={{ fontWeight: '500' }}>or
                                            drag and drop</span>
                                    </p>
                                    <p className="mb-1">CSV file only</p>
                                </div>
                            )}
                            {selectedImage && <img src={selectedImage} alt="Selected" />}
                            {/* <p className="mb-0">{selectedImage ? "1 file selected" : "No file selected"}</p> */}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="name">Select Wallet</label>
                            <Select
                                labelId="s"
                                id="s"
                                fullWidth
                                size="small"
                                required
                                name="verified"
                                defaultValue="operation"
                            >
                                <MenuItem value="operation">Operation Wallet - $1,100</MenuItem>
                                <MenuItem value="banking">Bank Wallet - $1,100</MenuItem>
                                <MenuItem value="finance">Finance - $1,100</MenuItem>
                            </Select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="drivers">Input Amount</label>
                            <div className={`${styles.charges_input}`}>
                                <div className={`${styles.select_wrap}`}>
                                    <Select
                                        labelId="s"
                                        id="s"
                                        size="small"
                                        required
                                        name="verified"
                                        defaultValue="ngn"
                                        style={{ width: "fit-content" }}
                                    >
                                        <MenuItem value="usd">USD</MenuItem>
                                        <MenuItem value="ngn">NGN</MenuItem>
                                        <MenuItem value="eur">EURO</MenuItem>
                                        <MenuItem value="ced">CEDES</MenuItem>
                                    </Select>
                                </div>
                                <input
                                    type="text"
                                    placeholder="1200"
                                    style={{ width: "100%", textIndent: "75px" }}
                                    className=" form-control"
                                />
                            </div>
                            <small className="text-secondary font-weight-normal">
                                charge for transaction NGN10
                            </small>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="ounit">Description</label>
                            <div className="" style={{ width: "100%" }}>
                                <div className="">
                                    <TextField
                                        name="min_products"
                                        required
                                        type="text"
                                        id="ounit"
                                        size="small"
                                        placeholder=""
                                        fullWidth
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="my-5">
                            <Link
                                to="/app/wallet/payment-otp"
                                component={RouterLink}
                                className="btn border btn-primary"
                            >
                                <span className="button_text text-white">Send</span>
                            </Link>
                            &nbsp; &nbsp;
                            <Link
                                className="btn border"
                                to="/app/payment/transfers"
                                component={RouterLink}
                            >
                                <span className="button_text">Cancel</span>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
