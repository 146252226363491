import React, { useEffect, useState, useRef } from "react";
import "../CustomerComponent.module.css";
import { Chart } from "chart.js/auto";
import { Link as RouterLink } from "react-router-dom";
import {
  Link,
  Stack,
  Drawer,
  Checkbox,
  Typography,
  IconButton,
} from "@mui/material";
import DatePicker from "react-datepicker";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InsightCustomersTable from "./InsightCustomers";
import toast from "react-hot-toast";
import SuccessToast from 'src/components/SuccessToast';
import ErrorToast from 'src/components/ErrorToast';
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Scrollbar from "../../../components/scrollbar";
import Iconify from "../../../components/iconify";
import styles from "../../Wallet/Wallet.module.css";
import TopCustomersChart from "../cstmr_charts/SmallChart01";
import RevenueGrowthChart from "../cstmr_charts/RevenueGrowth";
import RevenueGrowthHorizontalChart from "../cstmr_charts/RevenueGrowthHorizontalChart";
import MostTransactedBanksChart from "../cstmr_charts/MostTransactedBanks";
import PaymentMethod from "../cstmr_charts/PaymentMethod";
import PaymentMethodChart from "../cstmr_charts/PaymentMethodChart";
import PSRateChart from "../cstmr_charts/PSRateChart";
import InvoicesChart from "../cstmr_charts/InvoicesChart";
import PaymentSuccessRateChart from "../cstmr_charts/PaymentSuccessRate";
import BarWithLineChart from "../cstmr_charts/BarWithLineChart";
import TransactionHighChart from "../cstmr_charts/TransactionChart";
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import WeeklyVolumeChart from "../cstmr_charts/WeeklyVolumeChart";
import MostTransactedBankChart from "../cstmr_charts/MostTrsnBankChart";
import TopCountries from "../cstmr_charts/TopCountries";

export default function CustomerGroupStat() {
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [currType, setCurrType] = useState("ngn");
  const tableDataRef = useRef({});
  const [activeButton, setActiveButton] = useState("Churn Rate");
  const [checkedItems, setCheckedItems] = useState([]);
  const [saveStatus, setSaveStatus] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [paymentDate, setPaymentDate] = useState(new Date());

  const [assignCustomerDialog, setAssignCustomerDialog] = useState(false);


  const handleCloseAssignCustomerDialog = () => {
    setAssignCustomerDialog(false);
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    // console.log(`Active button: ${buttonName}`);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setDateFrom(start);
    setDateTo(end);

    if (start && end) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      const from = start.toLocaleDateString("en-US", options);
      const to = end.toLocaleDateString("en-US", options);
      // console.log(`Selected Date Range: ${from} - ${to}`);
    }
  };

  const handleCurrTypeChange = (event) => {
    setCurrType(event.target.value);
  };

  const handleDownloadFile = async (extension) => {
    try {
      let tableData = tableDataRef.current["customers"];

      if (!tableData || tableData.length === 0) {
        throw new Error("Table not found");
      }

      if (extension === "csv") {
        exportTableDataToCSV(tableData, "Table Data.csv");
      } else if (extension === "pdf") {
        exportTableDataToPDF(tableData, "Table Data.pdf");
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
          notifyError(err.response.data.data.errorMessage);
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
        } else {
          notifyError('An unexpected error occurred');
        }
      } else {
        notifyError('Network error or server not responding');
      }
      console.error(err);
      notifyError(`Unable to export ${extension} file`);
    }
  };

  const exportTableDataToCSV = (tableData, filename) => {
    const headers = ["Transaction ID", "Status", "Amount", "Date"];
    const csvContent = [
      headers.join(","),
      ...tableData.map((row) =>
        [row.trn_id, row.status, row.amount, row.date].join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, filename);
  };

  const exportTableDataToPDF = (tableData, filename) => {
    const doc = new jsPDF();
    const tableColumn = ["Transaction ID", "Status", "Amount", "Date"];
    const tableRows = [];

    tableData.forEach((item) => {
      const rowData = [item.trn_id, item.status, item.amount, item.date];
      tableRows.push(rowData);
    });

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.save(filename);
  };

  const hitmapCountries = [
    {
      country: "New York",
      amount: "72K",
      bar_rate: "80%",
    },
    {
      country: "San Francisco",
      amount: "39K",
      bar_rate: "50%",
    },
    {
      country: "Sydney",
      amount: "25K",
      bar_rate: "60%",
    },
    {
      country: "Singapore",
      amount: "61K",
      bar_rate: "70%",
    },
    {
      country: "Singapore",
      amount: "61K",
      bar_rate: "70%",
    },
    {
      country: "Singapore",
      amount: "61K",
      bar_rate: "70%",
    },
  ];

  useEffect(() => {
    // Update the checkedItems array whenever saveStatus changes
    const checked = customersData.filter((item) => saveStatus[item.id]);
    setCheckedItems(checked);
  }, [saveStatus]);

  const handleChangeSave = (id) => {
    setSaveStatus((prevSaveStatus) => ({
      ...prevSaveStatus,
      [id]: !prevSaveStatus[id],
    }));
    // console.log("saveStatus", saveStatus);
  };

  const customersData = [
    { id: "1", name: "Kristen Ramos", email: "Michseljoseph@gmail.com" },
    { id: "2", name: "Kristen Ramos", email: "Michseljoseph@gmail.com" },
    { id: "3", name: "Kristen Ramos", email: "Michseljoseph@gmail.com" },
    { id: "4", name: "Kristen Ramos", email: "Michseljoseph@gmail.com" },
    { id: "5", name: "Kristen Ramos", email: "Michseljoseph@gmail.com" },
    { id: "6", name: "Kristen Ramos", email: "Michseljoseph@gmail.com" },
    { id: "7", name: "Kristen Ramos", email: "Michseljoseph@gmail.com" },
    { id: "8", name: "Kristen Ramos", email: "Michseljoseph@gmail.com" },
    { id: "9", name: "Kristen Ramos", email: "Michseljoseph@gmail.com" },
  ];

  const filteredCustomers = customersData.length && customersData.filter(customer =>
    customer.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  //   useEffect(() => {
  //     const ctx = document.getElementById("transactionChart").getContext("2d");
  //     const myChart = new Chart(ctx, {
  //       type: "line",
  //       data: {
  //         labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  //         datasets: [
  //           {
  //             label: "Payout",
  //             data: [10, 20, 15, 25, 20, 30],
  //             backgroundColor: "rgba(0, 0, 0, 0.1)",
  //             borderColor: "black",
  //             borderWidth: 2,
  //             fill: false,
  //             pointRadius: 0,
  //             borderDash: [5, 5],
  //           },
  //           {
  //             label: "Pay In",
  //             data: [15, 25, 20, 30, 25, 35],
  //             backgroundColor: "rgba(0, 0, 0, 0.1)",
  //             borderColor: "#ccc",
  //             borderWidth: 2,
  //             fill: false,
  //             pointRadius: 0,
  //           },
  //         ],
  //       },
  //       options: {
  //         responsive: true,
  //         maintainAspectRatio: false,
  //         scales: {
  //           y: {
  //             beginAtZero: true,
  //             max: 40,
  //             stepSize: 10,
  //           },
  //         },
  //       },
  //     });
  //     return () => {
  //       myChart.destroy();
  //     };
  //   }, []);

  return (
    <>
      <div className="wrapper pt-4 pb-5 mx-auto" style={{ width: '1300px', maxWidth: '95%' }}>
        <div className="w-100 transactionChart pt-2">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <p style={{ color: "#4B5563", fontSize: "13px" }} className="mb-0">
              <Link
                to="/app/customer/all"
                component={RouterLink}
                className="btn border fit-content py-2 d-flex justify-content-center align-items-center"
              >
                <span>
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 15L8 10L13 5"
                      stroke="#111827"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Link>
            </p>

            <div className="d-flex justify-content-end d-block d-sm-none">
              <div
                className="fit-content d-flex"
                style={{ whiteSpace: "nowrap" }}
              >
                <div className="btn-group">
                  <button
                    className="btn border btn-primary"
                    type="button"
                    onClick={() => setAssignCustomerDialog(true)}
                  >
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.28125 6.5H9.12461"
                        stroke="white"
                        strokeWidth="1.16867"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.20312 9.42198V3.57861"
                        stroke="white"
                        strokeWidth="1.16867"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>{" "}
                    <span className="button_text text-white">
                      Assign Customer
                    </span>
                  </button>
                </div>
                &nbsp;&nbsp;
                <div className="btn-group">
                  <button
                    data-toggle="dropdown"
                    aria-expanded="false"
                    className="btn border btn-primary"
                    type="button"
                  >
                    <svg
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.51855 8.93359V10.3937C1.51855 10.9313 1.95436 11.3671 2.49195 11.3671H9.30576C9.84335 11.3671 10.2792 10.9313 10.2792 10.3937V8.93359"
                        stroke="#F3F4F6"
                        strokeWidth="1.86"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.9519 6.5L5.8987 8.4468L7.8455 6.5"
                        stroke="#F3F4F6"
                        strokeWidth="1.86"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.89868 1.6333V8.4471"
                        stroke="#F3F4F6"
                        strokeWidth="1.86"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>{" "}
                    <span className="button_text text-white">Export</span>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right rounded border-0"
                    style={{
                      background: "#fff",
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <button
                      className="dropdown-item dropdown_text fw-500 position-relative"
                      onClick={() => handleDownloadFile("csv")}
                    >
                      CSV
                    </button>
                    <button
                      className="dropdown-item dropdown_text fw-500 position-relative"
                      onClick={() => handleDownloadFile("pdf")}
                    >
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-start">
            <div className="">
              <h5 className="page_title mb-2">Retailers</h5>
              <p style={{ color: "#4B5563", fontSize: "13px" }}>
                <span className="fw-500 fs-14">List of all those...</span>
              </p>
            </div>

            <div className="d-flex justify-content-end d-sm-block d-none ">
              <div
                className="fit-content d-flex"
                style={{ whiteSpace: "nowrap" }}
              >
                <div className="btn-group">
                  <button
                    className="btn border btn-primary"
                    type="button"
                    onClick={() => setAssignCustomerDialog(true)}
                  >
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.28125 6.5H9.12461"
                        stroke="white"
                        strokeWidth="1.16867"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.20312 9.42198V3.57861"
                        stroke="white"
                        strokeWidth="1.16867"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>{" "}
                    <span className="button_text text-white">
                      Assign Customer
                    </span>
                  </button>
                </div>
                &nbsp;&nbsp;
                <div className="btn-group">
                  <button
                    data-toggle="dropdown"
                    aria-expanded="false"
                    className="btn border btn-primary"
                    type="button"
                  >
                    <svg
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.51855 8.93359V10.3937C1.51855 10.9313 1.95436 11.3671 2.49195 11.3671H9.30576C9.84335 11.3671 10.2792 10.9313 10.2792 10.3937V8.93359"
                        stroke="#F3F4F6"
                        strokeWidth="1.86"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.9519 6.5L5.8987 8.4468L7.8455 6.5"
                        stroke="#F3F4F6"
                        strokeWidth="1.86"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.89868 1.6333V8.4471"
                        stroke="#F3F4F6"
                        strokeWidth="1.86"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>{" "}
                    <span className="button_text text-white">Export</span>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right rounded border-0"
                    style={{
                      background: "#fff",
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <button
                      className="dropdown-item dropdown_text fw-500 position-relative"
                      onClick={() => handleDownloadFile("csv")}
                    >
                      CSV
                    </button>
                    <button
                      className="dropdown-item dropdown_text fw-500 position-relative"
                      onClick={() => handleDownloadFile("pdf")}
                    >
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="d-sm-flex justify-content-between align-items-center mt-3 mb-3 w-100">
            <div
              className="balance_as_at d-flex justify-content-end align-items-center mb-3 mb-md-0 w-100"
              style={{ gap: "10px" }}
            >
              <div
                className={`position-relative btn border py-1 rounded d-flex justify-content-start align-items-center mx-auto mx-sm-0`}
                style={{ width: "17.3em", background: "rgba(249, 250, 251, 1)" }}
              >
                <span className="">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.4375 1.59961V3.21972"
                      stroke="#4B5563"
                      strokeWidth="1.29609"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.75781 1.59961V3.21972"
                      stroke="#4B5563"
                      strokeWidth="1.29609"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.00781 5.42871H11.1885"
                      stroke="#4B5563"
                      strokeWidth="1.29609"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.457 5.10937V9.6997C11.457 11.3198 10.647 12.3999 8.75683 12.3999H4.43652C2.54639 12.3999 1.73633 11.3198 1.73633 9.6997V5.10937C1.73633 3.48926 2.54639 2.40918 4.43652 2.40918H8.75683C10.647 2.40918 11.457 3.48926 11.457 5.10937Z"
                      stroke="#4B5563"
                      strokeWidth="1.29609"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.59322 7.91846H8.59808"
                      stroke="#4B5563"
                      strokeWidth="1.29609"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.59322 9.53857H8.59808"
                      stroke="#4B5563"
                      strokeWidth="1.29609"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.59518 7.91846H6.60003"
                      stroke="#4B5563"
                      strokeWidth="1.29609"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.59518 9.53857H6.60003"
                      stroke="#4B5563"
                      strokeWidth="1.29609"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.59713 7.91846H4.60198"
                      stroke="#4B5563"
                      strokeWidth="1.29609"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.59713 9.53857H4.60198"
                      stroke="#4B5563"
                      strokeWidth="1.29609"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <div className="d-flex justify-content-start align-items-center ">
                  <span className="ml-2" style={{ marginTop: '1px' }}>Balance as at </span>
                  <DatePicker
                    selected={dateFrom}
                    onChange={handleDateChange}
                    startDate={dateFrom}
                    endDate={dateTo}
                    selectsRange
                    dateFormat="dd MMM, yyyy"
                    className="form-control border-0 fit-content"
                    placeholderText="Select date range"
                    required
                  />
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="d-flex w-100 justify-content-end" style={{}}>
          <div
            className="fit-content d-flex flex-wrap"
            style={{ whiteSpace: "nowrap" }}
          >
            <div className="fit-content last_btn_arrg ml-sm-2">
              <div className="btn-group">
                <button
                  data-toggle="dropdown"
                  aria-expanded="false"
                  className="btn border btn-primary"
                  type="button"
                >
                  <svg
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.51855 8.93359V10.3937C1.51855 10.9313 1.95436 11.3671 2.49195 11.3671H9.30576C9.84335 11.3671 10.2792 10.9313 10.2792 10.3937V8.93359"
                      stroke="#F3F4F6"
                      strokeWidth="1.86"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.9519 6.5L5.8987 8.4468L7.8455 6.5"
                      stroke="#F3F4F6"
                      strokeWidth="1.86"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.89868 1.6333V8.4471"
                      stroke="#F3F4F6"
                      strokeWidth="1.86"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>{" "}
                  <span className="button_text text-white">Export</span>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-right rounded border-0"
                  style={{
                    background: "#fff",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <button className="dropdown-item dropdown_text fw-500 position-relative">
                    CSV
                  </button>
                  <button className="dropdown-item dropdown_text fw-500 position-relative">
                    PDF
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}

          {/* <div className="top_stat_wrapper py-4 px-2 px-md-4 mb-5">
          <div className="row">
            <div className="col-lg-3 col-6 py-3 py-lg-2 border-right ">
              <div className="d-sm-flex h-100">
                <div className="w-100 h-sm-100 mb-sm-4">
                  <h6 className="fs-15 fw-500 text-dark">
                    Total Number <br /> of Customers
                  </h6>
                </div>
                <div className="w-100 w-md-75">
                  <p className="fw-700 mb-0 mb-sm-3 text-dark fs-22">71,897</p>
                  <small className="text-success fs-12">+26% this year</small>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6 py-3 py-lg-2 border-right ">
              <div className="d-sm-flex h-100">
                <div className="w-100 h-sm-100 mb-sm-4">
                <h6 className="fs-15 fw-500 text-dark d-none d-sm-block">
                Total <br /> Payout
                  </h6>
                <h6 className="fs-15 fw-500 text-dark d-block d-sm-none">
                Total Payout
                  </h6>
                </div>
                <div className="w-100 w-md-75">
                  <p className="fw-700 mb-0 mb-sm-3 text-dark fs-22">₦36,000</p>
                  <small className="text-success fs-12">+26% this year</small>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6 py-3 py-lg-2 border-right ">
              <div className="d-sm-flex h-100">
                <div className="w-100 h-sm-100 mb-sm-4">
                  <h6 className="fs-15 fw-500 text-dark d-none d-sm-block">
                    Total <br /> Pay In
                  </h6>
                  <h6 className="fs-15 mb-2 fw-500 text-dark d-block d-sm-none">
                    Total Pay In
                  </h6>
                </div>
                <div className="w-100 w-md-75">
                  <p className="fw-700 mb-0 mb-sm-3 text-dark fs-22">₦36,000</p>
                  <small className="text-success fs-12">+26% this year</small>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6 py-3 py-lg-2">
              <div className="d-sm-flex h-100">
                <div className="w-100 h-sm-100 mb-sm-4">
                  <h6 className="fs-15 fw-500 text-dark">Total Transaction</h6>
                </div>
                <div className="w-100 w-md-75">
                  <p className="fw-700 mb-0 mb-sm-3 text-dark fs-22">30,245,000</p>
                  <small className="text-success fs-12">+26% this year</small>
                </div>
              </div>
            </div>
          </div>
        </div> */}

          {/* <div className="w-100  py-4 mb-5">
          <div className="row">
            <div className="col-sm-6 mb-3 mb-lg-0 col-lg-3">
              <div className="card px-3 py-3 rounded border-0 bg-light">
                <span className="d-block fw-500 fs-15">No of Customers</span>
                <p className="mb-0 fs-30 fw-600 py-1 text-dark">230</p>
                <div className="d-flex align-items-center">
                  <div className='mr-2'>
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M5.0324 8.28478C4.88472 8.13724 4.80176 7.93716 4.80176 7.72854C4.80176 7.51992 4.88472 7.31984 5.0324 7.1723L8.18337 4.02527C8.33109 3.87778 8.53142 3.79492 8.7403 3.79492C8.94918 3.79492 9.14951 3.87778 9.29723 4.02527L12.4482 7.1723C12.5917 7.32069 12.6711 7.51942 12.6693 7.72571C12.6675 7.93199 12.5847 8.12932 12.4386 8.27519C12.2926 8.42107 12.095 8.50381 11.8884 8.5056C11.6819 8.50739 11.4829 8.42809 11.3343 8.28478L9.52804 6.48074V12.4491C9.52804 12.6577 9.44505 12.8579 9.29732 13.0054C9.14959 13.153 8.94922 13.2358 8.7403 13.2358C8.53138 13.2358 8.33101 13.153 8.18328 13.0054C8.03555 12.8579 7.95256 12.6577 7.95256 12.4491V6.48074L6.14627 8.28478C5.99854 8.43227 5.79821 8.51513 5.58933 8.51513C5.38045 8.51513 5.18012 8.43227 5.0324 8.28478V8.28478Z" fill="#10B981" />
                    </svg>
                    <span className='fs-14 fw-500'>2.02%</span>
                  </div>
                  <span className='fs-11'>from Last week</span>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mb-3 mb-lg-0 col-lg-3">
              <div className="card px-3 py-3 rounded border-0 bg-light">
                <span className="d-block fw-500 fs-15">Total Payout</span>
                <p className="mb-0 fs-30 fw-600 py-1 text-dark">₦230,000</p>
                <div className="d-flex align-items-center">
                  <div className='mr-2'>
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M5.0324 8.28478C4.88472 8.13724 4.80176 7.93716 4.80176 7.72854C4.80176 7.51992 4.88472 7.31984 5.0324 7.1723L8.18337 4.02527C8.33109 3.87778 8.53142 3.79492 8.7403 3.79492C8.94918 3.79492 9.14951 3.87778 9.29723 4.02527L12.4482 7.1723C12.5917 7.32069 12.6711 7.51942 12.6693 7.72571C12.6675 7.93199 12.5847 8.12932 12.4386 8.27519C12.2926 8.42107 12.095 8.50381 11.8884 8.5056C11.6819 8.50739 11.4829 8.42809 11.3343 8.28478L9.52804 6.48074V12.4491C9.52804 12.6577 9.44505 12.8579 9.29732 13.0054C9.14959 13.153 8.94922 13.2358 8.7403 13.2358C8.53138 13.2358 8.33101 13.153 8.18328 13.0054C8.03555 12.8579 7.95256 12.6577 7.95256 12.4491V6.48074L6.14627 8.28478C5.99854 8.43227 5.79821 8.51513 5.58933 8.51513C5.38045 8.51513 5.18012 8.43227 5.0324 8.28478V8.28478Z" fill="#10B981" />
                    </svg>
                    <span className='fs-14 fw-500'>2.02%</span>
                  </div>
                  <span className='fs-11'>from Last week</span>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mb-3 mb-lg-0 col-lg-3">
              <div className="card px-3 py-3 rounded border-0 bg-light">
                <span className="d-block fw-500 fs-15">Total Pay In</span>
                <p className="mb-0 fs-30 fw-600 py-1 text-dark">₦230,000</p>
                <div className="d-flex align-items-center">
                  <div className='mr-2'>
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M5.0324 8.28478C4.88472 8.13724 4.80176 7.93716 4.80176 7.72854C4.80176 7.51992 4.88472 7.31984 5.0324 7.1723L8.18337 4.02527C8.33109 3.87778 8.53142 3.79492 8.7403 3.79492C8.94918 3.79492 9.14951 3.87778 9.29723 4.02527L12.4482 7.1723C12.5917 7.32069 12.6711 7.51942 12.6693 7.72571C12.6675 7.93199 12.5847 8.12932 12.4386 8.27519C12.2926 8.42107 12.095 8.50381 11.8884 8.5056C11.6819 8.50739 11.4829 8.42809 11.3343 8.28478L9.52804 6.48074V12.4491C9.52804 12.6577 9.44505 12.8579 9.29732 13.0054C9.14959 13.153 8.94922 13.2358 8.7403 13.2358C8.53138 13.2358 8.33101 13.153 8.18328 13.0054C8.03555 12.8579 7.95256 12.6577 7.95256 12.4491V6.48074L6.14627 8.28478C5.99854 8.43227 5.79821 8.51513 5.58933 8.51513C5.38045 8.51513 5.18012 8.43227 5.0324 8.28478V8.28478Z" fill="#10B981" />
                    </svg>
                    <span className='fs-14 fw-500'>2.02%</span>
                  </div>
                  <span className='fs-11'>from Last week</span>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mb-3 mb-lg-0 col-lg-3">
              <div className="card px-3 py-3 rounded border-0 bg-light">
                <span className="d-block fw-500 fs-15">Total Transaction</span>
                <p className="mb-0 fs-30 fw-600 py-1 text-dark">230</p>
                <div className="d-flex align-items-center">
                  <div className='mr-2'>
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M5.0324 8.28478C4.88472 8.13724 4.80176 7.93716 4.80176 7.72854C4.80176 7.51992 4.88472 7.31984 5.0324 7.1723L8.18337 4.02527C8.33109 3.87778 8.53142 3.79492 8.7403 3.79492C8.94918 3.79492 9.14951 3.87778 9.29723 4.02527L12.4482 7.1723C12.5917 7.32069 12.6711 7.51942 12.6693 7.72571C12.6675 7.93199 12.5847 8.12932 12.4386 8.27519C12.2926 8.42107 12.095 8.50381 11.8884 8.5056C11.6819 8.50739 11.4829 8.42809 11.3343 8.28478L9.52804 6.48074V12.4491C9.52804 12.6577 9.44505 12.8579 9.29732 13.0054C9.14959 13.153 8.94922 13.2358 8.7403 13.2358C8.53138 13.2358 8.33101 13.153 8.18328 13.0054C8.03555 12.8579 7.95256 12.6577 7.95256 12.4491V6.48074L6.14627 8.28478C5.99854 8.43227 5.79821 8.51513 5.58933 8.51513C5.38045 8.51513 5.18012 8.43227 5.0324 8.28478V8.28478Z" fill="#10B981" />
                    </svg>
                    <span className='fs-14 fw-500'>2.02%</span>
                  </div>
                  <span className='fs-11'>from Last week</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}

          <div className="row">
            <div className="col-md-9 col-sm-12 mb-2 pr-1">
              <div className="row">
                <div className="col-md-9 col-sm-12 mb-2 pr-1">
                  <div className="card h-100 transaction_lineChart01 rounded-0" style={{ background: 'rgba(243, 244, 246, 1)', border: '2px solid rgba(229, 231, 235, 1)', }}>
                    <div className="card-body">
                      <div className="d-flex pl-3 justify-content-between align-items-center">
                        <h6 className="fs-16 fw-500 text-dark mb-0">Transaction</h6>

                        <div className="create_wallet_select_curr small">
                          <div
                            className="position-relative mx-auto mx-sm-0"
                            style={{
                              width: "9em",
                              maxWidth: "100%",
                              background: "rgba(249, 250, 251, 1)",
                              border: "1px solid rgba(209, 213, 219, 1)",
                              boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.05)",
                              borderRadius: "4px",
                            }}
                          >
                            <span
                              className=" all_selectdropdown_icon position-absolute px-1"
                              style={{
                                right: "auto",
                                top: "1px",
                                left: "-2px",
                                transform: "translateY(0%) scale(0.8)",
                              }}
                            >
                              {currType === "CNY" && (
                                <img src="/assets/icons/ic_flag_cny.svg" alt="" width={28} />
                              )}
                              {currType === "ngn" && (
                                <svg
                                  width="33"
                                  height="32"
                                  viewBox="0 0 33 32"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g filter="url(#filter0_d_3889_20025)">
                                    <mask
                                      id="mask0_3889_20025"
                                      style={{ maskType: "alpha" }}
                                      maskUnits="userSpaceOnUse"
                                      x="4"
                                      y="0"
                                      width="25"
                                      height="26"
                                    >
                                      <circle cx="16.5" cy="13" r="12.5" fill="#D9D9D9" />
                                    </mask>
                                    <g mask="url(#mask0_3889_20025)">
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M-1.26367 0.5H33.824V26.8158H-1.26367V0.5Z"
                                        fill="white"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M22.1245 0.5H33.824V26.8158H22.1245V0.5ZM-1.26367 0.5H10.4304V26.8158H-1.26367V0.5Z"
                                        fill="#008753"
                                      />
                                    </g>
                                  </g>
                                  <defs>
                                    <filter
                                      id="filter0_d_3889_20025"
                                      x="0.969697"
                                      y="0.5"
                                      width="31.0606"
                                      height="31.0606"
                                      filterUnits="userSpaceOnUse"
                                      colorInterpolationFilters="sRGB"
                                    >
                                      <feFlood
                                        floodOpacity="0"
                                        result="BackgroundImageFix"
                                      />
                                      <feColorMatrix
                                        in="SourceAlpha"
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha"
                                      />
                                      <feOffset dy="3.0303" />
                                      <feGaussianBlur stdDeviation="1.51515" />
                                      <feComposite in2="hardAlpha" operator="out" />
                                      <feColorMatrix
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                                      />
                                      <feBlend
                                        mode="normal"
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_3889_20025"
                                      />
                                      <feBlend
                                        mode="normal"
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_3889_20025"
                                        result="shape"
                                      />
                                    </filter>
                                  </defs>
                                </svg>
                              )}
                            </span>
                            <span
                              className=" all_selectdropdown_icon position-absolute px-2"
                              style={{
                                right: "2px",
                                left: "auto",
                                transform: "translateY(-45%)",
                              }}
                            >
                              <svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.13209 8.84964L7.80709 11.5246L9.44042 13.1663C10.1321 13.858 11.2571 13.858 11.9488 13.1663L16.2654 8.84964C16.8321 8.28298 16.4238 7.31631 15.6321 7.31631H10.9571H5.76542C4.96542 7.31631 4.56542 8.28298 5.13209 8.84964Z"
                                  fill="#4B5563"
                                />
                              </svg>
                            </span>
                            <Select
                              labelId="s"
                              id="s"
                              fullWidth
                              size="small"
                              required
                              name="verified"
                              defaultValue="ngn"
                              value={currType}
                              onChange={handleCurrTypeChange}
                              className="border-0"
                            >
                              <MenuItem value="ngn">NGN</MenuItem>
                              <MenuItem value="CNY">CNY</MenuItem>
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div
                        className="chartheight_specified"

                      >
                        <TransactionHighChart />
                        {/* <img
                    src="/assets/Chart01.png"
                    alt="LineChart01"
                    className="d-block mt-4"
                    style={{
                      aspectRatio: "2.1",
                    }}
                  /> */}
                        {/* <canvas id="transactionChart"></canvas> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 pl-1 col-sm-12 mb-2">
                  <div className="card h-100 rounded-0 border-0" style={{}}>
                    <div className="card-body p-0 d-flex flex-column justify-content-between align-items-center" style={{ gap: '1em' }}>
                      <div className="w-100 h-100 border px-2 py-3" style={{ background: 'rgba(17, 24, 39, 1)', border: '1px solid rgba(229, 231, 235, 1)', }}>
                        <h6 className="fs-15 fw-500 mb-0 text-white">Total Pay In</h6>
                        <h3 className="fw-600 mt-4 mb-0 text-white">₦230,000</h3>
                        <span className="fw-500 mb-0 d-block fs-11" style={{ color: 'rgba(156, 163, 175, 1)' }}>that’s 25% increase <br />
                          from last month</span>
                      </div>

                      <div className="w-100 h-100 border px-2 py-3"
                        style={{ background: 'rgba(243, 244, 246, 1)', border: '1px solid rgba(229, 231, 235, 1)', }}>
                        <h6 className="fs-15 fw-500 mb-0 text-dark">Total Payout</h6>
                        <h3 className="fw-600 mt-4 mb-0 text-dark">₦230,000</h3>
                        <span className="fw-500 mb-0 d-block fs-11" style={{ color: 'rgba(156, 163, 175, 1)' }}>that’s 25% increase <br />
                          from last month</span>
                      </div>

                      <div className="position-absolute" style={{
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: '10',
                        borderRadius: '50%',
                        boxShadow: '0 0 0 5px rgba(255, 255, 255, 1)',
                      }}>
                        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="17.5" cy="17.5" r="17.5" fill="black" />
                          <path d="M16.7078 13.6L13.6078 10.5L10.5078 13.6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M13.6074 25.5V10.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M19.291 22.4004L22.391 25.5004L25.491 22.4004" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M22.3906 10.5V25.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-7 col-sm-12 pr-1">
                  <div className="card h-100 transaction_lineChart01 rounded-0" style={{ background: 'rgba(243, 244, 246, 1)', border: '1px solid rgba(229, 231, 235, 1)', }}>
                    <div className="card-body">
                      <div className="d-flex pl-3 justify-content-between align-items-center">
                        <h6 className="fs-16 fw-500 text-dark mb-0">Invoice</h6>
                        <div className="balance_as_at d-flex justify-content-between align-items-center mb-3 mb-md-0" style={{ gap: '10px', width: 'fit-content' }}>
                          <div className={`position-relative btn border py-1 rounded d-flex justify-content-start align-items-center`}
                            style={{ width: '9.0em', background: 'rgba(249, 250, 251, 1)' }}>
                            <span className="">
                              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.4375 1.59961V3.21972" stroke="#4B5563" strokeWidth="1.29609" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8.75781 1.59961V3.21972" stroke="#4B5563" strokeWidth="1.29609" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M2.00781 5.42871H11.1885" stroke="#4B5563" strokeWidth="1.29609" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M11.457 5.10937V9.6997C11.457 11.3198 10.647 12.3999 8.75683 12.3999H4.43652C2.54639 12.3999 1.73633 11.3198 1.73633 9.6997V5.10937C1.73633 3.48926 2.54639 2.40918 4.43652 2.40918H8.75683C10.647 2.40918 11.457 3.48926 11.457 5.10937Z" stroke="#4B5563" strokeWidth="1.29609" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8.59322 7.91846H8.59808" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8.59322 9.53857H8.59808" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M6.59518 7.91846H6.60003" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M6.59518 9.53857H6.60003" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4.59713 7.91846H4.60198" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4.59713 9.53857H4.60198" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </span>
                            <div className="d-flex justify-content-start align-items-center ">
                              {/* <span className="ml-2" style={{ marginTop: '1px' }}>Balance as at </span>
                              <DatePicker
                               */}
                              <DatePicker
                                // showIcon
                                selected={paymentDate}
                                onChange={(date) => setPaymentDate(date)}
                                dateFormat="dd/MM/yyyy"
                                className="form-control border-0 fit-content"
                                placeholderText=""
                                required
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={15}
                                minDate={new Date()}
                                size="small"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="chartheight_specified"

                      >
                        <InvoicesChart />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-5 pl-1 col-sm-12">
                  <div className="card h-100 transaction_lineChart01 rounded-0" style={{ background: 'rgba(243, 244, 246, 1)', border: '1px solid rgba(229, 231, 235, 1)', }}>
                    <div className="card-body">
                      <div className="d-flex pl-3 justify-content-between align-items-center">
                        <h6 className="fs-16 fw-500 text-dark mb-0">Revenue Growth</h6>
                        <div className="balance_as_at d-flex justify-content-between align-items-center mb-3 mb-md-0" style={{ gap: '10px', width: 'fit-content' }}>
                          <div className={`position-relative btn border py-1 rounded d-flex justify-content-start align-items-center`}
                            style={{ width: '9.0em', background: 'rgba(249, 250, 251, 1)' }}>
                            <span className="">
                              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.4375 1.59961V3.21972" stroke="#4B5563" strokeWidth="1.29609" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8.75781 1.59961V3.21972" stroke="#4B5563" strokeWidth="1.29609" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M2.00781 5.42871H11.1885" stroke="#4B5563" strokeWidth="1.29609" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M11.457 5.10937V9.6997C11.457 11.3198 10.647 12.3999 8.75683 12.3999H4.43652C2.54639 12.3999 1.73633 11.3198 1.73633 9.6997V5.10937C1.73633 3.48926 2.54639 2.40918 4.43652 2.40918H8.75683C10.647 2.40918 11.457 3.48926 11.457 5.10937Z" stroke="#4B5563" strokeWidth="1.29609" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8.59322 7.91846H8.59808" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8.59322 9.53857H8.59808" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M6.59518 7.91846H6.60003" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M6.59518 9.53857H6.60003" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4.59713 7.91846H4.60198" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4.59713 9.53857H4.60198" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </span>
                            <div className="d-flex justify-content-start align-items-center ">
                              {/* <span className="ml-2" style={{ marginTop: '1px' }}>Balance as at </span> */}
                              <DatePicker
                                // showIcon
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={15}
                                selected={paymentDate}
                                onChange={(date) => setPaymentDate(date)}
                                dateFormat="dd/MM/yyyy"
                                className="form-control border-0 fit-content"
                                placeholderText=""
                                required
                                // minDate={new Date()}
                                size="small"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="chartheight_specified"
                      >
                        <RevenueGrowthHorizontalChart />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 pl-1 col-sm-12 mb-2">
              <div className="card h-100 rounded-0" style={{ background: 'rgba(243, 244, 246, 1)', border: '1px solid rgba(229, 231, 235, 1)', }}>
                <div className="card-body px-0 pt-3">
                  <div className="px-3 pt-2">
                    <h6 className="fs-14 fw-500 text-dark mb-0">
                      Weekly Volume
                    </h6>
                  </div>
                  <WeeklyVolumeChart />
                  {/* <div className="busiest-day-chart px-3 pt-3">
                    <ul className="list-unstyled mb-0">
                      <li>
                        <div className="d-flex align-items-center">
                          <span className="fs-14 fw-500 d-block w-25">Mon</span>
                          <div className="w-100">
                            <div
                              className="range_box"
                              style={{ width: "23%" }}
                            ></div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center">
                          <span className="fs-14 fw-500 d-block w-25">Tues</span>
                          <div className="w-100">
                            <div
                              className="range_box"
                              style={{ width: "28%" }}
                            ></div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center">
                          <span className="fs-14 fw-500 d-block w-25">Wed</span>
                          <div className="w-100">
                            <div
                              className="range_box"
                              style={{ width: "60%" }}
                            ></div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center">
                          <span className="fs-14 fw-500 d-block w-25">Thur</span>
                          <div className="w-100">
                            <div
                              className="range_box"
                              style={{ width: "50%" }}
                            ></div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center">
                          <span className="fs-14 fw-500 d-block w-25">Fri</span>
                          <div className="w-100">
                            <div
                              className="range_box"
                              style={{ width: "40%" }}
                            ></div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center">
                          <span className="fs-14 fw-500 d-block w-25">Sat</span>
                          <div className="w-100">
                            <div
                              className="range_box"
                              style={{ width: "52%" }}
                            ></div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center">
                          <span className="fs-14 fw-500 d-block w-25">Sun</span>
                          <div className="w-100">
                            <div
                              className="range_box"
                              style={{ width: "80%" }}
                            ></div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="w-100 pl-5">
                      <p className="mb-0 text-dark fw-500 fs-12 text-center">
                        Transactions
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="col-md-3 pr-1 col-sm-12 mb-4">
              <div className="card h-100 rounded-0" style={{ background: 'rgba(243, 244, 246, 1)', border: '1px solid rgba(229, 231, 235, 1)', }}>
                <div className="card-body px-0 pt-3">
                  <div className="px-3 px-lg-4 pt-2">
                    <h6 className="fs-14 fw-500 text-dark mb-0">
                      Payment Success Rate
                    </h6>
                  </div>
                  <PSRateChart />
                </div>
              </div>
            </div>

            <div className="col-md-3 px-1 col-sm-12 mb-4">
              <div className="card h-100 rounded-0" style={{ background: 'rgba(243, 244, 246, 1)', border: '1px solid rgba(229, 231, 235, 1)', }}>
                <div className="card-body px-0 pt-3">
                  <div className="px-3 px-lg-4 pt-2">
                    <h6 className="fs-14 fw-500 text-dark mb-0">
                      Payment Method
                    </h6>
                  </div>
                  <PaymentMethodChart />
                </div>
              </div>
            </div>

            <div className="col-md-3 px-1 col-sm-12 mb-4">
              <div className="card h-100 rounded-0" style={{ background: 'rgba(243, 244, 246, 1)', border: '1px solid rgba(229, 231, 235, 1)', }}>
                <div className="card-body px-0 pt-3">
                  <div className="d-flex px-3 justify-content-between align-items-center">
                    <h6 className="fs-13 fw-500 text-dark mb-0">
                      Most Transacted Banks
                    </h6>
                    <div className="balance_as_at d-flex justify-content-between align-items-center mb-3 mb-md-0" style={{ gap: '10px', width: 'fit-content' }}>
                      <div className={`position-relative btn border py-1 rounded d-flex justify-content-start align-items-center`}
                        style={{ width: '9.0em', background: 'rgba(249, 250, 251, 1)' }}>
                        <span className="">
                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.4375 1.59961V3.21972" stroke="#4B5563" strokeWidth="1.29609" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8.75781 1.59961V3.21972" stroke="#4B5563" strokeWidth="1.29609" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2.00781 5.42871H11.1885" stroke="#4B5563" strokeWidth="1.29609" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.457 5.10937V9.6997C11.457 11.3198 10.647 12.3999 8.75683 12.3999H4.43652C2.54639 12.3999 1.73633 11.3198 1.73633 9.6997V5.10937C1.73633 3.48926 2.54639 2.40918 4.43652 2.40918H8.75683C10.647 2.40918 11.457 3.48926 11.457 5.10937Z" stroke="#4B5563" strokeWidth="1.29609" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8.59322 7.91846H8.59808" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8.59322 9.53857H8.59808" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6.59518 7.91846H6.60003" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6.59518 9.53857H6.60003" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.59713 7.91846H4.60198" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.59713 9.53857H4.60198" stroke="#4B5563" strokeWidth="1.29609" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </span>
                        <div className="d-flex justify-content-start align-items-center ">
                          {/* <span className="ml-2" style={{ marginTop: '1px' }}>Balance as at </span> */}
                          <DatePicker
                            // showIcon
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={15}
                            selected={paymentDate}
                            onChange={(date) => setPaymentDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form-control border-0 fit-content"
                            placeholderText=""
                            required
                            minDate={new Date()}
                            size="small"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <MostTransactedBankChart />
                </div>
              </div>
            </div>

            <div className="col-md-3 pl-1 col-sm-12 mb-4">
              <div className="card h-100 rounded-0" style={{ background: '#111827', border: '1px solid rgba(229, 231, 235, 1)', }}>
                <div className="card-body px-0 pt-3">
                  <div className="px-3 px-lg-4 pt-2">
                    <h6 className="fs-14 fw-500 text-white mb-0">
                      Top Countries
                    </h6>
                  </div>
                  <TopCountries />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="card transaction_lineChart02 mb-4 w-100">
            <div className="row w-md-100">
              <div className="col-md-8">
                <div className="card-body pb-0 w-100">
                  <ul className="chart02_buttons list-unstyled d-flex flex-wrap w-100">
                    <li
                      className={`btn px-4 py-2 mb-2 mb-sm-0 mr-2 ${activeButton === "Churn Rate"
                        ? "btn-primary"
                        : "btn_inactive_indicator"
                        }`}
                      onClick={() => handleButtonClick("Churn Rate")}
                    >
                      Churn Rate
                    </li>
                    <li
                      className={`btn px-4 py-2 mb-2 mb-sm-0 mr-2 ${activeButton === "MRR"
                        ? "btn-primary"
                        : "btn_inactive_indicator"
                        }`}
                      onClick={() => handleButtonClick("MRR")}
                    >
                      MRR
                    </li>
                    <li
                      className={`btn px-4 py-2 mb-2 mr-2 ${activeButton === "Refunds"
                        ? "btn-primary"
                        : "btn_inactive_indicator"
                        }`}
                      onClick={() => handleButtonClick("Refunds")}
                    >
                      Refunds
                    </li>
                    <li
                      className={`btn px-4 py-2 mb-2 ${activeButton === "Active Customers"
                        ? "btn-primary"
                        : "btn_inactive_indicator"
                        }`}
                      onClick={() => handleButtonClick("Active Customers")}
                    >
                      Active Customers
                    </li>
                  </ul>

                  <div
                    className="chartheight_specified"
                    style={{}}
                  >
                    <BarWithLineChart />
                  </div>
                </div>
              </div>
              <div className="col-md-4 pl-0">
                <div
                  className=" mx-auto py-4"
                  style={{ width: "17em", maxWidth: "100%" }}
                >
                  <h6 className="fs-14 fw-500 text-dark mb-0">
                    Revenue by Location
                  </h6>
                  <img
                    src="/assets/hitMapImg.png"
                    alt="hitMapImg"
                    className="d-block mt-4"
                  />
                  <div className="hitmap_range_box_wrap">
                    {hitmapCountries.map((item, index) => (
                      <div className="hitmap_range_box mb-3">
                        <div className="d-flex justify-content-between">
                          <span className="fw-500 text-dark">{item.country}</span>
                          <span className="fw- text-dark">{item.amount}</span>
                        </div>
                        <div className="progress_bar">
                          <span style={{ width: `${item.bar_rate}` }}></span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row w-md-100">
            <div className="col-md-6 mb-4">
              <TopCustomersChart />
            </div>
            <div className="col-md-6 mb-4">
              <RevenueGrowthChart />
            </div>
            <div className="col-md-6 mb-4">
              <MostTransactedBanksChart />
            </div>
            <div className="col-md-6 mb-4">
              <PaymentMethod />
            </div>
            <div className="col-md-6">
              <InvoicesChart />
            </div>
            <div className="col-md-6">
              <PaymentSuccessRateChart />
            </div>
          </div> */}

          <div className="w-100 pb-3">
            <InsightCustomersTable
              getTableData={(data) => (tableDataRef.current["customers"] = data)}
            />
          </div>
        </div>
      </div>


      <Drawer
        anchor="right"
        open={assignCustomerDialog}
        onClose={handleCloseAssignCustomerDialog}
        PaperProps={{
          sx: {
            width: 350,
            maxWidth: "100%",
            border: "none",
            overflow: "",
            marginTop: "0em",
            height: "100vh",
          },
        }}
        className={``}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, pt: 1, pb: 1 }}
          className=""
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            {/* Filters */}
          </Typography>
          <IconButton onClick={() => setAssignCustomerDialog(false)}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        {/* <Divider /> */}

        <Scrollbar>
          <Stack spacing={2} sx={{ p: 0 }}>
            <div
              className={`modal_content px-3`}
              style={{ minHeight: "90vh", background: "rgba(249, 250, 251, 1)" }}
            >
              <div
                className={`${styles.trftobank_side_wrap} px-0 px-sm-4 pb-4 mx-auto mx-md-0`}
                style={{ background: "rgba(249, 250, 251, 1)" }}
              >
                {" "}
                <div className={` w-100 pt-2`}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="">
                      <p className="page_title fs-16 fw-500 text-dark mb-1">
                        Select Customers
                      </p>
                      <p
                        className="mb-0"

                      >
                        <span style={{ color: "#4B5563" }} className="fs-12">Assign Customers to this group</span>
                      </p>
                    </div>
                    <div>
                      <button className="btn btn-primary">
                        <svg
                          width="12"
                          height="13"
                          viewBox="0 0 12 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.94531 6.5H8.78868"
                            stroke="white"
                            strokeWidth="1.16867"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.86719 9.42198V3.57861"
                            stroke="white"
                            strokeWidth="1.16867"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>{" "}
                        Add
                      </button>
                    </div>
                  </div>

                  <p
                    className="mb-2 mt-4"
                    style={{ color: "#4B5563", fontSize: "13px" }}
                  >
                    <span className="text-dark fs-14 fw-500">
                      {checkedItems.length} Customers selected
                    </span>
                  </p>
                  <div className={`modal_header mb-0`}>
                    <div className={`${styles.hold_input} w-100`}>
                      <img src="/assets/icons/search.svg" alt="" />
                      <input
                        type="text"
                        name="search"
                        placeholder="Search"
                        className=" form-control border rounded-0 w-100 fs-13 py-3"
                        style={{ paddingLeft: "33px", }}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </div>

                  <div
                    className="mt-2 modal_content"
                    style={{ overflowY: "scroll" }}
                  >
                    {filteredCustomers.length > 0 ? (
                      <>
                        {filteredCustomers.map((item) => (
                          <div
                            key={item.id}
                            className="py-3"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex align-items-center">
                              <div className="checkbox_div">
                                <Checkbox
                                  id={item.id}
                                  checked={saveStatus[item.id] || false}
                                  onChange={() => handleChangeSave(item.id)}
                                  style={{}}
                                />
                              </div>
                              <label htmlFor={item.id} className="d-block w-100 ml-2 mb-0 cursor-pointer">
                                <p className="mb-0" style={{ fontSize: "13px" }}>
                                  <label
                                    htmlFor={item.id}
                                    className="mb-0 cursor-pointer"
                                    style={{ fontWeight: "500", color: "#000" }}
                                  >
                                    {item.name}
                                  </label>
                                </p>
                                <span
                                  className="text-muted cursor-pointer"
                                  style={{ fontSize: "11px" }}
                                >
                                  {item.email}
                                </span>
                              </label>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <h4 className="fw-500 fs-16 mb-4 text-center d-block border-top py-4">No match!</h4>
                      </>
                    )}
                    {/* {console.log(checkedItems)} */}
                  </div>
                </div>
              </div>
            </div>
          </Stack>
          {/* <Box sx={{ p: 3 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              startIcon={<Iconify icon="ic:round-clear-all" />}
            >
              Clear All
            </Button>
          </Box> */}
        </Scrollbar>
      </Drawer>
    </>
  );
}
