// // Preloader.js
// import React from 'react';
// import Preload from 'react-preload';

// const Preloader = ({ children }) => {
//   return (
//     <Preload
//       images={['image-url-1.jpg', 'image-url-2.png']} // Add URLs of images to preload
//       loadingIndicator={<div 
//       className='d-flex justify-content-center align-items-center'
//       style={{
//         width: '100%',
//         minHeight: '100vh',

//       }}><h1>Loading...</h1></div>} // Add a loading indicator
//     >
//       {children}
//     </Preload>
//   );
// };

// export default Preloader;


// src/components/Preloader.js
import React, { useState } from 'react';
import './Preloader.css';

const Preloader = () => {
  const [fadeOut] = useState(false);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setFadeOut(true);
  //   }, 2000); // minimum display time of 3 seconds

  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <div id="preloader" className={`${fadeOut ? 'fade-out' : ''} preloader`}>
      <div id="loader"></div>
    </div>
  );
};

export default Preloader;

