import React, { useState, useEffect } from 'react';
import {
    Box, Tabs, Tab, Typography, TextField, Button,
    Checkbox, MenuItem
} from '@mui/material';
import LiveSelect from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import { useParams, useNavigate } from 'react-router-dom';

import { AccountBalance, CreditCard, Tag } from '@mui/icons-material';
import GlobalModal from "../../Modals/GlobalModal";
import axiosInstance from "src/axiosInstance"
import CircularProgress from '@mui/material/CircularProgress';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function PaymentCheckout() {
    const [value, setValue] = useState(0);
    const [DialCode, setDialCode] = useState("");
    const [save, setSave] = useState(false);
    const [selectedBankOption] = useState(null);
    const [banks, setBanks] = useState([]);
    let [bank_info, setBankInfo] = useState({ account_no: '', account_name: '', bank_code: '', bank_name: '' });
    const [cardNumber, setCardNumber] = useState('');
    const [cardPin, setCardPin] = useState('');
    const [cardError, setCardError] = useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [cardCVV, setCardCVV] = useState('');
    const [cardOTP, setCardOTP] = useState('');
    const [openCardOTP, setOpenCardOTP] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);
    const [CancelTrf, setCancelTrf] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [CancelTrxn, setCancelTrxn] = useState(false);
    const [isCardPaymentLoading, setIsCardPaymentLoading] = useState(false);
    const trx_details = JSON.parse(localStorage.getItem('trx_details'));

    const apiUrl = process.env.REACT_APP_NAME;
    const [initiatePLResponseObj, setInitiatePLResponseObj] = useState(null);
    const [InitiateTransferObject, setInitiateTransferObject] = useState(null);
    const { trxRef } = useParams();
    const currentYear = new Date().getFullYear();

    // const base64Encode = (str) => {
    //     return btoa(unescape(encodeURIComponent(str)));
    //   };

    const handleGetPaymentInfo = async () => {
        setIsPending(true);

        try {
            const response = await axiosInstance.get(`/payments/transactions/${trxRef}?productName=${apiUrl}`);
            const data = response.data.data;

            setInitiatePLResponseObj(data);

        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsPending(false);
        }
    };

    const handleInitiatePayment = async (card) => {
        if(card === 'card-payment') {
            setIsCardPaymentLoading(true);
        } else {
            setIsCardPaymentLoading(false);
        }
        if (value === 0) {
            setIsPending(true);
            // console.log("Current Tab Value: ", value);

            if (!trx_details) {
                // const encodedTrxRef = base64Encode(trxRef);

                try {
                    const response = await axiosInstance.get(`/payments/transactions/init-payment/${trxRef}?productName=${apiUrl}`);
                    const data = response.data.data;

                    setInitiateTransferObject(data);
                    setTimeLeft(parseFloat(data.accountDurationSeconds));
                    localStorage.setItem('trx_details', JSON.stringify(data));

                } catch (err) {
                    if (err.response) {
                        if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                            if (err.response.data.data.errorMessage === 'Transaction already completed') {
                                window.location.href = `${initiatePLResponseObj.data}`
                            }
                            notifyError(err.response.data.data.errorMessage);
                        } else if (err.response.data && err.response.data.message) {
                            notifyError(err.response.data.message);
                        } else {
                            notifyError('An unexpected error occurred');
                        }
                    } else {
                        notifyError('Network error or server not responding');
                    }
                } finally {
                    setIsPending(false);
                }

            } else {
                setInitiateTransferObject(trx_details);
                    setTimeLeft(parseFloat(trx_details.accountDurationSeconds));
            }
        } else if (value === 1) {
            
            if(cardPin === '' || cardPin.length < 4 ) {
                setCardError('Input you 4 digits pin');
                return;
            }
            const firstTwoDigitsOfCurrentYear = currentYear.toString().substring(0, 2);
            const lastTwoDigitsOfExpiryYear = cardExpiry.substring(3, 5);
            const cleanedCardNumber = cardNumber.replace(/\s+/g, '');
            const payload = {
                number: cleanedCardNumber,
                expiryMonth: cardExpiry.substring(0, 2),
                expiryYear: firstTwoDigitsOfCurrentYear + lastTwoDigitsOfExpiryYear,
                pin: cardPin,
                cvv: cardCVV,
            }
            // console.log(payload)
            if(save) {
                localStorage.setItem('card-details', JSON.stringify(payload))
            }
            try {
                const response = await axiosInstance.post(`/payments/transactions/init-payment-card/${trxRef}?productName=${apiUrl}`, payload);
                const data = response.data.data;
                const message = response.data.message;

                setOpenCardOTP(true)


            } catch (err) {

                if (err.response) {
                    if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                        if (err.response.data.data.errorMessage === 'Transaction already completed') {
                            window.location.href = `${initiatePLResponseObj.data}`
                        }
                        notifyError(err.response.data.data.errorMessage);
                    } else if (err.response.data && err.response.data.message) {
                        notifyError(err.response.data.message);
                    } else {
                        notifyError('An unexpected error occurred');
                    }
                } else {
                    notifyError('Network error or server not responding');
                }

            } finally {
                setIsCardPaymentLoading(false);
            }

        } else if (value === 2) {
            // console.log("Current Tab Value: ", value);

            try {
                setDialCode('');

                const response = await axiosInstance.get(`/payments/transactions/init-payment/${trxRef}?productName=${apiUrl}&bankCode=${bank_info.bank_code}`);
                const data = response.data.data;

                setInitiateTransferObject(data);
                setTimeLeft(parseFloat(data.accountDurationSeconds));
                localStorage.setItem('trx_details', JSON.stringify(data));
                if (data.ussdPayment === null) {
                    notifyError('USSD not available for the selected bank')
                    // notifyError('Unable to retrieve bank code. Please select a different bank')
                    // notifyError('Error getting bank code. Kindly select another bank')
                } else {
                    setDialCode(data.ussdPayment)
                }
            } catch (err) {
                if (err.response) {
                    if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                        if (err.response.data.data.errorMessage === 'Transaction already completed') {
                            window.location.href = `${initiatePLResponseObj.data}`
                        }
                        notifyError(err.response.data.data.errorMessage);
                    } else if (err.response.data && err.response.data.message) {
                        notifyError(err.response.data.message);
                    } else {
                        notifyError('An unexpected error occurred');
                    }
                } else {
                    notifyError('Network error or server not responding');
                }
            } finally {
                setIsPending(false);
            }
        }
    };

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('card-details'))
        if(data) {
            const card_expiry = data.expiryMonth + '/' + data.expiryYear.substring(2, 4)
            setCardNumber(formatCardNumber(data.number))
            setCardExpiry(card_expiry)
            setCardPin(data.pin)
            setCardCVV(data.cvv)

        }
    }, [])

    const handleVerifyPayment = async (val) => {
        setIsPending(true);

        try {
            const response = await axiosInstance.get(`/payments/transactions/verify-payment/${trxRef}?productName=${apiUrl}`);
            const data = response.data.data;

            // console.log('verify payment data: ', data);
            if (response && response?.status >= 200 && response && response?.status < 300) {
                    notifySuccess(response?.data?.message)
                

                setTimeout(() => {
                    window.location.href = `${data.redirectUrl}`
                }, 1000);

                // console.log('verify payment status is 200');
            } else if (response && response?.status === 402) {
            }
        } catch (err) {
            if (err?.response?.status === 402) {
                // console.log('verify payment status is 402, awaiting');
            }
            if (err.response) {
                if (val === 'clicked') {
                    if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                        notifyError(err.response.data.data.errorMessage);
                    } else if (err.response.data && err.response.data.message) {
                        notifyError(err.response.data.message);
                    } else {
                        notifyError('An unexpected error occurred');
                    }
                    val = ''
                }

            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsPending(false);
        }
    };

    const handleAuthorizeCardPayment = async () => {
        setIsCardPaymentLoading(true);

        const payload = {
            otp: cardOTP,
        }

        try {
            const response = await axiosInstance.post(`/payments/transactions/authorize-card-payment/${trxRef}?productName=${apiUrl}`, payload);
            const data = response.data.data;

            // console.log('verify payment data: ', data);
            if (response && response?.status >= 200 && response && response?.status < 300) {
                    notifySuccess(response?.data?.message)
            
                    setOpenCardOTP(false)

                setTimeout(() => {
                    window.location.href = `${data.redirectUrl}`
                }, 1000);

                // console.log('verify payment status is 200');
            } else if (response && response?.status === 402) {
            }
        } catch (err) {
            if (err?.response?.status === 402) {
                // console.log('verify payment status is 402, awaiting');
            }
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsCardPaymentLoading(false);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            handleVerifyPayment();
        }, 10000);

        // Clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, [timeLeft]);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLeft]);

    // Set up another useEffect to check for timeLeft reduction by 10
    useEffect(() => {
        if (timeLeft % 10 === 0 && timeLeft !== 0) {
            handleVerifyPayment();
        }
    }, [timeLeft]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes} mins ${seconds} secs`;
    };

    useEffect(() => {
        handleGetPaymentInfo();
        handleInitiatePayment();
    }, [trxRef]);


    const formatCardNumber = (value) => {
        return value.replace(/\D/g, '').replace(/(.{4})/g, '$1 ').trim().substring(0, 24);
    };

    const handleCardNumberChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        const formattedValue = formatCardNumber(value);
        setCardNumber(formattedValue);
    };

    const formatCardPin = (value) => {
        return value.replace(/\D/g, '').replace(/(.{4})/g, '$1 ').trim().substring(0, 4);
    };

    const handleCardPinChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        const formattedValue = formatCardPin(value);
        setCardPin(formattedValue);
    };

    const handleSetCardOTP = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        setCardOTP(value)
    };

    const handleCardExpiryChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        let formattedValue = value;
        if (value.length > 2) {
            formattedValue = value.substring(0, 2) + '/' + value.substring(2, 4);
        }
        // console.log(value);
        setCardExpiry(formattedValue.substring(0, 5)); // Limit to MM/YY format
    };

    // const handleCardExpiryChange = (date) => {
    //     if (date) {
    //         setCardExpiry(date); // Store date object
    //     } else {
    //         setCardExpiry(null);
    //     }
    // };

    const handleCardCVVChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        setCardCVV(value.substring(0, 3)); // Limit to 3 digits
    };


    const fetchBanks = async () => {
        const banksApi = await fetch('https://api.paystack.co/bank')
        const banks = await banksApi.json();
        if (banks && banks.status) {
            setBanks(banks.data)
        }
    }

    const handleBankChange = (e) => {
        const value = e.value;
        const label = e.label;
        setBankInfo((prev) => {
            const newBankInfo = {
                ...prev,
                bank_code: value,
                bank_name: label,
            };
            // console.log('Selected bank details:', newBankInfo);
            return newBankInfo;
        });
    }

    // const handleBankChange = (e) => {
    //     const value = e.value
    //     const label = e.label
    //     setBankInfo((prev) => {
    //         return {
    //             ...prev,
    //             bank_code: value,
    //             bank_name: label,
    //         }
    //     })
    // }

    useEffect(() => {
        fetchBanks()
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setDialCode('');
        setCardNumber('');
        setCardPin('');
        setCardExpiry('');
        setCardCVV('');
    };


    const handleCancelTrf = () => {
        setCancelTrf(true);
        setCancelTrxn(false);
        setTimeout(() => {
            window.open(`/app/overview`, '_self');
        }, 2000);
    };

    const handleChangeSave = () => {
        setSave(!save);
    };

    const handleCloseCBDialog = () => {
        setCancelTrxn(false);
    };

    return (
        <>
        <div className={`${CancelTrf ? '' : 'align-items-center'} checkout_box w-100 d-flex justify-content-center py-5`} style={{ minHeight: '100vh', background: '#f9fafb' }}>
            {CancelTrf ? (
                <>
                    <Box className="my-5 position-relative text-center"
                        sx={{ width: '100%', maxWidth: '250px', }}>
                        <div className="fit-content mb-4 mx-auto cursor-pointer">
                            <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.843506" width="75.1601" height="75.1601" rx="37.5801" fill="#111827" />
                                <path d="M30.0225 28.6163L48.8297 48.2348" stroke="#F9FAFB" stroke-width="3.39714" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M29.6168 47.8294L49.2354 29.0222" stroke="#F9FAFB" stroke-width="3.39714" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>

                        <p className='fs-20 fw-500 text-dark mb-0'>Payment Canceled</p>
                        <p className="mb-0 pt-2">
                            <span className='fs-14'>Your payment was not processed and has been cancelled</span>
                        </p>
                    </Box>
                </>
            ) : (
                <Box className="my-4 position-relative bg-white"
                    sx={{ width: '100%', maxWidth: '500px', boxShadow: '0px 3.65px 3.65px 0px #00000040', }}>
                    <span
                        // onClick={handleCancelTrf}
                        onClick={() => setCancelTrxn(true)}
                        className='cursor-pointer'
                        style={{
                            position: 'absolute',
                            top: '0',
                            right: '-70px',
                        }}>
                        <svg width="46" height="47" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="23.4858" y="0.966797" width="32.5342" height="32.5342" rx="6.77795" transform="rotate(46.2095 23.4858 0.966797)" fill="#EAECF0" />
                            <path d="M17.3716 18.0952L28.6288 29.838" stroke="#292D32" stroke-width="2.03339" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M17.1288 29.5956L28.8716 18.3384" stroke="#292D32" stroke-width="2.03339" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>

                    <Box className="top" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, backgroundColor: '#000000', color: '#fff' }}>
                        {/* <Typography variant="h6">OnePoint</Typography> */}
                        <div className="fit-content">
                            <img src="/assets/checkout_logo.svg" className='checkout_logo' alt="" />
                        </div>
                        <Box>
                            <Typography variant="h6" className='text-white fw-700 fs-20 text-right'>{initiatePLResponseObj ? initiatePLResponseObj.paymentInfo.invoice.currency === 'CNY' ? '¥' : '₦' : ''}{initiatePLResponseObj ? initiatePLResponseObj.paymentInfo.invoice.amount : '0'}.00</Typography>
                            <Typography variant="body2" className='text-white fs-11'>{initiatePLResponseObj ? initiatePLResponseObj.paymentInfo.customer.email : '---'}</Typography>
                        </Box>
                    </Box>
                    {!openCardOTP && (
                        <div className='px-4 py-4 payment_checkout_wrapper'>
                            <p className='mb-2 mt-1'>
                                <span className='fs-16 fw-600'>Payment Method</span>
                            </p>
                            <div className='payment_checkout'>
                                <Tabs value={value} onChange={handleChange} centered>
                                    <Tab label="Transfer" icon={
                                        // <AccountBalance style={{fontSize: '20px'}} />
                                        <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.5512 19.8262V15.7643H24.6131V13.0564L30.0289 17.7953L24.6131 22.5342V19.8262H20.5512ZM19.1972 11.2963V13.0564H2.94971V11.2963L11.0735 6.28662L19.1972 11.2963ZM9.71951 14.4104H12.4274V21.1802H9.71951V14.4104ZM4.30367 14.4104H7.01159V21.1802H4.30367V14.4104ZM17.8433 14.4104V17.7953L15.1354 20.2324V14.4104H17.8433ZM12.5628 22.5342L11.7505 23.2111L14.0522 25.2421H2.94971V22.5342H12.5628ZM23.2591 21.1802V25.2421H19.1972V27.95L13.7814 23.2111L19.1972 18.4723V21.1802H23.2591Z" fill="#9CA3AF" />
                                        </svg>
                                    } />
                                    <Tab label="Card" icon={
                                        // <CreditCard style={{fontSize: '20px'}} />
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24.2144 8.29813C24.2144 9.01302 23.6295 9.59793 22.9146 9.59793H3.85083C3.13594 9.59793 2.55103 9.01302 2.55103 8.29813V8.2873C2.55103 5.80684 4.55489 3.80298 7.03534 3.80298H19.7192C22.1997 3.80298 24.2144 5.81767 24.2144 8.29813Z" fill="#9CA3AF" />
                                            <path d="M2.55103 12.5227V17.9494C2.55103 20.4298 4.55489 22.4337 7.03534 22.4337H19.7192C22.1997 22.4337 24.2144 20.419 24.2144 17.9385V12.5227C24.2144 11.8078 23.6295 11.2229 22.9146 11.2229H3.85083C3.13594 11.2229 2.55103 11.8078 2.55103 12.5227ZM9.05004 18.8051H6.8837C6.4396 18.8051 6.07132 18.4368 6.07132 17.9927C6.07132 17.5486 6.4396 17.1803 6.8837 17.1803H9.05004C9.49414 17.1803 9.86241 17.5486 9.86241 17.9927C9.86241 18.4368 9.49414 18.8051 9.05004 18.8051ZM16.0906 18.8051H11.758C11.3139 18.8051 10.9456 18.4368 10.9456 17.9927C10.9456 17.5486 11.3139 17.1803 11.758 17.1803H16.0906C16.5347 17.1803 16.903 17.5486 16.903 17.9927C16.903 18.4368 16.5347 18.8051 16.0906 18.8051Z" fill="#9CA3AF" />
                                        </svg>
                                    } />
                                    <Tab label="USSD" icon={
                                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.8707 18.8033L11.8813 0.453125H15.1069L12.0963 18.8033H8.8707ZM0.690186 14.4308L1.23675 11.2052H15.7878L15.2413 14.4308H0.690186ZM2.41947 18.8033L5.43004 0.453125H8.65566L5.64508 18.8033H2.41947ZM1.73851 8.05123L2.28507 4.82562H16.8362L16.2896 8.05123H1.73851Z" fill="#9CA3AF" />
                                        </svg>
                                    } />
                                </Tabs>
                            </div>

                            <TabPanel value={value} index={0}>
                                <div className="w-100 mt-4">
                                    <p className='text-center mx-auto py-2' style={{ width: '23em', maxWidth: '100%' }}>
                                        <span className='fw-600 text-center'>Please proceed to your mobile banking app to complete
                                            your bank transfer </span>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center py-3 border-bottom">
                                        <span className='fw-500 fs-14 text-dark'>Amount</span>
                                        <span className='fw-500 fs-14 text-dark'>{initiatePLResponseObj ? initiatePLResponseObj.paymentInfo.invoice.currency : ''} {initiatePLResponseObj ? initiatePLResponseObj.paymentInfo.invoice.amount.toLocaleString() : '0'}.00</span>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center py-3 border-bottom">
                                        <span className='fw-500 fs-14 text-dark'>Account Number</span>
                                        <span className='fw-500 fs-14 text-dark d-block d-flex align-items-center'>
                                            {InitiateTransferObject ? InitiateTransferObject.accountNumber : '---'}
                                            <span className='ml-1 p-1 cursor-pointer'
                                                onClick={() => {
                                                    navigator.clipboard.writeText(InitiateTransferObject ? InitiateTransferObject.accountNumber : '');
                                                    notifySuccess('Account Number Copied')
                                                }}>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.5503 8.55322V11.1782C10.5503 13.3657 9.67529 14.2407 7.48779 14.2407H4.86279C2.67529 14.2407 1.80029 13.3657 1.80029 11.1782V8.55322C1.80029 6.36572 2.67529 5.49072 4.86279 5.49072H7.48779C9.67529 5.49072 10.5503 6.36572 10.5503 8.55322Z" stroke="#5D20D2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10.5503 8.55322V11.1782C10.5503 13.3657 9.67529 14.2407 7.48779 14.2407H4.86279C2.67529 14.2407 1.80029 13.3657 1.80029 11.1782V8.55322C1.80029 6.36572 2.67529 5.49072 4.86279 5.49072H7.48779C9.67529 5.49072 10.5503 6.36572 10.5503 8.55322Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10.5503 8.55322V11.1782C10.5503 13.3657 9.67529 14.2407 7.48779 14.2407H4.86279C2.67529 14.2407 1.80029 13.3657 1.80029 11.1782V8.55322C1.80029 6.36572 2.67529 5.49072 4.86279 5.49072H7.48779C9.67529 5.49072 10.5503 6.36572 10.5503 8.55322Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10.5503 8.55322V11.1782C10.5503 13.3657 9.67529 14.2407 7.48779 14.2407H4.86279C2.67529 14.2407 1.80029 13.3657 1.80029 11.1782V8.55322C1.80029 6.36572 2.67529 5.49072 4.86279 5.49072H7.48779C9.67529 5.49072 10.5503 6.36572 10.5503 8.55322Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14.2998 4.80322V7.42822C14.2998 9.61572 13.4248 10.4907 11.2373 10.4907H10.5498V8.55322C10.5498 6.36572 9.6748 5.49072 7.4873 5.49072H5.5498V4.80322C5.5498 2.61572 6.4248 1.74072 8.6123 1.74072H11.2373C13.4248 1.74072 14.2998 2.61572 14.2998 4.80322Z" stroke="#5D20D2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14.2998 4.80322V7.42822C14.2998 9.61572 13.4248 10.4907 11.2373 10.4907H10.5498V8.55322C10.5498 6.36572 9.6748 5.49072 7.4873 5.49072H5.5498V4.80322C5.5498 2.61572 6.4248 1.74072 8.6123 1.74072H11.2373C13.4248 1.74072 14.2998 2.61572 14.2998 4.80322Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14.2998 4.80322V7.42822C14.2998 9.61572 13.4248 10.4907 11.2373 10.4907H10.5498V8.55322C10.5498 6.36572 9.6748 5.49072 7.4873 5.49072H5.5498V4.80322C5.5498 2.61572 6.4248 1.74072 8.6123 1.74072H11.2373C13.4248 1.74072 14.2998 2.61572 14.2998 4.80322Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14.2998 4.80322V7.42822C14.2998 9.61572 13.4248 10.4907 11.2373 10.4907H10.5498V8.55322C10.5498 6.36572 9.6748 5.49072 7.4873 5.49072H5.5498V4.80322C5.5498 2.61572 6.4248 1.74072 8.6123 1.74072H11.2373C13.4248 1.74072 14.2998 2.61572 14.2998 4.80322Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>

                                            </span>
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center py-3 border-bottom">
                                        <span className='fw-500 fs-14 text-dark'>Bank Name</span>
                                        <span className='fw-500 fs-14 text-dark'>
                                            {InitiateTransferObject ? InitiateTransferObject.bankName : '---'}
                                        </span>
                                    </div>
                                    <div className='py-4 text-center'>
                                    {timeLeft > 0 &&
                                        <p className="mb-0">
                                            <span className='fw-600'> Expires in <span className='text-dark'>{formatTime(timeLeft)}</span></span>
                                        </p>
                                    }
                                    </div>
                                    <button
                                        color="inherit"
                                        className='d-block w-100 btn btn-primary py-3 fw-500'
                                        onClick={() => handleVerifyPayment('clicked')}
                                    >
                                        I have made the payment
                                    </button>
                                    {/* <Typography variant="h6">Select Bank</Typography>
                        <TextField select fullWidth variant="outlined" sx={{ my: 2 }}>
                            <MenuItem value="bank1">Bank 1</MenuItem>
                            <MenuItem value="bank2">Bank 2</MenuItem>
                            <MenuItem value="bank3">Bank 3</MenuItem>
                        </TextField>
                        <Typography variant="h6">Dial Code</Typography>
                        <TextField fullWidth variant="outlined" value="*784*2*100*7482968995" InputProps={{ readOnly: true }} />
                        <button fullWidth sx={{ mt: 2 }} onClick={() => navigator.clipboard.writeText('*784*2*100*7482968995')}>Click to copy</button> */}
                                    {/* <button fullWidth variant="contained" color="primary" sx={{ mt: 2 }}>I have made the payment</button> */}
                                </div>
                            </TabPanel>

                            <TabPanel value={value} index={1}>
                                {/* <Typography variant="h6">Card Payment</Typography> */}
                                {/* Add card payment form here */}
                                <div className="w-100 mt-4">
                                    <p className='text-center mx-auto pt-2' style={{ width: '23em', maxWidth: '100%' }}>
                                        <span className='fw-600 text-center'>Enter your card details to proceed with payment</span>
                                    </p>
                                    <div className="mb-3 pt-3 w-100 height_40 position-relative">
                                        <label htmlFor='card_number' className="mb-2 fs-13 fw-500 text-dark">Card Number</label>
                                        <div className="">
                                            <TextField
                                                name="card_number"
                                                required
                                                type="text"
                                                id="card_number"
                                                size="small"
                                                placeholder=""
                                                className="rounded-0"
                                                fullWidth
                                                value={cardNumber}
                                                onChange={handleCardNumberChange}
                                                inputProps={{ maxLength: 24 }}
                                            />
                                        </div>
                                    </div>

                                    <div className="d-flex w-100">
                                        <div className="mb-3 w-100 height_40 pl-1 pr-sm-2">
                                            <label htmlFor='card_expiry' className="mb-2 fs-13 fw-500 text-dark">Card Expiry</label>
                                            <div className=" position-relative balance_as_at">
                                                <TextField
                                                    name="card_expiry"
                                                    required
                                                    type="text"
                                                    id="card_expiry"
                                                    size="small"
                                                    placeholder="MM/YY"
                                                    className="rounded-0"
                                                    fullWidth
                                                    value={cardExpiry}
                                                    onChange={handleCardExpiryChange}
                                                    inputProps={{ maxLength: 5 }}
                                                />
                                            </div>
                                        </div>

                                        {/* <div className=" mb-3 w-100 height_40 pl-1 pr-sm-2">
                                        <label htmlFor="card_expiry" className="mb-2 fs-13 fw-500 text-dark">Card Expiry</label>
                                        <div className='card_expiry position-relative'>
                                            <DatePicker
                                                selected={cardExpiry}
                                                onChange={handleCardExpiryChange}
                                                dateFormat="MM/yyyy" // Use full year for selection
                                                showMonthYearPicker
                                                className="form-control w-100"
                                                placeholderText="MM/YY"
                                                required
                                                minDate={new Date()}
                                            />
                                            <TextField
                                                name="formatted_card_expiry"
                                                id="formatted_card_expiry"
                                                size="small"
                                                placeholder="MM/YY"
                                                className="rounded-0 mt-0"
                                                fullWidth
                                                value={cardExpiry ? format(cardExpiry, 'MM/yy') : ''}
                                                readOnly
                                            />
                                        </div>
                                    </div> */}

                                        <div className="mb-3 w-100 height_40 position-relative pl-1 pl-sm-3">
                                            <label htmlFor='card_cvv' className="mb-2 fs-13 fw-500 text-dark">CVV</label>
                                            <div className="">
                                                <TextField
                                                    name="card_cvv"
                                                    required
                                                    type="text"
                                                    id="card_cvv"
                                                    size="small"
                                                    placeholder="576"
                                                    className="rounded-0"
                                                    fullWidth
                                                    value={cardCVV}
                                                    onChange={handleCardCVVChange}
                                                    inputProps={{ maxLength: 3 }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 pt-3 w-100 height_40 position-relative">
                                        <label htmlFor='card_pin' className="mb-2 fs-13 fw-500 text-dark">Card Pin</label>
                                        <div className="">
                                            <TextField
                                                name="card_pin"
                                                required
                                                type="password"
                                                id="card_pin"
                                                size="small"
                                                placeholder=""
                                                className="rounded-0"
                                                fullWidth
                                                value={cardPin}
                                                onChange={handleCardPinChange}
                                                inputProps={{ maxLength: 4 }}
                                            />
                                        </div>
                                    </div>
                                    <div className='pt-4 pb-3'>

                                        <div className="checkbox_div pt-2 pb-1 fit-content mx-auto">
                                            <Checkbox id="schedule" checked={save} onClick={handleChangeSave} style={{}} />
                                            <label htmlFor="schedule" className="ml-2 fs-13 cursor-pointer fw-600">Remember this card next time </label>
                                        </div>
                                    </div>
                                    <button
                                        color="inherit"
                                        className='d-block w-100 btn btn-primary py-3 fw-500'
                                        disabled={isCardPaymentLoading}
                                        onClick={() => handleInitiatePayment('card-payment')}
                                    >
                                        {!isCardPaymentLoading &&
                                            <>
                                                Pay Now {initiatePLResponseObj ? initiatePLResponseObj.paymentInfo.invoice.currency !== "NGN" ? '¥' : '₦' : ''}{initiatePLResponseObj ? initiatePLResponseObj.paymentInfo.invoice.amount.toLocaleString() : '0'}.00
                                            </>
                                        }
                                        {
                                            isCardPaymentLoading &&
                                            <>
                                                Authorizing...
                                                <CircularProgress
                                                    style={{ color: "#222222" }}
                                                    className="text-white ml-1"
                                                    size="0.8rem"
                                                />
                                            </>
                                        }
                                        {/* I have made the payment */}
                                    </button>
                                </div>
                            </TabPanel>

                            <TabPanel value={value} index={2}>
                                <div className="mt-5 pt-4 w-100">
                                    <div className="mb-3">
                                        <label htmlFor='card_cvv' className="mb-2 fs-13 fw-500 text-dark">Bank</label>
                                        <div className="" style={{ width: "100%" }}>
                                            <div className="position-relative height_40">
                                                <LiveSelect
                                                    placeholder={`Select Bank...`}
                                                    defaultValue={selectedBankOption}
                                                    onChange={handleBankChange}
                                                    options={banks?.map((option) => ({
                                                        value: option.code,
                                                        label: option.name,
                                                    }))}
                                                />
                                            </div>
                                            <div className='fit-content ml-auto'>
                                            <button onClick={handleInitiatePayment} className='btn pr-0'>Get USSD Code</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-4 pb-4 w-100 height_40 dial_code">
                                        <label htmlFor='dial_code' className="mb-2 fs-13 fw-500 d-block text-center text-dark">Dial Code</label>
                                        <div className="position-relative">
                                            <TextField
                                                name="dial_code"
                                                required
                                                type="text"
                                                id="dial_code"
                                                size="small"
                                                placeholder=""
                                                className="rounded-0 text-dark text-center"
                                                fullWidth
                                                value={DialCode}
                                                onChange={(e) => setDialCode(e.target.value)}
                                                InputProps={{ readOnly: true, disabled: true }}
                                            />
                                            {DialCode !== null && DialCode !== '' &&
                                            <span
                                                className="ml-1 px-2 py-1 cursor-pointer"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(DialCode);
                                                    notifySuccess('USSD Copied')
                                                }}
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    right: '70px',
                                                    transform: 'translate(-50%, -50%)'
                                                }}>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.5002 8.70996V11.335C10.5002 13.5225 9.62524 14.3975 7.43774 14.3975H4.81274C2.62524 14.3975 1.75024 13.5225 1.75024 11.335V8.70996C1.75024 6.52246 2.62524 5.64746 4.81274 5.64746H7.43774C9.62524 5.64746 10.5002 6.52246 10.5002 8.70996Z" stroke="#5D20D2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10.5002 8.70996V11.335C10.5002 13.5225 9.62524 14.3975 7.43774 14.3975H4.81274C2.62524 14.3975 1.75024 13.5225 1.75024 11.335V8.70996C1.75024 6.52246 2.62524 5.64746 4.81274 5.64746H7.43774C9.62524 5.64746 10.5002 6.52246 10.5002 8.70996Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10.5002 8.70996V11.335C10.5002 13.5225 9.62524 14.3975 7.43774 14.3975H4.81274C2.62524 14.3975 1.75024 13.5225 1.75024 11.335V8.70996C1.75024 6.52246 2.62524 5.64746 4.81274 5.64746H7.43774C9.62524 5.64746 10.5002 6.52246 10.5002 8.70996Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10.5002 8.70996V11.335C10.5002 13.5225 9.62524 14.3975 7.43774 14.3975H4.81274C2.62524 14.3975 1.75024 13.5225 1.75024 11.335V8.70996C1.75024 6.52246 2.62524 5.64746 4.81274 5.64746H7.43774C9.62524 5.64746 10.5002 6.52246 10.5002 8.70996Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14.25 4.95996V7.58496C14.25 9.77246 13.375 10.6475 11.1875 10.6475H10.5V8.70996C10.5 6.52246 9.625 5.64746 7.4375 5.64746H5.5V4.95996C5.5 2.77246 6.375 1.89746 8.5625 1.89746H11.1875C13.375 1.89746 14.25 2.77246 14.25 4.95996Z" stroke="#5D20D2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14.25 4.95996V7.58496C14.25 9.77246 13.375 10.6475 11.1875 10.6475H10.5V8.70996C10.5 6.52246 9.625 5.64746 7.4375 5.64746H5.5V4.95996C5.5 2.77246 6.375 1.89746 8.5625 1.89746H11.1875C13.375 1.89746 14.25 2.77246 14.25 4.95996Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14.25 4.95996V7.58496C14.25 9.77246 13.375 10.6475 11.1875 10.6475H10.5V8.70996C10.5 6.52246 9.625 5.64746 7.4375 5.64746H5.5V4.95996C5.5 2.77246 6.375 1.89746 8.5625 1.89746H11.1875C13.375 1.89746 14.25 2.77246 14.25 4.95996Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14.25 4.95996V7.58496C14.25 9.77246 13.375 10.6475 11.1875 10.6475H10.5V8.70996C10.5 6.52246 9.625 5.64746 7.4375 5.64746H5.5V4.95996C5.5 2.77246 6.375 1.89746 8.5625 1.89746H11.1875C13.375 1.89746 14.25 2.77246 14.25 4.95996Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                            }
                                        </div>
                                    </div>

                                    {/* <button
                                    className='d-block btn fit-content mx-auto mb-4 fw-600 fs-12'
                                    onClick={() => navigator.clipboard.writeText(DialCode)}
                                >
                                    Click to copy
                                </button> */}

                                    <button
                                        color="inherit"
                                        className='d-block w-100 btn btn-primary py-3 mt-4 fw-500'
                                        onClick={() => handleVerifyPayment('clicked')}
                                    >
                                        I have made the payment
                                    </button>
                                </div>
                                {/* <Typography variant="h6">USSD Payment</Typography>
                        <TextField select fullWidth variant="outlined" sx={{ my: 2 }}>
                            <MenuItem value="bank1">Bank 1</MenuItem>
                            <MenuItem value="bank2">Bank 2</MenuItem>
                            <MenuItem value="bank3">Bank 3</MenuItem>
                        </TextField>
                        <Typography variant="h6">Dial Code</Typography>
                        <TextField fullWidth variant="outlined"/>
                        <button fullWidth sx={{ mt: 2 }} onClick={() => navigator.clipboard.writeText('*784*2*100*7482968995')}>Click to copy</button>
                        <button fullWidth variant="contained" color="primary" sx={{ mt: 2 }}>I have made the payment</button> */}
                            </TabPanel>
                        </div>
                    )}
                    {openCardOTP &&
                        <div className='px-4 py-5 payment_checkout_wrapper'>
                            <div className='d-flex flex-column justify-content-start align-item-center pt-5 h-100'>
                                <div>
                                    <h4>Verify Payment</h4>
                                </div>
                                <div className="mb-3 pt-3 w-100 height_40 position-relative">
                                    <label htmlFor='card_otp' className="mb-2 fs-13 fw-500 text-dark">Enter OTP</label>
                                    <div className="">
                                        <TextField
                                            name="card_otp"
                                            required
                                            type="number"
                                            id="card_otp"
                                            size="small"
                                            placeholder=""
                                            className="rounded-0"
                                            fullWidth
                                            value={cardOTP}
                                            onChange={handleSetCardOTP}
                                            // inputProps={{ maxLength: 4 }}
                                        />
                                    </div>
                                    <small className='text-danger'>{cardError}</small>
                                </div>
                                <button
                                    color="inherit"
                                    className='d-block w-100 btn btn-primary py-3 fw-500'
                                    disabled={isCardPaymentLoading || cardOTP === ''}
                                    onClick={() => handleAuthorizeCardPayment()}
                                >
                                    {!isCardPaymentLoading &&
                                        <>
                                            Continue 
                                        </>
                                    }
                                    {
                                        isCardPaymentLoading &&
                                        <>
                                            Authorizing...
                                            <CircularProgress
                                                style={{ color: "#222222" }}
                                                className="text-white ml-1"
                                                size="0.8rem"
                                            />
                                        </>
                                    }
                                </button>
                            </div>
                        </div>
                    }
                </Box>
            )}
        </div>



        <GlobalModal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={CancelTrxn}
                    onClose={handleCloseCBDialog}
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                width="420px !important"
                maxWidth="100%"
                overflowY="scroll"
                bgcolor="#FFFF"
                border="1px solid #F5F5F5"
                borderRadius="5px"
                boxShadow="24"
                p="25px"
            >
                <span
                    className="px-3 py-2"
                    style={{
                        fontSize: "25px",
                        cursor: "pointer",
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                    }}
                    onClick={() => setCancelTrxn(false)}
                >
                    &times;
                </span>
                <div
                    className={`modal_content`}
                    style={{ overflowY: 'scroll', height: 'auto', }}
                >
                   <div className=" position-relative text-center"
                        sx={{ width: '100%' }}>
                        <div className="fit-content mb-4 mx-auto cursor-pointer">
                        <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.843506" width="75.1601" height="75.1601" rx="37.5801" fill="#FEE2E2"/>
<path d="M30.0225 28.6163L48.8297 48.2348" stroke="#DC2626" stroke-width="3.39714" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M29.6168 47.8294L49.2354 29.0222" stroke="#DC2626" stroke-width="3.39714" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                        </div>

                        <p className='fs-20 fw-500 text-dark mb-0'>Cancel Payment?</p>
                        <p className="mb-0 pt-2">
                            <span className='fs-14'>Do you want to cancel your payment?</span>
                        </p>
                        <div className="fit-content mx-auto mt-3">
                                    <button
                                        className="btn border btn-primary d-block"
                                        onClick={handleCancelTrf}
                                        style={{ padding: '8px 20px' }}
                                    >
                                        <span className="text-white">Confirm</span>
                                    </button>
                                </div>
                    </div>
                </div>
            </GlobalModal>
        </>
    );
}
