import React, { useState, useEffect } from 'react';
import styles from './landpage.module.css';
import { useLanguage } from 'src/theme/languageContext';
import { languageData } from 'src/utils/languageData';

const CustomSelect = ({ func }) => {
  const [isFixed, setFixed] = useState(false);

  const { language, changeLanguage } = useLanguage();

  const [isOpen, setIsOpen] = useState(false);

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'zh', name: 'Mandarin' },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(
    languageData?.[`${language}`] || 'English'
  );

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectLanguage = (language) => {
    setSelectedLanguage(language.name);
    setIsOpen(false);

    changeLanguage(language.code);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px 9px',
          cursor: 'pointer',
          border: '1px solid #ccc',
          borderRadius: '4px',
          width: '150px',
          backgroundColor: '#fff',
        }}
        onClick={toggleDropdown}
      >
        <img
          src="/assets/images/translate.png"
          style={{ marginRight: '8px' }}
          alt="translate icon"
        />
        <span>{selectedLanguage}</span>
        <img
          src="/assets/images/arrow-down.png"
          style={{ marginLeft: 'auto' }}
          alt="arrow down"
        />
      </div>

      {isOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            padding: '5px 9px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            width: '150px',
            backgroundColor: '#f9f9f9',
            zIndex: 1,
          }}
        >
          {languages.map((language) => (
            <div
              key={language.code}
              style={{ padding: '5px 0', cursor: 'pointer' }}
              onClick={() => {
                selectLanguage(language);
                func();
              }}
            >
              {language.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
