export const redDotSvg = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        id="Ellipse 1"
        cx="6"
        cy="6"
        r="4.5"
        fill="#D92D20"
        stroke="#FECDCA"
        strokeWidth="3"
      />
    </svg>
  );
};

export const arrowRightSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Arrow right">
        <path
          id="Icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.23429 2.63431C8.54671 2.32189 9.05324 2.32189 9.36566 2.63431L14.1657 7.43431C14.4781 7.74673 14.4781 8.25326 14.1657 8.56568L9.36566 13.3657C9.05324 13.6781 8.54671 13.6781 8.23429 13.3657C7.92187 13.0533 7.92187 12.5467 8.23429 12.2343L11.6686 8.79999L2.39998 8.79999C1.95815 8.79999 1.59998 8.44182 1.59998 7.99999C1.59998 7.55817 1.95815 7.19999 2.39998 7.19999H11.6686L8.23429 3.76568C7.92187 3.45326 7.92187 2.94673 8.23429 2.63431Z"
          fill="#344054"
        />
      </g>
    </svg>
  );
};

export const circleArrowRightSvg = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_688_530)">
        <rect
          x="0.543152"
          y="20.4564"
          width="40"
          height="40"
          rx="20"
          transform="rotate(-30 0.543152 20.4564)"
          fill="#0C111D"
        />
        <path
          d="M20.778 22.3549L29.4708 20.0256M29.4708 20.0256L31.8001 28.7185M29.4708 20.0256L21.289 34.197"
          stroke="white"
          strokeWidth="1.81818"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_688_530">
          <rect
            x="0.543152"
            y="20.4564"
            width="40"
            height="40"
            rx="20"
            transform="rotate(-30 0.543152 20.4564)"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const arrowIconSvg = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.57456 1.57959L15.2674 3.90884M15.2674 3.90884L12.9382 12.6017M15.2674 3.90884L1.09612 12.0907"
        stroke="white"
        strokeWidth="1.81818"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const arrowFaceSideIconSvg = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.3903 1.53199L13.3841 1.85288M13.3841 1.85288L13.0632 10.8467M13.3841 1.85288L1.40807 13.0038"
        stroke="white"
        stroke-width="1.81818"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const roundShieldIconSvg = () => {
  return (
    <svg
      width="69"
      height="69"
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.407104"
        width="68.473"
        height="68.473"
        rx="34.2365"
        fill="#1570EF"
      />
      <path
        d="M47.9505 18.835L37.1797 14.8009C36.0635 14.3896 34.2422 14.3896 33.126 14.8009L22.3551 18.835C20.2793 19.6184 18.5951 22.0467 18.5951 24.2596V40.1221C18.5951 41.7084 19.633 43.8038 20.906 44.7438L31.6768 52.7925C33.5764 54.2221 36.6901 54.2221 38.5897 52.7925L49.3605 44.7438C50.6335 43.7842 51.6714 41.7084 51.6714 40.1221V24.2596C51.691 22.0467 50.0068 19.6184 47.9505 18.835ZM41.958 29.8017L33.5372 38.2225C33.2435 38.5163 32.8714 38.6534 32.4993 38.6534C32.1272 38.6534 31.7551 38.5163 31.4614 38.2225L28.328 35.05C27.7601 34.4821 27.7601 33.5421 28.328 32.9742C28.896 32.4063 29.836 32.4063 30.4039 32.9742L32.5189 35.0892L39.9018 27.7063C40.4697 27.1384 41.4097 27.1384 41.9776 27.7063C42.5455 28.2742 42.5455 29.2338 41.958 29.8017Z"
        fill="white"
      />
    </svg>
  );
};

export const emailSvg = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="12" fill="#D0D5DD" />
      <path
        d="M15 20L22.8906 25.2604C23.5624 25.7083 24.4376 25.7083 25.1094 25.2604L33 20M17 31H31C32.1046 31 33 30.1046 33 29V19C33 17.8954 32.1046 17 31 17H17C15.8954 17 15 17.8954 15 19V29C15 30.1046 15.8954 31 17 31Z"
        stroke="#101828"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const phoneSvg = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="12" fill="#D0D5DD" />
      <path
        d="M15 17C15 15.8954 15.8954 15 17 15H20.2792C20.7097 15 21.0918 15.2754 21.2279 15.6838L22.7257 20.1772C22.8831 20.6493 22.6694 21.1653 22.2243 21.3879L19.967 22.5165C21.0692 24.9612 23.0388 26.9308 25.4835 28.033L26.6121 25.7757C26.8347 25.3306 27.3507 25.1169 27.8228 25.2743L32.3162 26.7721C32.7246 26.9082 33 27.2903 33 27.7208V31C33 32.1046 32.1046 33 31 33H30C21.7157 33 15 26.2843 15 18V17Z"
        stroke="#111827"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const redDotIconSvg = () => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill="#F87171" />
    </svg>
  );
};
