import { Helmet } from "react-helmet-async";
// import * as Router from 'react-router-dom';
// import Scrollbar from "../../../components/scrollbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import DatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import TextField from "@mui/material/TextField";
import { Link as RouterLink } from "react-router-dom";
import Scrollbar from "../../components/scrollbar";

import {
    Link,
    Checkbox,

} from "@mui/material";
import {
    // useEffect, 
    useState
} from "react";
// import bus from "src/bus";
import GlobalModal from "../../Modals/GlobalModal";
import styles from "./LedgerTable.module.css";
// ----------------------------------------------------------------------

export default function AddJournalEntries() {
    const [addCustomer, setAddCustomer] = useState(false);
    const [dueDate, setDueDate] = useState(null);
    const [save, setSave] = useState(false);
    const [existingBen, setExistingBen] = useState(false);
    const [status01, setStatus01] = useState(false);
    const [status02, setStatus02] = useState(false);
    const [status03, setStatus03] = useState(false);

    const handleChangeStatus01 = () => {
        setStatus01(!status01)
    }
    const handleChangeStatus02 = () => {
        setStatus02(!status02)
    }
    const handleChangeStatus03 = () => {
        setStatus03(!status03)
    }
    const handleCloseExistingBen = () => {
        setExistingBen(false);
    };
    const handleChangeSave = () => {
        setSave(!save)
    }

    const handleCloseAddCustomer = () => {
        setAddCustomer(false);
    };


    return (
        <>
            <Helmet>
                <title>Ledger Management - Add Entries</title>
            </Helmet>
            <div className="wrapper mt-2 mt-md-5 pt-sm-3">
                <div className={`${styles.header_wrap} d-flex justify-content-between align-items-center`}>
                    <div className="d-sm-flex justify-content-start align-items-center">
                        <Link
                            className="d-block text-secondary"
                            to="/app/ledger-management/journal-entries"
                            component={RouterLink}
                        >
                            <i
                                className="bi bi-x"
                                style={{ fontSize: "1.5em", lineHeight: "1" }}
                            ></i>
                        </Link>
                        <div className="pl-0 pl-sm-3 pt-3 pt-sm-0">
                            <h6 className="mb-2" style={{ fontWeight: "bold" }}>Add Journal Entries</h6>
                            <p className="mb-0"><small><span className="font-weight-bold">Note:</span> All balances are in your region currency</small></p>
                        </div>
                    </div>
                </div>

                <div className={`${styles.trftobank_side_wrap} mt-sm-4 px-sm-3 px-md-4 pt-4 py-sm-4`}>

                    <div className={`py-4`}>
                        <div className="row">
                            <div className="col-md-4 mb-3 mb-md-0 pb-0">
                                <div className="">
                                    <label htmlFor="ounit">Enter Description*</label>
                                    <div className="" style={{ width: "100%" }}>
                                        <div className="">
                                            <TextField
                                                name=""
                                                required
                                                type="text"
                                                id="ounit"
                                                size="small"
                                                placeholder=""
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 mb-3 mb-md-0 pb-0">
                                <div className="">
                                    <label htmlFor="ounit">Date*</label>
                                    <div className="" style={{ width: "fit-content" }}>
                                        <div
                                            style={{ position: "relative" }}
                                        >
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    right: "10px",
                                                    top: "5px",
                                                    zIndex: "10",
                                                }}
                                            >
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.66602 1.6665V4.1665" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M13.334 1.6665V4.1665" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M2.91602 7.5752H17.0827" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M17.5 7.08317V14.1665C17.5 16.6665 16.25 18.3332 13.3333 18.3332H6.66667C3.75 18.3332 2.5 16.6665 2.5 14.1665V7.08317C2.5 4.58317 3.75 2.9165 6.66667 2.9165H13.3333C16.25 2.9165 17.5 4.58317 17.5 7.08317Z" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M13.0781 11.4167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M13.0781 13.9167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M9.99607 11.4167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M9.99607 13.9167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M6.91209 11.4167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M6.91209 13.9167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>

                                            </span>
                                            <DatePicker
                                                // showIcon
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={15}
                                                selected={dueDate}
                                                onChange={(date) => setDueDate(date)}
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control w-100"
                                                placeholderText=""
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>







                <div>
                <Scrollbar>
                <div className="pb-2 px-sm-3 px-md-4 border-bottom" style={{minWidth: '45em'}}>
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="">Account</label>
                        </div>
                        <div className="col-4">
                            <label htmlFor="">Description</label>
                        </div>
                        <div className="col-2">
                            <label htmlFor="">Debit</label>
                        </div>
                        <div className="col-2">
                            <label htmlFor="">Credit</label>
                        </div>
                    </div>
                </div>
                <div className={`py-4 px-sm-3 px-md-4 `} style={{minWidth: '45em'}}>
                    <div className="row mb-4">
                        <div className="col-4 mb-0 pb-0">
                            <div className="">
                                <div className="" style={{ width: "100%" }}>
                                    <div className="">
                                        <Select
                                            labelId="s"
                                            id="s"
                                            fullWidth
                                            size="small"
                                            required
                                            name="verified"
                                            defaultValue=""
                                        >
                                            <MenuItem value="operation">Operations - $1,100</MenuItem>
                                            <MenuItem value="banking">Banking - $1,100</MenuItem>
                                            <MenuItem value="finance">Finance - $1,100</MenuItem>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-4 mb-0 pb-0">
                            <div className="">
                                <div className="" style={{ width: "100%" }}>
                                    <div className="">
                                        <TextField
                                            name=""
                                            required
                                            type="text"
                                            id="ounit"
                                            size="small"
                                            placeholder=""
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-2 mb-0 pb-0">
                            <div className="">
                                <div className="position-relative box_with_dollarsign">
                                    <span className="position-absolute" style={{
                                        zIndex: '10',
                                        top: '50%',
                                        left: '15px',
                                        transform: 'translateY(-50%)'
                                    }}>$</span>
                                    <TextField
                                        name="min_products"
                                        required
                                        type="number"
                                        fullWidth
                                        id="ounit"
                                        placeholder=""
                                        size="small"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-2 mb-0 pb-0">
                            <div className="">
                                <div className="position-relative box_with_dollarsign">
                                    <span className="position-absolute" style={{
                                        zIndex: '10',
                                        top: '50%',
                                        left: '15px',
                                        transform: 'translateY(-50%)'
                                    }}>$</span>
                                    <TextField
                                        name="min_products"
                                        required
                                        type="number"
                                        fullWidth
                                        id="ounit"
                                        placeholder=""
                                        size="small"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 mb-0 pb-0">
                            <div className="">
                                <div className="" style={{ width: "100%" }}>
                                    <div className="">
                                        <Select
                                            labelId="s"
                                            id="s"
                                            fullWidth
                                            size="small"
                                            required
                                            name="verified"
                                            defaultValue=""
                                        >
                                            <MenuItem value="operation">Operations - $1,100</MenuItem>
                                            <MenuItem value="banking">Banking - $1,100</MenuItem>
                                            <MenuItem value="finance">Finance - $1,100</MenuItem>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-4 mb-0 pb-0">
                            <div className="">
                                <div className="" style={{ width: "100%" }}>
                                    <div className="">
                                        <TextField
                                            name=""
                                            required
                                            type="text"
                                            id="ounit"
                                            size="small"
                                            placeholder=""
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-2 mb-0 pb-0">
                            <div className="">
                                <div className="position-relative box_with_dollarsign">
                                    <span className="position-absolute" style={{
                                        zIndex: '10',
                                        top: '50%',
                                        left: '15px',
                                        transform: 'translateY(-50%)'
                                    }}>$</span>
                                    <TextField
                                        name="min_products"
                                        required
                                        type="number"
                                        fullWidth
                                        id="ounit"
                                        placeholder=""
                                        size="small"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-2 mb-0 pb-0">
                            <div className="">
                                <div className="position-relative box_with_dollarsign">
                                    <span className="position-absolute" style={{
                                        zIndex: '10',
                                        top: '50%',
                                        left: '15px',
                                        transform: 'translateY(-50%)'
                                    }}>$</span>
                                    <TextField
                                        name="min_products"
                                        required
                                        type="number"
                                        fullWidth
                                        id="ounit"
                                        placeholder=""
                                        size="small"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                </Scrollbar>
                    <div>
                        <button className="btn px-0 mt-2" onClick={() => setAddCustomer(true)}>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 12.5H18" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 18.5V6.5" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span className="ml-1 text-primary">Add a line</span>
                        </button>
                    </div>
                </div>                                


                    <div className="ml-auto" style={{width: 'fit-content'}}>
                        <div className="btn-group">
                            <Link
                                className="btn border btn-primary"
                                to="/app/ledger-management/journal-entries"
                                component={RouterLink}
                            >
                                <span className="button_text text-white">Save</span>
                            </Link>
                            <Link
                                className="btn border ml-2 ml-sm-3"
                                to="/app/ledger-management/journal-entries"
                                component={RouterLink}
                            >
                                <span className="button_text">Cancel</span>
                            </Link>
                        </div>
                    </div>
            </div>
        </>
    );
}
