// ----------------------------------------------------------------------
import styles from "./LedgerTable.module.css";
import {
    // useEffect,
    useState,
} from "react";
import React from "react";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import PaymentLinkTable from "./TransactionsTable";

import "react-datepicker/dist/react-datepicker.css";

import { Link as RouterLink } from "react-router-dom";

import {
    Link,
    //   Avatar,
} from "@mui/material";
// import Scrollbar from "../../components/scrollbar";
// sections
// mock
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Helmet } from "react-helmet-async";
import GlobalModal from "../../Modals/GlobalModal";

// ----------------------------------------------------------------------

export default function JournalEntries() {
    // eslint-disable-next-line no-unused-vars
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [createAccounts, setCreateAccounts] = useState(false);
    const [downloadTB, setDownloadTB] = useState(false);

    const handleCloseCreateAccounts = () => {
        setCreateAccounts(false);
    };

    const handleCloseDownloadTB = () => {
        setDownloadTB(false);
    };



    const handleToggleDropdown = () => {
        setToggleDropdown(!toggleDropdown);
    };


    return (
        <>
            <Helmet>
                <title>Ledger Management - Journal Entries</title>
            </Helmet>
            <div className="px-0 px-md-2 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="">
                        <h5 className="page_title">Ledger Management</h5>
                    </div>
                    <div className="header_btn_group">
                        <div className="btn-group">
                            <Link
                                className="btn border btn-primary"
                                type="button"
                                to="/app/ledger-management/add-entries"
                                component={RouterLink}
                            >
                                <svg
                                    width="15"
                                    height="16"
                                    viewBox="0 0 15 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3.75 8H11.25"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M7.5 11.75V4.25"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>{" "}
                                <span className="button_text text-white">Add <span className="d-none d-sm-inline">Journal</span> Entries</span>
                            </Link>
                        </div>
                    </div>
                </div>


                <Box
                    style={{ padding: "0" }}
                    className="aa tab_wrapper pt-md-4"
                    sx={{ width: "100%", typography: "body1" }}
                >
                    <PaymentLinkTable />
                </Box>

            </div>




            <GlobalModal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={createAccounts}
                onClose={handleCloseCreateAccounts}
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                width="500px !important"
                maxWidth="100%"
                overflowY="scroll"
                bgcolor="#FFFF"
                border="1px solid #F5F5F5"
                borderRadius="0px"
                boxShadow="24"
                p="25px"
            >
                <div
                    className={`modal_content`}
                // style={{overflowY: 'scroll', height: '450px'}}
                >
                    <div className="mb-4 d-flex justify-content-between align-items-start pb-3 border-bottom">
                        <div>
                            <h6 className="mb-2" style={{ fontWeight: "500" }}>
                                Create Account
                            </h6>
                            <span>
                                <small>Add account to records of all tranc=sactions in separate accounts</small>
                            </span>
                        </div>
                    </div>

                    <div
                        className="mt-4 modal_content"
                        style={{ maxHeight: "70vh", overflowY: "scroll" }}
                    >
                        <div className="mb-3">
                            <label htmlFor="ounit">Account Name</label>
                            <div className="" style={{ width: "100%" }}>
                                <div className="">
                                    <TextField
                                        name="min_products"
                                        required
                                        type="text"
                                        id="ounit"
                                        size="small"
                                        placeholder=""
                                        fullWidth
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="ounit">Account Type</label>
                            <div className="" style={{ width: "100%" }}>
                                <div className="">
                                    <Select
                                        labelId="s"
                                        id="s"
                                        fullWidth
                                        size="small"
                                        required
                                        name="verified"
                                        defaultValue="select"
                                    >
                                        <MenuItem value="select">Income</MenuItem>
                                        <MenuItem value="outcome">Outcome</MenuItem>
                                        <MenuItem value="inflow">Inflow</MenuItem>
                                        <MenuItem value="outflow">Outflow</MenuItem>
                                    </Select>
                                </div>
                            </div>
                        </div>

                        <div className="mt-5">
                            <Link
                                onClick={() => setCreateAccounts(false)}
                                className="btn border btn-primary"
                            >
                                <span className="button_text text-white">Create</span>
                            </Link>
                            &nbsp; &nbsp;
                            <Link
                                className="btn border"
                                onClick={() => setCreateAccounts(false)}
                            >
                                <span className="button_text">Cancel</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </GlobalModal>



            <GlobalModal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={downloadTB}
                onClose={handleCloseDownloadTB}
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                width="400px !important"
                maxWidth="100%"
                overflowY="scroll"
                bgcolor="#FFFF"
                border="1px solid #F5F5F5"
                borderRadius="0px"
                boxShadow="24"
                p="25px"
            >
                <div
                    className={`modal_content`}
                // style={{overflowY: 'scroll', height: '450px'}}
                >
                    <div className="mb-4 d-flex justify-content-between align-items-start pb-3 border-bottom">
                        <div>
                            <h6 className="mb-2" style={{ fontWeight: "500" }}>
                                Trial Balance
                            </h6>
                            <span>
                                <small>Download accounts report</small>
                            </span>
                        </div>
                    </div>

                    <div
                        className="mt-4 modal_content"
                        style={{ maxHeight: "70vh", overflowY: "scroll" }}
                    >
                        <div className="mb-3">
                            <label htmlFor="ounit">Custom Date Range</label>
                            <div className=" d-flex">
                                <div className="" style={{ width: "100%" }}>
                                    <div
                                        className="mb-3"
                                        style={{ position: "relative" }}
                                    >
                                        <span
                                            style={{
                                                position: "absolute",
                                                right: "15px",
                                                top: "5px",
                                                zIndex: "10",
                                            }}
                                        >
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66602 1.66699V4.16699" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M13.334 1.66699V4.16699" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M2.91602 7.5752H17.0827" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M17.5 7.08366V14.167C17.5 16.667 16.25 18.3337 13.3333 18.3337H6.66667C3.75 18.3337 2.5 16.667 2.5 14.167V7.08366C2.5 4.58366 3.75 2.91699 6.66667 2.91699H13.3333C16.25 2.91699 17.5 4.58366 17.5 7.08366Z" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M13.0781 11.4167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M13.0781 13.9167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.99607 11.4167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.99607 13.9167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.91209 11.4167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.91209 13.9167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                        </span>
                                        <DatePicker
                                            // showIcon
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={15}
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control"
                                            placeholderText="Start date"
                                            required
                                        />
                                    </div>
                                </div>
                                <div
                                    className="px-2 d-flex align-items-center "
                                    style={{ height: "40px" }}
                                >
                                    <img
                                        src="/assets/icons/line.svg"
                                        style={{ width: "30px" }}
                                        alt=""
                                    />
                                </div>
                                <div className="" style={{ width: "100%" }}>
                                    <div
                                        className="mb-3"
                                        style={{ position: "relative" }}
                                    >
                                        <span
                                            style={{
                                                position: "absolute",
                                                right: "15px",
                                                top: "5px",
                                                zIndex: "10",
                                            }}
                                        >
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66602 1.66699V4.16699" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M13.334 1.66699V4.16699" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M2.91602 7.5752H17.0827" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M17.5 7.08366V14.167C17.5 16.667 16.25 18.3337 13.3333 18.3337H6.66667C3.75 18.3337 2.5 16.667 2.5 14.167V7.08366C2.5 4.58366 3.75 2.91699 6.66667 2.91699H13.3333C16.25 2.91699 17.5 4.58366 17.5 7.08366Z" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M13.0781 11.4167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M13.0781 13.9167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.99607 11.4167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.99607 13.9167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.91209 11.4167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.91209 13.9167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                        </span>
                                        <DatePicker
                                            // showIcon
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={15}
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control"
                                            placeholderText="End date"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4">
                            <Link
                                onClick={() => setDownloadTB(false)}
                                className="btn border btn-primary"
                            >
                                <span className="button_text text-white">Download</span>
                            </Link>
                            &nbsp; &nbsp;
                            <Link
                                className="btn border"
                                onClick={() => setDownloadTB(false)}
                            >
                                <span className="button_text">Cancel</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </GlobalModal>
        </>
    );
}
