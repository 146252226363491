// src/components/TaskCard.jsx
import React, { useEffect, useState } from 'react';
import { Card, CardContent, Box, Typography, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { notifyError } from 'src/hooks/useMockData';
import { t } from 'i18next';
import CircularProgress from "@mui/material/CircularProgress";
import bus from "src/bus"

const ratingToPercentage = (rating) => {
  switch (rating) {
    case 0:
      return 0;
    case 1:
      return 20;
    case 2:
      return 40;
    case 3:
      return 60;
    case 4:
      return 80;
    case 5:
      return 100;
    default:
      notifyError(`Invalid rating level: ${rating}`);
      return 0;
  }
};

const TaskCard = ({ user_details, task, defWalletInfo }) => {
  const [KYC_Status, setKYC_Status] = useState(JSON.parse(localStorage.getItem('kyc_status')))
  const [AwaitingStatus, setAwaitingStatus] = useState(JSON.parse(localStorage.getItem('awaiting_kyc_verification')))
  const navigate = useNavigate();
  const [percentage, setPercentage] = useState(0)
  const [IsDataLoaded, setIsDataLoaded] = useState(true);

  const handleNextStepRoute = (text) => {
    // console.log('route text:', text);

    if (text === 'Start KYC' || text === 'Continue KYC') {
      navigate("/complete-kyc");
    } else if (text === "Fund Wallet") {
      navigate("/app/wallet");
    } else if (text === "Get Started") {
      bus.emit('all_steps_done', true);
    } else if (text === 'Awaiting Verification') {
      notifyError('KYC Verification in progress...');
    }
  };

  useEffect(() => {
    setKYC_Status(JSON.parse(localStorage.getItem('kyc_status')));

    if (user_details && user_details.account.verify_business) {
      setPercentage(ratingToPercentage(1));
    } else {
      return;
    }

    if (user_details && user_details.account.docs_upload) {
      setPercentage(ratingToPercentage(2));
    } else {
      return;
    }

    if (user_details && user_details.account.business_rep) {
      setPercentage(ratingToPercentage(3));
    } else {
      return;
    }

    if (user_details && user_details.account.secure_account) {
      setPercentage(ratingToPercentage(4));
    } else {
      return;
    }

    if (user_details && user_details.account.setup_account) {
      setPercentage(ratingToPercentage(5));
    } else {
      return;
    }
  }, [user_details, percentage]);

  useEffect(() => {
    setIsDataLoaded(true)
    setTimeout(() => {
      setIsDataLoaded(false)
    }, 1000);
  }, []);


  return (
    <Card
      sx={{
        backgroundColor: task.completed ? 'rgba(0, 0, 0, 0.54)' : 'rgba(0, 0, 0, 0.87)',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        minHeight: '150px',
        boxShadow: '0px 4px 4px 0px #00000040',
        border: 'none',
        borderTop: task.completed ? '1px solid #333232' : '1px solid transparent',
        position: 'relative',
        height: '100%',
        background: '#191919',
        borderRadius: '8px',
        overflow: 'hidden'
      }}
    >
      <CardContent
        sx={{
          textAlign: 'left',
          background: `${task.completed ? '#191919' : (task.title === 'Welcome Onboard' && defWalletInfo?.length < 1) ? 'rgb(37,37,37)' : '#313131'}`,
          boxShadow: (task.buttonText !== '' && task.current) ? 'inset 0 0 0 2px white' : 'none',
          borderRadius: '8px'
        }} className='px-3 pb-2 pt-4 h-100 w-100 d-flex flex-column justify-content-between align-items-start'>
        <div className='w-100'>
          {task.completed ?
            <span style={{ color: 'green', }}>
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.0357 16.7273V24H20.7182V18.0092H20.6756L18.9746 19.0959V17.8885L20.7822 16.7273H22.0357Z" fill="white" />
                <mask id="mask0_6424_60397" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="1" width="38" height="38">
                  <circle cx="20.0003" cy="20.0001" r="18.3333" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_6424_60397)">
                  <rect width="40" height="40" fill="#19251E" />
                </g>
                <path d="M27.9862 14.7192C27.3374 14.0205 26.2619 14.0096 25.6004 14.6969L18.1506 22.4369L14.4003 18.5405C13.7388 17.8532 12.6633 17.8641 12.0145 18.5628C11.3819 19.2442 11.3906 20.3279 12.0356 20.998L18.1506 27.3511L27.9651 17.1544C28.6101 16.4843 28.6188 15.4006 27.9862 14.7192Z" fill="#6EE7B7" />
              </svg>
            </span>
            :
            <>
              <div className='d-flex justify-content-between'>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: (task.title === 'Welcome Onboard' && defWalletInfo?.length < 1) ? 'rgb(127,128,130)' : '#E5E7EB', width: '35px', height: '35px', minWidth: '35px', minHeight: '35px', borderRadius: '50%' }} className="">
                  <span className="fs-16 fw-600">{task.id}</span>
                </div>
                {task.title === 'Complete KYC' && !KYC_Status && !AwaitingStatus &&
                  <>
                    <div className=" mt-2" style={{ zIndex: '10', width: '7em' }}>
                      <div className="KYC_goal">
                        <div className="KYC_so_far" style={{ width: `${percentage}%` }}></div>
                      </div>

                      <div className='fit-content ml-auto mt-0'>
                        <span className='text-white fw-400 fs-10'>{percentage}% Completed</span>
                      </div>
                    </div>
                  </>
                }
              </div>

            </>
          }
          <Typography
            variant="body1"
            component="div"
            sx={{
              mb: 1,
              // textDecoration: task.completed ? 'line-through' : 'none', 
              color: task.completed ? 'rgba(255, 255, 255, 0.5)' : (task.title === 'Welcome Onboard' && defWalletInfo?.length < 1) ? 'rgb(127,128,130)' : 'white',
              width: '9em',
              maxWidth: '100%'
            }}
            className={`${task.completed ? 'mt-5' : 'mt-3'} mb-4 pb-2 fw-600 fs-16`}>
            {task.title}{AwaitingStatus !== null && !AwaitingStatus ? task.separator : ''}
            <div className='fs-16 text-white' style={{ whiteSpace: 'nowrap' }}>
              {(task.desc !== '' && AwaitingStatus !== null && AwaitingStatus === false) ? task.desc : ''}
            </div>
          </Typography>
        </div>

        {task.buttonText !== '' && !task.completed &&
          <Button onClick={() => handleNextStepRoute(task.buttonText)} variant="contained" className='d-block mb-3'
            style={{ background: AwaitingStatus === true && task.buttonText === 'Awaiting Verification' ? 'rgba(107, 114, 128, 1)' : 'white' }}>
            {IsDataLoaded && !KYC_Status ? (
              <div className="fit-content d-flex align-items-center">
                <span className='fw-500 fs-14 d-block mr-2' style={{ color: '#313131', whiteSpace: 'nowrap' }}>Loading status...</span>
                <CircularProgress
                  style={{ color: "white" }}
                  className="text-white"
                  size="1rem"
                />

              </div>
            ) : (
              <>
                {task.buttonText !== '' ?
                  <span className='fw-500 fs-14' style={{ color: AwaitingStatus !== null && !AwaitingStatus ? '#313131' : 'black', whiteSpace: 'nowrap' }}>
                    {task.buttonText}
                    {/* {(AwaitingStatus !== null && !AwaitingStatus) ? task.buttonText : 'Awaiting Verification'} */}
                  </span>
                  :
                  <span></span>
                }
              </>
            )}
          </Button>
        }
        {/* <CheckCircleIcon sx={{ color: 'green', position: 'absolute', top: 10, right: 10 }} /> */}
      </CardContent>
    </Card>
  );
};

export default TaskCard;
