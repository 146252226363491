import { Helmet } from "react-helmet-async";
// import * as Router from 'react-router-dom';
// import Scrollbar from "../../../components/scrollbar";
// import DatePicker from "react-datepicker";
// import { Link, Stack, Drawer, Typography, IconButton } from "@mui/material";
// import Scrollbar from "../../../components/scrollbar";
// import bus from "src/bus";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import styles from "../Payment.module.css";
import { useParams, useNavigate } from 'react-router-dom';
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import axiosInstance from "src/axiosInstance"
import CircularProgress from "@mui/material/CircularProgress";

// ----------------------------------------------------------------------

export default function CreatePaymentLink() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [selectedImage, setSelectedImage] = useState(null);
  // console.log('Payment Link ID:', id);
  const apiUrl = process.env.REACT_APP_NAME;
  const [paymentLinkDetailsData, setPaymentLinkDetailsData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [lessThan, setLessThan] = useState(false);
  const [isInitiating, setIsInitiating] = useState(false);
  const [AmountDisabled, setAmountDisabled] = useState(false);
  const [Amount, setAmount] = useState('');
  const [Fullname, setFullname] = useState('');
  const [Email, setEmail] = useState('');
  const [AcceptedCurrencies, setAcceptedCurrencies] = useState('');
  const [initiatePLResponseObj, setInitiatePLResponseObj] = useState(null);
  const preview_data = JSON.parse(localStorage.getItem('preview_data'));
  const [selectedOption, setSelectedOption] = useState("");


  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleFetchPLData = async () => {
    setPaymentLinkDetailsData(null);
    setSelectedImage(null);
    // console.log('id:', id);
    setIsPending(true);

    try {
      if (preview_data) {
        const data = preview_data;
        // console.log('preview_data', data);

        setPaymentLinkDetailsData(data);

        if (data.image !== '' || data.image !== null) {
          setSelectedImage(data.image);
        }

        if (data.amount !== '' || data.amount !== null) {
          setAmount(data.amount);
        } 

        if (data.sel_string_curr !== '' || data.sel_string_curr !== null) {
          setAcceptedCurrencies(data.sel_string_curr);
        } 

        if (data.amount !== null && data.amount !== '') {
          setAmountDisabled(true)
        } else {
          setAmountDisabled(false)
        }

        if (data.sel_string_curr === 'NGN, CNY' || data.sel_string_curr === 'CNY, NGN') {
          setSelectedOption('NGN');
        } else if (data.sel_string_curr === 'NGN') {
          setSelectedOption('NGN');
        } else if (data.sel_string_curr === 'CNY') {
          setSelectedOption('CNY');
        }

      } else {
        const response = await axiosInstance.get(`/payments/payment-links/${id}?productName=${apiUrl}`);
        const data = response.data.data;

        setPaymentLinkDetailsData(data);

        if (data.image !== '' || data.image !== null) {
          setSelectedImage(data.image);
        }

        if (data.amount !== '' || data.amount !== null) {
          setAmount(data.amount);
        } 

        if (data.accepted_currencies !== '' || data.accepted_currencies !== null) {
          setAcceptedCurrencies(data.accepted_currencies);
        } 

        if (data.amount !== null && data.amount !== '') {
          setAmountDisabled(true)
        } else {
          setAmountDisabled(false)
        }
        
        

        if (data.accepted_currencies === 'NGN, CNY' || data.accepted_currencies === 'CNY, NGN') {
          setSelectedOption('NGN');
        } else if (data.accepted_currencies === 'NGN') {
          setSelectedOption('NGN');
        } else if (data.accepted_currencies === 'CNY') {
          setSelectedOption('CNY');
        }

        // console.log('selectedImage', data.image);
        // notifySuccess('Payment link details fetched successfully');
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
          notifyError(err.response.data.data.errorMessage);
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
        } else {
          notifyError('An unexpected error occurred');
        }
      } else {
        notifyError('Network error or server not responding');
      }
    } finally {
      setIsPending(false);
    }

  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value)
    if (parseFloat(event.target.value) < 20) {
      setLessThan(true)
    } else {
      setLessThan(false)
    }
  }

  const handleFullNameChange = (event) => {
    setFullname(event.target.value)
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  useEffect(() => {
    handleFetchPLData();
  }, []);

  const initiatePLTransaction = async () => {
    setIsInitiating(true)
    let payload = {
      amount: parseFloat(Amount),
      email: Email,
      name: Fullname,
      phone: '08012345678',
    }

    // console.log('init payload:', payload);

    try {
      const response = await axiosInstance.post(`/payments/payment-links/checkout/${id}?productName=${apiUrl}`, payload)

      const data = response.data;
      // console.log('initiated data:', data);
      setInitiatePLResponseObj(data)
      localStorage.setItem('initiated_data', JSON.stringify(data))

      if (initiatePLResponseObj) {
        // console.log('initiatePLResponseObj:', initiatePLResponseObj);
      }
      setTimeout(() => {
        // navigate(`${data.data.url}`)
        window.location.href = `${data.data.url}`
      }, 1000);
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
          notifyError(err.response.data.data.errorMessage);
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
        } else {
          notifyError('An unexpected error occurred');
        }
      } else {
        notifyError('Network error or server not responding');
      }
    } finally {
      setIsInitiating(false)
    }

  }

  // const handleClosePreviewPL = () => {
  //   setPreviewPL(false);
  // };

  return (
    <>

      <Helmet>
        <title> Payment Link Preview </title>
      </Helmet>

      <div className="wrapper w-100 px-3">
        <div className={`modal_content px-md-3`} style={{ minHeight: '100vh' }}>
          <div
            className={` mx-auto px-md-3 pb-5 py-4 py-md-5 position-relative`}
          >
            <div className="w-100">
              <div>
                <h6 className="fit-content mx-auto " style={{display: 'flex',alignItems: 'center'}}>
                  <div className={`${styles.logo_wrap} d-inline`}>
                    {
                      paymentLinkDetailsData?.company_logo && 
                      (
                        <img style={{maxWidth: '80px', height:'auto'}} src={paymentLinkDetailsData?.company_logo} alt="" />
                      )
                    }
                    {
                      !paymentLinkDetailsData?.company_logo && 
                      (
                      <svg width="40" height="37" viewBox="0 0 40 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.0993 33.1906C17.3303 32.5961 17.4404 31.9616 17.4232 31.324C17.4584 29.5571 17.9306 27.8264 18.7977 26.2864C19.6627 24.7491 20.8961 23.4508 22.3872 22.5083C22.9393 22.2121 23.4256 21.8071 23.8166 21.3176C24.2076 20.8281 24.4953 20.2644 24.6622 19.6605C24.8291 19.0566 24.8717 18.4252 24.7875 17.8044C24.7033 17.1836 24.4941 16.5863 24.1724 16.0487C23.7427 15.3305 23.1243 14.7418 22.3872 14.3501C21.9646 14.0569 21.5587 13.7412 21.1717 13.4029L20.2019 12.4533C18.4249 10.5852 17.4284 8.10899 17.4161 5.53076C17.4344 4.89307 17.3249 4.25816 17.0939 3.66349C16.8629 3.06881 16.5152 2.52642 16.0712 2.06831C15.6292 1.61071 15.0996 1.24685 14.5139 0.998458C13.9281 0.750062 13.2984 0.622219 12.6622 0.622559C12.0264 0.623456 11.3971 0.751866 10.8118 1.00019C10.2265 1.24852 9.69687 1.61172 9.25435 2.06831C8.81116 2.52723 8.46394 3.06993 8.23302 3.66466C8.0021 4.25938 7.89211 4.8942 7.90949 5.53194C7.91986 6.35979 8.14788 7.17034 8.57064 7.88218C8.99195 8.59282 9.59346 9.17958 10.3143 9.58313C13.2331 11.4301 15.2641 14.9614 15.2641 18.4345C15.2569 20.9903 14.2794 23.4481 12.5293 25.3107L10.3215 27.2716C9.60095 27.674 8.99915 28.2604 8.57658 28.9726C8.15282 29.6836 7.92611 30.4955 7.91543 31.324C7.89727 31.9617 8.0069 32.5965 8.23787 33.1912C8.46884 33.7858 8.81647 34.3282 9.26028 34.7865C9.70236 35.2439 10.232 35.6077 10.8177 35.8561C11.4034 36.1045 12.0331 36.2324 12.6693 36.2322C13.3054 36.2315 13.9348 36.1031 14.5203 35.8548C15.1059 35.6065 15.6357 35.2432 16.0783 34.7865C16.5213 34.3276 16.8684 33.7851 17.0993 33.1906Z" fill="#111827" />
                        <path d="M23.5469 8.17181C23.0234 7.38602 22.7445 6.46136 22.7445 5.51651C22.7453 4.25086 23.2467 3.0369 24.1392 2.13953C24.9152 1.35878 25.9388 0.872424 27.0343 0.763916C28.1297 0.655409 29.2288 0.93151 30.143 1.54485C30.926 2.07057 31.5357 2.81654 31.895 3.68855C32.2543 4.56155 32.3481 5.52117 32.1646 6.44723C31.9812 7.37329 31.5285 8.22465 30.8635 8.89468C30.2004 9.56287 29.3533 10.0183 28.4303 10.2029C27.5072 10.3875 26.5501 10.2928 25.6811 9.93092C24.8112 9.56872 24.0685 8.95649 23.5469 8.17181Z" fill="#111827" />
                        <path d="M24.8585 27.3512C25.6407 26.8265 26.5606 26.5464 27.5007 26.5464V26.544C28.1257 26.5448 28.7443 26.6689 29.3211 26.9092C29.898 27.1496 30.4217 27.5015 30.8623 27.9447C31.6409 28.7279 32.1252 29.756 32.2333 30.8551C32.3414 31.9542 32.0668 33.0569 31.4558 33.9769C30.9342 34.7627 30.191 35.3758 29.3204 35.7384C28.4514 36.1003 27.4943 36.195 26.5712 36.0104C25.6482 35.8258 24.801 35.3704 24.138 34.7022C23.4727 34.0321 23.0199 33.1805 22.8364 32.2542C22.653 31.3279 22.7469 30.368 23.1065 29.4949C23.4659 28.6229 24.0755 27.8769 24.8585 27.3512Z" fill="#111827" />
                        <path d="M38.8685 15.7662C39.3906 16.5517 39.6695 17.4748 39.6697 18.4193C39.6689 19.6852 39.1676 20.8995 38.275 21.7972C37.8343 22.2403 37.3106 22.5921 36.7338 22.8324C36.1569 23.0727 35.5384 23.1969 34.9135 23.1979C33.9724 23.1979 33.0526 22.9177 32.2712 22.3931C31.4882 21.8674 30.8786 21.1214 30.5192 20.2494C30.1596 19.3763 30.0657 18.4164 30.2492 17.4901C30.4327 16.5638 30.8854 15.7122 31.5507 15.0421C32.2139 14.3737 33.0612 13.9181 33.9845 13.7335C34.9078 13.5489 35.8652 13.6437 36.7343 14.0059C37.6043 14.3684 38.3471 14.981 38.8685 15.7662Z" fill="#111827" />
                        <path d="M2.61315 14.4474C3.39538 13.9228 4.31411 13.6426 5.25539 13.6426C5.88015 13.644 6.49853 13.7684 7.07513 14.009C7.65174 14.2495 8.17526 14.6014 8.61575 15.0445C9.39395 15.8275 9.87801 16.8551 9.98615 17.9537C10.0943 19.0524 9.81986 20.1547 9.20925 21.0744C8.68776 21.8594 7.94499 22.4721 7.07504 22.8347C6.20574 23.1948 5.24906 23.2885 4.32639 23.104C3.40372 22.9195 2.55665 22.4651 1.89265 21.7984C1.22758 21.1284 0.774969 20.277 0.591488 19.351C0.408006 18.4249 0.501811 17.4653 0.861156 16.5923C1.22033 15.7199 1.83 14.9735 2.61315 14.4474Z" fill="#111827" />
                      </svg>

                      )
                    }

                  </div>
                  <span className="d-inline mt-3 ml-2 fs-15">
                    {paymentLinkDetailsData?.company_name ?? 'Company Name'}
                  </span>
                </h6>
              </div>
              <h4
                className="mt-3 text-capitalize text-center"
              >
                {/* Rabbit Fund Raising */}
                {paymentLinkDetailsData ? paymentLinkDetailsData.name : '---'}
              </h4>

              <div
                className="mt-4 mb-4 mx-auto"
                style={{ width: "38em", maxWidth: "100%" }}
              >
                <p className="text-center fw-500 fs-12">
                  {paymentLinkDetailsData ? paymentLinkDetailsData.description : '---'}
                </p>
              </div>

              <div className="card border-0 mx-auto overflow-hidden mb-5"
                style={{
                  width: '34rem', maxWidth: '100%', borderRadius: '10px',
                  boxShadow: '0px 3.37px 3.37px 0px rgba(0, 0, 0, 0.25)',
                  background: 'white'
                }}>
                <div className="card-img-top text-center overflow-hidden" style={{
                  maxHeight: '150px'
                }}>
                  {!selectedImage && (
                    <div className="w-100 border">
                      <div
                        className="mx-auto"
                        style={{ width: "100%" }}
                      >
                        <img src="/assets/payment_link_previewImg.png"
                          style={{ objectFit: 'cover', width: '100%', minHeight: '150px' }}
                          alt="preview" />
                      </div>
                    </div>
                  )}
                  {selectedImage && (
                    <div className="preview_selImage fit-content mx-auto">
                      <img className="" style={{ minHeight: '150px' }} src={selectedImage} alt="Selected" />
                    </div>
                  )}
                </div>
                <div className="card-body bg-white">


                  <div className="mb-3 input_amt">
                    <label htmlFor="preview_amount" className="fs-13">Amount</label>
                    <div className={`${styles.charges_input}`} style={{ background: '#F3F4F6' }}>
                      <div className={`${styles.select_wrap}`} style={{ transform: 'translateY(-17px)' }}>
                        <div className=" all_selectdropdown_icon position-absolute px-1 bg">
                          <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0.292893 0.792893C0.683416 0.402369 1.31658 0.402369 1.7071 0.792893L4.99999 4.08579L8.29288 0.792893C8.6834 0.402369 9.31657 0.402369 9.70709 0.792893C10.0976 1.18342 10.0976 1.81658 9.70709 2.20711L5.7071 6.20711C5.31657 6.59763 4.68341 6.59763 4.29289 6.20711L0.292893 2.20711C-0.0976309 1.81658 -0.0976309 1.18342 0.292893 0.792893Z" fill="#6B7280" />
                          </svg>
                        </div>

                        {paymentLinkDetailsData ? (paymentLinkDetailsData.accepted_currencies === 'NGN, CNY' || paymentLinkDetailsData.accepted_currencies === 'CNY, NGN') ? 
                        (
                          <Select
                          labelId="s"
                          id="s"
                          size="small"
                          required
                          name="verified"
                          className="remove_lines"
                          style={{ width: "fit-content", transform: 'translateX(-8px)' }}
                          value={selectedOption}
                          onChange={handleChange}
                          disabled={AmountDisabled}
                        >
                          <MenuItem value="NGN">NGN</MenuItem>
                          <MenuItem value="CNY">CNY</MenuItem>
                        </Select>
                        ) : (<></>) : (<></>)}

                        {preview_data ? (preview_data.sel_string_curr === 'NGN, CNY' || preview_data.sel_string_curr === 'CNY, NGN') ? 
                        (
                          <Select
                          labelId="s"
                          id="s"
                          size="small"
                          required
                          name="verified"
                          className="remove_lines"
                          style={{ width: "fit-content", transform: 'translateX(-8px)' }}
                          value={selectedOption}
                          onChange={handleChange}
                          disabled={AmountDisabled}
                        >
                          <MenuItem value="NGN">NGN</MenuItem>
                          <MenuItem value="CNY">CNY</MenuItem>
                        </Select>
                        ) : (<></>) : (<></>)}
                        {/* {console.log('AcceptedCurrencies',AcceptedCurrencies)} */}

                        {AcceptedCurrencies === 'NGN' || AcceptedCurrencies === 'CNY'
                         ?
                        <>

                        <Select
                          labelId="s"
                          id="s"
                          size="small"
                          required
                          name="verified"
                          className="remove_lines"
                          style={{ width: "fit-content", transform: 'translateX(-8px)' }}
                          value={selectedOption}
                          onChange={handleChange}
                          disabled={AmountDisabled}
                        >
                        {paymentLinkDetailsData ? paymentLinkDetailsData.accepted_currencies === 'NGN' ? 
                        (
                          <MenuItem value="NGN">NGN</MenuItem>
                        ) : (<></>) : (<></>)}

                        {paymentLinkDetailsData ? paymentLinkDetailsData.accepted_currencies === 'CNY' ? 
                        (
                          <MenuItem value="CNY">CNY</MenuItem>
                        ) : (<></>) : (<></>)}

                        {preview_data ? preview_data.sel_string_curr === 'NGN' ? 
                        (
                          <MenuItem value="NGN">NGN</MenuItem>
                        ) : (<></>) : (<></>)}

                        {preview_data ? preview_data.sel_string_curr === 'CNY' ? 
                        (
                          <MenuItem value="CNY">CNY</MenuItem>
                        ) : (<></>) : (<></>)}
                        </Select>
                        </> : <></>
                      }
                      </div>
                      <input
                        type="number"
                        readOnly={AmountDisabled}
                        disabled={AmountDisabled}
                        placeholder=""
                        style={{ width: "100%", textIndent: "65px" }}
                        className=" form-control border bg-none"
                        value={Amount}
                        onChange={handleAmountChange}
                      />
                    </div>
                    {lessThan &&
                      <span className="d-block fs-11 text-danger mt-1">Amount should not be less than {selectedOption === 'CNY' ? '¥' : '₦'}20</span>
                    }
                  </div>

                  <div className="mb-3 height_40">
                    <label htmlFor="full_name" className="">Full Name</label>
                    <div className="" style={{ width: "100%" }}>
                      <div className="">
                        <TextField
                          name="full_name"
                          required
                          type="text"
                          id="full_name"
                          size="small"
                          placeholder=""
                          fullWidth
                          value={Fullname}
                          onChange={handleFullNameChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-3 height_40">
                    <label className="" htmlFor="email">Email Address</label>
                    <div className="" style={{ width: "100%" }}>
                      <div className="">
                        <TextField
                          name=""
                          required
                          type="email"
                          id="email"
                          size="small"
                          placeholder=""
                          fullWidth
                          value={Email}
                          onChange={handleEmailChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="nelow_btn pt-4 pb-5">
                    <div className="btn_wrap">
                      <button
                        disabled={(Fullname === '' || Email === '' || Amount === '') || preview_data !== null}
                        onClick={initiatePLTransaction}
                        className="btn btn-primary d-block w-100 text-white py-2 cursor-pointer position-relative"
                      >
                        <span className="text-white py-1 d-block mr-3"> Make Payment</span>
                        {isInitiating && (
                          <span style={{
                            transform: 'translateY(1px)',
                            position: 'absolute',
                            right: '15px',
                            top: '12px'

                          }} className="ml-1 white_circular_prog">
                            <CircularProgress
                              style={{ stroke: "#fff" }}
                              className="text-white ml-1 position-relative"
                              size="1rem"
                            />
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className={`modal_content px-md-3`} style={{ minHeight: '100vh' }}>
      <div className="mx-auto px-md-3 pb-5 py-md-5 position-relative">
        <div>
          <div>
            <h6 className="fit-content mx-auto ">
              <div className="d-inline"></div>
              <span className="d-inline mt-3 ml-2">Company Name</span>
            </h6>
          </div>
          <p className="mt-3" style={{ fontSize: '13px', fontWeight: '500', textAlign: 'center' }}>
            Rabbit Fund Raising
          </p>
          <div className="p-2 text-center px-4">
            {!selectedImage && (
              <div>
                <div className="mx-auto" style={{ width: 'fit-content' }}>
                  <img src="/assets/payment_link_previewImg.png" alt="preview" />
                </div>
              </div>
            )}
            {selectedImage && (
              <div className="preview_selImage fit-content mx-auto">
                <img className="" src={selectedImage} alt="Selected" />
              </div>
            )}
          </div>
          <div className="mt-4 mx-auto" style={{ width: '38em', maxWidth: '100%' }}>
            <h3 className="text-dark">$46.16</h3>
            <h4 className="text-center">
              <span>DESCRIPTION</span>
            </h4>
            <p className="text-center fw-500" style={{ fontSize: '12px' }}>
              Rhoncus morbi et augue nec, in id ullamcorper at sit. Condimentum sit nunc in eros scelerisque sed. Commodo
              in viverra nunc, ullamcorper ut. Non, amet, aliquet scelerisque nullam sagittis, pulvinar. Fermentum
              scelerisque sit consectetur hac mi. Mollis leo eleifend ultricies purus iaculis.
            </p>
          </div>
          <div className="nelow_btn mt-4 pt-5">
            <div className="btn_wrap">
              <RouterLink to="" onClick={handleClosePreviewPL} className="btn btn-primary d-block w-100 text-white py-2">
                Make Payment
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    </>
  );
};
