import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import axiosInstance from "src/axiosInstance"
import bus from "src/bus";
import { BaseUrlContext } from "src/context/BaseUrlContext";

import { Link, Typography, Stack, Button } from "@mui/material";
// components
import Logo from "../../../components/logo";
// Module styles
import Styles from "../CSSModules/FormPages.module.css";

// sections
import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import { useCountryDropDowns, useLangDropDowns, notifySuccess, notifyError } from "src/hooks/useMockData";
import LiveSelect from 'react-select';
import { signInWithGoogle } from '../../../theme/firebaseConfig'; 
import Footer from '../Footer'

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme, imageUrl }) => ({
  [theme.breakpoints.up("0")]: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    boxSizing: "border-box",
    backgroundColor: "#FFFFFF",
    // backgroundImage: `url(${imageUrl})`,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    padding: theme.spacing(4, 0),
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  overflowX: "hidden",
  position: "relative",
  width: "80em",
  maxWidth: "100%",
  margin: "0",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "space-between",
  flexDirection: "column",
  padding: theme.spacing(3, 0),
}));

// ----------------------------------------------------------------------

export default function SignupPage() {
  const apiUrl = process.env.REACT_APP_NAME;
  const baseUrl = useContext(BaseUrlContext);
  const [doesNotMath, setDoesNotMath] = useState(false);
  const languageOptions = useLangDropDowns();
  const countryOptions = useCountryDropDowns();

  const imageUrl = "/assets/images/op_background.png";
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setCPassword] = useState("");

  // const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("select");
  const [language, setLanguage] = useState('English');

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword_two, setShowPassword_two] = React.useState(false);
  const [agree, setAgree] = useState(false);
  const [signUpError, setSignUpError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [isGooglePending, setIsGooglePending] = useState(false);
  const [response, setResponse] = useState(null);


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setDoesNotMath('');
    setSignUpError('');
  };
  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };
  const handleCPasswordChange = (event) => {
    setCPassword(event.target.value);
    setDoesNotMath('');
    setSignUpError('');
  };


  // const notify = (msg) => toast.success(msg);
  // const notifyError = (err) => notifyError(err);

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword_two = () => setShowPassword_two((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword_two = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  const handleProceed = () => {
    navigate("/verify-email");
  };

  const handleChange2 = (e) => {
    setAgree(!agree)
  }

  const TandC = () => {
    // localStorage.setItem('onRegister', JSON.stringify({
    //   email: email,
    //   password: password,
    //   confirm_password: confirm_password,
    //   // category: category
    // }))
    // if (StoreID) {
    //   localStorage.setItem('storeID', JSON.stringify(StoreIDValue))
    // }
    navigate('/privacy-policy')
  }
  // useEffect(() => {
  //   const regValue = localStorage.getItem('onRegister') ? JSON.parse(localStorage.getItem('onRegister')) : null
  //   if (regValue) {
  //     setCategory(regValue.category)
  //     setEmail(regValue.email)
  //     setPassword(regValue.password)
  //     setCPassword(regValue.confirm_password)
  //   }
  // }, [])


  // const fetchCountries = async () => {
  //   try {
  //     const countriesApi = await fetch("https://restcountries.com/v3.1/all");
  //     const countriesJson = await countriesApi.json();
  //     const countriesData = countriesJson.map((country) => ({
  //       code: country.cca2,
  //       name: country.name.common,
  //     }));
  //     setCountries(countriesData.sort((a, b) => a.name.localeCompare(b.name)));
  //   } catch (error) {
  //     console.error("Error fetching countries:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchCountries();
  // }, []);

  const handleLanguageChange = (event) => {
    setLanguage(event.value);
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.value);
  };


  function HandleFormSubmit(e) {
    e.preventDefault();


    let payload = {
      name: fullName,
      email: email,
      country: selectedCountry.toUpperCase(),
      language: language,
    };

    if (!fullName && !email && !selectedCountry && !language) {
      return notifyError('All fields are required!');
    }

    if (payload.country === 'SELECT') {
      notifyError('Kindly select a country to proceed!')
      // console.log('payload:', payload);
      return;
    }

    if (payload.language === 'select') {
      notifyError('Kindly select a language to proceed!')
      // console.log('payload:', payload);
      return;
    }

    // if (confirm_password !== password) {
    //   setDoesNotMath(true);
    //   notifyError('Passwords does not match!')

    //   return;
    // }

    // if (password.length < 8) {
    //   setSignUpError('Password must be at least 8 characters long');
    //   return;
    // }


    setSignUpError(null)

    const fetchData = async () => {

      try {

        setIsPending(true)

        const res = await axiosInstance.post(`/accounts/create?productName=${apiUrl}`, payload);

        notifySuccess(res.data.message);
        localStorage.setItem('e', email)
        bus.emit('email', email)
        setPassword('');
        setCPassword('');
        setEmail('');
        setFullName('')
        setLanguage('')
        setSelectedCountry('')

        setIsPending(false)
        setTimeout(() => {
          navigate('/verify-email');
        }, 1000);

      } catch (err) {
        if (err.response) {
          if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
            notifyError(err.response.data.data.errorMessage);
          } else if (err.response.data && err.response.data.message) {
            notifyError(err.response.data.message);
          } else {
            notifyError('An unexpected error occurred');
          }
        } else {
          notifyError('Network error or server not responding');
        }
      } finally {
        setIsPending(false)
      }
    };
    fetchData(true);
  };


  const handleGoogleSignIn = async () => {
    setIsGooglePending(true);
    try {
      // const result = await signInWithGoogle();
      const result = await axiosInstance.get(`/accounts/login/oauth/google?productName=${apiUrl}&actionType=login`);
      // You can get user info from result.user
      navigate(result.data.data.link)
      // Redirect or handle the authenticated user as needed
    } catch (error) {
      console.error("Google Sign-In Error:", error);
      setIsGooglePending(false);
    }
  };


  return (
    <>
      <Helmet>
        <title> Signup </title>
      </Helmet>

      <StyledRoot imageUrl={imageUrl} className="px-3">
        <StyledContent className={`${Styles.StyledContent}`}>
          <div className={`${Styles.logo_wrap}`}>
            <Logo />
          </div>

          <div
            className={`${Styles.signin_form_wrap} py-5 my-3 py-md-5 my-md-5 mx-sm-auto ml-md-5 pl-md-5`}
          >
            <form onSubmit={HandleFormSubmit}>
              <Typography variant="h4">Sign Up</Typography>
              {/* {(signUpError) && <Alert style={{
                border: '1px solid red'
              }} severity="error">{signUpError}</Alert>} */}

              {/* {(response) && <Alert style={{
              border: '1px dotted gray'
            }} severity="success">{response}</Alert>} */}
              {/* <div className={`${Styles.signup_switch}`}><Link href='/login'>or Log in</Link></div> */}

              <div className={`${Styles.input_box}`}>
                <label htmlFor="fullName">Business Name</label>
                <Box
                  sx={{
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    placeholder=""
                    type="text"
                    id="fullName"
                    value={fullName}
                    onChange={handleFullNameChange}
                  />
                </Box>
              </div>

              <div className={`${Styles.input_box}`}>
                <label htmlFor="email">Business Email</label>
                <Box
                  sx={{
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    placeholder=""
                    type="email"
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </Box>
              </div>

              <div className={`${Styles.input_box} remove_MUI_bg`}>
                <label>Country</label>
                <Box
                  sx={{
                    maxWidth: "100%",
                    position: "relative",
                  }}
                >
                  <LiveSelect
                    defaultValue={selectedCountry}
                    onChange={handleCountryChange}
                    options={countryOptions?.map((option) => ({
                      value: option.name,
                      label: option.name,
                    }))}
                  />

                  {/* <Select
                    labelId="country-select-label"
                    id="country-select"
                    fullWidth
                    size="small"
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    name="country"
                  >
                    <MenuItem value="select">Select Country</MenuItem>
                    {countries.map((el) => (
                      <MenuItem className="fw-500 text-dark" key={el.code} value={el.name}>
                        {el.name}
                      </MenuItem>
                    ))}
                  </Select> */}
                </Box>
              </div>

              <div className={`${Styles.input_box}`}>
                <label>Language</label>
                <Box
                  sx={{
                    maxWidth: "100%",
                    position: "relative",
                  }}
                >
                  <LiveSelect
                    defaultValue={language}
                    onChange={handleLanguageChange}
                    options={languageOptions?.map((option) => ({
                      value: option.key,
                      label: option.name,
                    }))}
                  />

                  {/* <Select
                    labelId="language-select-label"
                    id="language-select"
                    size="small"
                    fullWidth
                    value={language}
                    name="language"
                    required
                    onChange={handleLanguageChange}
                  >
                    <MenuItem value="select">Select Language</MenuItem>
                    {languageOptions.map(option => (
                      <MenuItem className="fw-500 text-dark" key={option.key} value={option.key}>{option.name}</MenuItem>
                    ))}
                  </Select> */}
                </Box>
              </div>

              {/* <div className={`${Styles.input_box}`}>
                <label>Password</label>

                <FormControl sx={{ width: '100%' }} variant="outlined">
                  <OutlinedInput
                    placeholder='Create a password'
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff style={{fontSize: '30px'}}/> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <small className='text-muted font-weight-normal mt-2' style={{ fontSize: '12px' }}>At least 8 characters, containing a letter and a number</small>
                </FormControl>
              </div>

              <div className={`${Styles.input_box} mb-3`}>
                <label>Confirm Password</label>

                <FormControl sx={{ width: '100%' }} variant="outlined">
                  <OutlinedInput
                    placeholder='Confirm password'
                    id="outlined-adornment-password_two"
                    type={showPassword_two ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword_two}
                          onMouseDown={handleMouseDownPassword_two}
                          edge="end"
                        >
                          {showPassword_two ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={confirm_password}
                    onChange={handleCPasswordChange}
                  />
                </FormControl>
                {doesNotMath &&
                  <p className='text-danger m-0' style={{ verticalAlign: 'middle' }}>

                    <span style={{ verticalAlign: 'middle', marginRight: '5px', fontWeight: 'lighter' }}>
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6933 9.28663L8.95999 2.56663C8.45832 1.66246 7.76415 1.16663 6.99999 1.16663C6.23582 1.16663 5.54165 1.66246 5.03999 2.56663L1.30665 9.28663C0.834154 10.1441 0.781654 10.9666 1.16082 11.6141C1.53999 12.2616 2.28665 12.6175 3.26665 12.6175H10.7333C11.7133 12.6175 12.46 12.2616 12.8392 11.6141C13.2183 10.9666 13.1658 10.1383 12.6933 9.28663ZM6.56249 5.24996C6.56249 5.01079 6.76082 4.81246 6.99999 4.81246C7.23915 4.81246 7.43749 5.01079 7.43749 5.24996V8.16663C7.43749 8.40579 7.23915 8.60413 6.99999 8.60413C6.76082 8.60413 6.56249 8.40579 6.56249 8.16663V5.24996ZM7.41415 10.3308C7.38499 10.3541 7.35582 10.3775 7.32665 10.4008C7.29165 10.4241 7.25665 10.4416 7.22165 10.4533C7.18665 10.4708 7.15165 10.4825 7.11082 10.4883C7.07582 10.4941 7.03499 10.5 6.99999 10.5C6.96499 10.5 6.92415 10.4941 6.88332 10.4883C6.84832 10.4825 6.81332 10.4708 6.77832 10.4533C6.74332 10.4416 6.70832 10.4241 6.67332 10.4008C6.64415 10.3775 6.61499 10.3541 6.58582 10.3308C6.48082 10.22 6.41665 10.0683 6.41665 9.91663C6.41665 9.76496 6.48082 9.61329 6.58582 9.50246C6.61499 9.47913 6.64415 9.45579 6.67332 9.43246C6.70832 9.40913 6.74332 9.39163 6.77832 9.37996C6.81332 9.36246 6.84832 9.35079 6.88332 9.34496C6.95915 9.32746 7.04082 9.32746 7.11082 9.34496C7.15165 9.35079 7.18665 9.36246 7.22165 9.37996C7.25665 9.39163 7.29165 9.40913 7.32665 9.43246C7.35582 9.45579 7.38499 9.47913 7.41415 9.50246C7.51915 9.61329 7.58332 9.76496 7.58332 9.91663C7.58332 10.0683 7.51915 10.22 7.41415 10.3308Z" fill="#EF4444" />
                      </svg>
                    </span>
                    <small className='text-danger font-weight-normal' style={{ fontSize: '13px' }}>Password Mismatch!</small>

                  </p>}
                {signUpError && <p className='text-danger m-0' style={{ verticalAlign: 'middle' }}>

                  <span style={{ verticalAlign: 'middle', marginRight: '5px', fontWeight: 'lighter' }}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.6933 9.28663L8.95999 2.56663C8.45832 1.66246 7.76415 1.16663 6.99999 1.16663C6.23582 1.16663 5.54165 1.66246 5.03999 2.56663L1.30665 9.28663C0.834154 10.1441 0.781654 10.9666 1.16082 11.6141C1.53999 12.2616 2.28665 12.6175 3.26665 12.6175H10.7333C11.7133 12.6175 12.46 12.2616 12.8392 11.6141C13.2183 10.9666 13.1658 10.1383 12.6933 9.28663ZM6.56249 5.24996C6.56249 5.01079 6.76082 4.81246 6.99999 4.81246C7.23915 4.81246 7.43749 5.01079 7.43749 5.24996V8.16663C7.43749 8.40579 7.23915 8.60413 6.99999 8.60413C6.76082 8.60413 6.56249 8.40579 6.56249 8.16663V5.24996ZM7.41415 10.3308C7.38499 10.3541 7.35582 10.3775 7.32665 10.4008C7.29165 10.4241 7.25665 10.4416 7.22165 10.4533C7.18665 10.4708 7.15165 10.4825 7.11082 10.4883C7.07582 10.4941 7.03499 10.5 6.99999 10.5C6.96499 10.5 6.92415 10.4941 6.88332 10.4883C6.84832 10.4825 6.81332 10.4708 6.77832 10.4533C6.74332 10.4416 6.70832 10.4241 6.67332 10.4008C6.64415 10.3775 6.61499 10.3541 6.58582 10.3308C6.48082 10.22 6.41665 10.0683 6.41665 9.91663C6.41665 9.76496 6.48082 9.61329 6.58582 9.50246C6.61499 9.47913 6.64415 9.45579 6.67332 9.43246C6.70832 9.40913 6.74332 9.39163 6.77832 9.37996C6.81332 9.36246 6.84832 9.35079 6.88332 9.34496C6.95915 9.32746 7.04082 9.32746 7.11082 9.34496C7.15165 9.35079 7.18665 9.36246 7.22165 9.37996C7.25665 9.39163 7.29165 9.40913 7.32665 9.43246C7.35582 9.45579 7.38499 9.47913 7.41415 9.50246C7.51915 9.61329 7.58332 9.76496 7.58332 9.91663C7.58332 10.0683 7.51915 10.22 7.41415 10.3308Z" fill="#EF4444" />
                    </svg>
                  </span>
                  <small className='text-danger font-weight-normal' style={{ fontSize: '13px' }}>{signUpError}</small>

                </p>}
              </div> */}

              <div className="checkbox_div mb-3">
                <Checkbox id="schedule" checked={agree} onClick={handleChange2} style={{}} />
                <label htmlFor="schedule" className="ml-2 fs-14 text-dar cursor-pointer fw-500">I agree with the<span
                  // onClick={() => TandC()}
                  className='ml-1 fw-600 text-dark cursor-pointer'>
                  Terms of the service and conditions
                </span></label>
              </div>

              <div>
                <Stack spacing={2} direction="row">
                  <div className='w-100 white_circular_progress'>
                    {isPending ? (
                      <>
                        <Button disabled className={`${Styles.signin_btn} mb-0 position-relative`} style={{ verticalAlign: 'middle' }}>
                          <span className='fw-500 text-white'>Signing up...</span>
                          {isPending && <CircularProgress style={{ color: 'white' }} className='text-white' size="1rem" />}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button disabled={!agree} type='submit' className={`${Styles.signin_btn} ${agree ? Styles.signin_btn_bg : ''} mb-0 position-relative`} style={{ verticalAlign: 'middle' }}>
                          <span className='fw-500 text-white'>Sign Up</span>
                          {isPending && <CircularProgress style={{ color: 'white' }} className='text-white' size="1rem" />}
                        </Button>
                      </>
                    )
                    }
                  </div>
                </Stack>
                {/* <Stack spacing={2} direction="row">
                  <Button
                  type="submit"
                    className={`${Styles.signin_btn}`}
                    // onClick={handleProceed}
                    disabled={!agree}
                  >
                    <span className="fw-500 text-white">Sign Up</span>
                  </Button>
                </Stack> */}
              </div>
            </form>
            <div className="py-3">
              <hr
                style={{
                  border: "none",
                  borderBottom: "1px solid #D1D5DB",
                  background: "none",
                }}
              />
            </div>
            {/* <div>
              <Stack spacing={2} direction="row">
                <Button
                  className="d-block w-100 text-center border py-2"
                  style={{ background: "#F9FAFB", border: "1px solid #E5E7EB" }}
                  disable={ isGooglePending }
                onClick={() => handleGoogleSignIn()}
                >
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_3102_22496)">
                      <path
                        d="M8.47357 1.64745C6.15005 2.4535 4.14625 3.98341 2.75649 6.01247C1.36673 8.04152 0.664279 10.4628 0.752304 12.9206C0.840329 15.3783 1.71419 17.7431 3.24554 19.6676C4.77689 21.592 6.88501 22.9746 9.26024 23.6124C11.1859 24.1093 13.2034 24.1311 15.1394 23.676C16.8931 23.2821 18.5146 22.4394 19.8448 21.2306C21.2294 19.934 22.2344 18.2846 22.7517 16.4597C23.314 14.4752 23.4141 12.3882 23.0442 10.3589H12.2307V14.8446H18.4932C18.368 15.56 18.0998 16.2428 17.7046 16.8522C17.3094 17.4616 16.7953 17.9849 16.1931 18.391C15.4284 18.8968 14.5663 19.2372 13.6623 19.3902C12.7556 19.5588 11.8256 19.5588 10.919 19.3902C10 19.2002 9.1307 18.8209 8.36638 18.2765C7.13851 17.4073 6.21654 16.1725 5.73204 14.7483C5.23936 13.2974 5.23936 11.7245 5.73204 10.2736C6.07692 9.25655 6.64704 8.33057 7.39985 7.56473C8.26136 6.67222 9.35205 6.03426 10.5523 5.72082C11.7525 5.40739 13.0158 5.4306 14.2037 5.78791C15.1317 6.07276 15.9803 6.57047 16.6818 7.24134C17.3879 6.53885 18.0929 5.83454 18.7966 5.12841C19.1599 4.7487 19.556 4.38716 19.9139 3.99837C18.843 3.00182 17.586 2.22638 16.2149 1.71649C13.7181 0.80989 10.9862 0.785526 8.47357 1.64745Z"
                        fill="white"
                      />
                      <path
                        d="M8.47357 1.64735C10.9859 0.784847 13.7179 0.80857 16.2149 1.71458C17.5862 2.22793 18.8427 3.00711 19.9121 4.00736C19.5487 4.39615 19.1654 4.75951 18.7947 5.1374C18.0898 5.84111 17.3855 6.54239 16.6818 7.24124C15.9803 6.57037 15.1317 6.07267 14.2037 5.78781C13.0162 5.42925 11.7529 5.4047 10.5524 5.71685C9.35184 6.02901 8.26048 6.6658 7.39803 7.55736C6.64522 8.32321 6.07509 9.24919 5.73022 10.2662L1.96402 7.35025C3.31209 4.67696 5.64619 2.6321 8.47357 1.64735Z"
                        fill="#E33629"
                      />
                      <path
                        d="M0.964809 10.239C1.16724 9.23579 1.50331 8.26423 1.96404 7.35034L5.73024 10.2736C5.23756 11.7245 5.23756 13.2974 5.73024 14.7483C4.47545 15.7173 3.22005 16.6911 1.96404 17.6697C0.810656 15.3739 0.458893 12.758 0.964809 10.239Z"
                        fill="#F8BD00"
                      />
                      <path
                        d="M12.2307 10.3572H23.0442C23.4141 12.3864 23.314 14.4734 22.7517 16.458C22.2344 18.2829 21.2294 19.9323 19.8449 21.2288C18.6294 20.2805 17.4085 19.3394 16.1931 18.391C16.7957 17.9846 17.31 17.4606 17.7052 16.8506C18.1005 16.2406 18.3685 15.5571 18.4932 14.841H12.2307C12.2289 13.3476 12.2307 11.8524 12.2307 10.3572Z"
                        fill="#587DBD"
                      />
                      <path
                        d="M1.96222 17.6697C3.21823 16.7007 4.47363 15.7269 5.72842 14.7483C6.21388 16.173 7.13717 17.4079 8.3664 18.2765C9.13309 18.8184 10.0043 19.1946 10.9244 19.3811C11.8311 19.5497 12.7611 19.5497 13.6678 19.3811C14.5718 19.2281 15.4338 18.8877 16.1986 18.3819C17.414 19.3302 18.6349 20.2713 19.8503 21.2197C18.5202 22.4292 16.8988 23.2724 15.1448 23.6669C13.2089 24.122 11.1914 24.1002 9.26571 23.6033C7.7427 23.1967 6.3201 22.4798 5.08709 21.4977C3.78203 20.4615 2.7161 19.1558 1.96222 17.6697Z"
                        fill="#319F43"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3102_22496">
                        <rect
                          width="23.2549"
                          height="23.2549"
                          fill="white"
                          transform="translate(0.372528 0.882568)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="ml-2 fw-500">Continue with Google  {isGooglePending && <CircularProgress style={{ color: 'white' }} className='text-white' size="1rem" />} </span>
                </Button>
              </Stack>
            </div> */}
            <div className="py-4">
              <p className="mb-0 text-center fw-500">
                <span>Already have an account ? </span>
                <span className={`${Styles.signup_switch}`}>
                  <Link to="/login" component={RouterLink} className="fw-600">
                    Login
                  </Link>
                </span>
              </p>
            </div>
            {/* <div className={`${Styles.forgot}`}>
              <p className='d-flex m-0' style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <Checkbox {...label} id='agree' style={{ verticalAlign: 'middle', margin: '0' }} />
                <label className='mt-2' for='agree' style={{fontSize: '14px', fontWeight: '600', color: '#4B5563'}}>By continuing I accept Launchpad's <Link href=''>Terms</Link> and <Link href=''>Privacy Policies</Link></label>
              </p>
            </div> */}
          </div>
          <Footer />
        </StyledContent>
      </StyledRoot>
    </>
  );
}
