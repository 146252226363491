import { Helmet } from "react-helmet-async";
// import * as Router from 'react-router-dom';
// import Scrollbar from "../../../components/scrollbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import DatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import TextField from "@mui/material/TextField";
import { Link as RouterLink } from "react-router-dom";
import Scrollbar from "../../components/scrollbar";

import {
    Link,
} from "@mui/material";
import {
    // useEffect, 
    useState
} from "react";
// import bus from "src/bus";
import styles from "./LedgerTable.module.css";
// ----------------------------------------------------------------------

export default function LedgerEntries() {
    const [effectiveDate, setEffectiveDate] = useState(new Date());


    return (
        <>
            <Helmet>
                <title>Edit Entries</title>
            </Helmet>

            <div className="wrapper pb-5">
                <div className={`${styles.header_wrap} d-sm-flex justify-content-between align-items-center`}>
                    <div className="d-flex">
                        <Link
                            className="d-block text-secondary"
                            to="/app/ledger-management/ledger-entries"
                            component={RouterLink}
                        >
                            <i
                                className="bi bi-x"
                                style={{ fontSize: "1.5em", lineHeight: "1" }}
                            ></i>
                        </Link>
                        <h6 className="pl-2 pl-sm-3 mb-0 fw-700 pb-0" style={{ lineHeight: '1.5' }} >Edit Entries</h6>
                    </div>
                    <div className="header_btn_group d-none d-sm-block mt-2 mt-sm-0">
                        <div className="btn-group">
                            <button
                                className="btn border btn-primary"
                            >
                                <span className="button_text px-2 text-white">Save</span>
                            </button>
                            &nbsp;
                            &nbsp;
                            <Link
                                to="/app/ledger-management/ledger-entries"
                                component={RouterLink}
                                className="btn border"
                            >
                                <span className="button_text px-2">Cancel</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="header_btn_group d-block d-sm-none mt-3 mt-sm-0">
                    <div className="btn-group">
                        <button
                            className="btn border btn-primary"
                        //   onClick={() => setTrsnPinDialog(true)}
                        >
                            <span className="button_text px-2 text-white">Save</span>
                        </button>
                        &nbsp;
                        &nbsp;
                        <Link
                            to="/app/ledger-management/ledger-entries"
                            component={RouterLink}
                            className="btn border"
                        >
                            <span className="button_text px-2">Cancel</span>
                        </Link>
                    </div>
                </div>

                <div className=" pr-md-4 pr-lg-5" style={{ width: '53em', maxWidth: '100%' }}>
                    <div className="w-100 pr-md-4 pr-lg-5">
                        <div className={`${styles.trftobank_side_wrap} mt-sm-4  pt-4 py-sm-2`}>

                            <div className="">


                                <div className="edit_trsn_effective_date mb-3">
                                    <div className="">
                                        <label htmlFor="ounit">Effective Date</label>
                                        <div className="" style={{ width: "22em", maxWidth: '100%' }}>
                                            <div
                                                style={{ position: "relative" }}
                                            >
                                                <span
                                                    style={{
                                                        position: "absolute",
                                                        right: "10px",
                                                        top: "5px",
                                                        zIndex: "0",
                                                    }}
                                                >
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.66602 1.6665V4.1665" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M13.334 1.6665V4.1665" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M2.91602 7.5752H17.0827" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M17.5 7.08317V14.1665C17.5 16.6665 16.25 18.3332 13.3333 18.3332H6.66667C3.75 18.3332 2.5 16.6665 2.5 14.1665V7.08317C2.5 4.58317 3.75 2.9165 6.66667 2.9165H13.3333C16.25 2.9165 17.5 4.58317 17.5 7.08317Z" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M13.0781 11.4167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M13.0781 13.9167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M9.99607 11.4167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M9.99607 13.9167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M6.91209 11.4167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M6.91209 13.9167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>

                                                </span>
                                                <DatePicker
                                                    // showIcon
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={15}
                                                    selected={effectiveDate}
                                                    onChange={(date) => setEffectiveDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control w-100 border"
                                                    placeholderText="Select Date"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3 pb-0">
                                    <div className="">
                                        <label htmlFor="ounit">Description*</label>
                                        <div className="" style={{ width: "22em", maxWidth: '100%' }}>
                                            <div className="">
                                                <TextField
                                                    name=""
                                                    required
                                                    type="text"
                                                    id="ounit"
                                                    size="small"
                                                    placeholder=""
                                                    fullWidth
                                                    defaultValue={`Bank Transfer`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-100">
                            <div className="pb-2 mb-3 d-flex justify-content-between align-items-center border-bottom">
                                <h6 className=" mb-0 fw-700 pb-0" style={{ lineHeight: '1.5' }} >Entries</h6>
                                <span className="text-dark fw-500 cursor-pointer" style={{ textDecoration: 'underline', textUnderlineOffset: '3px' }}>Add Entries</span>

                            </div>
                            <Scrollbar>
                                <div className="pb-2  border-bottom" style={{ minWidth: '40em' }}>
                                    <div className="row">
                                        <div className="col-4">
                                            <label htmlFor="">Ledger Account</label>
                                        </div>
                                        <div className="col-2">
                                            <label htmlFor="">Debit</label>
                                        </div>
                                        <div className="col-2">
                                            <label htmlFor="">Credit</label>
                                        </div>
                                    </div>
                                </div>
                                <div className={`pt-4 pb-2 `} style={{ minWidth: '40em' }}>
                                    <div className="row mb-4">
                                        <div className="col-4 mb-0 pb-0">
                                            <div className="">
                                                <div className="" style={{ width: "100%" }}>
                                                    <div className="position-relative">
                                                        <span className=" all_selectdropdown_icon position-absolute bg-white px-2">
                                                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z" fill="#6B7280" />
                                                            </svg>
                                                        </span>
                                                        <Select
                                                            labelId="s"
                                                            id="s"
                                                            fullWidth
                                                            size="small"
                                                            required
                                                            name="verified"
                                                            defaultValue="cash"
                                                        >
                                                            <MenuItem value="cash">Cash</MenuItem>
                                                            <MenuItem value="debtors">Debtors</MenuItem>
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-3 mb-0 pb-0">
                                            <div className="">
                                                <div className="position-relative ">
                                                    {/* <span className="position-absolute" style={{
                                                        zIndex: '10',
                                                        top: '50%',
                                                        left: '15px',
                                                        transform: 'translateY(-50%)'
                                                    }}>$</span> */}
                                                    <TextField
                                                        name="min_products"
                                                        required
                                                        type="text"
                                                        fullWidth
                                                        id="ounit"
                                                        placeholder=""
                                                        size="small"
                                                        defaultValue={`₦24,000,000.00`}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-3 mb-0 pb-0">
                                            <div className="">
                                                <div className="position-relative ">
                                                    <TextField
                                                        name="min_products"
                                                        required
                                                        type="text"
                                                        fullWidth
                                                        id="ounit"
                                                        placeholder=""
                                                        size="small"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2 mb-0 pb-0">
                                            <div className="fit-content ml-auto">
                                                <span className="btn py-0 px-1 rounded-0 fs-18">
                                                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.8145 12.6357L21.1187 21.2982" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M12.6344 21.1187L21.2969 12.8145" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                    </div><div className="row mb-4">
                                        <div className="col-4 mb-0 pb-0">
                                            <div className="">
                                                <div className="" style={{ width: "100%" }}>
                                                    <div className="position-relative">
                                                        <span className=" all_selectdropdown_icon position-absolute bg-white px-2">
                                                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z" fill="#6B7280" />
                                                            </svg>
                                                        </span>
                                                        <Select
                                                            labelId="s"
                                                            id="s"
                                                            fullWidth
                                                            size="small"
                                                            required
                                                            name="verified"
                                                            defaultValue="debtors"
                                                        >
                                                            <MenuItem value="cash">Cash</MenuItem>
                                                            <MenuItem value="debtors">Debtors</MenuItem>
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-3 mb-0 pb-0">
                                            <div className="">
                                                <div className="position-relative ">
                                                    {/* <span className="position-absolute" style={{
                                                        zIndex: '10',
                                                        top: '50%',
                                                        left: '15px',
                                                        transform: 'translateY(-50%)'
                                                    }}>$</span> */}
                                                    <TextField
                                                        name="min_products"
                                                        required
                                                        type="text"
                                                        fullWidth
                                                        id="ounit"
                                                        placeholder=""
                                                        size="small"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-3 mb-0 pb-0">
                                            <div className="">
                                                <div className="position-relative ">
                                                    <TextField
                                                        name="min_products"
                                                        required
                                                        type="text"
                                                        fullWidth
                                                        id="ounit"
                                                        placeholder=""
                                                        size="small"
                                                        defaultValue={`₦24,000,000.00`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2 mb-0 pb-0">
                                            <div className="fit-content ml-auto">
                                                <span className="btn py-0 px-1 rounded-0 fs-18">
                                                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.8145 12.6357L21.1187 21.2982" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M12.6344 21.1187L21.2969 12.8145" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Scrollbar>

                            <div className="py-3 border-top w-100 overflow-hidden">
                                <div className="w-100">
                                    <div className="row mx-0 px-0 mb-4">
                                        <div className="col-sm-4">
                                            <label className="fs-14 fw-700" htmlFor="">Total (NGN)</label>
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="d-block ml-auto ml-sm-0 fs-14 fw-700" htmlFor="">₦24,000,000.00</label>
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="d-block ml-auto ml-sm-0 fs-14 fw-700" htmlFor="">₦24,000,000.00</label>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.64721 16C3.42377 16 0 12.4183 0 8C0 3.58172 3.42377 0 7.64721 0C11.8706 0 15.2944 3.58172 15.2944 8C15.2944 12.4183 11.8706 16 7.64721 16ZM7.64721 13.5C8.4391 13.5 9.08106 12.8284 9.08106 12C9.08106 11.1716 8.4391 10.5 7.64721 10.5C6.85531 10.5 6.21336 11.1716 6.21336 12C6.21336 12.8284 6.85531 13.5 7.64721 13.5ZM7.64721 2C7.11928 2 6.69131 2.44772 6.69131 3V8C6.69131 8.55229 7.11928 9 7.64721 9C8.17514 9 8.60311 8.55229 8.60311 8V3C8.60311 2.44772 8.17514 2 7.64721 2Z" fill="#F87171" />
                                            </svg>

                                            <span className="ml-2 fs-13 fw-500" style={{ color: 'rgba(55, 65, 81, 1)' }}>Credit and Debit entry must be equal</span>
                                        </div>
                                        <button type="button" className="close py-1 mt-1 px-3" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true" className="fs-20 py-1">&times;</span>
                                        </button>
                                    </div>
                                </div> */}
                            </div>

                            {/* <div>
                                <button className="btn px-0 mt-2" onClick={() => setAddCustomer(true)}>
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 12.5H18" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12 18.5V6.5" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className="ml-1 text-primary">Add a line</span>
                                </button>
                            </div> */}
                        </div>

                        {/* <div className="ml-auto" style={{ width: 'fit-content' }}>
                            <div className="btn-group">
                                <Link
                                    className="btn border btn-primary"
                                    to="/app/ledger-management/journal-entries"
                                    component={RouterLink}
                                >
                                    <span className="button_text text-white">Save</span>
                                </Link>
                                <Link
                                    className="btn border ml-2 ml-sm-3"
                                    to="/app/ledger-management/journal-entries"
                                    component={RouterLink}
                                >
                                    <span className="button_text">Cancel</span>
                                </Link>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}
