import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';

import {
    Link,
    Typography,
    Stack,
    Button
} from '@mui/material';
import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from "react-router-dom";

// components
// import Logo from '../../components/logo';
// import Styles from './CSSModules/FormPages.module.css'
import Styles from '../../Auth/CSSModules/FormPages.module.css'

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('0')]: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        boxSizing: 'border-box',
        // backgroundImage: `url(${imageUrl})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        padding: theme.spacing(4, 0),
    },
}));

const StyledContent = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    overflowX: 'hidden',
    position: 'relative',
    width: '80em',
    maxWidth: '100%',
    margin: '0',
    minHeight: '70vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'space-between',
    flexDirection: 'column',
    padding: theme.spacing(3, 0),
}));

// ----------------------------------------------------------------------

export default function PaymentOtpPage() {
    // const mdUp = useResponsive('up', 'md');
    const [otp, setOtp] = useState('');
    const [countdown, setCountdown] = useState(120); // 10 minutes in seconds
    const [isResendDisabled, setIsResendDisabled] = useState(true);

    // const navigate = useNavigate();

    const handleProceed = () => {
        // navigate('/reset-password');
    }

    // Function to handle OTP input changes
    const handleOtpChange = (event) => {
        const value = event.target.value;

        // Ensure the value is only numbers and has a maximum length of 4
        if (/^\d{0,4}$/.test(value)) {
            setOtp(value);
        }
    };

    useEffect(() => {
        // Start the countdown timer
        const timer = setTimeout(() => {
            if (countdown > 0) {
                setCountdown(countdown - 1);
            } else {
                setIsResendDisabled(false); // Enable the "Resend OTP" link
            }
        }, 1000);

        // Clear the timer when the component unmounts
        return () => clearTimeout(timer);
    }, [countdown]); // Re-run effect when countdown changes

    // Format the countdown timer as mm:ss
    const formatTime = (seconds) => {
        // const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        // return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
        return `${remainingSeconds.toString()}`;
    };


    return (
        <>
            <Helmet>
                <title> Wallet OTP  </title>
            </Helmet>

            <StyledRoot className='px-md-3'>
                <StyledContent className={`${Styles.StyledContent}`}>
                    {/* <div className={`${Styles.logo_wrap}`}>
                        <Logo />
                    </div> */}

                    <div className={`${Styles.signin_form_wrap} my-3  my-md-5 mx-sm-auto ml-md-5 pl-md-5`}>
                        <Typography variant='h3'>
                            Enter OTP
                        </Typography>
                        <div className={`${Styles.signup_switch}`}>
                            <span className='' style={{ color: '#4B5563' }}>We’ve sent a message to michealjoseph344@gmail.com with an otp to proceed with this transaction</span>
                        </div>
                        <div className={`${Styles.input_box}`}>
                            <label>OTP</label>
                            <Box
                                sx={{
                                    maxWidth: '100%',
                                }}
                            >
                                <TextField
                                    type='text'
                                    value={otp}
                                    onChange={handleOtpChange}
                                    fullWidth
                                    inputProps={{
                                        maxLength: 4,
                                        style: { textAlign: 'center' }
                                    }}
                                />
                            </Box>
                        </div>
                        <div>
                            <Stack spacing={2} direction="row">
                                <Button onClick={handleProceed} className={`${Styles.signin_btn}`}>Proceed</Button>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                                {/* <div className='d-flex justify-content-center align-items-center'>
                                    <span className='mr-2'>
                                        <i className='bi bi-clock' style={{ color: '#4B5563' }}></i>
                                    </span>
                        <label className='mb-0'>{formatTime(countdown)}</label>
                                </div> */}
                                <div className={`${Styles.forgot}`}>
                                    {/* <Link href=''>Forgot Password?</Link> */}
                                    <Link
                                        to='/app/overview'
                                        component={RouterLink}
                                        onClick={(e) => isResendDisabled && e.preventDefault()}
                                        className={`${isResendDisabled && 'text-secondary'}`}
                                    >
                                        {isResendDisabled && (
                                            <span>Resend OTP in {formatTime(countdown)} seconds</span>
                                        )}

                                        {!isResendDisabled && (
                                            <span>Resend OTP</span>
                                        )}
                                    </Link>
                                </div>
                            </Stack>
                        </div>

                    </div>
                </StyledContent>
            </StyledRoot>
        </>
    );
}
