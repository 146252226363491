import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

import { Link, Typography, Stack, Button } from "@mui/material";
// components
import Logo from "../../../components/logo";
// Module styles
import Styles from "../CSSModules/FormPages.module.css";
import axiosInstance from "src/axiosInstance"

// sections
import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { BaseUrlContext } from "src/context/BaseUrlContext";
import { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "src/hooks/useAuth";
import bus from "src/bus";
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import { signInWithGoogle } from "../../../theme/firebaseConfig"; // Import the sign-in function

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import Preloader from 'src/preloader';
import Footer from '../Footer';
// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme, imageUrl }) => ({
  [theme.breakpoints.up("0")]: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    boxSizing: "border-box",
    backgroundColor: "#FFFFFF",
    // backgroundImage: `url(${imageUrl})`,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    padding: theme.spacing(4, 0),
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  overflowX: "hidden",
  position: "relative",
  width: "80em",
  maxWidth: "100%",
  margin: "0",
  minHeight: "90vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "space-between",
  flexDirection: "column",
  padding: theme.spacing(3, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const apiUrl = process.env.REACT_APP_NAME;
  const imageUrl = "/assets/images/op_background.png";
  const navigate = useNavigate();
  const { dispatch } = useAuth();
  const [loginError, setLoginError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const baseUrl = useContext(BaseUrlContext);
  const [showText, setShowText] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const [linkParam, setLinkParam] = useState(urlParams.get('link'));
  const authEmail = localStorage.getItem("e");
  const [isChecked, setIsChecked] = useState(false);
  const [isGooglePending, setIsGooglePending] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;

  // const notify = (msg) => toast.success(msg);

  // const notifyError = (err) =>
  //   notifyError(err);

  useEffect(() => {
    bus.on("email", (val) => {
      if (val) {
        setEmail(localStorage.getItem("e"));
      }
    });
  }, [email]);

  useEffect(() => {
    let isAuthAval = localStorage.getItem('auth');
    if (isAuthAval) {
      localStorage.setItem('awaiting_kyc_verification', JSON.stringify(false))
                localStorage.setItem('has_dived_in', JSON.stringify(false));
                navigate('/app/overview');
    }
  })

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const changeType = () => {
    setShowText(!showText);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  function HandleFormSubmit(e) {
    e.preventDefault();

    let payload = {
      emailAddress: email,
      // password: password,
    };

    const fetchData = async () => {
      try {
        setIsPending(true);
        setLoginError(null);
        if (payload.emailAddress) {
          const res = await axiosInstance.post(`/accounts/magic-link-otp?productName=${apiUrl}`, payload);
          notifySuccess("Login Successful!");
          // localStorage.setItem("auth", JSON.stringify( res.data.data));
          // localStorage.setItem("dl", today);
          localStorage.setItem("e", payload.emailAddress);
          dispatch({ type: "LOGIN", payload: res.data.data });

          navigate("/login-via-link");
          setTimeout(() => {
          }, 2000);
        }

      } catch (err) {
        if (err.response) {
          if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
            notifyError(err.response.data.data.errorMessage);
          } else if (err.response.data && err.response.data.message) {
            notifyError(err.response.data.message);
          } else {
            notifyError('An unexpected error occurred');
          }
        } else {
          notifyError('Network error or server not responding');
        }
        // setLoginError(err.response.data.data.errorMessage);
        localStorage.setItem("e", payload.emailAddress);
        // notifyError(err.response.data.data.errorMessage);

      } finally {
        setIsPending(false);
      }
    };
    fetchData(true);
  }

  const proceedToLogin = async () => {
    setIsPending(true);
    let payload = {
      email: authEmail,
      otp: linkParam ?? linkParam,
    };
    try {
      // if(!payload.email || !payload.otp) return notifyError('Invalid Email or Magic Link')
      if (payload.email && payload.otp) {
        const res = await axiosInstance.post(`/accounts/magic-link-login?productName=${apiUrl}`, payload);
        localStorage.setItem("auth", JSON.stringify(res.data.data));
        dispatch({ type: "LOGIN", payload: res.data.data });

        localStorage.removeItem('e')
        localStorage.setItem('awaiting_kyc_verification', JSON.stringify(false))
                localStorage.setItem('has_dived_in', JSON.stringify(false));
                navigate("/app/overview");
      }

    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
          notifyError(err.response.data.data.errorMessage);
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
        } else {
          notifyError('An unexpected error occurred');
        }
      } else {
        notifyError('Network error or server not responding');
      }
      // setLoginError(err.response.data.data.errorMessage);
      if (err.response.data.data.errorMessage === 'Email not verified') {
        setTimeout(() => {
          navigate('/verify-email');
        }, 1000);
      }
    } finally {
      setIsPending(false);
      setLinkParam(null)
    }
  }

  useEffect(() => {
    const storedEmail = JSON.parse(localStorage.getItem('me'));
    if (storedEmail) {
      setIsChecked(true);
      setEmail(storedEmail); // Optionally set the email if you want to prefill the input field
    }
  }, []);

  const rememberMe = (event) => {
    const isChecked = event.target.checked;
    setIsChecked(isChecked);

    if (isChecked) {
      localStorage.setItem('me', JSON.stringify(email));
    } else {
      localStorage.removeItem('me');
    }
  };


  const handleGoogleSignIn = async () => {
    setIsGooglePending(true);
    try {
      // const result = await signInWithGoogle();
      const result = await axiosInstance.get(`/accounts/login/oauth/google?productName=${apiUrl}&actionType=login`);
      // You can get user info from result.user
      window.open(result.data.data.link, '_blank')
      // Redirect or handle the authenticated user as needed
    } catch (error) {
      console.error("Google Sign-In Error:", error);
      setIsGooglePending(false);
    }
  };

  useEffect(() => {
    const me = JSON.parse(localStorage.getItem('me'))
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
    if (me) {
      setEmail(me)
    }
  }, []);

  useEffect(() => {
    proceedToLogin();
  }, [linkParam]);

  return (
    <>
      <Helmet>
        <title> Login </title>
      </Helmet>
      {linkParam ? (
        <Preloader />
      ):(
        <>
      {!isLoading && (
        <StyledRoot imageUrl={imageUrl} className="px-3">
          <StyledContent className={`${Styles.StyledContent}`}>
            <div className={`${Styles.logo_wrap}`}>
              <Logo />
            </div>

            <div
              className={`${Styles.signin_form_wrap} py-5 my-3 py-md-5 my-md-5 mx-sm-auto ml-md-5 pl-md-5`}
            >
              <form onSubmit={HandleFormSubmit} className="w-100">
                <Typography variant="h4">Log In</Typography>
                {loginError && (
                  <Alert
                    style={{
                      border: "1px solid red",
                    }}
                    severity="error"
                  >
                    {loginError}
                  </Alert>
                )}

                {/* <div className={`${Styles.signup_switch}`}><Link href='/signup'>or Create an account</Link></div> */}
                <div className={`${Styles.input_box} my-4`}>
                  <label>Business Email</label>
                  <Box
                    sx={{
                      maxWidth: "100%",
                    }}
                  >
                    <TextField
                      required
                      type="text"
                      id="email"
                      placeholder=""
                      value={email}
                      onChange={handleEmailChange}
                      fullWidth
                    />
                  </Box>
                </div>

                {/* Password */}

                {/* <div className={`${Styles.input_box}`}>
                <label>Password</label>

                <FormControl sx={{ width: '100%' }} variant="outlined">
                  <OutlinedInput
                    placeholder='Create a password'
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff style={{ fontSize: '30px' }} /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </FormControl>
              </div> */}

                {/* Confirm Password */}

                {/* <div className="mt-4 mb-3 inputs_n_label">
                <label htmlFor="password" className="text-dark">
                  Password
                </label>
                <div className="position-relative">
                  <TextField
                    required
                    type={showText ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder="****"
                    fullWidth
                  />
                  <div className="icon mt-2" onClick={changeType}>
                    {!showText && (
                      <img src="/assets/icons/eye.svg" alt="" width={20} />
                    )}
                    {showText && (
                      <img src="/assets/icons/eye-off.svg" alt="" width={20} />
                    )}
                  </div>
                </div>
              </div> */}

                {/* <div className="w-100 text-right mb-3">
                <Link to="" component={RouterLink} className="fs-14 fw-600">
                  Forgot your password?
                </Link>
              </div> */}
                <div>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 1 }}>
                    <div >
                      <Checkbox id='remember' name="remember" label="Remember me" checked={isChecked} onChange={rememberMe} />
                      <label htmlFor="remember" className="text-dark fs-14 cursor-pointer">Remember me</label>
                    </div>
                    {/* <div className={`${Styles.forgot}`}>
                <Link 
                // to='/forgot-password' component={RouterLink}
                to='/use-password' component={RouterLink} 
                className="fs-14 fw-500">Forgot your password?</Link>
              </div> */}
                  </Stack>
                </div>

                <Stack spacing={2} direction="row">
                  <div className="w-100 white_circular_progress">
                    {isPending ? (
                      <>
                        <Button
                          disabled
                          className={`${Styles.signin_btn} mb-0 position-relative`}
                          style={{ verticalAlign: "middle" }}
                        >
                          <span className="fw-500 text-white">
                            Authenticating...
                          </span>
                          {isPending && (
                            <CircularProgress
                              style={{ color: "white" }}
                              className="text-white"
                              size="1rem"
                            />
                          )}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          disabled={email === ""}
                          type="submit"
                          className={`${Styles.signin_btn}  ${email ? Styles.signin_btn_bg : ""
                            } mb-0 position-relative`}
                          style={{ verticalAlign: "middle" }}
                        >
                          <span className="fw-500 text-white">Login</span>
                          {isPending && (
                            <CircularProgress
                              style={{ color: "white" }}
                              className="text-white"
                              size="1rem"
                            />
                          )}
                        </Button>
                      </>
                    )}
                    {/*}
                  {!isPending &&
                    <Button type='submit' className={`${Styles.signin_btn} mb-0 position-relative`} style={{ verticalAlign: 'middle' }}>
                      <span className='fw-500 text-white'>Login</span>
                      {isPending && <CircularProgress style={{ color: 'white' }} className='text-white' size="1rem" />}
                    </Button>}
                  {isPending && <Button disabled className={`${Styles.signin_btn} mb-0 position-relative`} style={{ verticalAlign: 'middle' }}>
                    <span className='fw-500 text-white'>Signing you in</span>
                    {isPending && <CircularProgress style={{ color: 'white' }} className='text-white' size="1rem" />}
                  </Button>}
                    {*/}
                  </div>
                </Stack>
              </form>

              <div className="py-3">
                <hr
                  style={{
                    border: "none",
                    borderBottom: "1px solid #D1D5DB",
                    background: "none",
                  }}
                />
              </div>

              {/* <div>
                <Stack spacing={2} direction="row">
                  <Button
                    className="d-block w-100 text-center border py-2"
                    style={{ background: "#F9FAFB", border: "1px solid #E5E7EB" }}
                    // onClick={handleGoogleSignIn}
                    disabled={isGooglePending}
                    onClick={() =>
                      handleGoogleSignIn()
                    }
                  >
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_3102_22496)">
                        <path
                          d="M8.47357 1.64745C6.15005 2.4535 4.14625 3.98341 2.75649 6.01247C1.36673 8.04152 0.664279 10.4628 0.752304 12.9206C0.840329 15.3783 1.71419 17.7431 3.24554 19.6676C4.77689 21.592 6.88501 22.9746 9.26024 23.6124C11.1859 24.1093 13.2034 24.1311 15.1394 23.676C16.8931 23.2821 18.5146 22.4394 19.8448 21.2306C21.2294 19.934 22.2344 18.2846 22.7517 16.4597C23.314 14.4752 23.4141 12.3882 23.0442 10.3589H12.2307V14.8446H18.4932C18.368 15.56 18.0998 16.2428 17.7046 16.8522C17.3094 17.4616 16.7953 17.9849 16.1931 18.391C15.4284 18.8968 14.5663 19.2372 13.6623 19.3902C12.7556 19.5588 11.8256 19.5588 10.919 19.3902C10 19.2002 9.1307 18.8209 8.36638 18.2765C7.13851 17.4073 6.21654 16.1725 5.73204 14.7483C5.23936 13.2974 5.23936 11.7245 5.73204 10.2736C6.07692 9.25655 6.64704 8.33057 7.39985 7.56473C8.26136 6.67222 9.35205 6.03426 10.5523 5.72082C11.7525 5.40739 13.0158 5.4306 14.2037 5.78791C15.1317 6.07276 15.9803 6.57047 16.6818 7.24134C17.3879 6.53885 18.0929 5.83454 18.7966 5.12841C19.1599 4.7487 19.556 4.38716 19.9139 3.99837C18.843 3.00182 17.586 2.22638 16.2149 1.71649C13.7181 0.80989 10.9862 0.785526 8.47357 1.64745Z"
                          fill="white"
                        />
                        <path
                          d="M8.47357 1.64735C10.9859 0.784847 13.7179 0.80857 16.2149 1.71458C17.5862 2.22793 18.8427 3.00711 19.9121 4.00736C19.5487 4.39615 19.1654 4.75951 18.7947 5.1374C18.0898 5.84111 17.3855 6.54239 16.6818 7.24124C15.9803 6.57037 15.1317 6.07267 14.2037 5.78781C13.0162 5.42925 11.7529 5.4047 10.5524 5.71685C9.35184 6.02901 8.26048 6.6658 7.39803 7.55736C6.64522 8.32321 6.07509 9.24919 5.73022 10.2662L1.96402 7.35025C3.31209 4.67696 5.64619 2.6321 8.47357 1.64735Z"
                          fill="#E33629"
                        />
                        <path
                          d="M0.964809 10.239C1.16724 9.23579 1.50331 8.26423 1.96404 7.35034L5.73024 10.2736C5.23756 11.7245 5.23756 13.2974 5.73024 14.7483C4.47545 15.7173 3.22005 16.6911 1.96404 17.6697C0.810656 15.3739 0.458893 12.758 0.964809 10.239Z"
                          fill="#F8BD00"
                        />
                        <path
                          d="M12.2307 10.3572H23.0442C23.4141 12.3864 23.314 14.4734 22.7517 16.458C22.2344 18.2829 21.2294 19.9323 19.8449 21.2288C18.6294 20.2805 17.4085 19.3394 16.1931 18.391C16.7957 17.9846 17.31 17.4606 17.7052 16.8506C18.1005 16.2406 18.3685 15.5571 18.4932 14.841H12.2307C12.2289 13.3476 12.2307 11.8524 12.2307 10.3572Z"
                          fill="#587DBD"
                        />
                        <path
                          d="M1.96222 17.6697C3.21823 16.7007 4.47363 15.7269 5.72842 14.7483C6.21388 16.173 7.13717 17.4079 8.3664 18.2765C9.13309 18.8184 10.0043 19.1946 10.9244 19.3811C11.8311 19.5497 12.7611 19.5497 13.6678 19.3811C14.5718 19.2281 15.4338 18.8877 16.1986 18.3819C17.414 19.3302 18.6349 20.2713 19.8503 21.2197C18.5202 22.4292 16.8988 23.2724 15.1448 23.6669C13.2089 24.122 11.1914 24.1002 9.26571 23.6033C7.7427 23.1967 6.3201 22.4798 5.08709 21.4977C3.78203 20.4615 2.7161 19.1558 1.96222 17.6697Z"
                          fill="#319F43"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3102_22496">
                          <rect
                            width="23.2549"
                            height="23.2549"
                            fill="white"
                            transform="translate(0.372528 0.882568)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="ml-2 fw-500">Continue with Google  {isGooglePending && <CircularProgress style={{ color: 'white' }} className='text-white' size="1rem" />}</span>
                  </Button>
                </Stack>
              </div> */}
              <div className="py-4">
                <p className="mb-0 text-center fw-500">
                  <span>Don't have an account ? </span>
                  <span className={`${Styles.signup_switch}`}>
                    <Link to="/signup" component={RouterLink} className="fw-600">
                      {" "}
                      Sign Up
                    </Link>
                  </span>
                </p>
              </div>
            </div>
            <Footer />
          </StyledContent>
        </StyledRoot>
      )}
        </>
      )}
    </>
  );
}
