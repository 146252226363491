// ----------------------------------------------------------------------
import { Helmet } from "react-helmet-async";
import styles from "./CustomerComponent.module.css";
import {
  useEffect,
  useState,
  useRef,
} from "react";
import React from "react";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import CustomerTable from "./customer_comp/CustomerTable";

import "react-datepicker/dist/react-datepicker.css";
import GlobalModal from "../../Modals/GlobalModal";

import { Link as RouterLink, useNavigate } from "react-router-dom";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Link,
  //   Avatar,
} from "@mui/material";
// import Scrollbar from "../../components/scrollbar";
// sections
// mock
import Box from "@mui/material/Box";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import CustomerGroupTab from "./customer_comp/CustomerGroupTab";
import CustomersTab from "./customer_comp/CustomersTab";
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import Preloader from 'src/preloader';
import axiosInstance from "src/axiosInstance"
import bus from 'src/bus'
import { format } from 'date-fns';

// ----------------------------------------------------------------------

export default function CustomerOverview() {
  // eslint-disable-next-line no-unused-vars
  const [isDataAvl, setIsDataAvl] = useState(false);
  const [value, setValue] = React.useState("1");
  const tableDataRef = useRef({});
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({});
  const navigate = useNavigate();
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [IsKYCVerified, setIsKYCVerified] = useState(false);
  let KYC_Status = JSON.parse(localStorage.getItem('kyc_status'))
  let userDetails = JSON.parse(localStorage.getItem('userDetails'))
  const [hasLength, setHasLength] = useState(false);
  const [currentRef, setCurrentRef] = useState('')
  const [allCustomersData, setallCustomersData] = useState([]);
  const apiUrl = process.env.REACT_APP_NAME;
  const [isPending, setIsPending] = useState(false);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  // 
  const handleRouteToTrf = () => {
    navigate('/app/payment/transfers/single')
  }
  const handleToggleIsDataAvl = () => {
    setIsDataAvl(true);
    setHasLength(true);
    setIsKYCVerified(true);
  };


  useEffect(() => {
    if ((KYC_Status !== null && KYC_Status) || (userDetails !== null && userDetails.account !== null && userDetails.account.kyc_status)) {
      setIsKYCVerified(true);
    } else {
      setIsKYCVerified(false);
    }

    // setTimeout(() => {
    if (allCustomersData.length > 0) {
      setHasLength(true)
      // localStorage.setItem('hasLength', JSON.stringify(true))
      setIsDataAvl(true);
      // setShowLedgerBanner(true);
    } else {
      setIsDataAvl(false);
      // localStorage.setItem('hasLength', JSON.stringify(false))
    }

    fetchCustomers();
  }, [])

  const fetchCustomers = async () => {
    setIsPending(true)

    try {
      const response = await axiosInstance.get(`/payments/customers?productName=${apiUrl}`)
      const data = response.data.data.data
      setallCustomersData(data)
      if (data.length > 0) {
        setHasLength(true)
        // localStorage.setItem('hasLength', JSON.stringify(true))
      }
      setIsLoadingPage(false)
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
          notifyError(err.response.data.data.errorMessage);
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
        } else {
          notifyError('An unexpected error occurred');
        }
      } else {
        notifyError('Network error or server not responding');
      }
    } finally {
      setIsPending(false)
      setIsLoadingPage(false)
    }
  };

  useEffect(() => {
    setIsLoadingPage(true)
    if (hasLength && !allCustomersData.length) {
      setHasLength(true)
    }
  }, [])

  const handleDownloadFile = async (extension) => {
    try {
      let tableData;
      switch (value) {
        case "1":
          tableData = tableDataRef.current["customers"];
          break;
        case "2":
          tableData = tableDataRef.current["customerGroup"];
          break;
        default:
          break;
      }

      if (!tableData || tableData.length === 0) {
        throw new Error("Table not found");
      }

      if (extension === "csv") {
        exportTableDataToCSV(tableData, "Table Data.csv");
      } else if (extension === "pdf") {
        exportTableDataToPDF(tableData, "Table Data.pdf");
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
          notifyError(err.response.data.data.errorMessage);
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
        } else {
          notifyError('An unexpected error occurred');
        }
      } else {
        notifyError('Network error or server not responding');
      }
      console.error(err);
      notifyError(`Unable to export ${extension} file`);
    }
  };

  const exportTableDataToCSV = (tableData, filename) => {
    const headers = ["Customer Name", "Email", "Phone Number", "Account Bank Account No"];
    const csvContent = [
      headers.join(","),
      ...tableData.map((row) =>
        [row.trn_id, row.status, row.amount, row.date].join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, filename);
  };

  const exportTableDataToPDF = (tableData, filename) => {
    const doc = new jsPDF();
    const tableColumn = ["Customer Name", "Email", "Phone Number", "Account Bank", "Account No"];
    const tableRows = [];

    tableData.forEach((item) => {
      const rowData = [item.name, item.email, item.phone, item.cr_account_bank, item.cr_account_number];
      tableRows.push(rowData);
    });

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.save(filename);
  };

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [addCustomer, setAddCustomer] = useState(false);

  const handleCloseAddCustomer = () => {
    setAddCustomer(false);
  };

  const handleToggleDropdown = () => {
    setToggleDropdown(!toggleDropdown);
  };

  const handleClearFilter = () => {
    setToggleDropdown(false)
    setFrom(null);
    setTo(null);
    setFilter({
      from: null,
      to: null,
    })
  }

  const handleApplyFilter = async () => {
    if (from && to) {
      setFilter({
        from: format(new Date(from), 'yyyy-MM-dd'),
        to: format(new Date(to), 'yyyy-MM-dd'),
      });
      setToggleDropdown(false);
    } else {
      notifyError('Kindly select both dates to proceed');
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }


  return (
    <>
      <Helmet>
        <title>Customers</title>
      </Helmet>

      {IsKYCVerified ?
        (
          <>
            {isLoadingPage ? (
              <Preloader />
            ) : (
              <>
                {
                  (hasLength) &&
                  <div className="px-0">
                    <div
                      onClick={() => handleToggleDropdown()}
                      className={`overlay_div ${toggleDropdown ? "show_overlay" : ""}`}
                    ></div>

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="">
                        <h5 className="page_title mb-2">Customers</h5>
                        <p style={{ color: "#4B5563", fontSize: "13px" }} className="mt-">
                          <span className="fs-12 fw-500">Manage your Customers</span>
                        </p>
                      </div>
                    </div>

                    <div className="d-md-flex justify-content-between align-items-center mt-sm-4 w-100">
                      <div
                        className="d-flex justify-content-between align-items-center mb-3 mb-md-0 w-100"
                        style={{ gap: "10px", width: "fit-content" }}
                      >
                        <div className={`${styles.hold_input} w-100`}>
                          <img src="/assets/icons/search.svg" alt="" />
                          <input
                            type="text"
                            name="search"
                            placeholder="Search"
                            style={{
                              paddingLeft: "33px",
                              width: "24em",
                              maxWidth: "100%",
                              borderRadius: "0",
                              fontSize: "13px",
                              minHeight: "40px",
                            }}
                            className=" form-control rounded-0"
                            value={search}
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <div
                          className="fit-content d-flex"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <div className="btn-group">
                            <Link
                              to="/app/customer/create-new-group"
                              component={RouterLink}
                              className="btn border btn-primary"
                              type="button"
                            >
                              <svg
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.28125 6.5H9.12461"
                                  stroke="white"
                                  strokeWidth="1.16867"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6.20312 9.42198V3.57861"
                                  stroke="white"
                                  strokeWidth="1.16867"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>{" "}
                              <span className="button_text text-white">New Group</span>
                            </Link>
                          </div>
                          &nbsp;&nbsp;
                          <div className="btn-group">
                            <button
                              data-toggle="dropdown"
                              aria-expanded="false"
                              className="btn border btn-primary"
                              type="button"
                            >
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.51855 8.93359V10.3937C1.51855 10.9313 1.95436 11.3671 2.49195 11.3671H9.30576C9.84335 11.3671 10.2792 10.9313 10.2792 10.3937V8.93359"
                                  stroke="#F3F4F6"
                                  strokeWidth="1.86"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M3.9519 6.5L5.8987 8.4468L7.8455 6.5"
                                  stroke="#F3F4F6"
                                  strokeWidth="1.86"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.89868 1.6333V8.4471"
                                  stroke="#F3F4F6"
                                  strokeWidth="1.86"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>{" "}
                              <span className="button_text text-white">Export</span>
                            </button>
                            <div
                              className="dropdown-menu dropdown-menu-right rounded border-0"
                              style={{
                                background: "#fff",
                                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              <button
                                className="dropdown-item dropdown_text fw-500 position-relative"
                                onClick={() => handleDownloadFile("csv")}
                              >
                                CSV
                              </button>
                              <button
                                className="dropdown-item dropdown_text fw-500 position-relative"
                                onClick={() => handleDownloadFile("pdf")}
                              >
                                PDF
                              </button>
                            </div>
                          </div>
                          &nbsp;&nbsp;
                          <div className="btn-group">
                            <Link
                              onClick={() => handleToggleDropdown()}
                              className="btn border btn-primary rounded d-flex align-items-center"
                            >
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.7523 5.01514L6.57741 8.19003C6.20246 8.56498 5.58891 8.56498 5.21396 8.19003L2.03906 5.01514"
                                  stroke="white"
                                  strokeWidth="1.16867"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>{" "}
                              <span className="ml-1 text-white">Filters</span>
                            </Link>
                            <div
                              className={`${toggleDropdown ? "show" : ""
                                } dropdown-menu dropdown-menu-right ${styles.drp_dwn_menu_right_topup
                                } drp_dwn_menu_right`}
                              style={{ transform: "translate3d(-310px, 40px, 0px)" }}
                            >
                              <div className={`${styles.filter_wrap} px-2 px-sm-4 py-3`}>
                                <div className="row">
                                  <div className="col-12 mb-1">

                                    <label htmlFor="ounit" className="fw-500">Custom Date Range</label>

                                    <div className="start_to d-flex">
                                      <div className="" style={{ width: "100%" }}>
                                        <div
                                          className="mb-3 bg-white"
                                          style={{ position: "relative" }}
                                        >
                                          <span
                                            style={{
                                              position: "absolute",
                                              right: "10px",
                                              top: "7px",
                                              zIndex: "0",
                                            }}
                                          >
                                            <svg
                                              width="14"
                                              height="14"
                                              viewBox="0 0 14 14"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M2.40527 4.29523C2.40527 3.38641 3.14202 2.64966 4.05084 2.64966H10.6331C11.5419 2.64966 12.2787 3.3864 12.2787 4.29523V10.8775C12.2787 11.7863 11.5419 12.5231 10.6331 12.5231H4.05084C3.14202 12.5231 2.40527 11.7863 2.40527 10.8775V4.29523Z"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M9.53516 1.55298V3.74707"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M5.14844 1.55298V3.74707"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M2.40527 5.94092H12.2787"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          </span>
                                          <DatePicker
                                            // showIcon
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={15}
                                            selected={from}
                                            onChange={(date) => setFrom(date)}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control border"
                                            placeholderText="Start date"
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="px-2 d-flex align-items-center "
                                        style={{ height: "40px" }}
                                      >
                                        <img
                                          src="/assets/icons/line.svg"
                                          style={{ width: "30px" }}
                                          alt=""
                                        />
                                      </div>
                                      <div className="" style={{ width: "100%" }}>
                                        <div
                                          className="mb-3 bg-white"
                                          style={{ position: "relative" }}
                                        >
                                          <span
                                            style={{
                                              position: "absolute",
                                              right: "10px",
                                              top: "7px",
                                              zIndex: "0",
                                            }}
                                          >
                                            <svg
                                              width="14"
                                              height="14"
                                              viewBox="0 0 14 14"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M2.40527 4.29523C2.40527 3.38641 3.14202 2.64966 4.05084 2.64966H10.6331C11.5419 2.64966 12.2787 3.3864 12.2787 4.29523V10.8775C12.2787 11.7863 11.5419 12.5231 10.6331 12.5231H4.05084C3.14202 12.5231 2.40527 11.7863 2.40527 10.8775V4.29523Z"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M9.53516 1.55298V3.74707"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M5.14844 1.55298V3.74707"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M2.40527 5.94092H12.2787"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          </span>
                                          <DatePicker
                                            // showIcon
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={15}
                                            selected={to}
                                            onChange={(date) => setTo(date)}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control border"
                                            placeholderText="End date"
                                            required
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-1">
                                  <div
                                    className="mr-auto"
                                    style={{ width: "fit-content" }}
                                  >
                                    <button
                                      type="button"
                                      className={`btn border bg-white`}
                                      onClick={handleClearFilter}
                                    >
                                      Clear
                                    </button>
                                    &nbsp; &nbsp;
                                    <button
                                      type="button"
                                      className={`btn btn-primary`}
                                      onClick={handleApplyFilter}
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ marginTop: "0px", borderRadius: "10px" }}
                      className="p-0 mt-4 create_invoice_tab_section"
                    >
                      <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <TabList aria-label="lab API tabs" onChange={handleTabChange}>
                            <Tab label="Customers" value="1" />
                            <Tab label="Customer Group" value="2" />
                          </TabList>
                        </Box>
                        <TabPanel value="1">
                          <CustomersTab
                            getTableData={data => tableDataRef.current['topUps'] = data} search={search} filter={filter}
                          />
                        </TabPanel>
                        <TabPanel value="2">
                          <CustomerGroupTab
                            getTableData={data => tableDataRef.current['topUps'] = data} search={search} filter={filter}
                          />
                        </TabPanel>
                      </TabContext>
                    </div>
                  </div>
                }

                {
                  (!hasLength) && (
                    <div
                      className="px-0 px-md-2 mt-3 py-5 w-100 d-flex justify-content-center align-items-center"
                      style={{ minHeight: "75vh" }}
                    >
                      <div className="rowWrap py-5 mx-auto fit-content w-100 h-auto">
                        <div
                          className="mb-4 text-center mx-auto"
                          style={{ width: "40em", maxWidth: "100%" }}
                        >
                          <div
                            className={`${styles.trfpage_cardbox} pb-3 mx-auto px-3 ml-sm-auto mr-sm-0 pr-md-3`}
                          >
                            <div
                              className="topside fit-content mx-auto pb-2"
                              // onClick={() => handleToggleIsDataAvl()}
                            >
                              <svg
                                width="49"
                                height="48"
                                viewBox="0 0 49 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.5 26H30.5H18.5ZM24.5 20V32V20ZM6.5 34V14C6.5 12.9391 6.92143 11.9217 7.67157 11.1716C8.42172 10.4214 9.43913 10 10.5 10H22.5L26.5 14H38.5C39.5609 14 40.5783 14.4214 41.3284 15.1716C42.0786 15.9217 42.5 16.9391 42.5 18V34C42.5 35.0609 42.0786 36.0783 41.3284 36.8284C40.5783 37.5786 39.5609 38 38.5 38H10.5C9.43913 38 8.42172 37.5786 7.67157 36.8284C6.92143 36.0783 6.5 35.0609 6.5 34Z"
                                  stroke="#9CA3AF"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>

                            {/* <div className="topside py-5 w-100" onClick={() => handleToggleIsDataAvl()} style={{ background: 'url(/assets/single_trf.gif) no-repeat', backgroundSize: 'cover', backgroundPosition: 'top center', minHeight: '170px', borderRadius: '6px' }}></div> */}
                            <div className="content w-100">
                              <h2 className="fw-600 text-dark">
                                You have no customer currently
                              </h2>
                              <div className=" pt- text-center">
                                {/* <button
                                            className="btn btn-primary d-block fit-content mx-auto"
                                        >
                                            <span className="button_text">Get Started with ledger</span>
                                        </button> */}
                                <button
                                  // to="/app/payment/transfers/single"
                                  // component={RouterLink}
                                  onClick={handleRouteToTrf}
                                  className="btn d-block fit-content mx-auto mt-1"
                                >
                                  <span
                                    className="fs-14 fw-400"
                                    style={{
                                      textDecoration: "underline",
                                      textUnderlineOffset: "2px",
                                    }}
                                  >
                                    Initiate a transfer to see your customers
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </>
            )
            }
          </>
        ) :
        (
          <>
            <div
              className="px-0 px-md-2 mt-3 py-5 w-100 d-flex justify-content-center align-items-center"
              style={{ minHeight: "75vh" }}
            >
              <div className="rowWrap py-5 mx-auto fit-content w-100 h-auto">
                <div
                  className="mb-4 text-center mx-auto"
                  style={{ width: "40em", maxWidth: "100%" }}
                >
                  <div
                    className={`${styles.trfpage_cardbox} pb-3 mx-auto px-3 ml-sm-auto mr-sm-0 pr-md-3`}
                  >
                    <div
                      className="topside fit-content mx-auto pb-2"
                    >
                      <svg
                        width="49"
                        height="48"
                        viewBox="0 0 49 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.5 26H30.5H18.5ZM24.5 20V32V20ZM6.5 34V14C6.5 12.9391 6.92143 11.9217 7.67157 11.1716C8.42172 10.4214 9.43913 10 10.5 10H22.5L26.5 14H38.5C39.5609 14 40.5783 14.4214 41.3284 15.1716C42.0786 15.9217 42.5 16.9391 42.5 18V34C42.5 35.0609 42.0786 36.0783 41.3284 36.8284C40.5783 37.5786 39.5609 38 38.5 38H10.5C9.43913 38 8.42172 37.5786 7.67157 36.8284C6.92143 36.0783 6.5 35.0609 6.5 34Z"
                          stroke="#9CA3AF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>

                    {/* <div className="topside py-5 w-100" onClick={() => handleToggleIsDataAvl()} style={{ background: 'url(/assets/single_trf.gif) no-repeat', backgroundSize: 'cover', backgroundPosition: 'top center', minHeight: '170px', borderRadius: '6px' }}></div> */}
                    <div className="content w-100">
                      <h2 className="fw-600 text-dark">
                        You have no customer currently
                      </h2>
                      <div className=" pt- text-center">
                        {/* <button
                                            className="btn btn-primary d-block fit-content mx-auto"
                                        >
                                            <span className="button_text">Get Started with ledger</span>
                                        </button> */}
                        <button
                          // to="/app/payment/transfers/single"
                          // component={RouterLink}
                          onClick={() => bus.emit('not_verified', true)}
                          className="btn d-block fit-content mx-auto mt-1"
                        >
                          <span
                            className="fs-14 fw-400"
                            style={{
                              textDecoration: "underline",
                              textUnderlineOffset: "2px",
                            }}
                          >
                            Initiate a transfer to see your customers
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }

      {/* {!isDataAvl && (
        
      )} */}

      {/* {isDataAvl && (
        
      )} */}

      {/* <div
    className="d-flex justify-content-center align-items-center text-center"
    style={{
        minHeight: '80vh'
    }}>
    <h1>Customers Module 👋🏽</h1>
</div> */}

      {/* <div className="px-0">
            <div className="d-sm-flex justify-content-between align-items-center">
                    <div className="">
                        <h5 className="page_title">Customers</h5>
                    </div>
                    <div className="header_btn_group">
                        <div className="btn-group">
                            <div
                                onClick={() => handleToggleDropdown()}
                                className="btn border rounded"
                            >
                                <svg
                                    width="15"
                                    height="16"
                                    viewBox="0 0 15 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3.375 1.8125H11.625C12.3125 1.8125 12.875 2.375 12.875 3.0625V4.4375C12.875 4.9375 12.5625 5.5625 12.25 5.875L9.5625 8.25C9.1875 8.5625 8.9375 9.1875 8.9375 9.6875V12.375C8.9375 12.75 8.6875 13.25 8.375 13.4375L7.5 14C6.6875 14.5 5.5625 13.9375 5.5625 12.9375V9.625C5.5625 9.1875 5.3125 8.625 5.0625 8.3125L2.6875 5.8125C2.375 5.5 2.125 4.9375 2.125 4.5625V3.125C2.125 2.375 2.6875 1.8125 3.375 1.8125Z"
                                        stroke="#374151"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M6.83125 1.8125L3.75 6.75"
                                        stroke="#374151"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>{" "}
                                <span className="">Filter</span>
                            </div>
                            <div
                                className={`${toggleDropdown ? "show" : ""
                                    } dropdown-menu dropdown-menu-right ${styles.drp_dwn_menu_right_cstm} drp_dwn_menu_right`}
                                style={{ transform: "translate3d(-135px, 40px, 0px)" }}
                            >
                                <div className={`${styles.dropped_details_wrap} px-4 py-3`}>
                                    <div className="row">
                                        <div className="col-12 mb-1">
                                            <label htmlFor="ounit">Custom Date Range</label>

                                            <div className=" d-flex">
                                                <div className="" style={{ width: "100%" }}>
                                                    <div
                                                        className="mb-3"
                                                        style={{ position: "relative" }}
                                                    >
                                                        <span
                                                            style={{
                                                                position: "absolute",
                                                                right: "25px",
                                                                top: "5px",
                                                                zIndex: "10",
                                                            }}
                                                        >
                                                            <svg
                                                                width="14"
                                                                height="14"
                                                                viewBox="0 0 14 14"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M2.40527 4.29523C2.40527 3.38641 3.14202 2.64966 4.05084 2.64966H10.6331C11.5419 2.64966 12.2787 3.3864 12.2787 4.29523V10.8775C12.2787 11.7863 11.5419 12.5231 10.6331 12.5231H4.05084C3.14202 12.5231 2.40527 11.7863 2.40527 10.8775V4.29523Z"
                                                                    stroke="#7B8794"
                                                                    strokeWidth="1.64557"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M9.53516 1.55298V3.74707"
                                                                    stroke="#7B8794"
                                                                    strokeWidth="1.64557"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M5.14844 1.55298V3.74707"
                                                                    stroke="#7B8794"
                                                                    strokeWidth="1.64557"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M2.40527 5.94092H12.2787"
                                                                    stroke="#7B8794"
                                                                    strokeWidth="1.64557"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </span>
                                                        <DatePicker
                                                            // showIcon
                                                            selected={from}
                                                            onChange={(date) => setFrom(date)}
                                                            dateFormat="dd/MM/yyyy"
                                                            className="form-control"
                                                            placeholderText="Start date"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="px-2 d-flex align-items-center "
                                                    style={{ height: "40px" }}
                                                >
                                                    <img
                                                        src="/assets/icons/line.svg"
                                                        style={{ width: "30px" }}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="" style={{ width: "100%" }}>
                                                    <div
                                                        className="mb-3"
                                                        style={{ position: "relative" }}
                                                    >
                                                        <span
                                                            style={{
                                                                position: "absolute",
                                                                right: "25px",
                                                                top: "5px",
                                                                zIndex: "10",
                                                            }}
                                                        >
                                                            <svg
                                                                width="14"
                                                                height="14"
                                                                viewBox="0 0 14 14"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M2.40527 4.29523C2.40527 3.38641 3.14202 2.64966 4.05084 2.64966H10.6331C11.5419 2.64966 12.2787 3.3864 12.2787 4.29523V10.8775C12.2787 11.7863 11.5419 12.5231 10.6331 12.5231H4.05084C3.14202 12.5231 2.40527 11.7863 2.40527 10.8775V4.29523Z"
                                                                    stroke="#7B8794"
                                                                    strokeWidth="1.64557"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M9.53516 1.55298V3.74707"
                                                                    stroke="#7B8794"
                                                                    strokeWidth="1.64557"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M5.14844 1.55298V3.74707"
                                                                    stroke="#7B8794"
                                                                    strokeWidth="1.64557"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M2.40527 5.94092H12.2787"
                                                                    stroke="#7B8794"
                                                                    strokeWidth="1.64557"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </span>
                                                        <DatePicker
                                                            // showIcon
                                                            selected={to}
                                                            onChange={(date) => setTo(date)}
                                                            dateFormat="dd/MM/yyyy"
                                                            className="form-control"
                                                            placeholderText="End date"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="name">Status</label>
                                                <Select
                                                    labelId="s"
                                                    id="s"
                                                    fullWidth
                                                    size="small"
                                                    required
                                                    name="verified"
                                                    placeholder="Select"
                                                    defaultValue="select"
                                                >
                                                    <MenuItem value="select">Select Status</MenuItem>
                                                    <MenuItem value="pending">Pending</MenuItem>
                                                    <MenuItem value="verified">Verified</MenuItem>
                                                </Select>
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="name">Currency</label>
                                                <Select
                                                    labelId="s"
                                                    id="s"
                                                    fullWidth
                                                    size="small"
                                                    required
                                                    name="verified"
                                                    defaultValue="select"
                                                >
                                                    <MenuItem value="select">USD</MenuItem>
                                                    <MenuItem value="142282">NGN</MenuItem>
                                                    <MenuItem value="182282">EUR</MenuItem>
                                                    <MenuItem value="228828">CED</MenuItem>
                                                </Select>
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="ounit">RRN</label>
                                                <TextField
                                                    name="min_products"
                                                    required
                                                    type="number"
                                                    fullWidth
                                                    id="ounit"
                                                    placeholder=""
                                                    size="small"
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="ounit">Amount</label>
                                                <div className="position-relative box_with_dollarsign">
                                                    <span
                                                        className="position-absolute"
                                                        style={{
                                                            zIndex: "10",
                                                            top: "50%",
                                                            left: "15px",
                                                            transform: "translateY(-50%)",
                                                        }}
                                                    >
                                                        $
                                                    </span>
                                                    <TextField
                                                        name="min_products"
                                                        required
                                                        type="number"
                                                        fullWidth
                                                        id="ounit"
                                                        placeholder=""
                                                        size="small"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <div className="ml-auto" style={{ width: "fit-content" }}>
                                            <button
                                                type="button"
                                                className={`btn btn-sm border`}
                                                onClick={() => setToggleDropdown(false)}
                                            >
                                                Clear
                                            </button>
                                            &nbsp; &nbsp;
                                            <button
                                                type="button"
                                                className={`btn btn-sm btn-primary`}
                                                onClick={() => setToggleDropdown(false)}
                                            >
                                                Apply Filter
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        &nbsp;&nbsp;
                        <div className="btn border">
                            <svg
                                width="15"
                                height="16"
                                viewBox="0 0 15 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.875 11.125V13C1.875 13.6904 2.43464 14.25 3.125 14.25H11.875C12.5654 14.25 13.125 13.6904 13.125 13V11.125"
                                    stroke="#374151"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M5 8L7.5 10.5L10 8"
                                    stroke="#374151"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M7.5 1.75V10.5"
                                    stroke="#374151"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>{" "}
                            <span className="">Export</span>
                        </div>
                        &nbsp;&nbsp;
                        <div
                            className="btn border btn-primary"
                            onClick={() => setAddCustomer(true)}
                        >
                            <span className="button_text text-white">Add Customer</span>
                        </div>
                    </div>
                </div>

                <Box
                    style={{ padding: "0" }}
                    className="aa tab_wrapper pt-4"
                    sx={{ width: "100%", typography: "body1" }}
                >
                    <CustomerTable />
                </Box>
            </div> */}

      <GlobalModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={addCustomer}
        onClose={handleCloseAddCustomer}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="500px !important"
        maxWidth="100%"
        overflowY="scroll"
        bgcolor="#FFFF"
        border="1px solid #F5F5F5"
        borderRadius="0px"
        boxShadow="24"
        p="25px"
      >
        <div
          className={`modal_content`}
        // style={{overflowY: 'scroll', height: '450px'}}
        >
          <div className="mb-4 d-flex justify-content-between align-items-start pb-3 border-bottom">
            <div>
              <h6 className="mb-2" style={{ fontWeight: "500" }}>
                Add Customer
              </h6>
              <span>
                <small>Add to your list of existing customers</small>
              </span>
            </div>
          </div>

          <div
            className="mt-4 modal_content"
            style={{ maxHeight: "70vh", overflowY: "scroll" }}
          >
            <div className="mb-3">
              <label htmlFor="ounit">Customer Name</label>
              <div className="" style={{ width: "100%" }}>
                <div className="">
                  <TextField
                    name="min_products"
                    required
                    type="text"
                    id="ounit"
                    size="small"
                    placeholder=""
                    fullWidth
                    defaultValue={`Michael Joseph`}
                  />
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="ounit">Email Address</label>
              <div className="" style={{ width: "100%" }}>
                <div className="">
                  <TextField
                    name="min_products"
                    required
                    type="email"
                    id="ounit"
                    size="small"
                    placeholder=""
                    fullWidth
                    defaultValue={`michael@gmail.com`}
                  />
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="ounit">Phone Number</label>
              <div className="" style={{ width: "100%" }}>
                <div className="">
                  <TextField
                    name="min_products"
                    required
                    type="text"
                    id="ounit"
                    size="small"
                    placeholder=""
                    fullWidth
                    defaultValue={`070XXXXXX`}
                  />
                </div>
              </div>
            </div>

            <div className="mt-5">
              <Link
                onClick={() => setAddCustomer(false)}
                className="btn border btn-primary"
              >
                <span className="button_text text-white">Done</span>
              </Link>
              &nbsp; &nbsp;
              <Link
                className="btn border"
                onClick={() => setAddCustomer(false)}
              >
                <span className="button_text">Cancel</span>
              </Link>
            </div>
          </div>
        </div>
      </GlobalModal>
    </>
  );
}
