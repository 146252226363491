import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';

import {
  Link,
  Typography,
  Stack,
  Button
} from '@mui/material';
import axios from "axios";
import Logo from '../../components/logo';
import Styles from './CSSModules/FormPages.module.css'
import { BaseUrlContext } from "src/context/BaseUrlContext";
import toast from "react-hot-toast";
import SuccessToast from 'src/components/SuccessToast';
import ErrorToast from 'src/components/ErrorToast';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'src/axiosInstance';

// sections
import React, { useContext, useState, useEffect } from 'react';
import Alert from "@mui/material/Alert";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from "@mui/material/CircularProgress";
import { notifySuccess, notifyError } from 'src/hooks/useMockData';
import Footer from './Footer';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme, imageUrl }) => ({
  [theme.breakpoints.up('0')]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    // backgroundImage: `url(${imageUrl})`,
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
    padding: theme.spacing(4, 0),
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  overflowX: 'hidden',
  position: 'relative',
  width: '80em',
  maxWidth: '100%',
  margin: '0',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'space-between',
  flexDirection: 'column',
  padding: theme.spacing(3, 0),
}));

// ----------------------------------------------------------------------

export default function ResetPassword() {
  const imageUrl = '/assets/images/op_background.png';
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_NAME;
  const baseUrl = useContext(BaseUrlContext);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword_two, setShowPassword_two] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(null);
  const [isPending, setIsPending] = React.useState(false);
  const [mismatchError, setMismatchError] = React.useState(false);
  const authEmail = localStorage.getItem("e");
  const otp = localStorage.getItem("otp");
  const [field, setField] = React.useState({
    password: '',
    c_password: '',
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword_two = () => setShowPassword_two((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword_two = (event) => {
    event.preventDefault();
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return regex.test(password);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target
    setField(prev => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  const handleProceed = async () => {
    if (field.password !== field.c_password) return setMismatchError(true)
    const passwordValid = validatePassword(field.password);
    const c_passwordValid = validatePassword(field.c_password);
    if (!passwordValid || !c_passwordValid) return setPasswordError('Password must be at least 8 characters long and contain a letter and a number.');

    setIsPending(true)
    setMismatchError(false)
    setPasswordError('');
    let payload = {
      emailAddress: authEmail,
      otp
    }
    if (field.password !== '') {
      payload.password = field.password
    }
    if (field.c_password !== '') {
      payload.confirmPassword = field.c_password
    }
    try {

      const res = await axiosInstance.post(`/accounts/reset?productName=${apiUrl}`, payload);

      notifySuccess(res.data.message);
      navigate('/login')

    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
          notifyError(err.response.data.data.errorMessage);
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
        } else {
          notifyError('An unexpected error occurred');
        }
      } else {
        notifyError('Network error or server not responding');
      }
    } finally {
      setIsPending(false)
    }
  }

  return (
    <>
      <Helmet>
        <title> Reset Password  </title>
      </Helmet>

      <StyledRoot imageUrl={imageUrl} className='px-3'>

        <StyledContent className={`${Styles.StyledContent}`}>
          <div className={`${Styles.logo_wrap}`}>
            <Logo />
          </div>

          <div className={`${Styles.signin_form_wrap} py-5 my-3 py-md-5 my-md-5 mx-sm-auto ml-md-5 pl-md-5`}>
            <Typography variant='h4'>
              Reset Password
            </Typography>
            {passwordError && (
              <Alert
                style={{
                  border: "1px solid red",
                }}
                className='mb-3 mt-3'
                severity="error"
              >
                {passwordError}
              </Alert>
            )}

            <div className={`${Styles.input_box}`}>
              <label>Password</label>

              <FormControl sx={{ width: '100%' }} variant="outlined">
                <OutlinedInput
                  placeholder='Create a password'
                  id="outlined-adornment-password"
                  name='password'
                  value={field.password}
                  onChange={handleFieldChange}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <small className='text-muted font-weight-normal mt-2' style={{ fontSize: '12px' }}>At least 8 characters, containing a letter and a number</small>
              </FormControl>
            </div>

            <div className={`${Styles.input_box}`}>
              <label>Confirm Password</label>

              <FormControl sx={{ width: '100%' }} variant="outlined">
                <OutlinedInput
                  placeholder='Confirm password'
                  id="outlined-adornment-password_two"
                  name='c_password'
                  value={field.c_password}
                  onChange={handleFieldChange}
                  type={showPassword_two ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword_two}
                        onMouseDown={handleMouseDownPassword_two}
                        edge="end"
                      >
                        {showPassword_two ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {
                  mismatchError &&
                  <p className='text-danger m-0' style={{ verticalAlign: 'middle' }}>
                    <span style={{ verticalAlign: 'middle', marginRight: '5px', fontWeight: 'lighter' }}>
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6933 9.28663L8.95999 2.56663C8.45832 1.66246 7.76415 1.16663 6.99999 1.16663C6.23582 1.16663 5.54165 1.66246 5.03999 2.56663L1.30665 9.28663C0.834154 10.1441 0.781654 10.9666 1.16082 11.6141C1.53999 12.2616 2.28665 12.6175 3.26665 12.6175H10.7333C11.7133 12.6175 12.46 12.2616 12.8392 11.6141C13.2183 10.9666 13.1658 10.1383 12.6933 9.28663ZM6.56249 5.24996C6.56249 5.01079 6.76082 4.81246 6.99999 4.81246C7.23915 4.81246 7.43749 5.01079 7.43749 5.24996V8.16663C7.43749 8.40579 7.23915 8.60413 6.99999 8.60413C6.76082 8.60413 6.56249 8.40579 6.56249 8.16663V5.24996ZM7.41415 10.3308C7.38499 10.3541 7.35582 10.3775 7.32665 10.4008C7.29165 10.4241 7.25665 10.4416 7.22165 10.4533C7.18665 10.4708 7.15165 10.4825 7.11082 10.4883C7.07582 10.4941 7.03499 10.5 6.99999 10.5C6.96499 10.5 6.92415 10.4941 6.88332 10.4883C6.84832 10.4825 6.81332 10.4708 6.77832 10.4533C6.74332 10.4416 6.70832 10.4241 6.67332 10.4008C6.64415 10.3775 6.61499 10.3541 6.58582 10.3308C6.48082 10.22 6.41665 10.0683 6.41665 9.91663C6.41665 9.76496 6.48082 9.61329 6.58582 9.50246C6.61499 9.47913 6.64415 9.45579 6.67332 9.43246C6.70832 9.40913 6.74332 9.39163 6.77832 9.37996C6.81332 9.36246 6.84832 9.35079 6.88332 9.34496C6.95915 9.32746 7.04082 9.32746 7.11082 9.34496C7.15165 9.35079 7.18665 9.36246 7.22165 9.37996C7.25665 9.39163 7.29165 9.40913 7.32665 9.43246C7.35582 9.45579 7.38499 9.47913 7.41415 9.50246C7.51915 9.61329 7.58332 9.76496 7.58332 9.91663C7.58332 10.0683 7.51915 10.22 7.41415 10.3308Z" fill="#EF4444" />
                      </svg>
                    </span>
                    <small className='text-danger font-weight-normal' style={{ fontSize: '13px' }}>Password Mismatch!</small>
                  </p>
                }
              </FormControl>

            </div>

            <div>
              <Stack spacing={2} direction="row">
                {isPending ? (
                  <>
                    <Button
                      disabled
                      className={`${Styles.signin_btn} mb-0 position-relative`}
                      style={{ verticalAlign: "middle" }}
                    >
                      <span className="fw-500 text-white">
                        Resetting Password...
                      </span>
                      {isPending && (
                        <CircularProgress
                          style={{ color: "white" }}
                          className="text-white ml-1"
                          size="1rem"
                        />
                      )}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      disabled={field.password === '' || field.c_password === '' || isPending}
                      type="button"
                      className={`${Styles.signin_btn}  ${field.password && field.c_password ? Styles.signin_btn_bg : ""
                        } mb-0 position-relative`}
                      style={{ verticalAlign: "middle" }}
                      onClick={handleProceed}
                    >
                      <span className="fw-500 text-white">Proceed</span>
                      {isPending && (
                        <CircularProgress
                          style={{ color: "white" }}
                          className="text-white ml-1"
                          size="1rem"
                        />
                      )}
                    </Button>
                  </>
                )
                }
              </Stack>
            </div>
          </div>

          <Footer />
        </StyledContent>
      </StyledRoot>
    </>
  );
}
