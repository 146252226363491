import React from 'react';
import Chart from 'react-apexcharts';

const PSRateChart = () => {
    const chartOptions = {
      chart: {
        type: 'radialBar',
        height: 250,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          hollow: {
            margin: 0,
            size: '80%',
            background: 'transparent',
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
          },
          track: {
            background: 'transparent', // Light gray color for the track background
            strokeWidth: '100%',
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: false,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: 20,
              show: true,
              color: '#111827',
              fontSize: '12px',
            },
            value: {
              formatter: function (val) {
                return parseInt(val) + '%';
              },
              color: '#9CA3AF',
              fontSize: '36px',
              fontWeight: '500',
              show: true,
              offsetY: -16,
            },
          },
        },
      },
      fill: {
        type: 'solid',
        colors: ['#111827'], // Dark color for the filled portion
      },
      stroke: {
        lineCap: 'round',
      },
      labels: ['Successful'],
    };
  
    const chartSeries = [70]; // Percentage rating

  return (
    <div className="h-100">
        <div className="PM_chart chart pt-4">
          <Chart options={chartOptions} series={chartSeries} type="radialBar" height={250} />
        </div>
    </div>
  );
};

export default PSRateChart;
