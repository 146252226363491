import React, { useState, useEffect } from 'react';
import { Link } from '@mui/material';
import styles from './pages/Dashboard/landpage.module.css';
import './Global.css';
import CustomSelect from './pages/Dashboard/LanguageSelect';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { replace } from 'lodash';
import { useTranslation } from 'react-i18next';

export default function TopNavbar() {
  const { t } = useTranslation(['landing-page']);
  const [isHamburgerActive] = useState(false);
  const [isFixed, setFixed] = useState(false);
  const [isShowFixed, setShowFixed] = useState(false);
  const navigate = useNavigate();

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleLinkClicked = (link) => {
    if (link) {
      navigate(link);
    }
    setShowMobileMenu(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Calculate one-quarter of the page height
      const quarterHeight = 650;
      const quarterShowHeight = 700;
      // Check if the scroll position is beyond one-quarter of the page height
      if (window.scrollY > quarterHeight) {
        setFixed(true);
      } else {
        setFixed(false);
      }
      if (window.scrollY > quarterShowHeight) {
        setShowFixed(true);
      } else {
        setShowFixed(false);
      }
    };

    // Add event listener for scroll events
    window.addEventListener('scroll', handleScroll);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <header id="header" className={`scrolled  fixed   `}>
        <div
          className={` ${styles.nav_container} ${
            isHamburgerActive ? 'active' : ''
          } d-flex justify-content-between align-items-center container-width`}
        >
          <div
            className={`${styles.logo_box} sat_image  logo_box mt-3 mr-auto`}
          >
            <Link to="/" component={RouterLink}>
              <img
                src="/assets/images/new-nav-logo.png"
                className={`${styles.header_img} header_img`}
                alt="Launchpad Logo"
              />
            </Link>
          </div>

          <div
            className={`${
              !showMobileMenu ? 'hide-mobile-nav-view ' : 'block'
            } navbar px-0 pt-0 `}
            id="nav-bar2"
          >
            <ul className={`nav_menu ${isHamburgerActive ? 'active' : ''}`}>
              <li className={`${styles.nav_item} nav_item nav-item`}>
                <CustomSelect func={() => handleLinkClicked('')} />
              </li>

              <li
                className="border-btn"
                // onClick={() => handleLinkClicked('login')}
              >
                <Link to="/login" component={RouterLink}>
                  {' '}
                  {t('Login')}
                </Link>
              </li>

              <li
                className="dark-btn"
                onClick={() => handleLinkClicked('/signup')}
              >
                {t(`Sign up - it's free`)}
              </li>
            </ul>
          </div>

          <div className="hide-ham-menu">
            {!showMobileMenu ? (
              <span className="ham-container">
                <img
                  src="/assets/images/hambuger-menu.svg"
                  alt="ham menu"
                  onClick={() => setShowMobileMenu(true)}
                />
              </span>
            ) : (
              <img
                className="close-menu-icon"
                src="/assets/images/close-menu.svg"
                alt="close menu"
                onClick={() => setShowMobileMenu(false)}
              />
            )}
          </div>
        </div>
      </header>
    </>
  );
}
