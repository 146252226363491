import React from 'react';
import { Box, Typography } from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import '../Global.css';

const ErrorToast = ({ message, show }) => {
    return (
        <CSSTransition
            in={show}
            timeout={300}
            classNames="toast"
            unmountOnExit
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#111827',
                    color: '#fff',
                    padding: '6px 12px',
                    borderRadius: '8px',
                    width: 'auto',
                    maxWidth: '30em',
                }}
            >
                <span className="bi bi-x-circle-fill mr-2 fs-15 text-white"></span>
                <Typography className="text-white fs-13 fw-500">{message}</Typography>
            </Box>
        </CSSTransition>
    );
};

export default ErrorToast;
