import React from "react";
import Chart from "react-apexcharts";

const InvoicesChart = () => {
  const chartOptions = {
    chart: {
      type: "bar",
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "200%",
      },
    },
    colors: ["rgba(17, 24, 39, 1)", "rgba(229, 231, 235, 1)"],
    xaxis: {
      categories: [""],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: false,
    },
  };

  const chartSeries = [
    {
      name: "Inflow",
      data: [50],
    },
    {
      name: "Outflow",
      data: [40],
    },
  ];

  return (
    <div className="w-100 h-100">



      <div className="chart horizontal_invoice_chart position-relative"
        style={{ width: "100%", maxWidth: "100%" }}>
        <div className="floating_chart_wrap rev border"></div>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={80}
        />
      </div>

      <div className=" px-4 ">
        <span className="fs-12 fw-400 d-block">Growth since last month</span>

        <div className="d-flex justify-content-between align-items-center mt-1">
          <div className="fs-25 text-dark fw-600">+15.5%</div>
          {/* <div className="d-flex align-items-center fit-content">
            <div className="mr-3">
              <span className="d-block fs-11 fw-500"><svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.71412 0.95647L0.239908 8.17214C0.128819 8.40286 0.296945 8.67041 0.553019 8.67041H7.76869C8.02926 8.67041 8.19714 8.39425 8.07719 8.16293L4.33574 0.947261C4.2039 0.693006 3.83836 0.698421 3.71412 0.95647Z" fill="#5EF2CD" />
              </svg> Inflow</span>
              <span className="d-block fs-11 fw-500"><svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.71412 0.906665L0.239908 8.12233C0.128819 8.35306 0.296945 8.6206 0.553019 8.6206H7.76869C8.02926 8.6206 8.19714 8.34445 8.07719 8.11312L4.33574 0.897457C4.2039 0.643202 3.83836 0.648616 3.71412 0.906665Z" fill="#D9D9D9" />
              </svg> Outflow</span>
            </div>
            <div className="">
              <span className="d-block "><span className="text-danger fs-11">-10.4%</span> | <span className="text-danger fs-11">-23.6M ↓</span></span>
              <span className="d-block "><span className="text-success fs-11">+0.3%</span> | <span className="text-success fs-11">+52.0M ↑</span></span>
            </div>
          </div> */}
        </div>
      </div>

      {/* <div className="d-flex justify-content-between align-items-end">
        <div className="px-2">
          <div
            className="d-flex flex-column justify-content-between align-items-start px-4"
            style={{ width: "100%", maxWidth: "100%" }}
          >
            <span className="fs-12 fw-500">Invoice Sent</span>
            <span className="fs-25 fw-600 text-dark">388</span>
          </div>
        </div>

        <ul className="list-unstyled pb-2 px-3 px-sm-4 px-lg-5 d-flex">
          <li className="cursor-pointer d-flex align-items-center mr-3">
            <p className="mb-0 d-flex align-items-center">
              <span
                className="legend-color d-block mb-2"
                style={{
                  backgroundColor: "rgba(17, 24, 39, 1)",
                  width: "10px",
                  height: "10px",
                  borderRadius: "6px",
                }}
              ></span>
              <span className="ml-2 d-block mb-2 fs-11 fw-500">Paid</span>
            </p>
          </li>
          <li className="cursor-pointer d-flex align-items-center mr-3">
            <p className="mb-0 d-flex align-items-center">
              <span
                className="legend-color d-block mb-2"
                style={{
                  backgroundColor: "rgba(217, 217, 217, 1)",
                  width: "10px",
                  height: "10px",
                  borderRadius: "6px",
                }}
              ></span>
              <span className="ml-2 d-block mb-2 fs-11 fw-500">Open</span>
            </p>
          </li>
          <li className="cursor-pointer d-flex align-items-center">
            <p className="mb-0 d-flex align-items-center">
              <span
                className="legend-color d-block mb-2"
                style={{
                  backgroundColor: "rgba(229, 231, 235, 1)",
                  width: "10px",
                  height: "10px",
                  borderRadius: "6px",
                }}
              ></span>
              <span className="ml-2 d-block mb-2 fs-11 fw-500">Due</span>
            </p>
          </li>
        </ul>
      </div> */}
    </div>
  );
};

export default InvoicesChart;
