import React from 'react';
import Chart from 'react-apexcharts';

const WeeklyVolumeChart = () => {
  const chartOptions = {
    chart: {
      type: 'bar',
      height: 400,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '40%',
        borderRadius: 2,
        distributed: true,
        dataLabels: {
          position: 'top', // Position the labels in front of the bars
        },
      },
    },
    colors: ['#111827'],
    dataLabels: {
      enabled: true,
      offsetX: 40,
      style: {
        fontSize: '12px',
        colors: ['#111827'],
      },
      formatter: (val) => `${val.toLocaleString()}`,
    },
    xaxis: {
      categories: ['Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'],
      labels: {
        style: {
          colors: '#A3A3A3',
          fontSize: '12px',
        },
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#A3A3A3',
          fontSize: '12px',
        },
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  const chartSeries = [
    {
      name: 'Volume',
      data: [12000, 22000, 11000, 22000, 12000, 10000, 19000],
    },
  ];

  return (
    <div className="h-100">
        <div className="chart weekly_volume_chart" style={{width: '80%'}}>
          <Chart options={chartOptions} series={chartSeries} type="bar" height={370} style={{overflowX: 'auto'}} />
        </div>
        <div className="mt-2 px-3">
          <p className="mb-1 fs-12">Week Transaction</p>
          <div className="d-flex mt-2">
          <div className='w-75'>
          <h4 className="font-weight-bold text-dark">55,000</h4>
          </div>
          <div className='w-100'>
          <p className="text-muted fs-11">Busiest Day of the week is Thursday</p>
          </div>
          </div>
        </div>
      </div>
  );
};

export default WeeklyVolumeChart;
