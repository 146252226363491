import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import DashboardHeader from "./header/index";
import PagesHeader from "./header/pagesHeader";
import SmallScreenHeader from "./header/smallScreenHeader";
import Nav from "./nav";
import navConfig from "./nav/config"; // Import getFilteredNavConfig function

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 40;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(0),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 743);
  const location = useLocation();
  const currentRoute = window.location.pathname;
  const [currentPath, setCurrentPath] = useState(currentRoute);
  const tabValue = JSON.parse(localStorage.getItem('currentTab'))

  useEffect(() => {
    // console.log('navConfig :', navConfig);
    // console.log('pathName :', pathName);
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 743);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <StyledRoot className="">
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      {/* {location.pathname === "/app/dashboard" && !isSmallScreen && (
        <DashboardHeader onOpenNav={() => setOpen(true)} />
      )} */}
      {/* {!isSmallScreen && location.pathname !== "/app/dashboard" && (
      )} */}
      <PagesHeader
        pathName={location.pathname}
        onOpenNav={() => setOpen(true)}
      />
      {/* {isSmallScreen && <SmallScreenHeader onOpenNav={() => setOpen(true)} />} */}

      <Main
        className={`
      ${
        currentPath === "/app/overview" ||
        currentPath === "/app/wallet" ||
        currentPath === "/app/ledger-management" ||
        currentPath === "/app/payment/transfers" ||
        currentPath === "/app/payment/payment-links" ||
        currentPath === "/app/customer/all" ||
        currentPath === "/app/payment/invoice" ||
        currentPath === "/app/payment/chargebacks" ||
        currentPath === "/app/ledger-management/ledger-details" ||
        currentPath === "/app/ledger-management/ledger-details/accounts" ||
        currentPath === "/app/customer/customer-statistics" ||
        currentPath === `/app/user-settings` ||
        currentPath === "/app/wallet/transactions" 
          ? "mt-4 pt-5"
          : "pt-4 mt-4"
      }
      px-3 px-lg-5 remove_paddingn`}
      >
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
