
import { createContext } from "react";

export const BaseUrlContext = createContext();



export function BaseUrlContextProvider({children}) {
    const API_BASE_URL = 'https://b2bb-102-88-82-205.ngrok-free.app/onepoint-gateway/api/v1';

    return (
        <BaseUrlContext.Provider value={API_BASE_URL}>
            {children}

        </BaseUrlContext.Provider>
    )
}

;
