const navConfig = [
  {
    title: 'Home',
    path: '/app/overview',
    icon: '/assets/icons/navbar/home.svg',
    type: 'both',
    className: 'sidenav-home',
    iconActive: '/assets/icons/navbar/home_active.svg'
  },
  {
    title: 'Wallet',
    path: '/app/wallet',
    icon: '/assets/icons/navbar/wallet.svg',
    iconActive: '/assets/icons/navbar/wallet_active.svg',
    className: 'sidenav-wallet',
    type: 'both',
  },
  {
    title: 'Ledger',
    path: '/app/ledger-management',
    icon: '/assets/icons/navbar/ledger.svg',
    iconActive: '/assets/icons/navbar/ledger_active.svg',
    className: 'sidenav-ledger',
    type: 'both',
  },
  {
    title: 'Payment',
    path: '',
    icon: '',
    type: 'both',
    className: '',
    iconActive: ''
  },
  {
    title: 'Transfers',
    path: '/app/payment/transfers',
    icon: '/assets/icons/navbar/transfers.svg',
    type: 'both',
    className: 'sidenav-transfers',
    iconActive: '/assets/icons/navbar/transfers_active.svg'
  },
  {
    title: 'Payment Links',
    path: '/app/payment/payment-links',
    icon: '/assets/icons/navbar/payment_links.svg',
    type: 'both',
    className: 'sidenav-payment-links',
    iconActive: '/assets/icons/navbar/paymentlinks_active.svg',
  },
  {
    title: 'Customers',
    path: '/app/customer/all',
    icon: '/assets/icons/navbar/customers.svg',
    type: 'both',
    className: 'sidenav-customers',
    iconActive: '/assets/icons/navbar/customers_active.svg'
  },
  {
    title: 'Invoices',
    path: '/app/payment/invoice',
    icon: '/assets/icons/navbar/invoices.svg',
    type: 'both',
    className: 'sidenav-invoices',
    iconActive: '/assets/icons/navbar/invoices_active.svg'
  },
  {
    title: 'Chargebacks',
    path: '/app/payment/chargebacks',
    icon: '/assets/icons/navbar/chargebacks.svg',
    type: 'both',
    className: 'sidenav-chargebacks',
    iconActive: '/assets/icons/navbar/chargebacks_active.svg'
  },









  // {
  //   title: 'Home',
  //   path: '/app/overview',
  //   icon: '/assets/icons/navbar/home.svg',
  //   type: 'both',
  //   iconActive: '/assets/icons/navbar/home_active.svg'
  // },
  // {
  //   title: 'Payment',
  //   path: '/app/payment/transfers',
  //   icon: '/assets/icons/navbar/home.svg',
  //   type: 'both',
  //   iconActive: '/assets/icons/navbar/home_active.svg'

  // },
  // {
  //   title: 'Customers',
  //   path: '/app/customer/all',
  //   icon: '/assets/icons/navbar/home.svg',
  //   type: 'both',
  //   iconActive: '/assets/icons/navbar/home_active.svg'
  // },
  // {
  //   title: 'Messaging',
  //   path: '/app/messaging/sender-id',
  //   icon: '/assets/icons/navbar/home.svg',
  //   type: 'both',
  //   iconActive: '/assets/icons/navbar/home_active.svg'

  // },
  // {
  //   title: 'OTP',
  //   path: '/app/otp/configure-otp',
  //   icon: '/assets/icons/navbar/home.svg',
  //   type: 'both',
  //   iconActive: '/assets/icons/navbar/home_active.svg'
  // },
  // {
  //   title: 'Bills Payment',
  //   path: '/app/bills/airtime',
  //   icon: '/assets/icons/navbar/home.svg',
  //   iconActive: '/assets/icons/navbar/home_active.svg',
  //   type: 'both',
  // },
  // {
  //   title: 'Ledger Management',
  //   path: '/app/ledger-management',
  //   icon: '/assets/icons/navbar/home.svg',
  //   iconActive: '/assets/icons/navbar/home_active.svg',
  //   type: 'both',

  // },
  // {
  //   title: 'Wallet',
  //   path: '/app/wallet/overview',
  //   icon: '/assets/icons/navbar/home.svg',
  //   iconActive: '/assets/icons/navbar/home_active.svg',
  //   type: 'both',

  // },

  // // WALLET

  // {
  //   title: 'Overview',
  //   path: '/app/wallet/overview',
  //   icon: '/assets/icons/navbar/home.svg',
  //   type: 'wallet',
  //   iconActive: '/assets/icons/navbar/home_active.svg',
  // },
  // {
  //   title: 'Top Ups',
  //   path: '/app/wallet/top-ups',
  //   icon: '/assets/icons/navbar/home.svg',
  //   type: 'wallet',
  //   iconActive: '/assets/icons/navbar/home_active.svg'

  // },
  // {
  //   title: 'Transfers',
  //   path: '/app/payment/transfers',
  //   icon: '/assets/icons/navbar/home.svg',
  //   type: 'wallet',
  //   iconActive: '/assets/icons/navbar/home_active.svg'
  // },

  // {
  //   title: 'Fees',
  //   path: '/app/wallet/fees',
  //   icon: '/assets/icons/navbar/home.svg',
  //   type: 'wallet',
  //   iconActive: '/assets/icons/navbar/home_active.svg'

  // },

  // // PAYMENT

  // {
  //   title: 'Transfers',
  //   path: '/app/payment/transfers',
  //   icon: '/assets/icons/navbar/home.svg',
  //   type: 'payment_transfer',
  //   iconActive: '/assets/icons/navbar/home_active.svg'
  // },
  // {
  //   title: 'Invoices',
  //   path: '/app/payment/invoice',
  //   icon: '/assets/icons/navbar/home.svg',
  //   type: 'payment_transfer',
  //   iconActive: '/assets/icons/navbar/home_active.svg'

  // },
  // {
  //   title: 'Payment Links',
  //   path: '/app/payment/payment-links',
  //   icon: '/assets/icons/navbar/home.svg',
  //   type: 'payment_transfer',
  //   iconActive: '/assets/icons/navbar/home_active.svg',
  // },
  // // {
  // //   title: 'Payroll',
  // //   path: '/app/payment/payroll',
  // //   icon: '/assets/icons/navbar/home.svg',
  // //   type: 'payment_transfer',
  // //   iconActive: '/assets/icons/navbar/home_active.svg'
  // // },
  // {
  //   title: 'Chargebacks',
  //   path: '/app/payment/chargebacks',
  //   icon: '/assets/icons/navbar/home.svg',
  //   type: 'payment_transfer',
  //   iconActive: '/assets/icons/navbar/home_active.svg'
  // },
  // {
  //   title: 'Refunds',
  //   path: '/app/payment/refunds',
  //   icon: '/assets/icons/navbar/home.svg',
  //   type: 'payment_transfer',
  //   iconActive: '/assets/icons/navbar/home_active.svg'
  // },

  // // CUSTOMERS
  // {
  //   title: 'All Customers',
  //   path: '/app/customer/all',
  //   icon: '/assets/icons/navbar/home.svg',
  //   iconActive: '/assets/icons/navbar/home_active.svg',
  //   type: 'customers',
  // },
  // {
  //   title: 'Blacklisted',
  //   path: '/app/customer/blacklisted',
  //   icon: '/assets/icons/navbar/home.svg',
  //   iconActive: '/assets/icons/navbar/home_active.svg',
  //   type: 'customers',
  // },

  // // MESSAGING
  // {
  //   title: 'Sender ID',
  //   path: '/app/messaging/sender-id',
  //   icon: '/assets/icons/navbar/home.svg',
  //   iconActive: '/assets/icons/navbar/home_active.svg',
  //   type: 'messaging',
  // },
  // {
  //   title: 'Send Message',
  //   path: '/app/messaging/send-message',
  //   icon: '/assets/icons/navbar/home.svg',
  //   iconActive: '/assets/icons/navbar/home_active.svg',
  //   type: 'messaging',
  // },

  // // OTP
  // {
  //   title: 'Configure OTP',
  //   path: '/app/otp/configure-otp',
  //   icon: '/assets/icons/navbar/home.svg',
  //   type: 'otp',
  //   iconActive: '/assets/icons/navbar/home_active.svg'
  // },
  // {
  //   title: 'OTP Logs',
  //   path: '/app/otp/otp-logs',
  //   icon: '/assets/icons/navbar/home.svg',
  //   type: 'otp',
  //   iconActive: '/assets/icons/navbar/home_active.svg'
  // },

  // // BILLS
  // {
  //   title: 'Airtime Payment',
  //   path: '/app/bills/airtime',
  //   icon: '/assets/icons/navbar/home.svg',
  //   iconActive: '/assets/icons/navbar/home_active.svg',
  //   type: 'bills',
  // },
  // {
  //   title: 'Data Payment',
  //   path: '/app/bills/data',
  //   icon: '/assets/icons/navbar/home.svg',
  //   iconActive: '/assets/icons/navbar/home_active.svg',
  //   type: 'bills',
  // },

  // // LEDGER MANAGEMENT
  // {
  //   title: 'Accounts',
  //   path: '/app/ledger-management',
  //   icon: '/assets/icons/navbar/home.svg',
  //   iconActive: '/assets/icons/navbar/home_active.svg',
  //   type: 'ledger-management',
  // },
  // {
  //   title: 'Journal Entries',
  //   path: '/app/ledger-management/journal-entries',
  //   icon: '/assets/icons/navbar/home.svg',
  //   iconActive: '/assets/icons/navbar/home_active.svg',
  //   type: 'ledger-management',
  // },
];

export default navConfig;

// export const GetFilteredNavConfig = () => {
//   const currentRoute = window.location.pathname;
//   if (
//     // WALLET
//     currentRoute === '/app/wallet/overview' ||
//     currentRoute === '/app/payment/transfers/single' ||
//     currentRoute === '/app/payment/transfers/bulk' ||
//     currentRoute === '/app/wallet/payment-otp' ||
//     currentRoute === '/app/wallet/onepoint-transfer' ||
//     currentRoute === '/app/wallet/create' ||
//     currentRoute === '/app/wallet/top-ups' ||
//     currentRoute === '/app/wallet/withdraw' ||
//     currentRoute === '/app/payment/transfers' ||
//     currentRoute === '/app/wallet/fees'
//   ) {
//     return navConfig.filter(el => el.type === 'wallet');
//   } else if (ce
//     // PAYMENT
//     currentRoute === '/app/payment/payment-links' ||
//     currentRoute === '/app/payment/invoice' ||
//     currentRoute === '/app/payment/transfers' ||
//     currentRoute === '/app/payment/payroll' ||
//     currentRoute === '/app/payment/chargebacks' ||
//     currentRoute === '/app/payment/refunds' ||
//     currentRoute === '/app/payment/single-cash' ||
//     currentRoute === '/app/payment/bulk-cash' ||
//     currentRoute === '/app/payment/invoice/edit' ||
//     currentRoute === '/app/payment/invoice/preview' ||
//     currentRoute === '/app/payment/invoice/create' ||
//     currentRoute === '/app/payment/payment-links/create-single' ||
//     currentRoute === '/app/payment/payment-links/create-bulk' ||
//     currentRoute === '/app/payment/refunds/log-refund'
//   ) {
//     return navConfig.filter(el => el.type === 'payment_transfer');
//   } else if (
//     // CUSTOMERS
//     currentRoute === '/app/customer/all' ||
//     currentRoute === '/app/customer/blacklisted' ||
//     currentRoute === '/app/customer/customer-details'
//   ) {
//     return navConfig.filter(el => el.type === 'customers');
//   } else if (
//     // MESSAGING
//     currentRoute === '/app/messaging/sender-id' ||
//     currentRoute === '/app/messaging/send-message' ||
//     currentRoute === '/app/messaging/single-messaging' ||
//     currentRoute === '/app/messaging/bulk-messaging'
//   ) {
//     return navConfig.filter(el => el.type === 'messaging');
//   } else if (
//     // OTP
//     currentRoute === '/app/otp/configure-otp' ||
//     currentRoute === '/app/otp/otp-logs'
//   ) {
//     return navConfig.filter(el => el.type === 'otp');
//   } else if (
//     // BILLS
//     currentRoute === '/app/bills/airtime' ||
//     currentRoute === '/app/bills/data'
//   ) {
//     return navConfig.filter(el => el.type === 'bills');
//   } else if (
//     // LEDGER MANAGEMENT
//     currentRoute === '/app/ledger-management' ||
//     currentRoute === '/app/ledger-management/journal-entries' ||
//     currentRoute === '/app/ledger-management/sales-ledger' ||
//     currentRoute === '/app/ledger-management/add-entries'
//   ) {
//     return navConfig.filter(el => el.type === 'ledger-management');
//   } else {
//     return navConfig.filter(el => el.type === 'both');
//   }
// };

// const filteredNavConfig = getFilteredNavConfig();
// export default filteredNavConfig;
