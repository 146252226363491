import {
    TextField,
    Box
} from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { orgTabCategory, orgTabBanks } from "src/hooks/useMockData";
import LiveSelect from "react-select";

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import styles from './KYCSteps.module.css';
import axiosInstance from "src/axiosInstance"
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import bus from "src/bus"

const SecureAccount = ({ setIsLoading, setResponseStatus, KYCResponseObj }) => {
    const apiUrl = process.env.REACT_APP_NAME;

    const [EnterPIN, setEnterPIN] = useState("");
    const [showEnterPIN, setShowEnterPIN] = React.useState(false);

    const [ConfirmPIN, setConfirmPIN] = useState("");
    const [showConfirmPIN, setShowConfirmPIN] = React.useState(false);

    const [EnterPassword, setEnterPassword] = useState("");
    const [showEnterPassword, setShowEnterPassword] = React.useState(false);

    const [ConfPassword, setConfPassword] = useState("");
    const [showConfPassword, setShowConfPassword] = React.useState(false);

    const formRef = useRef(null);
    const [PasswordExisted, setPasswordExisted] = useState(false);

    const HTShowEnterPIN = () => setShowEnterPIN((show) => !show);
    const HTShowConfPIN = () => setShowConfirmPIN((show) => !show);

    const HTShowEnterPassword = () => setShowEnterPassword((show) => !show);
    const HTShowConfPassword = () => setShowConfPassword((show) => !show);

    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (KYCResponseObj && KYCResponseObj.data && KYCResponseObj.account) {
            if (KYCResponseObj?.data?.has_set_password === 'YES') {
                setPasswordExisted(true)
                setEnterPIN('XXXX')
                setConfirmPIN('XXXX')
                setEnterPassword('XXXXXXXXXX')
                setConfPassword('XXXXXXXXXX')
            } else {
                setPasswordExisted(false)
            }
        }
    }, []);

    const handlePINChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,4}$/.test(value)) {
          setEnterPIN(value);
        }
      };

      const handleConfPINChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,4}$/.test(value)) {
          setConfirmPIN(value);
        }
      };

        useEffect(() => {

        const handleSubmitVerifyYourBiz = (val) => {
            if (val) {
                if (formRef.current) {
                    formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                }
            }
        };

        bus.on("submit_secureAcct", handleSubmitVerifyYourBiz);

        return () => {
            bus.off("submit_secureAcct", handleSubmitVerifyYourBiz);
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (EnterPIN !== ConfirmPIN) {
            notifyError('PIN do not match!');
            return;
        }

        if (EnterPassword !== ConfPassword) {
            notifyError('Passwords do not match!');
            return;
        }

        if (EnterPIN.length && ConfirmPIN.length < 4) {
            notifyError('PIN should not be less than 4 characters!');
            return;
        }

        if (EnterPassword.length && ConfPassword.length < 8) {
            notifyError('Password should not be less than 8 characters!');
            return;
        }

        if (EnterPIN.includes('XXXX') || ConfirmPIN.includes('XXXX') || EnterPassword.includes('XXXX') || ConfPassword.includes('XXXX')) {
            bus.emit('type_XXXX', true);
            return;
            // notifyError('Passwords do not match!');
            // return;
        }

        let payload = {
            pin: parseFloat(EnterPIN),
            confirmPin: parseFloat(ConfirmPIN),
            password: EnterPassword,
            confirmPassword: ConfPassword
        }

        if (!payload.pin || !payload.confirmPin || !payload.password || !payload.confirmPassword) {
            notifyError('Fields are not allowed to be empty!');
            return;
        }

        setIsLoading(true)

        try {
            const response = await axiosInstance.post(`/accounts/user/secure-account?productName=${apiUrl}`, payload)

            const data = response.data;
            if (data.status === 'success') {
                setResponseStatus('success');
                notifySuccess(response.data.message)
            }
        } catch (err) {
            setResponseStatus('error');
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <>
            <Box component="form" noValidate autoComplete="off" className='mt-2 px-1' onSubmit={handleSubmit} ref={formRef}>
                <div className="mb-4">
                    <div className="w-100 align-items-center mb-2">
                        <div className="mb-2">
                            <label htmlFor="enter_pin" className="fw-500 mb-0">{PasswordExisted ? 'Update transaction pin' : 'Create transaction pin'}</label>
                        </div>
                        <div className="">
                            <div style={{ width: '100%', maxWidth: '100%' }} className="input_fullWidth height_40">
                                <div className={`${styles.input_box} mb-0`}>
                                    <label htmlFor=""></label>
                                    <FormControl sx={{ width: '100%' }} variant="outlined">
                                        <OutlinedInput
                                            placeholder='Enter pin'
                                            id="enter_pin"
                                            type={showEnterPIN ? 'number' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={HTShowEnterPIN}
                                                        onMouseDown={handleMouseDown}
                                                        edge="end"
                                                    >
                                                        {showEnterPIN ? <VisibilityOff style={{ fontSize: '30px' }} /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            value={EnterPIN}
                                            onChange={handlePINChange}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-100 align-items-center mb-3">
                        <div className="">
                            <div style={{ width: '100%', maxWidth: '100%' }} className="input_fullWidth height_40">
                                <div className={`${styles.input_box} mb-0`}>
                                    <label htmlFor=""></label>
                                    <FormControl sx={{ width: '100%' }} variant="outlined">
                                        <OutlinedInput
                                            placeholder='Confirm pin'
                                            id=""
                                            type={showConfirmPIN ? 'number' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={HTShowConfPIN}
                                                        onMouseDown={handleMouseDown}
                                                        edge="end"
                                                    >
                                                        {showConfirmPIN ? <VisibilityOff style={{ fontSize: '30px' }} /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            value={ConfirmPIN}
                                            onChange={handleConfPINChange}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-4">
                    <div className="w-100 align-items-center mb-2">
                        <div className="mb-2">
                            <label htmlFor="enter_password" className="fw-500 mb-0">{PasswordExisted ? 'Update login password' : 'Create login password'}</label>
                        </div>
                        <div className="">
                            <div style={{ width: '100%', maxWidth: '100%' }} className="input_fullWidth height_40">
                                <div className={`${styles.input_box} mb-0`}>
                                    <label htmlFor=""></label>
                                    <FormControl sx={{ width: '100%' }} variant="outlined">
                                        <OutlinedInput
                                            placeholder='Enter password'
                                            id="enter_password"
                                            type={showEnterPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={HTShowEnterPassword}
                                                        onMouseDown={handleMouseDown}
                                                        edge="end"
                                                    >
                                                        {showEnterPassword ? <VisibilityOff style={{ fontSize: '30px' }} /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            value={EnterPassword}
                                            onChange={(e) => setEnterPassword(e.target.value)}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-100 align-items-center mb-3">
                        <div className="">
                            <div style={{ width: '100%', maxWidth: '100%' }} className="input_fullWidth height_40">
                                <div className={`${styles.input_box} mb-0`}>
                                    <label htmlFor=""></label>
                                    <FormControl sx={{ width: '100%' }} variant="outlined">
                                        <OutlinedInput
                                            placeholder='Confirm password'
                                            id="conf_password"
                                            type={showConfPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={HTShowConfPassword}
                                                        onMouseDown={handleMouseDown}
                                                        edge="end"
                                                    >
                                                        {showConfPassword ? <VisibilityOff style={{ fontSize: '30px' }} /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            value={ConfPassword}
                                            onChange={(e) => setConfPassword(e.target.value)}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </>
    );
}

export default SecureAccount