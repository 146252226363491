import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

import { Link, Typography, Stack, Button } from "@mui/material";
// components
import Logo from "../../../components/logo";
// Module styles
import Styles from "../CSSModules/FormPages.module.css";

// sections
import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { BaseUrlContext } from "src/context/BaseUrlContext";
import { useContext, useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "src/axiosInstance"
import { useAuth } from "src/hooks/useAuth";
import bus from "src/bus";
import { mockupLoginSuccess, mockupLoginError } from "src/hooks/useMockData";
import { signInWithGoogle } from "../../../theme/firebaseConfig"; // Import the sign-in function

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import Footer from "../Footer";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme, imageUrl }) => ({
    [theme.breakpoints.up("0")]: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        boxSizing: "border-box",
        backgroundColor: "#FFFFFF",
        // backgroundImage: `url(${imageUrl})`,
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
        padding: theme.spacing(4, 0),
    },
}));

const StyledContent = styled("div")(({ theme }) => ({
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    width: "80em",
    maxWidth: "100%",
    margin: "0",
    minHeight: "90vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "space-between",
    flexDirection: "column",
    padding: theme.spacing(3, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
    const imageUrl = "/assets/images/op_background.png";
    const navigate = useNavigate();
    const { dispatch } = useAuth();
    const apiUrl = process.env.REACT_APP_NAME;
    const baseUrl = useContext(BaseUrlContext);
    const authEmail = localStorage.getItem("e");
    const [isPending, setIsPending] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [passwordError, setPasswordError] = React.useState(null);
    const [otp, setOtp] = useState('');
    const [timeLeft, setTimeLeft] = useState(30);
    const [isResendPending, setIsResendPending] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;

    // const notify = () => toast.success("Login Successful!");

    // const notifyError = () =>
    //     notifyError("That didn't work please try again later .");

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes} mins ${seconds} secs`;
    };

    const handleOtpChange = (event) => {
        const value = event.target.value;

        // Ensure the value is only numbers and has a maximum length of 4
        if (/^\d{0,6}$/.test(value)) {
            setOtp(value);
        }
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };


    const resendToken = async () => {
        try {
            setIsResendPending(true)
            // Make the API request
            let payload = {
                emailAddress: localStorage.getItem('e'),
            }
            //   setN([])
            //   setValues(['', '', '', '']);
            setOtp('')
            let inputsVal = document.querySelectorAll('.form-control');
            inputsVal.forEach(item => {
                item.value = ''
            })

            const response = await axiosInstance.post(`/accounts/otp/resend?productName=${apiUrl}`, payload);

            notifySuccess('Verification token has been sent to your mail')
        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsResendPending(false)
        }
    };


    const validatePassword = (password) => {
        const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{4,}$/;
        return regex.test(password);
    };

    const HandleFormSubmit = async (e) => {
        e.preventDefault();

        let payload = {
            email: authEmail,
            password: password,
            otp:otp
        }

        if (!authEmail || !password) return notifyError('Invalid Email or Password');
        // const passwordValid = validatePassword(payload.password);
        // if (!passwordValid) return notifyError('Password must be at least 4 characters long and contain a letter and a number.');

       
        setPasswordError('');
        try {
            setIsPending(true);

            const res = await axiosInstance.post(`/accounts/login/user-pass?productName=${apiUrl}`, payload);
            notifySuccess(res.data.message);
            localStorage.setItem("auth", JSON.stringify(res.data.data));
            
            dispatch({ type: "LOGIN", payload: res.data.data });
    
            localStorage.removeItem('e')
            localStorage.setItem('awaiting_kyc_verification', JSON.stringify(false))
                localStorage.setItem('has_dived_in', JSON.stringify(false));
            navigate('/app/overview')
        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
            if (err.response.data.data.errorMessage === 'Email not verified') {
                setTimeout(() => {
                    navigate('/verify-email');
                }, 1000);
            }
        } finally {
            setIsPending(false);
        }
    }

    const handleForgotPassword = () => {
        // navigate('/forgot-password')
    }

    return (
        <>
            <Helmet>
                <title> Use Password Login </title>
            </Helmet>

            <StyledRoot imageUrl={imageUrl} className="px-3">
                <StyledContent className={`${Styles.StyledContent}`}>
                    <div className={`${Styles.logo_wrap}`}>
                        <Logo />
                    </div>

                    <div
                        className={`${Styles.signin_form_wrap} py-5 my-3 py-md-5 my-md-5 mx-sm-auto ml-md-5 pl-md-5`}
                    >
                        <form onSubmit={HandleFormSubmit} className="w-100">
                            <Typography variant="h4">Enter Password</Typography>
                            {/* {passwordError && (
                                <Alert
                                style={{
                                    border: "1px solid red",
                                }}
                                className='mb-3 mt-3'
                                severity="error"
                                >
                                {passwordError}
                                </Alert>
                            )} */}

                            <div className={`${Styles.input_box} mb-3`}>
                                <label>Password</label>

                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <OutlinedInput
                                        placeholder='Create a password'
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff style={{ fontSize: '30px' }} /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        value={password}
                                        onChange={handlePasswordChange}
                                    />
                                </FormControl>
                            </div>

                            <div className={`${Styles.input_box} mb-3`}>
                                <label>Two-Factor Authentication Code</label>
                                <Box
                                    sx={{
                                        maxWidth: '100%',
                                    }}
                                >
                                    <TextField
                                        type='text'
                                        value={otp}
                                        onChange={handleOtpChange}
                                        fullWidth
                                        inputProps={{
                                            maxLength: 6,
                                            style: { textAlign: 'left' }
                                        }}
                                    />
                                </Box>
                            </div>
                            <div className="mb-3">
                                {timeLeft < 1 ? (
                                    <span className="d-block fw-500 cursor-pointer">Resend OTP</span>
                                ) : (
                                    <span className="d-block fw-500">Resend OTP in {formatTime(timeLeft)}</span>
                                )}
                            </div>
                            <div>
                            </div>

                            <Stack spacing={2} direction="row">
                                <div className="w-100 white_circular_progress">
                                    {isPending ? (
                                        <>
                                            <Button
                                                disabled
                                                className={`${Styles.signin_btn} ${Styles.signin_btn_bg} mb-0 position-relative`}
                                                style={{ verticalAlign: "middle" }}
                                            >
                                                <span className="fw-500 text-white">
                                                    Authenticating...
                                                </span>
                                                {isPending && (
                                                    <CircularProgress
                                                        style={{ color: "white" }}
                                                        className="text-white"
                                                        size="1rem"
                                                    />
                                                )}
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                disabled={!password || password === "" || otp === '' || otp.length < 6}
                                                type="submit"
                                                className={`${Styles.signin_btn}  ${authEmail && password && otp.length === 6 ? Styles.signin_btn_bg : ""
                                                    } mb-0 position-relative`}
                                                style={{ verticalAlign: "middle" }}
                                            >
                                                <span className="fw-500 text-white">Proceed</span>
                                                {isPending && (
                                                    <CircularProgress
                                                        style={{ color: "white" }}
                                                        className="text-white"
                                                        size="1rem"
                                                    />
                                                )}
                                            </Button>
                                        </>
                                    )}
                                    {/*}
                  {!isPending &&
                    <Button type='submit' className={`${Styles.signin_btn} mb-0 position-relative`} style={{ verticalAlign: 'middle' }}>
                      <span className='fw-500 text-white'>Login</span>
                      {isPending && <CircularProgress style={{ color: 'white' }} className='text-white' size="1rem" />}
                    </Button>}
                  {isPending && <Button disabled className={`${Styles.signin_btn} mb-0 position-relative`} style={{ verticalAlign: 'middle' }}>
                    <span className='fw-500 text-white'>Signing you in</span>
                    {isPending && <CircularProgress style={{ color: 'white' }} className='text-white' size="1rem" />}
                  </Button>}
                    {*/}
                                </div>
                            </Stack>
                        </form>

                        {/* <div className="py-3">
                            <p className="mb-0 text-dark fs-13 fw-500 text-center" 
                                onClick={handleForgotPassword}
                                style={{ cursor: "pointer" }}
                            >Forgot Password?</p>
                        </div> */}
                    </div>

                    <Footer />
                </StyledContent>
            </StyledRoot>
        </>
    );
}
