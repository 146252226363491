import React from 'react';
import { Box, Typography } from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import '../Global.css';

const SuccessToast = ({ message, show }) => {
    return (
        <CSSTransition
            in={show}
            timeout={300}
            classNames="toast"
            unmountOnExit
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#111827',
                    color: '#fff',
                    padding: '6px 12px',
                    borderRadius: '8px',
                    width: 'auto',
                    maxWidth: '30em',
                }}
            >
                <span className="mr-2">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.5 13.5C10.8137 13.5 13.5 10.8137 13.5 7.5C13.5 4.18629 10.8137 1.5 7.5 1.5C4.18629 1.5 1.5 4.18629 1.5 7.5C1.5 10.8137 4.18629 13.5 7.5 13.5ZM10.2803 6.53033C10.5732 6.23744 10.5732 5.76256 10.2803 5.46967C9.98743 5.17678 9.51256 5.17678 9.21967 5.46967L6.75 7.93934L5.78033 6.96967C5.48744 6.67678 5.01256 6.67678 4.71967 6.96967C4.42678 7.26256 4.42678 7.73744 4.71967 8.03033L6.21967 9.53033C6.51256 9.82322 6.98744 9.82322 7.28033 9.53033L10.2803 6.53033Z" fill="#F9FAFB" />
                    </svg>
                </span>
                <Typography className="text-white fs-13 fw-500">{message}</Typography>
            </Box>
        </CSSTransition>
    );
};

export default SuccessToast;
