// ----------------------------------------------------------------------
import React from "react";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import GlobalModal from "../../../Modals/GlobalModal";
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import axios from "axios"
import axiosInstance from "src/axiosInstance"
import CircularProgress from "@mui/material/CircularProgress";
import { useApiKeys } from 'src/hooks/useApiKeys';

import { Link } from "@mui/material";
// sections
// mock
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "../../../pagination/GlobalPagination"; // Adjust the path as necessary
import Scrollbar from "../../../components/scrollbar";
import styles from "../UserSettings.module.css";
import LiveSelect from "react-select";
import { orgTabCategory, orgTabBanks, useCountryDropDowns } from "src/hooks/useMockData";
import toast from "react-hot-toast";
import SuccessToast from 'src/components/SuccessToast';
import ErrorToast from 'src/components/ErrorToast';
import imageCompression from 'browser-image-compression';


export default function OrganisationTab() {
    const apiUrl = process.env.REACT_APP_NAME;
    const apiKeys = useApiKeys();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [category, setCategory] = useState("");
    const [banks, setBanks] = useState([]);
    // const [selectedBankOption] = useState(null);
    const [selectedBankOption, setSelectedBankOption] = useState(null);
    const [nameEnquiryLoading, setNameEnquiryLoading] = useState(false);
    const [saveResponse1, setSaveResponse1] = useState('')
    const [saveResponse2, setSaveResponse2] = useState('')
    // const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("NG");
    const [currentPage, setCurrentPage] = useState(1);
    const [enableSetPassword, setEnableSetPassword] = useState(false);
    const [passwordExisted, setPasswordExisted] = useState(false);
    const [editAvailAccount, setEditAvailAccount] = useState(false);
    const pageSize = 8;
    const [addAcctDialog, setAddAcctDialog] = useState(false);
    let [bank_info, setBankInfo] = useState({ account_no: '', account_name: '', bank_code: '', bank_name: '' });
    const [isObjUpdated, setIsObjUpdated] = useState(false);
    const [KYCResponseObj, setKYCResponseObj] = useState(null);
    const validTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'];
    const [item, setItem] = useState({
        name: '',
        email: '',
        phone: '',
        website: '',
        organisation_address: '',
        country: ''
    });
    const [selectedIndustry, setSelectedIndustry] = useState({});
    const [industry, setIndustry] = useState('');

    const [bankItem, setBankItem] = useState({
        bank_name: '',
        bank_code: '',
        account_name: '',
        account_number: '',
    });

    const industries = [
        { key: 'Transportation', name: 'Transportation i.e Ride sharing, Airlines and air carriers' },
        { key: 'Digital Product', name: 'Digital Product i.e Apps, Blogs and written content' },
        { key: 'Professional Service', name: 'Professional Service i.e Telemarketing and accounting, auditing or tax prep' },
        { key: 'Travel & Lodging', name: 'Travel & Lodging i.e Hotels, inns and motels, Property rentals' },
        { key: 'Others', name: 'Others' },
        // { key: 'financial_service', name: 'Financial Service' },
    ];

    const supportedImageFormats = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/webp",
    ];
    const orgCategories = orgTabCategory();
    const bankCategories = orgTabBanks();
    const countryOptions = useCountryDropDowns();



    const handleFetch = async () => {
        setIsObjUpdated(true)
        try {
            const response = await axiosInstance.get(`/accounts/user?productName=${apiUrl}`);
            const data = response.data.data;

            if (response.data.status === 'success') {
                setKYCResponseObj(data)
                setItem(prev => {
                    return {
                        ...prev,
                        name: data?.account?.name,
                        email: data?.data?.email,
                        phone: data?.data?.phone,
                        organisation_address: data?.account?.organisation_address,
                        organisation_type: data?.account?.industry,
                        website: data?.account?.website,
                        country: data?.account?.country
                    }
                })

                setSelectedImage(data?.account?.organisation_logo)

                setBankItem(prev => {
                    return {
                        ...prev,
                        account_name: data?.data?.settlement_bank?.account_name,
                        account_number: data?.data?.settlement_bank?.account_number,
                        bank_code: data?.data?.settlement_bank?.bank_code,
                        bank_name: data?.data?.settlement_bank?.bank_name,
                    }
                })

                const industryKey = data?.account?.industry;
                // setIndustry(industryKey)
                // setSelectedIndustry({ value: industryKey, label: industryKey });
                // const matchedIndustry = industries.find(industry => industry.key === industryKey);
                // if (matchedIndustry) {
                //     setSelectedIndustry({ value: matchedIndustry?.key, label: matchedIndustry?.name });
                //     setIndustry(matchedIndustry?.key)
                // }

                localStorage.setItem('userDetails', JSON.stringify(data))
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsObjUpdated(false);
        }
    }

    const uploadToCloudinary = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'm9xeajl4');

        try {
            const response = await axios.post('https://api.cloudinary.com/v1_1/dymxghqum/image/upload', formData); // Replace with your Cloudinary cloud name
            return response.data.secure_url;
        } catch (error) {
            console.error('Error uploading to Cloudinary:', error);
            return null;
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            if (!supportedImageFormats.includes(file.type)) {
                notifyError(
                    "Unsupported image format. Please upload a PNG, JPG, or JPEG file."
                );
                return;
            }

            const getBase64 = (file) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });
          };

            let base64Image = '';
            if (file) {
                try {
                    let compressedFile = await imageCompression(file, {
                    maxSizeMB: 0.05, // Target max size in MB (50KB = 0.05MB)
                    maxWidthOrHeight: 200, // Adjust as needed
                    useWebWorker: true,
                    });
            
                    // Check if the compressed file is still larger than 50KB
                    while (compressedFile.size > 50 * 1024) { // 50KB in bytes
                    compressedFile = await imageCompression(compressedFile, {
                        maxSizeMB: 0.05, // Further reduce size if necessary
                        maxWidthOrHeight: 1280, // Further reduce dimensions if necessary
                        useWebWorker: true,
                    });
                    }
            
                    base64Image = await getBase64(compressedFile);
                    // console.log(base64Image);
                } catch (error) {
                    console.error('Error during image compression', error);
                }
            }
            const uploadedUrl = await uploadToCloudinary(base64Image);
            setSelectedImage(uploadedUrl);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer?.files[0];
        if (file) {
            if (!supportedImageFormats.includes(file.type)) {
                notifyError(
                    "Unsupported image format. Please upload a PNG, JPG, or JPEG file."
                );
                return;
            }
            setSelectedImage(URL.createObjectURL(file));
        }
    };

    const handlePasswordExisted = () => {
        setEnableSetPassword(false);
        setPasswordExisted(true);
    };

    const handleUpdatePassword = () => {
        setEnableSetPassword(true);
        setPasswordExisted(true);
    };

    // const fetchCountries = async () => {
    //     try {
    //         const countriesApi = await fetch("https://restcountries.com/v3.1/all");
    //         const countriesJson = await countriesApi.json();
    //         const countriesData = countriesJson.map((country) => ({
    //             code: country.cca2,
    //             name: country.name.common,
    //         }));
    //         setCountries(countriesData.sort((a, b) => a.name.localeCompare(b.name)));
    //     } catch (error) {
    //         console.error("Error fetching countries:", error);
    //     }
    // };

    useEffect(() => {
        handleFetch();
        fetchBanks()
    }, []);

    const handleCountryChange = (event) => {
        setSelectedCountry(event.value);
    };


    const handleMainIndustryClick = (event) => {
        if (event && event.value) {
            setSelectedIndustry({ label: event.target.value, value: event.target.value });
            setItem(prev => {
                return {
                    ...prev,
                    organisation_type:  event.target.value
                }
            })
        }
    };

    const tableData = [
        {
            location: "Nigeria",
            devices: "Chrome - Mac OS",
            ip_addr: "102.88.84.186",
            time: "30 seconds ago",
        },
        {
            location: "Nigeria",
            devices: "Chrome - Mac OS",
            ip_addr: "102.88.84.186",
            time: "1 hour ago",
        },
    ];

    const totalResults = tableData.length;

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    // Calculate the data to display on the current page
    const currentTableData = tableData.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );

    const handleCategoryChange = (event) => {
        setCategory(event.value);
    };

    // const fetchBanks = async () => {
    //     const banksApi = await fetch('https://api.paystack.co/bank')
    //     const banks = await banksApi.json();
    //     if (banks.status) {
    //         setBanks(banks.data)
    //     }
    // }

    // const handleBankChange = (e) => {
    //     const value = e.value
    //     const label = e.label
    //     setBankInfo((prev) => {
    //         return {
    //             ...prev,
    //             bank_code: value,
    //             bank_name: label,
    //         }
    //     })
    // }

    useEffect(() => {
        if (banks.length > 0) {
            const defaultBank = banks.find(bank => bank.name === bankItem?.bank_name);
            if (defaultBank) {
                const defaultOption = { value: defaultBank.code, label: defaultBank.name };
                setSelectedBankOption(defaultOption);
                setBankInfo((prev) => ({
                    ...prev,
                    bank_code: defaultBank.code,
                    bank_name: defaultBank.name,
                }));
                // console.log("Default Bank:", defaultOption);
            }
        }
    }, [banks]);

    const fetchBanks = async () => {
        try {
            const response = await fetch('https://api.paystack.co/bank');
            const banks = await response.json();
            if (banks.status) {
                setBanks(banks.data);
            }
        } catch (error) {
            notifyError(error);
        }
    };

    const handleBankChange = (selectedOption) => {
        setSelectedBankOption(selectedOption);
        setBankInfo((prev) => ({
            ...prev,
            bank_code: selectedOption?.value,
            bank_name: selectedOption?.label,
        }));
        bankItem.account_number = ''
        bankItem.account_name = ''
        // console.log("Selected Bank:", selectedOption);
    };

    const handleCloseaddAcctDialog = () => {
        setAddAcctDialog(false);
    };

    const handleItemChange = (e) => {
        const { name, value } = e.target
        setItem(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleACNChange = (e) => {
        const { value } = e.target;
        bankItem.account_name = ''

        if (value.length <= 10 && /^\d*$/.test(value)) {
            setBankItem(prev => {
                return {
                    ...prev,
                    account_number: value,
                }
            })
        }
        
        // console.log('acct number value', value, value?.length);
        if (value?.length == 10 && /^\d*$/.test(value)) {
            nameEnquiry(value)
        } else {
            // console.log('not yet equals to 10');
        }
    };

    const nameEnquiry = async (val) => {
        // console.log('acct number value', val, val.length, bank_info.bank_name, bank_info.bank_code);

        if (val.length < 10 || bank_info?.bank_name === '' || bank_info?.bank_code === '') return;

        setNameEnquiryLoading(true);
        bankItem.account_name = ''
        const accountNameApi = await fetch(`https://api.paystack.co/bank/resolve?account_number=${val}&bank_code=${bank_info.bank_code}`, {
            headers: {
                'Authorization': `Bearer ${apiKeys.PASTACK.SECRET_KEY}`
            }
        })
        const accountName = await accountNameApi.json();
        setNameEnquiryLoading(false);

        if (accountName.status) {
            // console.log(accountName);
            const data = bankItem;
            data.account_name = accountName?.data?.account_name
            data.bank_code = bank_info?.bank_code
            data.bank_name = bank_info?.bank_name
            setBankItem(data)
            data.account_number = val
        } else {
            notifyError(accountName?.message);

        }
    }

    useEffect(() => {
        // if (bank_info.bank_code !== '') {
        //     nameEnquiry()
        // }
        const data = bankItem;
        data.bank_name = bank_info?.bank_name
    }, [bank_info, bank_info?.account_no])

    const handleSBItemChange = (e) => {
        const { name, value } = e.target
        setBankItem(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }


    const authUser = JSON.parse(localStorage.getItem('auth'))
    const userSBank = JSON.parse(localStorage.getItem('userDetails'))

    useEffect(() => {
        if (userSBank) {

            setItem(prev => {
                return {
                    ...prev,
                    name: userSBank?.account?.name,
                    email: userSBank?.data?.email,
                    phone: userSBank?.data?.phone,
                    organisation_address: userSBank?.account?.organisation_address,
                    organisation_type: userSBank?.account?.industry,
                    website: userSBank?.account?.website,
                    country: userSBank?.account?.country,
                }
            })

            setSelectedImage(userSBank?.account?.organisation_logo)

            setBankItem(prev => {
                return {
                    ...prev,
                    account_name: userSBank?.data?.settlement_bank?.account_name,
                    account_number: userSBank?.data?.settlement_bank?.account_number,
                    bank_code: userSBank?.data?.settlement_bank?.bank_code,
                    bank_name: userSBank?.data?.settlement_bank?.bank_name,
                }
            })

            const industryKey = userSBank?.account?.industry;
            // setIndustry(industryKey)
            // setSelectedIndustry({ value: industryKey, label: industryKey });
            // const matchedIndustry = industries.find(industry => industry.key === industryKey);
            // if (matchedIndustry) {
            //     setSelectedIndustry({ value: matchedIndustry?.key, label: matchedIndustry?.name });
            //     setIndustry(matchedIndustry?.key)
            // }

            // setBankItem(prev => {
            //     return {
            //         ...prev,
            //         bank_name: userSBank?.data?.settlement_bank?.bank_name,
            //         account_name: userSBank?.data?.settlement_bank?.account_name,
            //         account_number: userSBank?.data?.settlement_bank?.account_number,
            //     }
            // })
        }
    }, [])

    const handleUpdateSBank = async () => {
        setIsLoading(true)
        const payload = {
            account_id: authUser && authUser.account_id ? authUser.account_id : null,
            bank_name: bankItem?.bank_name,
            bank_code: bankItem?.bank_code,
            account_name: bankItem?.account_name,
            account_number: bankItem?.account_number,
            currency: KYCResponseObj?.data?.settlement_bank?.currency,
        }
        try {
            const response = await axiosInstance.patch(`/settlement-bank/${authUser && authUser.id ? authUser.id : null}?productName=${apiUrl}`, payload)
            setEditAvailAccount(false)
            setSaveResponse2(response.data.status)
        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsLoading(false)
        }
    }

    const handleSaveChanges = async () => {
        // console.log('selectedIndustry', industry);
        setIsLoading(true)
        const filter_payload = {}

        if (item?.name !== '') {
            filter_payload.name = item?.name
        }
        if (item?.email !== '') {
            filter_payload.email = item?.email
        }
        // if (item.phone !== '') {
        //     filter_payload.phone = item.phone
        // }
        if (item?.website !== '' && item?.website !== null) {
            filter_payload.website = item?.website
        } 
        if (item?.organisation_address !== '' && item?.organisation_address !== null) {
            filter_payload.organisation_address = item?.organisation_address
        }
        if (item?.organisation_type !== '' && item?.organisation_type !== null) {
            filter_payload.industry = item?.organisation_type
            // filter_payload.organisation_type = selectedIndustry?.value
        }
        if (item?.country !== '' && item?.country !== null) {
            filter_payload.country = item?.country 
        }
        if (selectedImage !== '' && selectedImage !== null) {
            filter_payload.organisation_logo = selectedImage
        }
        if(bankItem?.bank_name !== '' && bankItem?.bank_name !== null) {
            filter_payload.bank_name = bankItem?.bank_name
        }
        if(bankItem?.bank_code !== '' && bankItem?.bank_code !== null) {
            filter_payload.bank_code = bankItem?.bank_code
        }
        if(bankItem?.account_name !== '' && bankItem?.account_name !== null) {
            filter_payload.account_name = bankItem?.account_name
        }
        if(bankItem?.account_number !== '' && bankItem?.account_number !== null) {
            filter_payload.account_number = bankItem?.account_number
        }
        if (authUser && authUser?.account_id) {
            filter_payload.account_id = authUser?.account_id
        }

        filter_payload.currency = 'NGN'

        try {
            const response = await axiosInstance.patch(`/account/${authUser && authUser.account_id ? authUser.account_id : null}/update-organisation?productName=${apiUrl}`, filter_payload)
            handleFetch()
            notifySuccess(response.data.message)
            setEditAvailAccount(false)

        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (saveResponse1 !== '' && saveResponse2 !== '') {
            if (saveResponse1 === saveResponse2) {
                notifySuccess('Organization updated successfully')
            }
        }
    }, [saveResponse1, saveResponse2])


    return (
        <>
            <div className="px-0 pt-4">
                <div className="mb-3">
                    <label htmlFor="name" className="fw-500 mb-2">
                        Portrait Logo
                    </label>

                    <div
                        className="portrait_logo_box border rounded p-3 d-flex align-items-center position-relative"
                        style={{ width: "19em" }}
                    >
                        <div
                            className="imgDrop_zone"
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            style={{ minHeight: "80px", maxWidth: "115px" }}
                        >
                            <div className="dz_background">
                                <svg
                                    width="114"
                                    height="79"
                                    viewBox="0 0 114 79"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="0.263889"
                                        y="1.06955"
                                        width="113.472"
                                        height="77.0556"
                                        rx="4.44686"
                                        fill="white"
                                        stroke="#D1D5DB"
                                        strokeWidth="0.527778"
                                        stroke-dasharray="2.42 2.42"
                                    />
                                    {!selectedImage && (
                                        <>
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M45.1562 29.0111C45.1562 28.3416 45.699 27.7988 46.3685 27.7988H67.5827C68.2522 27.7988 68.7949 28.3416 68.7949 29.0111V50.2253C68.7949 50.8948 68.2522 51.4375 67.5827 51.4375H46.3685C45.699 51.4375 45.1562 50.8948 45.1562 50.2253V29.0111Z"
                                                stroke="#D1D5DB"
                                                strokeWidth="1.21224"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M52.38 36.9925C53.468 36.9925 54.3499 36.1106 54.3499 35.0226C54.3499 33.9347 53.468 33.0527 52.38 33.0527C51.2921 33.0527 50.4102 33.9347 50.4102 35.0226C50.4102 36.1106 51.2921 36.9925 52.38 36.9925Z"
                                                stroke="#D1D5DB"
                                                strokeWidth="1.21224"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M68.7954 43.5585L62.2291 36.9922L47.7832 51.438"
                                                stroke="#D1D5DB"
                                                strokeWidth="1.21224"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </>
                                    )}
                                </svg>
                            </div>
                            <input
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                multiple
                                onChange={handleFileChange}
                                onDrop={handleDrop}
                            />

                            {selectedImage && (
                                <div className="preview_selImage ">
                                    <img className="" src={selectedImage} alt="Selected" />
                                </div>
                            )}
                        </div>
                        <div className="btn_wrap ml-3">
                            <div className="change_portrait_container d-flex ">
                                <div className="change_portrait_wrap btn p-0 d-flex justify-content-center align-items-center cursor-pointer">
                                    <span className="position-absolute fs-11 text-dark">
                                        Upload Photo
                                    </span>
                                    <input
                                        type="file"
                                        accept=".png, .jpg, .jpeg, .webp"
                                        multiple
                                        onChange={handleFileChange}
                                        onDrop={handleDrop}
                                        className="change_portrait"
                                    />
                                </div>
                            </div>
                            {/* <button
                                className="btn border"
                                type="button"
                            >
                                <span className="button_text">Upload Photo</span>
                            </button> */}
                        </div>
                    </div>
                    <div style={{ width: "24em", maxWidth: "100%" }} className=" mt-1">
                        <small className="fs-11 d-block" style={{ lineHeight: "1.5" }}>
                            This is the logo that will show all document relating to the
                            customer Recommended size 40px by 40px.
                        </small>
                    </div>
                </div>

                <div className="row align-items-center mb-3">
                    <div className="col-sm-3">
                        <label htmlFor="name" className="fw-500 mb-0">
                            Business Name*
                        </label>
                    </div>
                    <div className="col-sm-9">
                        <div
                            style={{ width: "18em", maxWidth: "100%" }}
                            className="input_fullWidth height_40 disabled_bg"
                        >
                            <TextField
                                name="name"
                                required
                                type="text"
                                fullWidth
                                id="name"
                                placeholder=""
                                size="small"
                                defaultValue=''
                                value={item?.name}
                                onChange={handleItemChange}
                                InputProps={{ readOnly: true, disabled: true }}
                            />
                        </div>
                    </div>
                </div>

                <div className="row align-items-center mb-3">
                    <div className="col-sm-3">
                        <label htmlFor="email" className="fw-500 mb-0">
                            Business Email Address*
                        </label>
                    </div>
                    <div className="col-sm-9">
                        <div
                            style={{ width: "18em", maxWidth: "100%" }}
                            className="input_fullWidth height_40 disabled_bg"
                        >
                            <TextField
                                name="email"
                                required
                                type="email"
                                fullWidth
                                id="email"
                                placeholder=""
                                size="small"
                                defaultValue=''
                                InputProps={{ readOnly: true, disabled: true }}
                                value={item?.email}
                                onChange={handleItemChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="row align-items-center mb-3">
                    <div className="col-sm-3">
                        <label htmlFor="phone" className="fw-500 mb-0">
                            Business Phone Number
                        </label>
                    </div>
                    <div className="col-sm-9">
                        <div
                            style={{ width: "18em", maxWidth: "100%" }}
                            className="input_fullWidth height_40"
                        >
                            <TextField
                                name="phone"
                                required
                                type="number"
                                fullWidth
                                id="phone"
                                placeholder=""
                                size="small"
                                value={item?.phone}
                                onChange={handleItemChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="row align-items-center mb-3">
                    <div className="col-sm-3">
                        <label htmlFor="language-select" className="fw-500 mb-0">
                            Industry*
                        </label>
                    </div>
                    <div className="col-sm-9">
                        <div
                            style={{ width: "18em", maxWidth: "100%" }}
                            className="input_fullWidth position-relative"
                        >
                            <Box
                                sx={{
                                    maxWidth: "100%",
                                    position: "relative",
                                }}
                            >
                                <div className="w-100 liveselect_noborderradius">
                                    <TextField
                                        name="organisation_type"
                                        required
                                        type="text"
                                        fullWidth
                                        id="organisation_type"
                                        placeholder=""
                                        size="small"
                                        value={item?.organisation_type}
                                        onChange={handleMainIndustryClick}
                                        disabled
                                        // InputProps={{ readOnly: true, disabled: true }}
                                    />
                                    {/* <LiveSelect
                                        placeholder={`Please select your industry...`}
                                        disabled={true}
                                        InputProps={{ readOnly: true, disabled: true }}
                                        defaultValue={selectedIndustry}
                                        value={selectedIndustry}
                                        onChange={handleMainIndustryClick}
                                        options={industries?.map((option) => ({
                                            value: option.key,
                                            label: option.name,
                                        }))}
                                    /> */}
                                </div>
                                {/* <Select
                                    labelId="language-select-label"
                                    id="language-select"
                                    size="small"
                                    fullWidth
                                    value={category}
                                    name="category"
                                    required
                                    onChange={handleCategoryChange}
                                    placeholder="Select"
                                >
                                    <MenuItem value="select">Select</MenuItem>
                                    <MenuItem value="rabbit">Rabbit Africa</MenuItem>
                                </Select> */}
                            </Box>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center mb-3">
                    <div className="col-sm-3">
                        <label htmlFor="org_address" className="fw-500 mb-0">
                            Business Address*
                        </label>
                    </div>
                    <div className="col-sm-9">
                        <div
                            style={{ width: "18em", maxWidth: "100%" }}
                            className="input_fullWidth height_40 disabled_bg"
                        >
                            <TextField
                                name="org_address"
                                required
                                type="org_address"
                                fullWidth
                                id="org_address"
                                placeholder=""
                                size="small"
                                defaultValue={``}
                                value={item?.organisation_address}
                                onChange={handleItemChange}
                                InputProps={{ readOnly: true, disabled: true }}
                            />
                        </div>
                    </div>
                </div>

                <div className="row align-items-center mb-3">
                    <div className="col-sm-3">
                        <label htmlFor="country" className="fw-500 mb-0">
                            Country*
                        </label>
                    </div>
                    <div className="col-sm-9">
                        <div
                            style={{ width: "18em", maxWidth: "100%" }}
                            className="input_fullWidth disabled_bg"
                        >
                            <Box
                                sx={{
                                    maxWidth: "100%",
                                    position: "relative",
                                }}
                            >
                                <div className="w-100 liveselect_noborderradius">
                                    <TextField
                                        name="country"
                                        required
                                        type="country"
                                        fullWidth
                                        id="country"
                                        placeholder=""
                                        size="small"
                                        defaultValue={``}
                                        value={item?.country}
                                        onChange={handleItemChange}
                                        InputProps={{ readOnly: true, disabled: true }}

                                    />
                                    {/* <LiveSelect
                                        defaultValue={selectedCountry}
                                        onChange={handleCountryChange}
                                        options={countryOptions?.map((option) => ({
                                            value: option.name,
                                            label: option.name,
                                        }))}
                                        isDisabled={true}
                                    /> */}
                                </div>
                            </Box>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center mb-3">
                    <div className="col-sm-3">
                        <label htmlFor="website" className="fw-500 mb-0">
                            Website
                        </label>
                    </div>
                    <div className="col-sm-9">
                        <div
                            style={{ width: "18em", maxWidth: "100%" }}
                            className="input_fullWidth height_40"
                        >
                            <TextField
                                name="website"
                                required
                                type="text"
                                fullWidth
                                id="website"
                                placeholder="https://"
                                size="small"
                                value={item?.website}
                                onChange={handleItemChange}
                            />
                        </div>
                    </div>
                </div>

                {/* <div className="row align-items-center mb-3">
                    <div className="col-sm-3">
                        <label htmlFor="facebook" className="fw-500 mb-0">
                            Facebook
                        </label>
                    </div>
                    <div className="col-sm-9">
                        <div
                            style={{ width: "18em", maxWidth: "100%" }}
                            className="input_fullWidth height_40"
                        >
                            <TextField
                                name="facebook"
                                required
                                type="text"
                                fullWidth
                                id="facebook"
                                placeholder="http://"
                                size="small"
                            />
                        </div>
                    </div>
                </div>

                <div className="row align-items-center mb-3">
                    <div className="col-sm-3">
                        <label htmlFor="instagram" className="fw-500 mb-0">
                            Instagram
                        </label>
                    </div>
                    <div className="col-sm-9">
                        <div
                            style={{ width: "18em", maxWidth: "100%" }}
                            className="input_fullWidth height_40"
                        >
                            <TextField
                                name="instagram"
                                required
                                type="text"
                                fullWidth
                                id="instagram"
                                placeholder="http://"
                                size="small"
                            />
                        </div>
                    </div>
                </div> */}

                <div className="row align-items-center mb-2 mt-5">
                    <div className="col-sm-7 col-md-3">
                        <div className="">
                            <h6 className="page_title fw-500 fs-14 text-dark mb-1">
                                Settlement Account
                            </h6>
                            <p
                                style={{ color: "#4B5563", fontSize: "12px", gap: "8px" }}
                                className="mb-0 d-flex align-items-center fw-500 text-dark"
                            >
                                <span className="" style={{ color: "rgba(102, 112, 133, 1)" }}>
                                    Update your settlement account here
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-5 col-md-9">
                        <div className="" style={{ width: "18em", maxWidth: "100%" }}>
                            <div className="fit-content  ml-sm-auto mt-3 mt-sm-0">
                                {/* {enableSetPassword && !passwordExisted &&
                                    (
                                        <>
                                            <button
                                                className="btn border btn-primary"
                                                type="button"
                                                onClick={handlePasswordExisted}
                                            >
                                                <span className="button_text text-white">Confirm</span>
                                            </button>
                                            <button
                                                className="btn border ml-2"
                                                type="button"
                                                onClick={() => setEnableSetPassword(false)}
                                            >
                                                <span className="button_text">Cancel</span>
                                            </button>

                                        </>
                                    )} */}

                                {/* {!enableSetPassword && !passwordExisted &&

                                    (
                                        <> */}
                                {
                                    !editAvailAccount &&
                                    <button
                                        className={`${editAvailAccount ? 'btn-primary' : ''} btn border px-4`}
                                        type="button"
                                        // onClick={() => setAddAcctDialog(true)}
                                        onClick={() => !editAvailAccount ? setEditAvailAccount(!editAvailAccount) : handleUpdateSBank()}
                                    >
                                        <span className="button_text">
                                            {editAvailAccount ? 'Update Account' : 'Edit Account'}
                                        </span>
                                    </button>
                                }
                                {/* </>
                                    )
                                } */}

                                {/* {!enableSetPassword && passwordExisted && (
                                    <>
                                        <button
                                            className="btn border btn-primary"
                                            type="button"
                                            onClick={handleUpdatePassword}
                                        >
                                            <span className="button_text text-white">Update Password</span>
                                        </button>
                                    </>
                                )
                                }

                                {enableSetPassword && passwordExisted && (
                                    <>
                                        <button
                                            className="btn border btn-primary"
                                            type="button"
                                            onClick={handlePasswordExisted}
                                        >
                                            <span className="button_text text-white">Confirm</span>
                                        </button>
                                        <button
                                            className="btn border ml-2"
                                            type="button"
                                            onClick={() => setEnableSetPassword(false)}
                                        >
                                            <span className="button_text">Cancel</span>
                                        </button>
                                    </>
                                )
                                } */}
                            </div>
                        </div>
                    </div>

                    {!editAvailAccount ? (
                        <>
                            <div className="col-12 mt-3 mb-5">
                                <div
                                    className="align-items-left text-left"
                                    style={{
                                        // gap: "15px",
                                        width: "20em",
                                        maxWidth: "100%",
                                        whiteSpace: "nowrap",
                                        lineHeight: '40px',
                                    }}
                                >
                                    <div
                                        className="row justify-content-between"
                                        
                                    >
                                        <div className=" col-6">
                                            <label htmlFor="" className="mb-1 fs-14">
                                                Bank Name:
                                            </label>
                                        </div>

                                        <div className=" col-6">
                                            <label htmlFor="" className="mb-1 fs-14">
                                                {bankItem?.bank_name || 'NIL'}
                                            </label>
                                        </div>
                                    </div>

                                    <div
                                        className="row justify-content-between"
                                       
                                    >
                                        <div className=" col-6">
                                            <label htmlFor="" className="mb-1 fs-14">
                                                Account Name:
                                            </label>
                                        </div>

                                        <div className=" col-6">
                                            <label htmlFor="" className="mb-1 fs-14">
                                                {bankItem?.account_name || 'NIL'}
                                            </label>
                                        </div>
                                    </div>

                                    <div
                                        className="row justify-content-between"
                                       
                                    >
                                        <div className=" col-6">
                                            <label htmlFor="" className="mb-1 fs-14">
                                            Account Number:
                                            </label>
                                        </div>

                                        <div className=" col-6">
                                            <label htmlFor="" className="mb-1 fs-14">
                                                {bankItem?.account_number || 'NIL'}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-12 mt-3 mb-5">
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                    style={{
                                        gap: "15px",
                                        width: "35em",
                                        maxWidth: "100%",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <div
                                        className="d-flex justify-content-between"
                                        style={{
                                            gap: "15px",
                                            width: "18em",
                                            maxWidth: "33em",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        <div>
                                            <label htmlFor="" className="mb-1 fs-14">
                                            Account Number:
                                            </label>
                                        </div>

                                        <div className="">
                                            <label htmlFor="" className="mb-1 fs-14">
                                                {bankItem?.account_number || 'NIL'}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="col-12 mt-3 mb-5">
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                    style={{
                                        gap: "15px",
                                        width: "35em",
                                        maxWidth: "100%",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <div
                                        className="d-flex justify-content-between"
                                        style={{
                                            gap: "15px",
                                            width: "18em",
                                            maxWidth: "33em",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        <div>
                                            <label htmlFor="" className="mb-1 fs-14">
                                                Account Name:
                                            </label>
                                        </div>

                                        <div className="">
                                            <label htmlFor="" className="mb-1 fs-14">
                                                {bankItem?.account_name || 'NIL'}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </>
                    ) : (
                        <>

                            <div className="col-sm-3 mt-sm-4">
                                <label htmlFor="language-select" className="fw-500 mb-0">
                                    Bank
                                </label>
                            </div>
                            <div className="col-sm-9 mt-sm-4">
                                <div
                                    style={{ width: "18em", maxWidth: "100%" }}
                                    className="input_fullWidth"
                                >
                                    <Box
                                        sx={{
                                            maxWidth: "100%",
                                            position: "relative",
                                        }}
                                    >
                                        <div className="w-100 liveselect_noborderradius">
                                            <LiveSelect
                                                placeholder={`Select Bank...`}
                                                defaultValue={selectedBankOption}
                                                value={selectedBankOption}
                                                onChange={handleBankChange}
                                                options={banks?.map((option) => ({
                                                    value: option.code,
                                                    label: option.name,
                                                }))}
                                            />
                                        </div>
                                    </Box>
                                </div>
                            </div>


                            <div className="col-sm-3 mt-sm-3">
                                <label htmlFor="acct_no" className="fw-500 mb-0">
                                    Account Number
                                </label>
                            </div>
                            <div className="col-sm-9 mt-sm-3">
                                <div
                                    style={{ width: "18em", maxWidth: "100%" }}
                                    className="input_fullWidth height_40"
                                >
                                    <TextField
                                        name="account_number"
                                        required
                                        type="text"
                                        fullWidth
                                        id="account_number"
                                        placeholder=""
                                        size="small"
                                        value={bankItem?.account_number}
                                        onChange={handleACNChange}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-3 mt-sm-3">
                                <label htmlFor="acct_no" className="fw-500 mb-0">
                                    Account Name
                        {nameEnquiryLoading && <CircularProgress className='ml-1 mt-1' style={{ color: '#4B5563' }} size="0.8rem" />}

                                </label>
                            </div>
                            <div className="col-sm-9 mt-sm-3">
                                <div
                                    style={{ width: "18em", maxWidth: "100%" }}
                                    className="input_fullWidth height_40 disabled_bg"
                                >
                                    <TextField
                                        name="account_name"
                                        required
                                        type="text"
                                        fullWidth
                                        id="account_name"
                                        placeholder=""
                                        size="small"
                                        value={bankItem?.account_name}
                                        onChange={handleSBItemChange}
                                        InputProps={{ readOnly: true, disabled: true }}
                                    />
                                    {/* <small className="d-block fs-11 text-right">Michael Joseph</small> */}
                                </div>
                            </div>

                            {/* <div className="col-12">
                                <div className="save_btn_wrap my-4">
                                    <button className="btn border btn-primary" type="button" onClick={() => setEditAvailAccount(false)}>
                                        <span className="button_text text-white">Save Changes</span>
                                    </button>
                                </div>
                            </div> */}
                        </>
                    )}
                </div>

                <div className="save_btn_wrap my-4">
                    <button className="btn border btn-primary" type="button" onClick={handleSaveChanges}>
                        <span className="button_text text-white">
                            Save Changes
                            {
                                isLoading &&
                                <CircularProgress
                                    style={{ color: "#fff" }}
                                    className="text-white ml-1"
                                    size="0.8rem"
                                />
                            }
                        </span>
                    </button>
                </div>

                {/* {passwordExisted && !enableSetPassword && (
                    <>
                        <div className="row align-items-center mb-3">
                            <div className="col-sm-3">
                                <label htmlFor="currpassword" className="fw-500 mb-0">Current Password</label>
                            </div>
                            <div className="col-sm-9">
                                <div style={{ width: '18em', maxWidth: '100%' }}>
                                    <TextField
                                        name="currpassword"
                                        required
                                        type="password"
                                        fullWidth
                                        id="currpassword"
                                        placeholder="xxxxxxxxx"
                                        size="small"
                                        defaultValue={`xxxxxxxxx`}
                                        disabled={passwordExisted}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {enableSetPassword && !passwordExisted && (
                    <>
                        <div className="row align-items-center mb-3">
                            <div className="col-sm-3">
                                <label htmlFor="password" className="fw-500 mb-0">Enter Password</label>
                            </div>
                            <div className="col-sm-9">
                                <div style={{ width: '18em', maxWidth: '100%' }}>
                                    <TextField
                                        name="password"
                                        required
                                        type="password"
                                        fullWidth
                                        id="password"
                                        placeholder=""
                                        size="small"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center mb-3">
                            <div className="col-sm-3">
                                <label htmlFor="confpassword" className="fw-500 mb-0">Confirm Password</label>
                            </div>
                            <div className="col-sm-9">
                                <div style={{ width: '18em', maxWidth: '100%' }}>
                                    <TextField
                                        name="confpassword"
                                        required
                                        type="password"
                                        fullWidth
                                        id="confpassword"
                                        placeholder=""
                                        size="small"
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {enableSetPassword && passwordExisted && (

                    <>
                        <div className="row align-items-center mb-3">
                            <div className="col-sm-3">
                                <label htmlFor="currpassword" className="fw-500 mb-0">Current Password</label>
                            </div>
                            <div className="col-sm-9">
                                <div style={{ width: '18em', maxWidth: '100%' }}>
                                    <TextField
                                        name="currpassword"
                                        required
                                        type="password"
                                        fullWidth
                                        id="currpassword"
                                        placeholder="xxxxxxxxx"
                                        size="small"
                                        defaultValue={`xxxxxxxxx`}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center mb-3">
                            <div className="col-sm-3">
                                <label htmlFor="newpassword" className="fw-500 mb-0">New Password</label>
                            </div>
                            <div className="col-sm-9">
                                <div style={{ width: '18em', maxWidth: '100%' }}>
                                    <TextField
                                        name="newpassword"
                                        required
                                        type="password"
                                        fullWidth
                                        id="newpassword"
                                        placeholder=""
                                        size="small"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center mb-3">
                            <div className="col-sm-3">
                                <label htmlFor="confpassword" className="fw-500 mb-0">Confirm Password</label>
                            </div>
                            <div className="col-sm-9">
                                <div style={{ width: '18em', maxWidth: '100%' }}>
                                    <TextField
                                        name="confpassword"
                                        required
                                        type="password"
                                        fullWidth
                                        id="confpassword"
                                        placeholder=""
                                        size="small"
                                    />
                                </div>
                            </div>
                        </div>
                    </>

                )} */}
            </div>



            <GlobalModal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={addAcctDialog}
                onClose={handleCloseaddAcctDialog}
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                width="470px !important"
                maxWidth="100%"
                overflowY="scroll"
                bgcolor="#FFFF"
                border="1px solid #F5F5F5"
                borderRadius="5px"
                boxShadow="24"
                p="15px"
            >
                <span
                    className="px-3 py-2"
                    style={{
                        fontSize: "25px",
                        cursor: "pointer",
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                    }}
                    onClick={() => setAddAcctDialog(false)}
                >
                    &times;
                </span>
                <div
                    className={`modal_content px-sm-2 pt-3 pb-2`}
                    style={{ overflowY: 'scroll', height: 'auto', }}
                >
                    <div className="d-flex flex-column justify-content-start align-items-center">
                        <div className="w-100">
                            <h6 className="mb-1">
                                <span className="text-dark fw-500 fs-15">Add Account</span>
                            </h6>
                            <p style={{ fontWeight: '300', color: 'rgba(75, 85, 99, 1)' }}>
                                <small className="fs-12">Update your settlement account here</small>
                            </p>

                            <div className="row">
                                <div className="col-sm-4 mt-sm-4">
                                    <label htmlFor="language-select" className="fw-500 mb-0">
                                        Bank
                                    </label>
                                </div>
                                <div className="col-sm-8 mt-sm-4">
                                    <div
                                        style={{ width: "18em", maxWidth: "100%" }}
                                        className="input_fullWidth"
                                    >
                                        <Box
                                            sx={{
                                                maxWidth: "100%",
                                                position: "relative",
                                            }}
                                        >
                                            <div className="w-100 liveselect_noborderradius">
                                                <LiveSelect
                                                    defaultValue={banks}
                                                    onChange={handleBankChange}
                                                    options={bankCategories?.map((option) => ({
                                                        value: option.key,
                                                        label: option.name,
                                                    }))}
                                                />
                                            </div>
                                        </Box>
                                    </div>
                                </div>


                                <div className="col-sm-4 mt-sm-3">
                                    <label htmlFor="acct_no" className="fw-500 mb-0">
                                        Account Number
                                    </label>
                                </div>
                                <div className="col-sm-8 mt-sm-3">
                                    <div
                                        style={{ width: "18em", maxWidth: "100%" }}
                                        className="input_fullWidth height_40"
                                    >
                                        <TextField
                                            name="acct_no"
                                            required
                                            type="text"
                                            fullWidth
                                            id="acct_no"
                                            placeholder=""
                                            size="small"
                                            defaultValue={`0066924804`}
                                        />
                                        <small className="d-block mt-1 fs-11 text-right">Michael Joseph</small>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="save_btn_wrap mt-4 fit-content ml-auto">
                                        <button className="btn border btn-primary px-4" type="button"
                                            onClick={() => setAddAcctDialog(false)}>
                                            <span className="button_text text-white">Add Account</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </GlobalModal>
        </>
    );
}
