/* eslint-disable react/button-has-type */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Helmet } from "react-helmet-async";
// import * as Router from 'react-router-dom';
// import Scrollbar from "../../../components/scrollbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@mui/material/TextField";
import { Link as RouterLink } from "react-router-dom";

import { Link } from "@mui/material";
import {
  // useEffect, 
  useState
} from "react";
// import bus from "src/bus";
import GlobalModal from "../../../Modals/GlobalModal";
import styles from "../Wallet.module.css";
// ----------------------------------------------------------------------

export default function CreateWallet() {
  const [existingBen, setExistingBen] = useState(false);

  const handleCloseExistingBen = () => {
    setExistingBen(false);
  };


  return (
    <>
      <Helmet>
        <title> Create Wallet </title>
      </Helmet>
      <div className="wrapper">
        <div className={`${styles.header_wrap} d-flex justify-content-between align-items-center`}>
          <div className="d-flex">
            <Link
              className="d-block text-secondary"
              to="/app/wallet"
              component={RouterLink}
            >
              <i
                className="bi bi-x"
                style={{ fontSize: "1.5em", lineHeight: "1" }}
              ></i>
            </Link>
            <h6 className="pl-3 mb-0">Create Wallet</h6>
          </div>
        </div>

        <div className={`${styles.trftobank_side_wrap} mt-5`}>
          <div>
            <div className="mb-3">
              <label htmlFor="name">Select Currency</label>
              <Select
                labelId="s"
                id="s"
                fullWidth
                size="small"
                required
                name="verified"
                defaultValue="ngn"
              >
                <MenuItem value="ngn">NGN</MenuItem>
                <MenuItem value="cny">CNY</MenuItem>
              </Select>
            </div>

            <div className="mb-3">
              <label htmlFor="cus_name">Customize Name</label>
              <div className="" style={{ width: "100%" }}>
                <div className="">
                  <TextField
                    name="min_products"
                    required
                    type="text"
                    id="cus_name"
                    size="small"
                    placeholder="Operation Wallet"
                    fullWidth
                  />
                </div>
              </div>
            </div>

            <div className="my-5">
              <Link to="/app/wallet" component={RouterLink} className="btn border btn-primary">
                <span className="">Create</span>
              </Link>
              &nbsp; &nbsp;
              <Link
                className="btn border"
                to="/app/wallet"
                component={RouterLink}
              >
                <span className="">Cancel</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
