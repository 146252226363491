import React from 'react';
import Chart from 'react-apexcharts';

const TransactionChart = () => {
  const chartOptions = {
    chart: {
      type: 'bar',
      height: 250,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        borderRadius: 2,
      },
    },
    grid: {
      borderColor: 'rgb(243, 244, 246)',
    },
    colors: ['#111827', '#9CA3AF'],
    dataLabels: {
      enabled: false, // Hide the numbers on the bars
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov'],
      labels: {
        style: {
          colors: '#A3A3A3',
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value.toLocaleString(); // Format the y-axis labels
        },
        style: {
          colors: '#A3A3A3',
          fontSize: '12px',
        },
      },
      title: {
        text: '',
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const date = w.globals.categoryLabels[dataPointIndex];
        const payIn = series[1][dataPointIndex];
        const payOut = series[0][dataPointIndex];
        const noOfCustomers = 5000; // Example value for "No of Customers"
        const noOfTransactions = 55000; // Example value for "No of Transactions"

        return `<div class="apexcharts_tooltip" style="padding: 10px; background: rgba(243, 244, 246, 1);">
          <div class="apexcharts_tooltip-series-group">
            <div class="apexcharts_tooltip-series">
                <div class="d-flex justify-content-between align-items-center">
                <div>
                <span class="apexcharts_tooltip-label fw-400 fs-10">Pay In </span>
                </div>

                <span class="apexcharts_tooltip-value fw-500 fs-10">$${payIn?.toLocaleString()}</span>
                </div>
            </div>
            <div class="apexcharts_tooltip-series">
                <div class="d-flex justify-content-between align-items-center">
                <div>
                <span class="apexcharts_tooltip-label fw-400 fs-10">Payout </span>
                </div>

                <span class="apexcharts_tooltip-value fw-500 fs-10">$${payOut?.toLocaleString()}</span>
                </div>
            </div>
            <div class="apexcharts_tooltip-series">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                <span class="apexcharts_tooltip-label fw-400 fs-10">No of Customers </span>
                </div>

                <span class="apexcharts_tooltip-value fw-500 fs-10">${noOfCustomers?.toLocaleString()}</span>
                </div>
            </div><div class="apexcharts_tooltip-series">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                <span class="apexcharts_tooltip-label fw-400 fs-10">No of Transactions </span>
                </div>

                <span class="apexcharts_tooltip-value fw-500 fs-10">${noOfTransactions?.toLocaleString()}</span>
                </div>
            </div>
          </div>
        </div>`;
      },
      style: {
        fontSize: '12px',
        background: '#fff',
        border: '1px solid #e3e3e3',
        borderRadius: '5px',
        padding: '10px',
        color: '#333',
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      labels: {
        colors: ['#111827', '#9CA3AF'],
        useSeriesColors: false,
        show: false,
      },
      markers: {
        fillColors: ['#111827', '#9CA3AF']
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0
      },
    },
  };

  const chartSeries = [
    {
      name: 'Pay Out',
      data: [115666, 33002, 87998, 36931, 115034, 49020, 101212, 20300, 100000, 70000, 120000], // Example data for "Pay Out"
    },
    {
      name: 'Pay In',
      data: [56034, 101002, 67998, 79020, 56660, 76931, 56500, 40200, 76230, 45000, 75000], // Example data for "Pay In"
    },
  ];

  return (
    <div className="mb-">
      <div className="">
        <div className="chart mt-3">
          <Chart options={chartOptions} series={chartSeries} type="bar" height={200} />
        </div>
      </div>
    </div>
  );
};

export default TransactionChart;
