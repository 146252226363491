import { useState, useEffect, useRef } from "react";
import styles from "./Wallet.module.css";
import Scrollbar from "../../components/scrollbar";
import Pagination from '../../pagination/GlobalPagination';
import axiosInstance from "src/axiosInstance"
import { Link as RouterLink } from "react-router-dom";

import {
    Link,
    Stack,
    Drawer,
    Typography,
    IconButton,
    Divider
    //   Avatar,
} from "@mui/material";
import moment from "moment";
import Iconify from '../../components/iconify';
import toast from "react-hot-toast";
import SuccessToast from 'src/components/SuccessToast';
import ErrorToast from 'src/components/ErrorToast';
import CircularProgress from "@mui/material/CircularProgress";
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import Pageloader from 'src/pageloader';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
// ----------------------------------------------------------------------

export default function TopUpsTable({ getTableData, search, filter }) {
    const apiUrl = process.env.REACT_APP_NAME;
    const [isPending, setIsPending] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const walletRef = JSON.parse(localStorage.getItem('walletRef'))
    const [CollectionsTrfData, setCollectionsTrfData] = useState([]);
    const [CollectionsPagination, setCollectionsPagination] = useState({});
    const [CollectionTrfDetailsDrawer, setCollectionTrfDetailsDrawer] = useState(false);
    const [CollectionsDetailsData, setCollectionsDetailsData] = useState(null);
    const pageSize = 10;
    const detailsRef = useRef();


    const handleCloseCollectionTrfDetailsDrawer = () => {
        setCollectionTrfDetailsDrawer(false);
    };

    const tableData = [
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            currency: 'NGN',
            status: 'successful',
            amount_due: '230.00',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            currency: 'NGN',
            status: 'successful',
            amount_due: '230.00',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            currency: 'NGN',
            status: 'successful',
            amount_due: '230.00',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            currency: 'NGN',
            status: 'successful',
            amount_due: '230.00',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            currency: 'NGN',
            status: 'successful',
            amount_due: '230.00',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            currency: 'NGN',
            status: 'successful',
            amount_due: '230.00',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            currency: 'NGN',
            status: 'successful',
            amount_due: '230.00',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            currency: 'NGN',
            status: 'successful',
            amount_due: '230.00',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            currency: 'NGN',
            status: 'successful',
            amount_due: '230.00',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            currency: 'NGN',
            status: 'failed',
            amount_due: '230.00',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            currency: 'NGN',
            status: 'successful',
            amount_due: '230.00',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            currency: 'NGN',
            status: 'failed',
            amount_due: '230.00',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            currency: 'NGN',
            status: 'successful',
            amount_due: '230.00',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            currency: 'NGN',
            status: 'failed',
            amount_due: '230.00',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            currency: 'NGN',
            status: 'successful',
            amount_due: '230.00',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            amount_due: '230.00',
            currency: 'NGN',
            status: 'failed',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            currency: 'NGN',
            status: 'successful',
            amount_due: '230.00',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            currency: 'NGN',
            status: 'successful',
            amount_due: '230.00',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            currency: 'NGN',
            status: 'successful',
            amount_due: '230.00',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
        {
            transaction_ref: '06c1774-7f3d-46ad...',
            currency: 'NGN',
            status: 'successful',
            amount_due: '230.00',
            transaction_date: 'Mar 23, 2022, 13:00 PM',
        },
    ];

    const exportReceiptToPDF = () => {
        handleCloseCollectionTrfDetailsDrawer();
        const input = detailsRef.current;

        const buttonToHide = input.querySelector('#button-id'); // Replace with your button's ID or class

        if (buttonToHide) {
            buttonToHide.style.display = 'none';
        }

        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF({
              orientation: "portrait",
              unit: "mm",
              format: "a5",
            });

            const imgWidth = 148; // Width in mm for A5
            const pageHeight = 150; // Height in mm for A5
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            let position = 0;
            if (imgHeight > pageHeight) {
              pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            } else {
              pdf.addImage(imgData, "PNG", 0, position, imgWidth, pageHeight);
            }

            pdf.save("receipt.pdf");
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                notifyError(err.response.data.data.errorMessage);
              } else if (err.response.data && err.response.data.message) {
                notifyError(err.response.data.message);
              } else {
                notifyError('An unexpected error occurred');
              }
            } else {
            //   notifyError('Network error or server not responding');
            }
            console.error("Error generating PDF: ", err);
          });
      };

    const handleOpenPaymentLinkDetails = async (item) => {
        setCollectionTrfDetailsDrawer(true);
        setCollectionsDetailsData(null);
        // setIsLoadingPLData(true);
        // setSelectedImage(null);

        setCollectionsDetailsData(item);
        // setSelectedImage(item.image);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(
            () => {
                if ((CollectionsDetailsData)) {
                    notifySuccess('Trans Ref Copied')
                } else {
                    notifyError('No data to copy')
                }
            },
            (err) => {
                console.error('Failed to copy: ', err);
            }
        );
    };

    const fetchWalletTrans = async () => {
        setIsPending(true)

        try {
            const response = await axiosInstance.get(`/wallet-transaction/wallet/${walletRef}?productName=${apiUrl}`,
                {
                    params: {
                        perPage: pageSize,
                        page: currentPage,
                        description: search,
                        type: 'DEPOSIT',
                        ...filter
                    }
                })
            const data = response.data.data.data
            setCollectionsTrfData(data)
            setCollectionsPagination(response.data.data)
        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsPending(false)
        }
    };

    useEffect(() => {
        fetchWalletTrans()
    }, [search, filter, currentPage, pageSize])

    getTableData(CollectionsTrfData);

    const totalResults = CollectionsTrfData.length;

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    // Calculate the data to display on the current page
    const currentTableData = tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    return (
        <>

            <div className="px-0 px-md-2">


                <div style={{ marginTop: "0px", borderRadius: "10px" }} className="p-0 wallet_designed_table_wrap">
                    <Scrollbar>
                        <div
                            className={`${styles.overflow_table} table-responsive  pt-3 pb-3 pl-0 pr-0`}
                        >
                            <table
                                id="topUpsTable"
                                className={`${styles.table} w-100 table-hover table-striped`}
                                style={{ minWidth: "38em" }}
                            >
                                <thead className={`${styles.thead}`}>
                                    <tr>
                                        <th className="text-muted" style={{ borderBottom: '1px solid #EAECF0' }}>
                                            Transaction ID {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </th>
                                        <th className="text-muted th_width_12" style={{ borderBottom: '1px solid #EAECF0' }}>Status {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        </th>
                                        <th className="text-muted th_width" style={{ borderBottom: '1px solid #EAECF0' }}>Amount {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        </th>
                                        <th className="text-muted th_width" style={{ borderBottom: '1px solid #EAECF0' }}>Date {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        </th>
                                        <th className="d-none d-md-block text-white" style={{ minWidth: '200px', borderBottom: '1px solid #EAECF0', color: 'white' }}>
                                            <span className="text-white opacity_0">Date</span> {" "}<svg className="opacity_0" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {isPending ? (
                                        <>
                                            <tr>
                                                <td colspan="5" style={{ position: 'relative', height: '150px', backgroundColor: '#fff' }}>
                                                    <Pageloader loading={isPending} />
                                                </td>
                                            </tr>
                                        </>
                                    ) : (
                                        <>
                                            {!isPending && CollectionsTrfData.map((item, index) => (
                                                <tr key={index} className={`${styles.tr} table-fonts cursor-pointer`} style={{ minHeight: '70px' }}
                                                    onClick={() => handleOpenPaymentLinkDetails(item)}
                                                >
                                                    <td>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <div className="fw-500">
                                                                <span>
                                                                    {item?.transaction_ref}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className={`${item.status} badge_style fit-content text-uppercase`}>
                                                            <span>{item?.status}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="fw-600">
                                                            {item.currency === 'CNY' ? '¥' : '₦'} {item?.amount_due}
                                                        </span>
                                                        {/* <span style={{color: 'rgba(149, 155, 164, 1)'}}>
                                                    {item.currency} 
                                                </span> */}
                                                    </td>

                                                    <td>
                                                        <span className="fw-500">{moment(item?.transaction_date).format('MMM, DD, YYYY')}</span>
                                                        {' '}
                                                        <span className="fw-500">{moment(item?.transaction_date).format('HH:mm A')}</span>
                                                    </td>
                                                    <td className="d-none d-md-block">
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    )
                                    }
                                </tbody>
                            </table>
                        </div>
                        {
                            (!isPending && !CollectionsTrfData.length) &&
                            <div className="alerts alerts-primary text-center mt-4 mb-4">
                                <h5>No record found</h5>
                            </div>
                        }

                    </Scrollbar>

                    {!isPending && CollectionsTrfData.length ? (
                        <Pagination
                            currentPage={currentPage}
                            totalResults={CollectionsPagination.totalPages}
                            totalItems={CollectionsPagination.totalItems}
                            pageSize={pageSize}
                            onPageChange={onPageChange}
                        />
                    ) : (
                        <Pagination
                            currentPage={0}
                            totalResults={0}
                            totalItems={0}
                            pageSize={0}
                            onPageChange={onPageChange}
                        />
                    )}
                </div>
            </div>


            <Drawer
                anchor="right"
                open={CollectionTrfDetailsDrawer}
                onClose={handleCloseCollectionTrfDetailsDrawer}
                PaperProps={{
                    sx: { width: 350, maxWidth: '100%', border: 'none', overflow: '', marginTop: '0em', height: '100vh' },
                }}
                className={`${styles.drawerHolder}`}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, pt: 2, pb: 1 }} className="bg-white">
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>
                        {/* Filters */}
                    </Typography>
                    <IconButton onClick={() => setCollectionTrfDetailsDrawer(false)}>
                        <Iconify icon="eva:close-fill" />
                    </IconButton>
                </Stack>

                {/* <Divider /> */}

                <Scrollbar>
                    <Stack spacing={2} sx={{ p: 0, }}>
                        <div
                            className={`modal_content bg-white`}
                            style={{ minHeight: '90vh' }}
                            ref={detailsRef}
                        >

                            <div
                                className={`${styles.dropped_details_wrap} dropped_details_wrap px-4 pb-5 bg-white`}
                            >
                                <div className="mt-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M1.51953 1.25H10.5195C10.9337 1.25 11.2695 1.58579 11.2695 2V2.75H0.769531V2C0.769531 1.58579 1.10532 1.25 1.51953 1.25ZM0.769531 3.5H11.2695C11.6837 3.5 12.0195 3.80526 12.0195 4.18182V10.3182C12.0195 10.6947 11.6837 11 11.2695 11H0.769531C0.355318 11 0.0195312 10.6947 0.0195312 10.3182V4.18182C0.0195312 3.80526 0.355318 3.5 0.769531 3.5ZM7.89453 7.25C7.89453 8.28553 7.05507 9.125 6.01953 9.125C4.984 9.125 4.14453 8.28553 4.14453 7.25C4.14453 6.21447 4.984 5.375 6.01953 5.375C7.05507 5.375 7.89453 6.21447 7.89453 7.25Z" fill="#8792A2" />
                                            </svg>
                                            <small className="pl-2 font-weight-normal fs-12" style={{ color: 'rgba(105, 115, 134, 1)' }}>Collections</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <h4 className="d-flex">
                                        <span className="fw-700 preview_amount" style={{ fontSize: '24px' }}>
                                            {CollectionsDetailsData ? CollectionsDetailsData.currency === 'NGN' ? '₦' : CollectionsDetailsData.currency === 'CNY' ? '¥' : '' : ''}{
                                                CollectionsDetailsData && CollectionsDetailsData.amount_due !== null ? CollectionsDetailsData.amount_due : '0'}.00
                                        </span>
                                        <span className="ml-3 ml-auto">
                                            <div className={`${CollectionsDetailsData && CollectionsDetailsData.status !== null ? CollectionsDetailsData.status : ''} fw-500 fs-12 badge_style chargebacks fit-content text-uppercase pl-4 py-2 position-relative`}>
                                                <span
                                                    className="position-absolute dot"
                                                    style={{ top: '12px', left: '10px', width: '7px', height: '7px', borderRadius: '50%', background: '#FBBF24' }}
                                                ></span>
                                                {CollectionsDetailsData && CollectionsDetailsData.status !== null ? CollectionsDetailsData.status : ''}
                                            </div>
                                        </span>
                                    </h4>
                                </div>

                                <div className="mt-4 d-flex justify-content-between align-items-center">
                                    <div className="w-100">
                                        <small className="d-block mb-1 font-weight-normal fs-13">Date</small>
                                        <div>
                                            <span className="fw-500">{CollectionsDetailsData && CollectionsDetailsData.transaction_date !== null ? moment(CollectionsDetailsData.transaction_date).format('MMM, DD, YYYY') : ''}</span>
                                            {' '}
                                            <span className="fw-500">{CollectionsDetailsData && CollectionsDetailsData.transaction_date !== null ? moment(CollectionsDetailsData.transaction_date).format('HH:mm A') : ''}</span>
                                        </div>
                                    </div>
                                    <div className="text-right w-100 border-left">
                                        <small className="d-block mb-1 font-weight-normal fs-13">Transaction ID</small>
                                        <div className="d-flex fit-content ml-auto cursor-pointer">

                                        <small className="fw-500 fs-13 d-block ml-auto" style={{
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            wordBreak: 'keep-all',
                                            width: '9em',
                                        }}
                                            onClick={() => CollectionsDetailsData !== null ? copyToClipboard(CollectionsDetailsData && CollectionsDetailsData.transaction_ref) : null}
                                        >
                                            {CollectionsDetailsData && CollectionsDetailsData.transaction_ref !== null ? CollectionsDetailsData.transaction_ref : ''}
                                        </small>
                                            <span className="ml-1" onClick={() => CollectionsDetailsData !== null ? copyToClipboard(CollectionsDetailsData && CollectionsDetailsData.transaction_ref) : null}>
                                                <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.88777 8.3125V10.9375C8.88777 13.125 8.16813 14 6.36902 14H4.2101C2.41099 14 1.69135 13.125 1.69135 10.9375V8.3125C1.69135 6.125 2.41099 5.25 4.2101 5.25H6.36902C8.16813 5.25 8.88777 6.125 8.88777 8.3125Z" stroke="#5D20D2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M8.88777 8.3125V10.9375C8.88777 13.125 8.16813 14 6.36902 14H4.2101C2.41099 14 1.69135 13.125 1.69135 10.9375V8.3125C1.69135 6.125 2.41099 5.25 4.2101 5.25H6.36902C8.16813 5.25 8.88777 6.125 8.88777 8.3125Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M8.88777 8.3125V10.9375C8.88777 13.125 8.16813 14 6.36902 14H4.2101C2.41099 14 1.69135 13.125 1.69135 10.9375V8.3125C1.69135 6.125 2.41099 5.25 4.2101 5.25H6.36902C8.16813 5.25 8.88777 6.125 8.88777 8.3125Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M8.88777 8.3125V10.9375C8.88777 13.125 8.16813 14 6.36902 14H4.2101C2.41099 14 1.69135 13.125 1.69135 10.9375V8.3125C1.69135 6.125 2.41099 5.25 4.2101 5.25H6.36902C8.16813 5.25 8.88777 6.125 8.88777 8.3125Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M11.9719 4.5625V7.1875C11.9719 9.375 11.2523 10.25 9.45319 10.25H8.88776V8.3125C8.88776 6.125 8.16812 5.25 6.36901 5.25H4.77551V4.5625C4.77551 2.375 5.49516 1.5 7.29426 1.5H9.45319C11.2523 1.5 11.9719 2.375 11.9719 4.5625Z" stroke="#5D20D2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M11.9719 4.5625V7.1875C11.9719 9.375 11.2523 10.25 9.45319 10.25H8.88776V8.3125C8.88776 6.125 8.16812 5.25 6.36901 5.25H4.77551V4.5625C4.77551 2.375 5.49516 1.5 7.29426 1.5H9.45319C11.2523 1.5 11.9719 2.375 11.9719 4.5625Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M11.9719 4.5625V7.1875C11.9719 9.375 11.2523 10.25 9.45319 10.25H8.88776V8.3125C8.88776 6.125 8.16812 5.25 6.36901 5.25H4.77551V4.5625C4.77551 2.375 5.49516 1.5 7.29426 1.5H9.45319C11.2523 1.5 11.9719 2.375 11.9719 4.5625Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M11.9719 4.5625V7.1875C11.9719 9.375 11.2523 10.25 9.45319 10.25H8.88776V8.3125C8.88776 6.125 8.16812 5.25 6.36901 5.25H4.77551V4.5625C4.77551 2.375 5.49516 1.5 7.29426 1.5H9.45319C11.2523 1.5 11.9719 2.375 11.9719 4.5625Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className={`${styles.dropdown_payInfo} mt-5`}>
                                    <p className="fw-500 fs-14">Payment Information</p>
                                    <div className="d-flex align-items-center mb-1">
                                        <div className="w-75">
                                            <span className="fs-14 fw-400">Amount</span>
                                        </div>

                                        <div className="w-100 text-right">
                                            <span className="fs-14">{CollectionsDetailsData ? CollectionsDetailsData.currency === 'NGN' ? '₦' : CollectionsDetailsData.currency === 'CNY' ? '¥' : '' : ''}{
                                                CollectionsDetailsData && CollectionsDetailsData.amount_due !== null ? CollectionsDetailsData.amount_due : '0'}.00</span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <div className="w-75">
                                            <span className="fs-14 fw-400">Recipient</span>
                                        </div>

                                        <div className="w-100 text-right">
                                            <span className="fs-14">{CollectionsDetailsData && CollectionsDetailsData.credit_account_name !== null ? CollectionsDetailsData.credit_account_name : ''}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <div className="w-75">
                                            <span className="fs-14 fw-400">Payment Type</span>
                                        </div>

                                        <div className="w-100 text-right">
                                            <span className="fs-14">{CollectionsDetailsData && CollectionsDetailsData.type !== null ? CollectionsDetailsData.type : ''}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <div className="w-75">
                                            <span className="fs-14 fw-400">Bank Name</span>
                                        </div>

                                        <div className="w-100 text-right">
                                            <span className="fs-14">{CollectionsDetailsData && CollectionsDetailsData.credit_bank_name !== null ? CollectionsDetailsData.credit_bank_name : ''}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <div className="w-75">
                                            <span className="fs-14 fw-400">Account Number</span>
                                        </div>

                                        <div className="w-100 text-right">
                                            <span className="fs-14">{CollectionsDetailsData && CollectionsDetailsData.credit_account_no !== null ? CollectionsDetailsData.credit_account_no : ''}</span>
                                        </div>
                                    </div>
                                    {/* <div className="d-flex align-items-center mb-1">
                                        <div className="w-75">
                                            <span className="fs-14 fw-400">Routing ID</span>
                                        </div>

                                        <div className="w-100 text-right">
                                            <span className="fs-14">-</span>
                                        </div>
                                    </div> */}
                                    <div className="d-sm-flex align-items-center">
                                        <div className="w-75">
                                            <span className="fs-14 fw-400">Description</span>
                                        </div>

                                        <div className="w-100 text-sm-right">
                                            <span className="fs-14 d-block" style={{ lineHeight: '1.5' }}>{CollectionsDetailsData && CollectionsDetailsData.description !== null ? CollectionsDetailsData.description : ''}</span>
                                        </div>
                                    </div>
                                </div>

                                <div id="button-id" className="w-100 mt-5">
                                    <button
                                        type="button"
                                        className="btn border d-block w-100 py-2 d-flex justify-content-center align-items-center"
                                        onClick={exportReceiptToPDF}
                                    >
                                        <span>
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M2.375 11.125V13C2.375 13.6904 2.93464 14.25 3.625 14.25H12.375C13.0654 14.25 13.625 13.6904 13.625 13V11.125"
                                                    stroke="#374151"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M5.5 8L8 10.5L10.5 8"
                                                    stroke="#374151"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M8 1.75V10.5"
                                                    stroke="#374151"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        <span className="ml-2 py-1">Get PDF Receipt</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Stack>
                    {/* <Box sx={{ p: 3 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              startIcon={<Iconify icon="ic:round-clear-all" />}
            >
              Clear All
            </Button>
          </Box> */}
                </Scrollbar>

            </Drawer>
        </>
    );
}