import {
    TextField,
    Box,
    FormControl,
} from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { orgTabCategory, useNoOfEmployees, useCountryDropDowns, useNigeriaStates, useChinaStates, useHongKongStates } from "src/hooks/useMockData";
import LiveSelect from "react-select";
import bus from "src/bus"
import axiosInstance from "src/axiosInstance"
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import PropTypes from 'prop-types';

const VerifyYourBiz = ({ setIsLoading, setResponseStatus, KYCResponseObj }) => {

    const apiUrl = process.env.REACT_APP_NAME;
    const [selectedCountry, setSelectedCountry] = useState("");
    const [countries, setCountries] = useState([]);
    const [NoOfEmployeesList, setNoOfEmployeesList] = useState([]);
    const [NoOfEmployees, setNoOfEmployees] = useState(null);
    const [LegalBizName, setLegalBizName] = useState("");
    const [BizPhone, setBizPhone] = useState("");
    const [RegBizAddr, setRegBizAddr] = useState("");
    const [RegBizCity, setRegBizCity] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedNoOfEmployees, setSelectedNoOfEmployees] = useState(null);
    const [BizWebsite, setBizWebsite] = useState("");
    const [OtherIndustry, setOtherIndustry] = useState("");

    const [selectedIndustry, setSelectedIndustry] = useState('');
    const formRef = useRef(null);
    const businessName = JSON.parse(localStorage.getItem('auth'))?.businessName;
    const accountID = JSON.parse(localStorage.getItem('auth'))?.account_id;
    const [selectedCountryOption, setSelectedCountryOption] = useState(null);
    const [selectedStateOption, setSelectedStateOption] = useState(null);
    const [states, setStates] = useState([]);

    const CountryList = useCountryDropDowns();
    const nigeriaStates = useNigeriaStates();
    const chinaStates = useChinaStates();
    const hongKongStates = useHongKongStates();
    const useEmployeesList = useNoOfEmployees();

    // const industries = [
    //     { key: 'Transportation', name: 'Transportation i.e Ride sharing, Airlines and air carriers' },
    //     { key: 'Digital Product', name: 'Digital Product i.e Apps, Blogs and written content' },
    //     { key: 'Professional Service', name: 'Professional Service i.e Telemarketing and accounting, auditing or tax prep' },
    //     { key: 'Travel & Lodging', name: 'Travel & Lodging i.e Hotels, inns and motels, Property rentals' },
    //     { key: 'Others', name: 'Others' },
    //     // { key: 'financial_service', name: 'Financial Service' },
    // ];

    const industries = [
        { key: 'Transportation', name: 'Transportation' },
        { key: 'Digital Product', name: 'Digital Product' },
        { key: 'Professional Service', name: 'Professional Service' },
        { key: 'Travel & Lodging', name: 'Travel & Lodging' },
        { key: 'Financial Service', name: 'Financial Service' },
        { key: 'Others', name: 'Others' },
    ];


    useEffect(() => {
        setCountries(CountryList);
        setNoOfEmployeesList(useEmployeesList);

        if (KYCResponseObj && KYCResponseObj.data && KYCResponseObj.account) {
            setLegalBizName(businessName);
            setBizPhone(KYCResponseObj?.account?.business_phone);
            setRegBizAddr(KYCResponseObj?.account?.organisation_address);
            setRegBizCity(KYCResponseObj?.account?.city);
            setSelectedStateOption({ value: KYCResponseObj?.account?.state, label: KYCResponseObj?.account?.state });
            setSelectedState(KYCResponseObj?.account?.state);
            // setSelectedIndustry(KYCResponseObj?.account?.industry);
            setSelectedNoOfEmployees({ value: KYCResponseObj?.account?.no_of_employee, label: KYCResponseObj?.account?.no_of_employee?.toString() });
            setNoOfEmployees(KYCResponseObj?.account?.no_of_employee);
            setBizWebsite(KYCResponseObj?.account?.website);

            if (industries.length > 0) {
                const industryKey = KYCResponseObj?.account?.industry;

                if (industryKey !== null) {
                    const matchedIndustry = industries.find(industry => industry.key === industryKey);

                    if (matchedIndustry && industryKey !== 'Others') {
                        setSelectedIndustry({ value: matchedIndustry.key, label: matchedIndustry.name });
                        setOtherIndustry('');
                    } else {
                        setSelectedIndustry({ value: 'Others', label: 'Others' });
                        setOtherIndustry(KYCResponseObj?.account?.industry)
                    }
                } else {
                    const defaultIndustry = industries.find(industry => industry.key === "Transportation");
                    if (defaultIndustry) {
                        const defaultOption = { value: defaultIndustry.key, label: defaultIndustry.name };
                        setSelectedIndustry(defaultOption);
                        setOtherIndustry('')
                    }
                }
            }

            if (CountryList.length > 0) {
                if (KYCResponseObj?.account?.country === null) {
                    const defaultCountry = CountryList.find(country => country.name === "Nigeria");
                    if (defaultCountry) {
                        const defaultOption = { value: defaultCountry.key, label: defaultCountry.name };
                        setSelectedCountryOption(defaultOption);
                        setSelectedCountry(defaultOption.value)

                        setStates(nigeriaStates);
                    }
                } else {
                    setSelectedCountryOption({ value: KYCResponseObj?.account?.country, label: KYCResponseObj?.account?.country });
                }
            }

            if (useEmployeesList.length > 0) {
                if (KYCResponseObj?.account?.no_of_employee === null) {
                    const defaultEmployee = useEmployeesList.find(emp => emp.name === "1 - 40 employees");
                    if (defaultEmployee) {
                        const defaultOption = { value: defaultEmployee.key, label: defaultEmployee.name };
                        setSelectedNoOfEmployees(defaultOption);
                        setNoOfEmployees(defaultEmployee.name);
                    }
                }
            }
        }

    }, []);

    const handleCountryChange = (selected) => {
        if (selected && selected.value) {

            setSelectedCountryOption(selected);
            setSelectedCountry(selected.value)
            let statesArray = [];

            if (selected.value === "Nigeria") {
                statesArray = nigeriaStates;
            } else if (selected.value === "China") {
                statesArray = chinaStates;
            } else if (selected.value === "Hong kong") {
                statesArray = hongKongStates;
            }

            setStates(statesArray);
            setSelectedStateOption(null); // Clear the state selection when country changes
        }
    };

    const handleNoOfEmpChange = (selectedOption) => {
        if (selectedOption && selectedOption.value) {

            setSelectedNoOfEmployees(selectedOption);
            setNoOfEmployees(selectedOption.value);
        }
    };

    const handleStateChange = (selectedOption) => {
        setSelectedStateOption(selectedOption);
        if (selectedOption && selectedOption.label) {
            setSelectedState(selectedOption.label);

        }
    };

    useEffect(() => {
        const handleSubmitVerifyYourBiz = (val) => {
            if (val) {
                if (formRef.current) {
                    formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                }
            }
        };

        bus.on("submit_verifyYourBiz", handleSubmitVerifyYourBiz);

        return () => {
            bus.off("submit_verifyYourBiz", handleSubmitVerifyYourBiz);
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        let payload = {
            organisation_address: RegBizAddr,
            country: selectedCountryOption.value,
            state: selectedState,
            city: RegBizCity,
            industry: selectedIndustry.value !== 'Others' ? selectedIndustry.value : OtherIndustry,
            website: BizWebsite === null ? "" : BizWebsite,
            name: LegalBizName,
            business_phone: BizPhone,
            no_of_employee: NoOfEmployees
        }

        if (!payload.organisation_address ||
            !payload.country ||
            !payload.state ||
            !payload.city ||
            !payload.industry ||
            // !payload.website ||
            !payload.name ||
            !payload.business_phone ||
            !payload.no_of_employee 
        ) {
            notifyError('All fields are required!')
            return;
        }

        if (selectedIndustry.value === 'Others' && OtherIndustry === '') {
            notifyError('Kindly enter your custom Industry')
            return;
        }
        setIsLoading(true)

        try {
            const response = await axiosInstance.patch(`/account/${accountID}/update-business?productName=${apiUrl}`, payload)

            const data = response.data;
            if (data.status === 'success') {
                setResponseStatus('success');

                notifySuccess(response.data.message)
            }
        } catch (err) {
            setResponseStatus('error');
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleMainIndustryClick = (event) => {
        if (event && event.value) {
            setSelectedIndustry({ label: event.label, value: event.value });
        }
        setOtherIndustry('');
    };

    // const numbers = Array.from({ length: 500 }, (_, i) => i + 1); // Generate numbers from 1 to 500




    return (
        <>
            <Box component="form" noValidate autoComplete="off" className='mt-2 px-1' onSubmit={handleSubmit} ref={formRef}>
                <div className="mb-3 w-100 height_40">
                    <label htmlFor='legal_biz_name' className="mb-2 fs-13 fw-500">Legal business name</label>
                    <div className="">
                        <TextField
                            name="legal_biz_name"
                            required
                            type="text"
                            id="legal_biz_name"
                            size="small"
                            placeholder="Lau"
                            className="rounded-0"
                            fullWidth
                            value={LegalBizName}
                            onChange={(e) => setLegalBizName(e.target.value)}
                        />
                    </div>
                </div>

                <div className="mb-3 w-100 height_40">
                    <label htmlFor='legal_biz_name' className="mb-2 fs-13 fw-500">Business phone number</label>
                    <div className="">
                        <TextField
                            name="legal_biz_name"
                            required
                            type="number"
                            id="legal_biz_name"
                            size="small"
                            placeholder=""
                            className="rounded-0"
                            fullWidth
                            value={BizPhone}
                            onChange={(e) => setBizPhone(e.target.value)}
                        />
                    </div>
                </div>

                <div className="mb-2 height_40 w-100">
                    <label htmlFor='' className="mb-2 fs-13 fw-500">Registered business address</label>
                    <div className="">
                        <TextField
                            name="addr_line1"
                            required
                            type="text"
                            id="addr_line1"
                            size="small"
                            placeholder="Address line 1"
                            className="rounded-0"
                            fullWidth
                            value={RegBizAddr}
                            onChange={(e) => setRegBizAddr(e.target.value)}
                        />
                    </div>
                </div>

                <div className="mb-2 height_40 w-100">
                    <div className="">
                        <TextField
                            name="addr_city"
                            required
                            type="text"
                            id="addr_city"
                            size="small"
                            placeholder="City"
                            className="rounded-0"
                            fullWidth
                            value={RegBizCity}
                            onChange={(e) => setRegBizCity(e.target.value)}
                        />
                    </div>
                </div>

                <div className="w-100 mb-2">
                    <div className="position-relative">
                        <Box
                            sx={{
                                maxWidth: "100%",
                                position: "relative",
                            }}
                        >
                            <div className="w-100 liveselect_noborderradius">
                                <LiveSelect
                                    placeholder={`Country`}
                                    defaultValue={selectedCountryOption}
                                    value={selectedCountryOption}
                                    onChange={handleCountryChange}
                                    options={countries?.map((option) => ({
                                        value: option.key,
                                        label: option.name,
                                    }))}
                                />
                            </div>
                        </Box>
                    </div>
                </div>

                <div className="mb-3 w-100">
                    <Box
                        sx={{
                            maxWidth: "100%",
                            position: "relative",
                        }}
                    >
                        <div className="w-100 liveselect_noborderradius">
                            <LiveSelect
                                placeholder={`State`}
                                value={selectedStateOption}
                                onChange={handleStateChange}
                                options={states?.map((option) => ({
                                    value: option,
                                    label: option,
                                }))}
                            />
                        </div>
                    </Box>
                </div>



                <div className="w-100">
                    <label htmlFor='' className="mb-2 fs-13 fw-500">Industry</label>

                    <div className="mb-3 w-100 position-relative popover_selector height_40">
                        <FormControl fullWidth variant="outlined" className='mt-0 position-relative'>
                            <LiveSelect
                                placeholder={`Please select your industry...`}
                                defaultValue={selectedIndustry}
                                value={selectedIndustry}
                                onChange={handleMainIndustryClick}
                                options={industries?.map((option) => ({
                                    value: option.key,
                                    label: option.name,
                                }))}
                            />
                        </FormControl>
                    </div>
                </div>

                {selectedIndustry.value === "Others" &&
                    <div className="mb-3 w-100 height_40">
                        <label htmlFor='other_industry' className="mb-2 fs-13 fw-500">Enter Custom Industry</label>
                        <div className="">
                            <TextField
                                name="other_industry"
                                required
                                type="text"
                                id="other_industry"
                                size="small"
                                placeholder=""
                                className="rounded-0"
                                fullWidth
                                value={OtherIndustry}
                                onChange={(e) => setOtherIndustry(e.target.value)}
                            />
                        </div>
                    </div>}

                <div className="w-100 mb-3">
                    <label htmlFor='' className="mb-2 fs-13 fw-500">No of employees</label>
                    <div className="position-relative">
                        <Box
                            sx={{
                                maxWidth: "100%",
                                position: "relative",
                            }}
                        >
                            <div className="w-100 liveselect_noborderradius">
                                <LiveSelect
                                    placeholder={`1-500`}
                                    value={selectedNoOfEmployees}
                                    onChange={handleNoOfEmpChange}
                                    options={NoOfEmployeesList.map((employee) => ({
                                        value: employee.key,
                                        label: employee.name,
                                    }))}
                                />
                            </div>
                        </Box>
                    </div>
                </div>

                <div className="mb-3 w-100 height_40">
                    <label htmlFor='biz_website' className="mb-2 fs-13 fw-500">Business website</label>
                    <div className="">
                        <TextField
                            name="biz_website"
                            required
                            type="text"
                            id="biz_website"
                            size="small"
                            placeholder="www.example.com"
                            className="rounded-0"
                            fullWidth
                            value={BizWebsite}
                            onChange={(e) => setBizWebsite(e.target.value)}
                        />
                    </div>
                </div>
            </Box>
        </>
    );
}

export default VerifyYourBiz;
