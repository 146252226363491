import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';

import {
    Link,
    Typography,
    Stack,
    Button
} from '@mui/material';
import axios from "axios";
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseUrlContext } from "src/context/BaseUrlContext";
import axiosInstance from 'src/axiosInstance';

// components
import Logo from '../../components/logo';
import Styles from './CSSModules/FormPages.module.css'

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CircularProgress from "@mui/material/CircularProgress";
import { notifySuccess, notifyError } from 'src/hooks/useMockData';
import Footer from './Footer';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme, imageUrl }) => ({
    [theme.breakpoints.up('0')]: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        boxSizing: 'border-box',
        // backgroundImage: `url(${imageUrl})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        padding: theme.spacing(4, 0),
    },
}));

const StyledContent = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    overflowX: 'hidden',
    position: 'relative',
    width: '80em',
    maxWidth: '100%',
    margin: '0',
    minHeight: '90vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'space-between',
    flexDirection: 'column',
    padding: theme.spacing(3, 0),
}));

// ----------------------------------------------------------------------

export default function OtpPage() {
    const [otp, setOtp] = useState('');
    const apiUrl = process.env.REACT_APP_NAME;
    const baseUrl = useContext(BaseUrlContext);
    const authEmail = localStorage.getItem("e");
    const [isPending, setIsPending] = useState(false);
    const [countdown, setCountdown] = useState(120); // 10 minutes in seconds
    const [isResendDisabled, setIsResendDisabled] = useState(true);

    const imageUrl = '/assets/images/op_background.png';
    const navigate = useNavigate();

    const handleProceed = async () => {
        setIsPending(true)
        let payload = {
            email: authEmail,
            otp
        }
        try {

            const res = await axiosInstance.post(`/accounts/otp/validation?productName=${apiUrl}`, payload);

            notifySuccess(res.data.message);
            localStorage.setItem("otp", otp);
            navigate('/reset-password');

        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsPending(false)
        }
    }

    const handleResendCode = async (e) => {
        e.preventDefault();
        setIsPending(true)
        let payload = {
            emailAddress: authEmail,
        }
        try {

            const res = await axiosInstance.post(`/accounts/otp/resend?productName=${apiUrl}`, payload);

            notifySuccess(res.data.message);

        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsPending(false)
        }
    }

    // Function to handle OTP input changes
    const handleOtpChange = (event) => {
        const value = event.target.value;

        // Ensure the value is only numbers and has a maximum length of 4
        if (/^\d{0,4}$/.test(value)) {
            setOtp(value);
        }
    };

    useEffect(() => {
        // Start the countdown timer
        const timer = setTimeout(() => {
            if (countdown > 0) {
                setCountdown(countdown - 1);
            } else {
                setIsResendDisabled(false); // Enable the "Resend Code" link
            }
        }, 1000);

        // Clear the timer when the component unmounts
        return () => clearTimeout(timer);
    }, [countdown]); // Re-run effect when countdown changes

    // Format the countdown timer as mm:ss
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };


    return (
        <>
            <Helmet>
                <title> Enter OTP  </title>
            </Helmet>

            <StyledRoot imageUrl={imageUrl} className='px-3'>
                <StyledContent className={`${Styles.StyledContent}`}>
                    <div className={`${Styles.logo_wrap}`}>
                        <Logo />
                    </div>

                    <div className={`${Styles.signin_form_wrap} py-5 my-3 py-md-5 my-md-5 mx-sm-auto ml-md-5 pl-md-5`}>
                        <Typography variant='h4'>
                            Enter OTP
                        </Typography>
                        <div className={`${Styles.signup_switch}`}>
                            <span className='' style={{ color: '#4B5563' }}>We’ve sent a message to {authEmail ? authEmail : '--'} with an otp to reset your password.</span>
                        </div>
                        <div className={`${Styles.input_box}`}>
                            <label>OTP</label>
                            <Box
                                sx={{
                                    maxWidth: '100%',
                                }}
                            >
                                <TextField
                                    type='text'
                                    value={otp}
                                    onChange={handleOtpChange}
                                    fullWidth
                                    inputProps={{
                                        maxLength: 4,
                                        style: { textAlign: 'left' }
                                    }}
                                />
                            </Box>
                        </div>
                        <div>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <span className='mr-2'>
                                        <i className='bi bi-clock' style={{ color: '#4B5563' }}></i>
                                    </span>
                                    <label className='mb-0'>{formatTime(countdown)}</label>
                                </div>
                                <div className={`${Styles.forgot}`}>
                                    <Link href='' onClick={handleResendCode}>
                                        {/* <div onClick={handleResendCode}> */}
                                        <i>
                                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.62646 3.9397H11.8531C12.9598 3.9397 13.8531 4.83303 13.8531 5.9397V8.15304" stroke="#4A40DA" strokeWidth="2.62292" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M4.73313 1.83313L2.62646 3.93978L4.73313 6.04647" stroke="#4A40DA" strokeWidth="2.62292" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M13.8531 13.0599H4.62646C3.5198 13.0599 2.62646 12.1666 2.62646 11.0599V8.84656" stroke="#4A40DA" strokeWidth="2.62292" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M11.7461 15.1665L13.8528 13.0598L11.7461 10.9531" stroke="#4A40DA" strokeWidth="2.62292" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                        </i> Resend Code
                                        {/* </div> */}
                                    </Link>
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack spacing={2} direction="row">
                                {isPending ? (
                                    <>
                                        <Button
                                            disabled
                                            className={`${Styles.signin_btn} mb-0 position-relative`}
                                            style={{ verticalAlign: "middle" }}
                                        >
                                            <span className="fw-500 text-white">
                                                Please wait...
                                            </span>
                                            {isPending && (
                                                <CircularProgress
                                                    style={{ color: "white" }}
                                                    className="text-white ml-1"
                                                    size="1rem"
                                                />
                                            )}
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            disabled={otp === '' || otp.length < 4 || isPending}
                                            type="button"
                                            className={`${Styles.signin_btn}  ${otp ? Styles.signin_btn_bg : ""
                                                } mb-0 position-relative`}
                                            style={{ verticalAlign: "middle" }}
                                            onClick={handleProceed}
                                        >
                                            <span className="fw-500 text-white">Proceed</span>
                                            {isPending && (
                                                <CircularProgress
                                                    style={{ color: "white" }}
                                                    className="text-white ml-1"
                                                    size="1rem"
                                                />
                                            )}
                                        </Button>
                                    </>
                                )
                                }
                            </Stack>
                        </div>

                    </div>
                    <Footer />
                </StyledContent>
            </StyledRoot>
        </>
    );
}
