// ----------------------------------------------------------------------
import { Helmet } from "react-helmet-async";
import styles from "./PaymentTables.module.css";
import {
    useEffect,
    useState,
} from "react";
import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { Link as RouterLink, useNavigate } from "react-router-dom";
import Scrollbar from "../../components/scrollbar";
import {
    Link,
    Stack,
    Drawer,
    Typography,
    Divider,
    IconButton,
    //   Avatar,
} from "@mui/material";
import bus from "src/bus"

// import Scrollbar from "../../components/scrollbar";
// sections
// mock
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Pagination from '../../pagination/GlobalPagination';
import Iconify from '../../components/iconify';
import ChargebackDetails from './chargebacks_comp/ChargebackDetails';

// ----------------------------------------------------------------------

export default function Chargebacks() {
    // eslint-disable-next-line no-unused-vars
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 8;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const navigate = useNavigate();
    const [transactionDetailsDrawer, setTransactionDetailsDrawer] = useState(false);
    const [showLedgerBanner, setShowLedgerBanner] = useState(false);
    const [isDataAvl, setIsDataAvl] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);



    const handleCloseLedgerBanner = () => {
        setShowLedgerBanner(false);
        localStorage.setItem("show_ledger_banner", JSON.stringify(false));
    };


    const handleToggleIsDataAvl = () => {
        setIsDataAvl(!isDataAvl);
    };

    const handleCloseTransactionDetailsDrawer = () => {
        setTransactionDetailsDrawer(false);
    };

    const handleOpenTransactionDetailsDrawer = () => {
        setTransactionDetailsDrawer(true);
        // navigate("/app/payment/chargebacks/details");
    }

    const handleToggleDropdown = () => {
        setToggleDropdown(!toggleDropdown);
    };

    useEffect(() => {
        bus.on("dispute_modal_close", (val) => {
            if (val && val === true) {
                setShowLedgerBanner(false);
                handleCloseTransactionDetailsDrawer();
                setTimeout(() => {
                    let bannerStatus = JSON.parse(localStorage.getItem("show_ledger_banner"));
                    if (bannerStatus !== null) {
                        setIsDataAvl(true);

                        setShowLedgerBanner(bannerStatus);
                        //   console.log('bannerStatus',bannerStatus, typeof bannerStatus);
                        if (bannerStatus === true || bannerStatus === 'true') {
                        }
                    } else {
                        setIsDataAvl(false);
                    }
                    //   setIsLoading(false);

                    setTimeout(() => {
                        setShowLedgerBanner(false);
                        let authAval = localStorage.getItem('auth');
                        if (authAval) {
                            localStorage.setItem("show_ledger_banner", JSON.stringify(false));
                        }
                    }, 3000);

                }, 200);
            }
        });

        return () => {
            setShowLedgerBanner(false);
            let authAval = localStorage.getItem('auth');
            if (authAval) {
                localStorage.setItem("show_ledger_banner", JSON.stringify(false));
            }
        };
    }, []);



    const tableData = [
        {
            reference: '06c1774-7f3d-46ad...',
            status: 'OPEN',
            customer: 'Micson@gmail.com',
            date_disputed: '24 Mar,2024 11:54 pm',
            cb_trail: 'Dispute Initiated'
        },
        {
            reference: '06c1774-7f3d-46ad...',
            status: 'OPEN',
            customer: 'Micson@gmail.com',
            date_disputed: '24 Mar,2024 11:54 pm',
            cb_trail: 'Evidence declined'
        },
        {
            reference: '06c1774-7f3d-46ad...',
            status: 'OPEN',
            customer: 'Micson@gmail.com',
            date_disputed: '24 Mar,2024 11:54 pm',
            cb_trail: 'Evidence declined'
        },
        {
            reference: '06c1774-7f3d-46ad...',
            status: 'PENDING',
            customer: 'Micson@gmail.com',
            date_disputed: '24 Mar,2024 11:54 pm',
            cb_trail: 'Evidence declined'
        },
        {
            reference: '06c1774-7f3d-46ad...',
            status: 'PENDING',
            customer: 'Micson@gmail.com',
            date_disputed: '24 Mar,2024 11:54 pm',
            cb_trail: 'Evidence declined'
        },
        {
            reference: '06c1774-7f3d-46ad...',
            status: 'REFUNDED',
            customer: 'Micson@gmail.com',
            date_disputed: '24 Mar,2024 11:54 pm',
            cb_trail: 'Evidence declined'
        },
        {
            reference: '06c1774-7f3d-46ad...',
            status: 'WON',
            customer: 'Micson@gmail.com',
            date_disputed: '24 Mar,2024 11:54 pm',
            cb_trail: 'Evidence declined'
        },
        {
            reference: '06c1774-7f3d-46ad...',
            status: 'WON',
            customer: 'Micson@gmail.com',
            date_disputed: '24 Mar,2024 11:54 pm',
            cb_trail: 'Evidence declined'
        },
        {
            reference: '06c1774-7f3d-46ad...',
            status: 'WON',
            customer: 'Micson@gmail.com',
            date_disputed: '24 Mar,2024 11:54 pm',
            cb_trail: 'Evidence declined'
        },
        {
            reference: '06c1774-7f3d-46ad...',
            status: 'LOST',
            customer: 'Micson@gmail.com',
            date_disputed: '24 Mar,2024 11:54 pm',
            cb_trail: 'Evidence declined'
        },
        {
            reference: '06c1774-7f3d-46ad...',
            status: 'LOST',
            customer: 'Micson@gmail.com',
            date_disputed: '24 Mar,2024 11:54 pm',
            cb_trail: 'Evidence declined'
        },
        {
            reference: '06c1774-7f3d-46ad...',
            status: 'LOST',
            customer: 'Micson@gmail.com',
            date_disputed: '24 Mar,2024 11:54 pm',
            cb_trail: 'Evidence declined'
        },
        {
            reference: '06c1774-7f3d-46ad...',
            status: 'LOST',
            customer: 'Micson@gmail.com',
            date_disputed: '24 Mar,2024 11:54 pm',
            cb_trail: 'Evidence declined'
        },
    ];
    const totalResults = tableData.length;

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    // Calculate the data to display on the current page
    const currentTableData = tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize);



    return (
        <>
            <Helmet>
                <title>Chargebacks</title>
            </Helmet>

            {!isDataAvl &&
                <div className="px-0 px-md-2 mt-3 py-5 w-100 d-flex" style={{ minHeight: '88vh' }}>
                    <div className="rowWrap py-5 mx-auto fit-content w-100">
                        <div className="mb-4 text-center mx-auto" style={{ width: '45em', maxWidth: '100%' }}>
                            <div className={`${styles.trfpage_cardbox} pb-3 mx-auto px-3 ml-sm-auto mr-sm-0 pr-md-3`}>
                                <div className="topside fit-content mx-auto pb-2">
                                    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M44.5 33.4794V9.3394C44.5 6.9394 42.54 5.1594 40.16 5.3594H40.04C35.84 5.7194 29.46 7.8594 25.9 10.0994L25.56 10.3194C24.98 10.6794 24.02 10.6794 23.44 10.3194L22.94 10.0194C19.38 7.7994 13.02 5.6794 8.82 5.3394C6.44 5.1394 4.5 6.9394 4.5 9.3194V33.4794C4.5 35.3994 6.06 37.1994 7.98 37.4394L8.56 37.5194C12.9 38.0994 19.6 40.2994 23.44 42.3994L23.52 42.4394C24.06 42.7394 24.92 42.7394 25.44 42.4394C29.28 40.3194 36 38.0994 40.36 37.5194L41.02 37.4394C42.94 37.1994 44.5 35.3994 44.5 33.4794Z" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M24.5 10.9805V40.9805" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16 16.9805H11.5" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M17.5 22.9805H11.5" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>

                                {/* <div className="topside py-5 w-100" onClick={() => handleToggleIsDataAvl()} style={{ background: 'url(/assets/single_trf.gif) no-repeat', backgroundSize: 'cover', backgroundPosition: 'top center', minHeight: '170px', borderRadius: '6px' }}></div> */}
                                <div className="content w-100">
                                    <h2 className="fw-600 text-dark">No Chargebacks at the moment</h2>
                                    <p className="mb-0 mt-4"><span className="fs-14" style={{ color: 'rgba(107, 114, 128, 1)' }}>Your customers might be looking for ways to pay you, create a payment link or send them invoices.</span></p>
                                    <div className="mt-3 pt-2 text-center">

                                        <button
                                            onClick={() => handleToggleIsDataAvl()}
                                            className="btn d-block fit-content mx-auto"

                                        >
                                            <span className="fs-12 fw-400" style={{ textDecoration: 'underline', textUnderlineOffset: '2px' }}>View Chargeback Guide</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {isDataAvl && (
                <div className="px-0">
                    <div className={`${showLedgerBanner ? "run" : "d-none"} top_alert_wrapper`}>
                        <div
                            className="alert bg-white alert-dismissible fade show"
                            role="alert"
                        >
                            <div className="d-flex">
                                <div className="pr-3">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                                            stroke="#34D399"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div>
                                    <h4 className="fs-14 text-dark fw-600 mb-0">Chargebacks accepted</h4>
                                    <p className="mb-1 mt-2" style={{ lineHeight: '1' }}>
                                        <span className="fs-12 fw-400">
                                            $200 has been successfully removed from your balance
                                        </span>
                                    </p>
                                </div>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                    onClick={handleCloseLedgerBanner}
                                >
                                    <span aria-hidden="true">
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_5456_128482)">
                                                <rect width="20" height="20" rx="6" fill="white" />
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
                                                    fill="#9CA3AF"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_5456_128482">
                                                    <rect width="20" height="20" rx="6" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div onClick={() => handleToggleDropdown()}
                        className={`overlay_div ${toggleDropdown ? "show_overlay" : ""}`}
                    ></div>

                    <div className="d-flex justify-content-between align-items-center">
                        <div className="">
                            <h5 className="page_title mb-2">Chargebacks</h5>
                            <p style={{ color: '#4B5563', fontSize: '13px' }}><span>Manage your dispute</span></p>
                        </div>
                    </div>

                    <div className="d-md-flex justify-content-between align-items-center mt-4 w-100">
                        <div className="d-flex justify-content-between align-items-center mb-3 mb-md-0 w-100" style={{ gap: '10px', width: 'fit-content' }}>
                            <div className={`${styles.hold_input} w-100`}>
                                <img src="/assets/icons/search.svg" alt="" />
                                <input
                                    type="text"
                                    name="search"
                                    placeholder="Search"
                                    style={{ paddingLeft: "33px", width: "30em", maxWidth: '100%', borderRadius: '0', fontSize: '13px', minHeight: '40px' }}
                                    className=" form-control rounded-0"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <div className="fit-content d-flex" style={{ whiteSpace: 'nowrap' }}>
                                <div className="btn-group">
                                    <button
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                        className="btn border btn-primary"
                                        type="button"
                                    >
                                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.51855 8.93359V10.3937C1.51855 10.9313 1.95436 11.3671 2.49195 11.3671H9.30576C9.84335 11.3671 10.2792 10.9313 10.2792 10.3937V8.93359" stroke="#F3F4F6" strokeWidth="1.86" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3.9519 6.5L5.8987 8.4468L7.8455 6.5" stroke="#F3F4F6" strokeWidth="1.86" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M5.89868 1.6333V8.4471" stroke="#F3F4F6" strokeWidth="1.86" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        {" "}
                                        <span className="button_text text-white">Export</span>
                                    </button>
                                    <div
                                        className="dropdown-menu dropdown-menu-right rounded border-0"
                                        style={{
                                            background: "#fff",
                                            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                                        }}
                                    >
                                        <button
                                            className="dropdown-item dropdown_text fw-500 position-relative"
                                        >
                                            CSV
                                        </button>
                                        <button
                                            className="dropdown-item dropdown_text fw-500 position-relative"
                                        >
                                            PDF
                                        </button>
                                    </div>
                                </div>
                                &nbsp;&nbsp;
                                <div className="btn-group">
                                    <Link
                                        onClick={() => handleToggleDropdown()}
                                        className="btn border btn-primary rounded d-flex align-items-center"
                                    >
                                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.7523 5.01514L6.57741 8.19003C6.20246 8.56498 5.58891 8.56498 5.21396 8.19003L2.03906 5.01514" stroke="white" strokeWidth="1.16867" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        {" "}
                                        <span className="ml-1 text-white">Filters</span>
                                    </Link>
                                    <div
                                        className={`${toggleDropdown ? "show" : ""
                                            } dropdown-menu dropdown-menu-right ${styles.drp_dwn_menu_right_topup} drp_dwn_menu_right`}
                                        style={{ transform: "translate3d(-310px, 40px, 0px)" }}
                                    >
                                        <div className={`${styles.filter_wrap} px-2 px-sm-4 py-3`}>
                                            <div className="row">
                                                <div className="col-12 mb-1">

                                                    <div className="mb-3 d-flex flex-column">
                                                        <label htmlFor="name">Status</label>
                                                        <div className="position-relative bg-white">

                                                            <span className=" all_selectdropdown_icon position-absolute bg-white px-2">
                                                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z" fill="#6B7280" />
                                                                </svg>
                                                            </span>
                                                            <Select
                                                                labelId="s"
                                                                id="s"
                                                                fullWidth
                                                                size="small"
                                                                required
                                                                name="verified"
                                                                placeholder="Select"
                                                                defaultValue="ALL"
                                                            >
                                                                <MenuItem value="ALL">ALL</MenuItem>
                                                                <MenuItem value="WON">WON</MenuItem>
                                                                <MenuItem value="OPEN">OPEN</MenuItem>
                                                                <MenuItem value="PENDING">PENDING</MenuItem>
                                                                <MenuItem value="REFUNDED">REFUNDED</MenuItem>
                                                                <MenuItem value="LOST">LOST</MenuItem>
                                                            </Select>
                                                        </div>
                                                    </div>

                                                    <label htmlFor="ounit">Custom Date Range</label>

                                                    <div className="start_enddate d-flex">
                                                        <div className="" style={{ width: "100%" }}>
                                                            <div
                                                                className="mb-3 bg-white"
                                                                style={{ position: "relative" }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        position: "absolute",
                                                                        right: "10px",
                                                                        top: "7px",
                                                                        zIndex: "0",
                                                                    }}
                                                                >
                                                                    <svg
                                                                        width="14"
                                                                        height="14"
                                                                        viewBox="0 0 14 14"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M2.40527 4.29523C2.40527 3.38641 3.14202 2.64966 4.05084 2.64966H10.6331C11.5419 2.64966 12.2787 3.3864 12.2787 4.29523V10.8775C12.2787 11.7863 11.5419 12.5231 10.6331 12.5231H4.05084C3.14202 12.5231 2.40527 11.7863 2.40527 10.8775V4.29523Z"
                                                                            stroke="#7B8794"
                                                                            strokeWidth="1.64557"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                        <path
                                                                            d="M9.53516 1.55298V3.74707"
                                                                            stroke="#7B8794"
                                                                            strokeWidth="1.64557"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                        <path
                                                                            d="M5.14844 1.55298V3.74707"
                                                                            stroke="#7B8794"
                                                                            strokeWidth="1.64557"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                        <path
                                                                            d="M2.40527 5.94092H12.2787"
                                                                            stroke="#7B8794"
                                                                            strokeWidth="1.64557"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <DatePicker
                                                                    // showIcon
                                                                    showYearDropdown
                                                                    scrollableYearDropdown
                                                                    yearDropdownItemNumber={15}
                                                                    selected={startDate}
                                                                    onChange={(date) => setStartDate(date)}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    className="form-control border"
                                                                    placeholderText="Start date"
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="px-2 d-flex align-items-center "
                                                            style={{ height: "40px" }}
                                                        >
                                                            <img
                                                                src="/assets/icons/line.svg"
                                                                style={{ width: "30px" }}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="" style={{ width: "100%" }}>
                                                            <div
                                                                className="mb-3 bg-white"
                                                                style={{ position: "relative" }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        position: "absolute",
                                                                        right: "10px",
                                                                        top: "7px",
                                                                        zIndex: "0",
                                                                    }}
                                                                >
                                                                    <svg
                                                                        width="14"
                                                                        height="14"
                                                                        viewBox="0 0 14 14"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M2.40527 4.29523C2.40527 3.38641 3.14202 2.64966 4.05084 2.64966H10.6331C11.5419 2.64966 12.2787 3.3864 12.2787 4.29523V10.8775C12.2787 11.7863 11.5419 12.5231 10.6331 12.5231H4.05084C3.14202 12.5231 2.40527 11.7863 2.40527 10.8775V4.29523Z"
                                                                            stroke="#7B8794"
                                                                            strokeWidth="1.64557"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                        <path
                                                                            d="M9.53516 1.55298V3.74707"
                                                                            stroke="#7B8794"
                                                                            strokeWidth="1.64557"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                        <path
                                                                            d="M5.14844 1.55298V3.74707"
                                                                            stroke="#7B8794"
                                                                            strokeWidth="1.64557"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                        <path
                                                                            d="M2.40527 5.94092H12.2787"
                                                                            stroke="#7B8794"
                                                                            strokeWidth="1.64557"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <DatePicker
                                                                    // showIcon
                                                                    showYearDropdown
                                                                    scrollableYearDropdown
                                                                    yearDropdownItemNumber={15}
                                                                    selected={endDate}
                                                                    onChange={(date) => setEndDate(date)}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    className="form-control border"
                                                                    placeholderText="End date"
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-1">
                                                <div className="mr-auto" style={{ width: "fit-content" }}>
                                                    <button
                                                        type="button"
                                                        className={`btn border bg-white`}
                                                        onClick={() => handleToggleDropdown()}
                                                    >
                                                        Clear
                                                    </button>
                                                    &nbsp; &nbsp;
                                                    <button
                                                        type="button"
                                                        className={`btn btn-primary`}
                                                        onClick={() => handleToggleDropdown()}
                                                    >
                                                        Apply
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ marginTop: "0px", borderRadius: "10px" }} className="p-0">
                        <Scrollbar>
                            <div
                                className={`${styles.overflow_table} table-responsive  pt-3 pb-3 pl-0 pr-0`}
                                style={{ minHeight: '80vh' }}
                            >
                                <table
                                    className={`${styles.table} table-hover table-striped`}
                                    style={{ minWidth: "40em" }}
                                >
                                    <thead className={`${styles.thead}`}>
                                        <tr>
                                            <th className="text-muted fs-12 th_width3" style={{ minWidth: "26%" }}>
                                                Reference ID{" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>

                                            </th>
                                            <th className="text-muted fs-12 th_width">Customers {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            </th>
                                            <th className="text-muted fs-12 th_width_12">Status {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            </th>
                                            <th className="text-muted fs-12 th_width">Disputed On {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            </th>
                                            <th className="d-none d-md-block text-white" style={{ minWidth: '300px', color: 'white' }}><span style={{ color: 'white' }}>Action</span> {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#ffffff" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {currentTableData.map((item, index) => (
                                            <tr key={index} className={`${styles.tr} table-fonts cursor-pointer`}
                                                onClick={() => {
                                                    handleOpenTransactionDetailsDrawer();
                                                    setSelectedItem(item);
                                                }}>
                                                <td>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <div className=" ">
                                                            <span className=" fw-500">
                                                                {item.reference}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="fw-600">
                                                        {item.customer}
                                                    </span>
                                                    {/* <span style={{color: 'rgba(149, 155, 164, 1)'}}>
                                                    {item.currency} 
                                                </span> */}
                                                </td>
                                                <td>
                                                    <div className={`${item.status} fw-500 fs-11 badge_style chargebacks fit-content text-uppercase`}>
                                                        {item.status}
                                                    </div>
                                                </td>

                                                <td className="pr-5 pr-md-0">
                                                    <span className=" fw-500">{item.date_disputed}</span>
                                                </td>
                                                <td className="d-none d-lg-block">
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <Pagination
                                    currentPage={currentPage}
                                    totalResults={totalResults}
                                    pageSize={pageSize}
                                    onPageChange={onPageChange}
                                />
                            </div>
                            {/* <div className="d-flex justify-content-center">

            </div> */}

                            {/* <div className="alert alert-primary text-center">
                <h4>No record found</h4>
              </div> */}
                        </Scrollbar>
                    </div>
                </div>
            )}



            <Drawer
                anchor="right"
                open={transactionDetailsDrawer}
                onClose={handleCloseTransactionDetailsDrawer}
                PaperProps={{
                    sx: { background: 'rgba(249, 250, 251, 1)', width: 350, maxWidth: '100%', border: 'none', overflow: '', marginTop: '0em', height: '100vh' },
                }}
                className={`${styles.drawerHolder}`}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, pt: 2, pb: 1 }} className="">
                    <div className={`${styles.header_wrap} d-sm-flex justify-content-between align-items-center`}>
                        <div className="d-flex">
                            <h6 className="pl-3 mb-0"></h6>
                        </div>
                    </div>
                    <IconButton onClick={() => setTransactionDetailsDrawer(false)}>
                        <Iconify icon="eva:close-fill" />
                    </IconButton>
                </Stack>

                {/* <Divider /> */}

                <Scrollbar>
                    <Stack spacing={2} sx={{ p: 0, }}>
                        <div
                            className={`modal_content`}
                            style={{ minHeight: '90vh', }}
                        >

                            <ChargebackDetails item={selectedItem} />
                        </div>
                    </Stack>
                </Scrollbar>

            </Drawer>
        </>
    );
}
