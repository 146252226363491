import { Helmet } from "react-helmet-async";
// import * as Router from 'react-router-dom';
// import Scrollbar from "../../../components/scrollbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@mui/material/TextField";
import { Link as RouterLink } from "react-router-dom";

import { Link } from "@mui/material";
import {
    // useEffect, 
    useState
} from "react";
// import bus from "src/bus";
import GlobalModal from "../../Modals/GlobalModal";
import DatePicker from "react-datepicker";
import Scrollbar from "../../components/scrollbar";
import styles from "./LedgerTable.module.css";
// ----------------------------------------------------------------------

export default function SalesLedger() {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [existingBen, setExistingBen] = useState(false);

    const handleCloseExistingBen = () => {
        setExistingBen(false);
    };

    const tableData = [
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: '',
            credit: 'N2500',
        },
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: 'N2500',
            credit: '',
        },
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: 'N2500',
            credit: '',
        },
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: '',
            credit: 'N2500',
        },
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: 'N2500',
            credit: '',
        },
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: '',
            credit: 'N2500',
        },
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: 'N2500',
            credit: '',
        },
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: '',
            credit: 'N2500',
        },
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: 'N2500',
            credit: '',
        },
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: 'N2500',
            credit: '',
        },
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: '',
            credit: 'N2500',
        },
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: 'N2500',
            credit: '',
        },
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: 'N2500',
            credit: '',
        },
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: '',
            credit: 'N2500',
        },
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: 'N2500',
            credit: '',
        },
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: '',
            credit: 'N2500',
        },
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: 'N2500',
            credit: '',
        },
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: '',
            credit: 'N2500',
        },
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: 'N2500',
            credit: '',
        },
        {
            date: '12/03/24',
            desc: 'Being sales from stock sold for the future of OnePoint',
            debit: 'N2500',
            credit: '',
        },
    ];



    return (
        <>
            <Helmet>
                <title> Ledger Management - Sales Ledger </title>
            </Helmet>
            <div className="wrapper">
                <div className={`${styles.header_wrap} d-flex justify-content-between align-items-center`}>
                    <div className="d-flex">
                        <Link
                            className="d-block text-secondary"
                            to="/app/ledger-management"
                            component={RouterLink}
                        >
                            <i
                                className="bi bi-x"
                                style={{ fontSize: "1.5em", lineHeight: "1" }}
                            ></i>
                        </Link>
                        <h6 className="pl-2 pl-sm-3 mb-0" style={{ fontWeight: "bold" }}>Sales Ledger</h6>
                    </div>
                    <div className="header_btn_group mt-2 mt-sm-0">
                        <div className="btn-group">
                            <button
                                className="btn border btn-primary"
                            >
                                <span className="button_text text-white">Download</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className={` mt-3 mt-md-5 px-0 py-4 p-sm-4`}>
                    <div className="mb-3 d-md-flex align-items-center">
                        <label htmlFor="ounit" className="mr-3">Custom Date Range:</label>

                        <div className="d-sm-flex align-items-center">
                            <div className=" d-flex align-items-center">
                                <div className="" style={{ width: "fit-content" }}>
                                    <div
                                        className=""
                                        style={{ position: "relative" }}
                                    >
                                        <span
                                            style={{
                                                position: "absolute",
                                                right: "15px",
                                                top: "5px",
                                                zIndex: "10",
                                            }}
                                        >
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66602 1.66699V4.16699" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M13.334 1.66699V4.16699" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M2.91602 7.5752H17.0827" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M17.5 7.08366V14.167C17.5 16.667 16.25 18.3337 13.3333 18.3337H6.66667C3.75 18.3337 2.5 16.667 2.5 14.167V7.08366C2.5 4.58366 3.75 2.91699 6.66667 2.91699H13.3333C16.25 2.91699 17.5 4.58366 17.5 7.08366Z" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M13.0781 11.4167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M13.0781 13.9167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.99607 11.4167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.99607 13.9167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.91209 11.4167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.91209 13.9167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                        </span>
                                        <DatePicker
                                            // showIcon
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={15}
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control w-100"
                                            placeholderText="Start date"
                                            required
                                        />
                                    </div>
                                </div>
                                <div
                                    className="px-2 d-flex align-items-center "
                                    style={{ height: "40px" }}
                                >
                                    <img
                                        src="/assets/icons/line.svg"
                                        style={{ width: "20px" }}
                                        alt=""
                                    />
                                </div>
                                <div className="" style={{ width: "fit-content" }}>
                                    <div
                                        className=""
                                        style={{ position: "relative" }}
                                    >
                                        <span
                                            style={{
                                                position: "absolute",
                                                right: "15px",
                                                top: "5px",
                                                zIndex: "10",
                                            }}
                                        >
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.66602 1.66699V4.16699" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M13.334 1.66699V4.16699" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M2.91602 7.5752H17.0827" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M17.5 7.08366V14.167C17.5 16.667 16.25 18.3337 13.3333 18.3337H6.66667C3.75 18.3337 2.5 16.667 2.5 14.167V7.08366C2.5 4.58366 3.75 2.91699 6.66667 2.91699H13.3333C16.25 2.91699 17.5 4.58366 17.5 7.08366Z" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M13.0781 11.4167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M13.0781 13.9167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.99607 11.4167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.99607 13.9167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.91209 11.4167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.91209 13.9167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                        </span>
                                        <DatePicker
                                            // showIcon
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={15}
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control"
                                            placeholderText="End date"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="btn-group d-block ml-auto ml-md-3 mt-2 mt-sm-0">
                                <div className="ml-auto" style={{ width: 'fit-content' }}>
                                    <button
                                        className="btn border btn-primary px-4 py-2"
                                    >
                                        <span className="button_text text-white">Apply</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="px-0">

                            <div style={{ marginTop: "0px", borderRadius: "10px" }} className="p-0">
                                <Scrollbar>
                                    <div
                                        className={`${styles.overflow_table} table-responsive pl-0 pr-0`}
                                    >
                                        <table
                                            className={`${styles.table} w-100 table-hover table-striped`}
                                            style={{ minWidth: "50em", }}
                                        >
                                            <thead className={`${styles.thead}`} style={{ position: 'sticky', top: '0' }}>
                                                <tr className="w-100">
                                                    <th className="text-muted font-weight-bold" style={{ minWidth: "6%" }}>
                                                        Date
                                                    </th>
                                                    <th className="text-muted font-weight-bold">Description</th>
                                                    <th className="text-muted font-weight-bold">Debit</th>
                                                    <th className="text-muted font-weight-bold">Credit</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                    <div
                                        className={`${styles.overflow_table} table-responsive  pt- pb-3 pl-0 pr-0`}
                                        style={{height: '30em'}}
                                    >
                                        <table
                                            className={`${styles.table} w-100 table-hover table-striped`}
                                            style={{ minWidth: "50em", }}
                                        >
                                        <thead className={`${styles.thead_flat}`} style={{ height:'0' }}>
                                                <tr className="w-100 h-0">
                                                    <th className="py-0 text-muted font-weight-bold" style={{ height: "0%" }}>
                                                    </th>
                                                    <th className="py-0 text-muted font-weight-bold"></th>
                                                    <th className="py-0 text-muted font-weight-bold"></th>
                                                    <th className="py-0 text-muted font-weight-bold"></th>
                                                </tr>
                                            </thead>

                                            <tbody className=" styled_tbody">
                                                {tableData.map((item, index) => (
                                                    <tr key={index} className={`${styles.tr} bg-white table-fonts`}>
                                                    <td style={{width: '21%'}}>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <div className=" ">
                                                                    <span>
                                                                        {item.date}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{width: '33%'}}>
                                                            <span 
                                                            className="d-block"
                                                            style={{
                                                                width: '14em',
                                                                overflow: 'hidden',
                                                                whiteSpace: 'nowrap',
                                                                wordWrap: 'normal',
                                                                wordBreak: 'keep-all',
                                                                textOverflow: 'ellipsis'
                                                            }}>
                                                                {item.desc}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                {item.debit}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span style={{ fontWeight: '500' }}>
                                                                {item.credit}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr className={`${styles.tr} bg-white table-fonts`}>
                                                    <td></td>
                                                    <td><span className="font-weight-bold">Balance C/D</span></td>
                                                    <td><span className="font-weight-bold">N8000</span></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <div className="d-flex justify-content-center">

</div> */}

                                    {/* <div className="alert alert-primary text-center">
<h4>No record found</h4>
</div> */}
                                </Scrollbar>
                                {/* <div className="card border-0 p-0 m-0 responsive_paginate_btn_sect">
        <div className="card-body pt-0 mt-0">
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-item-center ">
                    <small className="font-weight-normal ">Showing 1 to 10 of 20 results</small>
                </div>
                
                <div className={`${styles.pagination_button_container}`}>
                    <button className="btn pginate mr-3">
                        <span className=" d-none d-sm-block">Previous</span>
                        <i className="bi bi-chevron-left d-block d-sm-none"></i>
                    </button>
                    <button className="btn pginate">
                        <span className=" d-none d-sm-block">Next</span>
                        <i className="bi bi-chevron-right d-block d-sm-none"></i>
                    </button>
                </div>
            </div>
        </div>
    </div> */}
                            </div>
                        </div>
                    </div>
                </div>



                {/* Select Existing Beneficiary Modal */}
                <GlobalModal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={existingBen}
                    onClose={handleCloseExistingBen}
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    width="500px !important"
                    maxWidth="100%"
                    overflowY="scroll"
                    bgcolor="#FFFF"
                    border="1px solid #F5F5F5"
                    borderRadius="5px"
                    boxShadow="24"
                    p="25px"
                >
                    <div
                        className={`modal_content`}
                    // style={{overflowY: 'scroll', height: '450px'}}
                    >
                        <div className="mb-4 d-flex justify-content-between align-items-start">
                            <h5>Beneficiaries</h5>
                            <span
                                className="px-3 py-2"
                                style={{ fontSize: "25px", cursor: "pointer", position: 'absolute', top: '0', right: '0' }}
                                onClick={() => setExistingBen(false)}
                            >
                                &times;
                            </span>
                        </div>
                        <div className={`modal_header mb-0`}>
                            <div className={`${styles.hold_input1} w-100`}>
                                <img src="/assets/icons/search.svg" alt="" />
                                <input
                                    type="text"
                                    name="search"
                                    placeholder="Search"
                                    style={{ paddingLeft: "33px", width: "100%" }}
                                    className=" form-control"
                                />
                            </div>
                        </div>

                        <div
                            className="mt-4 modal_content"
                            style={{ maxHeight: "70vh", overflowY: "scroll" }}
                        >
                            <div
                                className="border-bottom py-3"
                                onClick={() => setExistingBen(false)}
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <div>
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong style={{ fontWeight: '600' }}>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong style={{ fontWeight: '600' }}>Calvin Hawkins</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong style={{ fontWeight: '600' }}>Ted Fox</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong style={{ fontWeight: '600' }}>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong style={{ fontWeight: '600' }}>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong style={{ fontWeight: '600' }}>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong style={{ fontWeight: '600' }}>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong style={{ fontWeight: '600' }}>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong style={{ fontWeight: '600' }}>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong style={{ fontWeight: '600' }}>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong style={{ fontWeight: '600' }}>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </GlobalModal>
            </div>
        </>
    );
}
