import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translateText from './translateService'; // Import the translation service
import Backend from 'i18next-http-backend';

// Translation files
// const resources = {
//   en: {
//     translation: {
//       'OnePoint Africa': 'Ski dush',
//       thanks: 'Thanks for subscribing',
//       we_notify: 'We will notify you every week',
//     },
//   },
//   zh: {
//     translation: {
//       'OnePoint Africa': 'Liink er',
//       thanks: 'Thanks for subscribing',
//       we_notify: 'We will notify you every week',
//     },
//   },
// };

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    backend: {
      loadPath: '/locales/i18n/{{ns}}/{{lng}}.json',
    },
    //Name spaces base on features
    ns: ['landing-page'],
    react: {
      wait: true,
    },

    load: 'languageOnly', // Load only the base language, ignoring region
  });

// Function to dynamically add missing translations
// i18n.on('languageChanged', async (lng) => {
//   if (!resources[lng]) {
//     resources[lng] = { translation: {} };
//   }

//   const enTranslations = resources.en.translation;
//   for (const key in enTranslations) {
//     if (!resources[lng].translation[key]) {
//       const translatedText = await translateText(enTranslations[key], lng);
//       resources[lng].translation[key] = translatedText;
//       i18n.addResource(lng, 'translation', key, translatedText);
//     }
//   }
// });

export default i18n;
