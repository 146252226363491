import { Helmet } from "react-helmet-async";
// import * as Router from 'react-router-dom';
// import Scrollbar from "../../../components/scrollbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@mui/material/TextField";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Iconify from "../../../components/iconify";

import {
  Link,
  Checkbox,
  Box,
  AppBar,
  Toolbar,
  Stack,
  Drawer,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import React, {
  useEffect,
  useState,
} from "react";
// import bus from "src/bus";
import GlobalModal from "../../../Modals/GlobalModal";
import styles from "../../Wallet/Wallet.module.css";
import Scrollbar from "../../../components/scrollbar";
import Button from "src/theme/overrides/Button";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import bus from "src/bus";
import CircularProgress from "@mui/material/CircularProgress";
import axiosInstance from "src/axiosInstance"

import { notifySuccess, notifyError } from "src/hooks/useMockData";
// ----------------------------------------------------------------------

export default function CreateGroup() {
  const navigate = useNavigate();
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState("receivables");
  const [saveStatus, setSaveStatus] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [GroupName, setGroupName] = useState('');
  const [GroupDesc, setGroupDesc] = useState('');
  const [IsLoading, setIsLoading] = useState(false);
  const [IsCustomersLoading, setIsCustomersLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_NAME;
  const [SCData, setSCData] = useState([]);


  useEffect(() => {
    fetchCustomerData();
  }, []);


  // const customersData = [
  //   {
  //     "id": "e3974fce-4991-4e64-ae70-6a237240de49",
  //     "name": "Business development",
  //     "description": "vps",
  //     "createdAt": "2024-07-08T17:24:09.000Z",
  //     "updatedAt": "2024-07-08T17:24:09.000Z",
  //     "customerCount": 2
  //   },
  //   {
  //     "id": "8ade892d-dd9a-483d-a908-3aec6a80bb7e",
  //     "name": "Expert Tech 2",
  //     "description": "vps",
  //     "createdAt": "2024-07-08T17:23:59.000Z",
  //     "updatedAt": "2024-07-08T17:23:59.000Z",
  //     "customerCount": 2
  //   },
  //   {
  //     "id": "819979ed-c0d8-4e26-b4aa-9ccb4ab1599f",
  //     "name": "Algo expert Tech 2",
  //     "description": "vps",
  //     "createdAt": "2024-07-08T17:23:54.000Z",
  //     "updatedAt": "2024-07-08T17:23:54.000Z",
  //     "customerCount": 2
  //   },
  //   {
  //     "id": "c0f17f39-e232-4060-bde6-2c1f6e39e449",
  //     "name": "Landing Tech 2",
  //     "description": "vps",
  //     "createdAt": "2024-07-08T17:23:46.000Z",
  //     "updatedAt": "2024-07-08T17:23:46.000Z",
  //     "customerCount": 2
  //   },
  //   {
  //     "id": "c7c71e86-2161-4628-8a34-864711f93bb7",
  //     "name": "Tech 2",
  //     "description": "vps",
  //     "createdAt": "2024-07-08T17:23:41.000Z",
  //     "updatedAt": "2024-07-08T17:23:41.000Z",
  //     "customerCount": 2
  //   },
  //   {
  //     "id": "785ad2bf-9669-49f1-adcd-5bf67fd794d9",
  //     "name": "Vanguards 2",
  //     "description": "vps",
  //     "createdAt": "2024-07-08T17:23:33.000Z",
  //     "updatedAt": "2024-07-08T17:23:33.000Z",
  //     "customerCount": 2
  //   },
  //   {
  //     "id": "ccf9c2b9-9365-4401-8c31-e9af59c3fe2d",
  //     "name": "Vanguards",
  //     "description": "vps",
  //     "createdAt": "2024-07-08T12:55:13.000Z",
  //     "updatedAt": "2024-07-08T12:55:13.000Z",
  //     "customerCount": 1
  //   },
  //   {
  //     "id": "89a8437c-8702-4573-8dff-0e9cbe75bdfd",
  //     "name": "Vips",
  //     "description": "vps",
  //     "createdAt": "2024-07-08T12:55:02.000Z",
  //     "updatedAt": "2024-07-08T12:55:02.000Z",
  //     "customerCount": 1
  //   },
  //   {
  //     "id": "a1bf8a54-fe21-4590-8657-ed3da19119ab",
  //     "name": "OG.s..ss",
  //     "description": "Agba",
  //     "createdAt": "2024-07-08T12:52:58.000Z",
  //     "updatedAt": "2024-07-08T12:52:58.000Z",
  //     "customerCount": 0
  //   },
  //   {
  //     "id": "4b80e580-b0e2-4157-8784-8cbc4f6d75c4",
  //     "name": "OG.s..s",
  //     "description": "Agba",
  //     "createdAt": "2024-07-08T12:52:31.000Z",
  //     "updatedAt": "2024-07-08T12:52:31.000Z",
  //     "customerCount": 0
  //   }
  // ];

  useEffect(() => {
    // Update the checkedItems array whenever saveStatus changes
    const checked = Object.keys(saveStatus).filter((key) => saveStatus[key]);
    setCheckedItems(checked);
  }, [saveStatus]);

  const fetchCustomerData = async () => {
    setIsCustomersLoading(true);
    try {
      const response = await axiosInstance.get(`/payments/customers?productName=${apiUrl}`);
      const customersData = response.data.data.data;

      setSCData(customersData);

      if (customersData.length) {
      }
    } catch (err) {
      console.error(err);
    } finally {
    setIsCustomersLoading(false);
    }
  };

  const handleCreateGroup = () => {
    navigate("/app/customer/all");
  };

  const handleChangeSave = (id) => {
    setSaveStatus((prevSaveStatus) => ({
      ...prevSaveStatus,
      [id]: !prevSaveStatus[id],
    }));
    // console.log("saveStatus", saveStatus);
  };

  const filteredCustomers = SCData.length && SCData.filter(customer =>
    customer.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (GroupName && GroupDesc) {

      if (checkedItems.length < 1) {
        notifyError('Kindly select one or more customers to proceed!')
        return;
      }

      let payload = {
        name: GroupName,
        description: GroupDesc,
        // customers: demoItems,
        customers: checkedItems,
      }


      setIsLoading(true)

      try {
        const response = await axiosInstance.post(`/payments/customers/group?productName=${apiUrl}`, payload)

        const data = response.data;
        if (data.status === 'success') {
          notifySuccess(response.data.message)
          navigate("/app/customer/all");
        }
      } catch (err) {
        if (err.response) {
          if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
            notifyError(err.response.data.data.errorMessage);
          } else if (err.response.data && err.response.data.message) {
            notifyError(err.response.data.message);
          } else {
            notifyError('An unexpected error occurred');
          }
        } else {
          notifyError('Network error or server not responding');
        }
      } finally {
        setIsLoading(false);
      }

    } else {
      notifyError('Kindly fill all fields to proceed!')
    }

  };

  return (
    <>
      <Helmet>
        <title>Customers - Create Group</title>
      </Helmet>

      <div className="wrapper">
        <div
          className={`${styles.header_wrap} d-sm-flex justify-content-between align-items-center`}
        >
          <div className="d-flex">
            <Link
              className="d-block text-secondary"
              to="/app/customer/all"
              component={RouterLink}
            >
              <i
                className="bi bi-x"
                style={{ fontSize: "1.5em", lineHeight: "1" }}
              ></i>
            </Link>
            <h6
              className="pl-2 pl-sm-3 mb-0 fw-700 pb-0"
              style={{ lineHeight: "1.5" }}
            >
              Create Group
            </h6>
          </div>
          <div className="header_btn_group d-none d-sm-block mt-2 mt-sm-0">
            <div className="btn-group">
              <button
                className="btn border btn-primary"
                onClick={handleSubmit}
              >
                <span className="button_text text-white">Create
                  {
                    IsLoading &&
                    <CircularProgress
                      style={{ color: "#fff" }}
                      className="text-white ml-1"
                      size="0.8rem"
                    />
                  }</span>
              </button>
            </div>
          </div>
        </div>

        <div className="header_btn_group d-block d-sm-none mt-3 mt-sm-0">
          <div className="btn-group">
            <button
              className="btn border btn-primary"
              onClick={handleSubmit}
            >
              <span className="button_text text-white">Create
                {
                  IsLoading &&
                  <CircularProgress
                    style={{ color: "#fff" }}
                    className="text-white ml-1"
                    size="0.8rem"
                  />
                }</span>
            </button>
          </div>
        </div>

        <div className="row pb-5 mt-4">
          <div className="col-md-4">
            <div className="mb-3">
              <label htmlFor="group_name">Customer Group Name</label>
              <div className="" style={{ width: "100%" }}>
                <div className="">
                  <TextField
                    name="group_name"
                    required
                    type="text"
                    id="group_name"
                    size="small"
                    placeholder=""
                    fullWidth
                    value={GroupName}
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="mb-4">
              <label htmlFor="group_desc">Description</label>
              <div className="" style={{ width: "100%" }}>
                <div className="">
                  <TextField
                    name="group_desc"
                    required
                    type="text"
                    id="group_desc"
                    size="small"
                    placeholder=""
                    fullWidth
                    value={GroupDesc}
                    onChange={(e) => setGroupDesc(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* <div className="mb-3 px-0 py-sm-4">
              <label htmlFor="pty">Customer Type</label>
              <div className="position-relative">
                <span className=" all_selectdropdown_icon position-absolute bg-white px-2">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.43287 8.84964L7.10787 11.5246L8.7412 13.1663C9.43287 13.858 10.5579 13.858 11.2495 13.1663L15.5662 8.84964C16.1329 8.28298 15.7245 7.31631 14.9329 7.31631H10.2579H5.0662C4.2662 7.31631 3.8662 8.28298 4.43287 8.84964Z"
                      fill="#4B5563"
                    />
                  </svg>
                </span>
                <Select
                  labelId="pty"
                  id="pty"
                  fullWidth
                  size="small"
                  required
                  name="verified"
                  value={selectedOption}
                  onChange={handleChange}
                >
                  <MenuItem value="receivables">Receivables</MenuItem>
                  <MenuItem value="payables">Payables</MenuItem>
                </Select>
              </div>
            </div> */}
          </div>
          <div className="col-md-8">
            <div
              className={`${styles.trftobank_side_wrap} px-0 px-sm-4 py-4 mx-auto mx-md-0 ml-md-5`}
              style={{ background: "rgba(249, 250, 251, 1)", height: '76vh', overflowY: 'auto' }}
            >
              {" "}
              <div className={` w-100 pt-2`}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="">
                    <p className="page_title fs-16 fw-500 text-dark mb-2">
                      Select Customers
                    </p>
                    <p style={{ color: "#4B5563", fontSize: "13px" }}>
                      <span>Assign Customers to this group</span>
                    </p>
                  </div>
                </div>

                <p
                  className="mb-2 mt-4"
                  style={{ color: "#4B5563", fontSize: "13px" }}
                >
                  <span className="text-dark fs-14 fw-500">
                    {checkedItems.length} Customers selected
                  </span>
                </p>
                <div className={`modal_header mb-0`}>
                  <div className={`${styles.hold_input} w-100`}>
                    <img src="/assets/icons/search.svg" alt="" />
                    <input
                      type="text"
                      name="search"
                      placeholder="Search"
                      className=" form-control border w-100 fs-13 py-3"
                      style={{ paddingLeft: "33px", }}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>

                <div
                  className="mt-2 modal_content"
                  style={{ overflowY: "scroll" }}
                >
                  {
                    IsCustomersLoading ?
                      <div className="fit-content mx-auto mt-4">
                      <CircularProgress
                        style={{ color: "#fff" }}
                        className="text-white ml-1"
                        size="1.4rem"
                      />
                      </div> :
                      <>
                        {filteredCustomers.length > 0 ? (
                          <>
                            {filteredCustomers.map((item) => (
                              <div
                                key={item.id}
                                className="py-3"
                                style={{ cursor: "pointer" }}
                              >
                                <div className="d-flex align-items-center">
                                  <div className="checkbox_div">
                                    <Checkbox
                                      id={item.id}
                                      checked={saveStatus[item.id] || false}
                                      onChange={() => handleChangeSave(item.id)}
                                      style={{}}
                                    />
                                  </div>
                                  <label htmlFor={item.id} className="d-block w-100 ml-2 mb-0 cursor-pointer">
                                    <p className="mb-0" style={{ fontSize: "13px" }}>
                                      <label
                                        htmlFor={item.id}
                                        className="mb-0 cursor-pointer"
                                        style={{ fontWeight: "500", color: "#000" }}
                                      >
                                        {item.name}
                                      </label>
                                    </p>
                                    <span
                                      className="text-muted cursor-pointer"
                                      style={{ fontSize: "11px" }}
                                    >
                                      {item.email}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <>
                            <h4 className="fw-500 fs-16 mb-4 text-center d-block border-top py-4">No record!</h4>
                          </>
                        )}
                      </>
                  }
                  {/* {console.log(checkedItems)} */}
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </>
  );
}
