import { Helmet } from "react-helmet-async";
// import * as Router from 'react-router-dom';
// import Scrollbar from "../../../components/scrollbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@mui/material/TextField";
import { Link as RouterLink } from "react-router-dom";

import { Link } from "@mui/material";
import {
    // useEffect, 
    useState
} from "react";
// import bus from "src/bus";
import GlobalModal from "../../../Modals/GlobalModal";
import styles from "../Messaging.module.css";
// ----------------------------------------------------------------------

export default function SingleMessaging() {
    const [existingBen, setExistingBen] = useState(false);

    const handleCloseExistingBen = () => {
        setExistingBen(false);
    };


    return (
        <>
            <Helmet>
                <title> Messaging - Single Messaging </title>
            </Helmet>
            <div className="wrapper">
                <div className={`${styles.header_wrap} d-flex justify-content-between align-items-center`}>
                    <div className="d-flex">
                        <Link
                            className="d-block text-secondary"
                            to="/app/messaging/send-message"
                            component={RouterLink}
                        >
                            <i
                                className="bi bi-x"
                                style={{ fontSize: "1.5em", lineHeight: "1" }}
                            ></i>
                        </Link>
                        <h6 className="pl-3 mb-0" style={{ fontWeight: "bold" }}>Single Messaging</h6>
                    </div>
                </div>

                <div className={`${styles.trftobank_side_wrap} mt-2 mt-md-5 px-0 py-4 p-sm-4`}>
                    <div>
                        <div className="mb-3">
                            <label htmlFor="ounit">Sender ID</label>
                            <div className="" style={{ width: "100%" }}>
                                <div className="">
                                    <Select
                                        labelId="s"
                                        id="s"
                                        fullWidth
                                        size="small"
                                        required
                                        name="verified"
                                        defaultValue="launchPad"
                                    >
                                        <MenuItem value="launchPad">LaunchPad</MenuItem>
                                        <MenuItem value="onePoint">OnePoint</MenuItem>
                                        <MenuItem value="flex">Flex</MenuItem>
                                        <MenuItem value="rxDeals">RXDeals</MenuItem>
                                    </Select>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="name">Select Channel</label>
                            <Select
                                labelId="s"
                                id="s"
                                fullWidth
                                size="small"
                                required
                                name="verified"
                                placeholder="- Select -"
                                defaultValue="select"
                            >
                                <MenuItem value="select">- Select -</MenuItem>
                                <MenuItem value="whatsapp">WhatsApp</MenuItem>
                                <MenuItem value="sms">SMS</MenuItem>
                                <MenuItem value="email">Email</MenuItem>
                            </Select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="ounit">Enter Phone Number</label>
                            <div className="" style={{ width: "100%" }}>
                                <div className="">
                                    <TextField
                                        name=""
                                        required
                                        type="number"
                                        id="ounit"
                                        size="small"
                                        placeholder=""
                                        fullWidth
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="ounit">Enter Message</label>
                            <div className="" style={{ width: "100%" }}>
                                <div className="">
                                    <TextField
                                        name="min_products"
                                        required
                                        multiline
                                        rows={4}
                                        type="text"
                                        id="ounit"
                                        size="small"
                                        placeholder=""
                                        fullWidth
                                    />
                                    <small className="text-secondary font-weight-normal">
                                        charge for transaction NGN10
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div className="my-5">
                            <Link to="/app/wallet/payment-otp" component={RouterLink} className="btn border btn-primary">
                                <span className="button_text text-white px-2">Send</span>
                            </Link>
                            &nbsp; &nbsp;
                            <Link
                                className="btn border"
                                to="/app/wallet"
                                component={RouterLink}
                            >
                                <span className="button_text">Cancel</span>
                            </Link>
                        </div>
                    </div>
                </div>



                {/* Select Existing Beneficiary Modal */}
                <GlobalModal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={existingBen}
                    onClose={handleCloseExistingBen}
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    width="500px !important"
                    maxWidth="100%"
                    overflowY="scroll"
                    bgcolor="#FFFF"
                    border="1px solid #F5F5F5"
                    borderRadius="5px"
                    boxShadow="24"
                    p="25px"
                >
                    <div
                        className={`modal_content`}
                    // style={{overflowY: 'scroll', height: '450px'}}
                    >
                        <div className="mb-4 d-flex justify-content-between align-items-start">
                            <h4>Beneficiaries</h4>
                            <span
                                className="px-2"
                                style={{ fontSize: "25px", cursor: "pointer" }}
                                onClick={() => setExistingBen(false)}
                            >
                                &times;
                            </span>
                        </div>
                        <div className={`modal_header mb-0`}>
                            <div className={`${styles.hold_input1} w-100`}>
                                <img src="/assets/icons/search.svg" alt="" />
                                <input
                                    type="text"
                                    name="search"
                                    placeholder="Search"
                                    style={{ paddingLeft: "33px", width: "100%" }}
                                    className=" form-control"
                                />
                            </div>
                        </div>

                        <div
                            className="mt-4 modal_content"
                            style={{ maxHeight: "70vh", overflowY: "scroll" }}
                        >
                            <div
                                className="border-bottom py-3"
                                onClick={() => setExistingBen(false)}
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <div>
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Calvin Hawkins</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Ted Fox</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom py-3">
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div className=" ">
                                        <p className="mb-0 text-dark" style={{ fontSize: "16px" }}>
                                            <strong>Kristen Ramos</strong>
                                        </p>
                                        <span className="text-muted" style={{ fontSize: "13px" }}>
                                            Sterling Bank - 0066924804
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </GlobalModal>
            </div>
        </>
    );
}
