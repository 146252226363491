import { useState, useRef, useEffect } from "react";
import styles from "../CustomerComponent.module.css";
import Scrollbar from "../../../components/scrollbar";
import Pagination from "../../../pagination/GlobalPagination";

import {
  Link,
  Stack,
  Drawer,
  Box,
  Typography,
  IconButton,
  Button
  //   Avatar,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Iconify from "../../../components/iconify";
import Pageloader from 'src/pageloader';
import axiosInstance from "src/axiosInstance"
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import {
  DateTimeFormat,
  formatDateToDMY,
  fullNameDateFormat,
  formatDateToDMYWithHyphen,
  formatDateToToFullYear,
  formatDateToFullYear
} from "src/hooks/useDateFormat";
import GlobalModal from "src/Modals/GlobalModal";
import CircularProgress from "@mui/material/CircularProgress";
import html2canvas from "html2canvas";
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

// ----------------------------------------------------------------------

export default function AllCustomersTable({ getTableData, search, filter }) {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const apiUrl = process.env.REACT_APP_NAME;
  const [isPending, setIsPending] = useState(false);
  const [transactionDetailsDrawer, setTransactionDetailsDrawer] =
    useState(false);
  const [selectedCustomerDetailsDrawer, setSelectedCustomerDetailsDrawer] = useState(false);
  const [CustomerID, setCustomerID] = useState('');

  const [allCustomersData, setallCustomersData] = useState([]);
  const [walletPagination, setWalletPagination] = useState({});
  const [PayoutTrfDetailsData, setPayoutTrfDetailsData] = useState(null);
  const detailsRef = useRef();
  const [isLoadingPLData, setIsLoadingPLData] = useState(false);

  const [ConfirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [IsItemDeleting, setIsItemDeleting] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);



  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setTooltipVisible(true);
        setTimeout(() => {
          setTooltipVisible(false);
        }, 2000);
      },
      (err) => {
        console.error('Failed to copy: ', err);
      }
    );
  };

  const handleConfirmDelete = async () => {
    setIsItemDeleting(true);
    try {
      const response = await axiosInstance.delete(`/payments/customers/${CustomerID}?productName=${apiUrl}`);

      notifySuccess(response.data.message);
      fetchCustomers()

    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
          notifyError(err.response.data.data.errorMessage);
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
        } else {
          notifyError('An unexpected error occurred');
        }
      } else {
        notifyError('Network error or server not responding');
      }
      console.error(err);
    } finally {
      setIsItemDeleting(false);
      setConfirmDeleteDialog(false);
    }
  };

  const handleToggleDeleteDialog = (item) => {
    setCustomerID(item.id)
    setConfirmDeleteDialog(true);
  };

  const handleCloseTransactionDetailsDrawer = () => {
    setTransactionDetailsDrawer(false);
  };

  const handleCloseSelectedCustomerDetailsDrawer = () => {
    setSelectedCustomerDetailsDrawer(false);
  };

  const detailsData = {
    "errorCode": "0",
    "id": "bd45b30a-d4ab-4e0f-9c63-578ab3ea2aa8",
    "name": "OMOYELE, OLADIPUPO WARITH",
    "email": null,
    "phone": null,
    "account_number": "2019170936",
    "account_name": "OMOYELE, OLADIPUPO WARITH",
    "bank_name": "Kuda Microfinance Bank",
    "bank_code": "50211",
    "createdAt": "2024-07-11T17:42:06.000Z",
    "updatedAt": "2024-07-20T23:00:23.000Z",
    "totalDebit": 82095,
    "totalCredit": 0,
    "transactions": [
      {
        "amount": 200,
        "customer_email": null,
        "description": "Money",
        "channel": "TRANSFER",
        "transaction_type": "PAYOUT",
        "transaction_date": "2024-07-27T13:14:42.000Z",
        "transaction_reference": "TRANS_17220860802038720",
        "currency": "NGN",
        "account_number": "2019170936",
        "account_name": "OMOYELE, OLADIPUPO WARITH",
        "bank_name": "Kuda Bank",
        "bank_code": "50211",
        "status": "COMPLETED",
        "createdAt": "2024-07-27T13:13:56.000Z",
        "updatedAt": "2024-07-27T13:13:56.000Z"
      }
    ]
  };

  const handleOpenCustomerDrawer = async (item) => {
    setSelectedCustomerDetailsDrawer(true);
    setPayoutTrfDetailsData(null);
    setIsLoadingPLData(true);
    // setSelectedImage(null);

    // setPayoutTrfDetailsData(item);
    // setSelectedImage(item.image);

    try {
      const response = await axiosInstance.get(`/payments/customers/${item.id}?productName=${apiUrl}`);
      const data = response.data.data;

      setPayoutTrfDetailsData(data);
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
          notifyError(err.response.data.data.errorMessage);
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
        } else {
          notifyError('An unexpected error occurred');
        }
      } else {
        notifyError('Network error or server not responding');
      }
      setSelectedCustomerDetailsDrawer(false);
    } finally {
      setIsLoadingPLData(false);

    }
  };



  const fetchCustomers = async () => {
    setIsPending(true)

    try {
      const response = await axiosInstance.get(`/payments/customers?productName=${apiUrl}`,
        {
          params: {
            perPage: pageSize,
            page: currentPage,
            search: search,
            ...filter
          },
        })
      const data = response.data.data.data
      setallCustomersData(data)
      setWalletPagination(response.data.data)
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
          notifyError(err.response.data.data.errorMessage);
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
        } else {
          notifyError('An unexpected error occurred');
        }
      } else {
        notifyError('Network error or server not responding');
      }
    } finally {
      setIsPending(false)
    }
  };

  useEffect(() => {
    fetchCustomers()
  }, [search, filter, currentPage, pageSize])

  getTableData(allCustomersData);

  const totalResults = allCustomersData.length;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const exportReceiptToPDF = () => {
    handleCloseTransactionDetailsDrawer();
    const input = detailsRef.current;

    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: "a5",
        });

        const imgWidth = 148; // Width in mm for A5
        const pageHeight = 150; // Height in mm for A5
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        let position = 0;
        if (imgHeight > pageHeight) {
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        } else {
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, pageHeight);
        }

        pdf.save("receipt.pdf");
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
            notifyError(err.response.data.data.errorMessage);
          } else if (err.response.data && err.response.data.message) {
            notifyError(err.response.data.message);
          } else {
            notifyError('An unexpected error occurred');
          }
        } else {
          notifyError('Network error or server not responding');
        }
        console.error("Error generating PDF: ", err);
      }).finally((err) => {
        setTransactionDetailsDrawer(false);
        setSelectedCustomerDetailsDrawer(false);
      });
  };




  // const tableData = [
  //   {
  //     cstmr_name: "Micheal Joseph ",
  //     email: "Micson@gmail.com",
  //     phone: "07063932728",
  //     Acct_no: "1234567890",
  //     bank_name: "Access Bank",
  //   },
  //   {
  //     cstmr_name: "Micheal Joseph ",
  //     email: "Micson@gmail.com",
  //     phone: "07063932728",
  //     Acct_no: "1234567890",
  //     bank_name: "Access Bank",
  //   },
  //   {
  //     cstmr_name: "Micheal Joseph ",
  //     email: "Micson@gmail.com",
  //     phone: "07063932728",
  //     Acct_no: "1234567890",
  //     bank_name: "Access Bank",
  //   },
  //   {
  //     cstmr_name: "Micheal Joseph ",
  //     email: "Micson@gmail.com",
  //     phone: "07063932728",
  //     Acct_no: "1234567890",
  //     bank_name: "Access Bank",
  //   },
  //   {
  //     cstmr_name: "Micheal Joseph ",
  //     email: "Micson@gmail.com",
  //     phone: "07063932728",
  //     Acct_no: "1234567890",
  //     bank_name: "Access Bank",
  //   },
  //   {
  //     cstmr_name: "Micheal Joseph ",
  //     email: "Micson@gmail.com",
  //     phone: "07063932728",
  //     Acct_no: "1234567890",
  //     bank_name: "Access Bank",
  //   },
  // ];

  // getTableData(tableData);

  // // const totalResults = tableData.length;

  // // const onPageChange = (page) => {
  // //   setCurrentPage(page);
  // // };

  // // Calculate the data to display on the current page
  // const currentTableData = tableData.slice(
  //   (currentPage - 1) * pageSize,
  //   currentPage * pageSize
  // );

  return (
    <>
      <div className="px-0 px-md-2">
        <div
          style={{ marginTop: "0px", borderRadius: "10px" }}
          className="p-0 wallet_designed_table_wrap"
        >
          <Scrollbar>
            <div
              className={`${styles.overflow_table} table-responsive pb- pl-0 pr-0`}
            >
              <table
                id="AllCustomersTable"
                className={`${styles.table} table-hover table-striped`}
                style={{ minWidth: "38em" }}
              >
                <thead className={`${styles.thead}`}>
                  <tr>
                    <th
                      className="text-muted"
                      style={{ borderBottom: "1px solid #EAECF0" }}
                    >
                      Customer ID{" "}
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                          stroke="#667085"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                    <th
                      className="text-muted th_width_12"
                      style={{ borderBottom: "1px solid #EAECF0" }}
                    >
                      Customer Name{" "}
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                          stroke="#667085"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                    <th
                      className="text-muted th_width"
                      style={{ borderBottom: "1px solid #EAECF0" }}
                    >
                      Account Bank{" "}
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                          stroke="#667085"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                    <th
                      className="text-muted th_width"
                      style={{ borderBottom: "1px solid #EAECF0" }}
                    >
                      Account No{" "}
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                          stroke="#667085"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                    <th
                      className="d-none d-md-block"
                      style={{ minWidth: "200px", borderBottom: "1px solid #EAECF0" }}
                    >
                      <div className="h-100" style={{ minHeight: '25px' }}></div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {isPending ? (
                    <>
                      <tr>
                        <td colspan="5" style={{ position: 'relative', height: '150px', backgroundColor: '#fff' }}>
                          <Pageloader loading={isPending} />
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {!isPending && allCustomersData.map((item, index) => (
                        <tr
                          key={index}
                          className={`${styles.tr} table-fonts cursor-pointer`}
                          style={{ minHeight: "70px" }}
                        >
                          <td onClick={() => handleOpenCustomerDrawer(item)}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <span className="fw-500">{item?.id ? item?.id : '---'}</span>
                              </div>
                            </div>
                          </td>

                          <td onClick={() => handleOpenCustomerDrawer(item)}>
                            <span className="fw-500">{item?.name ? item?.name : '---'}</span>
                          </td>
                          <td onClick={() => handleOpenCustomerDrawer(item)}>
                            <span className="fw-500">{item?.bank_name ? item?.bank_name : '---'}</span>
                          </td>
                          <td onClick={() => handleOpenCustomerDrawer(item)}>
                            <span className="fw-500">
                              {item?.account_number ? item?.account_number : '---'}</span>
                          </td>
                          <td className="" style={{ minWidth: "300px" }}>
                            <div className="btn-group">
                              <span
                                className="fw-500 btn rounded-0"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4.5 10.5C3.675 10.5 3 11.175 3 12C3 12.825 3.675 13.5 4.5 13.5C5.325 13.5 6 12.825 6 12C6 11.175 5.325 10.5 4.5 10.5ZM19.5 10.5C18.675 10.5 18 11.175 18 12C18 12.825 18.675 13.5 19.5 13.5C20.325 13.5 21 12.825 21 12C21 11.175 20.325 10.5 19.5 10.5ZM12 10.5C11.175 10.5 10.5 11.175 10.5 12C10.5 12.825 11.175 13.5 12 13.5C12.825 13.5 13.5 12.825 13.5 12C13.5 11.175 12.825 10.5 12 10.5Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>

                              <div
                                className="dropdown-menu dropdown-menu-right py-0 rounded border-0 overflow-hidden"
                                style={{
                                  background: "rgba(249, 250, 251, 1)",
                                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                                }}
                              >
                                <button
                                  onClick={() => handleOpenCustomerDrawer(item)}
                                  className="dropdown-item dropdown_text fw-500 position-relative"
                                >
                                  View
                                </button>
                                <button
                                  //   to=""
                                  //   component={RouterLink}
                                  className="dropdown-item dropdown_text fw-500 position-relative"
                                  onClick={() => handleToggleDeleteDialog(item)}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>

            {
              (!isPending && !allCustomersData.length) &&
              <div className="alerts alerts-primary text-center mt-4 mb-4">
                <h5>No record found</h5>
              </div>
            }
          </Scrollbar>

          {!isPending && allCustomersData.length ? (
            <Pagination
              currentPage={currentPage}
              totalResults={walletPagination.totalPages}
              totalItems={walletPagination.totalItems}
              pageSize={pageSize}
              onPageChange={onPageChange}
            />
          ) : (
            <Pagination
              currentPage={0}
              totalResults={0}
              totalItems={0}
              pageSize={0}
              onPageChange={onPageChange}
            />
          )}
        </div>
      </div>






      <Drawer
        anchor="right"
        open={selectedCustomerDetailsDrawer}
        onClose={handleCloseSelectedCustomerDetailsDrawer}
        PaperProps={{
          sx: {
            width: 350,
            maxWidth: "100%",
            border: "none",
            overflow: "",
            marginTop: "0em",
            height: "100vh",
          },
        }}
        className={`${styles.drawerHolder}`}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, pt: 2, pb: 1 }}
          className=""
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            {/* Filters */}
          </Typography>
          <IconButton onClick={() => setSelectedCustomerDetailsDrawer(false)}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        {/* <Divider /> */}
        {isLoadingPLData && (

          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
              width: '100%',
              height: '100%',
              background: 'rgb(249, 250, 251)',
              zIndex: '10',
            }}>
            <CircularProgress
              style={{ color: "white" }}
              className="text-white ml-1"
              size="1.5rem"
            />
          </div>
        )}
        <Scrollbar>
          <Stack spacing={2} sx={{ p: 0 }}>
            <div
              className={`modal_content `}
              style={{ minHeight: "90vh" }}
            >
              <div className={`px-3 px-sm-4 pb-5 `}>
                <div className="mt-1">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="mb-0 fw-500 text-dark">Details</p>
                      <span className="fw-500 fs-14" style={{ color: 'rgba(105, 115, 134, 1)' }}>View recent update about your customer</span>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <h4 className="d-flex mb-0">
                    <span className="fw-700 preview_amount text-capitalize" style={{ fontSize: '24px' }}>
                      {PayoutTrfDetailsData &&
                        PayoutTrfDetailsData.name !== null ?
                        PayoutTrfDetailsData.name.toLowerCase() : ''}
                    </span>
                  </h4>
                  <p className="mb-0">
                    <span className="fs-14 fw-500">{PayoutTrfDetailsData &&
                      PayoutTrfDetailsData.email !== null ?
                      PayoutTrfDetailsData.email : 'NIL Email'}
                    </span>
                  </p>
                  <p className="mb-0">
                    <span className="fs-14 fw-500">{PayoutTrfDetailsData &&
                      PayoutTrfDetailsData.phone !== null ?
                      PayoutTrfDetailsData.phone : 'NIL Phone'}
                    </span>
                  </p>
                </div>

                <div className="mt-4 d-flex justify-content-between align-items-center">
                  <div>
                    <small className="d-block fw-500 fs-14">Total Debit</small>
                    <span className="fs-13 fs-14" style={{ color: 'rgba(104, 115, 133, 1)' }}>
                      {PayoutTrfDetailsData ?
                        PayoutTrfDetailsData.transactions[0].currency === 'NGN' ? '₦' :
                          PayoutTrfDetailsData.transactions[0].currency === 'CNY' ? '¥' : '' : ''}{PayoutTrfDetailsData &&
                            PayoutTrfDetailsData.totalDebit !== null ? Number(PayoutTrfDetailsData.totalDebit).toLocaleString() : '0'}.00
                    </span>
                  </div>
                  <div className="text-right">
                    <small className="d-block fw-500 fs-14">Total Credit</small>
                    <span className="fs-13 fs-14" style={{ color: 'rgba(104, 115, 133, 1)' }}>
                      {PayoutTrfDetailsData ?
                        PayoutTrfDetailsData.transactions[0].currency === 'NGN' ? '₦' :
                          PayoutTrfDetailsData.transactions[0].currency === 'CNY' ? '¥' : '' : ''}{PayoutTrfDetailsData &&
                            PayoutTrfDetailsData.totalCredit !== null ? Number(PayoutTrfDetailsData.totalCredit).toLocaleString() : '0'}.00
                    </span>
                  </div>
                </div>

                <div className={`mt-4 pt-3`}>
                  <p className="fw-500 fs-14 border-bottom pb-2 mb-3">Account Details</p>
                  <div style={{ width: '27em', maxWidth: '100%' }}>
                    <div className="d-flex align-items-center mb-2">
                      <div className="w-100">
                        <span className="fs-14 fw-500">Account Bank</span>
                      </div>

                      <div className="w-100 text-right">
                        <span className="fs-13 fs-14" style={{ color: 'rgba(104, 115, 133, 1)' }}>
                          {PayoutTrfDetailsData &&
                            PayoutTrfDetailsData.bank_name !== null ?
                            PayoutTrfDetailsData.bank_name : '---'}
                        </span>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-2">
                      <div className="w-100">
                        <span className="fs-14 fw-500">Account Number</span>
                      </div>

                      <div className="w-100 text-right">
                        <span className="fs-13 fs-14" style={{ color: 'rgba(104, 115, 133, 1)' }}>
                          {PayoutTrfDetailsData &&
                            PayoutTrfDetailsData.account_number !== null ?
                            PayoutTrfDetailsData.account_number : '---'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`mt-4 pt-3`}>
                  <p className="fw-500 fs-14 border-bottom pb-2 mb-3">Last Transaction</p>
                  <div style={{ width: '27em', maxWidth: '100%' }}>
                    <div className="d-flex align-items-center mb-2">
                      <div className="w-100">
                        <span className="fs-13 fw-500">
                          {/* 24 Mar, 2024 11:54pm */}
                          {PayoutTrfDetailsData &&
                            PayoutTrfDetailsData.createdAt !== null ?
                            DateTimeFormat(PayoutTrfDetailsData.createdAt) : '---'}
                        </span>
                      </div>

                      <div className="w-100 text-sm-right">

                        <div className="fit-content ml-auto">
                          <button className="btn py-1 px-0" onClick={() => setTransactionDetailsDrawer(true)}>
                            <span style={{ color: '#2f116b' }}>Details page </span>
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.53125 5.08366L8.94792 1.66699" stroke="#5D20D2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M5.53125 5.08366L8.94792 1.66699" stroke="black" stroke-opacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M5.53125 5.08366L8.94792 1.66699" stroke="black" stroke-opacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M5.53125 5.08366L8.94792 1.66699" stroke="black" stroke-opacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M9.28125 3.33301V1.33301H7.28125" stroke="#5D20D2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M9.28125 3.33301V1.33301H7.28125" stroke="black" stroke-opacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M9.28125 3.33301V1.33301H7.28125" stroke="black" stroke-opacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M9.28125 3.33301V1.33301H7.28125" stroke="black" stroke-opacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4.69727 1.33301H3.86393C1.7806 1.33301 0.947266 2.16634 0.947266 4.24967V6.74967C0.947266 8.83301 1.7806 9.66634 3.86393 9.66634H6.36393C8.44727 9.66634 9.2806 8.83301 9.2806 6.74967V5.91634" stroke="#5D20D2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4.69727 1.33301H3.86393C1.7806 1.33301 0.947266 2.16634 0.947266 4.24967V6.74967C0.947266 8.83301 1.7806 9.66634 3.86393 9.66634H6.36393C8.44727 9.66634 9.2806 8.83301 9.2806 6.74967V5.91634" stroke="black" stroke-opacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4.69727 1.33301H3.86393C1.7806 1.33301 0.947266 2.16634 0.947266 4.24967V6.74967C0.947266 8.83301 1.7806 9.66634 3.86393 9.66634H6.36393C8.44727 9.66634 9.2806 8.83301 9.2806 6.74967V5.91634" stroke="black" stroke-opacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4.69727 1.33301H3.86393C1.7806 1.33301 0.947266 2.16634 0.947266 4.24967V6.74967C0.947266 8.83301 1.7806 9.66634 3.86393 9.66634H6.36393C8.44727 9.66634 9.2806 8.83301 9.2806 6.74967V5.91634" stroke="black" stroke-opacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </Stack>
          {/* <Box sx={{ p: 3 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              startIcon={<Iconify icon="ic:round-clear-all" />}
            >
              Clear All
            </Button>
          </Box> */}
        </Scrollbar>
      </Drawer>


      <Drawer
        anchor="right"
        open={transactionDetailsDrawer}
        onClose={handleCloseTransactionDetailsDrawer}
        PaperProps={{
          sx: { width: 350, maxWidth: '100%', border: 'none', overflow: '', marginTop: '0em', height: '100vh', },
        }}
        className={`${styles.drawerHolder}`}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, pt: 2, pb: 1 }} className="">
          <Typography variant="h6" sx={{ ml: 1 }}>
            {/* <p className="fw-500 mb-0">
                            Saved Product
                        </p> */}
          </Typography>
          <button className="btn " onClick={() => setTransactionDetailsDrawer(false)}>
            <span className="text-dark fs-14" style={{ textDecoration: 'underline', textUnderlineOffset: '3px' }}>Back</span>
            {/* <Iconify icon="eva:close-fill" /> */}
          </button>
        </Stack>

        <Scrollbar>
          <Stack spacing={2} sx={{ p: 0, }}>
            <div
              className={`modal_content`}
              style={{ minHeight: '80vh' }}
            >

              {/* <div
                className={`${styles.dropped_details_wrap} dropped_details_wrap px-4 pt-2 pb-5`}
              > */}
              <div className={`px-3 px-sm-4 pt-3 pb-5 `} ref={detailsRef}>
                <div className="">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M1.51953 1.25H10.5195C10.9337 1.25 11.2695 1.58579 11.2695 2V2.75H0.769531V2C0.769531 1.58579 1.10532 1.25 1.51953 1.25ZM0.769531 3.5H11.2695C11.6837 3.5 12.0195 3.80526 12.0195 4.18182V10.3182C12.0195 10.6947 11.6837 11 11.2695 11H0.769531C0.355318 11 0.0195312 10.6947 0.0195312 10.3182V4.18182C0.0195312 3.80526 0.355318 3.5 0.769531 3.5ZM7.89453 7.25C7.89453 8.28553 7.05507 9.125 6.01953 9.125C4.984 9.125 4.14453 8.28553 4.14453 7.25C4.14453 6.21447 4.984 5.375 6.01953 5.375C7.05507 5.375 7.89453 6.21447 7.89453 7.25Z" fill="#8792A2" />
                      </svg>
                      <small className="pl-2 font-weight-normal fs-11" style={{ color: 'rgba(105, 115, 134, 1)' }}>Transfer</small>
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <h4 className="d-flex">
                    <span className="fw-700 preview_amount" style={{ fontSize: '24px' }}>
                      {PayoutTrfDetailsData?.transactions[0]?.currency === 'NGN' ? '₦' :
                        PayoutTrfDetailsData?.transactions[0]?.currency === 'CNY' ? '¥' : ''}{Number(PayoutTrfDetailsData?.transactions[0]?.amount).toLocaleString()}.00
                    </span>
                    <span className="ml-3 ml-auto">
                      <div className={`${PayoutTrfDetailsData?.transactions[0]?.status} fw-500 fs-12 badge_style chargebacks fit-content text-uppercase pl-4 py-2 position-relative`}>
                        <span
                          className="position-absolute dot"
                          style={{ top: '12px', left: '10px', width: '7px', height: '7px', borderRadius: '50%', background: '#FBBF24' }}
                        ></span>
                        {PayoutTrfDetailsData?.transactions[0]?.status}
                      </div>
                    </span>
                  </h4>
                </div>

                <div className="mt-4 d-flex justify-content-between align-items-center">
                  <div className="w-100">
                    <small className="d-block mb-0 font-weight-normal fs-13">Date</small>
                    <span className="fs-13">

                      {DateTimeFormat(PayoutTrfDetailsData?.transactions[0]?.createdAt) ?? '---'}
                    </span>
                  </div>
                  <div className="text-right w-100 border-left">
                    <small className="d-block mb-1 font-weight-normal fs-13">Transaction ID</small>
                    <div className="d-flex fit-content ml-auto cursor-pointer">

                      <small className="fw-500 fs-13 d-block ml-auto" style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        wordBreak: 'keep-all',
                        width: '9em',
                      }}
                        onClick={() => copyToClipboard(PayoutTrfDetailsData?.transactions[0]?.transaction_reference)}
                      >
                        {PayoutTrfDetailsData?.transactions[0]?.transaction_reference}
                      </small>
                      <span className="ml-1" onClick={() => copyToClipboard(PayoutTrfDetailsData?.transactions[0]?.transaction_reference)}>
                        <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.88777 8.3125V10.9375C8.88777 13.125 8.16813 14 6.36902 14H4.2101C2.41099 14 1.69135 13.125 1.69135 10.9375V8.3125C1.69135 6.125 2.41099 5.25 4.2101 5.25H6.36902C8.16813 5.25 8.88777 6.125 8.88777 8.3125Z" stroke="#5D20D2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M8.88777 8.3125V10.9375C8.88777 13.125 8.16813 14 6.36902 14H4.2101C2.41099 14 1.69135 13.125 1.69135 10.9375V8.3125C1.69135 6.125 2.41099 5.25 4.2101 5.25H6.36902C8.16813 5.25 8.88777 6.125 8.88777 8.3125Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M8.88777 8.3125V10.9375C8.88777 13.125 8.16813 14 6.36902 14H4.2101C2.41099 14 1.69135 13.125 1.69135 10.9375V8.3125C1.69135 6.125 2.41099 5.25 4.2101 5.25H6.36902C8.16813 5.25 8.88777 6.125 8.88777 8.3125Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M8.88777 8.3125V10.9375C8.88777 13.125 8.16813 14 6.36902 14H4.2101C2.41099 14 1.69135 13.125 1.69135 10.9375V8.3125C1.69135 6.125 2.41099 5.25 4.2101 5.25H6.36902C8.16813 5.25 8.88777 6.125 8.88777 8.3125Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M11.9719 4.5625V7.1875C11.9719 9.375 11.2523 10.25 9.45319 10.25H8.88776V8.3125C8.88776 6.125 8.16812 5.25 6.36901 5.25H4.77551V4.5625C4.77551 2.375 5.49516 1.5 7.29426 1.5H9.45319C11.2523 1.5 11.9719 2.375 11.9719 4.5625Z" stroke="#5D20D2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M11.9719 4.5625V7.1875C11.9719 9.375 11.2523 10.25 9.45319 10.25H8.88776V8.3125C8.88776 6.125 8.16812 5.25 6.36901 5.25H4.77551V4.5625C4.77551 2.375 5.49516 1.5 7.29426 1.5H9.45319C11.2523 1.5 11.9719 2.375 11.9719 4.5625Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M11.9719 4.5625V7.1875C11.9719 9.375 11.2523 10.25 9.45319 10.25H8.88776V8.3125C8.88776 6.125 8.16812 5.25 6.36901 5.25H4.77551V4.5625C4.77551 2.375 5.49516 1.5 7.29426 1.5H9.45319C11.2523 1.5 11.9719 2.375 11.9719 4.5625Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M11.9719 4.5625V7.1875C11.9719 9.375 11.2523 10.25 9.45319 10.25H8.88776V8.3125C8.88776 6.125 8.16812 5.25 6.36901 5.25H4.77551V4.5625C4.77551 2.375 5.49516 1.5 7.29426 1.5H9.45319C11.2523 1.5 11.9719 2.375 11.9719 4.5625Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>

                <div className={`${styles.dropdown_payInfo} mt-4 pt-2`}>
                  <p className="fw-500 fs-14">Payment Information</p>
                  <div className="d-flex align-items-center mb-1">
                    <div className="w-75">
                      <span className="fs-14">Amount</span>
                    </div>

                    <div className="w-100 text-right">
                      <span className="fs-14">
                        {PayoutTrfDetailsData?.transactions[0]?.currency === 'NGN' ? '₦' :
                          PayoutTrfDetailsData?.transactions[0]?.currency === 'CNY' ? '¥' : ''}{Number(PayoutTrfDetailsData?.transactions[0]?.amount).toLocaleString()}.00
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <div className="w-75">
                      <span className="fs-14">Recipient</span>
                    </div>

                    <div className="w-100 text-right">
                      <span className="fs-14 text-capitalize">
                        {PayoutTrfDetailsData?.transactions[0]?.account_name.toLowerCase()}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <div className="w-75">
                      <span className="fs-14">Payment Type</span>
                    </div>

                    <div className="w-100 text-right">
                      <span className="fs-14 text-capitalize">
                        {PayoutTrfDetailsData?.transactions[0]?.transaction_type.toLowerCase()}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <div className="w-75">
                      <span className="fs-14">Bank Name</span>
                    </div>

                    <div className="w-100 text-right">
                      <span className="fs-14">
                        {PayoutTrfDetailsData?.transactions[0]?.bank_name}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <div className="w-75">
                      <span className="fs-14">Account Number</span>
                    </div>

                    <div className="w-100 text-right">
                      <span className="fs-14">
                        {PayoutTrfDetailsData?.transactions[0]?.account_number}
                      </span>
                    </div>
                  </div>
                  {/* <div className="d-flex align-items-center mb-1">
                    <div className="w-75">
                      <span className="fs-14">Routing ID</span>
                    </div>

                    <div className="w-100 text-right">
                      <span className="fs-14">-</span>
                    </div>
                  </div> */}
                  <div className="d-sm-flex align-items-center mb-2">
                    <div className="w-75">
                      <span className="fs-14">Description</span>
                    </div>

                    <div className="w-100 text-sm-right">
                      <span className="fs-14 d-block" style={{ lineHeight: '1.5' }}>
                        {PayoutTrfDetailsData?.transactions[0]?.description}
                      </span>
                    </div>
                  </div>
                </div>

                {/* <div className="pt-5 mt-3">
                  <button type="button" className="btn border d-block w-100 py-2 d-flex justify-content-center align-items-center">
                    <span><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.375 11.125V13C2.375 13.6904 2.93464 14.25 3.625 14.25H12.375C13.0654 14.25 13.625 13.6904 13.625 13V11.125" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.5 8L8 10.5L10.5 8" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M8 1.75V10.5" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    </span>
                    <span className="ml-2 py-1">Get PDF Receipt</span>
                  </button>
                </div> */}
              </div>
            </div>
          </Stack>
          <Box sx={{ p: 3 }}>
            <div className={`px-3 px-sm-4 pb-4 w-100`}>
              <Button
                fullWidth
                size="large"
                type="submit"
                color="inherit"
                variant="outlined"
                onClick={exportReceiptToPDF}
              // startIcon={<Iconify icon="ic:round-clear-all" />}
              >
                <span><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.375 11.125V13C2.375 13.6904 2.93464 14.25 3.625 14.25H12.375C13.0654 14.25 13.625 13.6904 13.625 13V11.125" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5.5 8L8 10.5L10.5 8" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8 1.75V10.5" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                </span>
                <span className="ml-2 py-1 fw-500">Get PDF Receipt</span>
              </Button>
            </div>
          </Box>
        </Scrollbar>

      </Drawer>


      <GlobalModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={ConfirmDeleteDialog}
        onClose={() => { setConfirmDeleteDialog(false); setCustomerID('') }}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="400px !important"
        maxWidth="100%"
        overflowY="scroll"
        bgcolor="#FFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <span
          className=""
          style={{
            fontSize: "25px",
            cursor: "pointer",
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          <IconButton onClick={() => { setConfirmDeleteDialog(false); setCustomerID('') }}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </span>
        <div
          className={`modal_content`}
          style={{ overflowY: 'scroll', height: 'auto', }}
        >
          <div className="d-flex flex-column justify-content-start align-items-center">
            <Box className="mt-3 position-relative text-center"
              sx={{ width: '100%', maxWidth: '250px', }}>
              <div className="fit-content mb-4 mx-auto cursor-pointer">
                <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.843506" width="75.1601" height="75.1601" rx="37.5801" fill="#111827" />
                  <path d="M30.0225 28.6163L48.8297 48.2348" stroke="#F9FAFB" stroke-width="3.39714" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M29.6168 47.8294L49.2354 29.0222" stroke="#F9FAFB" stroke-width="3.39714" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>

              <p className='fs-20 fw-500 text-dark mb-0'>Confirm</p>
              <p className="mb-0 pt-2">
                <span className='fs-14'>Are you sure you want to delete this customer?</span>
              </p>
            </Box>

            <div className="mt-4 fit-content mx-auto">
              <button
                className="btn border btn-primary d-block"
                onClick={handleConfirmDelete}
                style={{ padding: '8px 20px' }}
              >
                <span className="text-white" style={{ whiteSpace: 'nowrap' }}>Delete
                  {
                    IsItemDeleting &&
                    <div className="fit-content d-inline">
                      <CircularProgress
                        style={{ stroke: "#fff" }}
                        className="text-white ml-1 position-relative"
                        size="0.8rem"
                      />
                    </div>
                  }</span>
              </button>
            </div>
          </div>
        </div>
      </GlobalModal>
    </>
  );
}
