import {
    TextField,
    Box
} from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { orgTabCategory, orgTabBanks } from "src/hooks/useMockData";
import LiveSelect from "react-select";
import axios from "axios"
import axiosInstance from "src/axiosInstance"
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import bus from "src/bus"
import CircularProgress from "@mui/material/CircularProgress";

const BusinessRepresentative = ({ setIsLoading, setResponseStatus, KYCResponseObj }) => {
    const apiUrl = process.env.REACT_APP_NAME;
    const formRef = useRef(null);
    const accountID = JSON.parse(localStorage.getItem('auth'))?.account_id;

    const [selectedImage, setSelectedImage] = useState(null);
    const [FullName, setFullName] = useState("");
    const [Email, setEmail] = useState("");
    const [Phone, setPhone] = useState("");
    const [JobDesc, setJobDesc] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const validTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'application/pdf'];
    const [IsUploading, setIsUploading] = useState(null);


    useEffect(() => {
        if (KYCResponseObj && KYCResponseObj.data && KYCResponseObj.account) {
            setFullName(KYCResponseObj?.data?.name);
            setEmail(KYCResponseObj?.data?.email);
            setPhone(KYCResponseObj?.data?.phone);
            setJobDesc(KYCResponseObj?.data?.job_description);
            setSelectedImage(KYCResponseObj?.data?.proof_of_identity);
        }
    }, [])

    useEffect(() => {
        const handleSubmitVerifyYourBiz = (val) => {
            if (val) {
                if (formRef.current) {
                    formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                }
            }
        };

        bus.on("submit_bizRep", handleSubmitVerifyYourBiz);

        return () => {
            bus.off("submit_bizRep", handleSubmitVerifyYourBiz);
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (FullName && Email && Phone && JobDesc) {
            if (!selectedImage) {
                notifyError('Kindly upload your proof of identity');
                return;
            }

            let payload = {
                name: FullName,
                email: Email,
                phone: Phone,
                proof_of_identity: selectedImage,
                job_description: JobDesc
            }

            setIsLoading(true)

            try {
                const response = await axiosInstance.post(`/accounts/user/verify/personal-details?productName=${apiUrl}`, payload)

                const data = response.data;
                if (data.status === 'success') {
                    setResponseStatus('success');

                    notifySuccess(response.data.message)
                }
            } catch (err) {
                setResponseStatus('error');
                if (err.response) {
                    if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                        notifyError(err.response.data.data.errorMessage);
                    } else if (err.response.data && err.response.data.message) {
                        notifyError(err.response.data.message);
                    } else {
                        notifyError('An unexpected error occurred');
                    }
                } else {
                    notifyError('Network error or server not responding');
                }
            } finally {
                setIsLoading(false);
            }

        } else {
            notifyError('Kindly fill all fields to proceed!')
        }

    };

    const uploadToCloudinary = async (file) => {
        setIsUploading(true)
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'm9xeajl4');

        try {
            const response = await axios.post('https://api.cloudinary.com/v1_1/dymxghqum/image/upload', formData); // Replace with your Cloudinary cloud name
            return response.data.secure_url;
        } catch (error) {
            console.error('Error uploading to Cloudinary:', error);
            return null;
        } finally {
            setIsUploading(false)
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (file && validTypes.includes(file.type)) {
            if (file.size > 2000 * 1024) { // 2MB in bytes
                setErrorMessage("File size should not exceed 2MB");
                notifyError('File size should not exceed 2MB')

                setTimeout(() => {
                    setErrorMessage("");
                }, 3000);
                return;
            }

            const uploadedUrl = await uploadToCloudinary(file);

            setSelectedImage(uploadedUrl);
            setSelectedFile(file);
            setErrorMessage("");
        } else {
            setSelectedFile(null);
            setSelectedImage(null);
            setErrorMessage("Please select a png, jpg, jpeg, webp or pdf file only.");
            setTimeout(() => {
                setErrorMessage("");
            }, 3000);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = async (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];

        if (file && validTypes.includes(file.type)) {
            if (file.size > 2000 * 1024) { // 2MB in bytes
                setErrorMessage("File size should not exceed 2MB");
                notifyError('File size should not exceed 2MB')

                setTimeout(() => {
                    setErrorMessage("");
                }, 3000);
                return;
            }

            const uploadedUrl = await uploadToCloudinary(file);

            setSelectedImage(uploadedUrl);
            setSelectedFile(file);
            setErrorMessage("");
        } else {
            setSelectedFile(null);
            setSelectedImage(null);
            setErrorMessage("Please select a png, jpg, jpeg, webp or pdf file only.");
            setTimeout(() => {
                setErrorMessage("");
            }, 3000);
        }
    };

    const handleRemoveFile = () => {
        setSelectedImage(null);
        setSelectedFile(null);
        setErrorMessage("");
    }

    return (
        <>
            <Box component="form" noValidate autoComplete="off" className='mt-2 px-1 position-relative' onSubmit={handleSubmit} ref={formRef}>
                {IsUploading &&
                    <div className='border d-flex justify-content-center align-items-center'
                        style={{ position: 'sticky', top: '10px', width: '35px', height: '35px', borderRadius: '50%', background: 'white', left: '50%', transform: 'translateX(-50%)', zIndex: '10' }}>
                        <CircularProgress
                            style={{ stroke: "#000", strokeWidth: '4px' }}
                            className="position-relative"
                            size="1rem"
                            sx={{ strokeWidth: '4px' }}
                        />
                    </div>
                }
                <div className="mb-3 w-100 height_40 position-relative">
                    <label htmlFor='full_name' className="mb-2 fs-13 fw-500">Full Name</label>
                    <div className="">
                        <TextField
                            name="full_name"
                            required
                            type="text"
                            id="full_name"
                            size="small"
                            placeholder=""
                            className="rounded-0"
                            fullWidth
                            value={FullName}
                            onChange={(e) => setFullName(e.target.value)}
                        />
                    </div>
                </div>

                <div className="mb-3 w-100 height_40 disabled_bg pwIcon">
                    <label htmlFor='email_addr' className="mb-2 fs-13 fw-500">Email Address</label>
                    <div className="">
                        <TextField
                            name="email_addr"
                            required
                            type="email"
                            id="email_addr"
                            size="small"
                            placeholder=""
                            className="rounded-0"
                            fullWidth
                            value={Email}
                            disabled={KYCResponseObj?.data?.email !== null}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                </div>

                <div className="mb-3 w-100 height_40">
                    <label htmlFor='phone' className="mb-2 fs-13 fw-500">Phone Number</label>
                    <div className="">
                        <TextField
                            name="phone"
                            required
                            type="number"
                            id="phone"
                            size="small"
                            placeholder=""
                            className="rounded-0"
                            fullWidth
                            value={Phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                </div>

                <div className="mb-3 w-100 height_40">
                    <label htmlFor='job_desc' className="mb-2 fs-13 fw-500">Job Description</label>
                    <div className="">
                        <TextField
                            name="job_desc"
                            required
                            type="text"
                            id="job_desc"
                            size="small"
                            placeholder=""
                            className="rounded-0"
                            fullWidth
                            value={JobDesc}
                            onChange={(e) => setJobDesc(e.target.value)}
                        />
                    </div>
                </div>

                <div className="mb-3 w-100 height_40">

                    <div className="d-flex align-items-start">
                    <label htmlFor='proof' className="mb-2 fs-13 fw-500">Proof of Identity*</label>

                        <span className='proof_popup d-block fit-content btn px-2 pt-0 pb-1 ml-1'>
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="5.5" cy="5.5" r="5.5" fill="#6B7280" />
                                <path d="M5.3125 7.03587V6.98864C5.31581 6.6804 5.34647 6.43513 5.40447 6.25284C5.46413 6.07055 5.54865 5.92306 5.65803 5.81037C5.7674 5.69768 5.89915 5.59493 6.05327 5.50213C6.1527 5.43916 6.24219 5.36873 6.32173 5.29084C6.40128 5.21295 6.46425 5.12346 6.51065 5.02237C6.55706 4.92128 6.58026 4.80942 6.58026 4.68679C6.58026 4.5393 6.54545 4.4117 6.47585 4.30398C6.40625 4.19626 6.31345 4.1134 6.19744 4.0554C6.0831 3.99574 5.95549 3.96591 5.81463 3.96591C5.68703 3.96591 5.56522 3.99242 5.44922 4.04545C5.33321 4.09848 5.2371 4.18134 5.16087 4.29403C5.08464 4.40507 5.04072 4.54841 5.02912 4.72408H4.27344C4.28504 4.42578 4.36044 4.17389 4.49964 3.96839C4.63885 3.76125 4.8228 3.60464 5.05149 3.49858C5.28184 3.39252 5.53622 3.33949 5.81463 3.33949C6.11956 3.33949 6.38636 3.39666 6.61506 3.51101C6.84375 3.6237 7.02107 3.78196 7.14702 3.9858C7.27462 4.18797 7.33842 4.42412 7.33842 4.69425C7.33842 4.87985 7.30942 5.04723 7.25142 5.19638C7.19342 5.34387 7.11056 5.47562 7.00284 5.59162C6.89678 5.70762 6.76918 5.81037 6.62003 5.89986C6.47917 5.98769 6.36482 6.07884 6.27699 6.1733C6.19081 6.26776 6.12784 6.37962 6.08807 6.50888C6.0483 6.63814 6.02675 6.79806 6.02344 6.98864V7.03587H5.3125ZM5.68786 8.54723C5.55197 8.54723 5.43513 8.49917 5.33736 8.40305C5.23958 8.30528 5.1907 8.18762 5.1907 8.05007C5.1907 7.91418 5.23958 7.79818 5.33736 7.70206C5.43513 7.60428 5.55197 7.5554 5.68786 7.5554C5.82209 7.5554 5.93809 7.60428 6.03587 7.70206C6.1353 7.79818 6.18501 7.91418 6.18501 8.05007C6.18501 8.14122 6.16181 8.22491 6.11541 8.30114C6.07067 8.37571 6.01101 8.43537 5.93643 8.48011C5.86186 8.52486 5.779 8.54723 5.68786 8.54723Z" fill="white" />
                            </svg>
                            <div className="position-absolute fit-content docs_supported_popup">
                                <img src="/assets/docs_supported_popup02.png" alt="" />
                            </div>
                        </span>
                    </div>

                    <span className="d-block d-flex align-items-start fs-16 mb-3">
                        <i className='bi bi-dot'></i>
                        <span className='fw-400 fs-12'>
                            Document supported : National ID card, Drivers License or Voter’s Card
                        </span>
                    </span>
                    <div
                        className="drop_zone"
                        style={{ minHeight: '10em' }}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                    >
                        {selectedImage &&
                            <div className="change_csv_container pdf_docs d-flex" style={{zIndex: '99'}}>
                                
                                <div
                                    className='btn bg-transparent px-2 py-2'
                                    onClick={handleRemoveFile}>
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="22" height="22" rx="5" fill="#E5E7EB" fill-opacity="0.7" />
                                        <path d="M14.7797 8.17921C14.1088 8.11254 13.438 8.06254 12.763 8.02504V8.02087L12.6713 7.47921C12.6088 7.09587 12.5172 6.52087 11.5422 6.52087H10.4505C9.47968 6.52087 9.38801 7.07087 9.32135 7.47504L9.23385 8.00837C8.84635 8.03337 8.45885 8.05837 8.07135 8.09587L7.22135 8.17921C7.04635 8.19587 6.92135 8.35004 6.93801 8.52087C6.95468 8.69171 7.10468 8.81671 7.27968 8.80004L8.12968 8.71671C10.313 8.50004 12.513 8.58337 14.7213 8.80421C14.7338 8.80421 14.7422 8.80421 14.7547 8.80421C14.913 8.80421 15.0505 8.68337 15.0672 8.52087C15.0797 8.35004 14.9547 8.19587 14.7797 8.17921Z" fill="#292D32" />
                                        <path d="M14.0122 9.39163C13.9122 9.28746 13.7747 9.22913 13.6331 9.22913H8.3664C8.22473 9.22913 8.08307 9.28746 7.98723 9.39163C7.8914 9.49579 7.83723 9.63746 7.84557 9.78329L8.1039 14.0583C8.14973 14.6916 8.20807 15.4833 9.66223 15.4833H12.3372C13.7914 15.4833 13.8497 14.6958 13.8956 14.0583L14.1539 9.78746C14.1622 9.63746 14.1081 9.49579 14.0122 9.39163ZM11.6914 13.3958H10.3039C10.1331 13.3958 9.9914 13.2541 9.9914 13.0833C9.9914 12.9125 10.1331 12.7708 10.3039 12.7708H11.6914C11.8622 12.7708 12.0039 12.9125 12.0039 13.0833C12.0039 13.2541 11.8622 13.3958 11.6914 13.3958ZM12.0414 11.7291H9.95807C9.78723 11.7291 9.64557 11.5875 9.64557 11.4166C9.64557 11.2458 9.78723 11.1041 9.95807 11.1041H12.0414C12.2122 11.1041 12.3539 11.2458 12.3539 11.4166C12.3539 11.5875 12.2122 11.7291 12.0414 11.7291Z" fill="#292D32" />
                                    </svg>
                                </div>
                                {/* <button className="btn d-block border m-0 text-danger" onClick={handleReset}><i className="bi bi-arrow-repeats"></i>Delete</button> */}
                            </div>
                        }
                        {!selectedImage &&
                            <input
                                type="file"
                                onChange={handleFileChange}
                                onDrop={handleDrop}
                                accept=".png, .jpg, .jpeg, .webp, .pdf"
                            />
                        }

                        {!selectedImage && (
                            <div>
                                <div className="mx-auto" style={{ width: "fit-content" }}>
                                    <svg
                                        width="46"
                                        height="46"
                                        viewBox="0 0 46 46"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            x="3"
                                            y="3"
                                            width="40"
                                            height="40"
                                            rx="20"
                                            fill="#F2F4F7"
                                        />
                                        <rect
                                            x="3"
                                            y="3"
                                            width="40"
                                            height="40"
                                            rx="20"
                                            stroke="#F9FAFB"
                                            strokeWidth="6"
                                        />
                                        <g clipPath="url(#clip0_1729_11206)">
                                            <path
                                                d="M26.3333 26.3332L23 22.9999M23 22.9999L19.6666 26.3332M23 22.9999V30.4999M29.9916 28.3249C30.8044 27.8818 31.4465 27.1806 31.8165 26.3321C32.1866 25.4835 32.2635 24.5359 32.0351 23.6388C31.8068 22.7417 31.2862 21.9462 30.5555 21.3778C29.8248 20.8094 28.9257 20.5005 28 20.4999H26.95C26.6977 19.5243 26.2276 18.6185 25.5749 17.8507C24.9222 17.0829 24.104 16.4731 23.1817 16.0671C22.2594 15.661 21.2571 15.4694 20.2501 15.5065C19.243 15.5436 18.2575 15.8085 17.3676 16.2813C16.4777 16.7541 15.7066 17.4225 15.1122 18.2362C14.5177 19.05 14.1155 19.9879 13.9358 20.9794C13.756 21.9709 13.8034 22.9903 14.0743 23.961C14.3452 24.9316 14.8327 25.8281 15.5 26.5832"
                                                stroke="#475467"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1729_11206">
                                                <rect
                                                    width="20"
                                                    height="20"
                                                    fill="white"
                                                    transform="translate(13 13)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <p className="mb-1">
                                    <span className="text-primary">Click to upload</span> or
                                    drag and drop
                                </p>
                                <p className="mb-1 fs-11">PNG, JPG, JPEG, WEBP or PDF file only</p>
                                {errorMessage && <p className="text-danger mb-0 fs-11">{errorMessage}</p>}
                            </div>
                        )}
                        {selectedImage &&
                            <>
                                <div className="file_preview"
                                    onChange={handleFileChange}
                                >
                                    <div className=" w-100">
                                        {selectedImage.startsWith("data:application/pdf") || selectedImage.endsWith(".pdf") ? (
                                            <embed src={selectedImage} type="application/pdf" width="100%"
                                                style={{ overflow: 'auto' }} />
                                        ) : (
                                            <img src={selectedImage} alt="Selected" />
                                        )}
                                    </div>
                                </div>
                                {/* <img src={selectedFile} alt="Selected" /> */}
                            </>
                        }
                    </div>

                    {/* <div
                        className="drop_zone"
                        style={{ minHeight: '8em' }}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                    >
                        <input
                            id='proof'
                            type="file"
                            accept=".png, .jpg, .jpeg, .webp, .pdf"
                            multiple
                            onChange={handleFileChange}
                        />
                        {!selectedImage && (
                            <div>
                                <div className="mx-auto" style={{ width: "fit-content" }}>
                                    <svg
                                        width="46"
                                        height="46"
                                        viewBox="0 0 46 46"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            x="3"
                                            y="3"
                                            width="40"
                                            height="40"
                                            rx="20"
                                            fill="#F2F4F7"
                                        />
                                        <rect
                                            x="3"
                                            y="3"
                                            width="40"
                                            height="40"
                                            rx="20"
                                            stroke="#F9FAFB"
                                            strokeWidth="6"
                                        />
                                        <g clipPath="url(#clip0_1729_11206)">
                                            <path
                                                d="M26.3333 26.3332L23 22.9999M23 22.9999L19.6666 26.3332M23 22.9999V30.4999M29.9916 28.3249C30.8044 27.8818 31.4465 27.1806 31.8165 26.3321C32.1866 25.4835 32.2635 24.5359 32.0351 23.6388C31.8068 22.7417 31.2862 21.9462 30.5555 21.3778C29.8248 20.8094 28.9257 20.5005 28 20.4999H26.95C26.6977 19.5243 26.2276 18.6185 25.5749 17.8507C24.9222 17.0829 24.104 16.4731 23.1817 16.0671C22.2594 15.661 21.2571 15.4694 20.2501 15.5065C19.243 15.5436 18.2575 15.8085 17.3676 16.2813C16.4777 16.7541 15.7066 17.4225 15.1122 18.2362C14.5177 19.05 14.1155 19.9879 13.9358 20.9794C13.756 21.9709 13.8034 22.9903 14.0743 23.961C14.3452 24.9316 14.8327 25.8281 15.5 26.5832"
                                                stroke="#475467"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1729_11206">
                                                <rect
                                                    width="20"
                                                    height="20"
                                                    fill="white"
                                                    transform="translate(13 13)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <p className="mb-1">
                                    <span className="text-primary">Click to upload</span> or
                                    drag and drop
                                </p>
                            </div>
                        )}
                        {selectedImage && 
                            <>
                                <div className="file_preview"
                                    onChange={(event) => handleFileChange(event, "memorandum")}
                                >
                                    {selectedImage.startsWith("data:application/pdf") ? (
                                        <embed src={selectedImage} type="application/pdf" width="100%" height="120px"
                                            style={{ position: 'absolute', zIndex: '999', overflow: 'auto' }} />
                                    ) : (
                                        <img src={selectedImage} alt="Selected" />
                                    )}
                                </div>
                        <img src={selectedImage} alt="Selected" />
                            </>
                        }
                        {errorMessage && <p className="text-danger mb-0 fs-11">{errorMessage}</p>}

                        <p className="mb-0">{selectedImage ? "1 file selected" : "No file selected"}</p>
                    </div> */}
                </div>
            </Box>
        </>
    );
}

export default BusinessRepresentative