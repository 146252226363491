import React, { useEffect, useRef } from 'react';

const TopCountries = () => {
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const container = scrollContainerRef.current;
      const items = container.querySelectorAll('.scroll-item');

      items.forEach((item) => {
        const rect = item.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        const itemCenter = (rect.top + rect.bottom) / 2;
        const containerCenter = (containerRect.top + containerRect.bottom) / 2;
        const distanceFromCenter = Math.abs(itemCenter - containerCenter);
        const maxDistance = containerRect.height / 2.0; // Adjust this value as needed

        const opacity = Math.max(0.3, 1 - distanceFromCenter / maxDistance);
        const scale = Math.max(0.8, 1 - (distanceFromCenter / maxDistance) * 0.2);

        item.style.opacity = opacity;
        item.style.transform = `scale(${scale})`;

        if (distanceFromCenter < maxDistance / 2) {
          item.classList.add('active');
        } else {
          item.classList.remove('active');
        }
      });
    };

    const container = scrollContainerRef.current;
    container.addEventListener('scroll', handleScroll);

    handleScroll(); // Initial check

    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  const countries = [
    { rank: 1, country: 'NIGERIA', transaction: '300,000' },
    { rank: 2, country: 'GHANA', transaction: '300,000' },
    { rank: 3, country: 'CHINA', transaction: '300,000' },
    { rank: 4, country: 'USA', transaction: '300,000' },
    { rank: 5, country: 'UK', transaction: '300,000' },
    // Add more countries as needed
  ];

  return (
    <div className="scroll-container" ref={scrollContainerRef} style={{ height: '300px', overflowY: 'scroll' }}>
      {countries.map((item, index) => (
        <div key={index} className="scroll-item d-flex align-items-center">
          <span id="rank">#{item.rank}</span>
          <div>
            <span id="country">{item.country}</span>
            <span id="transaction" className='fs-12 fw-400'>Total transaction : {item.transaction}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TopCountries;
