// ----------------------------------------------------------------------
import styles from "../Messaging.module.css";
// import {
//     // useEffect,
//     // useState,
// } from "react";
import "react-datepicker/dist/react-datepicker.css";

import { Link as RouterLink } from "react-router-dom";

import {
    Link,
    //   Avatar,
} from "@mui/material";
import Scrollbar from "../../../components/scrollbar";
// sections
// mock
// ----------------------------------------------------------------------


export default function SendMessageTable() {

    const tableData = [
        {
            sender_id: 'LaunchPad',
            receiver: '070xxxxxxx',
            message: 'Rhoncus morbi et augue nec......',
            channel: 'SMS',
            date: 'May 31, 2020, 11:58 AM',
        },
        {
            sender_id: 'LaunchPad',
            receiver: 'Micheal@gma.com',
            message: 'Rhoncus morbi et augue nec......',
            channel: 'Email',
            date: 'May 31, 2020, 11:58 AM',
        },
        {
            sender_id: 'LaunchPad',
            receiver: 'Bulk (21 recipient)',
            message: 'Rhoncus morbi et augue nec......',
            channel: 'Whatsapp',
            date: 'May 31, 2020, 11:58 AM',
        },
        {
            sender_id: 'LaunchPad',
            receiver: '070xxxxxxx',
            message: 'Rhoncus morbi et augue nec......',
            channel: 'Whatsapp',
            date: 'May 31, 2020, 11:58 AM',
        },
        {
            sender_id: 'LaunchPad',
            receiver: 'Bulk (21 recipient)',
            message: 'Rhoncus morbi et augue nec......',
            channel: 'SMS',
            date: 'May 31, 2020, 11:58 AM',
        },
        {
            sender_id: 'LaunchPad',
            receiver: '070xxxxxxx',
            message: 'Rhoncus morbi et augue nec......',
            channel: 'Whatsapp',
            date: 'May 31, 2020, 11:58 AM',
        },
        {
            sender_id: 'LaunchPad',
            receiver: 'Bulk (21 recipient)',
            message: 'Rhoncus morbi et augue nec......',
            channel: 'SMS',
            date: 'May 31, 2020, 11:58 AM',
        },
        {
            sender_id: 'LaunchPad',
            receiver: 'Bulk (21 recipient)',
            message: 'Rhoncus morbi et augue nec......',
            channel: 'SMS',
            date: 'May 31, 2020, 11:58 AM',
        },
        {
            sender_id: 'LaunchPad',
            receiver: '070xxxxxxx',
            message: 'Rhoncus morbi et augue nec......',
            channel: 'Whatsapp',
            date: 'May 31, 2020, 11:58 AM',
        },
        {
            sender_id: 'LaunchPad',
            receiver: '070xxxxxxx',
            message: 'Rhoncus morbi et augue nec......',
            channel: 'SMS',
            date: 'May 31, 2020, 11:58 AM',
        },
    ];

    return (
        <>
            <div className="px-0 px-md-2">

                <div style={{ marginTop: "0px", borderRadius: "10px" }} className="p-0">
                    <Scrollbar>
                        <div
                            className={`${styles.overflow_table} table-responsive pt- pb-3 pl-0 pr-0`}
                        >
                            <table
                                className={`${styles.table} table-hover table-striped`}
                                style={{ minWidth: "50em" }}
                            >
                                <thead className={`${styles.thead}`}>
                                    <tr>
                                        <th className="text-muted" style={{ width: '15%' }}>Sender ID</th>
                                        <th className="text-muted">Receiver</th>
                                        <th className="text-muted">Message</th>
                                        <th className="text-muted">Channel</th>
                                        <th className="text-muted">Date</th>
                                        <th className="text-muted">Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {tableData.map((item, index) => (
                                        <tr key={index} className={`${styles.tr} bg-white`}>
                                            <td>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div className=" ">
                                                        <span style={{ fontSize: "14px" }}>
                                                            {item.sender_id}
                                                        </span>
                                                        {/* <span className="ml-2" style={{ cursor: 'pointer' }}>
                                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10 8.0625V10.6875C10 12.875 9.125 13.75 6.9375 13.75H4.3125C2.125 13.75 1.25 12.875 1.25 10.6875V8.0625C1.25 5.875 2.125 5 4.3125 5H6.9375C9.125 5 10 5.875 10 8.0625Z" stroke="#6B7280" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M13.75 4.3125V6.9375C13.75 9.125 12.875 10 10.6875 10H10V8.0625C10 5.875 9.125 5 6.9375 5H5V4.3125C5 2.125 5.875 1.25 8.0625 1.25H10.6875C12.875 1.25 13.75 2.125 13.75 4.3125Z" stroke="#6B7280" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </span> */}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="fw-400">
                                                    {item.receiver}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="fw-400">
                                                    {item.message}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="fw-400">
                                                    {item.channel}
                                                </span>
                                            </td>
                                            <td>
                                                <span>{item.date}</span>
                                            </td>
                                            <td>
                                                <div className="btn-group">
                                                    <span
                                                        className="p-2"
                                                        style={{ cursor: "pointer" }}
                                                        data-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <svg
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M7.60162 8.78101L10.6809 5.4812L11.5607 6.42401L7.60162 10.6667L3.64258 6.42401L4.52237 5.4812L7.60162 8.78101Z"
                                                                fill="#4B5563"
                                                            />
                                                        </svg>
                                                    </span>
                                                    <div className="dropdown-menu dropdown-menu-right drp_dwn_menu_right second">
                                                        <div
                                                            className={`${styles.dropped_details_wrap} dropped_details_wrap px-4 pt-4 pb-5`}
                                                        >

                                                            <div className={`${styles.dropdown_payInfo} py-4`}>
                                                                <h6 style={{ fontWeight: '500' }}>Messaging Information</h6>
                                                                <div className="d-flex">
                                                                    <div className="w-75">
                                                                        <span>Sender ID</span>
                                                                    </div>

                                                                    <div className="w-100">
                                                                        <span>{item.sender_id}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <div className="w-75">
                                                                        <span>Type</span>
                                                                    </div>

                                                                    <div className="w-100">
                                                                        <span>Single</span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <div className="w-75">
                                                                        <span>Channel</span>
                                                                    </div>

                                                                    <div className="w-100">
                                                                        <span>{item.channel}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <div className="w-75">
                                                                        <span>Recipient</span>
                                                                    </div>

                                                                    <div className="w-100">
                                                                        <span>{item.receiver}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <div className="w-75">
                                                                        <span>Date</span>
                                                                    </div>

                                                                    <div className="w-100">
                                                                        <span>{item.date}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <div className="w-75">
                                                                        <span>Fee</span>
                                                                    </div>

                                                                    <div className="w-100">
                                                                        <span>$0.59 <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.75 6C6.75 5.58579 6.41421 5.25 6 5.25H4.125C3.71079 5.25 3.375 5.58579 3.375 6C3.375 6.41421 3.71079 6.75 4.125 6.75H5.25V9.75C5.25 10.1642 5.58579 10.5 6 10.5C6.41421 10.5 6.75 10.1642 6.75 9.75V6ZM3 0H9C10.6569 0 12 1.34315 12 3V9C12 10.6569 10.6569 12 9 12H3C1.34315 12 0 10.6569 0 9V3C0 1.34315 1.34315 0 3 0ZM6 4.125C6.62132 4.125 7.125 3.62132 7.125 3C7.125 2.37868 6.62132 1.875 6 1.875C5.37868 1.875 4.875 2.37868 4.875 3C4.875 3.62132 5.37868 4.125 6 4.125Z" fill="#4B5563" />
                                                                        </svg>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-5">
                                                                    <h6 style={{ fontWeight: '500' }}>Messaging Information</h6>
                                                                    <span style={{whiteSpace: 'wrap', lineHeight: '1.3'}}>
                                                                        Rhoncus morbi et augue nec, in id ullamcorper at sit. Condimentum sit nunc in eros scelerisque sed. Commodo in viverra nunc, ullamcorper ut. Non, amet, aliquet scelerisque nullam sagittis, pulvinar. Fermentum scelerisque sit consectetur hac mi. Mollis leo eleifend ultricies purus iaculis.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="d-flex justify-content-center">

            </div> */}

                        {/* <div className="alert alert-primary text-center">
                <h4>No record found</h4>
              </div> */}
                    </Scrollbar>
                    <div className="card border-0 p-0 m-0 responsive_paginate_btn_sect">
                        <div className="card-body pt-0 mt-0">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-item-center ">
                                    <small className="font-weight-normal ">Showing 1 to 10 of 20 results</small>
                                </div>
                                
                                <div className={`${styles.pagination_button_container}`}>
                                    <button className="btn pginate mr-3">
                                        <span className=" d-none d-sm-block">Previous</span>
                                        <i className="bi bi-chevron-left d-block d-sm-none"></i>
                                    </button>
                                    {/* &nbsp; &nbsp; */}
                                    <button className="btn pginate">
                                        <span className=" d-none d-sm-block">Next</span>
                                        <i className="bi bi-chevron-right d-block d-sm-none"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
