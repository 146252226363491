
import { Helmet } from "react-helmet-async";
// import * as Router from 'react-router-dom';
// import Scrollbar from "../../../components/scrollbar";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import React from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
    Link,
} from "@mui/material";
// import Scrollbar from "../../../components/scrollbar";

import {
    useEffect,
    useState
} from "react";
// import bus from "src/bus";
import styles from "../Payment.module.css";
// import toast from "react-hot-toast";
import SuccessToast from 'src/components/SuccessToast';
import ErrorToast from 'src/components/ErrorToast';
import {
    DateTimeFormat,
    formatDateToDMY,
    fullNameDateFormat,
    formatDateToDMYWithHyphen
  } from "src/hooks/useDateFormat";

// ----------------------------------------------------------------------

export default function PreviewInvoice() {
    const { id } = useParams(); // Extract the 'id' parameter from the URL

    // const [previewPL, setPreviewPL] = useState(false);
    const [value, setValue] = React.useState("1");
    const [activeButton, setActiveButton] = useState("Mobile");
    const [itemData, setItemData] = useState();
    const name = JSON.parse(localStorage.getItem('auth'))?.name;

    // const [selectedImage, setSelectedImage] = useState(null);
    // const navigate = useNavigate();
    // const [itemName, setItemName] = useState('');
    // const [quantity, setQuantity] = useState('');
    // const [amount, setAmount] = useState('');
    // const [products, setProducts] = useState([]);
    // const [isEditing, setIsEditing] = useState(false);
    // const [editIndex, setEditIndex] = useState(null);
    // const [selectedProducts, setSelectedProducts] = useState([]);
    // const [searchQuery, setSearchQuery] = useState('');

    // const notifyError = (err) => notifyError(err);


    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };

    // const handleClosePreviewPL = () => {
    //     setPreviewPL(false);
    // };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
        setActiveButton('Mobile');
    };


    useEffect(() => {
        setItemData(JSON.parse(localStorage.getItem('previewItem')));
        
    }, []);

    useEffect(() => {
        // console.log(itemData);
        
    }, [itemData]);



    return (
        <>
            <Helmet>
                <title> Invoice Preview </title>
            </Helmet>

            <div className="wrapper w-100">

                <div className={`modal_content px-md-3`} style={{ minHeight: '100vh' }}>
                    <div
                        className={` mx-auto px-md-3 pb-5 py-4 py-md-5 position-relative`}
                    >
                        <div className="w-100">
                            <Box
                                style={{ padding: "0" }}
                                className="aa tab_inv_wrapper mx-auto px-3 px-sm-0"
                                sx={{ width: "55em", maxWidth: '100%', typography: "body1" }}
                            >
                                <TabContext value={value}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <Box sx={{ borderBottom: 1, borderColor: "divider", width: 'fit-content' }}>
                                            <TabList aria-label="lab API tabs" onChange={handleTabChange}>
                                                {/* <Tab label="PDF" value="2" /> */}
                                                <Tab label="Email" value="1" />
                                            </TabList>
                                        </Box>

                                        {/* {value === '2' && (
                                            <div className="wallet_designed_tabs_wrap invoice">
                                                <ul className="nav nav-pills mb-0 fit-content mx-auto d-flex justify-content-center align-items-center" id="pills-tab" role="tablist">
                                                    <li
                                                        className={`${activeButton === "Mobile" && value === '2' ? 'active' : ''} nav-item`} role="presentation">
                                                        <button
                                                            onClick={() => handleButtonClick("Mobile")}
                                                            className={`nav-link active py-1 d-flex justify-content-center align-items-center`} id="pills-home-tab" data-toggle="pill" data-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                                                            <span className="active_svg">
                                                                <svg width="39" height="46" viewBox="0 0 39 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect width="39" height="46" rx="5" fill="#D1D5DB" />
                                                                    <path d="M25.24 13H16.76C14 13 13 14 13 16.81V29.19C13 32 14 33 16.76 33H25.23C28 33 29 32 29 29.19V16.81C29 14 28 13 25.24 13ZM21 30.3C20.04 30.3 19.25 29.51 19.25 28.55C19.25 27.59 20.04 26.8 21 26.8C21.96 26.8 22.75 27.59 22.75 28.55C22.75 29.51 21.96 30.3 21 30.3ZM23 17.25H19C18.59 17.25 18.25 16.91 18.25 16.5C18.25 16.09 18.59 15.75 19 15.75H23C23.41 15.75 23.75 16.09 23.75 16.5C23.75 16.91 23.41 17.25 23 17.25Z" fill="#292D32" />
                                                                </svg>
                                                            </span>
                                                            <span className="inactive_svg">
                                                                <svg width="39" height="46" viewBox="0 0 39 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M29 18V28C29 32 28 33 24 33H18C14 33 13 32 13 28V18C13 14 14 13 18 13H24C28 13 29 14 29 18Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M23 16.5H19" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M20.9992 30.1C21.8553 30.1 22.5492 29.406 22.5492 28.55C22.5492 27.694 21.8553 27 20.9992 27C20.1432 27 19.4492 27.694 19.4492 28.55C19.4492 29.406 20.1432 30.1 20.9992 30.1Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </span>
                                                        </button>
                                                    </li>
                                                    <li
                                                        onClick={() => handleButtonClick("Desktop")}
                                                        className={`${activeButton === "Mobile" && value === '2' ? 'active' : ''} nav-item`} role="presentation">
                                                        <button className={` nav-link py-1 d-flex justify-content-center align-items-center`} id="pills-profile-tab" data-toggle="pill" data-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                                                            <span className="active_svg">
                                                                <svg width="42" height="46" viewBox="0 0 42 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect width="42" height="46" rx="5" fill="#D1D5DB" />
                                                                    <path d="M26.56 13H15.41C12.98 13 11 14.98 11 17.41V23.91V24.11C11 26.55 12.98 28.52 15.41 28.52H19.25C19.8 28.52 20.25 28.97 20.25 29.52V30.49C20.25 31.04 19.8 31.49 19.25 31.49H16.83C16.42 31.49 16.08 31.83 16.08 32.24C16.08 32.65 16.41 33 16.83 33H25.18C25.59 33 25.93 32.66 25.93 32.25C25.93 31.84 25.59 31.5 25.18 31.5H22.76C22.21 31.5 21.76 31.05 21.76 30.5V29.53C21.76 28.98 22.21 28.53 22.76 28.53H26.57C29.01 28.53 30.98 26.55 30.98 24.12V23.92V17.42C30.97 14.98 28.99 13 26.56 13Z" fill="#292D32" />
                                                                </svg>
                                                            </span>
                                                            <span className="inactive_svg">
                                                                <svg width="42" height="46" viewBox="0 0 42 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M15.44 13H26.55C30.11 13 31 13.89 31 17.44V23.77C31 27.33 30.11 28.21 26.56 28.21H15.44C11.89 28.22 11 27.33 11 23.78V17.44C11 13.89 11.89 13 15.44 13Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M21 28.2202V33.0002" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M11 24H31" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M16.5 33H25.5" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        )} */}
                                    </div>

                                    <TabPanel value="2" className="invoice_preview_wrap">
                                        <div className="w-100 px-md-4 pt-3">
                                            <div className={`${styles.PreviewInvoice_wrapper_prev} mx-auto mt-3 py-4 px-3 px-md-4`}
                                                style={{
                                                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)',
                                                    width: '32rem', maxWidth: '100%'
                                                }}>
                                                <div className="mb-4 pt-3 d-flex justify-content-between align-items-center">
                                                    <h6>
                                                        <div className={`${styles.logo_wrap} d-inline`}>
                                                            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M11.3743 21.2766C11.5225 20.895 11.5932 20.4877 11.5822 20.0785C11.6048 18.9445 11.9079 17.8337 12.4644 16.8454C13.0195 15.8587 13.8112 15.0254 14.7681 14.4205C15.1225 14.2304 15.4346 13.9705 15.6855 13.6563C15.9365 13.3421 16.1211 12.9803 16.2282 12.5928C16.3354 12.2052 16.3627 11.7999 16.3087 11.4015C16.2547 11.0031 16.1204 10.6197 15.9139 10.2747C15.6381 9.81376 15.2412 9.4359 14.7681 9.18449C14.4969 8.99633 14.2364 8.79368 13.988 8.57656L13.3656 7.9671C12.2251 6.76815 11.5856 5.1789 11.5776 3.52416C11.5894 3.11488 11.5191 2.70739 11.3709 2.32572C11.2226 1.94406 10.9994 1.59594 10.7145 1.30192C10.4308 1.00823 10.0909 0.774703 9.71495 0.61528C9.33903 0.455856 8.93485 0.373805 8.52653 0.374024C8.11844 0.374599 7.71461 0.457015 7.33893 0.616394C6.96325 0.775773 6.62335 1.00888 6.33934 1.30192C6.05489 1.59646 5.83205 1.94477 5.68384 2.32647C5.53563 2.70818 5.46504 3.11561 5.47619 3.52492C5.48285 4.05624 5.62919 4.57646 5.90053 5.03333C6.17093 5.48942 6.55699 5.86601 7.01965 6.12502C8.89297 7.31041 10.1964 9.57683 10.1964 11.8059C10.1918 13.4463 9.56445 15.0237 8.44121 16.2192L7.02422 17.4777C6.56179 17.7359 6.17555 18.1123 5.90434 18.5694C5.63237 19.0257 5.48686 19.5468 5.48 20.0785C5.46835 20.4878 5.53872 20.8953 5.68695 21.2769C5.83519 21.6586 6.0583 22.0067 6.34315 22.3008C6.62688 22.5944 6.96683 22.8279 7.34273 22.9873C7.71864 23.1467 8.12279 23.2288 8.5311 23.2287C8.93932 23.2282 9.34329 23.1458 9.71911 22.9865C10.0949 22.8271 10.435 22.5939 10.7191 22.3008C11.0034 22.0063 11.2261 21.6581 11.3743 21.2766Z" fill="#222222" />
                                                                <path d="M15.5124 5.21921C15.1765 4.71489 14.9974 4.12143 14.9974 3.51502C14.998 2.70271 15.3198 1.92357 15.8926 1.34763C16.3907 0.846536 17.0476 0.53439 17.7507 0.464748C18.4538 0.395107 19.1592 0.572311 19.7459 0.96596C20.2484 1.30337 20.6397 1.78215 20.8703 2.34181C21.101 2.90211 21.1612 3.518 21.0434 4.11236C20.9256 4.70672 20.6352 5.25313 20.2083 5.68316C19.7828 6.11201 19.2391 6.40432 18.6466 6.52278C18.0542 6.64124 17.4399 6.58047 16.8822 6.34823C16.3239 6.11576 15.8472 5.72283 15.5124 5.21921Z" fill="#222222" />
                                                                <path d="M16.3542 17.5287C16.8563 17.192 17.4467 17.0122 18.0501 17.0122V17.0107C18.4511 17.0112 18.8482 17.0908 19.2184 17.2451C19.5886 17.3994 19.9248 17.6252 20.2075 17.9096C20.7073 18.4124 21.0181 19.0722 21.0875 19.7776C21.1569 20.483 20.9806 21.1908 20.5885 21.7812C20.2537 22.2855 19.7767 22.679 19.2179 22.9118C18.6602 23.144 18.0459 23.2048 17.4535 23.0863C16.8611 22.9679 16.3174 22.6755 15.8918 22.2467C15.4648 21.8166 15.1742 21.2701 15.0565 20.6755C14.9387 20.081 14.999 19.465 15.2298 18.9046C15.4605 18.3449 15.8517 17.8662 16.3542 17.5287Z" fill="#222222" />
                                                                <path d="M25.346 10.0933C25.6811 10.5975 25.8601 11.1899 25.8603 11.7961C25.8598 12.6086 25.538 13.388 24.9651 13.9642C24.6823 14.2485 24.3461 14.4743 23.9759 14.6285C23.6057 14.7828 23.2087 14.8625 22.8076 14.8631C22.2036 14.8631 21.6133 14.6833 21.1118 14.3466C20.6093 14.0092 20.218 13.5304 19.9874 12.9707C19.7566 12.4104 19.6963 11.7943 19.8141 11.1998C19.9318 10.6053 20.2224 10.0587 20.6494 9.62864C21.075 9.19964 21.6188 8.90723 22.2114 8.78877C22.804 8.67031 23.4184 8.73116 23.9763 8.96357C24.5347 9.19624 25.0114 9.58945 25.346 10.0933Z" fill="#222222" />
                                                                <path d="M2.07695 9.24696C2.57899 8.91023 3.16864 8.73044 3.77276 8.73044C4.17374 8.73129 4.57062 8.8112 4.9407 8.96558C5.31077 9.11996 5.64677 9.34579 5.92948 9.63016C6.42894 10.1327 6.73961 10.7922 6.80902 11.4974C6.87842 12.2025 6.70229 12.9099 6.31039 13.5002C5.9757 14.0041 5.49898 14.3973 4.94063 14.63C4.38271 14.8611 3.7687 14.9213 3.17652 14.8029C2.58434 14.6845 2.04068 14.3928 1.61452 13.9649C1.18767 13.5349 0.897181 12.9885 0.779421 12.3941C0.661661 11.7998 0.721865 11.1839 0.952497 10.6236C1.18302 10.0637 1.57431 9.58461 2.07695 9.24696Z" fill="#222222" />
                                                            </svg>
                                                        </div>
                                                        <span className="ml-2 fs-12 fw-600">Company Name</span>
                                                    </h6>
                                                    <span
                                                        className="px-2 fs-8 cursor-pointer"
                                                    >
                                                        #456799
                                                    </span>
                                                </div>

                                                <div>
                                                    <small className="d-block pb-2 fw-500">Bill to <span className="font_10"> {name?name:""}</span></small>
                                                    <small className="d-block pb-2"><span className="font_10 fw-500">Date Created:</span> <span className="font_10">13th Decemeber , 2024</span></small>
                                                    <small className="d-block pb-2"><span className="font_10 fw-500">Due Created:</span> <span className="font_10">20th Decemeber , 2024</span></small>
                                                </div>

                                                <div className="mt-4">
                                                    <div className="pb-2 border-bottom">
                                                        <label htmlFor="">Item Details</label>
                                                    </div>
                                                    <div className={`py-5 w-100`}>
                                                        <div className="row w-100">
                                                            <div className="col-3 mb-0 pb-0 pr-1">
                                                                <div className="">
                                                                    <label htmlFor="">Items</label>
                                                                    <div className="w-100">
                                                                        <div className="d-flex align-items-center">
                                                                            <img src="/assets/laptop_prod_img.png" alt="" width={30} />
                                                                            <div className="ml-2 ">
                                                                                <small className="d-block">Macbook</small>
                                                                            </div>
                                                                        </div>
                                                                        {/* <small className="d-block mb-2">iPad</small>
                                                                <small className="d-block mb-2">WristWatch</small>
                                                                <small className="d-block mb-2">Macbook</small> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-3 mb-0 pb-0 pr-1">
                                                                <div className="">
                                                                    <label htmlFor="" className="d-block text-right">Per Unit</label>
                                                                    <div className="w-100">
                                                                        <div className="text-right">
                                                                            <small className="d-block">$10.00</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-3 mb-0 pb-0 pr-1">
                                                                <div className="">
                                                                    <label htmlFor="" className="d-block text-right">Quantity</label>
                                                                    <div className="w-100">
                                                                        <div className="text-right">
                                                                            <small className="d-block mb-2">2</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-3 mb-0 pb-0 pr-1">
                                                                <div className="">
                                                                    <label htmlFor="" className="d-block text-right">Amount</label>
                                                                    <div className="w-100">
                                                                        <div className="text-right">
                                                                            <small className="d-block mb-2">$20.00</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="">
                                                    <div className="pb-2 border-bottom">
                                                        <label htmlFor="">Add Ons & Totals</label>
                                                    </div>
                                                    <div className={`py-4`}>
                                                        <div className="d-flex justify-content-between">
                                                            <div className="mb-0 pb-0 pr-1">
                                                                <div className="">
                                                                    <div className="">
                                                                        <div className="">
                                                                            <small className="d-block mb-2">Subtotal</small>
                                                                            <small className="d-block mb-2">Shipping</small>
                                                                            <small className="d-block mb-2">Discount</small>
                                                                            <small className="d-block mb-2">Tax</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mb-0 pb-0 pr-1">
                                                                <div className="">
                                                                    <div className="">
                                                                        <div className="text-right">
                                                                            <small className="d-block mb-2">$61.16</small>
                                                                            <small className="d-block mb-2">$5.00</small>
                                                                            <small className="d-block mb-2">-</small>
                                                                            <small className="d-block mb-2">-</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="">
                                                    <div className="pb-2 border-bottom border-top">
                                                        <div className="d-flex justify-content-between py-3">
                                                            <span className="fw-500">Total</span>
                                                            <p className="mb-0">
                                                                <span className="fw-500">$25.00</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="pt-4 mt-2 mb-4">
                                                    <p className="d-block fw-500 fs-12">Payment Details</p>
                                                    <small className="d-block fw-500 pb-2">Bank Name: <span className="font_10">Wema Bank</span></small>
                                                    <small className="d-block fw-500 pb-2">Account Number: <span className="font_10">0066924804</span></small>
                                                    <small className="d-block fw-500 pb-2">Account Name: <span className="font_10">Michel Jason</span></small>
                                                    <small className="d-block fw-500 pb-2">
                                                        <Link className="text-primary font-weight-bold text-underlined">
                                                            Click to proceed to payment
                                                        </Link>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel value="1" className="pt-4" style={{ paddingLeft: "0" }}>
                                    <div className="w-100">
                                        <div
                                            className={`${styles.desktop_width} mt-3 mx-auto px-md-4 pt-4 pb-md-4 position-relative`}
                                            // className={`${styles.PreviewInvoice_wrapper_email} ${activeButton === "Mobile" && value === '2' ? '' : `${styles.desktop_width}`} mt-3 mx-auto px-md-4 pt-4 pb-md-4 position-relative`}
                                        >
                                            <div className="pt-2">
                                                <div>
                                                    <h6 className="fit-content mx-auto ">
                                                        <div className={`${styles.logo_wrap} d-inline`}>
                                                            {
                                                                !itemData?.company_logo &&
                                                                <svg style={{
                                                                    width: "auto", maxWidth: "150px",display: "block", margin: "0 auto"
                                                                }} width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M11.3743 21.2766C11.5225 20.895 11.5932 20.4877 11.5822 20.0785C11.6048 18.9445 11.9079 17.8337 12.4644 16.8454C13.0195 15.8587 13.8112 15.0254 14.7681 14.4205C15.1225 14.2304 15.4346 13.9705 15.6855 13.6563C15.9365 13.3421 16.1211 12.9803 16.2282 12.5928C16.3354 12.2052 16.3627 11.7999 16.3087 11.4015C16.2547 11.0031 16.1204 10.6197 15.9139 10.2747C15.6381 9.81376 15.2412 9.4359 14.7681 9.18449C14.4969 8.99633 14.2364 8.79368 13.988 8.57656L13.3656 7.9671C12.2251 6.76815 11.5856 5.1789 11.5776 3.52416C11.5894 3.11488 11.5191 2.70739 11.3709 2.32572C11.2226 1.94406 10.9994 1.59594 10.7145 1.30192C10.4308 1.00823 10.0909 0.774703 9.71495 0.61528C9.33903 0.455856 8.93485 0.373805 8.52653 0.374024C8.11844 0.374599 7.71461 0.457015 7.33893 0.616394C6.96325 0.775773 6.62335 1.00888 6.33934 1.30192C6.05489 1.59646 5.83205 1.94477 5.68384 2.32647C5.53563 2.70818 5.46504 3.11561 5.47619 3.52492C5.48285 4.05624 5.62919 4.57646 5.90053 5.03333C6.17093 5.48942 6.55699 5.86601 7.01965 6.12502C8.89297 7.31041 10.1964 9.57683 10.1964 11.8059C10.1918 13.4463 9.56445 15.0237 8.44121 16.2192L7.02422 17.4777C6.56179 17.7359 6.17555 18.1123 5.90434 18.5694C5.63237 19.0257 5.48686 19.5468 5.48 20.0785C5.46835 20.4878 5.53872 20.8953 5.68695 21.2769C5.83519 21.6586 6.0583 22.0067 6.34315 22.3008C6.62688 22.5944 6.96683 22.8279 7.34273 22.9873C7.71864 23.1467 8.12279 23.2288 8.5311 23.2287C8.93932 23.2282 9.34329 23.1458 9.71911 22.9865C10.0949 22.8271 10.435 22.5939 10.7191 22.3008C11.0034 22.0063 11.2261 21.6581 11.3743 21.2766Z" fill="#222222" />
                                                                    <path d="M15.5124 5.21921C15.1765 4.71489 14.9974 4.12143 14.9974 3.51502C14.998 2.70271 15.3198 1.92357 15.8926 1.34763C16.3907 0.846536 17.0476 0.53439 17.7507 0.464748C18.4538 0.395107 19.1592 0.572311 19.7459 0.96596C20.2484 1.30337 20.6397 1.78215 20.8703 2.34181C21.101 2.90211 21.1612 3.518 21.0434 4.11236C20.9256 4.70672 20.6352 5.25313 20.2083 5.68316C19.7828 6.11201 19.2391 6.40432 18.6466 6.52278C18.0542 6.64124 17.4399 6.58047 16.8822 6.34823C16.3239 6.11576 15.8472 5.72283 15.5124 5.21921Z" fill="#222222" />
                                                                    <path d="M16.3542 17.5287C16.8563 17.192 17.4467 17.0122 18.0501 17.0122V17.0107C18.4511 17.0112 18.8482 17.0908 19.2184 17.2451C19.5886 17.3994 19.9248 17.6252 20.2075 17.9096C20.7073 18.4124 21.0181 19.0722 21.0875 19.7776C21.1569 20.483 20.9806 21.1908 20.5885 21.7812C20.2537 22.2855 19.7767 22.679 19.2179 22.9118C18.6602 23.144 18.0459 23.2048 17.4535 23.0863C16.8611 22.9679 16.3174 22.6755 15.8918 22.2467C15.4648 21.8166 15.1742 21.2701 15.0565 20.6755C14.9387 20.081 14.999 19.465 15.2298 18.9046C15.4605 18.3449 15.8517 17.8662 16.3542 17.5287Z" fill="#222222" />
                                                                    <path d="M25.346 10.0933C25.6811 10.5975 25.8601 11.1899 25.8603 11.7961C25.8598 12.6086 25.538 13.388 24.9651 13.9642C24.6823 14.2485 24.3461 14.4743 23.9759 14.6285C23.6057 14.7828 23.2087 14.8625 22.8076 14.8631C22.2036 14.8631 21.6133 14.6833 21.1118 14.3466C20.6093 14.0092 20.218 13.5304 19.9874 12.9707C19.7566 12.4104 19.6963 11.7943 19.8141 11.1998C19.9318 10.6053 20.2224 10.0587 20.6494 9.62864C21.075 9.19964 21.6188 8.90723 22.2114 8.78877C22.804 8.67031 23.4184 8.73116 23.9763 8.96357C24.5347 9.19624 25.0114 9.58945 25.346 10.0933Z" fill="#222222" />
                                                                    <path d="M2.07695 9.24696C2.57899 8.91023 3.16864 8.73044 3.77276 8.73044C4.17374 8.73129 4.57062 8.8112 4.9407 8.96558C5.31077 9.11996 5.64677 9.34579 5.92948 9.63016C6.42894 10.1327 6.73961 10.7922 6.80902 11.4974C6.87842 12.2025 6.70229 12.9099 6.31039 13.5002C5.9757 14.0041 5.49898 14.3973 4.94063 14.63C4.38271 14.8611 3.7687 14.9213 3.17652 14.8029C2.58434 14.6845 2.04068 14.3928 1.61452 13.9649C1.18767 13.5349 0.897181 12.9885 0.779421 12.3941C0.661661 11.7998 0.721865 11.1839 0.952497 10.6236C1.18302 10.0637 1.57431 9.58461 2.07695 9.24696Z" fill="#222222" />
                                                                </svg>
                                                            }
                                                            {
                                                                itemData?.company_logo &&
                                                                <img src={itemData?.company_logo} alt="" style={{
                                                                    width: "auto", maxWidth: "150px",display: "block", margin: "0 auto"
                                                                }} />
                                                            }

                                                        </div>
                                                        <span className="d-block text-center mt-3 ml-2">
                                                            {itemData?.company_name ?? "John Doe LTD."}
                                                        </span>
                                                    </h6>
                                                </div>
                                                <p
                                                    className="mt-3 mb-0"
                                                    style={{
                                                        fontSize: "12px",
                                                        fontWeight: "500",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {itemData?.company_name}{' '} has sent you an invoice of
                                                </p>

                                                <div
                                                    className="mx-auto text-center"
                                                    style={{ width: "38em", maxWidth: "100%" }}
                                                >
                                                    <h3 className="text-dark my-3">
                                                        {(itemData && itemData?.currency) &&  
                                                            <>
                                                            {itemData?.currency === 'NGN' ? 'N' : itemData?.currency === 'USD' ? '$' : '¥'}
                                                            {Number(itemData?.totalAmount).toLocaleString()}
                                                            </>
                                                        }
                                                    </h3>
                                                    <p
                                                        className="mt-3 mb-0"
                                                        style={{
                                                            fontSize: "12px",
                                                            fontWeight: "500",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        Due Date on {" "}
                                                        <span>
                                                            {(itemData && itemData?.currency) &&  
                                                                <>
                                                                    {fullNameDateFormat(itemData?.due_date)}
                                                                </>
                                                            }
                                                        </span>
                                                    </p>

                                                    <p
                                                        className="mt-4 mb-0 fs-10"
                                                        style={{
                                                            fontWeight: "500",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        INVOICE SUMMARY
                                                    </p>

                                                    <div className="drawer_inv_summary_wrap px-3" style={{ background: 'rgba(249, 250, 251, 1)' }}>
                                                        <div className="mt-3">
                                                            <div className={`pt-3 w-100`}>
                                                                <div className="d-flex justify-content-between w-100">
                                                                    <label htmlFor="" className="d-block text-left fs-11">Items</label>
                                                                    <label htmlFor="" className="d-block text-right fs-11">Amount</label>
                                                                </div>
                                                                {
                                                                    itemData?.products.length > 0 ? (
                                                                        <div>
                                                                        {itemData?.products.map((product, index) => (
                                                                            <div className="d-flex justify-content-between align-items-center w-100">
                                                                                <div className="mb-0 pb-0">
                                                                                    <div className="">
                                                                                        <div className="w-100">
                                                                                            <div className="d-flex align-items-center">
                                                                                                <img src={product?.image_url} alt="" width={30} />
                                                                                                <div className="ml-2 ">
                                                                                                    <span className="d-inline fs-10">{product?.name}</span>
                                                                                                    <span className="d-inline ml-2 fs-10">X{product?.quantity}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="mb-0 pb-0">
                                                                                    <div className="">
                                                                                        <div className="w-100">
                                                                                            <div className="text-right">
                                                                                                <small className="d-block fs-10">
                                                                                                    {itemData?.currency === 'NGN' ? 'N' : itemData?.currency === 'USD' ? '$' : '¥'}
                                                                                                    {Number(product?.price).toLocaleString()}
                                                                                                </small>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            <h4 className="fw-700 fs-16 mb-4 text-center d-block border-top py-4">No Item Added Yet</h4>
                                                                        </>
                                                                    )
                                                                }
                                                                
                                                            </div>
                                                        </div>


                                                        <div className=" mt-4 border-top"></div>
                                                        <div 
                                                        className="ml-auto addOns_sect" style={{ width: '13em', maxWidth: '100%' }}>
                                                        {/* className="ml-auto" style={{ width: `${activeButton === "Mobile" && value === '2' ? '100%' : '13em'}`, maxWidth: '100%' }}> */}
                                                            <div className="pb-2 pt-3">
                                                                <label htmlFor="" className="text-left d-block fs-10">Add Ons & Totals</label>
                                                            </div>
                                                            <div className={`py-`}>
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="mb-0 pb-0 pr-1">
                                                                        <div className="">
                                                                            <div className="">
                                                                                <div className="">
                                                                                    <small className="d-block fs-10 text-left mb-2">Subtotal</small>
                                                                                    <small className="d-block fs-10 text-left mb-2">Shipping</small>
                                                                                    <small className="d-block fs-10 text-left mb-2">Discount</small>
                                                                                    <small className="d-block fs-10 text-left mb-2">Tax</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-0 pb-0 pr-1">
                                                                        <div className="">
                                                                            <div className="">
                                                                                <div className="text-right">
                                                                                    <small className="d-block mb-2 fs-10">
                                                                                        {(itemData && itemData?.subTotal) &&  
                                                                                            <>
                                                                                            {itemData?.currency === 'NGN' ? 'N' : itemData?.currency === 'USD' ? '$' : '¥'}
                                                                                            {Number(itemData?.subTotal).toLocaleString()}
                                                                                            </>
                                                                                        }
                                                                                        {
                                                                                            (itemData && !itemData?.subTotal) &&
                                                                                            <>0</>
                                                                                        }
                                                                                    </small>
                                                                                    <small className="d-block mb-2 fs-10">
                                                                                        {(itemData && itemData?.shipping) &&  
                                                                                            <>
                                                                                            {itemData?.currency === 'NGN' ? 'N' : itemData?.currency === 'USD' ? '$' : '¥'}
                                                                                            {Number(itemData?.shipping).toLocaleString()}
                                                                                            </>
                                                                                        }
                                                                                        {
                                                                                            (itemData && !itemData?.shipping) &&
                                                                                            <>0</>
                                                                                        }
                                                                                    </small>
                                                                                    <small className="d-block mb-2 fs-10">
                                                                                        {(itemData && itemData?.discount) &&  
                                                                                            <>
                                                                                                {Number(itemData?.discount).toLocaleString()}%
                                                                                            </>
                                                                                        }
                                                                                        {
                                                                                            (itemData && !itemData?.discount) &&
                                                                                            <>%</>
                                                                                        }
                                                                                    </small>
                                                                                    <small className="d-block mb-2 fs-10">
                                                                                        {(itemData && itemData?.tax) &&  
                                                                                            <>
                                                                                                {Number(itemData?.tax).toLocaleString()}%
                                                                                            </>
                                                                                        }
                                                                                        {
                                                                                            (itemData && !itemData?.tax) &&
                                                                                            <>%</>
                                                                                        }
                                                                                    </small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="">
                                                            <div className="pb-2 ">
                                                                <div className="d-flex justify-content-between py-3">
                                                                    <span className="fw-500">Total</span>
                                                                    <p className="mb-0">
                                                                        <span className="fw-700 text-dark">
                                                                            {(itemData && itemData?.currency) &&  
                                                                                <>
                                                                                    {itemData?.currency === 'NGN' ? 'N' : itemData?.currency === 'USD' ? '$' : '¥'}
                                                                                    {Number(itemData?.totalAmount).toLocaleString()}
                                                                                </>
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="nelow_btn pt-2 pb-3">
                                                            <div className="btn_wrap">
                                                                <Link
                                                                    to=""
                                                                    component={RouterLink}
                                                                    // onClick={handleClosePreviewPL}
                                                                    className="btn btn-primary d-block w-100 text-white py-2"
                                                                >
                                                                    Make Payment
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </>)
}

// import React from "react";
// import { Helmet } from "react-helmet-async";
// import { Link as RouterLink } from "react-router-dom";
// import { Link } from "@mui/material";
// import styles from "../Payment.module.css";

// export default function PreviewInvoice() {
//     return (
//         <>
//             <Helmet>
//                 <title> Preview Invoice </title>
//             </Helmet>
//             <div className="px-0 px-md-2 mt-3">
//                 <div className="d-flex justify-content-between align-items-center border-bottom pb-3">
//                     <div className="d-flex">
//                         <Link
//                             className="d-block text-secondary"
//                             to="/app/payment/invoice"
//                             component={RouterLink}
//                         >
//                             <i
//                                 className="bi bi-x"
//                                 style={{ fontSize: "1.5em", lineHeight: "1" }}
//                             ></i>
//                         </Link>
//                         <h5 className="pl-2 pl-md-3">Preview Invoice</h5>
//                     </div>
//                     <div>
//                         <div className="btn-group">
//                             <Link
//                                 className="btn border btn-primary"
//                                 to="/app/payment/invoice"
//                                 component={RouterLink}
//                             >
//                                 <span className="text-white">Send</span>
//                             </Link>
//                         </div>
//                     </div>
//                 </div>

//                 <div className={`${styles.PreviewInvoice_wrapper} mx-auto px-4 py-5 p-md-5`}>
//                     <div className="mb-4 d-sm-flex justify-content-between align-items-end">
//                         <h4>
//                             <div className={`${styles.logo_wrap} d-inline`}>
//                                 <svg
//                                     width="50"
//                                     height="45"
//                                     viewBox="0 0 50 45"
//                                     fill="none"
//                                     xmlns="http://www.w3.org/2000/svg"
//                                 >
//                                     <path
//                                         d="M20.8075 40.8245C21.0969 40.0792 21.2349 39.2838 21.2134 38.4846C21.2576 36.2698 21.8495 34.1003 22.9364 32.17C24.0207 30.2429 25.5668 28.6155 27.4359 27.434C28.1279 27.0628 28.7375 26.555 29.2276 25.9414C29.7178 25.3279 30.0784 24.6212 30.2876 23.8643C30.4968 23.1073 30.5502 22.3158 30.4447 21.5376C30.3392 20.7594 30.0769 20.0107 29.6737 19.3368C29.135 18.4366 28.3598 17.6986 27.4359 17.2076C26.9062 16.8401 26.3973 16.4443 25.9122 16.0203L24.6966 14.8299C22.4691 12.4883 21.22 9.38433 21.2045 6.15248C21.2275 5.35313 21.0902 4.55727 20.8007 3.81184C20.5111 3.06641 20.0752 2.38652 19.5187 1.81227C18.9647 1.23866 18.3007 0.782563 17.5665 0.471195C16.8323 0.159827 16.0429 -0.000426083 15.2455 8.50811e-07C14.4484 0.00112486 13.6597 0.162089 12.926 0.473371C12.1922 0.784652 11.5284 1.23993 10.9737 1.81227C10.4181 2.38753 9.98289 3.06781 9.69343 3.8133C9.40397 4.5588 9.2661 5.35455 9.28789 6.15397C9.30088 7.19169 9.58671 8.20772 10.1166 9.10002C10.6448 9.99081 11.3988 10.7263 12.3024 11.2322C15.9611 13.5474 18.5069 17.9739 18.5069 22.3275C18.4979 25.5313 17.2726 28.6121 15.0788 30.9469L12.3113 33.4049C11.4081 33.9093 10.6538 34.6444 10.1241 35.5371C9.59291 36.4284 9.30872 37.4461 9.29533 38.4846C9.27257 39.284 9.40999 40.0798 9.69951 40.8251C9.98903 41.5705 10.4248 42.2505 10.9811 42.8248C11.5353 43.3983 12.1992 43.8543 12.9334 44.1657C13.6676 44.477 14.4569 44.6374 15.2544 44.6371C16.0517 44.6362 16.8407 44.4753 17.5747 44.164C18.3087 43.8527 18.9728 43.3974 19.5276 42.8248C20.0829 42.2497 20.518 41.5697 20.8075 40.8245Z"
//                                         fill="#222222"
//                                     />
//                                     <path
//                                         d="M28.8895 9.46307C28.2334 8.47808 27.8837 7.319 27.8837 6.13463C27.8847 4.54812 28.5132 3.0264 29.632 1.90154C30.6048 0.922857 31.8878 0.313209 33.261 0.177193C34.6342 0.0411779 36.0119 0.387274 37.1578 1.1561C38.1393 1.8151 38.9036 2.75019 39.354 3.84326C39.8044 4.93757 39.922 6.14046 39.692 7.30129C39.462 8.46213 38.8946 9.52931 38.061 10.3692C37.2298 11.2068 36.168 11.7777 35.0109 12.0091C33.8539 12.2404 32.6541 12.1217 31.5648 11.6681C30.4744 11.2141 29.5434 10.4467 28.8895 9.46307Z"
//                                         fill="#222222"
//                                     />
//                                     <path
//                                         d="M30.5337 33.5046C31.5142 32.847 32.6673 32.4958 33.8457 32.4958V32.4928C34.6291 32.4938 35.4045 32.6494 36.1276 32.9507C36.8507 33.2519 37.5072 33.693 38.0595 34.2486C39.0355 35.2304 39.6425 36.5191 39.7781 37.8968C39.9136 39.2746 39.5694 40.6569 38.8034 41.8101C38.1496 42.795 37.218 43.5636 36.1267 44.0181C35.0374 44.4717 33.8376 44.5904 32.6806 44.3591C31.5235 44.1277 30.4616 43.5568 29.6305 42.7192C28.7965 41.8792 28.229 40.8117 27.999 39.6506C27.769 38.4895 27.8868 37.2863 28.3375 36.1918C28.788 35.0987 29.5522 34.1637 30.5337 33.5046Z"
//                                         fill="#222222"
//                                     />
//                                     <path
//                                         d="M48.0954 18.9827C48.7498 19.9673 49.0995 21.1244 49.0997 22.3084C49.0987 23.8952 48.4703 25.4174 47.3515 26.5427C46.799 27.0981 46.1425 27.539 45.4195 27.8403C44.6964 28.1416 43.921 28.2973 43.1377 28.2984C41.958 28.2985 40.805 27.9473 39.8256 27.2896C38.8441 26.6306 38.0799 25.6956 37.6295 24.6025C37.1787 23.508 37.061 22.3048 37.291 21.1437C37.521 19.9825 38.0885 18.9151 38.9225 18.0751C39.7537 17.2372 40.8159 16.6661 41.9732 16.4347C43.1306 16.2034 44.3307 16.3222 45.4202 16.7761C46.5107 17.2305 47.4418 17.9985 48.0954 18.9827Z"
//                                         fill="#222222"
//                                     />
//                                     <path
//                                         d="M2.64886 17.3296C3.62939 16.672 4.78103 16.3208 5.96093 16.3208C6.74409 16.3225 7.51923 16.4785 8.24201 16.78C8.96479 17.0816 9.62103 17.5226 10.1732 18.078C11.1487 19.0595 11.7555 20.3477 11.891 21.7248C12.0265 23.102 11.6826 24.4837 10.9171 25.6366C10.2635 26.6207 9.33239 27.3886 8.24189 27.8431C7.15221 28.2945 5.953 28.412 4.79642 28.1808C3.63984 27.9495 2.57803 27.3799 1.7457 26.5442C0.912032 25.7043 0.344677 24.6371 0.114681 23.4763C-0.115315 22.3155 0.00227014 21.1126 0.452713 20.0182C0.902942 18.9247 1.66717 17.9891 2.64886 17.3296Z"
//                                         fill="#222222"
//                                     />
//                                 </svg>

//                                 <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
// <g filter="url(#filter0_d_2896_13415)">
// <path d="M30.7906 6.48615C18.4022 -4.18169 9.11087 3.30299 5.50028 7.28095C4.89804 11.6685 8.94151 11.5941 9.97388 10.4641C16.3402 3.49559 24.255 6.93682 26.4918 10.0339C32.0838 16.1421 29.3595 22.9672 25.9756 26.2077C19.3685 31.7137 12.5548 27.9857 9.97388 25.4335C6.46382 24.4699 6.15985 27.4982 6.44662 29.1328C13.9313 37.4778 23.7101 34.4667 27.8683 31.5417C39.0867 23.2827 34.6593 11.2834 30.7906 6.48615Z" fill="#5D20D2"/>
// <path d="M30.7906 6.48615C18.4022 -4.18169 9.11087 3.30299 5.50028 7.28095C4.89804 11.6685 8.94151 11.5941 9.97388 10.4641C16.3402 3.49559 24.255 6.93682 26.4918 10.0339C32.0838 16.1421 29.3595 22.9672 25.9756 26.2077C19.3685 31.7137 12.5548 27.9857 9.97388 25.4335C6.46382 24.4699 6.15985 27.4982 6.44662 29.1328C13.9313 37.4778 23.7101 34.4667 27.8683 31.5417C39.0867 23.2827 34.6593 11.2834 30.7906 6.48615Z" stroke="#5D20D2" strokeWidth="0.172062"/>
// </g>
// <g filter="url(#filter1_d_2896_13415)">
// <path d="M14.14 15.5017C11.4124 17.8607 7.41317 16.976 5.75448 16.2389V19.2798C10.8411 17.658 13.28 18.9112 13.8636 19.7405L14.14 15.5017Z" fill="#5D20D2"/>
// <path d="M24 17.3907C24 20.3679 21.5865 22.7814 18.6093 22.7814C15.6321 22.7814 13.2186 20.3679 13.2186 17.3907C13.2186 14.4135 15.6321 12 18.6093 12C21.5865 12 24 14.4135 24 17.3907Z" fill="#5D20D2"/>
// <path d="M6.86027 17.8054C6.86027 19.2813 5.66383 20.4777 4.18795 20.4777C2.71206 20.4777 1.51562 19.2813 1.51562 17.8054C1.51562 16.3295 2.71206 15.1331 4.18795 15.1331C5.66383 15.1331 6.86027 16.3295 6.86027 17.8054Z" fill="#5D20D2"/>
// </g>
// <defs>
// <filter id="filter0_d_2896_13415" x="4.66527" y="0.914062" width="31.1109" height="35.1148" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
// <feFlood floodOpacity="0" result="BackgroundImageFix"/>
// <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
// <feOffset dy="0.688247"/>
// <feGaussianBlur stdDeviation="0.344124"/>
// <feComposite in2="hardAlpha" operator="out"/>
// <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
// <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2896_13415"/>
// <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2896_13415" result="shape"/>
// </filter>
// <filter id="filter1_d_2896_13415" x="0.778432" y="12" width="23.9588" height="12.2556" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
// <feFlood floodOpacity="0" result="BackgroundImageFix"/>
// <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
// <feOffset dy="0.737193"/>
// <feGaussianBlur stdDeviation="0.368596"/>
// <feComposite in2="hardAlpha" operator="out"/>
// <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
// <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2896_13415"/>
// <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2896_13415" result="shape"/>
// </filter>
// </defs>
// </svg>

//                             </div>
//                             <span className="d-block d-sm-inline mt-3 mt-sm-0 ml-sm-3">Company Name</span>
//                         </h4>
//                         <span
//                             className="px-2"
//                             style={{ fontSize: "14px", cursor: "pointer" }}
//                         >
//                             #456799
//                         </span>
//                     </div>

//                     <div>
//                         <small className="d-block pb-2">Bill to <span> Michel Joseph</span></small>
//                         <small className="d-block pb-2">Date Created: <span>13th Decemeber , 2024</span></small>
//                         <small className="d-block pb-2">Due Created: <span>20th Decemeber , 2024</span></small>
//                     </div>

//                     <div className="mt-5">
//                         <div className="pb-2 border-bottom">
//                             <label htmlFor="">Item Details</label>
//                         </div>
//                         <div className={`py-5`}>
//                             <div className="row">
//                                 <div className="col-3 mb-0 pb-0 pr-1">
//                                     <div className="">
//                                         <label htmlFor="">Items</label>
//                                         <div className="" style={{ width: "100%" }}>
//                                             <div className="">
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>iPad</small>
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>WristWatch</small>
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>Macbook</small>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-3 mb-0 pb-0 pr-1">
//                                     <div className="">
//                                         <label htmlFor="" className="d-block text-right">Per Unit</label>
//                                         <div className="" style={{ width: "100%" }}>
//                                             <div className="text-right">
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>$10.00</small>
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>$0.29</small>
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>$20.00</small>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-3 mb-0 pb-0 pr-1">
//                                     <div className="">
//                                         <label htmlFor="" className="d-block text-right">Quantity</label>
//                                         <div className="" style={{ width: "100%" }}>
//                                             <div className="text-right">
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>2</small>
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>4</small>
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>1</small>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-3 mb-0 pb-0 pr-1">
//                                     <div className="">
//                                         <label htmlFor="" className="d-block text-right">Amount</label>
//                                         <div className="" style={{ width: "100%" }}>
//                                             <div className="text-right">
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>$20.00</small>
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>$1.16</small>
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>$20.00</small>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>



//                     <div className="">
//                         <div className="pb-2 border-bottom">
//                             <label htmlFor="">Add Ons & Totals</label>
//                         </div>
//                         <div className={`py-4`}>
//                             <div className="d-flex justify-content-between">
//                                 <div className="mb-0 pb-0 pr-1">
//                                     <div className="">
//                                         <div className="">
//                                             <div className="">
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>Subtotal</small>
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>Shipping</small>
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>Discount</small>
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>Tax</small>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="mb-0 pb-0 pr-1">
//                                     <div className="">
//                                         <div className="">
//                                             <div className="text-right">
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>$61.16</small>
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>$5.00</small>
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>-</small>
//                                                 <small className="d-block mb-2" style={{ fontSize: '13px' }}>-</small>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                     <div className="">
//                         <div className="pb-2 border-bottom border-top">
//                             <div className="d-flex justify-content-between py-3">
//                                 <span style={{ fontWeight: '500' }}>Total</span>
//                                 <p className="mb-0">
//                                     <span style={{ fontWeight: '500' }}>$99.45</span>
//                                 </p>
//                             </div>
//                         </div>
//                     </div>

//                     <div className="pt-4 mt-2">
//                         <p className="d-block ">Payment Details</p>
//                         <small className="d-block pb-2">Bank Name: <span>Wema Bank</span></small>
//                         <small className="d-block pb-2">Account Number: <span>0066924804</span></small>
//                         <small className="d-block pb-2">Account Name: <span>Michel Jason</span></small>
//                         <small className="d-block pb-2">
//                             <Link to="/app/wallet/payment-otp" component={RouterLink} className="text-primary font-weight-bold text-underlined">
//                                 Click to proceed to payment
//                             </Link>
//                         </small>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }