import { Link } from '@mui/material';
import React from 'react'; //   } //     useRef //     useEffect, //   {
//   import { useInView } from "react-intersection-observer";
// import mystyle from "./scroll.module.css";
import styles from './landpage.module.css';
import '../../Global.css';
import TopNavbar from '../../topnav';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import {
  arrowRightSvg,
  emailSvg,
  phoneSvg,
  redDotSvg,
} from 'src/components/svgs';
import Carousel from 'src/components/Carousel/Carousel';
import HomeFeatureSection from 'src/components/HomeFeatureSection/HomeFeatureSection';
import { AppDashboardData } from './AppDashboardData';
import { useTranslation } from 'react-i18next';
import SpreadCard from 'src/components/SpreadCard/SpreadCard';

export default function WebsiteLandPage() {
  const { t } = useTranslation(['landing-page']);
  const { data } = useAuth();
  let isLoggedIn = Object.keys(data).length;
  const navigate = useNavigate();

  const handleProceed = () => {
    navigate('/login');
  };

  const currentYear = new Date().getFullYear();

  return (
    <>
      {/* <!-- For Nav Bar --> */}

      <TopNavbar />

      {/* <!-- Hero Section --> */}

      <div className="main-container" style={{ overflowX: 'hidden' }}>
        <div className={`${styles.hero_home} hero_home_wrap`}>
          {/* container-width */}

          <div className={`${styles.hero_home_content} container-width  `}>
            <div
              className={`${styles.heroLeftSide} py-5 my-5`}
              style={{ position: 'relative' }}
            >
              <div className={` py-2`}>
                <div className={` ${styles.slogacontainer} pb-2`}>
                  <div
                    className={`${styles.slogan1} ${styles.top} font-weignt-bold`}
                  >
                    <span>{redDotSvg()}</span>

                    <span className={`${styles.sloganText}`}>
                      {t('What’s new?')}
                    </span>
                  </div>

                  <div className={`${styles.slogan2}`}>
                    <span className={`${styles.sloganText}`}>
                      {t('Access to Ledger Management')}
                    </span>

                    <span>{arrowRightSvg()}</span>
                  </div>
                </div>
                <span className={`${styles.title_hero_text}`}>
                  {t('The Point where')} <br />
                  {t('your Business')} <br />
                  {t('exceeds Borders')}
                </span>
                <br />
                <div className="pb-2">
                  <p className={`${styles.sloganDescrip}`}>
                    {t(
                      `Simplify your business between Africa and Asia by seamlessly collecting, sending, exchanging and managing multiple-currency wallet on One platform.`
                    )}
                  </p>
                </div>

                <div
                  className={` ${styles.sloganBtn} dark-btn`}
                  onClick={() => navigate('/login')}
                >
                  {t('Get Started')}
                </div>
              </div>
            </div>

            <div className={`${styles.heroRightSide}`}>
              <img src="/assets/hero-frame-bg.png" alt="dashboard" />
            </div>
          </div>
        </div>

        <div
          className={`  ${styles.second_segment}`}
          style={{ position: 'relative' }}
        >
          <div className="container-width-small py-5">
            <div className={`${styles.second_segment_top} mb-5 `}>
              <div className={`${styles.hummingBirdText}`}>
                <img
                  className="humming-brid-img"
                  src="/assets/video/humming_bord.gif"
                  alt="hummiing bird"
                />

                <h1>
                  {t('STRUGGLING WITH COMPLEX GLOBAL FINANCIAL OPERATIONS?')}
                </h1>
              </div>

              <p>
                {t(
                  `Finding it difficult to manage cash flow across multiple countries? Looking for a streamlined solution to your global financial challenges? OnePoint is your solution.`
                )}
              </p>

              <div className={` ${styles.second_segment_btn} dark-btn`}>
                {t('Get Your Global Wallet')}
              </div>
            </div>
            <div className="row justify-content-end">
              <Carousel />

              <div className={`${styles.arrow_down_vid}`}>
                <img src="/assets/video/arrow-down.gif" alt="hummiing bird" />
              </div>
            </div>

            {/* Feactures section */}
            <div>
              <HomeFeatureSection />
            </div>

            <div className={styles.valueables_secion}>
              <div className={styles.valueable_secion}>
                <div className={styles.valueable_secion__img_con}>
                  <img src="/assets/images/valuable1.png" alt="values" />
                </div>

                <div className={`flex-full ${styles.valueable_secion_right}`}>
                  <div className={styles.valueable_secion_right_header}>
                    <h2>
                      {t('Uncover valuable insights from your financial data')}
                    </h2>

                    <img
                      className={` humming-brid-img ${styles.humming_brid_img_valuable}`}
                      src="/assets/video/humming_bord.gif"
                      alt="hummiing bird"
                    />
                  </div>

                  <p className={styles.valueable_secion_right_graph_p}>
                    {t(
                      `Designed to streamline cross-border transactions, optimize cash flow, and deliver actionable insights.`
                    )}
                  </p>

                  <div className={styles.valueable_secion_right_imgCon}>
                    <img
                      className={styles.valueable_secion_right_imgCon_graph}
                      src="/assets/video/line-graph.gif"
                      alt="graph video"
                    />

                    <img
                      className={styles.valueable_secion_right_imgCon_shadow}
                      src="/assets/images/graph-shadow-top.png"
                      alt="graph "
                    />
                  </div>
                </div>
              </div>

              <div className={styles.valueable_secion_reverse}>
                <div className={styles.valueable_secion__img_con}>
                  <img src="/assets/images/valuable2.png" alt="vvalues" />
                </div>

                <div className={styles.valueable_secion_right}>
                  <h2>{t('Maintain detailed financial records with ease')}</h2>

                  <p
                    style={{
                      color: '#182230',
                    }}
                    className={styles.valueable_secion_right_p}
                  >
                    {t(
                      `Gain seamless access to a comprehensive ledger that consolidates all your daily transactions on a single platform, allowing you to track, manage, and analyze financial data in real-time.`
                    )}
                  </p>

                  <div className={styles.valueable_secion_right_video}>
                    <SpreadCard />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.one_point_journey_con}>
              <div className={styles.one_point_journey_item}>
                <h3>{t('Start Your OnePoint Journey')}</h3>
                <p>{t('See how OnePoint can transform your business')}</p>
                <div className={styles.one_point_journey_item_bth}>
                  <button onClick={() => navigate('/app/overview')}>
                    {t('Learn more')}
                  </button>
                  <button onClick={() => navigate('/app/start-tour')}>
                    {t('Get started')}
                  </button>
                </div>
              </div>

              <div className={styles.one_point_journey_right}>
                <div>
                  <video
                    className="video-card__media"
                    src="/assets/video/onepoint-video.mp4"
                    type="video/mp4"
                    preload="auto"
                    autoPlay
                    loop
                    muted
                    playsInline
                  >
                    {t('Your browser does not support the video tag.')}
                  </video>
                </div>
                <p>OnePoint</p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.one_point_journey_item_mobile}>
          <div className={styles.one_point_journey_item_mobile_header}>
            <h3>{t('Start Your OnePoint Journey')}</h3>
            <p>{t('See how OnePoint can transform your business')}</p>
          </div>

          <div className={styles.one_point_journey_item_bth}>
            <button onClick={() => navigate('/app/overview')}>
              {t('Learn more')}
            </button>
            <button onClick={() => navigate('/app/start-tour')}>
              {t('Get started')}
            </button>
          </div>
        </div>

        {/* Wider width */}
        <div
          className={` pt-5 pb-3 ${styles.keypoint_main_con}`}
          style={{ position: 'relative', background: '#0C111D' }}
        >
          <div className={`container-width  ${styles.kepoints_con}`}>
            <div className={styles.kepoints_con_left}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '2rem',
                }}
              >
                <img src="/assets/images/logo-white.png" alt="app logo" />

                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.6rem',
                  }}
                >
                  One Point
                </p>
              </div>

              <div className={styles.kepoints_con_right}>
                <h3>{t('Your All-in-One Financial Solution')}</h3>
                <p>
                  {t(
                    'Maximize your business potential with OnePoint: spend smarter, save more, and unlock growth opportunities'
                  )}
                </p>
              </div>
            </div>

            {/* Show on mobile */}
            <div
              className={`${styles.keypoint_right_content} ${styles.keypoint_show_mobile} `}
            >
              {AppDashboardData.map(({ text, subText }, index) => (
                <div key={index} className={styles.keypoint_show_mobile_card}>
                  <h3>{t(text)}</h3>
                  <p>{t(subText)}</p>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.keypoint_wave_pattern}>
            <img
              src="/assets/images/wave-pattern.png"
              alt="wave pattern design"
            />
          </div>
        </div>

        <div className={` container-width-small  ${styles.wait_list_con}`}>
          <div>
            <h3>{t('What are you waiting for?')}</h3>

            <p>{t('Get started with OnePoint today')}</p>

            <div className={styles.wait_list_con_btns}>
              <button onClick={() => navigate('/app/overview')}>
                {t('Learn more')}
              </button>

              <button onClick={() => navigate('/app/start-tour')}>
                {t('Get started')}
              </button>
            </div>
          </div>

          <div className={styles.wait_list_con_img}>
            <img
              src="/assets/images/waiting-image.png"
              alt="key points"
              className={styles.wait_list_con_img_large_view}
            />

            <img
              src="/assets/images/mobile-waitlist.png"
              alt="key points"
              className={styles.wait_list_con_img_small_view}
            />
          </div>
        </div>

        <div className={` container-width-small  ${styles.contact_us_con}`}>
          <div className={styles.contact_us_con_left}>
            <span>{t('Contact us')}</span>

            <h3>{t(`We’d love to hear from you`)}</h3>

            <p>{t('Our friendly team is always here to help')}</p>
          </div>

          <div className={styles.contact_us_right}>
            <div className={styles.contact_us_right_item}>
              <div>{emailSvg()}</div>

              <div className={styles.contact_us_right_item_content}>
                <h4>{t(`Chat with support`)}</h4>

                <a href="mailto:hailey@onepointhq.com">hailey@onepointhq.com</a>
              </div>
            </div>

            <div className={styles.contact_us_right_item}>
              <div>{phoneSvg()}</div>

              <div className={styles.contact_us_right_item_content}>
                <h4>{t('Call us')}</h4>

                <a href="tel:+2349047187156">(+234) 904 718 7156</a>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- /* For Footer intersect_img  --> */}

        <div className={styles.home_footer}>
          <div className={styles.home_footer_image}>
            <img src="/assets/images/logo-text-white.png" alt="Footer logo" />
          </div>

          <div>
            <ul>
              {/* <li onClick={() => navigate('/')}>{t('Home')}</li> */}

              {/* <li>{t('Legal')}</li>

              <li>{t('Policy')}</li> */}
            </ul>
          </div>
          <div className={styles.home_footer_copy_right}>
            <p>
              {t('Copyright')} © Eaven Technologies {t('Limited')} 2024.{' '}
              {t('All rights reserved.')}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
