// ----------------------------------------------------------------------
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import styles from "./UserSettings.module.css";
import MyAccountTab from "./components/MyAccountTab";
import OrganisationTab from "./components/OrganisationTab";
import NotificationTab from "./components/NotificationTab";
import PaymentSecurityTab from "./components/PaymentSecurityTab";
import TeamTab from "./components/TeamTab";
// import ActivityLogTab from "./components/ActivityLogTab";

import {
    // useEffect,
    useState,
} from "react";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Link as RouterLink } from "react-router-dom";

import {
    Link,
} from "@mui/material";
// sections
// mock
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function UserSettings() {
    // eslint-disable-next-line no-unused-vars
    const location = useLocation();
    const [value, setValue] = React.useState("1");

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const tabValue = JSON.parse(localStorage.getItem('currentTab'))
    useEffect(() => {
        // Check if there's a tab in the location state and set it
        // console.log(location.state);
        if (location.state === null && tabValue === 'team') {
          setValue("5");
        //   localStorage.setItem('currentTab', JSON.stringify('team'));
        }
      }, [location.state]);

    const handleTabChange = (event, newValue) => {
        let value = "";
        // console.log(newValue);
        setValue(newValue);
    
        switch (newValue) {
          case "1":
            value = 'my-account';
            break;
          case "2":
            value = 'organization';
            break;
          case "3":
            value = 'notification';
            break;
          case "4":
            value = 'payment&security';
            break;
          default:
            value = 'team';
            break;
        }
    
        localStorage.setItem('currentTab', JSON.stringify(value));
      };
    const handleToggleDropdown = () => {
        setToggleDropdown(!toggleDropdown);
    };

    // useEffect(() => {
    //     if(tabValue) {
    //         handleTabChange(tabValue)
    //     }
    // }, [tabValue])
    // useEffect(() => {
    //     console.log(newValue);
    // }, [newValue])

    return (
        <>
            <Helmet>
                <title>Site Settings</title>
            </Helmet>

            <div className="px-0 px-md-2">
                <div onClick={() => handleToggleDropdown()}
                    className={`overlay_div ${toggleDropdown ? "show_overlay" : ""}`}
                ></div>

                <div className="d-flex justify-content-between align-items-center">
                    <div className="">
                        <h5 className="page_title mb-2">Settings</h5>
                        <p style={{ color: '#4B5563', fontSize: '13px', gap: '8px' }} className="mt-2 d-flex align-items-center">
                            <span className="">
                                Manage your financial transactions with ease
                            </span>
                        </p>
                    </div>
                </div>

                <div style={{ marginTop: "0px", borderRadius: "10px" }} className="p-0 mt-2 userSettings_tab_section">
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider", }}>
                            <TabList aria-label="lab API tabs" onChange={handleTabChange}>
                                <Tab label="My Account" value="1" />
                                <Tab label="Organisation" value="2" />
                                <Tab label="Notification" value="3" />
                                <Tab label="Payment and Security" value="4" />
                                <Tab label="Team" value="5" />
                                {/* <Tab label="Activity Log" value="6" /> */}
                            </TabList>
                        </Box>
                        <TabPanel value="1" className="invoice_preview_wrap">
                            <MyAccountTab />
                        </TabPanel>
                        <TabPanel value="2" className="invoice_preview_wrap">
                            <OrganisationTab />
                        </TabPanel>
                        <TabPanel value="3" className="invoice_preview_wrap">
                            <NotificationTab />
                        </TabPanel>
                        <TabPanel value="4" className="invoice_preview_wrap">
                            <PaymentSecurityTab />
                        </TabPanel>
                        <TabPanel value="5" className="invoice_preview_wrap">
                            <TeamTab />
                        </TabPanel>
                        {/* <TabPanel value="6" className="invoice_preview_wrap">
                            <ActivityLogTab />
                        </TabPanel> */}
                    </TabContext>
                </div>
            </div>
        </>
    );
}
