import React from 'react';

import './SpreadCard.css';
import { redDotIconSvg } from '../svgs';
import { useTranslation } from 'react-i18next';

const SpreadCard = () => {
  const { t } = useTranslation();
  return (
    <div className="spread-cards">
      <div className="card-back card-back-4"></div>
      <div className="card-back card-back-3"></div>
      <div className="card-back card-back-2"></div>
      <div className="card-back card-back-1"></div>
      <div className="spread-card card-primary">
        {/* Main card */}
        <div className="spread-card__header">
          {redDotIconSvg()}

          <p className="spread-card__header-p-bold">{t('Ledger Update')}</p>

          <p className="spread-card__header-p-light">{t('11 hours ago')}</p>
        </div>

        <p className="spread-card__header-p-body">
          {t('Your transfer of NGN 200,000 has been updated to your ledger')}
        </p>

        <p className="spread-card__header-view-more">{t('View Posting')} →</p>
      </div>
    </div>
  );
};

export default SpreadCard;
