// ----------------------------------------------------------------------
import React from "react";
import { Helmet } from "react-helmet-async";
import styles from "../UserSettings.module.css";
import {
    useEffect,
    useState,
} from "react";
import TextField from "@mui/material/TextField";
import "react-datepicker/dist/react-datepicker.css";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
    Link,
    Checkbox,
} from "@mui/material";
import axiosInstance from "src/axiosInstance"
import CircularProgress from "@mui/material/CircularProgress";
import { notifySuccess, notifyError } from "src/hooks/useMockData";
// sections
// mock
// ----------------------------------------------------------------------

export default function TeamTab() {
    // eslint-disable-next-line no-unused-vars
    const apiUrl = process.env.REACT_APP_NAME;
    const [declineCBDialog, setDeclineCBDialog] = useState(false);
    const navigate = useNavigate();
    const [isDataAvl, setIsDataAvl] = useState(false);
    const [save, setSave] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [Email, setEmail] = useState("");
    const [Description, setDescription] = useState("");
    const [RoleName, setRoleName] = useState("");
    const [roleCategories, setRoleCategories] = useState([]);
    const [isRoleLoading, setIsRoleLoading] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const tabValue = JSON.parse(localStorage.getItem('currentTab'))

    const handleChangeSave = () => {
        setSave(!save);
    }

    const handleToggleDropdown = () => {
        setToggleDropdown(!toggleDropdown);
    };

    const handleCheckboxChange = (id) => {
        setSelectedIds(prevSelectedIds =>
          prevSelectedIds.includes(id)
            ? prevSelectedIds.filter(selectedId => selectedId !== id)
            : [...prevSelectedIds, id]
        );
      };

      useEffect(() => {
        // console.log(selectedIds);
      }, [selectedIds])
    
    const handleSubmitEvidence = async () => {
        if(Email === '' || RoleName === '' || Description === '') {
            notifyError('All fields are required')
            return;
        }
        setIsLoading(true)
        let filter_payload = {}
        if(Email !== '') {
            filter_payload.email = Email
        }
        if(RoleName !== '') {
            filter_payload.role_name = RoleName
        }
        if(Description !== '') {
            filter_payload.description = Description
        }
        if(selectedIds?.length) {
            filter_payload.permissions = selectedIds
        }
        try {
            const response = await axiosInstance.post(`/user-account/invite?productName=${apiUrl}`, filter_payload)
            notifySuccess(response.data.message)
            navigate(`/app/user-settings`);

        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsLoading(false)
        }
    }

    const getRoles = async () => {
        setIsRoleLoading(true)
        try {
            const response = await axiosInstance.get(`/role/all-roles-with-permissions?productName=${apiUrl}`)
            const data = response.data.data
            setRoleCategories(data?.data)
        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsRoleLoading(false)
        }
    }

    useEffect(() => {
        getRoles()
    }, [])

    useEffect(() => {
        // const groupedPermissions = groupPermissions(el?.permissions || []);
    }, [roleCategories])

    const groupPermissions = (permissions) => {
        const grouped = {};
        const settingsPermissions = [];
      
        permissions.forEach(permission => {
          if (permission.name.includes('wallet') || permission.name.includes('withdraw') || permission.name.includes('convert') || permission.name.includes('transaction')) {
            const key = 'Wallet Permission';
            if (!grouped[key]) grouped[key] = [];
            grouped[key].push(permission);
          } else if (permission.name.includes('transfer')) {
            const key = 'Transfer Permission';
            if (!grouped[key]) grouped[key] = [];
            grouped[key].push(permission);
          } else if (permission.name.includes('payment')) {
            const key = 'Payment Links Permission';
            if (!grouped[key]) grouped[key] = [];
            grouped[key].push(permission);
          } else if (permission.name.includes('chargeback')) {
            const key = 'Dispute Permission';
            if (!grouped[key]) grouped[key] = [];
            grouped[key].push(permission);
          } else if (permission.name.includes('customer')) {
            const key = 'Customer Permission';
            if (!grouped[key]) grouped[key] = [];
            grouped[key].push(permission);
          } else if (permission.name.includes('invoice')) {
            const key = 'Invoice Permission';
            if (!grouped[key]) grouped[key] = [];
            grouped[key].push(permission);
          } else if (permission.name.includes('ledger') || permission.name.includes('entries')) {
            const key = 'Ledger Permission';
            if (!grouped[key]) grouped[key] = [];
            grouped[key].push(permission);
          } else {
            settingsPermissions.push(permission);
          }
        });
      
        if (settingsPermissions.length > 0) {
          grouped['Settings Permission'] = settingsPermissions;
        }
      
        return grouped;
      };

    return (
        <>
            <Helmet>
                <title> Custom Roles </title>
            </Helmet>
            <div className="wrapper  pb-5">
                <div className={`${styles.header_wrap} d-sm-flex justify-content-between align-items-center`}>
                    <div className="d-flex">
                        <Link
                            className="d-block text-secondary"
                            to={`/app/user-settings/invite-team`}
                            component={RouterLink}
                        >
                            <i
                                className="bi bi-x"
                                style={{ fontSize: "1.5em", lineHeight: "1" }}
                            ></i>
                        </Link>
                        <h6 className="pl-2 pl-sm-3 mb-0 fw-700 pb-0" style={{ lineHeight: '1.5' }} >Invite Team</h6>
                        {/* <h6 className="pl-2 pl-sm-3 mb-0 fw-700 pb-0" style={{ lineHeight: '1.5' }} >Custom Role</h6> */}
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-5">
                        <div
                            style={{ width: '24em', maxWidth: '100%' }}
                            className="d-flex flex-column justify-content-start align-items-center input_fullWidth">
                            <div className="w-100">
                                <h6>
                                    <span className="text-dark fw-700" style={{ fontSize: '20px' }}>Invite Team Member</span>
                                </h6>
                                <p style={{ fontWeight: '300', color: 'rgba(75, 85, 99, 1)', width: '28em', maxWidth: '100%' }}>
                                    <small className="fs-12">Enter names and emails for some team members to invite them to join your organisation on OnePoint.</small>
                                </p>
                            </div>
                            <div className="mb-3" style={{ width: "100%" }}>
                                <label htmlFor="invite_email" className="fs-14">Email</label>
                                <div className="">
                                    <TextField
                                        name="invite_email"
                                        required
                                        type="email"
                                        id="invite_email"
                                        value={Email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        size="small"
                                        placeholder=""
                                        fullWidth
                                    />
                                </div>
                            </div>

                            <div className="mb-3 d-flex flex-column w-100">
                                <div className="d-flex mb-2 justify-content-between align-items-center">
                                    <label htmlFor="role_name" className="mb-0">Role Name</label>
                                </div>
                                <div className="position-relative">
                                    <TextField
                                        name="role_name"
                                        required
                                        type="text"
                                        id="role_name"
                                        value={RoleName}
                                        onChange={(e) => setRoleName(e.target.value)}
                                        size="small"
                                        placeholder=""
                                        fullWidth
                                    />
                                </div>
                            </div>

                            <div className="mb-3 d-flex flex-column w-100">
                                <div className="d-flex mb-2 justify-content-between align-items-center">
                                    <label htmlFor="role_name" className="mb-0">Description</label>
                                </div>
                                <div className="position-relative">
                                    <TextField
                                        name="role_name"
                                        required
                                        type="text"
                                        id="role_name"
                                        value={Description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        size="small"
                                        placeholder=""
                                        fullWidth
                                    />
                                </div>
                            </div>

                            <div className="w-100 mt-3">
                                <button
                                    className="btn border btn-primary d-block"
                                    onClick={handleSubmitEvidence}
                                    style={{ padding: '8px 20px' }}
                                    disabled={isLoading}
                                >
                                    <span className="text-white">
                                        Send Invite
                                        {
                                            isLoading &&
                                            <CircularProgress
                                                style={{ color: "#fff" }}
                                                className="text-white ml-1"
                                                size="0.8rem"
                                            />
                                        }
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 mt-4 mt-md-0">
                        <div className=" px-4 py-4"
                            style={{ width: '37em', maxWidth: '100%', background: 'rgb(249,250,251)' }}
                        >
                            <label htmlFor="" className="fs-16 fw-700 d-block mb-4 pb-2 text-dark mt-5 mt-md-0">What the role have access to</label>

                            <div className="w-100 pl-4 pl-lg-3 pr-4">
                                <div className="row">
                                    {(!isRoleLoading && roleCategories?.length > 0) &&
                                    roleCategories.map((el, i) => {
                                        const groupedPermissions = groupPermissions(el?.permissions || []);

                                        return (
                                        <React.Fragment key={i}>
                                            {Object.keys(groupedPermissions).map((group, a) => (
                                            <div key={a} className="col-md-6 px-0 mb-3">
                                                <label htmlFor="" className="fs-14 d-block text-dark">{group}</label>
                                                {groupedPermissions[group].map((item, index) => (
                                                    <div key={index} className="checkbox_div light d-flex align-items-center mb-1">
                                                        <div  className="position-relative d-flex justify-content-center align-items-center inline">
                                                            <Checkbox
                                                                id="schedule"
                                                                checked={selectedIds.includes(item.id)}
                                                                onChange={() => handleCheckboxChange(item.id)}
                                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            />
                                                            <svg className="alt" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g opacity="0.5">
                                                                    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#111827" />
                                                                    {selectedIds.includes(item.id) &&
                                                                        <path d="M12.2064 4.79279C12.3939 4.98031 12.4992 5.23462 12.4992 5.49979C12.4992 5.76495 12.3939 6.01926 12.2064 6.20679L7.20643 11.2068C7.0189 11.3943 6.76459 11.4996 6.49943 11.4996C6.23427 11.4996 5.97996 11.3943 5.79243 11.2068L3.79243 9.20679C3.61027 9.01818 3.50948 8.76558 3.51176 8.50339C3.51403 8.24119 3.6192 7.99038 3.80461 7.80497C3.99002 7.61956 4.24083 7.51439 4.50303 7.51211C4.76523 7.50983 5.01783 7.61063 5.20643 7.79279L6.49943 9.08579L10.7924 4.79279C10.98 4.60532 11.2343 4.5 11.4994 4.5C11.7646 4.5 12.0189 4.60532 12.2064 4.79279Z" fill="#111827" />
                                                                    }
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <label htmlFor="schedule" className="ml-2 fs-13 cursor-pointer mb-0 fw-400">{item.name}</label>
                                                    </div>
                                                ))}
                                            </div>
                                            ))}
                                        </React.Fragment>
                                        );
                                    })}

                                </div>
                            </div>
                            {isRoleLoading &&
                                <>
                                    <div className="d-flex justify-content-center mt-3">
                                        <CircularProgress
                                            style={{ color: "#fff" }}
                                            className="text-white ml-1"
                                            size="1.3rem"
                                        />
                                    </div>
                                    <br />
                                </>
                            }
                            {
                                (!isRoleLoading && !roleCategories?.length) &&
                                <div className="alerts alerts-primary text-center mt-4 mb-4">
                                    <h6>No record found</h6>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}