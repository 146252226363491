// ----------------------------------------------------------------------
import { Helmet } from "react-helmet-async";
import styles from "./Messaging.module.css";
import {
    // useEffect,
    useState,
} from "react";
import React from "react";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import SendMessageTable from './components/SendMessageTable';
import "react-datepicker/dist/react-datepicker.css";

import { Link as RouterLink } from "react-router-dom";

import {
    Link,
} from "@mui/material";
// import Scrollbar from "../../components/scrollbar";
// sections
// mock
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// ----------------------------------------------------------------------

export default function SendMessage() {
    // eslint-disable-next-line no-unused-vars
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [toggleDropdown, setToggleDropdown] = useState(false);

    const handleToggleDropdown = () => {
        setToggleDropdown(!toggleDropdown);
    };

    return (
        <>
            <Helmet>
                <title>Messaging - Send Mesage</title>
            </Helmet>
            <div className="px-0 px-md-2 mt-3">
                <div className="d-sm-flex justify-content-between align-items-center">
                    <div className="">
                        <h5 className="page_title">Send Mesage</h5>
                    </div>
                    <div className="header_btn_group">
                        <div className="btn-group">
                            <div
                                onClick={() => handleToggleDropdown()}
                                className="btn border rounded"
                            >
                                <svg
                                    width="15"
                                    height="16"
                                    viewBox="0 0 15 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3.375 1.8125H11.625C12.3125 1.8125 12.875 2.375 12.875 3.0625V4.4375C12.875 4.9375 12.5625 5.5625 12.25 5.875L9.5625 8.25C9.1875 8.5625 8.9375 9.1875 8.9375 9.6875V12.375C8.9375 12.75 8.6875 13.25 8.375 13.4375L7.5 14C6.6875 14.5 5.5625 13.9375 5.5625 12.9375V9.625C5.5625 9.1875 5.3125 8.625 5.0625 8.3125L2.6875 5.8125C2.375 5.5 2.125 4.9375 2.125 4.5625V3.125C2.125 2.375 2.6875 1.8125 3.375 1.8125Z"
                                        stroke="#374151"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M6.83125 1.8125L3.75 6.75"
                                        stroke="#374151"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>{" "}
                                <span className="">Filter</span>
                            </div>
                            <div
                                className={`${toggleDropdown ? "show" : ""
                                    } dropdown-menu dropdown-menu-right ${styles.drp_dwn_menu_right_send} drp_dwn_menu_right`}
                                style={{ transform: "translate3d(-170px, 40px, 0px)" }}
                            >
                                <div className={`${styles.dropped_details_wrap} px-4 py-3`}>
                                    <div className="row">
                                        <div className="col-12 mb-1">
                                            <label htmlFor="ounit">Custom Date Range</label>

                                            <div className="start_enddate d-flex">
                                                <div className="" style={{ width: "100%" }}>
                                                    <div
                                                        className="mb-3"
                                                        style={{ position: "relative" }}
                                                    >
                                                        <span
                                                            style={{
                                                                position: "absolute",
                                                                right: "25px",
                                                                top: "5px",
                                                                zIndex: "10",
                                                            }}
                                                        >
                                                            <svg
                                                                width="14"
                                                                height="14"
                                                                viewBox="0 0 14 14"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M2.40527 4.29523C2.40527 3.38641 3.14202 2.64966 4.05084 2.64966H10.6331C11.5419 2.64966 12.2787 3.3864 12.2787 4.29523V10.8775C12.2787 11.7863 11.5419 12.5231 10.6331 12.5231H4.05084C3.14202 12.5231 2.40527 11.7863 2.40527 10.8775V4.29523Z"
                                                                    stroke="#7B8794"
                                                                    strokeWidth="1.64557"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M9.53516 1.55298V3.74707"
                                                                    stroke="#7B8794"
                                                                    strokeWidth="1.64557"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M5.14844 1.55298V3.74707"
                                                                    stroke="#7B8794"
                                                                    strokeWidth="1.64557"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M2.40527 5.94092H12.2787"
                                                                    stroke="#7B8794"
                                                                    strokeWidth="1.64557"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </span>
                                                        <DatePicker
                                                            // showIcon
                                                            showYearDropdown
                                                            scrollableYearDropdown
                                                            yearDropdownItemNumber={15}
                                                            selected={startDate}
                                                            onChange={(date) => setStartDate(date)}
                                                            dateFormat="dd/MM/yyyy"
                                                            className="form-control"
                                                            placeholderText="Start date"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="px-2 d-flex align-items-center "
                                                    style={{ height: "40px" }}
                                                >
                                                    <img
                                                        src="/assets/icons/line.svg"
                                                        style={{ width: "30px" }}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="" style={{ width: "100%" }}>
                                                    <div
                                                        className="mb-3"
                                                        style={{ position: "relative" }}
                                                    >
                                                        <span
                                                            style={{
                                                                position: "absolute",
                                                                right: "25px",
                                                                top: "5px",
                                                                zIndex: "10",
                                                            }}
                                                        >
                                                            <svg
                                                                width="14"
                                                                height="14"
                                                                viewBox="0 0 14 14"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M2.40527 4.29523C2.40527 3.38641 3.14202 2.64966 4.05084 2.64966H10.6331C11.5419 2.64966 12.2787 3.3864 12.2787 4.29523V10.8775C12.2787 11.7863 11.5419 12.5231 10.6331 12.5231H4.05084C3.14202 12.5231 2.40527 11.7863 2.40527 10.8775V4.29523Z"
                                                                    stroke="#7B8794"
                                                                    strokeWidth="1.64557"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M9.53516 1.55298V3.74707"
                                                                    stroke="#7B8794"
                                                                    strokeWidth="1.64557"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M5.14844 1.55298V3.74707"
                                                                    stroke="#7B8794"
                                                                    strokeWidth="1.64557"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M2.40527 5.94092H12.2787"
                                                                    stroke="#7B8794"
                                                                    strokeWidth="1.64557"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </span>
                                                        <DatePicker
                                                            // showIcon
                                                            showYearDropdown
                                                            scrollableYearDropdown
                                                            yearDropdownItemNumber={15}
                                                            selected={endDate}
                                                            onChange={(date) => setEndDate(date)}
                                                            dateFormat="dd/MM/yyyy"
                                                            className="form-control"
                                                            placeholderText="End date"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="name">Status</label>
                                                <Select
                                                    labelId="s"
                                                    id="s"
                                                    fullWidth
                                                    size="small"
                                                    required
                                                    name="verified"
                                                    placeholder="Select"
                                                    defaultValue="select"
                                                >
                                                    <MenuItem value="select">Select Status</MenuItem>
                                                    <MenuItem value="pending">Pending</MenuItem>
                                                    <MenuItem value="verified">Verified</MenuItem>
                                                </Select>
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="name">Currency</label>
                                                <Select
                                                    labelId="s"
                                                    id="s"
                                                    fullWidth
                                                    size="small"
                                                    required
                                                    name="verified"
                                                    defaultValue="select"
                                                >
                                                    <MenuItem value="select">USD</MenuItem>
                                                    <MenuItem value="142282">NGN</MenuItem>
                                                    <MenuItem value="182282">EUR</MenuItem>
                                                    <MenuItem value="228828">CED</MenuItem>
                                                </Select>
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="ounit">RRN</label>
                                                <TextField
                                                    name="min_products"
                                                    required
                                                    type="number"
                                                    fullWidth
                                                    id="ounit"
                                                    placeholder=""
                                                    size="small"
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="ounit">Amount</label>
                                                <div className="position-relative box_with_dollarsign">
                                                    <span
                                                        className="position-absolute"
                                                        style={{
                                                            zIndex: "10",
                                                            top: "50%",
                                                            left: "15px",
                                                            transform: "translateY(-50%)",
                                                        }}
                                                    >
                                                        $
                                                    </span>
                                                    <TextField
                                                        name="min_products"
                                                        required
                                                        type="number"
                                                        fullWidth
                                                        id="ounit"
                                                        placeholder=""
                                                        size="small"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <div className="ml-auto" style={{ width: "fit-content" }}>
                                            <button
                                                type="button"
                                                className={`btn btn-sm border`}
                                                onClick={() => setToggleDropdown(false)}
                                            >
                                                Clear
                                            </button>
                                            &nbsp;&nbsp;
                                            <button
                                                type="button"
                                                className={`btn btn-sm btn-primary`}
                                                onClick={() => setToggleDropdown(false)}
                                            >
                                                Apply Filter
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        &nbsp;&nbsp;
                        <div className="btn border">
                            <svg
                                width="15"
                                height="16"
                                viewBox="0 0 15 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.875 11.125V13C1.875 13.6904 2.43464 14.25 3.125 14.25H11.875C12.5654 14.25 13.125 13.6904 13.125 13V11.125"
                                    stroke="#374151"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M5 8L7.5 10.5L10 8"
                                    stroke="#374151"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M7.5 1.75V10.5"
                                    stroke="#374151"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>{" "}
                            <span className="">Export</span>
                        </div>
                        &nbsp;&nbsp;
                        <div className="btn-group">
                            <div
                                className="btn border btn-primary"
                                data-toggle="dropdown"
                                aria-expanded="false"
                            >
                                {/* onClick={() => setAddCustomer(true)} */}
                                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.75 8H11.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.5 11.75V4.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                <span className="button_text text-white">Send Message</span>
                            </div>
                            <div
                                className="dropdown-menu rounded border-0"
                                style={{
                                    background: "#F9FAFB",
                                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                <Link
                                    className="dropdown-item dropdown_text"
                                    to="/app/messaging/single-messaging"
                                    component={RouterLink}
                                >
                                    Single Messaging
                                </Link>
                                <Link
                                    className="dropdown-item dropdown_text"
                                    to="/app/messaging/bulk-messaging"
                                    component={RouterLink}
                                >
                                    Bulk Messaging
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <Box
                    style={{ padding: "0" }}
                    className="aa tab_wrapper pt-4"
                    sx={{ width: "100%", typography: "body1" }}
                >
                    <SendMessageTable />
                </Box>
            </div>
        </>
    );
}
