import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import LiveSelect from "react-select";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import bus from "src/bus"
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import Preloader from 'src/utils/page_preloader';

import {
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    Link
} from '@mui/material';
import styles from "../Payment/Payment.module.css";
import VerifyYourBiz from "./VerifyYourBusiness";
import BusinessRepresentative from "./BusinessRep";
import SetupAccount from "./SetupAccount";
import DocumentUpload from "./DocumentUpload";
import SecureAccount from './SecureAccount';
import CircularProgress from "@mui/material/CircularProgress";
import axiosInstance from "src/axiosInstance"

const steps = [
    'Verify your business',
    'Document Upload',
    'Business representative',
    'Secure Account',
    'Set up account',
];

const GetStepContent = (step, isObjUpdated, HasSetPassword, props) => {

    switch (step) {
        case 0:
            return (
                <>
                    <div className='' style={{
                        width: '25em',
                        maxWidth: '100%'
                    }}>
                        <div className="top">
                            <h4 className='fw-600 text-dark tell_us_more'>Tell us more about your business</h4>
                            <p className="mb-0 pt-1 pb-2">
                                <span className='fw-400 fs-13'>
                                    As a regulated financial services company, we would need to verify your identification and business registration information.
                                </span>
                            </p>
                        </div>
                        <div className="w-100 remove_calc_height position-relative" style={{ height: '55vh', overflowY: 'auto' }}>

                            <VerifyYourBiz {...props} />
                        </div>
                    </div>
                </>
            );
        case 1:
            return (
                <>
                    <div className='' style={{
                        width: '25em',
                        maxWidth: '100%'
                    }}>
                        <div className="top">
                            <h4 className='fw-600 text-dark tell_us_more'>Upload business document</h4>
                            <p className="mb-0 pt-1 pb-2">
                                <span className='fw-400 fs-13'>
                                    Upload your company's documentation to be able to process unlimited payments on your dashboard.                                </span>
                            </p>
                        </div>
                        <div className="w-100 remove_calc_height" style={{ height: '55vh', overflowY: 'auto' }}>
                            <DocumentUpload {...props} />
                        </div>
                    </div>
                </>
            );
        case 2:
            return (
                <>
                    <div className='' style={{
                        width: '25em',
                        maxWidth: '100%'
                    }}>
                        <div className="top">
                            <h4 className='fw-600 text-dark tell_us_more'>Verify your personal details</h4>
                            <p className="mb-0 pt-1 pb-2">
                                <span className='fw-400 fs-13'>
                                    This account should be activated by someone authorized to sign on your organization’s behalf. If that’s not you, please ask the right person to complete this form.
                                </span>
                            </p>
                        </div>
                        <div className="w-100 remove_calc_height" style={{ height: '52vh', overflowY: 'auto' }}>
                            <BusinessRepresentative {...props} />
                        </div>
                    </div>
                </>
            );
        case 3:
            return (
                <>
                    <div className='' style={{
                        width: '25em',
                        maxWidth: '100%'
                    }}>
                        <div className="top">
                            <h4 className='fw-600 text-dark tell_us_more'>{HasSetPassword === "YES" ? 'Update transaction pin and login password' : 'Set transaction pin and login password'}</h4>
                            <p className="mb-0 pt-1 pb-2">
                                <span className='fw-400 fs-13'>
                                    To ensure the security of your account and transactions, enter a 4 digit transaction pin and choose a password that is at least 8-12 characters long.
                                </span>
                            </p>
                        </div>
                        <div className="w-100 remove_calc_height" style={{ height: '48vh', overflowY: 'auto' }}>
                            <SecureAccount {...props} />
                        </div>
                    </div>
                </>
            );
        case 4:
        case 5:
            return (
                <>
                    <div className='' style={{
                        width: '25em',
                        maxWidth: '100%'
                    }}>
                        <div className="w-100 remove_calc_height" style={{ height: '69vh', overflowY: 'auto' }}>
                            <div className="top">
                                <h4 className='fw-600 text-dark tell_us_more'>Add your bank to receive payouts</h4>
                                <p className="mb-0 pt-1 pb-2">
                                    <span className='fw-400 fs-13'>
                                        A payout is the transfer of funds from Onepoint to your bank account. Link your account to seamlessly receive payouts and help us better understand your business.
                                    </span>
                                </p>
                            </div>
                            <SetupAccount {...props} />
                        </div>
                    </div>
                </>
            );
        default:
            return 'Unknown step';
    }
};

export default function APIC() {
    const [activeStep, setActiveStep] = useState(0);
    const { width, height } = useWindowSize();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isObjUpdated, setIsObjUpdated] = useState(false);
    const apiUrl = process.env.REACT_APP_NAME;
    const [responseStatus, setResponseStatus] = useState(null);
    const [HasSetPassword, setHasSetPassword] = useState(null);
    const [KYCResponseObj, setKYCResponseObj] = useState(JSON.parse(localStorage.getItem('userDetails')));
    const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('userDetails')));
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (responseStatus === 'success') {
            // console.log('activeStep', activeStep, 'step length', steps.length);
            if (activeStep !== steps.length) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
            setResponseStatus(null); // Reset response status
            setTimeout(() => {
                if (!isLoading) {
                    handleFetch();
                }
            }, 1000);
        } else if (responseStatus === 'error') {
            setActiveStep((prevActiveStep) => prevActiveStep);
            setResponseStatus(null); // Reset response status
        }
    }, [responseStatus, activeStep]);


    useEffect(() => {
        if ((userDetails !== null) && (userDetails.account.business_rep && userDetails.account.docs_upload && userDetails.account.secure_account && userDetails.account.setup_account && userDetails.account.verify_business)) {
            localStorage.setItem('awaiting_kyc_verification', JSON.stringify(true))
            navigate('/app/overview');
        }

        if (userDetails !== null && userDetails.account.verify_business === true) {
            setActiveStep(1);
        } else {
            return;
        }
        if (userDetails !== null && userDetails.account.docs_upload === true) {
            setActiveStep(2);
        } else {
            return;
        }
        if (userDetails !== null && userDetails.account.business_rep === true) {
            setActiveStep(3);
        } else {
            return;
        }
        if (userDetails !== null && userDetails.account.secure_account === true) {
            setActiveStep(4);
        } else {
            return;
        }
        if (userDetails !== null && userDetails.account.setup_account === true) {
            setActiveStep(5);
        }

        // if (userDetails && userDetails.data.business_rep && userDetails.data.docs_upload && userDetails.data.secure_account && userDetails.data.setup_account) {

        const moveToNextStep = (val) => {
            if (val) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        };

        bus.on("type_XXXX", moveToNextStep);

        return () => {
            bus.off("type_XXXX", moveToNextStep);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (count < 10) {
                setCount(prevCount => prevCount + 1);

                setKYCResponseObj(JSON.parse(localStorage.getItem('userDetails')));
                setUserDetails(JSON.parse(localStorage.getItem('userDetails')));

            } else {
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, [count, isLoading]);

    const handleNext = (active) => {
        if (active === 0) {
            bus.emit('submit_verifyYourBiz', true);
        } else if (active === 1) {
            bus.emit('submit_docUpload', true);
        } else if (active === 2) {
            bus.emit('submit_bizRep', true);
        } else if (active === 3) {
            bus.emit('submit_secureAcct', true);
        } else if (active === 4) {
            bus.emit('submit_setupAcct', true);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);

        setTimeout(() => {
            if (!isLoading) {
                handleFetch();
            }
        }, 500);
    };

    const handleFetch = async () => {
        setIsObjUpdated(true)
        try {
            const response = await axiosInstance.get(`/accounts/user?productName=${apiUrl}`);
            const data = response.data.data;
            localStorage.setItem('userDetails', JSON.stringify(data))

            if (response.data.status === 'success') {
                if (KYCResponseObj !== null) {
                    // setKYCResponseObj(null);
                    setHasSetPassword(null)
                    setKYCResponseObj(data);
                    setHasSetPassword(data.data.has_set_password)
                } else {
                    setKYCResponseObj(data);
                    setHasSetPassword(data.data.has_set_password)
                }

                if (data && data.account) {
                    if (data.account.business_rep &&
                        data.account.docs_upload &&
                        data.account.secure_account &&
                        data.account.setup_account &&
                        data.account.verify_business) {
                        localStorage.setItem('awaiting_kyc_verification', JSON.stringify(true))
                    } else {
                        localStorage.setItem('awaiting_kyc_verification', JSON.stringify(false))
                        localStorage.setItem('kyc_status', JSON.stringify(data?.account?.kyc_status))
                    }
                    if (data.account.kyc_status !== null) {
                        localStorage.setItem('kyc_status', JSON.stringify(data?.account?.kyc_status))
                    }
                }

                const auth = JSON.parse(localStorage.getItem('auth'));
                auth.businessName = data.account?.name;
                localStorage.setItem('auth', JSON.stringify(auth));
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsObjUpdated(false);
        }
    }

    useEffect(() => {
        if (activeStep === steps.length) {
            handleFetch();
            localStorage.setItem('awaiting_kyc_verification', JSON.stringify(true))
            notifySuccess('KYC Completed Successfully!');

            setTimeout(() => {
                navigate('/app/overview');
            }, 2000);
        }
    }, [activeStep])

    useEffect(() => {
        if (userDetails) {
            setKYCResponseObj(userDetails);
        }

        handleFetch();
    }, [])

    const handleSetActiveStep = async (val) => {

        setKYCResponseObj(JSON.parse(localStorage.getItem('userDetails')));
        setUserDetails(JSON.parse(localStorage.getItem('userDetails')));

        // console.log("userDetails:", userDetails, userDetails.account, userDetails.account.verify_business);

        // console.log('statuses:', userDetails, userDetails?.account?.verify_business);
        if (val === 'Verify your business' &&
            userDetails !== null && userDetails?.account?.verify_business === true) {
            setActiveStep(0)
        }
        if (val === 'Document Upload' &&
            userDetails !== null && userDetails?.account?.verify_business === true &&
            (userDetails !== null && userDetails?.account?.docs_upload === true ||
                userDetails !== null && userDetails?.account?.docs_upload === false)) {
            setActiveStep(1)
        }
        if (val === 'Business representative' &&
            userDetails !== null && userDetails?.account?.docs_upload === true &&
            (userDetails !== null && userDetails?.account?.business_rep === true ||
                userDetails !== null && userDetails?.account?.business_rep === false)) {
            setActiveStep(2)
        }
        if (val === 'Secure Account' &&
            userDetails !== null && userDetails?.account?.business_rep === true &&
            (userDetails !== null && userDetails?.account?.secure_account === true ||
                userDetails !== null && userDetails?.account?.secure_account === false)) {
            setActiveStep(3)
        }
        if (val === 'Set up account' &&
            userDetails !== null && userDetails?.account?.secure_account === true &&
            (userDetails !== null && userDetails?.account?.setup_account === true ||
                userDetails !== null && userDetails?.account?.setup_account === false)) {
            setActiveStep(4)
        }
        handleFetch();
    }


    return (
        <>
            <Helmet>
                <title> Complete KYC </title>
            </Helmet>
            {/* && (userDetails !== null) && (userDetails.account.business_rep && userDetails.account.docs_upload && userDetails.account.secure_account && userDetails.account.setup_account && userDetails.account.verify_business))  */}
            {/* {activeStep === steps.length ? (
                <>
                    <div
                        className="w-100 d-flex justify-content-center align-items-center py-5"
                        style={{ minHeight: '100vh' }}>
                        <Confetti width={width} height={height} recycle={false} />

                        <div style={{ width: '34em', maxWidth: '95%', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)', }} className='px-5 py-5 text-center'>
                            <div className="fit-content mx-auto mb-4 pt-5">
                                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="62" height="62" rx="31" fill="#111827" />
                                    <path d="M21.958 32.2916L27.1247 37.4583L40.0413 24.5416" stroke="#F9FAFB" stroke-width="2.58333" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                                <img src="/assets/kyc_completed_checked.png" alt="" width={60} />
                            </div>

                            <p className='fs-24 fw-600'>KYC Completed!</p>
                            <p className='text-center mx-auto' style={{ width: '23em', maxWidth: '100%', }}>
                                <span className='fs-18 fw-500' style={{ color: 'rgba(107, 114, 128, 1)' }}>
                                    Congratulations, you are ready to use
                                    OnePoint, kindly wait while we verify your information
                                </span>
                            </p>

                            <div className="w-100 pb-4">
                                <button className='btn d-block mx-auto mt-4 px-3 ' onClick={handleProceed}>
                                    <span className=' fs-20 fw-600' style={{ color: 'rgba(96, 165, 250, 1)' }}>
                                        Navigate Onepoint
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                </>
            )} */}
            <div className="wrapper pt-5 mx-auto" style={{ width: '1200px', maxWidth: '95%' }}>
                <div className={`${styles.header_wrap} d-sm-flex justify-content-between align-items-center`}>
                    <div className="d-flex">
                        <Link
                            className="d-block text-secondary"
                            to="/app/overview"
                            component={RouterLink}
                        >
                            <i
                                className="bi bi-x"
                                style={{ fontSize: "1.5em", lineHeight: "1" }}
                            ></i>
                        </Link>
                        <h6 className="pl-2 pl-sm-3 mb-0 fw-700 pb-0" style={{ lineHeight: '1.5' }} >Activate your account</h6>
                    </div>
                </div>

                <div className="pb-5 pt-5 pb-md-0 mt-2">

                    <Box className="stepper_wrapper" sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', }}>
                        <Stepper activeStep={activeStep} className='pl-md-5'>
                            {steps.map((label) => (
                                <Step key={label} onClick={() => handleSetActiveStep(label)} className='cursor-pointer'>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Box sx={{ mt: 0, mb: 1 }} className=" w-100">
                            <div className='pl-md-5 ml-md-3 w-100 d-flex align-items-start'>
                                {/* {activeStep === steps.length ? (
                                            <Typography>All steps completed</Typography>
                                        ) : (
                                            <> */}
                                {KYCResponseObj !== null &&
                                    <>
                                        <Box className="position-relative" style={{
                                            width: '25em',
                                            maxWidth: '100%'
                                        }}>
                                            <div className="position-relative">
                                                {/* {isObjUpdated &&
                                                                <Preloader />
                                                            } */}
                                                {/* {GetStepContent(activeStep)} */}

                                                {GetStepContent(activeStep, isObjUpdated, HasSetPassword, { isLoading, setIsLoading, responseStatus, setResponseStatus, KYCResponseObj })}
                                            </div>

                                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                {activeStep !== 0 &&
                                                    <Button
                                                        color="inherit"
                                                        disabled={activeStep === 0}
                                                        onClick={handleBack}
                                                        sx={{ mr: 1 }}
                                                        className='d-block w-100 btn-primary fw-500'
                                                    >
                                                        Previous
                                                    </Button>
                                                }
                                                <Box sx={{ flex: '1 1 auto' }} />
                                                <Button onClick={() => handleNext(activeStep)}
                                                    className='d-block w-100 btn-primary fw-500 py-2'
                                                >
                                                    {activeStep === steps.length - 1 ? 'Complete KYC' : 'Continue'}
                                                    {isLoading && <span style={{ transform: 'translateY(1px)' }} className="ml-1 white_circular_prog">
                                                        <CircularProgress
                                                            style={{ stroke: "#fff" }}
                                                            className="text-white ml-1 position-relative"
                                                            size="0.8rem"
                                                        />
                                                    </span>}
                                                </Button>
                                            </Box>
                                        </Box>
                                        {activeStep === 1 &&
                                            <div className={`${styles.requirement_wrap} pb-2 ml-md-3 ml-lg-5`} style={{ width: '23em', maxWidth: '100%', marginTop: '135px', lineHeight: '1.6' }}>
                                                <ul className={`${styles.requirement_ul}`}>
                                                    <li>The scanned copy of your document should show the edges, be clear and have readable content.</li>
                                                    <li>
                                                        Do not scan or upload documents that are laminated.
                                                    </li>
                                                    <li>
                                                        The document must be in a PDF format.
                                                    </li>
                                                    <li>
                                                        The maximum recommended size for the file upload is 2 megabytes.
                                                    </li>
                                                    {/* <li>
                                                                If a document has more than 1 page, it should be scanned together as a multi-page PDF file.
                                                            </li> */}
                                                </ul>
                                            </div>
                                        }
                                    </>
                                }
                                {KYCResponseObj === null &&
                                    (
                                        <>
                                            <Box className="" style={{
                                                width: '25em',
                                                maxWidth: '100%'
                                            }}>
                                                {/* {GetStepContent(activeStep)} */}

                                                {GetStepContent(activeStep, isObjUpdated, HasSetPassword, { isLoading, setIsLoading, responseStatus, setResponseStatus })}
                                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                    {activeStep !== 0 &&
                                                        <Button
                                                            color="inherit"
                                                            disabled={activeStep === 0}
                                                            onClick={handleBack}
                                                            sx={{ mr: 1 }}
                                                            className='d-block w-100 btn-primary fw-500'
                                                        >
                                                            Previous
                                                        </Button>
                                                    }
                                                    <Box sx={{ flex: '1 1 auto' }} />
                                                    <Button onClick={() => handleNext(activeStep)}
                                                        className='d-block w-100 btn-primary fw-500 py-2'
                                                    >
                                                        {activeStep === steps.length - 1 ? 'Complete KYC' : 'Continue'}
                                                        {isLoading && <span style={{ transform: 'translateY(1px)' }} className="ml-1 white_circular_prog">
                                                            <CircularProgress
                                                                style={{ stroke: "#fff" }}
                                                                className="text-white ml-1 position-relative"
                                                                size="0.8rem"
                                                            />
                                                        </span>}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </>
                                    )}
                                {/* <div className="pl-md-4" style={{ width: '220px', maxWidth: '100%', marginTop: '135px', lineHeight: '1' }}>
                                            <span className='fw-400 fs-12'>
                                                Please provide the legal name of your business as stated in your business registration document
                                            </span>
                                        </div> */}
                                {/* </>
                                        )} */}
                            </div>
                        </Box>
                    </Box>
                </div>
            </div>

        </>
    );
}
