// ----------------------------------------------------------------------
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import styles from "../UserSettings.module.css";
import {
    // useEffect,
    useState,
} from "react";
import TextField from "@mui/material/TextField";
import "react-datepicker/dist/react-datepicker.css";

import Box from "@mui/material/Box";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import LiveSelect from "react-select";

import { teamTabRoles } from "src/hooks/useMockData";
import {
    Link,
} from "@mui/material";

import axiosInstance from "src/axiosInstance"
import CircularProgress from "@mui/material/CircularProgress";
import { notifySuccess, notifyError } from "src/hooks/useMockData";
// sections
// mock
// ----------------------------------------------------------------------

export default function TeamTab() {
    // eslint-disable-next-line no-unused-vars
    const apiUrl = process.env.REACT_APP_NAME;
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [isDataAvl, setIsDataAvl] = useState(false);
    const [save, setSave] = useState(true);
    const [email, setEmail] = useState('');
    const [TeamRole, setTeamRole] = useState("select");
    const [isRoleLoading, setIsRoleLoading] = useState(false);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [toggleShowRoles01, setToggleShowRoles01] = useState(false);
    const [toggleShowRoles02, setToggleShowRoles02] = useState(false);
    const [toggleShowRoles03, setToggleShowRoles03] = useState(false);
    const [roleCategories, setRoleCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 8;
    const [openRole, setOpenRole] = useState(null);
    const tabValue = JSON.parse(localStorage.getItem('currentTab'))

    // const roleCategories = teamTabRoles();


    const handleTeamRoleChange = (event) => {
        setTeamRole(event.value);
    };

    const handleToggleShowRoles = (role) => {
        setOpenRole(openRole === role ? null : role);
      };

    const handleToggleShowRoles01 = () => {
        setToggleShowRoles01(!toggleShowRoles01);
    };

    const handleToggleShowRoles02 = () => {
        setToggleShowRoles02(!toggleShowRoles02);
    };

    const handleToggleShowRoles03 = () => {
        setToggleShowRoles03(!toggleShowRoles03);
    };


    const handleSubmitEvidence = () => {
        navigate(`/app/user-settings`);
    };

    const handleRouteToCustom = () => {
        navigate("/app/user-settings/custom-role");
    };

    const handleChange = (e) => {
       setEmail(e.target.value)
    };

    const handleCreateTeam = async () => {
        setIsLoading(true)
        const filter_payload = {}

        if (email !== '') {
            filter_payload.email = email
        }

        if (TeamRole !== '') {
            filter_payload.role_id = TeamRole
        }

        try {
            const response = await axiosInstance.post(`/user-account/invite?productName=${apiUrl}`, filter_payload)
            notifySuccess(response.data.message)
            handleSubmitEvidence()

        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsLoading(false)
        }
    }

    const getRoles = async () => {
        setIsRoleLoading(true)
        try {
            const response = await axiosInstance.get(`/role/all-roles-with-permissions?productName=${apiUrl}`)
            // notifySuccess(response.data.message)
            const data = response.data.data
            setRoleCategories(data?.data)
        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsRoleLoading(false)
        }
    }

    useEffect(() => {
        getRoles()
    }, [])

    const groupPermissions = (permissions) => {
        const grouped = {};
        const settingsPermissions = [];
      
        permissions.forEach(permission => {
          if (permission.name.includes('wallet') || permission.name.includes('withdraw') || permission.name.includes('convert') || permission.name.includes('transaction')) {
            const key = 'Wallet Permission';
            if (!grouped[key]) grouped[key] = [];
            grouped[key].push(permission);
          } else if (permission.name.includes('transfer')) {
            const key = 'Transfer Permission';
            if (!grouped[key]) grouped[key] = [];
            grouped[key].push(permission);
          } else if (permission.name.includes('payment')) {
            const key = 'Payment Links Permission';
            if (!grouped[key]) grouped[key] = [];
            grouped[key].push(permission);
          } else if (permission.name.includes('chargeback')) {
            const key = 'Dispute Permission';
            if (!grouped[key]) grouped[key] = [];
            grouped[key].push(permission);
          } else if (permission.name.includes('customer')) {
            const key = 'Customer Permission';
            if (!grouped[key]) grouped[key] = [];
            grouped[key].push(permission);
          } else if (permission.name.includes('invoice')) {
            const key = 'Invoice Permission';
            if (!grouped[key]) grouped[key] = [];
            grouped[key].push(permission);
          } else if (permission.name.includes('ledger') || permission.name.includes('entries')) {
            const key = 'Ledger Permission';
            if (!grouped[key]) grouped[key] = [];
            grouped[key].push(permission);
          } else {
            settingsPermissions.push(permission);
          }
        });
      
        if (settingsPermissions.length > 0) {
          grouped['Settings Permission'] = settingsPermissions;
        }
      
        return grouped;
      };
      
      


    return (
        <>

            <Helmet>
                <title> Invite Team </title>
            </Helmet>
            <div className="wrapper  pb-5">
                <div className={`${styles.header_wrap} d-sm-flex justify-content-between align-items-center`}>
                    <div className="d-flex">
                        <Link
                            className="d-block text-secondary"
                            to={{
                                pathname: `/app/user-settings`,
                                state: "5"
                            }}
                            component={RouterLink}
                        >
                            <i
                                className="bi bi-x"
                                style={{ fontSize: "1.5em", lineHeight: "1" }}
                            ></i>
                        </Link>
                        <h6 className="pl-2 pl-sm-3 mb-0 fw-700 pb-0" style={{ lineHeight: '1.5' }} >Invite Team</h6>
                    </div>
                    {/* <div>
                        <button className="btn btn-primary px-3" onClick={handleRouteToCustom}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 10H15" stroke="#F9FAFB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M10 15V5" stroke="#F9FAFB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <span> Custom Roles</span>
                        </button>
                    </div> */}
                </div>

                <div className="row mt-5">
                    <div className="col-md-5">
                        <div
                            style={{ width: '24em', maxWidth: '100%' }}
                            className="d-flex flex-column justify-content-start align-items-center input_fullWidth">
                            <div className="w-100">
                                <h6>
                                    <span className="text-dark fw-700" style={{ fontSize: '20px' }}>Invite Team Member</span>
                                </h6>
                                <p style={{ fontWeight: '300', color: 'rgba(75, 85, 99, 1)', width: '28em', maxWidth: '100%' }}>
                                    <small className="fs-12">Enter names and emails for some team members to invite them to join your organisation on OnePoint.</small>
                                </p>
                            </div>
                            <div className="mb-3" style={{ width: "100%" }}>
                                <label htmlFor="invite_email" className="fs-14">Email</label>
                                <div className="">
                                    <TextField
                                        name="email"
                                        required
                                        type="email"
                                        id="invite_email"
                                        value={email}
                                        onChange={handleChange}
                                        size="small"
                                        placeholder=""
                                        fullWidth
                                    />
                                </div>
                            </div>

                            <div className="mb-3 d-flex flex-column w-100">
                                <div className="d-flex mb-2 justify-content-between align-items-center">
                                    <label htmlFor="name" className="mb-0">Select Role</label>
                                    {/* <span className="fs-12 cursor-pointer text-dark fw-500" style={{ textDecoration: 'underline', textUnderlineOffset: '3px' }}>Custom Role</span> */}
                                </div>
                                <div className="position-relative">
                                    <Box
                                        sx={{
                                            maxWidth: "100%",
                                            position: "relative",
                                        }}
                                    >
                                        <div className="w-100 liveselect_noborderradius">
                                            <LiveSelect
                                                placeholder={`Select Role...`}
                                                defaultValue={TeamRole}
                                                onChange={handleTeamRoleChange}
                                                options={roleCategories?.map((option) => ({
                                                    value: option.id,
                                                    label: option.name,
                                                }))}
                                            />
                                        </div>
                                    </Box>
                                    {/* <span className=" all_selectdropdown_icon position-absolute px-2">
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z" fill="#6B7280" />
                                        </svg>
                                    </span>
                                    <Select
                                        labelId="s"
                                        id="s"
                                        fullWidth
                                        size="small"
                                        required
                                        name="verified"
                                        placeholder="Select"
                                        defaultValue="owner"
                                    >
                                        <MenuItem value="owner">Owner</MenuItem>
                                        <MenuItem value="wallet">Wallet Permission</MenuItem>
                                        <MenuItem value="transfer">Transfer Permission</MenuItem>
                                        <MenuItem value="customer">Customer Permission</MenuItem>
                                        <MenuItem value="ledger">Ledger Permission</MenuItem>
                                        <MenuItem value="dispute">Dispute Permission</MenuItem>
                                        <MenuItem value="payment">Payment Links Permission</MenuItem>
                                        <MenuItem value="invoice">Invoice Permission</MenuItem>
                                        <MenuItem value="settings">Settings Permission</MenuItem>
                                    </Select> */}
                                </div>
                            </div>

                            <div className="w-100 mt-3">
                                <button
                                    className="btn border btn-primary d-block"
                                    onClick={handleCreateTeam}
                                    style={{ padding: '8px 20px' }}
                                    disabled={isLoading}
                                >
                                    <span className="text-white">
                                        Send Invite
                                        {
                                            isLoading &&
                                            <CircularProgress
                                                style={{ color: "#fff" }}
                                                className="text-white ml-1"
                                                size="0.8rem"
                                            />
                                        }
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 px-">
                        <div className=" px-4 py-4"
                            style={{ width: '35em', maxWidth: '100%', background: 'rgb(249,250,251)' }}
                        >
                            <label htmlFor="" className="fs-16 d-block mb-4 fw-600 text-dark mt-5 mt-md-0 pl-">Role Description</label>

                            {(!isRoleLoading && roleCategories?.length > 0) && roleCategories?.map((el,i) => {
                                const groupedPermissions = groupPermissions(el?.permissions || []);

                                return (
                                    <>
                                        <div key={i} className="border-bottom w-100 pt-4 pb-3">
                                            <p className="fw-600 mb-1 text-dark fs-15">{el?.name}</p>
                                            <span className="d-block py-1">
                                                {el?.description || 'No description for this role'}
                                            </span>
                                            {/* {toggleShowRoles01 ? (
                                                <span
                                                    style={{ textDecoration: 'underline', textUnderlineOffset: '3px' }}
                                                    className="d-block fit-content text-dark fw-500 mt-2 py-1 cursor-pointer" onClick={handleToggleShowRoles01}>
                                                    {"< Back"}
                                                </span>
                                            ) : (
                                                <span className="d-block fit-content text-dark fw-500 mt-2 py-1 cursor-pointer" onClick={handleToggleShowRoles01}>
                                                    View Role
                                                </span>
                                            )} */}

                                            <span
                                                style={{ textDecoration: openRole === el ? 'underline' : 'none', textUnderlineOffset: '3px' }}
                                                className="d-block fit-content text-dark fw-500 mt-2 py-1 cursor-pointer"
                                                onClick={() => handleToggleShowRoles(el)}
                                            >
                                                {openRole === el ? '< Back' : 'View Role'}
                                            </span>

                                            {openRole === el && (
                                                <>
                                                    <div className="w-100 px-3">
                                                        <div className="row mt-4">
                                                            {(!isRoleLoading && groupedPermissions) && Object.keys(groupedPermissions).map((group, a) => {
                                                                return (
                                                                        <div key={a} className="col-md-6 px-0 mb-3">
                                                                            <label htmlFor="" className="fs-14 d-block text-dark">{group}</label>
                                                                            {groupedPermissions[group].map((item, index) => (
                                                                                <div key={index} className="checkbox_div light d-flex align-items-center mb-1">
                                                                                    <label htmlFor="schedule" className="fs-13 cursor-pointer mb-0 fw-400">{item.name}</label>
                                                                                </div>
                                                                            ))}

                                                                            {/* <div className="checkbox_div light d-flex align-items-center mb-1">
                                                                                <label htmlFor="schedule" className="fs-13 cursor-pointer mb-0 fw-400">Can manage wallet</label>
                                                                            </div> */}
                                                                        </div>

                                            
                                                                        )
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            
                                            {
                                                (!isRoleLoading && !groupedPermissions) &&
                                                <div className="alerts alerts-primary text-center mt-4 mb-4">
                                                    <h6>No record found</h6>
                                                </div>
                                            }
                                        </div>

                                        {/* <div className="border-bottom w-100 pt-4 pb-3">
                                            <p className="fw-600 mb-1 text-dark fs-15">Developers</p>
                                            <span className="d-block py-1">
                                                This role is for developers who need to set up a OnePoint integration. This role has access to the secret key which grants access to almost all Onepoint resources.
                                            </span>
                                            {toggleShowRoles02 ? (
                                                <span
                                                    style={{ textDecoration: 'underline', textUnderlineOffset: '3px' }}
                                                    className="d-block fit-content text-dark fw-500 mt-2 py-1 cursor-pointer" onClick={handleToggleShowRoles02}>
                                                    {"< Back"}
                                                </span>
                                            ) : (
                                                <span className="d-block fit-content text-dark fw-500 mt-2 py-1 cursor-pointer" onClick={handleToggleShowRoles02}>
                                                    View Role
                                                </span>
                                            )}

                                            {toggleShowRoles02 && (
                                                <>
                                                    <div className="w-100 px-3">
                                                        <div className="row mt-4">
                                                            <div className="col-md-6 px-0 mb-3">
                                                                <label htmlFor="" className="fs-14 d-block text-dark">Wallet Permission</label>
                                                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                                                    <label htmlFor="schedule" className="fs-13 cursor-pointer mb-0 fw-400">Can view wallet</label>
                                                                </div>

                                                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                                                    <label htmlFor="schedule" className="fs-13 cursor-pointer mb-0 fw-400">Can manage wallet</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 px-0 mb-3">
                                                                <label htmlFor="" className="fs-14 d-block text-dark">Dispute Permission</label>
                                                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                                                    <label htmlFor="schedule" className="fs-13 cursor-pointer mb-0 fw-400">Can view chargebacks</label>
                                                                </div>

                                                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                                                    <label htmlFor="schedule" className="fs-13 cursor-pointer mb-0 fw-400">Can manage chargebacks</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 px-0 mb-3">
                                                                <label htmlFor="" className="fs-14 d-block text-dark">Transfer Permission</label>
                                                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                                                    <label htmlFor="schedule" className="fs-13 cursor-pointer mb-0 fw-400">Can view transfers</label>
                                                                </div>

                                                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                                                    <label htmlFor="schedule" className="fs-13 cursor-pointer mb-0 fw-400">Can create single transfers </label>
                                                                </div>

                                                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                                                    <label htmlFor="schedule" className="fs-13 cursor-pointer mb-0 fw-400">Can create bulk transfers </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        <div className=" w-100 pt-4 pb-3">
                                            <p className="fw-600 mb-1 text-dark fs-15">Accountant</p>
                                            <span className="d-block py-1">
                                                This role is for developers who need to set up a OnePoint integration. This role has access to the secret key which grants access to almost all Onepoint resources.
                                            </span>
                                            {toggleShowRoles03 ? (
                                                <span
                                                    style={{ textDecoration: 'underline', textUnderlineOffset: '3px' }}
                                                    className="d-block fit-content text-dark fw-500 mt-2 py-1 cursor-pointer" onClick={handleToggleShowRoles03}>
                                                    {"< Back"}
                                                </span>
                                            ) : (
                                                <span className="d-block fit-content text-dark fw-500 mt-2 py-1 cursor-pointer" onClick={handleToggleShowRoles03}>
                                                    View Role
                                                </span>
                                            )}

                                            {toggleShowRoles03 && (
                                                <>
                                                    <div className="w-100 px-3">
                                                        <div className="row mt-4">
                                                            <div className="col-md-6 px-0 mb-3">
                                                                <label htmlFor="" className="fs-14 d-block text-dark">Wallet Permission</label>
                                                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                                                    <label htmlFor="schedule" className="fs-13 cursor-pointer mb-0 fw-400">Can view wallet</label>
                                                                </div>

                                                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                                                    <label htmlFor="schedule" className="fs-13 cursor-pointer mb-0 fw-400">Can manage wallet</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 px-0 mb-3">
                                                                <label htmlFor="" className="fs-14 d-block text-dark">Dispute Permission</label>
                                                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                                                    <label htmlFor="schedule" className="fs-13 cursor-pointer mb-0 fw-400">Can view chargebacks</label>
                                                                </div>

                                                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                                                    <label htmlFor="schedule" className="fs-13 cursor-pointer mb-0 fw-400">Can manage chargebacks</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 px-0 mb-3">
                                                                <label htmlFor="" className="fs-14 d-block text-dark">Transfer Permission</label>
                                                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                                                    <label htmlFor="schedule" className="fs-13 cursor-pointer mb-0 fw-400">Can view transfers</label>
                                                                </div>

                                                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                                                    <label htmlFor="schedule" className="fs-13 cursor-pointer mb-0 fw-400">Can create single transfers </label>
                                                                </div>

                                                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                                                    <label htmlFor="schedule" className="fs-13 cursor-pointer mb-0 fw-400">Can create bulk transfers </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div> */}
                                    </>
                                )
                            })}

                            {isRoleLoading &&
                                <>
                                    <div className="d-flex justify-content-center mt-3">
                                        <CircularProgress
                                            style={{ color: "#fff" }}
                                            className="text-white ml-1"
                                            size="1.3rem"
                                        />
                                    </div>
                                    <br />
                                </>
                            }
                            {
                                (!isRoleLoading && !roleCategories?.length) &&
                                <div className="alerts alerts-primary text-center mt-4 mb-4">
                                    <h6>No record found</h6>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}