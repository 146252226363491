
import toast from "react-hot-toast";
import SuccessToast from 'src/components/SuccessToast';
import ErrorToast from 'src/components/ErrorToast';

const activeMessages = new Map();

const TOAST_DURATION = 5000;

export const notifySuccess = (msg) => {
    if (activeMessages.has(msg)) {
        // console.log(`Message already active: ${msg}`);
    }
    
    activeMessages.set(msg, setTimeout(() => {
        activeMessages.delete(msg);
    }, TOAST_DURATION));

    toast.custom(
        (t) => <SuccessToast message={msg} show={t.visible} />,
        {
            duration: TOAST_DURATION,
            onClose: () => {
                if (activeMessages.has(msg)) {
                    clearTimeout(activeMessages.get(msg));
                    activeMessages.delete(msg);
                }
            },
        }
    );
};

export const notifyError = (err) => {
    if (activeMessages.has(err)) {
        return;
    }

    activeMessages.set(err, setTimeout(() => {
        activeMessages.delete(err);
    }, TOAST_DURATION));

    // console.log(`Showing message: ${err}`);
    toast.custom(
        (t) => <ErrorToast message={err} show={t.visible} />,
        {
            duration: TOAST_DURATION,
            onClose: () => {
                if (activeMessages.has(err)) {
                    clearTimeout(activeMessages.get(err));
                    activeMessages.delete(err);
                }
            },
        }
    );
};

export const useLangDropDowns = () => {
    return [
        { name: 'English', key: 'English' },
        { name: 'Mandarin Chinese', key: 'Mandarin Chinese' },
        // {name: 'Spanish', key: 'Spanish'},
        // {name: 'French', key: 'French'},
        // {name: 'German', key: 'German'},
        // {name: 'Chinese', key: 'Chinese'},
        // {name: 'Russian', key: 'Russian'},
        // {name: 'Hindi', key: 'Hindi'},
        // {name: 'Portuguese', key: 'Portuguese'},
        // {name: 'Japanese', key: 'Japanese'},
        // {name: 'Italian', key: 'Italian'},
        // {name: 'Turkish', key: 'Turkish'},
        // {name: 'Korean', key: 'Korean'},
        // {name: 'Yoruba', key: 'Yoruba'},
        // {name: 'Igbo', key: 'Igbo'},
        // {name: 'Hausa', key: 'Hausa'},
        // {name: 'Zulu', key: 'Zulu'},
        // {name: 'Swahili', key: 'Swahili'},
        // {name: 'Afrikaans', key: 'Afrikaans'},
    ];
}

export const useCountryDropDowns = () => {
    return [
        { name: 'Nigeria', key: 'Nigeria' },
        { name: 'China', key: 'China' },
        { name: 'Hong kong', key: 'Hong kong' },
    ]
}

export const useNoOfEmployees = () => {
    return [
        { name: '1 - 40 employees', key: '1 - 40 employees' },
        { name: '41 - 80 employees', key: '41 - 80 employees' },
        { name: '61 - 80 employees', key: '61 - 80 employees' },
        { name: '81 - 100 employees', key: '81 - 100 employees' },
        { name: '100+ employees', key: '100+ employees' },
    ]
}

export const orgTabCategory = () => {
    return [
        { name: 'Rabbit Africa', key: 'rabbit_africa' },
    ];
}

export const orgTabBanks = () => {
    return [
        { name: 'Wema Bank', key: 'wema' },
    ];
}

export const teamTabRoles = () => {
    return [
        { name: 'Owner Permission', key: 'owner' },
        { name: 'Wallet Permission', key: 'wallet' },
        { name: 'Transfer Permission', key: 'trf' },
        { name: 'Customer Permission', key: 'cstm' },
        { name: 'Ledger Permission', key: 'ldg' },
        { name: 'Dispute Permission', key: 'disp' },
        { name: 'Payment Permission', key: 'payment' },
        { name: 'Invoice Permission', key: 'invc' },
        { name: 'Settings Permission', key: 'set' },
    ];
}

export const mockupLoginSuccess = () => {
    return {
        "status": "success",
        "message": "User Login successful",
        "data": {
            "errorCode": "0",
            "id": "6bce81ca-0fd7-4fc2-86f2-df78b306db09",
            "emailAddress": "deendipo@gmail.com",
            "createdAt": "2024-04-22T19:07:27.000Z",
            "isVerified": true
        }
    }
}

export const mockupLoginError = () => {
    return {
        "status": "failed",
        "message": "Bad Request",
        "data": {
            "errorCode": "01",
            "errorMessage": "Incorrect email or password! kindly re-attempt your login"
        }
    }
}

export const mockupSignupSuccess = () => {
    return {
        "status": "success",
        "message": "Account created successfully, A verification code has been sent your email",
        "data": {
            "errorCode": "0",
            "id": "6bce81ca-0fd7-4fc2-86f2-df78b306db09",
            "emailAddress": "deendipo@gmail.com",
            "createdAt": "2024-04-22T19:07:27.645Z",
            "isVerified": false
        }
    }
}

export const mockupSignupError = () => {
    return {
        "status": "failed",
        "message": "Bad request",
        "data": {
            "errorCode": "01",
            "errorMessage": "Note that this is a demo test. Kindly use deendipo@gmail.com as email and Okay_Pass@1 as password"
        }
    }
}

export const mockupEmailVerifySuccess = () => {
    return {
        "status": "success",
        "message": "Email verified successfully",
        "data": {
            "errorCode": "0",
            "id": "6bce81ca-0fd7-4fc2-86f2-df78b306db09",
            "emailAddress": "deendipo@gmail.com",
            "createdAt": "2024-04-22T19:07:27.000Z",
            "isVerified": true
        }
    }
}

export const mockupEmailVerifyError = () => {
    return {
        "status": "failed",
        "message": "Bad request",
        "data": {
            "errorCode": "01",
            "errorMessage": "Invalid OTP string"
        }
    }
}

export const mockupGetWalletTransactions = () => {
    return {
        "status": "success",
        "message": "Wallet Transaction Information fetched Successfully.",
        "data": {
            "errorCode": "0",
            "data": [
                {
                    "wallet_ref": "WALL_17144867341899490",
                    "transaction_ref": "TRANS_17176863541352472",
                    "amount_due": 100.25,
                    "description": "For recharge Card",
                    "transaction_date": "2024-06-06T15:05:54.000Z",
                    "credit_bank_code": "CDB",
                    "credit_bank_name": "Credit Bank",
                    "currency": "NGN",
                    "payment_type": "DEFAULT",
                    "transfer_type": "INTERNAL",
                    "provider_charges": 20.4,
                    "credit_account_name": "Jane Doe",
                    "credit_account_no": "987654321",
                    "status": "APPROVED",
                    "next_schedule_date": "2024-06-23"
                },
                {
                    "wallet_ref": "WALL_17144867341899490",
                    "transaction_ref": "TRANS_17176863054188082",
                    "amount_due": 100.25,
                    "description": "For recharge Card",
                    "transaction_date": "2024-06-06T15:05:05.000Z",
                    "credit_bank_code": "CDB",
                    "credit_bank_name": "Credit Bank",
                    "currency": "NGN",
                    "payment_type": "DEFAULT",
                    "transfer_type": "INTERNAL",
                    "provider_charges": 20.4,
                    "credit_account_name": "Jane Doe",
                    "credit_account_no": "987654321",
                    "status": "APPROVED",
                    "next_schedule_date": "2024-06-23"
                },
                {
                    "wallet_ref": "WALL_17144867341899490",
                    "transaction_ref": "TRANS_17176862851995294",
                    "amount_due": 100.25,
                    "description": "For recharge Card",
                    "transaction_date": "2024-06-06T15:04:45.000Z",
                    "credit_bank_code": "CDB",
                    "credit_bank_name": "Credit Bank",
                    "currency": "NGN",
                    "payment_type": "DEFAULT",
                    "transfer_type": "INTERNAL",
                    "provider_charges": 20.4,
                    "credit_account_name": "Jane Doe",
                    "credit_account_no": "987654321",
                    "status": "APPROVED",
                    "next_schedule_date": "2024-06-23"
                },
                {
                    "wallet_ref": "WALL_17144867341899490",
                    "transaction_ref": "TRANS_17176862769022615",
                    "amount_due": 100.25,
                    "description": "For recharge Card",
                    "transaction_date": "2024-06-06T15:04:37.000Z",
                    "credit_bank_code": "CDB",
                    "credit_bank_name": "Credit Bank",
                    "currency": "NGN",
                    "payment_type": "DEFAULT",
                    "transfer_type": "INTERNAL",
                    "provider_charges": 20.4,
                    "credit_account_name": "Jane Doe",
                    "credit_account_no": "987654321",
                    "status": "APPROVED",
                    "next_schedule_date": "2024-06-23"
                },
                {
                    "wallet_ref": "WALL_17144867341899490",
                    "transaction_ref": "TRANS_17176855643727423",
                    "amount_due": 100.25,
                    "description": "For recharge Card",
                    "transaction_date": "2024-06-06T14:52:44.000Z",
                    "credit_bank_code": "CDB",
                    "credit_bank_name": "Credit Bank",
                    "currency": "NGN",
                    "payment_type": "DEFAULT",
                    "transfer_type": "INTERNAL",
                    "provider_charges": 20.4,
                    "credit_account_name": "Jane Doe",
                    "credit_account_no": "987654321",
                    "status": "APPROVED",
                    "next_schedule_date": "2024-06-23"
                },
                {
                    "wallet_ref": "WALL_17144867341899490",
                    "transaction_ref": "TRANS_17176854254278739",
                    "amount_due": 100.25,
                    "description": "For recharge Card",
                    "transaction_date": "2024-06-06T14:50:25.000Z",
                    "credit_bank_code": "CDB",
                    "credit_bank_name": "Credit Bank",
                    "currency": "NGN",
                    "payment_type": "DEFAULT",
                    "transfer_type": "INTERNAL",
                    "provider_charges": 20.4,
                    "credit_account_name": "Jane Doe",
                    "credit_account_no": "987654321",
                    "status": "APPROVED",
                    "next_schedule_date": "2024-06-23"
                },
                {
                    "wallet_ref": "WALL_17144867341899490",
                    "transaction_ref": "TRANS_17176846724646401",
                    "amount_due": 100.25,
                    "description": "For recharge Card",
                    "transaction_date": "2024-06-06T14:37:52.000Z",
                    "credit_bank_code": "CDB",
                    "credit_bank_name": "Credit Bank",
                    "currency": "NGN",
                    "payment_type": "DEFAULT",
                    "transfer_type": "INTERNAL",
                    "provider_charges": 20.4,
                    "credit_account_name": "Jane Doe",
                    "credit_account_no": "987654321",
                    "status": "APPROVED",
                    "next_schedule_date": "2024-06-23"
                },
                {
                    "wallet_ref": "WALL_17144867341899490",
                    "transaction_ref": "TRANS_17176846302395310",
                    "amount_due": 100.25,
                    "description": "For recharge Card",
                    "transaction_date": "2024-06-06T14:37:10.000Z",
                    "credit_bank_code": "CDB",
                    "credit_bank_name": "Credit Bank",
                    "currency": "NGN",
                    "payment_type": "DEFAULT",
                    "transfer_type": "INTERNAL",
                    "provider_charges": 20.4,
                    "credit_account_name": "Jane Doe",
                    "credit_account_no": "987654321",
                    "status": "APPROVED",
                    "next_schedule_date": "2024-06-23"
                },
                {
                    "wallet_ref": "WALL_17144867341899490",
                    "transaction_ref": "TRANS_17169305160323506",
                    "amount_due": 100.25,
                    "description": "For recharge Card",
                    "transaction_date": "2024-05-28T21:08:36.000Z",
                    "credit_bank_code": "CDB",
                    "credit_bank_name": "Credit Bank",
                    "currency": "NGN",
                    "payment_type": "DEFAULT",
                    "transfer_type": "EXTERNAL",
                    "provider_charges": 20.4,
                    "credit_account_name": "Jane Doe",
                    "credit_account_no": "987654321",
                    "status": "PENDING",
                    "next_schedule_date": "2024-06-23"
                },
                {
                    "wallet_ref": "WALL_17144867341899490",
                    "transaction_ref": "TRANS_17164810133319188",
                    "amount_due": 100.25,
                    "description": "For recharge Card",
                    "transaction_date": "2024-05-23T16:16:53.000Z",
                    "credit_bank_code": "CDB",
                    "credit_bank_name": "Credit Bank",
                    "currency": "NGN",
                    "payment_type": "DEFAULT",
                    "transfer_type": "EXTERNAL",
                    "provider_charges": 20.4,
                    "credit_account_name": "Jane Doe",
                    "credit_account_no": "987654321",
                    "status": "PENDING",
                    "next_schedule_date": "2024-06-23"
                }
            ],
            "totalItems": 24,
            "totalPages": 3,
            "currentPage": 1,
            "hasNext": true,
            "hasPrevious": false
        }
    }
}

export const mockupGetSingleTransactions = () => {
    return {
        "status": "success",
        "message": "Wallet Transaction Information fetched Successfully.",
        "data": {
            "errorCode": "0",
            "wallet_ref": "WALL_17134747650425730",
            "transaction_ref": "TRANS_17144329759378660",
            "amount_due": 100.25,
            "description": "For recharge Card",
            "transaction_date": "2024-04-29T23:22:55.000Z",
            "credit_bank_code": "CDB",
            "credit_bank_name": "Credit Bank",
            "currency": "NGN",
            "payment_type": "DEFAULT",
            "transfer_type": "INTERNAL",
            "provider_charges": 10,
            "credit_account_name": "Jane Doe",
            "credit_account_no": "987654321",
            "status": "DECLINED",
            "next_schedule_date": null
        }
    }
}

export const useNigeriaStates = () => {
    return [
        "Abia",
        "Adamawa",
        "Akwa Ibom",
        "Anambra",
        "Bauchi",
        "Bayelsa",
        "Benue",
        "Borno",
        "Cross River",
        "Delta",
        "Ebonyi",
        "Edo",
        "Ekiti",
        "Enugu",
        "Gombe",
        "Imo",
        "Jigawa",
        "Kaduna",
        "Kano",
        "Katsina",
        "Kebbi",
        "Kogi",
        "Kwara",
        "Lagos",
        "Nasarawa",
        "Niger",
        "Ogun",
        "Ondo",
        "Osun",
        "Oyo",
        "Plateau",
        "Rivers",
        "Sokoto",
        "Taraba",
        "Yobe",
        "Zamfara"
    ]
}

export const useChinaStates = () => {
    return [
        "Anhui",
        "Fujian",
        "Gansu",
        "Guangdong",
        "Guizhou",
        "Hainan",
        "Hebei",
        "Heilongjiang",
        "Henan",
        "Hubei",
        "Hunan",
        "Jiangsu",
        "Jiangxi",
        "Jilin",
        "Liaoning",
        "Qinghai",
        "Shaanxi",
        "Shandong",
        "Shanxi",
        "Sichuan",
        "Taiwan",
        "Yunnan",
        "Zhejiang"
    ]
}

export const useHongKongStates = () => {
    return [
        "Central and Western",
        "Eastern",
        "Southern",
        "Wan Chai",
        "Sham Shui Po",
        "Kowloon City",
        "Kwun Tong",
        "Wong Tai Sin",
        "Yau Tsim Mong",
        "Islands",
        "Kwai Tsing",
        "North",
        "Sai Kung",
        "Sha Tin",
        "Tai Po",
        "Tsuen Wan",
        "Tuen Mun",
        "Yuen Long"
    ]
}

export const useCurrencyList = () => {
    return [
        "NGN",
        "CNY",
    ]
}