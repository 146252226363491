import React, { createContext, useContext, useState, useEffect } from 'react';

const PathnameContext = createContext('');

export const usePathname = () => useContext(PathnameContext);

export const PathnameProvider = ({ children }) => {
  const [pathname, setPathname] = useState(localStorage.getItem("path") || '');

  useEffect(() => {
    const handleStorageChange = () => {
      setPathname(localStorage.getItem("path") || '');
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <PathnameContext.Provider value={pathname}>
      {children}
    </PathnameContext.Provider>
  );
};
