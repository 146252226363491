import axios from 'axios';

const API_KEY = 'AIzaSyCvH_nDKUVAcmjtKEbv-_YDY9rVbGqg4VQ';
const BASE_URL = 'https://translation.googleapis.com/language/translate/v2';

const translateText = async (text, targetLang) => {
  try {
    const response = await axios.post(`${BASE_URL}?key=${API_KEY}`, {
      q: text,
      target: targetLang,
      format: 'text'
    });

    if (response.data && response.data.data && response.data.data.translations) {
      return response.data.data.translations[0].translatedText;
    }
    return text; // Fallback to original text if translation fails
  } catch (error) {
    console.error('Translation error:', error);
    return text;
  }
};

export default translateText;
